<template>
  <loading-card :loading="filing === null" :height="500">
    <template #default>
      <div class="pa-3">
        <div class="d-flex align-center">
          <div style="flex:0 0 auto">
            <v-icon color="icon" style="font-size:28px">
              mdi-folder-open
            </v-icon>
          </div>
          <div class="c-caption" style="margin:2px 0 0 8px">
            {{ isNewCaseFiling ? $t('global.filing.caseType.newCase') : $t('global.filing.caseType.existingCase') }}
          </div>
        </div>
        <div class="mt-3">
          <filing-status :filingStatusID="filing.filingStatus.resourceID"/>
          <span class="pl-1 text-minimum-gray" style="font-size:.95rem" :title="statusDate">
            {{ relativeStatusDate }}
          </span>
        </div>
        <div class="mt-5">
          <div>
            <div class="label text-left">{{ $t('courtCase.court') }}</div>
            <div class="break-word">{{ filing.court.displayName }}</div>
          </div>
          <template v-if="hasCaseLink">
            <div class="mt-4">
              <div class="label text-left">{{ $t('courtCase.caseNumber') }}</div>
              <div class="break-word">
                <router-link v-if="isFilerCaseAccessible" :to="generateCaseViewURL">
                  {{ filing.courtCase.caseNumber }}
                </router-link>
                <template v-else>
                  {{ filing.courtCase.caseNumber }}
                </template>
              </div>
            </div>
            <div class="mt-4" :style="isNewCaseFiling ? 'display:none' : '' ">
              <div class="label text-left">{{ $t('courtCase.caseTitle') }}</div>
              <div class="break-word">{{ caseTitle }}</div>
            </div>
          </template>
          <div class="mt-4">
            <div class="label text-left">{{ $t('courtCase.caseClassification') }}</div>
            <div class="break-word">{{ filing.courtCase.caseClassificationName }}</div>
          </div>
          <div class="mt-4">
            <div class="label text-left">{{ $t('filing.leadDocketEntry') }}</div>
            <div class="break-word">{{ filing.leadDocketEntry.docketEntryExternalName }}</div>
          </div>
          <div class="mt-4">
            <div class="label text-left">{{ $t('filing.referenceName') }}</div>
            <transition name="fade-transition" mode="out-in">
              <div class="break-word" :key="filing.referenceName">
                {{ filing.referenceName }}
              </div>
            </transition>
          </div>
          <div class="mt-4">
            <div class="label text-left">{{ $t('filing.referenceNumber') }}</div>
            <div class="break-word">{{ filing.referenceNumber }}</div>
          </div>
        </div>
        <div class="d-print-none">
          <v-divider class="my-3"/>
          <div>
            <c-btn block @click="statusHistoryPopupVisible = true;">
              {{ $t('page.manageFiling.sidebar.statusHistory') }}
            </c-btn>
          </div>
          <div v-if="!isLocked" class="mt-3">
            <c-btn block type="primary" @click="$emit('submitFiling')">
              {{ $t('page.manageFiling.sidebar.submitFiling') }}
            </c-btn>
          </div>
          <div v-if="!isLocked" class="mt-3">
            <c-btn block type="danger" @click="$emit('removeFiling')">
              {{ $t('page.manageFiling.sidebar.removeFiling') }}
            </c-btn>
          </div>
          <div v-if="allowCorrections" class="mt-3">
            <c-btn block type="primary" @click="$emit('makeCorrections')">
              {{ $t('page.manageFiling.sidebar.makeCorrections') }}
            </c-btn>
          </div>
        </div>
      </div>

      <filing-status-history-popup v-model="statusHistoryPopupVisible"/>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import LoadingCard from '@components/support/LoadingCard';
import FilingStatus from '@components/support/FilingStatus';
import FilingStatusHistoryPopup from '@components/support/manageFiling/FilingStatusHistoryPopup';

export default {
  components: { LoadingCard, FilingStatus, FilingStatusHistoryPopup },
  data () {
    return {
      statusHistoryPopupVisible: false
    };
  },
  computed: {
    filing () {
      return this.$store.state.filing.filing;
    },
    isNewCaseFiling () {
      return this.$store.getters['filing/isNewCaseFiling'];
    },
    hasCaseLink () {
      return !_.isNil(this.filing.courtCase.caseIdentifier);
    },
    allowCorrections () {
      return this.filing.filingStatus.resourceID === this.CONST.FILING_STATUS_REJECTED ||
        this.filing.filingStatus.resourceID === this.CONST.FILING_STATUS_ERRORED;
    },
    statusDate () {
      return this.$cove.formatDateTime(this.filing.filingStatusDate);
    },
    isFilerCaseAccessible () {
      return this.$store.getters['filing/isFilerCaseAccessible'];
    },
    caseTitle () {
      return this.isFilerCaseAccessible ? this.filing.courtCase.caseTitle : this.$t('courtCase.secure');
    },
    generateCaseViewURL () {
      let courtID = this.filing.court.resourceID;
      let caseUUID = this.filing.courtCase.caseIdentifier;
      return { name: 'caseView', params: { courtID, caseUUID } };
    },
    isLocked () {
      return this.$store.getters['filing/isLocked'];
    },
    relativeStatusDate () {
      let dateTime = this.$cove.parseDate(this.filing.filingStatusDate);

      // in case of small time differences between server and client ensure we never show a 'future' value here
      let diff = dateTime.toMillis() - new Date().getTime();
      if (diff > 0)
      {
        dateTime = dateTime.minus(diff);
      }

      // also, subtract a second so immediate actions display at least 'one second ago'
      dateTime = dateTime.minus(1000);
      return dateTime.toRelative();
    }
  }
};
</script>