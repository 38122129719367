<template>
  <page-wrapper :title="$t('page.adminReceipts.title')">
    <search-result-template :config="config">

      <template #table-title>
        {{ $t('page.adminReceipts.table') }}
      </template>

      <template #v-data-table.item.receiptDate="{ value }">
        <div style="min-width:100px">
          {{ value | formatDateTime }}
        </div>
      </template>

      <template #v-data-table.item.referenceNumber="{ item }">
        <router-link :to="{ name: 'receipt', params: { receiptID: item.resourceID} }">
          {{ item.referenceNumber }}
        </router-link>
      </template>

      <template #v-data-table.item.amount="{ value }">
        <div style="min-width:100px">
          {{ value | formatCurrency }}
        </div>
      </template>

      <template #v-data-table.item.user.actor.sortName="{ item }">
        <template v-if="!isNil(item.user)">
          <div>
            {{ item.user.actor.sortName }}
          </div>
          <div class="text-minimum-gray">
            {{ item.user.username }}
          </div>
        </template>
      </template>

      <!-- Search Criteria -->
      <template #nav-drawer-filter-body>
        <v-row no-gutters class="mt-4">
          <v-col cols="12">
            <date-range-picker
              :label="$t('receipt.receiptDate')"
              :labelStart="$t('page.adminReceipts.receiptDate.from')"
              :labelEnd="$t('page.adminReceipts.receiptDate.to')"
              keyPrefix="receiptDate"
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.receiptDateChoice"
              :startDate.sync="searchDTO.receiptDateFrom"
              :endDate.sync="searchDTO.receiptDateTo"
              aria-describedby="5fa7dd30-39af-4a10-8356-228d8c4829c1"/>
            <field-description id="5fa7dd30-39af-4a10-8356-228d8c4829c1"
                               message-key="page.adminReceipts.receiptDate.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.referenceNumber"
              :label="$t('receipt.referenceNumber')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="f25274cf-d9f4-4bf5-9074-7c341b1b46eb"/>
            <field-description id="f25274cf-d9f4-4bf5-9074-7c341b1b46eb"
                               message-key="page.adminReceipts.referenceNumber.description"/>
          </v-col>
          <v-col cols="12">
            <c-autocomplete
              :items="receiptTypes"
              v-model="searchDTO.receiptTypeID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('receipt.receiptType')"
              outlined
              key="receiptTypeID"
              hide-details="auto"
              aria-describedby="673557ae-2960-4f48-be86-fc0d2e87c772"/>
            <field-description id="673557ae-2960-4f48-be86-fc0d2e87c772"
                               message-key="page.adminReceipts.receiptTypeID.description"/>
          </v-col>
          <v-col cols="12">
            <c-autocomplete
              :items="receiptStatuses"
              v-model="searchDTO.receiptStatusID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('receipt.receiptStatus.short')"
              outlined
              key="receiptStatusID"
              hide-details="auto"
              aria-describedby="a394b459-0557-4556-b966-609cd30a894c"/>
            <field-description id="a394b459-0557-4556-b966-609cd30a894c"
                               message-key="page.adminReceipts.receiptStatusID.description"/>
          </v-col>

          <v-col cols="12">
            <c-autocomplete
              v-model="searchDTO.userID"
              :items="users"
              :label="$t('receipt.userID')"
              :loading="userLoading"
              :no-data-text="$t('global.typeahead.search.noDataText')"
              :menu-props="{ 'max-width': 350, contentClass: 'wrapped-dropdown' }"
              no-filter
              outlined
              key="userSearch"
              item-text="sortName"
              item-value="resourceID"
              :search-input.sync="userSearch"
              @blur="() => { if (!searchDTO.userID) { users = []; } }"
              hide-details="auto"
              aria-describedby="2b254210-f487-411f-8802-5f6314ed655d">
              <template #item="{ item }">
                <v-list-item-content>
                  <div class="d-flex align-center">
                    <div class="break-word pr-2" style="flex: 0 0 50%">
                      {{ item.sortName }}
                    </div>
                    <div class="text-minimum-gray text-right break-word" style="flex: 0 0 50%">
                      {{ item.username }}
                    </div>
                  </div>
                </v-list-item-content>
              </template>
            </c-autocomplete>
            <field-description id="2b254210-f487-411f-8802-5f6314ed655d"
                               message-key="page.adminReceipts.userID.description"/>
          </v-col>

          <v-col cols="12">
            <c-autocomplete
              v-model="searchDTO.organizationID"
              :items="organizations"
              :label="$t('receipt.organizationID')"
              :loading="organizationLoading"
              :no-data-text="$t('global.typeahead.search.noDataText')"
              :menu-props="{ 'max-width': 350, contentClass: 'wrapped-dropdown' }"
              no-filter
              outlined
              key="organizationSearch"
              item-text="sortName"
              item-value="resourceID"
              :search-input.sync="organizationSearch"
              @blur="() => { if (!searchDTO.organizationID) { organizations = []; } }"
              hide-details="auto"
              aria-describedby="1d52897b-ef0e-4711-8dee-84ac2a438bde"/>
            <field-description id="1d52897b-ef0e-4711-8dee-84ac2a438bde"
                               message-key="page.adminReceipts.organizationID.description"/>
          </v-col>
        </v-row>
      </template>

    </search-result-template>

  </page-wrapper>
</template>

<script>

import SearchResultTemplate from '@components/support/template/SearchResultTemplate';
import SearchResultTemplateMixin from '@/mixins/searchResultTemplate';
import _ from 'lodash';
import axios from 'axios';
import { dateToEndOfDay, dateToStartOfDay } from '@/application/application';
import { DateTime } from 'luxon';

export default {
  components: { SearchResultTemplate },
  mixins: [SearchResultTemplateMixin],
  data () {
    return {
      searchDTO: {
        sort: {
          sortBy: 'receiptDate',
          sortDesc: true
        },
        userID: null,
        organizationID: null,
        receiptDateChoice: '-1m',
        receiptDateFrom: this.$cove.formatDate(DateTime.local().minus({ 'months': 1 })),
        receiptDateTo: this.$cove.formatDate(DateTime.local()),
        referenceNumber: null,
        receiptTypeID: null,
        receiptStatusID: null
      },
      receiptTypes: [],
      receiptStatuses: [],
      users: [],
      organizations: [],
      userSearch: null,
      organizationSearch: null,
      userLoading: false,
      organizationLoading: false
    };
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.receipt.types' }))
      .then((response) => {
        this.receiptTypes = _.defaultTo(response.data._embedded.results, []);
      });
    axios.get(this.$cove.getAPI({ name: 'api.receipt.statuses' }))
      .then((response) => {
        this.receiptStatuses = _.defaultTo(response.data._embedded.results, []);
      });
  },
  watch: {
    userSearch (newValue) {
      this.userDebounce(newValue);
    },
    organizationSearch (newValue) {
      this.organizationDebounce(newValue);
    }
  },
  methods: {
    getRouteName () {
      return 'admin-receipts';
    },
    getSearchAPIName () {
      return 'api.receipts.search';
    },
    getItemKey () {
      return 'resourceID';
    },
    getHeaders () {
      let headers = [];

      headers.push({ text: this.$t('receipt.receiptDate'), value: 'receiptDate', sortable: true });
      headers.push({ text: this.$t('receipt.referenceNumber'), value: 'referenceNumber', sortable: true });
      headers.push({ text: this.$t('receipt.receiptType'), value: 'receiptType.displayName', sortable: true });
      headers.push({ text: this.$t('receipt.receiptStatus.short'), value: 'receiptStatus.displayName', sortable: true });
      headers.push({ text: this.$t('receipt.amount'), value: 'amount', sortable: true });
      headers.push({ text: this.$t('receipt.organizationID'), value: 'organization.sortName', sortable: true });
      headers.push({ text: this.$t('receipt.userID'), value: 'user.actor.sortName', sortable: true });

      return headers;
    },
    addSearchCriteria (query) {

      if (!_.isNil(this.searchDTO.sort.sortBy)) {
        query.sort = `${this.searchDTO.sort.sortBy},${this.searchDTO.sort.sortDesc ? 'desc' : 'asc'}`;
      }

      if (!_.isNil(this.searchDTO.receiptDateFrom)) {
        query.receiptDateFrom = dateToStartOfDay(this.$cove.parseDate(this.searchDTO.receiptDateFrom)).toISO();
      }

      if (!_.isNil(this.searchDTO.receiptDateTo)) {
        query.receiptDateTo = dateToEndOfDay(this.$cove.parseDate(this.searchDTO.receiptDateTo)).toISO();
      }

      if (!_.isNil(this.searchDTO.referenceNumber)) {
        query.referenceNumber = this.searchDTO.referenceNumber;
      }

      if (!_.isNil(this.searchDTO.receiptTypeID)) {
        query.receiptTypeID = this.searchDTO.receiptTypeID;
      }
      if (!_.isNil(this.searchDTO.receiptStatusID)) {
        query.receiptStatusID = this.searchDTO.receiptStatusID;
      }

      if (!_.isNil(this.searchDTO.userID)) {
        query.userID = this.searchDTO.userID;
      }

      if (!_.isNil(this.searchDTO.organizationID)) {
        query.organizationID = this.searchDTO.organizationID;
      }

      return query;
    },
    validateSearchCriteria (searchDTO) {
      this.$cove.validation.date.rejectIfInvalid(searchDTO.receiptDateFrom, 'page.adminReceipts.receiptDate.from', 'receiptDate-startDate');
      this.$cove.validation.date.rejectIfInvalid(searchDTO.receiptDateTo, 'page.adminReceipts.receiptDate.to', 'receiptDate-endDate');
      this.$cove.validation.date.rejectIfBefore(searchDTO.receiptDateTo, searchDTO.receiptDateFrom,
        'page.adminReceipts.receiptDate.to', 'page.adminReceipts.receiptDate.from', 'receiptDate-endDate');
    },
    userDebounce: _.debounce(function (newValue) { // do not use arrow function here
      if (!_.isNil(newValue) && newValue.trim().length > 0)
      {
        this.loadUsers();
      }
    }, 333),
    loadUsers () {
      let url = this.$cove.getAPI({
        name: 'api.users.namesearch',
        query: {
          name: this.userSearch.trim(),
          size: this.CONST.PAGING_MAX_SIZE
        }
      });

      this.userLoading = true;
      axios.get(url)
        .then((response) => {
          this.users = _.get(response, 'data._embedded.results', []);
        })
        .finally(() => {
          this.userLoading = false;
        });
    },
    organizationDebounce: _.debounce(function (newValue) { // do not use arrow function here
      if (!_.isNil(newValue) && newValue.trim().length > 0)
      {
        this.loadOrganizations();
      }
    }, 333),
    loadOrganizations () {
      let url = this.$cove.getAPI({
        name: 'api.organization.nameSearch',
        query: {
          name: this.organizationSearch.trim(),
          size: this.CONST.PAGING_MAX_SIZE
        }
      });

      this.organizationLoading = true;
      axios.get(url)
        .then((response) => {
          this.organizations = _.get(response, 'data._embedded.results', []);
        })
        .finally(() => {
          this.organizationLoading = false;
        });
    }
  }
};
</script>

