import * as core from '@/application/filing-core';

let custom = {};
try {
  let empty = ''; // suppresses needless webpack warnings since it's less strict with dynamic paths
  custom = require(`@/application/filing-custom${empty}`);
} catch (err) {
  // do nothing
}

let filing = Object.assign({}, core, custom);

export default filing;