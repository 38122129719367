<template>
  <div>
    +{{ phoneCountry.dialCode }} {{ formatPhoneNumber() }}
  </div>
</template>

<script>
import { formatPhoneInput } from '@/application/utility';

export default {
  props: {
    phoneCountry: {
      type: Object
    },
    phoneNumber: {
      type: String
    }
  },
  methods: {
    formatPhoneNumber () {
      return formatPhoneInput(this.phoneNumber, this.phoneCountry.iso);
    }
  }
};
</script>