<template>
  <loading-card :loading="loading">
    <v-card-title>
      {{ $t('page.manageFiling.party.stepSummary.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.manageFiling.party.stepSummary.description') }}
    </v-card-subtitle>
    <v-card-text class="pt-2">
      <dl class="d-grid grid-4060">
        <dt>{{ $t('page.manageFiling.party.stepRole.field.role') }}</dt>
        <dd>{{ bean.party.partySubTypeName }}</dd>
        <dt>{{ $t('page.manageFiling.party.stepSummary.field.name') }}</dt>
        <dd>{{ generateDisplayName(bean.actor) }}</dd>
        <template v-if="hasAddress">
          <dt>{{ $t('page.manageFiling.party.stepSummary.field.address') }}</dt>
          <dd><render-address :address="bean.address"/></dd>
        </template>
        <template v-if="hasPhone">
          <dt>{{ $t('page.manageFiling.party.stepSummary.field.phone') }}</dt>
          <dd><render-phone :phoneCountry="bean.contact.contactPhoneCountry" :phoneNumber="bean.contact.contactPhoneNumber"/></dd>
        </template>
        <template v-if="hasEmail">
          <dt>{{ $t('page.manageFiling.party.stepSummary.field.emailAddress') }}</dt>
          <dd>{{ bean.contact.primaryEmail }}</dd>
        </template>
        <template v-if="hasRepresentation">
          <dt>{{ $t('global.representation') }}</dt>
          <dd>
            <template v-if="bean.representation.representationType === CONST.REP_TYPE_PRO_SE">
              {{ $t('global.selfRep') }}
            </template>
            <template v-else-if="bean.representation.representationType === CONST.REP_TYPE_CURRENT_USER">
              {{ user.sortName }}
            </template>
            <template v-else-if="bean.representation.representationType === CONST.REP_TYPE_PRIVATE">
              <template v-if="bean.representation.representationSelectionMode === CONST.REP_SELECTION_MODE_NEW">
                {{ generateDisplayName(bean.representation.newRepresentation.actor) }}
              </template>
              <template v-else-if="bean.representation.representationSelectionMode === CONST.REP_SELECTION_MODE_ATTORNEY">
                {{ bean.representation.existingAttorney.actorHeader.sortName }}
              </template>
              <template v-else-if="bean.representation.representationSelectionMode === CONST.REP_SELECTION_MODE_LAW_FIRM">
                {{ bean.representation.existingLegalOrg.actorHeader.sortName }}
              </template>
            </template>
          </dd>
        </template>
      </dl>
    </v-card-text>
    <v-card-actions class="align-center mt-5">
      <div style="flex: 0 0 auto">
        <c-checkbox v-model="addAnother"
                    :label="$t('page.manageFiling.party.option.addAnother')"
                    hide-details
                    class="ma-0 pa-0"/>
      </div>
      <v-spacer/>
      <div class="text-right" style="flex: 0 0 auto">
        <c-btn type="primary" @click="save">
          {{ $t('global.finish') }}
        </c-btn>
        <c-btn @click="$emit('back')">
          {{ $t('global.back') }}
        </c-btn>
        <c-btn @click="$emit('cancel')">
          {{ $t('global.cancel') }}
        </c-btn>
      </div>
    </v-card-actions>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import WorkflowStepMixin from '@/mixins/workflowStep';
import RenderAddress from '@/components/support/RenderAddress';
import RenderPhone from '@/components/support/RenderPhone';
import { generateDisplayName } from '@/application/utility';

export default {
  components: { RenderAddress, RenderPhone },
  mixins: [ WorkflowStepMixin ],
  data () {
    return {
      addAnother: false
    };
  },
  computed: {
    hasAddress () {
      return !_.isNil(this.bean.address.line1);
    },
    hasPhone () {
      return !_.isNil(this.bean.contact.contactPhoneNumber);
    },
    hasEmail () {
      return !_.isNil(this.bean.contact.primaryEmail);
    },
    hasRepresentation () {
      return !_.isNil(this.bean.representation.representationType) &&
      this.bean.representation.representationType !== this.CONST.REP_TYPE_UNKNOWN;
    },
    user () {
      return this.$store.state.user;
    }
  },
  methods: {
    generateDisplayName,
    save () {
      this.$emit('save', { addAnother: this.addAnother });
    }
  }
};
</script>