<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" class="pt-2">
        <c-text-field
          v-model="interpreter.employer"
          :label="$t('page.initialRegistration.interpreter.field.employer')"
          key="employer"
          outlined
          maxlength="250"
          hide-details="auto"
          aria-describedby="ad81696a-a37c-4ebc-8dc9-2016f12b0689"/>
        <field-description id="ad81696a-a37c-4ebc-8dc9-2016f12b0689"
                           message-key="page.initialRegistration.interpreter.field.employer.description"/>
      </v-col>
      <!-- <transition-group name="scroll-y-transition" tag="div" style="width:100%">
              <v-col cols="12" v-for="sl in interpreter.selectedLocations" :key="sl.id" class="d-flex pa-0">
                <c-autocomplete
                  v-model="sl.locationID"
                  :items="locations"
                  item-text="displayName"
                  item-value="resourceID"
                  style="flex:1 1 auto"
                  label="Location Served"
                  :id="`selected-location-${sl.id}`"
                  outlined/>
                <v-btn icon style="flex:0 0 auto" class="ml-4 mt-3" @click="removeLocation($event, sl.id)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </transition-group> -->
      <!-- <v-col cols="12">
              <button type="button" class="link-button" @click="addLocation">
                Add Location
              </button>
            </v-col> -->
      <v-col cols="12">
        <section-separator :title="$t('page.initialRegistration.interpreter.section.languages')" class="mt-0 mb-2"/>
      </v-col>
      <v-col cols="12">
        <transition name="fade-transition">
          <v-simple-table v-if="hasLanguages">
            <caption class="d-sr-only">
              {{ $t('page.initialRegistration.interpreter.section.languages') }}
            </caption>
            <thead>
              <tr>
                <th>{{ $t('page.initialRegistration.interpreter.field.classification') }}</th>
                <th>{{ $t('page.initialRegistration.interpreter.field.language') }}</th>
                <th>{{ $t('page.initialRegistration.interpreter.field.certificationNumber') }}</th>
                <th class="text-right">{{ $t('global.remove') }}</th>
              </tr>
            </thead>
            <tbody name="fade-transition" is="transition-group">
              <tr v-for="sl in interpreter.selectedLanguages" :key="sl.id">
                <td>{{ sl.classification }}</td>
                <td>{{ sl.language }}</td>
                <td>{{ sl.certificationNumber }}</td>
                <td class="text-right">
                  <v-btn icon @click="removeLanguage($event, sl.id)">
                    <span class="d-sr-only">
                      {{ $t('page.initialRegistration.interpreter.button.removeLanguage', [sl.language]) }}
                    </span>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </transition>
      </v-col>
      <v-col cols="12" class="pb-7 pt-3" :class="{ 'pt-6': hasLanguages }">
        <button type="button" class="link-button" @click="toggleLanguagePopup">
          {{ $t('page.initialRegistration.interpreter.button.addLanguage') }}
        </button>
      </v-col>
    </v-row>

    <interpreter-language-popup v-model="languagePopupVisible" @addLanguage="addLanguage"/>
  </div>
</template>

<script>
import _ from 'lodash';
import { focusNext } from '@/application/utility';
import FieldDescription from '@components/support/FieldDescription';
import InterpreterLanguagePopup from '@components/support/InterpreterLanguagePopup';
import SectionSeparator from '@components/support/SectionSeparator';

export default {
  components: { FieldDescription, InterpreterLanguagePopup, SectionSeparator },
  props: {
    interpreter: {
      type: Object
    }
  },
  data () {
    return {
      languagePopupVisible: false,
      locations: []
    };
  },
  computed: {
    courts () {
      return this.$store.state.application.courts;
    },
    hasLanguages () {
      return this.interpreter.selectedLanguages.length > 0;
    }
  },
  methods: {
    validate () {
      this.$cove.validation.string.maxLength(this.interpreter.employer, 250, 'page.initialRegistration.interpreter.field.employer',
        'employer');

      // scrub empty and duplicate locations
      this.selectedLocations = _.uniqBy(_.reject(this.interpreter.selectedLocations, ['locationID', null]), 'locationID');

      // require at least one language
      if (this.interpreter.selectedLanguages.length === 0)
      {
        this.$cove.validation.addError('missingLanguage', 'page.initialRegistration.interpreter.validation.missingLanguage');
      }
      // duplicate language detection
      else
      {
        let languageIdentifiers = [];
        let duplicateIdentifiers = [];
        this.interpreter.selectedLanguages.forEach((sl, i) => {
          let languageIdentifier = String(sl.languageIdentifier);
          if (languageIdentifiers.includes(languageIdentifier) && !duplicateIdentifiers.includes(languageIdentifier))
          {
            duplicateIdentifiers.push(languageIdentifier);
            this.$cove.validation.addError('duplicateLanguage'+i,
              'page.initialRegistration.interpreter.validation.duplicateLanguage', [sl.language]);
          }
          else
          {
            languageIdentifiers.push(languageIdentifier);
          }
        });
      }

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    toggleLanguagePopup () {
      this.languagePopupVisible = true;
    },
    addLanguage (payload) {
      this.interpreter.selectedLanguages.push(payload);
    },
    removeLanguage (e, id) {
      focusNext(e);
      this.$delete(this.interpreter.selectedLanguages, _.findIndex(this.interpreter.selectedLanguages, { id }));
    },
    formatLanguage (selectedLanguage) {
      let result = `${selectedLanguage.classification} - ${selectedLanguage.language}`;
      if (!_.isNil(selectedLanguage.certificationNumber))
      {
        result += ' - ' + selectedLanguage.certificationNumber;
      }

      return result;
    }
    // loadLocations () {
    //   let locations = [];
    //   let promises = [];
    //   this.courts.forEach((court) => {
    //     promises.push(
    //       axios.get(this.$cove.getAPI({ name: 'api.court.locations', params: { courtID: court.resourceID } }))
    //         .then((response) => {
    //           let courtLocations = _.filter(_.defaultTo(response.data, []), 'calendarEventFlag');
    //           courtLocations = courtLocations.map((location) => {
    //             location.displayName = court.displayName + ' - ' + location.locationName;
    //             return location;
    //           });

    //           locations = locations.concat(courtLocations);
    //         })
    //     );
    //   });

    //   return Promise.all(promises).then(() => {
    //     this.locations = locations;
    //   });
    // },
  }
};
</script>
