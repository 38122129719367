var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-2",attrs:{"items":_vm.items,"headers":_vm.headers,"sort-by":_vm.sort.sortBy,"sort-desc":_vm.sort.sortDesc,"server-items-length":_vm.page.totalElements,"loading":_vm.loading,"item-key":"searchResultKey","must-sort":"","hide-default-footer":"","mobile-breakpoint":800},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-alert',{staticClass:"mb-1 rounded-b-0",attrs:{"value":_vm.readOnly && _vm.page.totalElements > _vm.CONST.CASE_VIEW_PRINTABLE_MAX_TABLE_RESULTS,"icon":"mdi-alert-box","color":"warning","text":""}},[_c('div',{staticClass:"font-weight-medium",staticStyle:{"font-size":"1.1rem","line-height":"1.5"}},[_vm._v(" "+_vm._s(_vm.$t('global.warning'))+" ")]),_c('div',{staticClass:"mt-2",staticStyle:{"max-width":"120ch"}},[_vm._v(" "+_vm._s(_vm.$t('page.caseView.tab.printableLimitWarning', [_vm.CONST.CASE_VIEW_PRINTABLE_MAX_TABLE_RESULTS]))+" ")])]),_c('div',{staticClass:"d-flex align-center pt-3 px-4",staticStyle:{"min-height":"44px"}},[_c('div',{staticClass:"c-caption",staticStyle:{"flex":"1 1 auto"}},[_vm._v(" "+_vm._s(_vm.$t('page.caseView.tab.judgments'))+" ")]),(!_vm.readOnly)?_c('div',{staticClass:"text-right d-print-none",staticStyle:{"flex":"1 1 auto"}},[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":_vm.toggleDrawer}},[_c('v-icon',[_vm._v(" mdi-sort ")]),_c('span',{staticClass:"d-sr-only"},[_vm._v(" "+_vm._s(_vm.$t('global.sort'))+" ")])],1)],1):_vm._e()])]},proxy:true},{key:"item.judgmentDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]}},{key:"item.judgmentAmount",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(value))+" ")]}},{key:"item.forParties",fn:function(ref){
var value = ref.value;
return _vm._l((_vm.sortParties(value)),function(p){return _c('div',{key:p.partyActorInstance.sortName},[_vm._v(" "+_vm._s(p.partyActorInstance.sortName)+" ")])})}},{key:"item.againstParties",fn:function(ref){
var value = ref.value;
return _vm._l((_vm.sortParties(value)),function(p){return _c('div',{key:p.partyActorInstance.sortName},[_vm._v(" "+_vm._s(p.partyActorInstance.sortName)+" ")])})}},{key:"item.claims",fn:function(ref){
var value = ref.value;
return _vm._l((value),function(c){return _c('div',{key:c.sequenceNumber},[_vm._v(" "+_vm._s(c.sequenceNumber)+" - "+_vm._s(c.claimType)+" ")])})}},{key:"footer",fn:function(){return [_c('paging-controls',{attrs:{"page":_vm.page,"read-only":_vm.readOnly},on:{"changePage":_vm.changePage}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }