<template>
  <div>
    <div class="mx-auto" style="max-width:675px">
      <div class="mb-1" style="font-size:1.4rem">
        {{ $t('page.manageFiling.party.editParty') }}
      </div>
      <validation-error-panel/>
      <workflow-wrapper>
        <edit-guide v-if="stepID === 'guide'"
                    key="guide"
                    :party="party"
                    @back="back"
                    @editRole="editRole"
                    @editName="editName"
                    @editAddress="editAddress"
                    @editContact="editContact"
                    @addRepresentation="addRepresentation"
                    @editRepresentation="editRepresentation"
                    @removeRepresentation="removeRepresentation"/>
        <step-role v-else-if="stepID === 'role'"
                   key="role"
                   :originalBean="partyToEditRoleBean(party)"
                   :editing="true"
                   @save="saveRole"
                   @cancel="cancel"/>
        <step-name v-else-if="stepID === 'name'"
                   key="name"
                   :originalBean="partyToEditNameBean(party)"
                   :editing="true"
                   @save="saveName"
                   @cancel="cancel"/>
        <step-address v-else-if="stepID === 'address'"
                      key="address"
                      :originalBean="partyToEditAddressBean(party)"
                      :editing="true"
                      @save="saveAddress"
                      @remove="removeAddress"
                      @cancel="cancel"/>
        <step-contact v-else-if="stepID === 'contact'"
                      key="contact"
                      :originalBean="partyToEditContactBean(party)"
                      :editing="true"
                      @save="saveContact"
                      @cancel="cancel"/>
        <step-representation v-else-if="stepID === 'addRepresentation'"
                             key="addRepresentation"
                             :originalBean="partyToNewRepresentationBean(party)"
                             :editing="true"
                             :allowProSe="!hasAttorneys"
                             @save="saveNewRepresentation"
                             @cancel="cancel"/>
        <step-representation v-else-if="stepID === 'editRepresentation'"
                             key="editRepresentation"
                             :originalBean="partyToEditRepresentationBean(party, editLegalRepID)"
                             :editing="true"
                             :hideOptions="true"
                             @save="updateRepresentation"
                             @cancel="cancel"/>
      </workflow-wrapper>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Filing from '@/application/filing';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import WorkflowWrapper from '@components/support/WorkflowWrapper';
import EditGuide from '@components/support/manageFiling/party/EditGuide';
import StepRole from '@components/support/manageFiling/party/StepRole';
import StepName from '@components/support/manageFiling/party/StepName';
import StepAddress from '@components/support/manageFiling/party/StepAddress';
import StepContact from '@components/support/manageFiling/party/StepContact';
import StepRepresentation from '@components/support/manageFiling/party/StepRepresentation';

export default {
  components: { WorkflowWrapper, ValidationErrorPanel, EditGuide, StepRole, StepName, StepAddress, StepContact, StepRepresentation },
  props: {
    partyID: {
      type: String
    }
  },
  data () {
    return {
      party: null,
      editLegalRepID: null,
      stepID: 'guide'
    };
  },
  computed: {
    hasAttorneys () {
      return !_.isNil(_.find(this.party.legalRepresenterMaps, { proSe: false }));
    }
  },
  created () {
    this.loadParty();
  },
  methods: {
    partyToEditRoleBean: Filing.partyToEditRoleBean,
    partyToEditNameBean: Filing.partyToEditNameBean,
    partyToEditAddressBean: Filing.partyToEditAddressBean,
    partyToEditContactBean: Filing.partyToEditContactBean,
    partyToNewRepresentationBean: Filing.partyToNewRepresentationBean,
    partyToEditRepresentationBean: Filing.partyToEditRepresentationBean,
    loadParty () {
      return Filing.loadPartyForEditing(this.partyID)
        .then((data) => {
          this.party = data;
        });
    },
    afterSave () {
      return this.loadParty()
        .then(() => {
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
          this.stepID = 'guide';
        });
    },
    back () {
      this.$emit('exit');
    },
    cancel () {
      this.stepID = 'guide';
    },
    editRole () {
      this.stepID = 'role';
    },
    editName () {
      this.stepID = 'name';
    },
    editAddress () {
      this.stepID = 'address';
    },
    editContact () {
      this.stepID = 'contact';
    },
    addRepresentation () {
      this.stepID = 'addRepresentation';
    },
    editRepresentation (legalRepID) {
      this.editLegalRepID = legalRepID;
      this.stepID = 'editRepresentation';
    },
    saveRole (bean) {
      this.$cove.block({ delay: 0 });
      Filing.patchPartyRole({
        partyID: this.partyID,
        bean
      })
        .then(this.afterSave)
        .finally(() => {
          this.$cove.unblock();
        });
    },
    saveName (bean) {
      this.$cove.block({ delay: 0 });
      Filing.patchPartyName({
        partyID: this.partyID,
        bean
      })
        .then(this.afterSave)
        .finally(() => {
          this.$cove.unblock();
        });
    },
    saveAddress (bean) {
      this.$cove.block({ delay: 0 });
      Filing.patchPartyAddress({
        partyID: this.partyID,
        bean
      })
        .then(this.afterSave)
        .finally(() => {
          this.$cove.unblock();
        });
    },
    removeAddress (bean) {
      this.$cove.block({ delay: 0 });
      Filing.removePartyAddress({
        partyID: this.partyID
      })
        .then(this.afterSave)
        .finally(() => {
          this.$cove.unblock();
        });
    },
    saveContact (bean) {
      this.$cove.block({ delay: 0 });
      Filing.patchPartyContact({
        partyID: this.partyID,
        bean
      })
        .then(this.afterSave)
        .finally(() => {
          this.$cove.unblock();
        });
    },
    saveNewRepresentation (bean) {
      this.$cove.block({ delay: 0 });
      Filing.saveNewRepresentation({
        partyID: this.partyID,
        bean
      })
        .then(this.afterSave)
        .finally(() => {
          this.$cove.unblock();
        });
    },
    updateRepresentation (bean) {
      // do not make the patch call if nothing changed when selecting a pre-existing attorney/legal org.
      // this is because we lack some of the necessary data in the ExistingLegalRep to fully create the PATCH call
      // AND b/c of server side validations checking for duplicates.
      let oldBean = Filing.partyToEditRepresentationBean(this.party, this.editLegalRepID);
      if ((bean.representationSelectionMode === this.CONST.REP_SELECTION_MODE_ATTORNEY &&
        oldBean.existingAttorney.actorHeader.actorUUID === bean.existingAttorney.actorHeader.actorUUID)
        ||
        (bean.representationSelectionMode === this.CONST.REP_SELECTION_MODE_LAW_FIRM &&
        oldBean.existingLegalOrg.actorHeader.actorUUID === bean.existingLegalOrg.actorHeader.actorUUID))
      {
        this.cancel();
        return;
      }

      this.$cove.block({ delay: 0 });
      Filing.updateRepresentation({
        partyID: this.partyID,
        legalRepID: this.editLegalRepID,
        bean
      })
        .then(this.afterSave)
        .finally(() => {
          this.$cove.unblock();
        });
    },
    removeRepresentation (legalRepID) {
      this.$cove.confirm({ message: this.$t('page.manageFiling.party.removeRep.confirm') })
        .then((confirmed) => {
          if (confirmed)
          {
            this.$cove.block({ delay: 0 });
            Filing.removeRepresentation(legalRepID)
              .then(this.loadParty)
              .then(() => {
                this.$cove.notify({ message: this.$t('application.remove.success'), color: 'success' });
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    }
  }
};
</script>