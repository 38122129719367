<template>
  <div :data-custom-range="choice === CONST.DATE_RANGE_OPTION_CUSTOM ? 'true' : 'false'">
    <transition name="scroll-y-transition" mode="out-in">
      <!-- render the choices dropdown -->
      <div v-if="choice !== CONST.DATE_RANGE_OPTION_CUSTOM" key="choice">
        <c-autocomplete
          :label="label"
          :items="items"
          :required="required"
          v-bind="inputAttributes"
          :value="choice"
          hide-details="auto"
          persistent-hint
          @change="changeChoice"
          :hint="selectedRange"
          :key="`${keyPrefix}-choice`"
          :aria-describedby="ariaDescribedby"/>
      </div>

      <!-- render the custom / freeform inputs -->
      <div v-else key="custom">
        <c-date-picker
          :label="labelStart"
          :required="required"
          ref="startDate"
          :key="`${keyPrefix}-startDate`"
          class="mb-2"
          @input="changeStartDate"
          v-bind="inputAttributes"
          :value="startDate"/>
        <c-date-picker
          :label="labelEnd"
          :required="required"
          :key="`${keyPrefix}-endDate`"
          @input="changeEndDate"
          v-bind="inputAttributes"
          :value="endDate"/>
      </div>
    </transition>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  props: {
    choice: {
      type: String
    },
    startDate: {
      type: String
    },
    endDate: {
      type: String
    },
    offerPastDates: {
      type: Boolean,
      default: true
    },
    offerFutureDates: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    labelStart: {
      type: String
    },
    labelEnd: {
      type: String
    },
    inputAttributes: {
      type: Object
    },
    ariaDescribedby: {
      type: String
    },
    keyPrefix: {
      type: String
    }
  },
  computed: {
    items () {
      let items = [];
      if (this.offerPastDates)
      {
        items.push({ text: this.$t('global.dateRangePicker.inTheLastDays', [7]), value: '-7d' });
        items.push({ text: this.$t('global.dateRangePicker.inTheLastMonth'), value: '-1m' });
        items.push({ text: this.$t('global.dateRangePicker.inTheLastMonths', [3]), value: '-3m' });
        items.push({ text: this.$t('global.dateRangePicker.inTheLastMonths', [6]), value: '-6m' });
        items.push({ text: this.$t('global.dateRangePicker.inTheLastYear'), value: '-1y' });
      }
      if (this.offerFutureDates)
      {
        items.push({ text: this.$t('global.dateRangePicker.inTheNextDays', [7]), value: '7d' });
        items.push({ text: this.$t('global.dateRangePicker.inTheNextMonth'), value: '1m' });
        items.push({ text: this.$t('global.dateRangePicker.inTheNextMonths', [3]), value: '3m' });
        items.push({ text: this.$t('global.dateRangePicker.inTheNextMonths', [6]), value: '6m' });
        items.push({ text: this.$t('global.dateRangePicker.inTheNextYear'), value: '1y' });
      }

      items.push({ text: this.$t('global.dateRangePicker.customRange'), value: this.CONST.DATE_RANGE_OPTION_CUSTOM });

      return items;
    },
    selectedRange () {
      let result = null;
      if (this.choice !== null && this.choice !== this.CONST.DATE_RANGE_OPTION_CUSTOM)
      {
        result = this.$t('global.dateRangePicker.effectiveRange', [this.startDate, this.endDate]);
      }

      return result;
    }
  },
  methods: {
    changeChoice (value) {
      this.$emit('update:choice', value);
      this.updateDates(value);
    },
    changeStartDate (value) {
      this.$emit('update:startDate', value);
    },
    changeEndDate (value) {
      this.$emit('update:endDate', value);
    },
    updateDates (value) {
      // default to null which handles a few cases
      let newStartDate = null;
      let newEndDate = null;

      if (value === this.CONST.DATE_RANGE_OPTION_CUSTOM)
      {
        setTimeout(() => {
          this.$refs.startDate.focus();
        }, 400);
      }
      else if (value !== null) // they chose one of our pre-configured options
      {
        let match = value.match(/(-?\d+)([a-z]{1})/);
        let amount = parseInt(match[1]);
        let type = match[2] === 'y' ? 'years' : (match[2] === 'm' ? 'months' : 'days');
        if (amount < 0)
        {
          newStartDate = this.$cove.formatDate(DateTime.local().minus({ [type]: Math.abs(amount) }));
          newEndDate = this.$cove.formatDate(DateTime.local());
        }
        else
        {
          newStartDate = this.$cove.formatDate(DateTime.local());
          newEndDate = this.$cove.formatDate(DateTime.local().plus({ [type]: amount }));
        }
      }

      this.changeStartDate(newStartDate);
      this.changeEndDate(newEndDate);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-text-field__details {
  margin-bottom: -2px !important;
}
</style>