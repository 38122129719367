<template>
  <page-wrapper :title="$t('page.admin.messageErrors.title')">
    <!-- need a custom error scope so validation errors are not shared between this page and the Filter panel which has no scope -->
    <validation-error-panel :error-scope="errorScope"/>

    <search-result-template :config="config" ref="searchResultTemplate">

      <template #table-title>
        {{ $t('page.admin.messageErrors.table') }}
      </template>

      <template #v-data-table.item.modifiedDate="{ value }">
        <div style="min-width:100px">
          {{ value | formatDateTime }}
        </div>
      </template>

      <template #v-data-table.item.resourceID="{ item }">
        <div class="text-right">
          <button class="link-button" @click="showPopup(item)">
            {{ $t('global.view') }}
          </button>
        </div>
      </template>

      <template #nav-drawer-filter-body>
        <v-row no-gutters class="mt-4">
          <v-col cols="12">
            <date-range-picker
              :label="$t('messageError.modifiedDate')"
              :labelStart="$t('page.admin.messageErrors.dateFrom')"
              :labelEnd="$t('page.admin.messageErrors.dateTo')"
              keyPrefix="modifiedDate"
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.modifiedDateChoice"
              :startDate.sync="searchDTO.modifiedDateStart"
              :endDate.sync="searchDTO.modifiedDateEnd"
              aria-describedby="7eb79e8c-637a-49e8-9f1c-31884199c0b4"/>
            <field-description id="7eb79e8c-637a-49e8-9f1c-31884199c0b4"
                               message-key="page.admin.messageErrors.date.description"/>
          </v-col>
        </v-row>
        {{ $t('global.noFilters') }}
      </template>

      <template #tableFooter>
        <div class="text-right pb-2 pr-2">
          <c-btn type="primary" @click="requeueSelected">
            {{ $t('application.button.requeue') }}
          </c-btn>
          <c-btn type="danger" @click="deleteSelected">
            {{ $t('application.button.delete') }}
          </c-btn>
        </div>
      </template>

    </search-result-template>

    <message-error-popup v-model="popupVisible" :message-error="popupMessageError" @reload="reload"/>

  </page-wrapper>
</template>

<script>

import SearchResultTemplate from '@components/support/template/SearchResultTemplate';
import SearchResultTemplateMixin from '@/mixins/searchResultTemplate';
import _ from 'lodash';
import axios from 'axios';
import { DateTime } from 'luxon';
import { dateToEndOfDay, dateToStartOfDay } from '@/application/application';
import MessageErrorPopup from '../../support/MessageErrorPopup';

export default {
  components: { MessageErrorPopup, SearchResultTemplate },
  mixins: [ SearchResultTemplateMixin ],
  data () {
    return {
      showSelect: true,
      searchDTO: {
        sort: {
          sortBy: 'modifiedDate',
          sortDesc: true
        },
        modifiedDateChoice: '-7d',
        modifiedDateStart: this.$cove.formatDate(DateTime.local().minus({ 'days': 7 })),
        modifiedDateEnd: this.$cove.formatDate(DateTime.local())
      },
      messageErrors: [],
      popupVisible: false,
      popupMessageError: {},
      errorScope: 'messageErrorsSearch'
    };
  },
  created () {
    // clear out the custom error scope when loading the screen since the router guard will handle only the filter validations
    this.$store.commit('cove/errors/clear', this.errorScope);
  },
  methods: {
    getItemKey () {
      return 'resourceID';
    },
    getRouteName () {
      return 'admin-message-errors';
    },
    getSearchAPIName () {
      return 'api.messageerrors';
    },
    getHeaders () {
      let headers = [];

      headers.push({ text: this.$t('messageError.modifiedDate'), value: 'modifiedDate', sortable: true });
      headers.push({ text: this.$t('messageError.errorMessage'), value: 'errorMessage', cellClass: 'overflow-wrap', sortable: false });
      headers.push({ text: this.$t('page.admin.messageErrors.viewHeader'), value: 'resourceID', sortable: false });

      return headers;
    },
    validateSearchCriteria (searchDTO) {
      this.$cove.validation.date.rejectIfInvalid(searchDTO.modifiedDateStart, 'page.admin.messageErrors.dateFrom',
        'modifiedDate-startDate');
      this.$cove.validation.date.rejectIfInvalid(searchDTO.modifiedDateEnd, 'page.admin.messageErrors.dateTo',
        'modifiedDate-endDate');
      this.$cove.validation.date.rejectIfBefore(searchDTO.modifiedDateEnd, searchDTO.modifiedDateStart,
        'page.admin.messageErrors.dateTo', 'page.admin.messageErrors.dateFrom', 'modifiedDate-endDate'); },
    addSearchCriteria (query) {

      if (!_.isNil(this.searchDTO.sort.sortBy))
      {
        query.sort = `${this.searchDTO.sort.sortBy},${this.searchDTO.sort.sortDesc ? 'desc' : 'asc'}`;
      }
      if (!_.isNil(this.searchDTO.modifiedDateStart))
      {
        query.modifiedDateFrom = dateToStartOfDay(this.$cove.parseDate(this.searchDTO.modifiedDateStart)).toISO();
      }
      if (!_.isNil(this.searchDTO.modifiedDateEnd))
      {
        query.modifiedDateTo = dateToEndOfDay(this.$cove.parseDate(this.searchDTO.modifiedDateEnd)).toISO();
      }

      return query;
    },
    requeueSelected () {
      if (this.selected.length === 0)
      {
        this.$cove.notify({ color: 'warning', message: this.$t('page.admin.messageErrors.noneSelected.validation') });
      }
      else
      {
        this.$store.commit('cove/errors/clear', this.errorScope);
        this.$cove.block({ delay: 0 });
        let url = this.$cove.getAPI({ name: 'api.messageerrors.requeue' });
        axios.post(url, { messageErrors: _.map(this.selected, 'resourceID') })
          .then(() => {
            this.clearSelected();
            this.$cove.notify({ message: this.$t('page.admin.messageErrors.requeue.success'), color: 'success' });
            this.reload();
          })
          .finally(() => {
            this.$cove.unblock();
          });
      }
    },
    deleteSelected () {
      if (this.selected.length === 0)
      {
        this.$cove.notify({ color: 'warning', message: this.$t('page.admin.messageErrors.noneSelected.validation') });
      }
      else
      {
        let count = this.selected.length;
        let confirmMessage = count === 1 ? this.$t('application.remove.confirm') : this.$t('application.remove.confirm.multiple', [count]);
        this.$cove.confirm({ message: confirmMessage })
          .then((confirmed) => {
            if (confirmed) {
              this.$store.commit('cove/errors/clear', this.errorScope);
              this.$cove.block({ delay: 0 });
              let url = this.$cove.getAPI({ name: 'api.messageerrors' });
              axios.delete(url, {
                data: { messageErrors: _.map(this.selected, 'resourceID') },
                errorScope: this.errorScope
              })
                .then(() => {
                  this.clearSelected();
                  let message = count === 1 ?
                    this.$t('application.remove.success') : this.$t('application.remove.success.multiple', [count]);
                  this.$cove.notify({ message, color: 'success' });
                  this.reload();
                })
                .finally(() => {
                  this.$cove.unblock();
                });
            }
          });
      }
    },
    clearSelected () {
      this.$refs.searchResultTemplate.clearSelected();
    },
    showPopup (popupMessageError) {
      this.popupMessageError = popupMessageError;
      this.popupVisible = true;
    },
    reload () {
      this._reload();
    }
  }
};
</script>
