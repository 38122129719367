<template>
  <loading-area v-if="!isLocked" :loading="loading" :height="50">
    <!-- horizontal (monitor) display -->
    <v-tabs :value="selectedStepIndex"
            @change="changeTab"
            slider-color="accent"
            slider-size="4"
            class="elevation-2 d-none d-md-block d-print-none rounded"
            grow>
      <v-tab v-for="(step, i) in filingSteps" class="primary--text inset-focus" tag="button" :key="step.filingStepID"
             :disabled="!step.available" @click="refreshTab(i)">
        <div v-if="step.available && isComplete(step)" class="mr-2 primary white--text rounded-circle d-flex align-center"
             style="width:1.8em;height:1.8em;line-height:1em;letter-spacing:0">
          <div class="flex-grow-1 text-center">
            <v-icon small color="white">
              mdi-check-bold
            </v-icon>
          </div>
        </div>
        <div v-else class="mr-2 primary white--text rounded-circle d-flex align-center"
             style="width:1.8em;height:1.8em;line-height:1em;letter-spacing:0">
          <div class="flex-grow-1 text-center">{{ i + 1 }}</div>
        </div>
        {{ step.name }}
      </v-tab>
    </v-tabs>

    <!-- vertical (mobile) display -->
    <v-tabs :value="selectedStepIndex"
            @change="changeTab"
            slider-color="accent"
            slider-size="4"
            class="elevation-2 mb-5 d-md-none d-print-none rounded"
            vertical>
      <v-tab v-for="(step, i) in filingSteps" class="primary--text inset-focus justify-start" tag="button" style="max-width:none"
             :key="step.filingStepID" :disabled="!step.available" @click="refreshTab(i)">
        <div v-if="step.available && isComplete(step)" class="mr-2 primary py-1 px-1 white--text rounded-circle">
          <v-icon small color="white">
            mdi-check-bold
          </v-icon>
        </div>
        <div v-else class="mr-2 primary py-1 px-2 white--text rounded-circle">
          {{ i + 1 }}
        </div>
        {{ step.name }}
      </v-tab>
    </v-tabs>
  </loading-area>
</template>

<script>
import _ from 'lodash';
import LoadingArea from '@components/support/LoadingArea';

export default {
  components: { LoadingArea },
  computed: {
    loading () {
      return _.isEmpty(this.filingSteps);
    },
    filingSteps () {
      return this.$store.getters['filing/getFilingSteps'];
    },
    selectedStepIndex () {
      return this.$store.state.filing.selectedStepIndex;
    },
    isLocked () {
      return this.$store.getters['filing/isLocked'];
    }
  },
  methods: {
    changeTab (newIndex) {
      this.$store.commit('filing/setSelectedStepIndex', newIndex);
    },
    isComplete (step) {
      return this.$store.getters['filing/isStepComplete'](step.filingStepID);
    },
    refreshTab (index) {
      if (index === this.selectedStepIndex)
      {
        this.$store.commit('filing/refreshTab');
      }
    }
  }
};
</script>