<template>
  <v-card class="pa-6">
    <div class="d-flex">
      <div class="text-right" style="flex: 0 0 auto">
        <v-icon color="icon" style="font-size:64px">
          mdi-playlist-edit
        </v-icon>
      </div>
      <div class="ml-6 pl-7" style="flex: 1 1 auto;border-left:1px solid var(--line-color)">
        <template v-if="isNewCaseFiling">
          <div class="c-caption" style="font-size:1.3rem">
            {{ $t('page.manageFiling.filingInfo.splash.newCase.title') }}
          </div>
          <div class="font-weight-light" style="font-size: 1.35rem;max-width:80ch">
            {{ $t('page.manageFiling.filingInfo.splash.newCase.description') }}
          </div>
          <div class="mt-10">
            <c-btn large type="primary" style="min-width:160px" @click="$emit('edit')">
              {{ $t('page.manageFiling.filingInfo.button.enterInfo') }}
            </c-btn>
            <c-btn v-if="isStepComplete" large type="primary" style="min-width:160px" @click="$emit('viewInformation')">
              {{ $t('page.manageFiling.filingInfo.button.viewInfo') }}
            </c-btn>
          </div>
        </template>
        <template v-else>
          <div class="c-caption" style="font-size:1.3rem">
            {{ $t('page.manageFiling.filingInfo.splash.existingCase.title') }}
          </div>
          <div class="font-weight-light" style="font-size: 1.35rem;max-width:80ch">
            {{ $t('page.manageFiling.filingInfo.splash.existingCase.description') }}
          </div>
          <div class="mt-10">
            <c-btn large type="primary" style="min-width:160px" @click="$emit('edit')">
              {{ $t('page.manageFiling.filingInfo.button.enterInfo') }}
            </c-btn>
            <c-btn v-if="isStepComplete" large type="primary" style="min-width:160px" @click="$emit('viewInformation')">
              {{ $t('page.manageFiling.filingInfo.button.viewInfo') }}
            </c-btn>
          </div>
        </template>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  computed: {
    isStepComplete () {
      return this.$store.getters['filing/isStepComplete'](this.CONST.FILING_STEP_FILING_INFO);
    },
    isNewCaseFiling () {
      return this.$store.getters['filing/isNewCaseFiling'];
    }
  }
};
</script>