<template>
  <page-wrapper :title="$t('global.error')">
    <v-row>
      <v-col md="7">
        <v-card>
          <v-card-title>
            {{ $t('page.error.404ajax.section.title') }}
          </v-card-title>
          <v-card-text>
            <div>
              {{ $t('page.error.404ajax.section.description') }}
            </div>
            <div v-if="resource !== null" class="mt-5">
              <code class="pa-2 error--text font-weight-bold" style="font-size:1rem;display:block;word-break:break-all">
                {{ resource }}
              </code>
            </div>
            <div class="text-right mt-16">
              <c-btn type="primary" @click="$router.go(-1);" style="min-width:120px">
                {{ $t('global.goBack') }}
              </c-btn>
              <c-btn type="primary" :to="{ name: 'home' }" style="min-width:120px">
                {{ $t('global.returnHome') }}
              </c-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import PageWrapper from '@components/support/PageWrapper';
import PageMixin from '@/mixins/page';

export default {
  components: { PageWrapper },
  mixins: [ PageMixin ],
  props: {
    resource: {
      type: String,
      default: null
    }
  }
};
</script>
