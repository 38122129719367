<template>
  <loading-card :loading="loading" class="nav-tab-medium">
    <v-card-title>
      {{ $t('page.currentUser.calendar.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.currentUser.calendar.subtitle') }}
    </v-card-subtitle>
    <v-card-text class="mb-3 pa-0">
      <v-simple-table>
        <thead>
          <tr>
            <th scope="col">
              {{ $t('popup.calendarSubscriptions.calendar') }}
            </th>
            <th scope="col">
              {{ $t('popup.calendarSubscriptions.url') }}
            </th>
            <th scope="col">
              {{ $t('popup.calendarSubscriptions.copy') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="showInviteCalendar">
            <td>{{ $t('popup.calendarSubscriptions.invites') }}</td>
            <td>
              <transition name="slide-y-reverse-transition" mode="out-in">
                <div class="font-weight-bold" style="font-family:monospace;word-break:break-all"
                     :key="inviteCalendarURL">
                  {{ inviteCalendarURL }}
                </div>
              </transition>
            </td>
            <td>
              <v-btn icon color="primary" @click="copyToClipboard(inviteCalendarURL)">
                <v-icon>mdi-content-copy</v-icon>
                <span class="d-sr-only">
                  {{ $t('global.copyToClipboard') }}
                </span>
              </v-btn>
            </td>
          </tr>
          <tr>
            <td>{{ $t('popup.calendarSubscriptions.hearings') }}</td>
            <td>
              <transition name="slide-y-reverse-transition" mode="out-in">
                <div class="font-weight-bold" style="font-family:monospace;word-break:break-all;"
                     :key="hearingCalendarURL">
                  {{ hearingCalendarURL }}
                </div>
              </transition>
            </td>
            <td>
              <v-btn icon color="primary" @click="copyToClipboard(hearingCalendarURL)">
                <v-icon>mdi-content-copy</v-icon>
                <span class="d-sr-only">
                  {{ $t('global.copyToClipboard') }}
                </span>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
    <v-card-actions class="mt-8 justify-end">
      <c-btn type="primary" class="pa-4" @click="regenerateToken">
        {{ $t('popup.calendarSubscriptions.regenerateToken') }}
      </c-btn>
    </v-card-actions>
  </loading-card>
</template>

<script>
import axios from 'axios';
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { LoadingCard },
  data () {
    return {
      loading: true,
      calendarToken: null
    };
  },
  computed: {
    user () {
      return this.$store.state.user;
    },
    showInviteCalendar () {
      return this.$store.getters['user/hasRole'](this.CONST.ROLE_INTERPRETER);
    },
    inviteCalendarURL () {
      return this.$cove.getAPI({
        name: 'api.search.user.invites.calendar',
        params: {
          userID: this.user.userID,
          token: this.calendarToken }
      });
    },
    hearingCalendarURL () {
      return this.$cove.getAPI({
        name: 'api.search.user.hearings.calendar',
        params: {
          userID: this.user.userID,
          token: this.calendarToken }
      });
    }
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.user.currentuser' }))
      .then((response) => {
        this.calendarToken = response.data.calendarToken;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    regenerateToken () {
      this.$cove.block();
      axios.patch(this.$cove.getAPI({ name: 'api.user.rotatecalendartoken', params: { userID: this.user.userID } }))
        .then((response) => {
          this.calendarToken = response.data.calendarToken;
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    copyToClipboard (link) {
      navigator.clipboard.writeText(link);
    }
  }
};
</script>