<template>
  <loading-card :loading="loading" class="nav-tab-small">
    <template #default>
      <v-card-title>
        {{ $t('page.admin.userAccount.contact.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.admin.userAccount.contact.description') }}
      </v-card-subtitle>
      <v-card-text class="mt-3 pb-9">
        <dl class="d-grid">
          <dt>{{ $t('page.admin.userAccount.accountInformation.emailAddress') }}</dt>
          <dd>{{ email }}</dd>
          <dt>{{ $t('page.admin.userAccount.accountInformation.ccEmailAddress') }}</dt>
          <dd>
            <div v-for="email in ccEmails" :key="email">
              {{ email }}
            </div>
          </dd>
          <dt>{{ $t('page.admin.userAccount.accountInformation.contactPhone') }}</dt>
          <dd>{{ contactPhone }}</dd>
          <dt>{{ $t('page.admin.userAccount.accountInformation.smsPhone') }}</dt>
          <dd>{{ smsPhone }}</dd>
        </dl>
      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { formatPhoneDisplay } from '@/application/utility';
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { LoadingCard },
  props: {
    userID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      user: null
    };
  },
  computed: {
    contactPhone () {
      let result = null;
      let phone = _.get(this.user, 'actor.contacts.contactPhoneNumber', null);
      if (!_.isNil(phone))
      {
        result = formatPhoneDisplay(phone);
      }

      return result;
    },
    smsPhone () {
      let result = null;
      let phone = _.get(this.user, 'actor.contacts.smsPhoneNumber', null);
      if (!_.isNil(phone))
      {
        result = formatPhoneDisplay(phone);
      }

      return result;
    },
    ccEmails () {
      return _.get(this.user, 'actor.contacts.carbonCopyEmails', null);
    },
    email () {
      return _.get(this.user, 'actor.contacts.primaryEmail', null);
    }
  },
  created () {
    let url = this.$cove.getAPI({
      name: 'api.user',
      params: {
        userID: this.userID
      },
      query: {
        fields: '*,actor(*,contacts(*))'
      }
    });

    axios.get(url)
      .then((response) => {
        this.user = response.data;
      }).finally(() => {
        this.loading = false;
      });
  }
};
</script>