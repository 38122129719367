<template>
  <c-dialog
    :value="value"
    width="1200"
    close-key="cove.general.close"
    title-key="page.admin.messageErrorPopup.title"
    @input="close">

    <validation-error-panel :error-scope="errorScope" class="ma-4 mb-8"/>

    <div class="pa-5">

      <dl class="d-grid grid-2080">
        <dt>{{ $t('messageError.modifiedDate') }}</dt>
        <dd>{{ messageError.modifiedDate }}</dd>
        <dt>{{ $t('messageError.messageUUID') }}</dt>
        <dd>{{ messageError.messageUUID }}</dd>
        <dt>{{ $t('messageError.headers') }}</dt>
        <dd><pre>{{ messageError.headers }}</pre></dd>
        <dt>{{ $t('messageError.payload') }}</dt>
        <dd><pre>{{ messageError.payload }}</pre></dd>
        <dt>{{ $t('messageError.errorDetails') }}</dt>
        <dd><pre>{{ messageError.errorDetails }}</pre></dd>
      </dl>
    </div>

    <template #actions-right>
      <c-btn type="primary" @click="requeueMessageError">
        {{ $t('application.button.requeue') }}
      </c-btn>
      <c-btn type="danger" @click="deleteMessageError">
        {{ $t('application.button.delete') }}
      </c-btn>
    </template>
  </c-dialog>
</template>

<script>
import axios from 'axios';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';

export default {
  components: { ValidationErrorPanel },
  props: {
    value: {
      type: Boolean
    },
    messageError: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      errorScope: 'messageErrorPopup'
    };
  },
  methods: {
    close (reload = false) {
      this.$store.commit('cove/errors/clear', this.errorScope);
      this.$emit('input', false);

      if (reload)
      {
        this.$emit('reload');
      }
    },
    requeueMessageError () {
      this.$store.commit('cove/errors/clear', this.errorScope);
      this.$cove.block({ delay: 0 });
      let url = this.$cove.getAPI({ name: 'api.messageerrors.requeue' });
      axios.post(url, { messageErrors: [this.messageError.resourceID] })
        .then(() => {
          this.close(true);
          this.$cove.notify({ message: this.$t('page.admin.messageErrors.requeue.success'), color: 'success' });
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    deleteMessageError () {
      this.$cove.confirm({ message: this.$t('application.remove.confirm') })
        .then((confirmed) => {
          if (confirmed) {
            this.$store.commit('cove/errors/clear', this.errorScope);
            this.$cove.block({ delay: 0 });
            let url = this.$cove.getAPI({ name: 'api.messageerrors' });
            axios.delete(url, {
              data: { messageErrors: [this.messageError.resourceID] },
              errorScope: this.errorScope
            })
              .then(() => {
                this.close(true);
                this.$cove.notify({ message: this.$t('application.remove.success'), color: 'success' });
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    }
  }
};
</script>