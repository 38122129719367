import { fetchCurrentUser, redirectToLogin, setLocalStorageValue, redirectToLogout } from '@/application/application';
import { scrollTo } from '@/application/utility';
import axios from 'axios';
import CONST from '@/application/constants';
import cove from '@ctrack/cove';
import store from '@/plugins/store';
import router from '@/plugins/router';
import _ from 'lodash';

/**
 * Defines the default error handlers applied to all AJAX requests. These can be overridden by applying an 'errorHandlers' property to your
 * axios call and either using null (for a no-op) or specifying an alternate function to execute.
 */
const defaultErrorHandlers =
{
  400: defaultBadRequestErrorHandler,
  401: defaultUnauthorizedErrorHandler,
  403: defaultUnauthorizedErrorHandler,
  404: defaultServerErrorHandler,
  415: defaultServerErrorHandler,
  500: defaultServerErrorHandler
};

/**
 * Alternate AJAX 404 error handler that should be used instead of the default one for primary resource types, e.g. cases. Redirects the
 * user to a fresh 404 error page. This is preferable to the default as it's cleaner looking than the popup display.
 *
 * @param {Object} error the axios error object
 */
export function redirectNotFoundErrorHandler (error)
{
  let resource = _.get(error, 'config.url', null);
  console.error('redirecting to ajax 404 error page for: ' + resource);
  router.replace({ name: 'error-404-ajax', params: { resource } });
}

/**
 * Default error handler that's used for AJAX 500 error responses. It displays a popup informing the user of the error.
 *
 * @param {Object} error the axios error object
 */
export function defaultServerErrorHandler (error)
{
  let statusCode = _.get(error, 'response.status');
  let payload = {
    statusCode,
    url: _.get(error, 'config.url', null),
    method: _.get(error, 'config.method', null),
    errorReference: _.get(error, 'response.data.threadIdentifier', null),
    timestamp: _.get(error, 'response.data.timestamp', new Date().toISOString())
  };

  store.commit('application/showServerErrorPopup', payload);
}

/**
 * Default error handler that's used for AJAX 401 and 403 error responses. Performs a simple API call and redirects the user to the login
 * page if they don't have an active session.
 *
 * @param {Object} error the axios error object
 */
export function defaultUnauthorizedErrorHandler (error)
{
  fetchCurrentUser()
    .then((user) => {
      if (user === null)
      {
        redirectToLogin();
      }
      else // if they are logged in and got the error they must not have access / failed permission check, so make it look like a 404
      {
        let resource = _.get(error, 'config.url', null);
        router.replace({ name: 'error-404-ajax', params: { resource } });
      }
    });
}

/**
 * Provides a standard method of processing bad request / validation errors from our AJAX calls. Attempts to show the errors as normal
 * validation errors if possible and if the request was to modify data. Otherwise falls back to simply showing an error popup.
 *
 * @param {Object} error the axios error object
 */
export function defaultBadRequestErrorHandler (error)
{
  let fallbackDisplay = true;

  // check for kiosk mode error first (occurs if this browser was in KM and someone changes the password)
  // ECORE-55795 - can't check we are in kiosk mode unfortunately since that call itself will fail first
  let disableKioskMode = _.get(error, 'response.data.disableKiosk', false);
  if (disableKioskMode)
  {
    redirectToLogout();
    return;
  }

  let errorScope = error.config.errorScope;
  let hasErrorScope = !_.isEmpty(errorScope);
  let errorScopePrefix = hasErrorScope ? errorScope + ':' : '';

  // look for validation errors that we'll attempt to display to the user
  let validationErrors = _.get(error, 'response.data.validationErrors', []);
  validationErrors.forEach((error, i) => {
    let replaced = error.replace(/^.*?:\s*/, ''); // chop the prefix (field name) from the REST API error message
    cove.validation.addErrorText(`${errorScopePrefix}requestError` + i, replaced);
  });

  if (validationErrors.length > 0)
  {
    fallbackDisplay = false;
    let nonScopedSelector = CONST.ERROR_CONTAINER_SELECTOR + ':not(' + CONST.SCOPED_ERROR_CONTAINER_SELECTOR + ')';
    let nonScopedContainer = document.querySelector(nonScopedSelector);

    // attempt to show the errors in the form's standard error container
    if (hasErrorScope)
    {
      // does nothing for now...
      // TODO find the scoped parent container, be it a sidebar or popup, and scroll it to the top
    }
    else if (nonScopedContainer !== null)
    {
      scrollTo(nonScopedSelector);
    }
    // otherwise, if we aren't on a page with a form, simply show them in a popup
    else
    {
      store.commit('application/showServerValidationErrorPopup');
    }
  }

  // in the event this HTTP method isn't accounted for OR we couldn't find validation errors in the response, just show a simple popup
  // informing the user of the error.
  if (fallbackDisplay)
  {
    defaultServerErrorHandler(error);
  }
}

/**
 * Request interceptor that updates the request with the 'withCredentials' flag.
 *
 * @param {Object} config The axios request configuration object
 */
function withCredentialsInterceptor (config)
{
  config.withCredentials = true;
  return config;
}

/**
 * Request interceptor that updates the request with the user's locale as a custom header.
 *
 * @param {Object} config The axios request configuration object
 */
function localeInterceptor (config)
{
  config.headers['Accept-Language'] = store.state.user.localeCode;
  return config;
}

/**
 * Request interceptor that records the start time of the request.
 *
 * @param {Object} config The axios request configuration object
 */
function startTimeInterceptor (config)
{
  config.startTime = new Date().getTime();
  return config;
}

/**
 * Request interceptor that makes a session heartbeat each time the user initiates an AJAX request.
 *
 * @param {Object} config The axios request configuration object
 */
function sessionHeartbeatInterceptor (config)
{
  if (!config.skipHeartbeat)
  {
    store.commit('user/updatePollingHeartbeat');
  }
  return config;
}

/**
 * Response interceptor used to track the life of the user's back-end session by recording the start time associated with successful ajax
 * calls.
 *
 * @param {Object} response
 * @returns
 */
function sessionExpiryInterceptor (response)
{
  let startTime = response.config.startTime;
  setLocalStorageValue(CONST.BROWSER_STORAGE_LAST_AJAX_DATE, startTime);

  return response;
}

/**
 * Defines the primary error interceptor that will run on all AJAX requests. Delegates out to the default error handlers (registered by
 * status code) while also taking into account any overrides provided on this particular AJAX call.
 *
 * @param {Object} error the axios error object
 */
function errorInterceptor (error)
{
  let instanceErrorHandlers = _.get(error, 'config.errorHandlers', {});
  let errorHandlers = Object.assign({}, defaultErrorHandlers, instanceErrorHandlers);

  let statusCode = _.get(error, 'response.status', null);
  if (statusCode !== null)
  {
    let errorHandler = errorHandlers[statusCode];

    if (_.isFunction(errorHandler))
    {
      errorHandler(error);
    }
  }

  // must continue to rethrow the error here (by default catches return a resolved promise) since these handlers run BEFORE any attached
  // then/catch/finally callbacks
  return Promise.reject(error);
}

// =========================================
// request interceptors
// - axios runs these reverse order, so define them in the order they should be executed and they will be reverse loaded
// =========================================
[
  { interceptor: sessionHeartbeatInterceptor, synchronous: true },
  { interceptor: withCredentialsInterceptor, synchronous: true },
  { interceptor: localeInterceptor, synchronous: true },
  { interceptor: startTimeInterceptor, synchronous: true }
].reverse().forEach(({ interceptor, synchronous }) => {
  axios.interceptors.request.use(interceptor, null, { synchronous });
});

// =========================================
// success response interceptors
// - response interceptors must have a success & error handler, so define the success ones here and null will be used for errors
// - axios runs these reverse order, so define them in the order they should be executed and they will be reverse loaded
// =========================================
[
  { interceptor: sessionExpiryInterceptor, synchronous: true }
].reverse().forEach(({ interceptor, synchronous }) => {
  axios.interceptors.response.use(interceptor, null, { synchronous });
});

// =========================================
// error response interceptors
// - response interceptors must have a success & error handler, so define the error ones here and null will be used for success
// - axios runs these reverse order, so define them in the order they should be executed and they will be reverse loaded
// =========================================
[
  { interceptor: errorInterceptor, synchronous: true }
].reverse().forEach(({ interceptor, synchronous }) => {
  axios.interceptors.response.use(null, interceptor, { synchronous });
});