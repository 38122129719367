<template>
  <page-wrapper :title="$t('page.admin.userSearch.title')">
    <search-result-template :config="config">

      <template #table-title>
        {{ $t('page.admin.userSearch.table.title') }}
      </template>

      <!--  Result Values  -->
      <template #v-data-table.item.username="{ item }">
        <div>
          <div style="white-space: nowrap">
            <router-link :to="{ name: 'admin-user-account', params: { userID: item.userUUID }}">
              {{ item.username }}
            </router-link>
          </div>
        </div>
      </template>

      <template #v-data-table.item.usable="{ item }">
        <div>
          <div v-if="item.usable">
            <v-icon>
              mdi-check
            </v-icon>
            <span class="d-sr-only">
              {{ $t('global.yes') }}
            </span>
          </div>
        </div>
      </template>

      <!-- Search Criteria -->
      <template #nav-drawer-filter-body>
        <v-row no-gutters class="mt-4">
          <!-- Username (Starts With) -->
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.username"
              :label="$t('page.admin.userSearch.searchCriteria.username')"
              maxlength="100"
              outlined
              hide-details="auto"
              aria-describedby="c6adfde1-91ad-442a-8e5c-9ef338bd343d"/>
            <field-description id="c6adfde1-91ad-442a-8e5c-9ef338bd343d"
                               message-key="page.admin.userSearch.searchCriteria.username.description"/>
          </v-col>

          <!-- First Name (Starts With) -->
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.firstName"
              :label="$t('page.admin.userSearch.searchCriteria.firstName')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="7af3df53-54fc-40d7-98ef-835adb93e1c5"/>
            <field-description id="7af3df53-54fc-40d7-98ef-835adb93e1c5"
                               message-key="page.admin.userSearch.searchCriteria.firstName.description"/>
          </v-col>

          <!-- Last Name (Starts With) -->
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.lastName"
              :label="$t('page.admin.userSearch.searchCriteria.lastName')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="5ead0053-94f9-4b43-9db8-c37752d815b4"/>
            <field-description id="5ead0053-94f9-4b43-9db8-c37752d815b4"
                               message-key="page.admin.userSearch.searchCriteria.lastName.description"/>
          </v-col>

          <!-- E-mail Address (Starts With) -->
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.emailAddress"
              :label="$t('page.admin.userSearch.searchCriteria.emailAddress')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="7fe4f436-ec23-499f-aa0d-d81c836e3143"/>
            <field-description id="7fe4f436-ec23-499f-aa0d-d81c836e3143"
                               message-key="page.admin.userSearch.searchCriteria.emailAddress.description"/>
          </v-col>

          <!-- Has Role (role Dropdown) -->
          <v-col cols="12">
            <c-autocomplete
              :items="roles"
              v-model="searchDTO.roleUUID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('page.admin.userSearch.searchCriteria.hasRole')"
              outlined
              key="roleUUID"
              hide-details="auto"
              aria-describedby="320412d7-a53d-4eed-9b1d-6fbe68950e24"/>
            <field-description id="320412d7-a53d-4eed-9b1d-6fbe68950e24"
                               message-key="page.admin.userSearch.searchCriteria.hasRole.description"/>
          </v-col>

          <!-- Show Active Only -->
          <v-col cols="12">
            <c-checkbox
              v-model="searchDTO.showActiveOnly"
              :label="$t('page.admin.userSearch.searchCriteria.showActiveOnly')"
              class="mt-0"
              hide-details="auto"
              aria-describedby="0b4e8456-6c96-4131-9281-520ba0dd454b"/>
            <field-description id="0b4e8456-6c96-4131-9281-520ba0dd454b"
                               message-key="page.admin.userSearch.searchCriteria.showActiveOnly.description"/>
          </v-col>

          <!-- Show API Users Only (checkbox) (when toggled includes system users that have APIUserPassword) -->
          <v-col cols="12">
            <c-checkbox
              v-model="searchDTO.showAPIUsersOnly"
              :label="$t('page.admin.userSearch.searchCriteria.showAPIUsersOnly')"
              class="mt-0"
              hide-details="auto"
              aria-describedby="d8168267-45d6-4573-944a-87da6d8e0922"/>
            <field-description id="d8168267-45d6-4573-944a-87da6d8e0922"
                               message-key="page.admin.userSearch.searchCriteria.showAPIUsersOnly.description"/>
          </v-col>

        </v-row>
      </template>

    </search-result-template>

  </page-wrapper>
</template>

<script>

import SearchResultTemplate from '@components/support/template/SearchResultTemplate';
import SearchResultTemplateMixin from '@/mixins/searchResultTemplate';
import _ from 'lodash';
import axios from 'axios';

export default {
  components: { SearchResultTemplate },
  mixins: [ SearchResultTemplateMixin ],
  data () {
    return {
      // Search
      searchDTO: {
        sort: {
          sortBy: 'lastName', // Sort by last name by default, Asc
          sortDesc: false
        },
        userUUID: null,
        username: null,
        firstName: null,
        lastName: null,
        emailAddress: null,
        roleUUID: null,
        showActiveOnly: true,
        showAPIUsersOnly: false

      },
      // Dropdowns
      roles: []
    };
  },
  created () {
    this.loadRoles();
  },
  methods: {
    getRouteName ()
    {
      return 'admin-search-user';
    },
    getSearchAPIName ()
    {
      return 'api.users';
    },
    getItemKey () {
      return 'userUUID';
    },
    getHeaders () {

      let headers = [];

      headers.push({ text: this.$t('page.admin.userSearch.headers.lastName'), value: 'lastName', sortable: true });
      headers.push({ text: this.$t('page.admin.userSearch.headers.firstName'), value: 'firstName', sortable: true });
      headers.push({ text: this.$t('page.admin.userSearch.headers.username'), value: 'username', sortable: true });
      headers.push({ text: this.$t('page.admin.userSearch.headers.emailAddress'), value: 'emailAddress',
        sortable: true });
      headers.push({ text: this.$t('page.admin.userSearch.headers.active'), value: 'usable', sortable: true });

      return headers;
    },
    addSearchCriteria (query)
    {
      if (!_.isNil(this.searchDTO.sort.sortBy))
      {
        query.sort = `${this.searchDTO.sort.sortBy},${this.searchDTO.sort.sortDesc ? 'desc' : 'asc'}`;
      }

      if (!_.isNil(this.searchDTO.username))
      {
        query.username = this.searchDTO.username;
      }

      if (!_.isNil(this.searchDTO.firstName))
      {
        query.firstName = this.searchDTO.firstName;
      }

      if (!_.isNil(this.searchDTO.lastName))
      {
        query.lastName = this.searchDTO.lastName;
      }

      if (!_.isNil(this.searchDTO.emailAddress))
      {
        query.emailAddress = this.searchDTO.emailAddress;
      }

      if (!_.isNil(this.searchDTO.roleUUID))
      {
        query.roleUUID = this.searchDTO.roleUUID;
      }

      if (!_.isNil(this.searchDTO.showActiveOnly))
      {
        query.includeUnusable = !this.searchDTO.showActiveOnly;
      }

      if (!_.isNil(this.searchDTO.showAPIUsersOnly))
      {
        query.showAPIUsersOnly = this.searchDTO.showAPIUsersOnly;
      }

      return query;
    },
    validateSearchCriteria (searchDTO)
    {
      // No Validation Of Search Criteria Needed
    },
    loadRoles ()
    {
      this.roles = [];

      axios.get(this.$cove.getAPI({
        name: 'api.roles'
      })).then((response) => {
        this.roles = _.defaultTo(response.data._embedded.results, []);
      });
    }
  }
};
</script>

