import { getLocalStorageValue, redirectToLogin } from '@/application/application';
import { replaceIgnoreDuplicate } from '@/application/utility';
import CONST from '@/application/constants';
import store from './store';
import Vue from 'vue';
import VueRouter from 'vue-router';
import _ from 'lodash';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  // TODO set 'base' property?
  routes: [
    {
      path: '',
      redirect: { name: 'home' }
    },
    {
      // this path should ONLY be hit via redirect from the backend during the logout process
      path: '/logout',
      redirect: { name: 'home', params: { logout: true } }
    },
    {
      path: '/portal',
      redirect: { name: 'home' },
      component: () => { return import('@components/layout/Portal'); },
      meta: { anonymous: true },
      children: [
        {
          name: 'home',
          path: 'home',
          props: true,
          component: () => { return import('@components/pages/Home'); },
          meta: {
            anonymous: true,
            refreshEnabled: true
          }
        },
        {
          name: 'announcements',
          path: 'announcements',
          component: () => { return import('@components/pages/Announcements'); },
          meta: {
            anonymous: true,
            refreshEnabled: true
          }
        },
        {
          name: 'manageAnnouncement',
          path: 'manage-announcement/:announcementID?',
          props: true,
          component: () => { return import('@components/pages/ManageAnnouncement'); },
          meta: {
            roleSecurity: [ CONST.ROLE_ADMINISTRATOR ]
          }
        },
        {
          name: 'language',
          path: 'language',
          component: () => { return import('@components/pages/Language'); },
          meta: {
            anonymous: true,
            refreshEnabled: true
          }
        },
        {
          name: 'user-profile',
          path: 'user-profile',
          redirect: { name: 'profile-login-account' },
          component: () => { return import('@components/pages/UserProfile'); },
          meta: {
            refreshEnabled: true
          },
          children: [
            {
              name: 'profile-login-account',
              path: 'account',
              component: () => { return import('@components/support/userProfile/LoginAccount'); }
            },
            {
              name: 'profile-preferences',
              path: 'preferences',
              component: () => { return import('@components/support/userProfile/Preferences'); }
            },
            {
              name: 'profile-address',
              path: 'address',
              component: () => { return import('@components/support/userProfile/UserAddress'); }
            },
            {
              name: 'profile-contact',
              path: 'contact',
              component: () => { return import('@components/support/userProfile/UserContact'); }
            },
            {
              name: 'profile-interpreter',
              path: 'interpreter',
              component: () => { return import('@components/support/userProfile/Interpreter'); },
              meta: {
                roleSecurity: [ CONST.ROLE_INTERPRETER ]
              }
            },
            {
              name: 'profile-user-access',
              path: 'access',
              component: () => { return import('@components/support/userProfile/UserAccess'); }
            },
            {
              name: 'profile-calendar-subscriptions',
              path: 'calendar-subscriptions',
              component: () => { return import('@components/support/userProfile/CalendarSubscriptions'); }
            },
            {
              name: 'profile-notification-preferences',
              path: 'notification-preferences',
              component: () => { return import('@components/support/userProfile/NotificationPreferences'); }
            },
            {
              name: 'profile-payment-method',
              path: 'payment-method',
              component: () => { return import('@components/support/userProfile/UserPaymentMethod'); }
            },
            {
              name: 'profile-subscription',
              path: 'subscription',
              component: () => { return import('@components/support/userProfile/UserSubscription'); }
            },
            {
              name: 'profile-document-access',
              path: 'document-access',
              component: () => { return import('@components/support/userProfile/UserDocumentAccess'); }
            },
            {
              name: 'profile-organziation',
              path: 'organization',
              component: () => { return import('@components/support/userProfile/UserOrgDetails'); }
            }
          ]
        },
        {
          name: 'about',
          path: 'about',
          component: () => { return import('@components/pages/About'); },
          meta: {
            anonymous: true,
            refreshEnabled: true
          }
        },
        {
          name: 'site-map',
          path: 'site-map',
          component: () => { return import('@components/pages/SiteMap'); },
          meta: {
            anonymous: true,
            refreshEnabled: true
          }
        },
        {
          name: 'searchHome',
          path: 'search',
          component: () => { return import('@components/pages/SearchHome'); },
          meta: {
            anonymous: true,
            refreshEnabled: true
          }
        },
        {
          name: 'searchCriteria',
          path: 'search/:searchType',
          props: true,
          component: () => { return import('@components/pages/SearchCriteria'); },
          meta: { anonymous: true }
        },
        {
          name: 'searchResults',
          path: 'search/:searchType/results',
          props: true,
          component: () => { return import('@components/pages/SearchResults'); },
          meta: { anonymous: true }
        },
        {
          name: 'caseView',
          path: 'court/:courtID/case/:caseUUID',
          props: true,
          component: () => { return import('@components/pages/CaseView'); },
          meta: { anonymous: true }
        },
        {
          name: 'error-server',
          path: 'error/server',
          component: () => { return import('@components/pages/error/ErrorServer'); },
          props: (route) => { return { ...route.query, ...route.params }; },
          meta: { anonymous: true }
        },
        {
          name: 'error-404-ajax',
          path: 'error/404',
          component: () => { return import('@components/pages/error/Error404Ajax'); },
          props: true,
          meta: { anonymous: true }
        },
        {
          name: 'error-document-cart-checkout',
          path: 'error/document-cart-checkout', // supports receiptUUID, processorExternalIdentifier, processorTransactioNumber query params
          component: () => { return import('@components/pages/error/ErrorDocumentCartCheckout'); },
          props: (route) => { return { ...route.query, ...route.params }; },
          meta: { anonymous: false }
        },
        {
          name: 'error-invalid-user',
          path: 'error/invalid-user',
          component: () => { return import('@components/pages/error/ErrorInvalidUser'); },
          meta: { anonymous: true }
        },
        {
          name: 'initialRegistration',
          path: 'initial-registration',
          props: true,
          component: () => { return import('@components/pages/initialRegistration/RegistrationGuide'); }
        },
        {
          name: 'requestAccess',
          path: 'request-access/:userTypeID', // supports courtID and caseNumber query params
          props: (route) => { return { ...route.query, ...route.params }; },
          component: () => { return import('@components/pages/initialRegistration/RequestAccess'); },
          meta: {
            refreshEnabled: true
          }
        },
        {
          name: 'publication',
          path: 'court/:courtID/publication/:publicationUUID',
          props: true,
          component: () => { return import('@components/pages/Publication'); },
          meta: {
            anonymous: true
          }
        },
        {
          name: 'receipt',
          path: 'receipt/:receiptID',
          props: true,
          component: () => { return import('@components/pages/accounting/Receipt'); },
          meta: {
            refreshEnabled: true
          }
        },
        {
          name: 'create-filing',
          path: 'create-filing',
          component: () => { return import('@components/pages/CreateFiling'); },
          meta: {
            roleSecurity: [ CONST.ROLE_FILER ],
            refreshEnabled: true
          }
        },
        {
          name: 'cart',
          path: 'cart',
          component: () => { return import('@components/pages/DocumentCart'); },
          meta: {
            anonymous: false
          }
        },
        {
          name: 'work-filings',
          path: 'work-filings',
          component: () => { return import('@components/pages/work/Filings'); },
          meta: {
            roleSecurity: [ CONST.ROLE_FILER ],
            refreshEnabled: true
          }
        },
        {
          name: 'work-services',
          path: 'work-services',
          component: () => { return import('@components/pages/work/Services'); },
          meta: {
            refreshEnabled: true
          }
        },
        {
          name: 'work-service',
          path: 'work-service/:serviceID',
          props: true,
          component: () => { return import('@components/pages/work/Service'); }
        },
        {
          name: 'work-notifications',
          path: 'work-notifications',
          component: () => { return import('@components/pages/work/Notifications'); },
          meta: {
            refreshEnabled: true
          }
        },
        {
          name: 'work-cases',
          path: 'work-cases',
          component: () => { return import('@components/pages/work/MyCases'); },
          meta: {
            refreshEnabled: true
          }
        },
        {
          name: 'work-hearings',
          path: 'work-hearings',
          component: () => { return import('@components/pages/work/MyHearings'); },
          meta: {
            refreshEnabled: true
          }
        },
        {
          name: 'work-tasks',
          path: 'work-tasks',
          component: () => { return import('@components/pages/work/Tasks'); },
          meta: {
            roleSecurity: [ CONST.ROLE_INTERPRETER ],
            refreshEnabled: true
          }
        },
        {
          name: 'work-task',
          path: 'work-task/:taskID',
          props: true,
          component: () => { return import('@components/pages/work/Task'); },
          meta: {
            roleSecurity: [ CONST.ROLE_INTERPRETER ],
            refreshEnabled: true
          }
        },
        {
          name: 'work-invites',
          path: 'work-invites',
          component: () => { return import('@components/pages/work/Invites'); },
          meta: {
            roleSecurity: [ CONST.ROLE_INTERPRETER ],
            refreshEnabled: true
          }
        },
        {
          name: 'work-invite',
          path: 'work-invite/:eventID/type/:typeValue/subtype/:subtypeID',
          props: true,
          component: () => { return import('@components/pages/work/Invite'); },
          meta: {
            roleSecurity: [ CONST.ROLE_INTERPRETER ],
            refreshEnabled: true
          }
        },
        {
          name: 'work-receipts',
          path: 'work-receipts',
          component: () => { return import('@components/pages/work/Receipts'); },
          meta: {
            refreshEnabled: true
          }
        },
        {
          name: 'work-purchased-documents',
          path: 'work-purchased-documents',
          component: () => { return import('@components/pages/work/PurchasedDocuments'); },
          meta: {
            refreshEnabled: true
          }
        },
        {
          name: 'manage-filing',
          path: 'manage-filing/:filingID',
          props: true,
          component: () => { return import('@components/pages/ManageFiling'); },
          meta: {
            roleSecurity: [ CONST.ROLE_ADMINISTRATOR, CONST.ROLE_FILER ],
            roleOperator: CONST.ROLE_OPERATOR_OR
          }
        },
        {
          name: 'my-organization',
          path: 'my-organization',
          component: () => { return import('@components/pages/MyOrganization'); },
          meta: {
            roleSecurity: [ CONST.ROLE_ORGANIZATION_ADMINISTRATOR ]
          }
        },
        {
          name: 'manage-org',
          path: 'manage-org/:organizationID',
          redirect: { name: 'manage-org-details' },
          props: true,
          component: () => { return import('@components/pages/ManageOrg'); },
          meta: {
            roleSecurity: [ CONST.ROLE_ADMINISTRATOR, CONST.ROLE_ORGANIZATION_ADMINISTRATOR ],
            roleOperator: CONST.ROLE_OPERATOR_OR
          },
          children: [
            {
              name: 'manage-org-details',
              path: 'details',
              props: true,
              component: () => { return import('@components/support/manageOrg/OrgDetails'); }
            },
            {
              name: 'manage-org-payment-method',
              path: 'payment-method',
              props: true,
              component: () => { return import('@components/support/manageOrg/OrgPaymentMethod'); }
            },
            {
              name: 'manage-org-subscription',
              path: 'subscription',
              props: true,
              component: () => { return import('@components/support/manageOrg/OrgSubscription'); }
            },
            {
              name: 'manage-org-document-access',
              path: 'document-access',
              props: true,
              component: () => { return import('@components/support/manageOrg/OrgDocumentAccess'); }
            },
            {
              name: 'manage-org-users',
              path: 'users',
              props: true,
              component: () => { return import('@components/support/manageOrg/OrgUsers'); }
            },
            {
              name: 'manage-org-receipts',
              path: 'receipts',
              props: true,
              component: () => { return import('@components/support/manageOrg/OrgReceipts'); }
            }
          ]
        },
        {
          name: 'admin-filings',
          path: 'admin-filings',
          component: () => { return import('@components/pages/admin/Filings'); },
          meta: {
            roleSecurity: [ CONST.ROLE_ADMINISTRATOR ],
            refreshEnabled: true
          }
        },
        {
          name: 'admin-search-user',
          path: 'admin-search-user',
          component: () => { return import('@components/pages/admin/UserSearch'); },
          meta: {
            roleSecurity: [ CONST.ROLE_ADMINISTRATOR ],
            refreshEnabled: true
          }
        },
        {
          name: 'admin-user-account',
          path: 'admin-user-account/:userID',
          redirect: { name: 'admin-user-login-account' },
          component: () => { return import('@components/pages/admin/UserAccount'); },
          props: true,
          meta: {
            roleSecurity: [ CONST.ROLE_ADMINISTRATOR ]
          },
          children: [
            {
              name: 'admin-user-login-account',
              path: 'account',
              props: true,
              component: () => { return import('@components/support/manageUser/LoginAccount'); }
            },
            {
              name: 'admin-user-preferences',
              path: 'preferences',
              props: true,
              component: () => { return import('@components/support/manageUser/Preferences'); }
            },
            {
              name: 'admin-user-address',
              path: 'address',
              props: true,
              component: () => { return import('@components/support/manageUser/Address'); }
            },
            {
              name: 'admin-user-contact',
              path: 'contact',
              props: true,
              component: () => { return import('@components/support/manageUser/Contact'); }
            },
            {
              name: 'admin-user-roles',
              path: 'roles',
              props: true,
              component: () => { return import('@components/support/manageUser/Roles'); }
            },
            {
              name: 'admin-user-document-access',
              path: 'document-access',
              props: true,
              component: () => { return import('@components/support/manageUser/DocumentAccess'); }
            },
            {
              name: 'admin-user-org-details',
              path: 'org-details',
              props: true,
              component: () => { return import('@components/support/manageUser/OrgDetails'); }
            },
            {
              name: 'admin-user-payment-method',
              path: 'payment-method',
              props: true,
              component: () => { return import('@components/support/manageUser/PaymentMethod'); }
            },
            {
              name: 'admin-user-subscription',
              path: 'subscription',
              props: true,
              component: () => { return import('@components/support/manageUser/Subscription'); }
            }
          ]
        },
        {
          name: 'admin-subscriptions',
          path: 'admin-subscriptions',
          redirect: { name: 'admin-subscription-active' },
          component: () => { return import('@components/pages/admin/Subscriptions'); },
          meta: {
            roleSecurity: [ CONST.ROLE_ADMINISTRATOR ]
          },
          children: [
            {
              name: 'admin-subscription-active',
              path: 'active',
              component: () => { return import('@components/support/subscriptions/ActiveSubscriptions'); }
            },
            {
              name: 'admin-subscription-process',
              path: 'process',
              component: () => { return import('@components/support/subscriptions/ProcessSubscriptions'); }
            },
            {
              name: 'admin-subscription-history',
              path: 'history',
              component: () => { return import('@components/support/subscriptions/RenewalHistory'); }
            }
          ]
        },
        {
          name: 'admin-subscription-renewal',
          path: 'admin-subscription-renewal/:subscriptionRenewalID',
          component: () => { return import('@components/support/subscriptions/RenewalDetail'); },
          props: true,
          meta: {
            roleSecurity: [ CONST.ROLE_ADMINISTRATOR ]
          }
        },
        {
          name: 'admin-notifications',
          path: 'admin-notifications',
          component: () => { return import ('@components/pages/admin/Notifications'); },
          meta: {
            roleSecurity: [ CONST.ROLE_ADMINISTRATOR ],
            refreshEnabled: true
          }
        },
        {
          name: 'admin-configuration',
          path: 'admin-configuration',
          component: () => { return import ('@components/pages/admin/Configuration'); },
          meta: {
            roleSecurity: [ CONST.ROLE_ADMINISTRATOR ],
            refreshEnabled: true
          }
        },
        {
          name: 'admin-kiosk-mode',
          path: 'admin-kiosk-mode',
          component: () => { return import ('@components/pages/admin/KioskMode'); },
          meta: {
            roleSecurity: [ CONST.ROLE_ADMINISTRATOR ],
            refreshEnabled: true
          }
        },
        {
          name: 'admin-message-errors',
          path: 'admin-message-errors',
          component: () => { return import ('@components/pages/admin/MessageErrors'); },
          meta: {
            roleSecurity: [ CONST.ROLE_ADMINISTRATOR ],
            refreshEnabled: true
          }
        },
        {
          name: 'admin-receipts',
          path: 'admin-receipts',
          component: () => { return import ('@components/pages/admin/Receipts'); },
          meta: {
            roleSecurity: [ CONST.ROLE_ADMINISTRATOR ],
            refreshEnabled: true
          }
        },
        {
          name: 'admin-organizations',
          path: 'admin-organizations',
          component: () => { return import ('@components/pages/admin/Organizations'); },
          meta: {
            roleSecurity: [ CONST.ROLE_ADMINISTRATOR ],
            refreshEnabled: true
          }
        },
        {
          name: 'simulate-paymentprocessor',
          path: 'simulate-paymentprocessor/receipt/:receiptUUID/processorExternalIdentifier/:processorExternalIdentifier',
          props: true,
          component: () => { return import ('@components/pages/simulate/PaymentProcessor'); },
          meta: {
            refreshEnabled: true
          }
        }
      ]
    },
    {
      name: 'init-error',
      path: '/error/initialization',
      component: () => { return import('@components/pages/error/ErrorInit'); },
      meta: { anonymous: true }
    },
    {
      name: 'error-unauth',
      path: '/error/unauthorized',
      component: () => { return import('@components/pages/error/ErrorUnauthorized'); },
      props: true,
      meta: { anonymous: true }
    },
    {
      name: 'error-404',
      path: '*',
      component: () => { return import('@components/pages/error/Error404'); },
      meta: { anonymous: true }
    }
  ]
});

/**
 * Tests if the user is routing to and from the same component. This occurs when the route is pushed/updated on the current page, e.g.
 * when paging search results and we update the query parameters in the URL.
 *
 * @param {Object} to the destination route
 * @param {Object} from the previous route
 */
function isSameRouteComponent (to, from)
{
  return to.name === from.name;
}

/**
 * Router guard to clear any validation errors on a navigation event.
 */
router.beforeEach((to, from, next) => {
  store.commit('cove/errors/clear');
  next();
});

/**
 * Router guard making a session heartbeat each time the user performs a navigation event.
 */
router.beforeEach((to, from, next) => {
  store.commit('user/updatePollingHeartbeat');
  next();
});

/**
 * Router guard that halts application navigation from occuring until the application has been initialized.
 */
const removeInitGuard = router.beforeEach((to, from, next) => {
  if (store.state.application.initializationStatus === CONST.INITIALIZATION_STATUS_PENDING)
  {
    let unwatch = store.watch(
      (state) => {
        return state.application.initializationStatus;
      },
      (newValue) => {
        if (newValue === CONST.INITIALIZATION_STATUS_INITIALIZED)
        {
          next();
        }
        else if (newValue === CONST.INITIALIZATION_STATUS_ERRORED)
        {
          next({ name: 'init-error' });
        }

        unwatch();
      }
    );
  }
  else
  {
    next();
  }

  removeInitGuard();
});

/**
 * Router guard that checks if we should be forwarding the user to a different page, e.g. restoring the page they were on after they
 * login.
 */
const removeNextURLGuard = router.beforeEach((to, from, next) => {
  let nextURL = getLocalStorageValue(CONST.BROWSER_STORAGE_NEXT_URL, true);
  if (!_.isNil(nextURL))
  {
    next(nextURL);
  }
  else
  {
    next();
  }

  removeNextURLGuard();
});

/**
 * Router guard that checks the route and determines if it supports anonymous access or not. If it does not the guard will ensure that the
 * user is logged in, otherwise they will be forwarded to the login page.
 */
router.beforeEach((to, from, next) => {
  let proceed = to.matched.every((route) => {
    let anonymous = _.defaultTo(route.meta.anonymous, false);
    return anonymous ? true : store.state.user.hasSession;
  });

  if (proceed)
  {
    next();
  }
  else
  {
    redirectToLogin({ nextPath: to.fullPath });
  }
});

/**
 * Router guard that checks the route definition for a required security role and if found enforces the user has access to it.
 */
router.beforeEach((to, from, next) => {
  // checks each part of the route definition in the event of nested security checks
  let proceed = to.matched.every((route) => {
    let rs = route.meta.roleSecurity;
    let op = _.defaultTo(route.meta.roleOperator, CONST.ROLE_OPERATOR_AND);
    let hasAccess = false;
    if (_.isArray(rs)) {
      if (op === CONST.ROLE_OPERATOR_OR) {
        hasAccess = _.some(rs, (r) => {return store.getters['user/hasRole'](r);});
      } else {
        hasAccess = _.every(rs, (r) => {return store.getters['user/hasRole'](r);});
      }
    } else if (_.isNil(rs)) {
      // no security set
      hasAccess = true;
    }
    return hasAccess;
  });

  if (proceed)
  {
    next();
  }
  else
  {
    next({ name: 'error-unauth', params: { resource: to.fullPath } });
  }
});

/**
 * Caches the previous route when transferring to a brand new component, i.e. ignores any route updates/pushes to the current component.
 *
 * Also, resets the scroll position. It's reset manually, rather than by the 'scrollPosition' main router config, since that updates
 * immediately and can be jarring when scrolled down a page. This ensure we don't reset the position until the page has transitioned.
 */
router.afterEach((to, from) => {
  if (!isSameRouteComponent(to, from))
  {
    store.commit('application/setPreviousRoute', from);

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 333); // timing should be equivalent to main router-view transition speed (scroll-x-reverse-transition)
  }
});

/**
 * Wraps the router's push method so it has the ability to force refresh/recreate the current view if the route is properly configured for
 * this behavior, i.e. by using the `refreshEnabled` meta attribute. This should primarily be used by the main menu navigation as those are
 * the links that are always visible and any one of them may represent the page you are currently on.
 */
(() => {
  const originalPush = router.push;
  router.push = function () {
    let currentRouteName = router.currentRoute.name;
    let nextRoute = router.resolve(arguments[0]).route;

    if (nextRoute.name === currentRouteName && nextRoute.meta.refreshEnabled)
    {
      replaceIgnoreDuplicate(arguments); // use replace here instead of push since we don't want a new history entry created
      store.commit('cove/errors/clear');
      store.commit('application/refreshView');
      return Promise.resolve();
    }
    else
    {
      return originalPush.apply(router, arguments);
    }
  };
})();

export default router;