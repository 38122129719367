import * as core from '@/application/caseView-core';

let custom = {};
try {
  let empty = ''; // suppresses needless webpack warnings since it's less strict with dynamic paths
  custom = require(`@/application/caseView-custom${empty}`);
} catch (err) {
  // do nothing
}

let caseView = Object.assign({}, core, custom);

export default caseView;