<template>
  <!--
    use a transition element if this tab is visible, otherwise if just use a div and avoid the transition.
    this is important so that tabs opened in the background, eg middle clicking a link, will have their component load and set the tab title
    even before they are activated. this is due to CSS transitions requiring visibility before they run -- if this transition is held up the
    component won't load and the title won't be set until it's activated/visible.
  -->
  <component :is="isTabVisible ? 'transition' : 'div'" v-bind="isTabVisible ? transitionProps : null">
    <div v-if="isInitialized" key="main">
      <transition name="fade-transition" mode="out-in"> <!-- this transition is for moving from error pages, eg 404, back into the app -->
        <router-view/>
      </transition>
    </div>
    <v-app v-else key="loading">
      <div class="mx-auto my-auto">
        <v-progress-circular indeterminate color="accent" size="100" width="4"/>
      </div>
    </v-app>
  </component>
</template>

<script>
export default {
  data () {
    return {
      transitionProps: {
        name: 'fade-transition',
        mode: 'out-in'
      }
    };
  },
  computed: {
    isInitialized () {
      return this.$store.getters['application/isInitialized'];
    },
    isTabVisible () {
      return document.visibilityState === 'visible';
    }
  }
};
</script>