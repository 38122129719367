<template>
  <div>
    <validation-error-panel :error-scope="errorScope"/>
    <loading-card :loading="loading">
      <template #default>
        <v-card-title>
          {{ $t('page.subscriptionMgmt.processSubs.title') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('page.subscriptionMgmt.processSubs.description') }}
        </v-card-subtitle>
        <v-card-text class="pa-0">
          <cutout-area v-if="isExecuting" class="pt-4 pb-10">
            {{ $t('page.subscriptionMgmt.processSubs.executing') }}
          </cutout-area>
          <v-simple-table v-else>
            <thead>
              <tr>
                <th>{{ $t('subscription.type') }}</th>
                <th>{{ $t('page.subscriptionMgmt.processSubs.activeSubs') }}</th>
                <th>{{ $t('page.subscriptionMgmt.processSubs.canceledSubs') }}</th>
                <th>{{ $t('subscription.activeSeats') }}</th>
                <th class="text-right">{{ $t('page.subscriptionMgmt.processSubs.toBeAssessed') }}</th>
                <th class="text-right">{{ $t('page.subscriptionMgmt.processSubs.toBePurchased') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item) in items" :key="item.subscriptionTypeID">
                <td>{{ item.subscriptionTypeDisplayName }}</td>
                <td>{{ item.activeSubscriptions | formatNumber }}</td>
                <td>{{ item.cancelledSubscriptions | formatNumber }}</td>
                <td>{{ item.activeSeats | formatNumber }}</td>
                <td class="text-right">{{ item.toBeAssessed | formatCurrency }}</td>
                <td class="text-right">{{ item.toBeProcessed | formatCurrency }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="font-weight-medium">{{ $t('page.subscriptionMgmt.processSubs.total') }}</td>
                <td class="font-weight-medium">{{ totals.activeSubscriptions | formatNumber }}</td>
                <td class="font-weight-medium">{{ totals.cancelledSubscriptions | formatNumber }}</td>
                <td class="font-weight-medium">{{ totals.activeSeats | formatNumber }}</td>
                <td class="font-weight-medium text-right">{{ totals.toBeAssessed | formatCurrency }}</td>
                <td class="font-weight-medium text-right">{{ totals.toBeProcessed | formatCurrency }}</td>
              </tr>
            </tfoot>
          </v-simple-table>
          <div v-if="!isExecuting" class="mt-8 mr-4 pb-4 text-right">
            <c-btn type="primary" @click="processAllSubscriptions">
              {{ $t('page.subscriptionMgmt.processSubs.processAllSubscriptions') }}
            </c-btn>
          </div>
        </v-card-text>
      </template>
    </loading-card>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import LoadingCard from '@components/support/LoadingCard';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import CutoutArea from '@components/support/CutoutArea';

export default {
  components: { LoadingCard, ValidationErrorPanel, CutoutArea },
  data () {
    return {
      loading: true,
      isExecuting: false,
      items: [],
      totals: {},
      errorScope: 'processSubscriptions'
    };
  },
  created () {
    this.loadData();
  },
  methods: {
    loadData () {
      this.loading = true;
      this.$store.commit('cove/errors/clear', this.errorScope);

      let promises = [];
      let url = this.$cove.getAPI({ name: 'api.subscription.renewal.executing' });
      promises.push(
        axios.get(url)
          .then((response) => {
            this.isExecuting = response.data;
          })
      );

      promises.push(
        axios.get(this.$cove.getAPI({
          name: 'api.subscriptions.management'
        })).then((response) => {
          // pop off the total into it's own object
          this.items = _.get(response, 'data', []);
          this.totals = _.last(this.items);
          this.items = _.dropRight(this.items);
        })
      );

      Promise.all(promises)
        .finally(() => {
          this.loading = false;
        });
    },
    processAllSubscriptions () {
      this.$store.commit('cove/errors/clear', this.errorScope);
      this.$cove.confirm({ message: this.$t('page.subscriptionMgmt.processSubs.confirmation') })
        .then((confirmed) => {
          if (confirmed)
          {
            this.$cove.block({ delay: 0 });
            axios.post(this.$cove.getAPI({ name: 'api.subscription.renewals' }), {}, { errorScope: this.errorScope })
              .then((response) => {
                this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
                this.$router.push({ name: 'admin-subscription-renewal', params: { subscriptionRenewalID: response.data.resourceID } });
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    }
  }
};
</script>