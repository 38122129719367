import PagingControls from '@components/support/PagingControls';
import { isTableSortClickEvent } from '@/application/utility';

export default {
  components: { PagingControls },
  props: {
    searchDTO: {
      type: Object
    },
    items: {
      type: Array
    },
    headers: {
      type: Array
    }
  },
  methods: {
    changePage (newPage) {
      this.$emit('changePage', newPage);
    },
    toggleDrawer () {
      this.$emit('toggleDrawer');
    },
    updateOptions (options) {
      if (isTableSortClickEvent())
      {
        let sortBy = options.sortBy[0];
        let sortDesc = options.sortDesc[0];
        this.$emit('changeSort', { sortBy, sortDesc });
      }
    },
    getCourtByExternalID (externalCourtID) {
      return this.$store.getters['application/getCourtByExternalID'](externalCourtID);
    }
  }
};