<template>
  <div class="d-flex align-center">
    <div class="section-separator" style="flex: 0 0 auto;">
      {{ title }}
    </div>
  </div>

</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  }
};
</script>