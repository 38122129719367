var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('loading-card',{attrs:{"loading":_vm.purchasedDocuments === null}},[_c('v-card-title',[_vm._t("title",function(){return [_vm._v(" "+_vm._s(_vm.$t('page.receiptPurchasedDocuments.title'))+" ")]})],2),_c('v-card-subtitle',{staticClass:"pb-2"},[_vm._t("subtitle",function(){return [_vm._v(" "+_vm._s(_vm.$t('page.receiptPurchasedDocuments.subtitle'))+" ")]})],2),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"mt-2",attrs:{"items":_vm.purchasedDocuments,"headers":_vm.headers,"item-key":"resourceID","hide-default-footer":"","disable-pagination":"","mobile-breakpoint":800},scopedSlots:_vm._u([{key:"item.caseNumber",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('router-link',{attrs:{"to":{ name: 'caseView', params: {
            courtID: item.court.resourceID,
            caseUUID: item.caseIdentifier }}}},[_vm._v(" "+_vm._s(item.caseNumber)+" ")])],1),_c('div',[_vm._v(" "+_vm._s(item.caseTitle)+" ")])]}},{key:"item.docketEntryFiledDate",fn:function(ref){
            var value = ref.value;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("formatTime")(value))+" ")])]}},{key:"item.pageCount",fn:function(ref){
            var value = ref.value;
return [(value > 0)?_c('div',[_vm._v(" "+_vm._s(value)+" ")]):_vm._e()]}},{key:"item.documentLinkIdentifier",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.openDocumentPopup(item)}}},[_c('span',{staticClass:"d-sr-only"},[_vm._v(" "+_vm._s(_vm.$t('application.button.viewDocument'))+" ")]),_c('v-icon',[_vm._v("mdi-file")])],1)]}}])})],1),_c('document-popup',{attrs:{"courtID":_vm.courtID,"caseInstanceUUID":_vm.caseInstanceUUID,"documentLinkUUID":_vm.documentLinkUUID},model:{value:(_vm.documentPopupVisible),callback:function ($$v) {_vm.documentPopupVisible=$$v},expression:"documentPopupVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }