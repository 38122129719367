<template>
  <c-dialog
    :value="value && !loading"
    width="500"
    close-key="cove.general.cancel"
    title-key="popup.addLanguage.title"
    @input="close">

    <v-container class="pt-8 pb-0 px-6">
      <v-row>
        <v-col cols="12" class="pt-0 pb-2">
          <validation-error-panel :error-scope="errorScope"/>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-2">
        <v-col cols="12">
          <c-autocomplete
            v-model="classification"
            :label="$t('popup.addLanguage.field.classification')"
            :items="classifications"
            item-text="interpreterClassificationValue"
            return-object
            :key="`${errorScope}:classification`"
            hide-details="auto"
            outlined
            required
            aria-describedby="ab2546ad-312e-48e9-9774-665cccdb6f05"/>
          <field-description id="ab2546ad-312e-48e9-9774-665cccdb6f05"
                             message-key="popup.addLanguage.field.classification.description"/>
        </v-col>
        <v-col cols="12">
          <c-autocomplete
            v-model="language"
            :label="$t('popup.addLanguage.field.language')"
            :items="languages"
            item-text="languageName"
            return-object
            :key="`${errorScope}:language`"
            hide-details="auto"
            outlined
            required
            aria-describedby="a1c1e015-7fe4-4d71-9086-95070db3fd77"/>
          <field-description id="a1c1e015-7fe4-4d71-9086-95070db3fd77"
                             message-key="popup.addLanguage.field.language.description"/>
        </v-col>
        <v-col cols="12">
          <c-text-field
            v-model="certificationNumber"
            :label="$t('popup.addLanguage.field.certificationNumber')"
            :key="`${errorScope}:certificationNumber`"
            maxlength="100"
            hide-details="auto"
            outlined
            aria-describedby="ab43c131-fb70-4ca8-b5bc-7ad81649200e"/>
          <field-description id="ab43c131-fb70-4ca8-b5bc-7ad81649200e"
                             message-key="popup.addLanguage.field.certificationNumber.description"/>
        </v-col>
      </v-row>
    </v-container>
    <template #actions-right>
      <c-btn type="primary" @click="save">
        {{ $t('global.add') }}
      </c-btn>
    </template>
  </c-dialog>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import FieldDescription from '@components/support/FieldDescription';

export default {
  components: { ValidationErrorPanel, FieldDescription },
  props: {
    value: {
      type: Boolean
    }
  },
  data () {
    return {
      loading: true,
      language: null,
      classification: null,
      certificationNumber: null,
      languages: [],
      classifications: [],
      errorScope: 'languagePopup'
    };
  },
  watch: {
    value (newValue) {
      if (newValue && this.loading)
      {
        this.$cove.block();
        Promise.all([this.loadLanguages(), this.loadClassifications()])
          .finally(() => {
            this.loading = false;
            this.$cove.unblock();
          });
      }

      if (!newValue)
      {
        this.$store.commit('cove/errors/clear', this.errorScope);
        this.reset();
      }
    }
  },
  methods: {
    validate () {
      this.$store.commit('cove/errors/clear', this.errorScope);

      this.$cove.validation.required(this.classification, 'popup.addLanguage.field.classification', `${this.errorScope}:classification`);
      this.$cove.validation.required(this.language, 'popup.addLanguage.field.language', `${this.errorScope}:language`);
      this.$cove.validation.string.maxLength(this.certificationNumber, 100, 'popup.addLanguage.field.certificationNumber',
        `${this.errorScope}:certificationNumber`);

      return !this.$store.getters['cove/errors/hasErrors'](this.errorScope);
    },
    save () {
      let isValid = this.validate();
      if (isValid)
      {
        this.$emit('addLanguage', {
          id: _.uniqueId(),
          classificationIdentifier: this.classification.interpreterClassificationID,
          classification: this.classification.interpreterClassificationValue,
          languageIdentifier: this.language.languageID,
          language: this.language.languageName,
          certificationNumber: this.certificationNumber
        });

        this.close();
      }
    },
    loadLanguages () {
      return axios.get(this.$cove.getAPI({ name: 'api.interpreter.languages' }))
        .then((response) => {
          this.languages = response.data;
        });
    },
    loadClassifications () {
      return axios.get(this.$cove.getAPI({ name: 'api.interpreter.classifications' }))
        .then((response) => {
          this.classifications = response.data;
        });
    },
    close () {
      this.$emit('input', false);
    },
    reset () {
      this.classification = null;
      this.language = null;
      this.certificationNumber = null;
    }
  }
};
</script>
