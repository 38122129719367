<template>
  <v-data-table
    :items="items"
    :headers="headers"
    :sort-by="sort.sortBy"
    :sort-desc="sort.sortDesc"
    :server-items-length="page.totalElements"
    :loading="loading"
    item-key="searchResultKey"
    must-sort
    hide-default-footer
    class="elevation-2"
    :mobile-breakpoint="800"
    @update:options="updateOptions">
    <template #top>
      <v-alert
        :value="readOnly && page.totalElements > CONST.CASE_VIEW_PRINTABLE_MAX_TABLE_RESULTS"
        class="mb-1 rounded-b-0"
        icon="mdi-alert-box"
        color="warning"
        text>
        <div class="font-weight-medium" style="font-size:1.1rem;line-height:1.5">
          {{ $t('global.warning') }}
        </div>
        <div class="mt-2" style="max-width:120ch">
          {{ $t('page.caseView.tab.printableLimitWarning', [CONST.CASE_VIEW_PRINTABLE_MAX_TABLE_RESULTS]) }}
        </div>
      </v-alert>

      <div class="d-flex align-center pt-3 px-4" style="min-height:44px">
        <div class="c-caption" style="flex:1 1 auto">
          {{ $t('page.caseView.tab.parties') }}
        </div>
        <div v-if="!readOnly" class="text-right d-print-none" style="flex:1 1 auto">
          <v-btn icon color="primary" @click="toggleDrawer">
            <v-icon>
              mdi-sort
            </v-icon>
            <span class="d-sr-only">
              {{ $t('global.sort') }}
            </span>
          </v-btn>
        </div>
      </div>
    </template>
    <template #item.legalRepresentations="{ item }">
      <div v-for="name in renderLegalRepresentations(item)" :key="name">
        {{ name }}
      </div>
    </template>
    <template #item.address.formattedAddress="{ value }">
      <render-address :formattedAddress="value"/>
    </template>
    <template #item.birthDate="{ value }">
      {{ value | formatDate }}
    </template>
    <template #footer>
      <paging-controls :page="page" @changePage="changePage" :read-only="readOnly"/>
    </template>
  </v-data-table>
</template>

<script>
import RenderAddress from '@/components/support/RenderAddress';
import CaseViewTabMixin from '@/mixins/caseViewTab';
import _ from 'lodash';

export default {
  mixins: [ CaseViewTabMixin ],
  components: { RenderAddress },
  methods: {
    renderLegalRepresentations (item) {
      let result = [];

      if (item.proSeFlag === true)
      {
        result.push(this.$t('global.selfRep'));
      }
      else
      {
        _.orderBy(item.legalRepresentations, 'primaryFlag', 'desc').map((rep) => {
          if (!_.isNil(rep.attorneyPartyHeader))
          {
            result.push(rep.attorneyPartyHeader.partyActorInstance.sortName);
          }
          else if (!_.isNil(rep.legalOrganizationPartyHeader))
          {
            result.push(rep.legalOrganizationPartyHeader.partyActorInstance.sortName);
          }
        });
      }

      return result;
    }
  }
};
</script>