<template>
  <page-wrapper :title="$t('page.adminNotificationSearch.title')">

    <!-- need a custom error scope so validation errors are not shared between this page and the Filter panel which has no scope -->
    <validation-error-panel :error-scope="errorScope"/>

    <search-result-template :config="config" ref="searchResultTemplate">

      <template #table-title>
        {{ $t('page.adminNotificationSearch.table') }}
      </template>

      <template #v-data-table.item.createdDate="{ value }">
        <div style="min-width:100px">
          {{ value | formatDateTime }}
        </div>
      </template>
      <template #v-data-table.item.recipientSortName="{ item }">
        <div style="min-width:100px">
          {{ item.recipientDisplayName }}
        </div>
      </template>
      <template #v-data-table.item.caseNumber="{ item }">
        <div v-if="item.caseNumber" style="white-space: nowrap">
          <router-link :to="{ name: 'caseView', params: { courtID: item.courtID, caseUUID: item.caseIdentifier } }">
            {{ item.caseNumber }}
          </router-link>
        </div>
      </template>
      <template #v-data-table.item.notificationID="{ item }">
        <button class="link-button" @click="openNotificationPopup(item)">
          {{ $t('global.view') }}
        </button>
      </template>

      <template #nav-drawer-filter-body>
        <v-row no-gutters class="mt-4">
          <v-col cols="12">
            <date-range-picker
              :label="$t('page.searchCriteria.notification.field.date')"
              :labelStart="$t('page.searchCriteria.notification.field.dateFrom')"
              :labelEnd="$t('page.searchCriteria.notification.field.dateTo')"
              keyPrefix="createdDate"
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.dateChoice"
              :startDate.sync="searchDTO.dateStart"
              :endDate.sync="searchDTO.dateEnd"
              aria-describedby="659a4d78-b7ed-412b-8a6f-51466b96e73f"/>
            <field-description id="659a4d78-b7ed-412b-8a6f-51466b96e73f"
                               message-key="page.searchCriteria.notification.field.date.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.caseNumber"
              :label="$t('page.searchCriteria.notification.field.caseNumber')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="c03e59d5-7d7b-444c-9b35-96b0affedfd8"/>
            <field-description id="c03e59d5-7d7b-444c-9b35-96b0affedfd8"
                               message-key="page.searchCriteria.notification.field.caseNumber.description"/>
          </v-col>
          <v-col cols="12">
            <c-autocomplete
              :items="notificationTypes"
              v-model="searchDTO.notificationTypeID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('page.searchCriteria.notification.field.notificationType')"
              outlined
              hide-details="auto"
              aria-describedby="ed67ae99-7dd9-458b-a53a-0aa940caa303"/>
            <field-description id="ed67ae99-7dd9-458b-a53a-0aa940caa303"
                               message-key="page.searchCriteria.notification.field.notificationType.description"/>
          </v-col>
          <v-col cols="12">
            <c-autocomplete
              :items="notificationMethods"
              v-model="searchDTO.notificationMethodID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('page.searchCriteria.notification.field.notificationMethod')"
              outlined
              hide-details="auto"
              aria-describedby="2dfe8fe4-0a8d-438e-82f4-9c169f5fc322"/>
            <field-description id="2dfe8fe4-0a8d-438e-82f4-9c169f5fc322"
                               message-key="page.searchCriteria.notification.field.notificationMethod.description"/>
          </v-col>

          <v-col cols="12">
            <c-autocomplete
              :items="notificationStatuses"
              v-model="searchDTO.notificationStatusID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('page.searchCriteria.notification.field.status')"
              outlined
              hide-details="auto"
              aria-describedby="7b2fff4a-551b-431c-85c1-4d854a87b160"/>
            <field-description id="7b2fff4a-551b-431c-85c1-4d854a87b160"
                               message-key="page.searchCriteria.notification.field.status.description"/>
          </v-col>

          <v-col cols="12">
            <c-autocomplete
              v-model="searchDTO.recipientUserID"
              :items="users"
              :label="$t('page.searchCriteria.notification.field.recipient')"
              :loading="userLoading"
              :no-data-text="$t('global.typeahead.search.noDataText')"
              :menu-props="{ 'max-width': 350, contentClass: 'wrapped-dropdown' }"
              no-filter
              outlined
              key="userSearch"
              item-text="sortName"
              item-value="resourceID"
              :search-input.sync="userSearch"
              @blur="() => { if (!searchDTO.recipientUserID) { users = []; } }"
              hide-details="auto"
              aria-describedby="5a96d141-5981-45f7-92a1-0d41328e5aa2">
              <template #item="{ item }">
                <v-list-item-content>
                  <div class="d-flex align-center">
                    <div class="break-word pr-2" style="flex: 0 0 50%">
                      {{ item.sortName }}
                    </div>
                    <div class="text-minimum-gray text-right break-word" style="flex: 0 0 50%">
                      {{ item.username }}
                    </div>
                  </div>
                </v-list-item-content>
              </template>
            </c-autocomplete>
            <field-description id="5a96d141-5981-45f7-92a1-0d41328e5aa2"
                               message-key="page.searchCriteria.notification.field.recipient.description"/>
          </v-col>
        </v-row>
      </template>

      <template #tableFooter>
        <div class="text-right pb-2 pr-2">
          <c-btn type="primary" @click="resendSelected">
            {{ $t('application.button.resend') }}
          </c-btn>
        </div>
      </template>
    </search-result-template>

    <notification-popup v-model="notificationPopupVisible" :notification="popupNotification" @reload="reload"/>
  </page-wrapper>
</template>

<script>

import SearchResultTemplate from '@components/support/template/SearchResultTemplate';
import SearchResultTemplateMixin from '@/mixins/searchResultTemplate';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { dateToEndOfDay, dateToStartOfDay } from '@/application/application';
import axios from 'axios';
import NotificationPopup from '@components/support/NotificationPopup';

export default {
  components: { NotificationPopup, SearchResultTemplate },
  mixins: [ SearchResultTemplateMixin ],
  data () {
    return {
      showSelect: true,
      searchDTO: {
        sort: {
          sortBy: 'createdDate',
          sortDesc: true
        },
        dateChoice: '-7d',
        dateStart: this.$cove.formatDate(DateTime.local().minus({ 'days': 7 })),
        dateEnd: this.$cove.formatDate(DateTime.local()),
        caseNumber: null,
        notificationTypeID: null,
        notificationMethodID: null,
        notificationStatusID: null,
        recipientUserID: null
      },
      notificationTypes: [],
      notificationMethods: [],
      notificationStatuses: [],
      users: [],
      userSearch: null,
      userLoading: false,
      errorScope: 'notificationsSearch',

      // for popup
      notificationPopupVisible: false,
      popupNotification: {}
    };
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.notification.types' }))
      .then((response) => {
        this.notificationTypes = _.defaultTo(response.data._embedded.results, []);
      });
    axios.get(this.$cove.getAPI({ name: 'api.notification.methods' }))
      .then((response) => {
        this.notificationMethods = _.defaultTo(response.data._embedded.results, []);
      });
    axios.get(this.$cove.getAPI({ name: 'api.notification.statuses' }))
      .then((response) => {
        this.notificationStatuses = _.defaultTo(response.data._embedded.results, []);
      });
    // clear out the custom error scope when loading the screen since the router guard will handle only the filter validations
    this.$store.commit('cove/errors/clear', this.errorScope);
  },
  watch: {
    userSearch (newValue) {
      this.userDebounce(newValue);
    }
  },
  methods: {
    getItemKey () {
      return 'resourceID';
    },
    getRouteName () {
      return 'admin-notifications';
    },
    getSearchAPIName () {
      return 'api.notifications';
    },
    getHeaders () {
      let headers = [];

      headers.push({ text: this.$t('notification.createdDate'), value: 'createdDate', sortable: true });
      headers.push({ text: this.$t('notification.subject'), value: 'subject', sortable: true });
      headers.push({ text: this.$t('notification.method.short'), value: 'notificationMethodDisplayName', sortable: true });
      headers.push({ text: this.$t('notification.status.short'), value: 'notificationStatusDisplayName', sortable: true });
      headers.push({ text: this.$t('notification.recipient'), value: 'recipientSortName', sortable: true });
      headers.push({ text: this.$t('courtCase.caseNumber'), value: 'caseNumber', sortable: true });
      headers.push({ text: this.$t('page.searchResults.notification.viewLinkHeader'), value: 'notificationID', sortable: false });

      return headers;
    },
    addSearchCriteria (query) {

      if (!_.isNil(this.searchDTO.sort.sortBy))
      {
        query.sort = `${this.searchDTO.sort.sortBy},${this.searchDTO.sort.sortDesc ? 'desc' : 'asc'}`;
      }

      if (!_.isNil(this.searchDTO.dateStart))
      {
        query.dateFrom = dateToStartOfDay(this.$cove.parseDate(this.searchDTO.dateStart)).toISO();
      }

      if (!_.isNil(this.searchDTO.dateEnd))
      {
        query.dateTo = dateToEndOfDay(this.$cove.parseDate(this.searchDTO.dateEnd)).toISO();
      }

      if (!_.isNil(this.searchDTO.caseNumber))
      {
        query.caseNumber = this.searchDTO.caseNumber;
      }

      if (!_.isNil(this.searchDTO.notificationTypeID))
      {
        query.notificationTypeID = this.searchDTO.notificationTypeID;
      }

      if (!_.isNil(this.searchDTO.notificationMethodID))
      {
        query.notificationMethodID = this.searchDTO.notificationMethodID;
      }

      if (!_.isNil(this.searchDTO.notificationStatusID))
      {
        query.notificationStatusID = this.searchDTO.notificationStatusID;
      }

      if (!_.isNil(this.searchDTO.recipientUserID))
      {
        query.recipientUserID = this.searchDTO.recipientUserID;
      }

      return query;
    },
    validateSearchCriteria (searchDTO)
    {
      this.$cove.validation.date.rejectIfInvalid(searchDTO.dateStart, 'page.searchCriteria.notification.field.dateFrom',
        'createdDate-startDate');
      this.$cove.validation.date.rejectIfInvalid(searchDTO.dateEnd, 'page.searchCriteria.notification.field.dateTo',
        'createdDate-endDate');
      this.$cove.validation.date.rejectIfBefore(searchDTO.dateEnd, searchDTO.dateStart,
        'page.searchCriteria.notification.field.dateTo', 'page.searchCriteria.notification.field.dateFrom', 'createdDate-endDate');
    },
    userDebounce: _.debounce(function (newValue) { // do not use arrow function here
      if (!_.isNil(newValue) && newValue.trim().length > 0)
      {
        this.loadUsers();
      }
    }, 333),
    loadUsers () {
      let url = this.$cove.getAPI({
        name: 'api.users.namesearch',
        query: {
          name: this.userSearch.trim(),
          userSearch: true,
          size: this.CONST.PAGING_MAX_SIZE
        }
      });

      this.userLoading = true;
      axios.get(url)
        .then((response) => {
          this.users = _.get(response, 'data._embedded.results', []);
        })
        .finally(() => {
          this.userLoading = false;
        });
    },
    openNotificationPopup (notification) {
      this.popupNotification = notification;
      this.notificationPopupVisible = true;
    },
    resendSelected () {
      this.$cove.block({ delay: 0 });

      this.$store.commit('cove/errors/clear', this.errorScope);

      let isValid = this.validate();
      if (!isValid)
      {
        this.$cove.unblock();
      }
      else
      {
        let notificationIds = [];
        let notifications = this.selected;
        notifications.forEach((value, index) => {
          notificationIds.push(value.notificationUUID);
        });

        let url = this.$cove.getAPI({
          name: 'api.notifications.resend',
          query: {
            notificationIds
          }
        });

        axios.post(url, {}, { errorScope: this.errorScope })
          .then(() => {
            this.$cove.notify({ message: this.$t('notification.resend.success'), color: 'success' });
            this.reload();
          })
          .finally(() => {
            this.$cove.unblock();
          });
      }
    },
    validate () {
      if (this.selected.length === 0)
      {
        this.$cove.notify({ color: 'warning', message: this.$t('notification.resend.validation.noNotificationSelected') });
        return false;
      }
      let allNotificationsErrored = true;
      let notifications = this.selected;
      notifications.forEach((value, index) => {
        if (value.notificationStatusID !== this.CONST.NOTIFICATION_STATUS_ERROR)
        {
          allNotificationsErrored = false;
        }});

      if (!allNotificationsErrored)
      {
        this.$cove.notify({ color: 'warning', message: this.$t('notification.resend.validation.noError') });
      }
      return allNotificationsErrored;
    },
    reload () {
      this.clearSelected();
      this._reload();
    },
    clearSelected () {
      this.$refs.searchResultTemplate.clearSelected();
    }
  }
};
</script>
