<template>
  <loading-card :loading="loading">
    <v-card-title>
      {{ $t('page.manageFiling.party.stepContact.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.manageFiling.party.stepContact.description') }}
    </v-card-subtitle>
    <v-card-text class="pt-2">
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-md-2">
          <c-autocomplete
            v-model="bean.contactPhoneCountry"
            :label="$t('page.manageFiling.party.stepContact.field.phoneCountry')"
            :items="countryPhones"
            item-text="typeahead"
            return-object
            outlined
            hide-details
            :clearable="false"
            aria-describedby="f3242c61-4249-4aa2-a6e4-49013691ec85">
            <template #item="{ item }">
              <div style="flex:1 1 auto">
                {{ item.name }}
              </div>
              <div class="ml-4 text-minimum-gray" style="flex:0 0 auto">
                +{{ item.dialCode }}
              </div>
            </template>
          </c-autocomplete>
        </v-col>
        <div class="d-md-none" style="flex: 0 0 100%;margin-top:26px">
          <!-- placeholder when wrapped on small displays -->
        </div>
        <v-col cols="12" md="6" class="pl-md-2">
          <c-text-field
            v-model="bean.contactPhoneNumber"
            :label="$t('page.manageFiling.party.stepContact.field.phoneNumber')"
            maxlength="50"
            type="tel"
            key="contactPhoneNumber"
            outlined
            :required="isPhoneRequired"
            @change="formatContactPhoneNumber"
            hide-details
            aria-describedby="f3242c61-4249-4aa2-a6e4-49013691ec85"/>
        </v-col>
        <div>
          <field-description id="f3242c61-4249-4aa2-a6e4-49013691ec85"
                             message-key="page.manageFiling.party.stepContact.field.phone.description"/>
        </div>
        <v-col cols="12">
          <c-text-field
            v-model="bean.primaryEmail"
            :label="$t('page.manageFiling.party.stepContact.field.emailAddress')"
            maxlength="50"
            type="email"
            key="emailAddress"
            outlined
            :required="isEmailRequired"
            hide-details="auto"
            aria-describedby="f982ad87-e235-4c7d-912d-9e28adcb5148"/>
          <field-description id="f982ad87-e235-4c7d-912d-9e28adcb5148"
                             message-key="page.manageFiling.party.stepContact.field.emailAddress.description"/>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="mt-5">
      <div>
        <c-btn v-if="!editing && showSkipButton" @click="$emit('skip')">
          {{ $t('global.skipThisStep') }}
        </c-btn>
      </div>
      <v-spacer/>
      <div>
        <c-btn type="primary" @click="save">
          {{ editing ? $t('global.save') : $t('global.next') }}
        </c-btn>
        <c-btn v-if="!editing" @click="$emit('back')">
          {{ $t('global.back') }}
        </c-btn>
        <c-btn @click="$emit('cancel')">
          {{ $t('global.cancel') }}
        </c-btn>
      </div>
    </v-card-actions>
  </loading-card>
</template>

<script>
import CountryPhones from '@/application/countryPhone';
import { formatPhoneInput, isPhoneValid } from '@/application/utility';
import WorkflowStepMixin from '@/mixins/workflowStep';
import Filing from '@/application/filing';

export default {
  mixins: [ WorkflowStepMixin ],
  props: {
    editing: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isPhoneRequired () {
      return Filing.getPartyFieldConfig().requirePhone;
    },
    isEmailRequired () {
      return Filing.getPartyFieldConfig().requireEmail;
    },
    showSkipButton () {
      return !this.isPhoneRequired && !this.isEmailRequired;
    },
    defaultCountry () {
      return this.$store.getters['application/getDefaultCountry'];
    },
    countryPhones () {
      return CountryPhones;
    },
    defaultPhoneCountry () {
      return this.$store.getters['application/getDefaultPhoneCountry'];
    }
  },
  methods: {
    loadData () {
      if (this.bean.contactPhoneCountry === null)
      {
        this.bean.contactPhoneCountry = this.defaultPhoneCountry;
      }
    },
    formatContactPhoneNumber () {
      this.bean.contactPhoneNumber = formatPhoneInput(this.bean.contactPhoneNumber, this.bean.contactPhoneCountry.iso);
    },
    validate () {
      this.$store.commit('cove/errors/clear');

      if (this.isPhoneRequired)
      {
        this.$cove.validation.required(this.bean.contactPhoneNumber, 'page.manageFiling.party.stepContact.field.phoneNumber',
          'contactPhoneNumber');
      }

      if (this.bean.contactPhoneNumber !== null && !isPhoneValid(this.bean.contactPhoneNumber, this.bean.contactPhoneCountry.iso))
      {
        this.$cove.validation.addError('contactPhoneNumber', 'page.currentUser.error.invalidPhone',
          [this.$t('page.manageFiling.party.stepContact.field.phoneNumber')]);
      }

      if (this.isEmailRequired)
      {
        this.$cove.validation.required(this.bean.primaryEmail, 'page.manageFiling.party.stepContact.field.emailAddress',
          'emailAddress');
      }

      if (this.bean.primaryEmail !== null && !this.CONST.REGEX_VALID_EMAIL.test(this.bean.primaryEmail))
      {
        this.$cove.validation.addError('emailAddress', 'page.currentUser.error.invalidEmail',
          [this.$t('page.manageFiling.party.stepContact.field.emailAddress')]);
      }

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      let isValid = this.validate();
      if (!isValid)
      {
        this.scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
      }
      else
      {
        this.$emit('save', this.bean);
      }
    }
  }
};
</script>