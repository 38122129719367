<template>
  <page-wrapper :title="$t('page.documentCart.title')">
    <v-fade-transition>
      <div v-if="hadStaleCartData">
        <v-alert
          :value="true"
          class="mb-6"
          rounded
          icon="mdi-alert-box"
          color="warning"
          outlined
          elevation="2"
          text>
          <div class="font-weight-medium" style="font-size:1.1rem;line-height:1.5">
            {{ $t('global.warning') }}
          </div>
          <div class="mt-2">
            <p>
              {{ $t('page.documentCart.warning.hadStaleCartData') }}
            </p>
            <p>
              {{ $t('page.documentCart.warning.hadStaleCartData.possibleCauses') }}
            </p>
          </div>
        </v-alert>
      </div>
    </v-fade-transition>
    <v-row>
      <v-col cols="12" md="9" xl="12">
        <!-- Top -->
        <v-row>
          <v-col cols="12">
            <documents-card :purchased-documents="purchasedDocuments"
                            :page="searchDTO.page"
                            @changeSort="onChangeSort"
                            @changePage="onChangePage"
                            @remove="onRemove"
                            @removeAll="onRemoveAll"/>
          </v-col>
        </v-row>
        <!-- Bottom -->
        <template v-if="purchasedDocuments.length > 0">
          <v-row>
            <v-col cols="6">
              <receipt-order-summary :receipt="previewReceipt">
                <template #title>
                  {{ $t('page.documentCart.receiptOrderSummary.title') }}
                </template>
                <template #subtitle>
                  {{ $t('page.documentCart.receiptOrderSummary.description') }}
                </template>
              </receipt-order-summary>
            </v-col>
            <v-col cols="6">
              <check-out-card :previewReceipt="previewReceipt"
                              @checkout="onCheckoutWithPaymentProcessor"/>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import PageMixin from '@/mixins/page';
import PageWrapper from '@components/support/PageWrapper';
import DocumentsCard from '@components/support/documentCart/DocumentsCard';
import CheckOutCard from '@components/support/documentCart/CheckOutCard';
import _ from 'lodash';
import axios from 'axios';
import { redirectNotFoundErrorHandler } from '@/application/ajax';
import ReceiptOrderSummary from '@components/support/accounting/ReceiptOrderSummary';

export default {
  components: { ReceiptOrderSummary, PageWrapper, DocumentsCard, CheckOutCard },
  mixins: [ PageMixin ],
  data () {
    return {
      searchDTO: {
        page:
                {
                  size: 25,
                  number: 0,
                  totalElements: 0,
                  totalPages: 0
                },
        sort:
                {
                  sortBy: null,
                  sortDesc: true
                },
        courtID: null
      },
      purchasedDocuments: [],
      previewReceipt: null,
      hadStaleCartData: false
    };
  },
  created () {
    this.load();
  },
  watch: {
    hadStaleCartData () {
      if (this.hadStaleCartData)
      {
        this.load();
      }
    }
  },
  methods: {
    load () {
      this.purchasedDocuments = [];
      this.previewReceipt = null;
      this.loadPurchaseDocuments();
      this.loadPreviewReceipt();
      this.$store.dispatch('user/poll');
    },
    loadPreviewReceipt () {
      axios.get(this.$cove.getAPI({ name: 'api.receipt.preview' }),
        { errorHandlers: { 404: redirectNotFoundErrorHandler } })
        .then((response) => {
          this.previewReceipt = response.data;
          this.hadStaleCartData = this.hadStaleCartData || this.previewReceipt.hadStaleCartData;
        });
    },
    loadPurchaseDocuments () {
      this.search();
    },
    search () {
      // update sorting and paging
      this.searchDTO.page.number = 0;
      this.loadSearchResults();
    },
    loadSearchResults () {
      let query = {
        page: this.searchDTO.page.number,
        size: this.searchDTO.page.size,
        pendingPurchase: true
      };

      query = this.addSearchCriteria(query);
      let url = this.$cove.getAPI({
        name: 'api.purchasedDocuments.search',
        query
      });

      this.$cove.block();

      return axios.get(url)
        .then((response) => {
          this.purchasedDocuments = _.get(response, 'data._embedded.results', []);
          this.searchDTO.page.number = response.data.page.number;
          this.searchDTO.page.totalPages = response.data.page.totalPages;
          this.searchDTO.page.totalElements = response.data.page.totalElements;
          this.scrollTo('html');
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    addSearchCriteria (query) {
      if (!_.isNil(this.searchDTO.sort.sortBy))
      {
        query.sort = `${this.searchDTO.sort.sortBy},${this.searchDTO.sort.sortDesc ? 'desc' : 'asc'}`;
      }

      query.userID = this.$store.state.user.userID;
      return query;
    },
    onChangeSort (sorting) {
      this.searchDTO.sort = sorting;
      this.searchDTO.page.number = 0;
      this.loadSearchResults();
    },
    onChangePage (newPage) {
      this.searchDTO.page.number = newPage;
      this.loadSearchResults();
    },
    onRemove (purchasedDocumentResourceID) {
      this.$cove.confirm({ message: this.$t('documentCart.removeDocument.confirmation') })
        .then((confirmed) => {
          if (confirmed)
          {
            this.$cove.block({ delay: 0 });

            // Clear the removed documents warning
            this.hadStaleCartData = false;

            axios.delete(this.$cove.getAPI({ name: 'api.purchasedDocument', params: { id: purchasedDocumentResourceID } }))
              .then(() => {
                this.load();
                this.$cove.notify({ message: this.$t('application.remove.success'), color: 'success' });
              })
              .finally( () => {
                this.$cove.unblock();
              });
          }
        });
    },
    onRemoveAll () {
      this.$cove.confirm({ message: this.$t('documentCart.removeAllDocuments.confirmation') })
        .then((confirmed) => {
          if (confirmed)
          {
            this.$cove.block({ delay: 0 });

            // Clear the removed documents warning
            this.hadStaleCartData = false;

            axios.delete(this.$cove.getAPI({ name: 'api.purchasedDocument.cart' }))
              .then(() => {
                this.load();
                this.$cove.notify({ message: this.$t('application.removeAll.success'), color: 'success' });
              })
              .finally( () => {
                this.$cove.unblock();
              });
          }
        });
    },
    onCheckoutWithPaymentProcessor () {
      this.$cove.confirm({ message: this.$t('documentCart.checkout.confirmation',
        [this.$cove.formatCurrency(this.previewReceipt.amount)]) })
        .then((confirmed) => {
          if (confirmed)
          {
            this.checkoutWithPaymentProcessor();
          }
        });
    },
    checkoutWithPaymentProcessor () {
      this.$cove.block({ delay: 0 });

      // Clear the removed documents warning
      this.hadStaleCartData = false;
      axios.post(this.$cove.getAPI({ name: 'api.receipt.checkout' }),
        { cartPreviewChecksum: this.previewReceipt.checksum })
        .then((response) => {
          if (response.data.hadStaleCartData)
          {
            // During the checkout process, the user either lost access to one or more of their documents or the
            // document access type changed to unavailable or free
            this.hadStaleCartData = this.hadStaleCartData || response.data.hadStaleCartData;
            this.$cove.unblock(); // can't consolidate unblock because it can't be called on the redirect
          }
          else
          {
            if (_.isNil(response.data.redirectLocation))
            {
              this.$cove.notify({ message: this.$t('documentCart.purchase.success'), color: 'success' });
              this.$router.push({ name: 'receipt', params: { receiptID: response.data.resourceID } });
              this.$store.dispatch('user/poll');
              this.$cove.unblock();
            }
            else
            {
              // redirect to payment processor
              window.location.href = response.data.redirectLocation;
            }
          }
        })
        .catch(() => {
          this.$cove.unblock();
        });
    }
  }
};
</script>