import PageWrapper from '@components/support/PageWrapper';
import PagingControls from '@components/support/PagingControls';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import DateRangePicker from '@components/support/DateRangePicker';
import FieldDescription from '@components/support/FieldDescription';
import PageMixin from '@/mixins/page';
import JSURL from 'jsurl';
import _ from 'lodash';
import axios from 'axios';

import { isTableSortClickEvent, replaceIgnoreDuplicate } from '@/application/utility';

export default {
  components: { PageWrapper, PagingControls, ValidationErrorPanel, DateRangePicker, FieldDescription },
  mixins: [ PageMixin ],
  data () {
    return {
      selected: [],
      searchDTO: {
        advanced: false,
        page: {
          size: 25,
          number: 0,
          totalElements: 0,
          totalPages: 0
        },
        sort: {
          sortBy: null,
          sortDesc: true
        },
        courtID: null
      },
      items: [],
      loading: false,
      backupSearchDTO: null,
      filter: {
        sortBy: null,
        sortDesc: null
      }
    };
  },
  computed: {
    config () {
      return {
        items: this.items,
        itemKey: this.getItemKey(),
        headers: this.getHeaders(),
        searchDTO: this.searchDTO,
        loading: this.loading,
        filter: this.filter,
        sortableColumns: this.sortableColumns,
        onVDataTableUpdate: this._onVDataTableUpdate,
        onVDataTableChangePage: this._changePage,
        onDrawerUpdate: this._onDrawerUpdate,
        onSelectedUpdate: this._onSelectedUpdate,
        onFilterSearch: this._search,
        showSelect: this.showSelect
      };
    },
    sortableColumns () {
      let filters = _.filter(this.getHeaders(), { sortable: true });
      return _.orderBy(filters, 'text');
    },
    courts () {
      return this.$store.state.application.courts;
    },
    isSingleCourtMode () {
      return this.$store.getters['application/isSingleCourtMode'];
    },
    isSingleCaseLocationMode () {
      return this.$store.getters['application/isSingleCaseLocationMode'];
    },
    queryTypes () {
      return _.orderBy([
        { text: this.$t('global.queryType.contains'), value: this.CONST.QUERY_TYPE_CONTAINS },
        { text: this.$t('global.queryType.exact'), value: this.CONST.QUERY_TYPE_EXACT },
        { text: this.$t('global.queryType.match'), value: this.CONST.QUERY_TYPE_MATCH },
        { text: this.$t('global.queryType.startsWith'), value: this.CONST.QUERY_TYPE_STARTS_WITH },
        { text: this.$t('global.queryType.phonetic'), value: this.CONST.QUERY_TYPE_PHONETIC_MATCH }
      ], 'text');
    },
    caseLocations () {
      let courtID = null;

      if (this.isSingleCourtMode)
      {
        courtID = this.courts[0].resourceID;
      }
      else
      {
        courtID = this.searchDTO.courtID;
      }

      return this.$store.getters['application/getCaseLocationsByCourt'](courtID);
    }
  },
  created () {
    _.merge(this.searchDTO, JSURL.parse(this.$route.query.criteria));
    this._loadSearchResults(false);
  },
  methods: {
    getCourtByExternalID (externalCourtID) {
      return this.$store.getters['application/getCourtByExternalID'](externalCourtID);
    },

    /* ------------------------------------- Must Implement Methods -------------------------------------------------*/
    /**
       * Returns the route name
       */
    getRouteName () {
      throw new Error('Missing "getRouteName" method in searchResultTemplate implementation!');
    },
    /**
       * Returns the search api name that should be used for the search endpoint
       */
    getSearchAPIName () {
      throw new Error('Missing "getSearchAPIName" method in searchResultTemplate implementation!');
    },
    /**
       * Returns the path parameters that should be sent to the api search endpoint
       */
    getSearchAPIParams () {
      // Optional
    },
    /**
     * Returns the v-data-table item-key property (item-key: The property on each item that is used as a unique key)
     */
    getItemKey () {
      throw new Error('Missing "getItemKey" method in searchResultTemplate implementation!');
    },
    getHeaders () {
      throw new Error('Missing "getHeaders" method in searchResultTemplate implementation!');
    },
    /**
       * Adds search criteria to the query that will get sent to the specified search API
       *
       * @param query The query object populated with default properties that should be modified with the search
       * criteria properties
       * @returns {*} The modified query object that contains all the search criteria properties that should be sent
       * to the search API endpoint
       **/
    addSearchCriteria (query) {
      return query;
    },
    /**
       * Performs validation on specific search criteria values that require it using cove
       *
       * @param searchDTO The object populated with the values for the search criteria that should be validated
       */
    validateSearchCriteria ( searchDTO )
    {
      throw new Error('Missing "validateSearchCriteria" method in searchResultTemplate implementation!');
    },

    /* ------------------------------------------- END ------------------------------------------------------------- */
    _search () {
      let isValid = this._validateSearchCriteria(this.searchDTO);
      if (isValid) {
        // update sorting and paging
        this.searchDTO.sort = { sortBy: this.filter.sortBy, sortDesc: this.filter.sortDesc };
        this.searchDTO.page.number = 0;

        this._loadSearchResults(true);

        this.backupSearchDTO = null;
      }
      else {
        this.scrollSidebarTo(this.CONST.ERROR_CONTAINER_SELECTOR);
      }
    },
    _reload () {
      this.searchDTO.page.number = 0;
      this._loadSearchResults(true);
    },
    _validateSearchCriteria (searchDTO)
    {
      this.$store.commit('cove/errors/clear');

      this.validateSearchCriteria(searchDTO);

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    _loadSearchResults (updateURL) {
      if (updateURL)
      {
        let criteria = this._generateQueryObject(this.searchDTO);
        replaceIgnoreDuplicate({ name: this.getRouteName(), query: { criteria } });
      }

      let query = {
        page: this.searchDTO.page.number,
        size: this.searchDTO.page.size
      };

      query = this.addSearchCriteria(query);
      let url = this.$cove.getAPI({
        name: this.getSearchAPIName(),
        query,
        params: this.getSearchAPIParams()
      });

      this.$cove.block();
      this.loading = true;

      return axios.get(url)
        .then((response) => {
          this.items = _.get(response, 'data._embedded.results', []);
          this.searchDTO.page.number = response.data.page.number;
          this.searchDTO.page.totalPages = response.data.page.totalPages;
          this.searchDTO.page.totalElements = response.data.page.totalElements;
          this.scrollTo('html');
        })
        .finally(() => {
          this.$cove.unblock();
          this.loading = false;
        });
    },
    _generateQueryObject () {
      let cloned = _.cloneDeep(this.searchDTO);
      cloned = _.omitBy(cloned, _.isNil);
      return JSURL.stringify(cloned);
    },
    _changePage (newPage) {
      this.searchDTO.page.number = newPage;
      this._loadSearchResults(true);
    },
    _onVDataTableUpdate (options) {
      if (isTableSortClickEvent())
      {
        let sortBy = options.sortBy[0];
        let sortDesc = options.sortDesc[0];
        this._changeSort({ sortBy, sortDesc });
      }
    },
    _changeSort (sorting) {
      this.searchDTO.sort = sorting;
      this.searchDTO.page.number = 0;
      this._loadSearchResults(true);
    },
    _onDrawerUpdate (newValue) {
      if (newValue)
      {
        this.filter.sortBy = this.searchDTO.sort.sortBy;
        this.filter.sortDesc = this.searchDTO.sort.sortDesc;
        this.backupSearchDTO = _.cloneDeep(this.searchDTO);

        setTimeout(() => {
          document.querySelector('.js-filter-sidebar h1').focus();
        }, 150);
      }
      else
      {
        this.$store.commit('cove/errors/clear');
        if (this.backupSearchDTO !== null)
        {
          this.searchDTO = this.backupSearchDTO;
        }
      }
    },
    _onSelectedUpdate (newValue) {
      this.selected = newValue;
    },
    _toggleAdvancedSearch () {
      this.searchDTO.advanced = true;
      this.scrollSidebarTo(this.CONST.FILTER_CONTAINER_SELECTOR);
    }
  }
};