<template>
  <loading-card :loading="receipt === null">
    <template #default>
      <v-card-title>
        <slot name="title">
          {{ $t('page.receiptOrderSummary.title') }}
        </slot>
      </v-card-title>
      <v-card-subtitle>
        <slot name="subtitle">
          {{ $t('page.receiptOrderSummary.subtitle') }}
        </slot>
      </v-card-subtitle>
      <v-card-text class="px-4">
        <receipt-order-line-items :receipt="receipt"/>
      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import LoadingCard from '@components/support/LoadingCard';
import ReceiptOrderLineItems from '@components/support/accounting/ReceiptOrderLineItems';

export default {
  components: { LoadingCard, ReceiptOrderLineItems },
  props: {
    receipt: {
      type: Object
    }
  }
};
</script>
