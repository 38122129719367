export default {
  APP_PROPERTY_LOGIN_URL: 'properties.loginURL',
  APP_PROPERTY_LOGOUT_URL: 'properties.logoutURL',
  APP_PROPERTY_ACCOUNT_URL: 'properties.accountURL',
  APP_PROPERTY_PAYMENT_CLIENT: 'properties.paymentClient',
  APP_PROPERTY_POLLING_INTERVAL: 'computedProperties.polling.interval',
  APP_PROPERTY_POLLING_TIME_TO_IDLE: 'computedProperties.polling.timeToIdle',
  APP_PROPERTY_SESSION_TIMEOUT: 'computedProperties.spring.session.timeout',
  APP_PROPERTY_SESSION_TIMEOUT_WARNING: 'computedProperties.spring.session.timeoutWarning',
  APP_PROPERTY_MAIL_ENABLED: 'properties.mail.enabled',
  APP_PROPERTY_SMS_ENABLED: 'properties.sms.enabled',

  APP_PROPERTY_SEARCH_RESULTS_LIMIT: 'constants.SEARCH_RESULTS_LIMIT',

  FEATURE_PROPERTY_ENABLE_DOCUMENT_PURCHASE_FUNCTIONS: 'featureProperties.enableDocumentPurchaseFunctions',
  FEATURE_PROPERTY_ENABLE_SUBSCRIPTION_FUNCTIONS: 'featureProperties.enableSubscriptionFunctions',

  BROADCAST_CHANNEL_PRIMARY: 'portal-primary-channel',
  BROADCAST_MESSAGE_LOGOUT: 'logout',
  BROADCAST_MESSAGE_KEEP_ALIVE: 'keepAlive',

  POLLING_INTERVAL_MINIMUM_MS: 10000, // Milliseconds

  DATE_RANGE_OPTION_CUSTOM: 'custom',

  ACTOR_CATEGORY_PERSON: '1',
  ACTOR_CATEGORY_ORGANIZATION: '2',

  PAGING_MAX_SIZE: 500,

  MAX_CONCURRENT_REQUESTS: 5,

  INITIALIZATION_STATUS_PENDING: 'pending',
  INITIALIZATION_STATUS_INITIALIZED: 'initialized',
  INITIALIZATION_STATUS_ERRORED: 'errored',

  FILING_ENTRY_MODE_MANUAL: 400019,
  FILING_ENTRY_MODE_GUIDED: 400020,
  FILING_ENTRY_MODE_ABBREVIATED: 400021,

  FILING_CODE_EXTERNAL_IDENTIFIER_MANUAL: '400019',
  FILING_CODE_EXTERNAL_IDENTIFIER_GUIDED: '400020',
  FILING_CODE_EXTERNAL_IDENTIFIER_ABBREVIATED: '400021',

  ERROR_CONTAINER_SELECTOR: '.js-validation-anchor',
  SCOPED_ERROR_CONTAINER_SELECTOR: '.js-scoped-errors',
  FILTER_CONTAINER_SELECTOR: '.js-criteria-anchor',

  ROLE_ADMINISTRATOR: 'ROLE_SYSTEM_ADMIN',
  ROLE_ATTORNEY: 'ROLE_ATTORNEY',
  ROLE_FILER: 'ROLE_FILER',
  ROLE_ANONYMOUS: 'ROLE_ANONYMOUS',
  ROLE_INTERPRETER: 'ROLE_INTERPRETER',
  ROLE_ORGANIZATION_ADMINISTRATOR: 'ROLE_ORGANIZATION_ADMINISTRATOR',

  ROLE_UUID_ADMINISTRATOR: '477439da-1348-40a6-8195-99aa6a86ae75',
  ROLE_UUID_ATTORNEY: 'dac1530f-e772-40d1-8281-bcd5b2d0bf60',
  ROLE_UUID_FILER: '95ecbb95-fc3c-4063-b981-28235753d797',
  ROLE_UUID_INTERPRETER: 'e2c1b7f1-bca8-4f5d-9fea-5804fb906a78',

  ROLE_OPERATOR_OR: 'OR',
  ROLE_OPERATOR_AND: 'AND',

  SUBSCRIPTION_STATUS_ACTIVE: 'bf49c3a9-ab06-4e7f-8802-09f81440221d',
  SUBSCRIPTION_STATUS_CANCELLED: '06544fc6-937f-4bfd-85b4-80843883da18',
  SUBSCRIPTION_STATUS_TERMINATED: '55350105-3951-45fe-b3d4-14615f5132dd',

  SUBSCRIPTION_RENEWAL_STATUS_PENDING: '21a02ddf-6af1-4c39-a4f1-2ab0a472b3aa',
  SUBSCRIPTION_RENEWAL_STATUS_PROCESSING: 'bd43e1ba-8ad0-42d4-ae0e-9cdfad1e09f1',

  USER_TYPE_ATTORNEY: 'b997cbf1-39cd-4da7-821d-1d26d3ee507c',
  USER_TYPE_INTERPRETER: 'bd3b3005-9596-4f52-8628-cc6a09818c48',
  USER_TYPE_PUBLIC: '8f46a20b-e22f-455e-bc4e-587d8e9ca5f7',
  USER_TYPE_ORGANIZATION_ADMIN: '64e14bea-9e35-439f-b3af-7c6c1bc4fd9e',

  USER_REQUEST_STATUS_PENDING: 'E1ECF1A2-D02A-11EB-B8BC-0242AC130003',
  USER_REQUEST_STATUS_ACCEPTED: '0F33BCC2-D02B-11EB-B8BC-0242AC130003',
  USER_REQUEST_STATUS_REJECTED: '29BBBC66-D02B-11EB-B8BC-0242AC130003',

  USER_DOCUMENT_STATE_VIEWABLE: 'b64d1d1e-f926-4c87-ab6b-df5c96d3b186',
  USER_DOCUMENT_STATE_IN_CART: 'e069220c-7377-4b6a-8664-15f29da6808a',
  USER_DOCUMENT_STATE_PURCHASABLE: 'dc195201-c792-4883-ab2a-5730dc357ca2',
  USER_DOCUMENT_STATE_UNAVAILABLE: 'ea5979e2-9949-4819-819b-72f9ef7f7106',

  COOKIE_LOCALE: 'locale',

  // via https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s01.html
  REGEX_VALID_EMAIL: /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/i,

  DOCUMENT_TYPE_ANNOUNCEMENT: 'Public Portal Announcement',
  DOCUMENT_TYPE_USER_REQUEST: 'Public Portal User Request',
  DOCUMENT_TYPE_TASK_RESPONSE: 'Public Portal Task Response',
  DOCUMENT_TYPE_FILING: 'Public Portal Filing',

  SYSTEM_TABLE_USER_REQUEST: 'd2d39206-21d0-eb11-b8bc-0242ac130003',
  SYSTEM_TABLE_ANNOUNCEMENT: '1e977401-e6f2-2349-935c-1f618f0dec66',
  SYSTEM_TABLE_DOCKET_ENTRY_DOCUMENT: '50f598f4-c604-ec11-9a03-0242ac130003',

  BROWSER_STORAGE_NEXT_URL: 'nextURL',
  BROWSER_STORAGE_HIDDEN_INSTR_FILING_IDS: 'hiddenInstrFilingIDs',
  BROWSER_STORAGE_HIDDEN_INSTR_MAX_LENGTH: 100,
  BROWSER_STORAGE_FILING_MAX_SEEN_STEP: 'maxSeenStep',
  BROWSER_STORAGE_FILING_MAX_SEEN_STEP_LENGTH: 100,
  BROWSER_STORAGE_LAST_AJAX_DATE: 'lastAjaxDateMillis',

  SEARCH_TYPE_CASE: 'case',
  SEARCH_TYPE_PARTY: 'party',
  SEARCH_TYPE_CITATION: 'citation',
  SEARCH_TYPE_JUDGMENT: 'judgment',
  SEARCH_TYPE_DOCUMENT: 'document',
  SEARCH_TYPE_CALENDAR: 'calendar',
  SEARCH_TYPE_PUBLICATION: 'publication',

  CASE_VIEW_TAB_DOCKET_ENTRIES: 'docketEntries',
  CASE_VIEW_TAB_PARTIES: 'parties',
  CASE_VIEW_TAB_CITATIONS: 'citations',
  CASE_VIEW_TAB_HEARINGS: 'hearings',
  CASE_VIEW_TAB_CHARGES: 'charges',
  CASE_VIEW_TAB_JUDGMENTS: 'judgments',
  CASE_VIEW_TAB_CLAIMS: 'claims',

  CASE_CLASS_GROUP_TYPE_CIVIL: 10001,
  CASE_CLASS_GROUP_TYPE_CRIMINAL: 10002,
  CASE_CLASS_GROUP_TYPE_JUV_DEL: 10003,
  CASE_CLASS_GROUP_TYPE_ADMIN: 10004,
  CASE_CLASS_GROUP_TYPE_ABUSE_NEGLECT: 10005,
  CASE_CLASS_GROUP_TYPE_TRAFFIC: 10006,
  CASE_CLASS_GROUP_TYPE_MARRIAGE: 10007,
  CASE_CLASS_GROUP_TYPE_APPEAL: 10008,
  CASE_CLASS_GROUP_TYPE_PARKING: 10009,

  INVOLVMENT_TYPE_FILING_PARTY: 10360,
  INVOLVMENT_TYPE_RESPONDING_PARTY: 10361,
  INVOLVMENT_TYPE_INVOLVED_PARTY: 10362,

  QUERY_TYPE_STARTS_WITH: 10461,
  QUERY_TYPE_EXACT: 10462,
  QUERY_TYPE_CONTAINS: 10463,
  QUERY_TYPE_MATCH: 300054,
  QUERY_TYPE_PHONETIC_MATCH: 300055,

  MIME_TYPE_EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

  CASE_VIEW_PRINTABLE_MAX_TABLE_RESULTS: 250,

  FILING_STEP_PARTIES: '5c7010c4-e3a2-482d-a9b4-f0bc20212ca8',
  FILING_STEP_FILING_INFO: '895136e3-b587-49c0-84f3-6d274cedb4a9',
  FILING_STEP_DOCKET_ENTRIES: 'e8175a4c-eb94-43a1-999b-3a2c36c38f85',
  FILING_STEP_SERVICE: '5edf0094-81de-4ca2-bd01-520c4a6100fa',
  FILING_STEP_SUMMARY: '094e1163-c31e-46d8-8553-da7af328a640',

  FILING_SERVICE_METHOD_ESERVICE: 'c7e08622-e761-4495-931f-757c272c0114',

  REP_TYPE_CURRENT_USER: 'current',
  REP_TYPE_PRO_SE: 'proSe',
  REP_TYPE_PRIVATE: 'private',
  REP_TYPE_UNKNOWN: 'unknown',

  REP_SELECTION_MODE_NEW: 'createNew',
  REP_SELECTION_MODE_ATTORNEY: 'existingAttorney',
  REP_SELECTION_MODE_LAW_FIRM: 'existingLawFirm',

  FILING_STATUS_DRAFT: '65768d68-0324-45d2-90e7-aa02fa549f0e',
  FILING_STATUS_SUBMITTED: '8153eab6-cc56-45ee-ad39-eda25a3a8454',
  FILING_STATUS_RECEIVED: '2094a4e9-e64e-4911-8946-738d259dd885',
  FILING_STATUS_APPROVED: 'c4c3b593-0c87-4e93-a451-d77c43692486',
  FILING_STATUS_REJECTED: 'f629fba5-aff8-4b32-9447-7937be353352',
  FILING_STATUS_ERRORED: 'c024673c-0d9d-4742-8386-27e53b5f8230',

  FILING_CASE_TYPE_NEW: 1,
  FILING_CASE_TYPE_EXISTING: 2,

  NOTIFICATION_METHOD_APPLICATION: '044b84c3-b9bc-48c7-9335-f0647077c0fe',
  NOTIFICATION_METHOD_EMAIL: '299ffec6-6172-456b-85ff-fe9fe294d71e',
  NOTIFICATION_METHOD_SMS: '886eeafb-f44a-4ac3-b75f-60dba2178792',
  NOTIFICATION_STATUS_ERROR: '82ed8880-b9af-4ba3-b01b-404925d568d9',
  NOTIFICATION_STATUS_SENT: '678e4d43-255c-4c76-8aef-272cbef87d8f',

  EVENT_ATTRIBUTE_STATUS_PENDING: 400000,
  EVENT_ATTRIBUTE_STATUS_ACCEPTED: 400001,

  EVENT_TYPE_EVENT: 'event',
  EVENT_TYPE_HEARING: 'hearing',

  RECEIPT_TYPE_PURCHASED_DOCUMENTS: '2699d2a1-5688-4d55-9da7-23a26b6adb31',

  SUBSCRIPTION_TYPE_PERSONAL: '5ff79047-b1d8-44f1-a585-ed55786156fa',
  SUBSCRIPTION_TYPE_ORGANIZATION: 'f977f71c-e4fd-443b-a3c2-fafda0fcd56f',
  SUBSCRIPTION_TYPE_PERSONAL_GRANTED: '20309d6f-1de8-4a04-ba63-f8acbec19cdf',
  SUBSCRIPTION_TYPE_ORGANIZATION_GRANTED: '3a3a1c2a-2442-4517-8cf9-9831bd85c2f0'

};