<template>
  <c-dialog
    :value="value && !loading"
    width="850"
    close-key="cove.general.close"
    title-key="popup.userRequests.title"
    @input="close">

    <v-data-table
      :items="userRequests"
      :headers="headers"
      :server-items-length="page.totalElements"
      :loading="loading"
      item-key="resourceID"
      disable-sort
      show-expand
      hide-default-footer
      class="my-1"
      :mobile-breakpoint="800">
      <template #footer>
        <paging-controls :page="page" @changePage="changePage"/>
      </template>
      <template #item.submissionDate="{ item }">
        {{ item.submissionDate | formatDateTime }}
      </template>
      <template #item.processedDate="{ value }">
        {{ value | formatDateTime }}
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="pb-2">
            <dl class="d-grid grid-4060">
              <dt>{{ $t('popup.userRequests.submissionNumber') }}</dt>
              <dd>{{ item.submissionNumber }}</dd>
              <template v-if="!isNil(item.clerkComments)">
                <dt>{{ $t('popup.userRequests.clerkComments') }}</dt>
                <dd>{{ item.clerkComments }}</dd>
              </template>
              <template v-if="item.userType.resourceID === CONST.USER_TYPE_PUBLIC">
                <dt>{{ $t('popup.userRequests.court') }}</dt>
                <dd>{{ item.court.displayName }}</dd>
                <dt>{{ $t('popup.userRequests.caseNumber') }}</dt>
                <dd>{{ item.caseNumber }}</dd>
              </template>
            </dl>
          </div>
        </td>
      </template>
      <template #item.data-table-expand="{ expand, isExpanded }">
        <v-btn color="primary" icon @click="expand(!isExpanded)">
          <v-icon class="rotating-icon" :class="{ active: isExpanded }">
            mdi-chevron-down
          </v-icon>
          <span class="d-sr-only">
            {{ isExpanded ? $t('global.collapse') : $t('global.expand') }}
          </span>
        </v-btn>
      </template>
    </v-data-table>

  </c-dialog>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import PagingControls from '@components/support/PagingControls';

export default {
  components: { PagingControls },
  props: {
    value: {
      type: Boolean
    }
  },
  data () {
    return {
      loading: false,
      userRequests: [],
      page: {
        size: 20,
        number: 0,
        totalElements: 0,
        totalPages: 0
      }
    };
  },
  computed: {
    headers () {
      return [
        { text: this.$t('popup.userRequests.userType'), value: 'userType.displayName', sortable: false },
        { text: this.$t('popup.userRequests.submissionDate'), value: 'submissionDate', sortable: false },
        { text: this.$t('popup.userRequests.processedDate'), value: 'processedDate', sortable: false },
        { text: this.$t('popup.userRequests.status'), value: 'userRequestStatus.displayName', sortable: false },
        { text: this.$t('global.expand'), value: 'data-table-expand', sortable: false, align: 'end' }
      ];
    }
  },
  watch: {
    value (newValue) {
      if (newValue)
      {
        this.$cove.block();
        this.loading = true;
        this.loadUserRequests()
          .finally(() => {
            this.loading = false;
            this.$cove.unblock();
          });
      }
      else
      {
        this.userRequests = [];
        this.page.number = 0;
      }
    }
  },
  methods: {
    loadUserRequests () {
      let query = {
        fields: '*,userRequestStatus(*),userType(*),court(*)',
        page: this.page.number,
        size: this.page.size,
        sort: 'submissionDate,desc'
      };

      return axios.get(this.$cove.getAPI({ name: 'api.user.requests', query }))
        .then((response) => {
          this.userRequests = _.get(response, 'data._embedded.results', []);
          this.page.number = response.data.page.number;
          this.page.totalPages = response.data.page.totalPages;
          this.page.totalElements = response.data.page.totalElements;
        });
    },
    changePage (newPage) {
      this.page.number = newPage;
      this.loadUserRequests();
    },
    close () {
      this.$emit('input', false);
    }
  }
};
</script>
