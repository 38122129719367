<template>
  <!-- Customization Sandbox -->
  <div></div>
</template>

<script>
export default {
  props: {
    courtID: {
      type: String
    },
    caseInstance: {
      type: Object
    },
    isVerifiedParty: {
      type: Boolean
    }
  }
};
</script>