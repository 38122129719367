<template>
  <page-wrapper :title="title">
    <v-row>
      <v-col md="7" xl="6">
        <validation-error-panel/>
        <v-card>
          <v-card-title>
            {{ $t('page.manageAnnouncement.form.title') }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('page.manageAnnouncement.form.description') }}
          </v-card-subtitle>
          <v-card-text class="pt-2">
            <form>
              <v-row no-gutters>
                <v-col cols="12">
                  <c-text-field
                    v-model="announcementTitle"
                    :label="$t('page.manageAnnouncement.field.title')"
                    key="announcementTitle"
                    outlined
                    required
                    hide-details="auto"
                    aria-describedby="title-description"/>
                  <field-description id="title-description" message-key="page.manageAnnouncement.field.title.description"/>
                </v-col>
                <v-col cols="12">
                  <div v-if="!preview">
                    <c-textarea
                      v-if="!preview"
                      v-model="announcementText"
                      :label="$t('page.manageAnnouncement.field.text')"
                      key="announcementText"
                      outlined
                      required
                      hide-details="auto"
                      aria-describedby="text-description"/>
                    <field-description id="text-description">
                      {{ $t('page.manageAnnouncement.field.text.description.prefix') }}
                      <a href="https://www.markdownguide.org/basic-syntax/" target="_blank">{{
                        $t('page.manageAnnouncement.field.text.description.markdownLink')
                      }}</a>
                      {{ $t('page.manageAnnouncement.field.text.description.also') }}
                      <a id="preview-link" href="#" @click="(e) => { togglePreview(e, true); }">{{
                        $t('page.manageAnnouncement.field.text.description.previewLink')
                      }}</a>
                    </field-description>
                  </div>
                  <div v-else>
                    <div class="pa-4 rounded"
                         style="background-color:#f7f7f7;border:1px solid #a0a0a0"
                         v-html="previewText"></div>
                    <div class="mt-1 mb-5 text-right">
                      <a id="exit-preview-link"
                         href="#"
                         style="font-style:italic;font-size:.9rem"
                         @click="(e) => { togglePreview(e, false); }">
                        {{ $t('page.manageAnnouncement.field.text.description.exitLink') }}
                      </a>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <c-autocomplete
                    v-model="localeID"
                    :label="$t('page.manageAnnouncement.field.locale')"
                    :items="locales"
                    :clearable="false"
                    item-text="displayName"
                    item-value="resourceID"
                    key="locale"
                    outlined
                    required
                    hide-details="auto"
                    aria-describedby="locale-description"/>
                  <field-description id="locale-description" message-key="page.manageAnnouncement.field.locale.description"/>
                </v-col>
                <v-col cols="12">
                  <c-checkbox
                    v-model="critical"
                    :label="$t('page.manageAnnouncement.field.critical')"
                    class="my-0"
                    hide-details="auto"
                    aria-describedby="critical-description"/>
                  <field-description id="critical-description" message-key="page.manageAnnouncement.field.critical.description"/>
                </v-col>
                <v-col cols="12">
                  <c-checkbox
                    v-model="homePage"
                    :label="$t('page.manageAnnouncement.field.homePage')"
                    class="my-0"
                    hide-details="auto"
                    aria-describedby="home-page-description"/>
                  <field-description id="home-page-description" message-key="page.manageAnnouncement.field.homePage.description"/>
                </v-col>
                <transition name="scroll-y-transition">
                  <v-col v-if="homePage" cols="12">
                    <c-number
                      :label="$t('page.manageAnnouncement.field.orderBy')"
                      v-model="orderBy"
                      key="orderBy"
                      ref="orderBy"
                      required
                      outlined
                      hide-details="auto"
                      aria-describedby="order-by-description"/>
                    <field-description id="order-by-description" message-key="page.manageAnnouncement.field.orderBy.description"/>
                  </v-col>
                </transition>
                <v-col cols="12">
                  <section-separator :title="$t('page.manageAnnouncement.section.effectiveDates')" class="mb-5"/>
                </v-col>
                <v-col cols="12">
                  <c-date-picker
                    v-model="startDate"
                    :label="$t('page.manageAnnouncement.field.startDate')"
                    key="startDate"
                    outlined
                    required
                    hide-details="auto"
                    aria-describedby="start-date-description"/>
                  <field-description id="start-date-description" message-key="page.manageAnnouncement.field.startDate.description"/>
                </v-col>
                <v-col cols="12">
                  <c-date-picker
                    v-model="endDate"
                    :label="$t('page.manageAnnouncement.field.endDate')"
                    key="endDate"
                    outlined
                    hide-details="auto"
                    aria-describedby="end-date-description"/>
                  <field-description id="end-date-description" message-key="page.manageAnnouncement.field.endDate.description"/>
                </v-col>
                <v-col cols="12">
                  <section-separator :title="$t('page.manageAnnouncement.section.file')" class="mb-5"/>
                </v-col>
                <v-col cols="12">
                  <template v-if="documentLinkID === null || removeDocumentRequested">
                    <c-file-input
                      :label="$t('page.manageAnnouncement.field.file')"
                      v-model="file"
                      key="file"
                      outlined
                      hide-details="auto"
                      aria-describedby="file-description"/>
                    <field-description id="file-description">
                      {{ $te('page.manageAnnouncement.field.file.description.prefix') ?
                        $t('page.manageAnnouncement.field.file.description.prefix') : null }}
                      <button type="button" class="link-button" @click="openFileTypesPopup">
                        {{ $t('page.manageAnnouncement.field.file.description.viewTypes') }}
                      </button>
                    </field-description>
                  </template>
                  <template v-else>
                    <c-btn small :href="viewDocumentURL" target="_blank">
                      {{ $t('page.manageAnnouncement.button.viewFile') }}
                    </c-btn>
                    <c-btn small @click="toggleRemoveDocument">
                      {{ $t('page.manageAnnouncement.button.removeFile') }}
                    </c-btn>
                  </template>
                </v-col>
              </v-row>
            </form>
          </v-card-text>
          <v-card-actions class="justify-end mt-6">
            <c-btn type="primary" @click="save">
              {{ $t('application.button.save') }}
            </c-btn>
            <c-btn v-if="!isNew" type="danger" @click="remove">
              {{ $t('application.button.remove') }}
            </c-btn>
            <c-btn @click="returnToAnnouncements">
              {{ $t('application.button.cancel') }}
            </c-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <file-types-popup v-model="fileTypesPopupVisible" :fileType="CONST.DOCUMENT_TYPE_ANNOUNCEMENT"/>

  </page-wrapper>
</template>

<script>
import PageWrapper from '@components/support/PageWrapper';
import SectionSeparator from '@components/support/SectionSeparator';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import FieldDescription from '@components/support/FieldDescription';
import FileTypesPopup from '@components/support/FileTypesPopup';
import { marked } from 'marked';
import axios from 'axios';
import _ from 'lodash';
import { dateOnlyToUTC, saveDocument } from '@/application/application';
import { redirectNotFoundErrorHandler } from '@/application/ajax';
import { validateFile } from '@/application/utility';
import PageMixin from '@/mixins/page';

export default {
  components: { PageWrapper, SectionSeparator, ValidationErrorPanel, FieldDescription, FileTypesPopup },
  mixins: [ PageMixin ],
  props: {
    announcementID: {
      type: String
    }
  },
  data () {
    return {
      preview: false,
      announcementText: null,
      announcementTitle: null,
      startDate: null,
      endDate: null,
      critical: false,
      homePage: false,
      localeID: null,
      orderBy: null,
      file: null,
      documentLinkID: null,
      removeDocumentRequested: false,
      fileTypesPopupVisible: false
    };
  },
  created () {
    this.$store.dispatch('application/loadFileTypes', this.CONST.DOCUMENT_TYPE_ANNOUNCEMENT);

    if (this.isNew)
    {
      this.startDate = this.$cove.formatDate(new Date());

      let userLocaleCode = this.$store.state.user.localeCode;
      this.localeID = this.$store.getters['application/getLocaleByCode'](userLocaleCode).resourceID;
    }
    else
    {
      this.$cove.block();
      let url = this.$cove.getAPI({
        name: 'api.announcement',
        params: { id: this.announcementID },
        query: {
          fields: '*,locale(resourceID)'
        } });
      axios.get(url, { errorHandlers: { 404: redirectNotFoundErrorHandler } })
        .then((response) => {
          this.fromBean(response.data);
        })
        .finally(() => {
          this.$cove.unblock();
        });
    }
  },
  computed: {
    isNew () {
      return this.announcementID === undefined || this.announcementID === null;
    },
    title () {
      return this.isNew ? this.$t('page.manageAnnouncement.title.add') : this.$t('page.manageAnnouncement.title.edit');
    },
    locales () {
      return this.$store.state.application.locales;
    },
    previewText () {
      return marked.parse(_.escape(this.announcementText), {
        breaks: true
      });
    },
    viewDocumentURL () {
      return this.$cove.getAPI({
        name: 'api.dms.document',
        params: { documentLinkID: this.documentLinkID }
      });
    }
  },
  watch: {
    homePage () {
      if (!this.homePage)
      {
        this.orderBy = null;
      }
    }
  },
  methods: {
    openFileTypesPopup () {
      this.fileTypesPopupVisible = true;
    },
    togglePreview (e, enabled) {
      this.preview = enabled;
      this.$nextTick(() => {
        document.getElementById(enabled ? 'exit-preview-link' : 'preview-link').focus();
      });

      e.preventDefault();
      return false;
    },
    returnToAnnouncements () {
      let previousRoute = this.$store.state.application.previousRoute;
      if (previousRoute !== null && previousRoute.name === 'announcements')
      {
        this.$router.push(previousRoute.fullPath);
      }
      else
      {
        this.$router.push({ name: 'announcements' });
      }
    },
    validate () {
      this.$store.commit('cove/errors/clear');

      this.$cove.validation.required(this.announcementTitle, 'page.manageAnnouncement.field.title', 'announcementTitle');
      this.$cove.validation.string.maxLength(this.announcementTitle, 250, 'page.manageAnnouncement.field.title', 'announcementTitle');

      this.$cove.validation.required(this.announcementText, 'page.manageAnnouncement.field.text', 'announcementText');
      this.$cove.validation.string.maxLength(this.announcementText, 10000, 'page.manageAnnouncement.field.title', 'announcementText');

      this.$cove.validation.required(this.localeID, 'page.manageAnnouncement.field.locale', 'locale');

      this.$cove.validation.required(this.startDate, 'page.manageAnnouncement.field.startDate', 'startDate');
      this.$cove.validation.date.rejectIfInvalid(this.startDate, 'page.manageAnnouncement.field.startDate', 'startDate');

      this.$cove.validation.date.rejectIfInvalid(this.endDate, 'page.manageAnnouncement.field.endDate', 'endDate');
      this.$cove.validation.date.rejectIfBefore(this.endDate, this.startDate, 'page.manageAnnouncement.field.endDate',
        'page.manageAnnouncement.field.startDate', 'endDate');

      if (this.homePage)
      {
        this.$cove.validation.required(this.orderBy, 'page.manageAnnouncement.field.orderBy', 'orderBy');
        this.$cove.validation.integer.rejectIfInvalid(this.orderBy, 'page.manageAnnouncement.field.orderBy', 'orderBy');
        this.$cove.validation.integer.rejectIfLessThanEqualTo(this.orderBy, 0, 'page.manageAnnouncement.field.orderBy', 'orderBy');
        this.$cove.validation.integer.rejectIfGreaterThan(this.orderBy, 1000, 'page.manageAnnouncement.field.orderBy', 'orderBy');
      }

      if (this.file !== null)
      {
        validateFile(this.file, this.CONST.DOCUMENT_TYPE_ANNOUNCEMENT, 'page.manageAnnouncement.field.file', 'file');
      }

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      this.$cove.block({ delay: 0 });

      let isValid = this.validate();
      if (!isValid)
      {
        this.$cove.unblock();
        this.scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
        return;
      }

      // remove existing file attachment
      if (this.removeDocumentRequested)
      {
        this.removeDocument();
      }

      // save new file attachment
      let saveFilePromise = Promise.resolve(null);
      let hasNewDocument = this.file !== null;
      if (hasNewDocument)
      {
        saveFilePromise = saveDocument(this.CONST.SYSTEM_TABLE_ANNOUNCEMENT, 'Announcement', this.file)
          .then((value) => {
            this.documentLinkID = value;
          });
      }

      // TODO how to handle w/ global error handling here...
      // save the announcement
      saveFilePromise
        .then(() => {
          let url = this.isNew ?
            this.$cove.getAPI({ name: 'api.announcements' }) :
            this.$cove.getAPI({ name: 'api.announcement', params: { id: this.announcementID } });
          return axios({
            url,
            method: this.isNew ? 'post' : 'patch',
            data: this.toBean()
          });
        })
        .then(() => {
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
          this.returnToAnnouncements();
        })
        .catch(() => {
          // if anything failed try and clean up a new document attachment
          if (hasNewDocument && this.documentLinkID !== null)
          {
            this.removeDocument();
          }
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    remove () {
      this.$cove.confirm({ message: this.$t('application.remove.confirm') })
        .then((confirmed) => {
          if (confirmed)
          {
            this.$cove.block({ delay: 0 });

            if (this.documentLinkID !== null)
            {
              this.removeDocument();
            }

            // TODO test with global error mgmt, i.e. make sure user is notified something went wrong with the removal
            let url = this.$cove.getAPI({ name: 'api.announcement', params: { id: this.announcementID } });
            axios.delete(url)
              .then(() => {
                this.$cove.notify({ message: this.$t('application.remove.success'), color: 'success' });
                this.returnToAnnouncements();
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    },
    toggleRemoveDocument () {
      this.removeDocumentRequested = true;
    },
    removeDocument () {
      // TODO need to ignore any global error checking on this request, we don't care if it fails there is nothing we can do about it
      let url = this.$cove.getAPI({ name: 'api.dms.document', params: { documentLinkID: this.documentLinkID } });
      axios.delete(url)
        .catch((error) => {
          console.error('Error removing document from announcement.');
          console.error(error);
        });

      this.documentLinkID = null;
    },
    fromBean (announcement) {
      this.announcementTitle = announcement.subject;
      this.announcementText = _.unescape(announcement.body);
      this.localeID = announcement.locale.resourceID;
      this.startDate = this.$cove.formatDate(announcement.startDate);
      this.documentLinkID = _.defaultTo(announcement.documentLinkID, null);
      this.critical = announcement.critical;
      this.homePage = announcement.homePage;
      this.orderBy = _.defaultTo(announcement.orderBy, null);

      if (announcement.endDate !== undefined)
      {
        this.endDate = this.$cove.formatDate(announcement.endDate);
      }
    },
    toBean () {
      return {
        subject: this.announcementTitle,
        body: _.escape(this.announcementText),
        critical: this.critical,
        homePage: this.homePage,
        orderBy: this.orderBy !== null ? this.$refs.orderBy.getValueAsNumber() : null,
        localeID: this.localeID,
        documentLinkID: this.documentLinkID,
        startDate: dateOnlyToUTC(this.$cove.parseDate(this.startDate)),
        endDate: dateOnlyToUTC(this.$cove.parseDate(this.endDate))
      };
    }
  }
};
</script>