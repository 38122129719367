<template>
  <loading-card :loading="loading" :height="250">
    <v-card-title>
      {{ $t('page.createFiling.step.entryMode.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.createFiling.step.entryMode.description') }}
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-list class="transparent py-0">
            <action-list-item @click="save(true)"
                              icon="mdi-format-list-bulleted"
                              :title="$t('page.createFiling.step.entryMode.guided.title')"
                              :description="$t('page.createFiling.step.entryMode.guided.description')"/>
            <v-divider/>
            <action-list-item @click="save(false)"
                              icon="mdi-file-document-edit"
                              :title="$t('page.createFiling.step.entryMode.manual.title')"
                              :description="$t('page.createFiling.step.entryMode.manual.description')"/>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
  </loading-card>
</template>

<script>
import WorkflowStepMixin from '@/mixins/workflowStep';

export default {
  mixins: [ WorkflowStepMixin ],
  methods: {
    save (guidedEntry) {
      this.$emit('save', guidedEntry);
    }
  }
};
</script>
