<template>
  <page-wrapper :title="$t('page.subRenewal.title')">
    <div class="d-flex flex-wrap flex-md-nowrap">
      <div class="flex-grow-1">
        <loading-card :loading="loadingSummaries" :height="360">
          <template #default>
            <v-card-title>
              {{ $t('page.subRenewal.processedSummary.title') }}
            </v-card-title>
            <v-card-subtitle>
              {{ $t('page.subRenewal.processedSummary.description') }}
            </v-card-subtitle>
            <v-card-text class="pa-0">
              <v-simple-table>
                <thead>
                  <tr>
                    <th>{{ $t('subscription.type') }}</th>
                    <th>{{ $t('page.subscriptionMgmt.processSubs.activeSubs') }}</th>
                    <th>{{ $t('page.subscriptionMgmt.processSubs.terminatedSubs') }}</th>
                    <th>{{ $t('subscription.activeSeats') }}</th>
                    <th class="text-right">{{ $t('page.subscriptionMgmt.processSubs.assessed') }}</th>
                    <th class="text-right">{{ $t('page.subscriptionMgmt.processSubs.processed') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- eslint-disable-next-line vue/require-v-for-key -->
                  <tr v-for="summary in renewalSummaries">
                    <td>{{ summary.subscriptionType.displayName }}</td>
                    <td>{{ summary.activeSubscriptions | formatNumber }}</td>
                    <td>{{ summary.terminatedSubscriptions | formatNumber }}</td>
                    <td>{{ summary.activeSeats | formatNumber }}</td>
                    <td class="text-right">{{ summary.assessed | formatCurrency }}</td>
                    <td class="text-right">{{ summary.processed | formatCurrency }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="font-weight-medium">{{ $t('global.total') }}</td>
                    <td class="font-weight-medium">{{ summaryTotals.activeSubscriptions | formatNumber }}</td>
                    <td class="font-weight-medium">{{ summaryTotals.terminatedSubscriptions | formatNumber }}</td>
                    <td class="font-weight-medium">{{ summaryTotals.activeSeats | formatNumber }}</td>
                    <td class="font-weight-medium text-right">{{ summaryTotals.assessed | formatCurrency }}</td>
                    <td class="font-weight-medium text-right">{{ summaryTotals.processed | formatCurrency }}</td>
                  </tr>
                </tfoot>
              </v-simple-table>
            </v-card-text>
          </template>
        </loading-card>
      </div>
      <div class="d-block d-md-none" style="width:100%">
        <!-- forces wrap on small viewports -->
      </div>
      <div class="flex-grow-1 flex-md-grow-0 ml-md-5 mt-4 mt-md-0" style="flex-basis:260px">
        <loading-card :loading="loadingRenewal">
          <template #default>
            <v-card-title>
              {{ $t('global.details') }}
            </v-card-title>
            <v-card-subtitle>
              {{ $t('page.subRenewal.details.description') }}
            </v-card-subtitle>
            <v-card-text>
              <div>
                <div class="label text-left">{{ $t('page.subscriptionMgmt.renewalHistory.renewalDate') }}</div>
                <div class="break-word">{{ subscriptionRenewal.renewalDate | formatDateTime }}</div>
              </div>
              <div class="mt-4">
                <div class="label text-left">{{ $t('page.subscriptionMgmt.renewalHistory.renewalStatus.short') }}</div>
                <div class="break-word">{{ subscriptionRenewal.subscriptionRenewalStatus.displayName }}</div>
              </div>
              <div class="mt-4">
                <div class="label text-left">{{ $t('page.subscriptionMgmt.renewalHistory.initiatedUser') }}</div>
                <div class="break-word">{{ subscriptionRenewal.initiatedUser.actor.displayName }}</div>
              </div>
            </v-card-text>
            <v-card-actions v-if="canAbort" class="justify-end d-print-none">
              <c-btn block type="danger" @click="abortRenewal">
                {{ $t('page.subRenewal.details.abort') }}
              </c-btn>
            </v-card-actions>
          </template>
        </loading-card>
      </div>
    </div>
    <div class="mt-5">
      <loading-card :loading="loadingItems">
        <v-card-title>
          {{ $t('page.subRenewal.subscriptions.title') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('page.subRenewal.subscriptions.description') }}
        </v-card-subtitle>
        <v-card-text class="pa-0">
          <v-data-table
            :items="renewalItems"
            :headers="renewalItemHeaders"
            :sort-by="paging.sortBy"
            :sort-desc="paging.sortDesc"
            :server-items-length="paging.totalElements"
            :loading="itemsTableLoading"
            item-key="resourceID"
            must-sort
            hide-default-footer
            :mobile-breakpoint="800"
            @update:options="paging.updateOptions">
            <template #item.name="{ item }">
              <router-link v-if="!isNil(item.subscription.organization)"
                           :to="{ name: 'manage-org', params: { organizationID: item.subscription.organization.resourceID} }">
                {{ item.subscription.organization.sortName }}
              </router-link>
              <router-link v-else-if="!isNil(item.subscription.user)"
                           :to="{ name: 'admin-user-account', params: { userID: item.subscription.user.resourceID }}">
                {{ item.subscription.user.actor.sortName }}
              </router-link>
            </template>
            <template #item.receipt="{ item }">
              <router-link v-if="!isNil(item.receipt)" :to="{ name: 'receipt', params: { receiptID: item.receipt.resourceID } }">
                {{ $t('global.view') }}
              </router-link>
            </template>
            <template #footer>
              <paging-controls-new :paging="paging"/>
            </template>
          </v-data-table>
        </v-card-text>
      </loading-card>
    </div>
  </page-wrapper>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import PageMixin from '@/mixins/page';
import PageWrapper from '@components/support/PageWrapper';
import LoadingCard from '@components/support/LoadingCard';
import PagingControlsNew from '@components/support/PagingControlsNew';
import Paging from '@/application/paging';
import JSURL from 'jsurl';
import { replaceIgnoreDuplicate } from '@/application/utility';

export default {
  components: { PageWrapper, LoadingCard, PagingControlsNew },
  mixins: [ PageMixin ],
  props: {
    subscriptionRenewalID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loadingRenewal: true,
      loadingSummaries: true,
      loadingItems: true,
      itemsTableLoading: true,
      subscriptionRenewal: null,
      renewalSummaries: [],
      summaryTotals: null,
      renewalItems: [],
      renewalItemHeaders: [
        { text: this.$t('global.name'), value: 'name', sortable: false },
        { text: this.$t('global.type'), value: 'subscription.subscriptionType.displayName', sortable: false },
        { text: this.$t('page.subscriptionMgmt.processSubs.seats'), value: 'seats', sortable: false },
        { text: this.$t('page.subRenewal.subscriptions.processingStatus'), value: 'subscriptionRenewalItemStatus.displayName',
          sortable: false },
        { text: this.$t('page.subRenewal.subscriptions.subscriptionStatus'), value: 'finalSubscriptionStatus.displayName',
          sortable: false },
        { text: this.$t('page.subRenewal.subscriptions.receipt'), value: 'receipt', sortable: false, align: 'right' }
      ],
      paging: new Paging({
        size: 25,
        sortBy: 'subscriptionRenewalItemStatus.orderBy',
        sortDesc: false,
        searchCallback: this.loadRenewalItems
      })
    };
  },
  computed: {
    canAbort () {
      return this.subscriptionRenewal.subscriptionRenewalStatus.executing === true;
    }
  },
  created () {
    let criteria = JSURL.parse(_.defaultTo(this.$route.query.criteria, ''));
    this.paging.parseURLParams(criteria);

    this.loadRenewal();
    this.loadSummaries();
    this.loadRenewalItems(false);
  },
  methods: {
    loadRenewal () {
      let url = this.$cove.getAPI({
        name: 'api.subscription.renewal',
        params: { id: this.subscriptionRenewalID },
        query: { fields: '*,subscriptionRenewalStatus(*),initiatedUser(*,actor(*))' }
      });

      axios.get(url)
        .then((response) => {
          this.subscriptionRenewal = response.data;
        })
        .finally(() => {
          this.loadingRenewal = false;
        });
    },
    loadSummaries () {
      let url = this.$cove.getAPI({
        name: 'api.subscription.renewal.summary',
        params: { id: this.subscriptionRenewalID }
      });

      axios.get(url)
        .then((response) => {
          let items = response.data;
          this.summaryTotals = _.remove(items, (v) => { return _.isNil(v.subscriptionType); })[0];
          this.renewalSummaries = _.sortBy(items, 'subscriptionType.displayName');
        })
        .finally(() => {
          this.loadingSummaries = false;
        });
    },
    loadRenewalItems (updateURL = true) {
      if (updateURL)
      {
        // updates the browser URL so we can restore the paging state if the user navigates back
        replaceIgnoreDuplicate({
          name: 'admin-subscription-renewal',
          params: {
            subscriptionRenewalID: this.subscriptionRenewalID
          },
          query: {
            criteria: JSURL.stringify(this.paging.generateParams())
          }
        });
      }

      let url = this.$cove.getAPI({
        name: 'api.subscription.renewal.items',
        query: {
          subscriptionRenewalID: this.subscriptionRenewalID,
          // eslint-disable-next-line max-len
          fields: '*,subscription(*,user(*,actor(*)),organization(*),subscriptionType(*)),finalSubscriptionStatus(*),subscriptionRenewalItemStatus(*),receipt(*)',
          ...this.paging.generateParams()
        }
      });

      this.itemsTableLoading = true;
      axios.get(url)
        .then((response) => {
          this.renewalItems = _.get(response, 'data._embedded.results', []);
          this.paging.parseResponse(response);
        })
        .finally(() => {
          this.loadingItems = false;
          this.itemsTableLoading = false;
        });
    },
    abortRenewal () {
      this.$cove.confirm({ message: this.$t('page.subRenewal.details.abortConfirm') })
        .then((confirm) => {
          if (confirm)
          {
            this.$cove.block({ delay: 0 });

            let url = this.$cove.getAPI({
              name: 'api.subscription.renewal',
              params: { id: this.subscriptionRenewalID }
            });

            axios.delete(url)
              .then(() => {
                this.$cove.notify({ message: this.$t('page.subRenewal.details.abortSuccess'), color: 'success' });
                this.loadingRenewal = true;
                this.loadRenewal();
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    }
  }
};
</script>