<template>
  <loading-card :loading="loading" class="nav-tab-medium">
    <template #default>
      <v-card-title>
        {{ $t('page.admin.userAccount.paymentMethod.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.admin.userAccount.paymentMethod.description') }}
      </v-card-subtitle>
      <v-card-text>

        <mini-card v-if="hasOrganizationPaymentToken" class="mt-4 mb-8">
          <v-card-text class="pa-6">
            <div class="d-flex" style="gap:24px">
              <div class="flex-grow-0">
                <img :src="require(`@/assets/paymentClient/${paymentClientName}/logo.png`)" alt=""/>
              </div>
              <v-divider vertical/>
              <div class="flex-grow-1 text-minimum-gray align-self-center" style="max-width:300px;">
                {{ $t('page.admin.userAccount.paymentMethod.hasOrgPayment') }}
              </div>
            </div>
          </v-card-text>
        </mini-card>

        <mini-card v-else-if="hasPaymentToken" class="mt-4 mb-8">
          <v-card-text class="pa-6">
            <div class="d-flex" style="gap:24px">
              <div class="flex-grow-0">
                <img :src="require(`@/assets/paymentClient/${paymentClientName}/logo.png`)" alt=""/>
              </div>
              <v-divider vertical/>
              <div class="flex-grow-1 text-minimum-gray align-self-center" style="max-width:300px;">
                {{ $t('page.admin.userAccount.paymentMethod.hasPayment') }}
              </div>
            </div>
          </v-card-text>
        </mini-card>

        <cutout-area v-else class="mt-4 mb-8">
          {{ $t('page.admin.userAccount.paymentMethod.noPayment') }}
        </cutout-area>

      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import CutoutArea from '@components/support/CutoutArea';
import MiniCard from '@components/support/MiniCard';
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { LoadingCard, CutoutArea, MiniCard },
  props: {
    userID: {
      type: String
    }
  },
  data () {
    return {
      loading: true,
      hasPaymentToken: false,
      hasOrganizationPaymentToken: false
    };
  },
  computed: {
    paymentClientName () {
      return this.$store.getters['application/getAppProperty'](this.CONST.APP_PROPERTY_PAYMENT_CLIENT);
    }
  },
  created () {
    let url = this.$cove.getAPI({
      name: 'api.user',
      params: {
        userID: this.userID
      },
      query: {
        fields: '*,hasPaymentToken,hasOrganizationPaymentToken'
      }
    });

    axios.get(url)
      .then((response) => {
        this.hasPaymentToken = _.get(response.data, 'hasPaymentToken', false);
        this.hasOrganizationPaymentToken = _.get(response.data, 'hasOrganizationPaymentToken', false);
      })
      .finally(() => {
        this.loading = false;
      });
  }
};
</script>