<template>
  <div>
    <v-data-table
      :items="items"
      :headers="headers"
      :sort-by="searchDTO.sort.sortBy"
      :sort-desc="searchDTO.sort.sortDesc"
      :server-items-length="searchDTO.page.totalElements"
      item-key="searchResultKey"
      must-sort
      hide-default-footer
      class="elevation-2"
      :mobile-breakpoint="800"
      @update:options="updateOptions">
      <template #top>
        <div class="d-flex align-center">
          <div class="c-caption pt-2 pl-3" style="flex:1 1 auto">
            {{ $t('page.searchResults.publication.table') }}
          </div>
          <div class="text-right pt-2 pr-2 d-print-none" style="flex:1 1 auto">
            <v-btn icon color="primary" @click="toggleDrawer">
              <v-icon>
                mdi-filter
              </v-icon>
              <span class="d-sr-only">
                {{ $t('global.table.filterAndSort') }}
              </span>
            </v-btn>
          </div>
        </div>
      </template>

      <template #item.publicationDate="{ value }">
        <div style="min-width:100px">
          <div>
            {{ value | formatDate }}
          </div>
        </div>
      </template>

      <template #item.publicationNumber="{ item }">
        <div>
          <div style="white-space: nowrap">
            <router-link :to="{ name: 'publication', params: { courtID: getCourtByExternalID(item.courtID).resourceID,
                                                               publicationUUID: item.publicationUUID }}">
              {{ item.publicationNumber }}
            </router-link>
          </div>
        </div>
      </template>

      <template #footer>
        <paging-controls :page="searchDTO.page" @changePage="changePage"/>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import SearchResultsMixin from '@/mixins/searchResults';

export default {
  mixins: [ SearchResultsMixin ]
};
</script>