import * as core from '@/application/caseView-core';
import CONST from '@/application/constants';
import i18n from '@/plugins/i18n';

export function getTabHeaders (tab, isVerifiedParty)
{
  let headers = core.getTabHeaders(tab, isVerifiedParty);

  if (tab === CONST.CASE_VIEW_TAB_PARTIES)
  {
    _.remove(headers, ['value', 'birthDate']);
    _.remove(headers, ['value', 'address.formattedAddress']);
  }
  else if (tab === CONST.CASE_VIEW_TAB_HEARINGS)
  {
    _.remove(headers, ['value', 'event.judgeActorInstances']);
    _.remove(headers, ['value', 'event.virtualStreamingLink']);
  }

  return headers;
}