<template>
  <v-data-table
    :items="items"
    :headers="headers"
    :sort-by="searchDTO.sort.sortBy"
    :sort-desc="searchDTO.sort.sortDesc"
    :server-items-length="searchDTO.page.totalElements"
    item-key="searchResultKey"
    must-sort
    hide-default-footer
    class="elevation-2"
    :mobile-breakpoint="800"
    @update:options="updateOptions">
    <template #top>
      <div class="d-flex align-center">
        <div class="c-caption pt-2 pl-3" style="flex:1 1 auto">
          {{ $t('page.searchResults.case.table') }}
        </div>
        <div class="text-right pt-2 pr-2 d-print-none" style="flex:1 1 auto">
          <v-btn icon color="primary" @click="toggleDrawer">
            <v-icon>
              mdi-filter
            </v-icon>
            <span class="d-sr-only">
              {{ $t('global.table.filterAndSort') }}
            </span>
          </v-btn>
        </div>
      </div>
    </template>
    <template #item.caseHeader.caseNumber="{ item }">
      <router-link :to="{ name: 'caseView', params: { courtID: getCourtByExternalID(item.caseHeader.courtID).resourceID,
                                                      caseUUID: item.caseHeader.caseInstanceUUID} }">
        {{ item.caseHeader.caseNumber }}
      </router-link>
    </template>
    <template #item.caseHeader.filedDate="{ value }">
      {{ value | formatDate }}
    </template>
    <template #item.caseHeader.closedFlag="{ value }">
      {{ value ? $t('global.closed') : $t('global.open') }}
    </template>
    <template #footer>
      <paging-controls :page="searchDTO.page" @changePage="changePage"/>
    </template>
  </v-data-table>
</template>

<script>
import SearchResultsMixin from '@/mixins/searchResults';

export default {
  mixins: [ SearchResultsMixin ]
};
</script>