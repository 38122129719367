<template>
  <page-wrapper :title="$t('page.receipts.title')" :subtitle="subtitle">
    <v-row>
      <v-col cols="12" md="6">
        <receipt-details :receipt="receipt"/>
      </v-col>
      <v-col cols="12" md="6">
        <receipt-order-summary :receipt="receipt"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <receipt-purchased-documents v-if="isPurchasedDocuments" :purchased-documents="purchasedDocuments"/>
      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import PageWrapper from '@components/support/PageWrapper';
import ReceiptDetails from '@components/support/accounting/ReceiptDetails';
import ReceiptOrderSummary from '@components/support/accounting/ReceiptOrderSummary';
import ReceiptPurchasedDocuments from '@components/support/accounting/ReceiptPurchasedDocuments';

export default {
  components: { PageWrapper, ReceiptDetails, ReceiptOrderSummary, ReceiptPurchasedDocuments },
  props: {
    receiptID: {
      type: String
    }
  },
  data () {
    return {
      receipt: null,
      purchasedDocuments: []
    };
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.receipts', params: { id: this.receiptID } }))
      .then((response) => {
        this.receipt = _.defaultTo(response.data, null);
        if (this.isPurchasedDocuments) {
          axios.get(this.$cove.getAPI({
            name: 'api.purchasedDocuments.search',
            query: {
              receiptID: this.receiptID,
              size: this.CONST.PAGING_MAX_SIZE
            }
          }))
            .then((response) => {
              this.purchasedDocuments = _.defaultTo(response.data._embedded.results, []);
            });
        }
      });
  },
  computed: {
    subtitle () {
      return !_.isNil(this.receipt) ? this.receipt.referenceNumber : '';
    },
    isPurchasedDocuments () {
      return _.get(this, 'receipt.receiptType.resourceID') === this.CONST.RECEIPT_TYPE_PURCHASED_DOCUMENTS;
    }
  }
};
</script>