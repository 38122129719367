<template>
  <page-wrapper :title="$t('page.requestAccess.title')" :subtitle="subtitle">
    <div>
      <v-row>
        <v-col md="7" xl="6">
          <validation-error-panel/>

          <transition name="scroll-x-reverse-transition" mode="out-in">
            <step-address v-if="step === 'address'" @nextStep="nextStep" :required="getUserAccessConfig.requireAddress"/>
            <step-contact v-else-if="step === 'contact'" @nextStep="nextStep" :required="getUserAccessConfig.requireContactPhone"/>
            <step-attorney v-else-if="step === 'attorney'" @submitRequest="submitRequest"/>
            <step-interpreter v-else-if="step === 'interpreter'" @submitRequest="submitRequest"/>
            <step-public v-else-if="step === 'public'" @submitRequest="submitRequest"
                         :forwardCourtID="courtID" :forwardCaseNumber="caseNumber"/>
            <step-organization-admin v-else-if="step === 'organizationAdmin'" @submitRequest="submitRequest"/>
          </transition>
        </v-col>
      </v-row>
    </div>
  </page-wrapper>
</template>

<script>

import PageWrapper from '@components/support/PageWrapper';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import StepAddress from '@components/pages/initialRegistration/StepAddress';
import StepContact from '@components/pages/initialRegistration/StepContact';
import StepAttorney from '@components/pages/initialRegistration/StepAttorney';
import StepInterpreter from '@components/pages/initialRegistration/StepInterpreter';
import StepPublic from '@components/pages/initialRegistration/StepPublic';
import StepOrganizationAdmin from '@components/pages/initialRegistration/StepOrganizationAdmin';
import UserAccess from '@/application/userAccess';
import axios from 'axios';
import _ from 'lodash';

export default {
  components: { PageWrapper, ValidationErrorPanel, StepAddress, StepAttorney, StepInterpreter, StepContact, StepPublic,
    StepOrganizationAdmin },
  props: {
    userTypeID: {
      type: String,
      default: null
    },
    courtID: {
      type: String,
      default: null
    },
    caseNumber: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      stepPosition: 0,
      userTypes: []
    };
  },
  computed: {
    subtitle () {
      return _.defaultTo(_.find(this.userTypes, { resourceID: this.userTypeID }), {}).displayName;
    },
    user () {
      return this.$store.state.user;
    },
    hasAddress () {
      return this.user.mailingAddress !== null;
    },
    hasContactPhone () {
      return this.user.contactPhoneNumber !== null;
    },
    getUserAccessConfig () {
      return UserAccess.getUserAccessConfig(this.userTypeID);
    },
    step () {
      return this.steps[this.stepPosition];
    },
    steps () {
      let steps = [];
      if (!this.hasAddress)
      {
        steps.push('address');
      }
      if (!this.hasContactPhone)
      {
        steps.push('contact');
      }
      if (this.userTypeID === this.CONST.USER_TYPE_ATTORNEY)
      {
        steps.push('attorney');
      }
      if (this.userTypeID === this.CONST.USER_TYPE_INTERPRETER)
      {
        steps.push('interpreter');
      }
      if (this.userTypeID === this.CONST.USER_TYPE_ORGANIZATION_ADMIN)
      {
        steps.push('organizationAdmin');
      }
      if (this.userTypeID === this.CONST.USER_TYPE_PUBLIC)
      {
        steps.push('public');
      }

      return steps;
    }
  },
  created () {
    this.$store.dispatch('application/loadFileTypes', this.CONST.DOCUMENT_TYPE_USER_REQUEST);

    axios.get(this.$cove.getAPI({ name: 'api.usertypes' }))
      .then((response) => {
        this.userTypes = _.get(response, 'data._embedded.results', []);
        if (_.isNil(_.find(this.userTypes, ['resourceID', this.userTypeID])))
        {
          this.$router.replace({ name: 'profile-user-access' });
        }
      });
  },
  methods: {
    submitRequest: UserAccess.submitRequest,
    nextStep () {
      this.stepPosition++;
    }
  }
};
</script>