<template>
  <loading-card :loading="loading">
    <v-card-title>
      {{ $t('page.manageFiling.docketEntry.stepDocument.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.manageFiling.docketEntry.stepDocument.description') }}
    </v-card-subtitle>
    <v-card-text class="pt-2">
      <v-row no-gutters>
        <v-col cols="12">
          <c-text-field
            v-model="bean.documentName"
            :label="$t('docketEntryDocument.name')"
            key="documentName"
            maxlength="250"
            required
            outlined
            hide-details="auto"
            aria-describedby="e6bf05b9-0583-49e7-bfe8-eb09808394d9"/>
          <field-description id="e6bf05b9-0583-49e7-bfe8-eb09808394d9"
                             message-key="page.manageFiling.docketEntry.stepDocument.field.name.description"/>
        </v-col>
        <v-col cols="12">
          <c-textarea
            v-model="bean.documentNote"
            :label="$t('docketEntryDocument.note')"
            maxlength="2000"
            outlined
            hide-details="auto"
            aria-describedby="c1e740db-78c4-432e-8c24-929dc3dc5490"/>
          <field-description id="c1e740db-78c4-432e-8c24-929dc3dc5490"
                             message-key="page.manageFiling.docketEntry.stepDocument.field.note.description"/>
        </v-col>
        <v-col cols="12">
          <c-autocomplete
            v-model="bean.confidentialReasonID"
            :label="$t('docketEntryDocument.confidential')"
            :items="confidentialReasons"
            item-text="displayName"
            item-value="resourceID"
            outlined
            hide-details="auto"
            aria-describedby="3b19dc08-4571-4beb-bb06-817786565e31"/>
          <field-description id="3b19dc08-4571-4beb-bb06-817786565e31"
                             message-key="page.manageFiling.docketEntry.stepDocument.field.confidential.description"/>
        </v-col>
        <v-col cols="12">
          <section-separator :title="$t('page.manageAnnouncement.section.file')" class="mb-5"/>
        </v-col>
        <v-col cols="12">
          <template v-if="bean.documentLinkID === null">
            <c-file-input
              v-model="bean.file"
              :label="$t('docketEntryDocument.file')"
              key="file"
              required
              outlined
              hide-details="auto"
              aria-describedby="7b1ebb78-322f-450d-9c14-ea6c85b022cb"/>
            <field-description id="7b1ebb78-322f-450d-9c14-ea6c85b022cb">
              {{ $t('page.manageFiling.docketEntry.stepDocument.field.file.description') }}
              <button type="button" class="link-button" @click="openFileTypesPopup">
                {{ $t('page.manageFiling.docketEntry.stepDocument.field.file.fileTypes') }}
              </button>
            </field-description>
            <file-types-popup v-model="fileTypesPopupVisible" :fileType="CONST.DOCUMENT_TYPE_FILING"/>
          </template>
          <template v-else>
            <c-btn small :href="viewDocumentURL" target="_blank">
              {{ $t('global.viewFile') }}
            </c-btn>
            <c-btn small @click="queueDocumentForRemoval">
              {{ $t('global.removeFile') }}
            </c-btn>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-end mt-5">
      <c-btn type="primary" @click="save">
        {{ editing ? $t('global.save') : $t('global.next') }}
      </c-btn>
      <c-btn v-if="!editing" @click="$emit('back')">
        {{ $t('global.back') }}
      </c-btn>
      <c-btn @click="$emit('cancel')">
        {{ $t('global.cancel') }}
      </c-btn>
    </v-card-actions>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import WorkflowStepMixin from '@/mixins/workflowStep';
import FileTypesPopup from '@components/support/FileTypesPopup';
import { saveDocument } from '@/application/application';
import { validateFile } from '@/application/utility';

export default {
  components: { FileTypesPopup },
  mixins: [ WorkflowStepMixin ],
  props: {
    editing: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fileTypesPopupVisible: false,
      removeDocumentLinkID: null,
      confidentialReasons: []
    };
  },
  computed: {
    viewDocumentURL () {
      return this.$cove.getAPI({
        name: 'api.dms.document',
        params: { documentLinkID: this.bean.documentLinkID }
      });
    }
  },
  methods: {
    loadData () {
      let fileTypePromise = this.$store.dispatch('application/loadFileTypes', this.CONST.DOCUMENT_TYPE_FILING);
      let confReasonsPromise =
        axios.get(this.$cove.getAPI( { name: 'api.confidential.reasons', query: { size: this.CONST.PAGING_MAX_SIZE } } ))
          .then((response) => {
            this.confidentialReasons = _.get(response, 'data._embedded.results', []);
          });

      return Promise.all([fileTypePromise, confReasonsPromise]);
    },
    validate () {
      this.$store.commit('cove/errors/clear');

      this.$cove.validation.required(this.bean.documentName, 'docketEntryDocument.name', 'documentName');

      if (this.bean.file === null && this.bean.documentLinkID === null)
      {
        this.$cove.validation.required(this.bean.file, 'docketEntryDocument.file', 'file');
      }

      if (this.bean.file !== null)
      {
        validateFile(this.bean.file, this.CONST.DOCUMENT_TYPE_FILING, 'page.manageAnnouncement.field.file', 'file');
      }

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      let isValid = this.validate();
      if (!isValid)
      {
        this.scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
      }
      else
      {
        if (this.bean.file !== null)
        {
          this.$cove.block({ delay: 0 });
          saveDocument(this.CONST.SYSTEM_TABLE_DOCKET_ENTRY_DOCUMENT, 'Filing Document', this.bean.file)
            .then((value) => {
              // we'll wait to do the removal until at least the new document has saved successfully
              if (this.removeDocumentLinkID !== null)
              {
                this.removeDocument();
              }

              this.bean.documentLinkID = value;
              this.bean.file = null;

              // this is annoying but we want to make sure the block comes down (in the finally) before we emit from here
              // since sometimes we have to block again as part of the parent save process
              setTimeout(() => {
                this.$emit('save', this.bean);
              }, 1);
            })
            .finally(() => {
              this.$cove.unblock();
            });
        }
        else
        {
          this.$emit('save', this.bean);
        }
      }
    },
    queueDocumentForRemoval () {
      this.removeDocumentLinkID = this.bean.documentLinkID;
      this.bean.documentLinkID = null;
    },
    removeDocument () {
      // this AJAX call configured to fail silently since failures might confuse the user and at worst we end up with
      // some orphaned documents
      let url = this.$cove.getAPI({ name: 'api.dms.document', params: { documentLinkID: this.removeDocumentLinkID } });
      axios.delete(url, { errorHandlers: { 400: null, 404: null, 500: null } })
        .catch((error) => {
          console.error('Error removing document from filing');
          console.error(error);
        });

      this.removeDocumentLinkID = null;
    },
    openFileTypesPopup () {
      this.fileTypesPopupVisible = true;
    }
  }
};
</script>