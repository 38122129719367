<template>
  <loading-card :loading="docketEntry === null">
    <template v-if="docketEntry !== null">
      <v-card-title>
        {{ $t('page.manageFiling.docketEntry.edit.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.manageFiling.docketEntry.edit.description') }}
      </v-card-subtitle>
      <v-card-text class="pt-2">
        <!-- Details -->
        <v-divider class="my-2" style="opacity:0.5"/>
        <div class="d-flex">
          <div style="flex: 1 1 auto;align-self:center">
            <dl class="d-grid grid-4060 mt-1">
              <dt>{{ $t('global.details') }}</dt>
              <dd>
                <div>
                  <div class="label text-left">
                    {{ $t('docketEntry.type') }}
                  </div>
                  <div>
                    {{ docketEntry.docketEntryExternalName }}
                  </div>
                </div>
                <div v-if="docketEntry.excludeFromService" class="mt-3">
                  <div class="label text-left">
                    {{ $t('docketEntry.excludeFromService') }}
                  </div>
                  <div>
                    {{ $t('global.yes') }}
                  </div>
                </div>
              </dd>
            </dl>
          </div>
          <div class="text-right" style="flex: 0 0 100px">
            <v-btn color="primary" text icon @click="$emit('editType')">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </div>
        <!-- Documents -->
        <template v-for="document in docketEntry.documents">
          <v-divider class="my-2" style="opacity:0.5" :key="`${document.resourceID}_divider`"/>
          <div class="d-flex" :key="`${document.resourceID}_wrapper`">
            <div style="flex: 1 1 auto;align-self:center">
              <dl class="d-grid grid-4060 mt-1">
                <dt>{{ $t('global.document') }}</dt>
                <dd>
                  <div>
                    <div class="label text-left">
                      {{ $t('docketEntryDocument.name') }}
                    </div>
                    <div>
                      <a :href="viewDocumentURL(document.documentLinkID)" target="_blank">
                        {{ document.documentName }}
                      </a>
                    </div>
                  </div>
                  <div v-if="hasComments(document)" class="mt-4">
                    <div class="label text-left">
                      {{ $t('docketEntryDocument.note') }}
                    </div>
                    <div class="clamp-note">
                      {{ document.documentNote }}
                    </div>
                  </div>
                  <div v-if="isConfidential(document)" class="mt-4">
                    <v-chip label small color="error" class="portal-tag">
                      {{ $t('global.confidential') }}
                    </v-chip>
                  </div>
                </dd>
              </dl>
            </div>
            <div class="text-right" style="flex: 0 0 100px">
              <v-btn color="primary" text icon @click="$emit('editDocument', document.resourceID)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn color="primary" text icon @click="$emit('removeDocument', document.resourceID)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <!-- Add... -->
        <v-divider class="my-2" style="opacity:0.5"/>
        <div class="d-flex mt-4">
          <div class="d-flex" style="flex: 1 1 auto">
            <div style="flex: 0 0 40%">
              <!-- empty but stubbed out for alignment with other sections -->
            </div>
            <div class="pl-1" style="flex: 0 0 60%">
              <c-btn small type="primary" @click="$emit('addDocument')">
                {{ $t('page.manageFiling.docketEntry.button.addDocument') }}
              </c-btn>
            </div>
          </div>
          <div style="flex: 0 0 100px">
            <!-- empty but stubbed out for alignment with other sections -->
          </div>
        </div>

      </v-card-text>
      <v-card-actions class="justify-end mt-5">
        <c-btn @click="$emit('back')">
          {{ $t('page.manageFiling.docketEntry.button.backToDocketEntries') }}
        </c-btn>
      </v-card-actions>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import WorkflowStepMixin from '@/mixins/workflowStep';

export default {
  mixins: [ WorkflowStepMixin ],
  props: {
    docketEntry: {
      type: Object
    }
  },
  methods: {
    isConfidential (document) {
      return !_.isNil(document.confidentialReason);
    },
    hasComments (document) {
      return !_.isNil(document.documentNote);
    },
    viewDocumentURL (documentLinkID) {
      return this.$cove.getAPI({ name: 'api.dms.document', params: { documentLinkID } });
    }
  }
};
</script>

<style lang="scss" scoped>
.clamp-note {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

dl.d-grid dt
{
  font-weight: 600;
  text-align: left;
}
</style>