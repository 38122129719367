<template>
  <page-wrapper :title="$t('page.currentUser.profile.title')" :subtitle="user.displayName">
    <nav-tabs :items="navTabs" :loading="loading"/>
  </page-wrapper>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import PageMixin from '@/mixins/page';
import PageWrapper from '@components/support/PageWrapper';
import NavTabs from '@components/support/NavTabs';

export default {
  components: { PageWrapper, NavTabs },
  mixins: [ PageMixin ],
  props: {
    reloadNav: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hasOrganization: false,
      hasFreeDocuments: false,
      loading: true
    };
  },
  computed: {
    user () {
      return this.$store.state.user;
    },
    isInterpreter () {
      return this.$store.getters['user/hasRole'](this.CONST.ROLE_INTERPRETER);
    },
    isEnableSubscriptionFunctions () {
      return this.$store.getters['application/getAppProperty'](this.CONST.FEATURE_PROPERTY_ENABLE_SUBSCRIPTION_FUNCTIONS);
    },
    navTabs () {
      let result = [
        { name: this.$t('page.userProfile.nav.loginAccount'), routeName: 'profile-login-account' },
        { name: this.$t('page.userProfile.nav.preferences'), routeName: 'profile-preferences' },
        { name: this.$t('page.userProfile.nav.address'), routeName: 'profile-address' },
        { name: this.$t('page.userProfile.nav.contact'), routeName: 'profile-contact' }
      ];

      if (this.isInterpreter)
      {
        result.push({ name: this.$t('page.userProfile.nav.interpreter'), routeName: 'profile-interpreter' });
      }

      result.push({ name: this.$t('page.userProfile.nav.paymentMethod'), routeName: 'profile-payment-method' });

      if (this.isEnableSubscriptionFunctions)
      {
        result.push({ name: this.$t('page.userProfile.nav.subscription'), routeName: 'profile-subscription' });
      }

      if (this.hasFreeDocuments)
      {
        result.push({ name: this.$t('page.userProfile.nav.documentAccess'), routeName: 'profile-document-access' });
      }

      if (this.hasOrganization)
      {
        result.push({ name: this.$t('page.userProfile.nav.assocOrg'), routeName: 'profile-organziation' });
      }

      result.push({ name: this.$t('page.userProfile.nav.calendarSub'), routeName: 'profile-calendar-subscriptions' });
      result.push({ name: this.$t('page.userProfile.nav.notificationPref'), routeName: 'profile-notification-preferences' });
      result.push({ name: this.$t('page.userProfile.nav.userAccess'), routeName: 'profile-user-access' });

      return result;
    }
  },
  beforeRouteUpdate (to, from, next) {
    // offers support to force reload the sub navigation if something happens that might affect the options we offer, e.g. the user leaves
    // their organization.
    if (to.params.reloadNav === true)
    {
      this.loadData();
    }

    next();
  },
  created () {
    this.loadData();
  },
  methods: {
    loadData () {
      this.loading = true;
      axios.get(this.$cove.getAPI({ name: 'api.user.currentuser', query: { fields: '*,organization(*)' } }))
        .then((response) => {
          this.hasFreeDocuments = _.get(response, 'data.freeDocuments', false) ||
            _.get(response, 'data.organization.freeDocuments', false);
          this.hasOrganization = _.get(response, 'data.organization.resourceID', null) !== null;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>