<template>
  <div>
    <div v-for="(receiptItem, i) in receipt.receiptItems" :key="receiptItem.resourceID" :class="{'mt-5': i !== 0 }">
      <div class="d-flex" style="gap:16px">
        <div class="font-weight-medium" style="flex: 1 1 auto">{{ receiptItem.displayName }}</div>
        <div class="font-weight-medium text-right" style="flex: 1 1 auto">{{ receiptItem.amount | formatCurrency }}</div>
      </div>
      <div v-for="(receiptItemDetail) in receiptItem.receiptItemDetails" :key="receiptItemDetail.resourceID">
        <div class="d-flex" style="gap:16px">
          <div class="pl-2 text-minimum-gray" style="flex: 1 1 auto">{{ receiptItemDetail.displayName }}</div>
          <div class="text-minimum-gray text-right" style="flex: 1 1 auto">{{ receiptItemDetail.amount | formatCurrency }}</div>
        </div>
      </div>
    </div>
    <v-divider class="my-4"/>
    <div class="d-flex">
      <div style="flex: 1 1 50%">
        <div class="font-weight-medium">
          {{ $t('page.receiptOrderSummary.total') }}
        </div>
      </div>
      <div style="flex: 1 1 50%" class="text-right font-weight-medium">{{ receipt.amount | formatCurrency }}</div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    receipt: {
      type: Object
    }
  }
};
</script>