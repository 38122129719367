<template>
  <div>
    <!-- no template required for this component -->
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';

// this is a simple component used to prevent us from hardcoding the user's org ID into the main menu. also it provides a nicer experience
// in the event a user is disassociated from an org while still logged in -- in that case it will route them back to the home page.

export default {
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.user.currentuser' }))
      .then((response) => {
        let organizationID = _.get(response, 'data.organization.resourceID', null);
        if (organizationID !== null)
        {
          this.$router.replace({ name: 'manage-org', params: { organizationID } });
        }
        else
        {
          this.$router.replace({ name: 'home' });
          this.$cove.notify({ message: this.$t('organization.warning.noAssociation'), color: 'warning' });
        }
      });
  }
};
</script>