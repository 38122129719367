import router from '@/plugins/router';
import _ from 'lodash';
import { DateTime, Interval } from 'luxon';
import humanizeDuration from 'humanize-duration';
import { parsePhoneNumber } from 'awesome-phonenumber';
import CountryPhone from '@/application/countryPhone';
import cove from '@ctrack/cove';
import store from '@/plugins/store';
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';

/**
 * Proxies through to the standard router replace mechanism however it will silently ignore any errors resulting from replacing the current
 * URL with the same one.
 *
 * @param {*} options any standard router.replace(...) options
 */
export function replaceIgnoreDuplicate (options)
{
  router.replace(options).catch((err) => {
    if (err.name === 'NavigationDuplicated')
    {
      return undefined;
    }
    else
    {
      return Promise.reject(err);
    }
  });
}

/**
 * Checks if the current execution / global event is due to a click event on a Vuetify table header. Vuetify will fire sorting events even
 * if we change the sort outside of the column headers, e.g. via form inputs. This can lead to double sort executions and worse, stale data
 * (seems like a Vuetify bug). Regardless, this function is used to filter out "noise" requests from the Vuetify table so that we only act
 * upon true column header clicks.
 *
 * @returns boolean whether or not current execution is due to the clicking of a column header
 */
export function isTableSortClickEvent ()
{
  let result = false;
  let e = window.event;
  if (!_.isNil(e) && e.type === 'click' && e.currentTarget.nodeName.toLowerCase() === 'th')
  {
    result = true;
  }

  return result;
}

/**
 * Scrolls the page to the specified selector expression with optional offset. Simply use 'html' as the selector to scroll to the very top
 * of the page.
 *
 * @param {*} selector
 * @param {*} offset
 */
export function scrollTo (selector, offset = 16) {
  // next tick to allow UI changes first
  Vue.nextTick(() => {
    vuetify.framework.goTo(selector, { offset });
  });
}

/**
 * Scrolls the sidebar to the specified selector expression with optional offset. Since there could be multiple nav drawers on the page,
 * e.g. the left-nav plus a filter sidebar, we'll attempt to find the correct container by first looking for the specified target/selector
 * element within a nav drawer wrapper.
 *
 * @param {*} selector
 * @param {*} offset
 */
export function scrollSidebarTo (selector, offset = -32) {
  // next tick to allow UI changes first
  Vue.nextTick(() => {
    let anchor = document.querySelector('.v-navigation-drawer__content ' + selector);
    if (!_.isNil(anchor))
    {
      let container = anchor.closest('.v-navigation-drawer__content');
      vuetify.framework.goTo(selector, { offset, container });
    }
  });
}

/**
 * Formats a phone number into the preferred format for the specified country.
 *
 * @param {String} input
 * @param {String} countryCode
 */
export function formatPhoneInput (input, countryCode) {
  let result = input;
  if (!_.isNil(input))
  {
    let phoneNumber = parsePhoneNumber(input, countryCode);
    if (phoneNumber.isValid())
    {
      result = phoneNumber.getNumber('international').replace(/^\+\d+\s/, '');
    }
  }

  return result;
}

/**
 * Formats a phone number from the database into a suitable human readable display, including internation dial code and formatted into the
 * native phone number pattern. For example "+1 4843681234" becomes "+1 484-368-1234".
 *
 * @param {String} phoneNumber
 * @returns
 */
export function formatPhoneDisplay (phoneNumber) {
  let result = null;
  if (!_.isNil(phoneNumber))
  {
    let parsed = parsePhoneNumber(phoneNumber);
    if (parsed.isValid())
    {
      result = parsed.getNumber('international');
    }
  }

  return result;
}

/**
 * Returns a CountryPhone object representing the country assoicated with the specified phone number.
 *
 * @param {String} phoneNumber
 * @returns
 */
export function getCountryForPhoneNumber (phoneNumber) {
  let iso = parsePhoneNumber(phoneNumber).getRegionCode();
  return _.find(CountryPhone, { iso });
}

/**
 * Formats a phone number into the expected format used by the backend API.
 *
 * @param {Object} countryPhone
 * @param {String} phoneNumber
 */
export function formatPhoneForSaving (countryPhone, phoneNumber) {
  return `+${countryPhone.dialCode} ${phoneNumber.replace(/\D/g, '')}`;
}

/**
 * Validates if the specified phone number input is valid for the specified country code.
 *
 * @param {String} input
 * @param {String} countryCode
 */
export function isPhoneValid (input, countryCode) {
  let result = false;
  if (!_.isNil(input))
  {
    let phoneNumber = parsePhoneNumber(input, countryCode);
    result = phoneNumber.isValid();
  }

  return result;
}

/**
 * Returns a human readable description of the duration between the two input dates. Will be localized to the current user's language.
 *
 * @param {String} startDateISO
 * @param {String} endDateISO
 * @returns String
 */
export function formatDuration (startDateISO, endDateISO)
{
  let startDate = DateTime.fromISO(startDateISO);
  let endDate = DateTime.fromISO(endDateISO);
  let durationMillis = Interval.fromDateTimes(startDate, endDate).toDuration().valueOf();

  return humanizeDuration(durationMillis, {
    maxDecimalPoints: 0,
    delimiter: ' ',
    units: ['d', 'h', 'm', 's'],
    largest: 2, // only show the 2 largest populated units
    language: store.getters['user/getLanguage'],
    fallbacks: ['en']
  });
}

/**
 * Generates the display name value for the specified actor bean.
 *
 * @param {Object} actor
 * @returns
 */
export function generateDisplayName (actor)
{
  let result = '';
  if (actor.person)
  {
    result += (actor.prefix ? actor.prefix + ' ' : '');
    result += actor.firstName + ' ';
    result += (actor.middleName ? actor.middleName + ' ' : '');
    result += actor.lastName;
    result += (actor.suffix ? ' ' + actor.suffix : '');
  }
  else
  {
    result = actor.organizationName;
  }

  return result;
}

/**
 * Generates the sort name value for the specified actor bean.
 *
 * @param {Object} actor
 * @returns
 */
export function generateSortName (actor)
{
  let result = '';
  if (actor.person)
  {
    result += actor.lastName + ', ';
    result += actor.firstName;
    result += (actor.middleName ? ' ' + actor.middleName : '');
    result += (actor.prefix ? ', ' + actor.prefix : '');
    result += (actor.suffix ? ', ' + actor.suffix : '');
  }
  else
  {
    result = actor.organizationName;
  }

  return result;
}

/**
 * Given a specified event, locates where it occurred and focuses on the next focusable element.
 *
 * @param {Event} event
 */
export function focusNext (event)
{
  let element = event.currentTarget;
  let focusable = Array.from(document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'));
  let elementIndex = focusable.indexOf(element);
  let nextElement = focusable[elementIndex + 1];
  nextElement.focus();
}

/**
 * Utility method to run some common file validations at the same time.
 *
 * @param {File} file
 * @param {String} documentType
 * @param {String} labelKey
 * @param {String} vKey
 */
export function validateFile (file, documentType, labelKey, vKey)
{
  let validFileTypes = store.state.application.fileTypes[documentType];
  if (validFileTypes === undefined)
  {
    throw new Error('Missing valid file types for [' + documentType + ']. This is likely because they haven\'t been ' +
      'loaded into the store yet which should always occur before this point.');
  }

  let hasData = cove.validation.file.rejectIfEmpty(file.size, labelKey, vKey);
  if (hasData)
  {
    // do not validate client side if the content type is unknown by the browser
    // the invalid type will be validated server-side using the file extension
    let contentType = file.type;
    if (!_.isNil(contentType) && contentType.length > 0)
    {
      let allowedTypes = _.map(validFileTypes, 'contentType');
      let validType = cove.validation.file.rejectIfInvalidType(contentType, allowedTypes, labelKey, vKey);
      if (validType)
      {
        let fileType = _.find(validFileTypes, { contentType });
        if (!_.isNil(fileType.maxFileSize))
        {
          cove.validation.file.rejectIfGreaterThan(file.size, fileType.maxFileSize, labelKey, vKey);
        }
      }
    }
  }
}

/**
 * Sets focus on the element specified by the selector. Will continue to wait if the element is in the process of being added to DOM or if
 * it's being animated and can't be focused yet.
 *
 * @param {String} selector DOM selector string
 */
export function focusElement (selector)
{
  let hasFocus = false;
  let element = document.querySelector(selector);
  if (element !== null)
  {
    element.focus();
    if (document.activeElement === element)
    {
      hasFocus = true;
    }
  }

  if (!hasFocus)
  {
    let observer = new MutationObserver((m) => {
      let element = document.querySelector(selector);
      if (element !== null)
      {
        element.focus();
        if (document.activeElement === element)
        {
          observer.disconnect();
        }
      }
    });

    observer.observe(document, { subtree: true, childList: true });
  }
}