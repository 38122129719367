<template>
  <loading-card :loading="purchasedDocuments === null">
    <template #default>
      <v-card-title>
        {{ $t('page.documentCart.documentsCard.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.documentCart.documentsCard.description') }}
      </v-card-subtitle>

      <v-data-table :items="purchasedDocuments"
                    :headers="headers"
                    item-key="resourceID"
                    must-sort
                    hide-default-footer
                    class="elevation-0"
                    :mobile-breakpoint="800"
                    disable-pagination
                    @update:options="updateOptions">

        <template #item.caseNumber="{ item }">
          <div>
            <div style="white-space: nowrap">
              <router-link :to="{ name: 'caseView', params: { courtID: item.court.resourceID,
                                                              caseUUID: item.caseIdentifier} }">
                {{ item.caseNumber }}
              </router-link>
            </div>
            <div>
              {{ item.caseTitle }}
            </div>
          </div>
        </template>

        <template #item.docketEntryFiledDate="{ value }">
          {{ value | formatDate }}
        </template>

        <template #item.pageCount="{ value }">
          <template v-if="!isNil(value) && value > 0">
            {{ value }}
          </template>
        </template>

        <template #item.resourceID=" { value }">
          <div>
            <v-btn icon @click="remove(value)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </template>

        <template #footer>
          <paging-controls :page="page" @changePage="changePage"/>
        </template>
        <template #tableFooter>

        </template>
      </v-data-table>
      <div class="text-right pb-2 pr-2">
        <c-btn v-if="!isCartEmpty" type="primary" class="pa-4" @click="removeAll">
          {{ $t('application.button.removeAll') }}
        </c-btn>
      </div>

    </template>
  </loading-card>
</template>

<script>
import LoadingCard from '@components/support/LoadingCard';
import PagingControls from '@components/support/PagingControls';
import _ from 'lodash';
import { isTableSortClickEvent } from '@/application/utility';

export default {
  components: { LoadingCard, PagingControls },
  props: {
    purchasedDocuments: {
      type: Array
    },
    page: {
      type: Object
    }
  },
  computed: {
    isSingleCourtMode () {
      return this.$store.getters['application/isSingleCourtMode'];
    },
    isCartEmpty () {
      return _.isEmpty(this.purchasedDocuments);
    },
    headers () {
      let headers = [];
      if (!this.isSingleCourtMode)
      {
        headers.push({ text: this.$t('page.documentCart.documentsCard.court'),
          value: 'court.displayName', sortable: false });
      }
      headers.push({ text: this.$t('page.documentCart.documentsCard.caseNumber'),
        value: 'caseNumber', sortable: false });
      headers.push({ text: this.$t('page.documentCart.documentsCard.docketEntry'),
        value: 'docketEntryExternalName', sortable: false });
      headers.push({ text: this.$t('page.documentCart.documentsCard.docketEntryFiledDate'),
        value: 'docketEntryFiledDate', sortable: false });
      headers.push({ text: this.$t('page.documentCart.documentsCard.documentName'),
        value: 'documentName', sortable: false });
      headers.push({ text: this.$t('page.documentCart.documentsCard.pageCount'),
        value: 'pageCount', sortable: false });
      headers.push({ text: this.$t('global.remove'), align: 'right',
        value: 'resourceID', sortable: false });

      return headers;
    }
  },
  methods: {
    updateOptions (options)
    {
      if (isTableSortClickEvent())
      {
        let sortBy = options.sortBy[0];
        let sortDesc = options.sortDesc[0];
        this.$emit('changeSort', { sortBy, sortDesc });
      }
    },
    changePage (newPage) {
      this.$emit('changePage', newPage);
    },
    remove (purchasedDocumentResourceID) {
      this.$emit('remove', purchasedDocumentResourceID);
    },
    removeAll () {
      this.$emit('removeAll');
    }
  }
};
</script>