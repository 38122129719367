<template>
  <loading-card :loading="!ready">
    <v-card-title>
      {{ $t('page.caseView.partiesCard.title') }}
    </v-card-title>
    <v-card-subtitle v-if="hasParties">
      {{ $t('page.caseView.partiesCard.description') }}
    </v-card-subtitle>
    <v-card-text v-if="hasParties" class="pb-0">
      <div v-for="filer in filers" class="d-flex align-center pb-3" :key="filer.partyHeader.partyActorInstance.displayName">
        <div class="pr-3 text-right" style="flex:0 0 44%;min-width:0">
          <div class="text-truncate font-weight-medium font-95">
            {{ filer.partyHeader.partyActorInstance.displayName }}
          </div>
          <div class="text-truncate label" style="font-size:11px">
            {{ filer.partyHeader.partySubType }}
          </div>
        </div>
        <template v-if="filer._showAttorney">
          <v-divider/>
          <div class="pl-3" style="flex:0 0 44%;min-width:0">
            <div class="text-truncate font-weight-medium font-95">
              {{ filer._attorneyName }}
            </div>
            <div class="text-truncate label text-left" style="font-size:11px">
              {{ $t('page.caseView.partiesCard.representation') }}
            </div>
          </div>
        </template>
      </div>

      <div v-for="responder in responders" class="d-flex align-center pb-3" :key="responder.partyHeader.partyActorInstance.displayName">
        <div class="pr-3 text-right" style="flex:0 0 44%;min-width:0">
          <div class="text-truncate font-weight-medium font-95">
            {{ responder.partyHeader.partyActorInstance.displayName }}
          </div>
          <div class="text-truncate label" style="font-size:11px">
            {{ responder.partyHeader.partySubType }}
          </div>
        </div>
        <template v-if="responder._showAttorney">
          <v-divider/>
          <div class="pl-3" style="flex:0 0 44%;min-width:0">
            <div class="text-truncate font-weight-medium font-95">
              {{ responder._attorneyName }}
            </div>
            <div class="text-truncate label text-left" style="font-size:11px">
              {{ $t('page.caseView.partiesCard.representation') }}
            </div>
          </div>
        </template>
      </div>
    </v-card-text>
    <v-card-text v-else>
      <div class="font-italic text-minimum-gray text-center pb-3">
        {{ $t('page.caseView.partiesCard.noneFound') }}
      </div>
    </v-card-text>
  </loading-card>
</template>

<script>
import LoadingCard from '@components/support/LoadingCard';
import axios from 'axios';
import _ from 'lodash';

export default {
  components: { LoadingCard },
  props: {
    courtID: {
      type: String
    },
    caseInstance: {
      type: Object
    },
    isVerifiedParty: {
      type: Boolean
    }
  },
  data () {
    return {
      ready: false,
      filers: [],
      responders: []
    };
  },
  computed: {
    hasParties () {
      return this.filers.length > 0 || this.responders.length > 0;
    }
  },
  watch: {
    caseInstance: {
      immediate: true,
      handler (newValue) {
        if (!_.isNil(newValue))
        {
          this.loadParties();
        }
        else
        {
          this.ready = false;
          this.filers = [];
          this.responders = [];
        }
      }
    }
  },
  methods: {
    setAttorneyValues (party) {
      if (party.proSeFlag === true)
      {
        party._showAttorney = true;
        party._attorneyName = this.$t('global.selfRep');
      }
      else
      {
        _.orderBy(party.legalRepresentations, 'primaryFlag', 'desc').map((rep) => {
          if (!party._showAttorney)
          {
            if (!_.isNil(rep.attorneyPartyHeader))
            {
              party._showAttorney = true;
              party._attorneyName = rep.attorneyPartyHeader.partyActorInstance.displayName;
            }
            else if (!_.isNil(rep.legalOrganizationPartyHeader))
            {
              party._showAttorney = true;
              party._attorneyName = rep.legalOrganizationPartyHeader.partyActorInstance.displayName;
            }
          }
        });
      }
    },
    loadParties () {
      let promises = [];

      let filersURL = this.$cove.getAPI({
        name: 'api.case.parties',
        params: { courtID: this.courtID, caseUUID: this.caseInstance.caseInstanceUUID },
        query: { involvementTypeID: this.CONST.INVOLVMENT_TYPE_FILING_PARTY, sort: ['orderBy,asc', 'partyNumber,asc'], size: 2 }
      });

      promises.push(
        axios.get(filersURL)
          .then((response) => {
            this.filers = _.get(response, 'data._embedded.results', []);
            this.filers.forEach((f) => { this.setAttorneyValues(f); });
          })
      );

      let respondersURL = this.$cove.getAPI({
        name: 'api.case.parties',
        params: { courtID: this.courtID, caseUUID: this.caseInstance.caseInstanceUUID },
        query: { involvementTypeID: this.CONST.INVOLVMENT_TYPE_RESPONDING_PARTY, sort: ['orderBy,asc', 'partyNumber,asc'], size: 2 }
      });

      promises.push(
        axios.get(respondersURL)
          .then((response) => {
            this.responders = _.get(response, 'data._embedded.results', []);
            this.responders.forEach((r) => { this.setAttorneyValues(r); });
          })
      );

      Promise.all(promises).finally(() => {
        this.ready = true;
      });
    }
  }
};
</script>