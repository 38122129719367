import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from '../styles/variables.scss';
import i18n from './i18n';

const PATTERN = /^\$/;

Vue.use(Vuetify);

const vuetifyInstance = new Vuetify({
  // override to use i18n for translations
  lang: {
    t: (key, ...params) => {
      // remove leading '$' from vuetify messages - cleans up / standardize our message resource keys, e.g. 'vuetify.KEY' vs '$vuetify.KEY'
      return i18n.t(key.replace(PATTERN, ''), params);
    }
  },
  breakpoint: {
    thresholds: {
      lg: 1660,
      md: 1360,
      sm: 1024,
      xs: 600
    },
    scrollbarWidth: 16,
    mobileBreakpoint: 'md'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: colors.primary,
        secondary: colors.secondary,
        accent: colors.accent,
        success: colors.success,
        error: colors.error,
        info: colors.info,
        warning: colors.warning,
        icon: colors.icon,
        anchor: colors.anchor
      },
      dark: {
        primary: colors.primaryDark,
        secondary: colors.secondaryDark,
        accent: colors.accentDark,
        success: colors.successDark,
        error: colors.errorDark,
        info: colors.infoDark,
        warning: colors.warningDark,
        icon: colors.iconDark,
        anchor: colors.anchorDark
      }
    }
  }
});

// hidden utility method to quickly change the theme via the JS console
window._toggleTheme = () => {
  vuetifyInstance.framework.theme.dark = !vuetifyInstance.framework.theme.dark;
};

export default vuetifyInstance;