<template>
  <page-wrapper :title="$t('page.searchHome.title')">
    <v-row>
      <v-col md="7" xl="6">
        <v-card>
          <v-card-title>
            {{ $t('page.searchHome.searchType.title') }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('page.searchHome.searchType.description') }}
          </v-card-subtitle>
          <v-card-text>
            <v-list class="transparent">
              <template v-for="(searchType, i) in validSearchTypes">
                <action-list-item
                  :key="searchType"
                  :to="{ name: 'searchCriteria', params: { searchType } }"
                  :icon="getIconBySearchType(searchType)"
                  :title="$t(`searchType.${searchType}.label`)"
                  :description="$t(`searchType.${searchType}.description`)"
                />
                <v-divider v-if="i !== validSearchTypes.length - 1" :key="searchType + '-divider'"/>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import PageWrapper from '@components/support/PageWrapper';
import ActionListItem from '@components/support/ActionListItem';
import Search from '@/application/search';
import PageMixin from '@/mixins/page';

export default {
  components: { PageWrapper, ActionListItem },
  mixins: [ PageMixin ],
  computed: {
    validSearchTypes () {
      return Search.getValidSearchTypes();
    }
  },
  methods: {
    getIconBySearchType: Search.getIconBySearchType
  }
};
</script>
