import Search from '@/application/search';
import _ from 'lodash';

export default {
  props: {
    searchType: {
      type: String
    },
    searchDTO: {
      type: Object
    }
  },
  computed: {
    queryTypes () {
      return _.orderBy([
        { text: this.$t('global.queryType.contains'), value: this.CONST.QUERY_TYPE_CONTAINS },
        { text: this.$t('global.queryType.exact'), value: this.CONST.QUERY_TYPE_EXACT },
        { text: this.$t('global.queryType.match'), value: this.CONST.QUERY_TYPE_MATCH },
        { text: this.$t('global.queryType.startsWith'), value: this.CONST.QUERY_TYPE_STARTS_WITH },
        { text: this.$t('global.queryType.phonetic'), value: this.CONST.QUERY_TYPE_PHONETIC_MATCH }
      ], 'text');
    },
    courts () {
      return this.$store.state.application.courts;
    },
    isSingleCourtMode () {
      return this.$store.getters['application/isSingleCourtMode'];
    },
    actorCategories () {
      return _.orderBy([
        { text: this.$t('global.actorType.person'), actorCategoryID: this.CONST.ACTOR_CATEGORY_PERSON },
        { text: this.$t('global.actorType.organization'), actorCategoryID: this.CONST.ACTOR_CATEGORY_ORGANIZATION }
      ], 'text');
    }
  },
  methods: {
    getDefaultQueryType: Search.getDefaultQueryType
  }
};