<template>
  <loading-area :loading="initialLoad" :height="350">
    <transition name="scroll-x-reverse-transition" mode="out-in">
      <v-card v-if="hasSeenStep" key="serviceTable">
        <v-card-title>
          {{ $t('page.manageFiling.service.table.title') }}
        </v-card-title>
        <v-card-subtitle v-if="serviceRequired">
          {{ $t('page.manageFiling.service.table.description') }}
        </v-card-subtitle>

        <div v-if="!serviceRequired"
             class="mt-4 text-minimum-gray text-center mx-auto"
             style="font-size:1.1em;max-width:55ch;">
          {{ $t('page.manageFiling.service.noInputRequired') }}
        </div>

        <v-data-table
          v-else
          v-model="selected"
          :items="recipients"
          :headers="headers"
          :loading="tableLoading"
          class="mx-sm-4"
          item-key="resourceID"
          show-select
          checkbox-color="primary"
          disable-sort
          disable-pagination
          hide-default-footer
          :mobile-breakpoint="800">
          <template #item.eServiceEligible="{ value }">
            <template v-if="value">
              <v-icon>
                mdi-check
              </v-icon>
              <span class="d-sr-only">
                {{ $t('global.yes') }}
              </span>
            </template>
            <template v-else>
              <span class="d-sr-only">
                {{ $t('global.no') }}
              </span>
            </template>
          </template>
          <template #item.method="{ item }">
            <div>
              {{ item.filingServiceMethodName }}
            </div>
            <div v-if="item.serviceDate" class="text-minimum-gray">
              {{ item.serviceDate | formatDate }}
            </div>
          </template>
          <template #item.representingNames="{ value }">
            <div v-for="name in value" :key="name">
              {{ name }}
            </div>
          </template>
          <template #footer>
            <paging-controls :page="page" :selected-count="selected.length" read-only/>
          </template>
        </v-data-table>

        <div class="pa-3 mt-12 text-right">
          <c-btn v-if="serviceRequired" @click="setService">
            {{ $t('page.manageFiling.service.button.setService') }}
          </c-btn>
          <c-btn v-if="isStepComplete" type="primary" @click="$emit('next')">
            {{ $t('global.continue') }}
          </c-btn>
        </div>

        <set-service-popup v-model="setServicePopupVisible" :recipients="selected" @reload="reload"/>
      </v-card>

      <service-splash-card v-else
                           key="splashCard"
                           @view="viewTable"/>

    </transition>
  </loading-area>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import LoadingArea from '@components/support/LoadingArea';
import ServiceSplashCard from '@components/support/manageFiling/service/ServiceSplashCard';
import SetServicePopup from '@components/support/manageFiling/service/SetServicePopup';
import PagingControls from '@components/support/PagingControls';

export default {
  components: { LoadingArea, ServiceSplashCard, SetServicePopup, PagingControls },
  data () {
    return {
      initialLoad: true,
      hasSeenStep: false,
      loading: false,
      toggleAll: false,
      setServicePopupVisible: false,
      tableLoading: false,
      recipients: [],
      selected: [],
      docketEntriesToBeServed: [],
      page: {
        size: 0,
        number: 0,
        totalElements: 0
      },
      headers: [
        { text: this.$t('page.manageFiling.service.table.column.name'), value: 'recipientName', sortable: false },
        { text: this.$t('page.manageFiling.service.table.column.role'), value: 'recipientRole', sortable: false },
        { text: this.$t('page.manageFiling.service.table.column.representing'), value: 'representingNames', sortable: false },
        { text: this.$t('page.manageFiling.service.table.column.methodAndDate'), value: 'method', sortable: false },
        { text: this.$t('page.manageFiling.service.table.column.eServiceEligible'), value: 'eServiceEligible', sortable: false,
          align: 'center' }
      ]
    };
  },
  computed: {
    filingID () {
      return this.$store.state.filing.filingID;
    },
    isStepComplete () {
      return this.$store.getters['filing/isStepComplete'](this.CONST.FILING_STEP_SERVICE);
    },
    selectedCount () {
      return this.selected.length;
    },
    serviceRequired () {
      return this.docketEntriesToBeServed.length > 0;
    }
  },
  created () {
    this.hasSeenStep = this.$store.getters['filing/hasSeenStep'];
    if (!this.hasSeenStep)
    {
      this.$store.commit('filing/setMaxSeenStep', this.$store.getters['filing/getSelectedStepID']);
    }

    let promises = [this.loadDocketEntries(), this.loadRecipients()];
    Promise.all(promises)
      .then(this.setEligibleEService)
      .then((updatedService) => {
        return updatedService ? this.refreshView() : null;
      })
      .finally(() => {
        this.initialLoad = false;
      });
  },
  methods: {
    viewTable () {
      this.hasSeenStep = true;
    },
    loadDocketEntries () {
      let url = this.$cove.getAPI({
        name: 'api.filing.docketEntries',
        query: { filingID: this.filingID, excludeFromService: false }
      });

      return axios.get(url).
        then((response) => {
          this.docketEntriesToBeServed = _.get(response, 'data._embedded.results', []);
        });
    },
    loadRecipients () {
      let url = this.$cove.getAPI({ name: 'api.filing.recipients', params: { id: this.filingID } });
      return axios.get(url).
        then((response) => {
          this.recipients = _.defaultTo(response.data, []);
          this.page.size = this.recipients.length;
          this.page.totalElements = this.recipients.length;
        });
    },
    setEligibleEService () {
      let promise = Promise.resolve(false);
      let eligibleRecipients = _.filter(this.recipients, { eServiceEligible: true });
      let notYetServed = _.filter(eligibleRecipients, (r) => { return _.isNil(r.filingServiceMethodName); });

      if (!_.isEmpty(notYetServed))
      {
        let filingServiceRecipients = _.groupBy(notYetServed, 'recipientType');
        _.forEach(filingServiceRecipients, (value, key) => {
          filingServiceRecipients[key] = _.map(value, 'resourceID');
        });

        let payload = {
          filingID: this.filingID,
          filingServiceRecipients,
          filingServiceMethodID: this.CONST.FILING_SERVICE_METHOD_ESERVICE
        };

        promise = axios.post(this.$cove.getAPI({ name: 'api.filing.services' }), payload)
          .then(() => {
            return true;
          });
      }

      return promise;
    },
    setService () {
      if (this.selectedCount > 0)
      {
        this.setServicePopupVisible = true;
      }
      else
      {
        this.$cove.notify({ color: 'warning', message: this.$t('page.manageFiling.service.warn.mustSelectRecipient') });
      }
    },
    reload () {
      this.recipients = [];
      this.selected = [];
      this.tableLoading = true;

      this.refreshView()
        .finally(() => {
          this.tableLoading = false;
        });
    },
    refreshView () {
      let promises = [];
      promises.push(this.loadRecipients());
      promises.push(this.$store.dispatch('filing/toggleSteps', { visible: true, refresh: true }));

      return Promise.all(promises);
    }
  }
};
</script>