<template>
  <c-dialog :value="value && !loading"
            close-key="cove.general.close"
            title-key="popup.caseGroups.title"
            @input="close"
            width="900">
    <div class="mt-4">
      <div class="px-4">
        <v-alert
          :value="true"
          class="mb-5"
          rounded
          icon="mdi-information"
          color="info"
          text>
          {{ $t('popup.caseGroups.disclaimer') }}
        </v-alert>

        <case-group-tables :caseGroups="caseGroups" @navigating="close"/>
      </div>
    </div>
  </c-dialog>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import CaseGroupTables from '@components/support/caseView/CaseGroupTables';

export default {
  components: { CaseGroupTables },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    courtID: {
      type: String
    },
    caseInstance: {
      type: Object
    }
  },
  data () {
    return {
      caseGroups: [],
      loading: false
    };
  },
  watch: {
    value (newValue) {
      if (newValue)
      {
        this.loadData();
      }
      else
      {
        this.caseGroups = [];
      }
    }
  },
  methods: {
    loadData () {
      this.$cove.block();
      this.loading = true;

      let url = this.$cove.getAPI({
        name: 'api.case.groups',
        params: {
          courtID: this.courtID,
          caseUUID: this.caseInstance.caseInstanceUUID
        }
      });

      axios.get(url)
        .then((response) => {
          let caseGroups = _.defaultTo(response.data, []);
          caseGroups.forEach((cg) => {
            cg.caseLinks = _.orderBy(cg.caseLinks, ['leadCaseFlag', 'caseNumber'], ['desc', 'asc']);
          });

          this.caseGroups = caseGroups;
        })
        .finally(() => {
          this.loading = false;
          this.$cove.unblock();
        });
    },
    close () {
      this.$emit('input', false);
    }
  }
};
</script>