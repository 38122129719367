<template>
  <v-card>
    <v-card-title>
      {{ announcement.subject }}
    </v-card-title>
    <v-card-subtitle>
      <span v-if="isPending">
        {{ $t('announcement.subject.pending.description', [$cove.formatDate(announcement.startDate)]) }}
      </span>
      <span v-else-if="isExpired">
        {{ $t('announcement.subject.expired.description', [$cove.formatDate(announcement.endDate)]) }}
      </span>
      <span v-else>
        {{ $t('announcement.subject.posted.description', [$cove.formatDate(announcement.startDate)]) }}
      </span>
    </v-card-subtitle>
    <v-card-text>
      <div class="hide-if-empty mb-4">
        <v-chip v-if="hasSession && announcement.read === false" dark label small color="info darken-1" class="portal-tag mr-2">
          {{ $t('announcementCard.tag.new') }}
        </v-chip>
        <v-chip v-if="announcement.homePage" dark label small color="info darken-1" class="portal-tag mr-2">
          {{ $t('announcementCard.tag.homePage') }}
        </v-chip>
        <v-chip v-if="announcement.critical" dark label small color="error darken-1" class="portal-tag">
          {{ $t('announcementCard.tag.important') }}
        </v-chip>
      </div>
      <div class="markdown" v-html="body"></div>
    </v-card-text>
    <v-card-actions class="justify-end">
      <c-btn
        v-if="hasDocument"
        type="primary"
        :href="viewDocumentURL"
        target="_blank">
        {{ $t('application.button.viewFile') }}
      </c-btn>
      <c-btn
        v-if="isAdmin"
        type="primary"
        :to="{ name: 'manageAnnouncement', params: { announcementID: announcement.resourceID } }">
        {{ $t('application.button.edit') }}
      </c-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { marked } from 'marked';
import { DateTime } from 'luxon';
import _ from 'lodash';

export default {
  props: {
    announcement: {
      type: Object
    }
  },
  computed: {
    body () {
      return marked.parse(this.announcement.body, { breaks: true });
    },
    hasSession () {
      return this.$store.state.user.hasSession;
    },
    hasDocument () {
      return !_.isNil(this.announcement.documentLinkID);
    },
    isAdmin () {
      return this.$store.getters['user/isAdmin'];
    },
    isPending () {
      return DateTime.fromISO(this.announcement.startDate).diffNow().milliseconds > 0;
    },
    isExpired () {
      return this.announcement.endDate !== undefined && this.announcement.endDate !== null &&
        DateTime.fromISO(this.announcement.endDate).diffNow().milliseconds < 0;
    },
    viewDocumentURL () {
      return this.$cove.getAPI({
        name: 'api.dms.document',
        params: { documentLinkID: this.announcement.documentLinkID }
      });
    }
  }
};
</script>
