var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('loading-card',{attrs:{"loading":_vm.purchasedDocuments === null},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('page.documentCart.documentsCard.title'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t('page.documentCart.documentsCard.description'))+" ")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"items":_vm.purchasedDocuments,"headers":_vm.headers,"item-key":"resourceID","must-sort":"","hide-default-footer":"","mobile-breakpoint":800,"disable-pagination":""},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.caseNumber",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('router-link',{attrs:{"to":{ name: 'caseView', params: { courtID: item.court.resourceID,
                                                            caseUUID: item.caseIdentifier} }}},[_vm._v(" "+_vm._s(item.caseNumber)+" ")])],1),_c('div',[_vm._v(" "+_vm._s(item.caseTitle)+" ")])])]}},{key:"item.docketEntryFiledDate",fn:function(ref){
                                                            var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]}},{key:"item.pageCount",fn:function(ref){
                                                            var value = ref.value;
return [(!_vm.isNil(value) && value > 0)?[_vm._v(" "+_vm._s(value)+" ")]:_vm._e()]}},{key:"item.resourceID",fn:function( ref){
                                                            var value = ref.value;
return [_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.remove(value)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]}},{key:"footer",fn:function(){return [_c('paging-controls',{attrs:{"page":_vm.page},on:{"changePage":_vm.changePage}})]},proxy:true},{key:"tableFooter",fn:function(){return undefined},proxy:true}])}),_c('div',{staticClass:"text-right pb-2 pr-2"},[(!_vm.isCartEmpty)?_c('c-btn',{staticClass:"pa-4",attrs:{"type":"primary"},on:{"click":_vm.removeAll}},[_vm._v(" "+_vm._s(_vm.$t('application.button.removeAll'))+" ")]):_vm._e()],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }