<template>
  <loading-card :loading="loading" class="nav-tab-medium">
    <template #default>
      <v-card-title>
        {{ $t('page.manageOrg.documentAccess.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.manageOrg.documentAccess.description') }}
      </v-card-subtitle>
      <v-card-text>

        <!-- sys admin view -->
        <mini-card v-if="isAdmin" class="mt-4 mb-8">
          <v-card-text>
            <div class="d-flex pa-2" style="gap:20px">
              <div class="flex-grow-0">
                <v-icon color="primary" style="font-size:50px">
                  mdi-file-document-check
                </v-icon>
              </div>
              <v-divider vertical/>
              <div class="flex-grow-1" style="max-width:300px">
                <div style="font-size:1.5rem">
                  {{ $t('page.manageOrg.documentAccess.card.title') }}
                </div>
                <div class="text-minimum-gray mt-2">
                  {{ $t('page.manageOrg.documentAccess.orgGrant') }}
                </div>
                <div class="mt-6">
                  <c-checkbox
                    v-model="freeDocuments"
                    :label="$t('page.manageOrg.documentAccess.field.grantAccess')"
                    hide-details
                    class="ma-0 pa-0"/>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end mt-8">
            <c-btn type="primary" @click="save">
              {{ $t('global.save') }}
            </c-btn>
          </v-card-actions>
        </mini-card>

        <!-- org admin view -->
        <mini-card v-else class="mt-4 mb-8">
          <v-card-text class="pa-6">
            <div class="d-flex" style="gap:24px">
              <div class="flex-grow-0">
                <v-icon color="primary" style="font-size:50px">
                  mdi-file-document-check
                </v-icon>
              </div>
              <v-divider vertical/>
              <div class="flex-grow-1 text-minimum-gray align-self-center" style="max-width:300px;">
                {{ $t('page.manageOrg.documentAccess.card.description') }}
              </div>
            </div>
          </v-card-text>
        </mini-card>
      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import MiniCard from '@components/support/MiniCard';
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { LoadingCard, MiniCard },
  props: {
    organizationID: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      freeDocuments: false
    };
  },
  computed: {
    isAdmin () {
      return this.$store.getters['user/isAdmin'];
    }
  },
  created () {
    let url = this.$cove.getAPI({ name: 'api.organization', params: { id: this.organizationID } });
    axios.get(url)
      .then((response) => {
        this.freeDocuments = _.defaultTo(response.data.freeDocuments, false);

        // if this user is not a sys admin (ie just an org admin) and they don't have free docs, don't render this card at all
        if (!this.isAdmin && !this.freeDocuments)
        {
          this.$router.replace({ name: 'manage-org' });
        }
        else
        {
          this.loading = false;
        }
      })
      .catch(() => {
        this.loading = false;
      });
  },
  methods: {
    save () {
      this.$cove.block({ delay: 0 });

      let url = this.$cove.getAPI({ name: 'api.organization', params: { id: this.organizationID } });
      axios.patch(url, { freeDocuments: this.freeDocuments })
        .then(() => {
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
        })
        .finally(() => {
          this.$cove.unblock();
        });
    }
  }
};
</script>