<template>
  <div>
    <div class="d-flex align-center mt-7 mb-2">
      <div>
        <v-icon style="font-size:20px" color="icon">
          {{ icon }}
        </v-icon>
      </div>
      <div class="c-caption pl-2">
        {{ title }}
      </div>
      <v-divider class="ml-5" style="opacity:0.5"/>
    </div>
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String
    },
    title: {
      type: String
    }
  }
};
</script>