<template>
  <c-dialog
    :value="value"
    close-key="cove.general.close"
    title-key="popup.fileTypes.title"
    @input="$emit('input', false)">
    <v-data-table
      :headers="fileTypeHeaders"
      :items="fileTypes"
      hide-default-footer
      disable-pagination
      class="my-1">
      <template #item.extension="{ item }">
        <template v-for="(e, i) in item.fileExtensions">
          {{ e }}{{ i !== item.fileExtensions.length - 1 ? ', ' : '' }}
        </template>
      </template>
      <template #item.maxFileSize="{ item }">
        {{ formatFileSize(item.maxFileSize) }}
      </template>
    </v-data-table>
  </c-dialog>
</template>

<script>
import { formatFileSize } from '@/application/application';
import _ from 'lodash';

export default {
  props: {
    value: {
      type: Boolean
    },
    fileType: {
      type: String
    }
  },
  computed: {
    fileTypes () {
      return _.sortBy(this.$store.state.application.fileTypes[this.fileType], 'note');
    },
    fileTypeHeaders () {
      return [
        { text: this.$t('popup.fileTypes.table.fileType'), value: 'note' },
        { text: this.$t('popup.fileTypes.table.extensions'), value: 'extension' },
        { text: this.$t('popup.fileTypes.table.maxSize'), value: 'maxFileSize' },
        { text: this.$t('popup.fileTypes.table.maxPage'), value: 'maxPageSize' }
      ];
    }
  },
  methods: {
    formatFileSize
  }
};
</script>
