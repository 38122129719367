<template>
  <c-dialog
    :value="value && !loading"
    width="900"
    close-key="cove.general.close"
    title-key="popup.eventHearings.title"
    @input="close">

    <v-data-table
      :items="hearings"
      :headers="headers"
      :server-items-length="page.totalElements"
      :loading="loading"
      item-key="resourceID"
      disable-sort
      hide-default-footer
      class="my-1"
      :mobile-breakpoint="800">
      <template #footer>
        <paging-controls :page="page" @changePage="changePage"/>
      </template>
      <template #item.caseHeader.caseNumber=" { item }">
        <router-link @click.native="close" :to="{ name: 'caseView', params: buildCaseViewParams(item) }">
          {{ item.caseHeader.caseNumber }}
        </router-link>
      </template>
      <template #item.startDate="{ value }">
        {{ value | formatDateTime }}
      </template>
    </v-data-table>

  </c-dialog>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import PagingControls from '@components/support/PagingControls';

export default {
  components: { PagingControls },
  props: {
    value: {
      type: Boolean
    },
    courtID: {
      type: String
    },
    eventID: {
      type: String
    }
  },
  data () {
    return {
      loading: false,
      hearings: [],
      page: {
        size: 20,
        number: 0,
        totalElements: 0,
        totalPages: 0
      }
    };
  },
  computed: {
    headers () {
      return [
        { text: this.$t('popup.eventHearings.column.caseNumber'), value: 'caseHeader.caseNumber' },
        { text: this.$t('popup.eventHearings.column.caseTitle'), value: 'caseHeader.caseTitle' },
        { text: this.$t('popup.eventHearings.column.hearingDate'), value: 'startDate' },
        { text: this.$t('popup.eventHearings.column.type'), value: 'hearingType' },
        { text: this.$t('popup.eventHearings.column.status'), value: 'hearingStatus' }
      ];
    }
  },
  watch: {
    value (newValue) {
      if (newValue)
      {
        this.$cove.block();
        this.loading = true;
        this.loadHearings()
          .finally(() => {
            this.loading = false;
            this.$cove.unblock();
          });
      }
      else
      {
        this.hearings = [];
        this.page.number = 0;
      }
    }
  },
  methods: {
    loadHearings () {
      let query = {
        page: this.page.number,
        size: this.page.size,
        sort: 'orderBy,asc'
      };

      return axios.get(this.$cove.getAPI({ name: 'api.event.hearings', params: { courtID: this.courtID, eventID: this.eventID }, query }))
        .then((response) => {
          this.hearings = _.get(response, 'data._embedded.results', []);
          this.page.number = response.data.page.number;
          this.page.totalPages = response.data.page.totalPages;
          this.page.totalElements = response.data.page.totalElements;
        });
    },
    changePage (newPage) {
      this.page.number = newPage;
      this.loadHearings();
    },
    close () {
      this.$emit('input', false);
    },
    buildCaseViewParams (hearing) {
      let court = this.$store.getters['application/getCourtByExternalID'](hearing.caseHeader.courtID);

      return {
        courtID: court.resourceID,
        caseUUID: hearing.caseHeader.caseInstanceUUID
      };
    }
  }
};
</script>