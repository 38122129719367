<template>
  <loading-card :loading="loading" :height="250">
    <v-card-title>
      {{ $t('page.createFiling.step.filingType.title') }}
    </v-card-title>
    <v-card-subtitle>
      <template v-if="isGuidedEntry">
        {{ $t('page.createFiling.step.filingType.guided.description') }}
      </template>
      <template v-else>
        {{ $t('page.createFiling.step.filingType.manual.description') }}
      </template>
    </v-card-subtitle>
    <v-card-text class="pt-2">
      <v-row>
        <v-col cols="12">
          <template v-if="isGuidedEntry">
            <c-text-field
              v-model="search"
              @input="searchInput"
              outlined
              :label="$t('global.search')"
              prepend-inner-icon="mdi-magnify"
              clearable
              hide-details/>

            <transition name="scroll-x-reverse-transition" mode="out-in">
              <v-list v-if="filteredItems.length > 0" class="transparent" key="list">
                <transition name="scroll-x-reverse-transition" mode="out-in">
                  <div :key="filteredItemsKey">
                    <div v-for="(item, i) in filteredItems" :key="item.portalFilingTypeID">
                      <action-list-item
                        @click="save(item)"
                        :title="item.name"
                        :description="item.description"
                        :renderMarkdown="true"/>
                      <v-divider v-if="i !== filteredItems.length - 1"/>
                    </div>
                  </div>
                </transition>
              </v-list>
              <div v-else class="text-minimum-gray text-center font-italic mt-8 mb-5" key="noData">
                {{ $t('global.noneFound') }}
              </div>
            </transition>
          </template>
          <template v-else>
            <template v-if="isNewCaseFiling">
              <c-autocomplete
                v-model="caseClassificationID"
                :items="caseClassifications"
                :label="$t('page.createFiling.step.filingType.field.classification')"
                item-text="displayName"
                item-value="caseClassificationID"
                key="caseClassificationID"
                outlined
                required
                @change="changeClassification"
                hide-details="auto"
                aria-describedby="a8eac090-9495-461d-9c14-41b8eef941a7"/>
              <field-description id="a8eac090-9495-461d-9c14-41b8eef941a7"
                                 message-key="page.createFiling.step.filingType.field.classification.description"/>
            </template>
            <c-autocomplete
              v-model="docketEntrySubtypeID"
              :items="docketEntrySubtypes"
              :label="$t('page.createFiling.step.filingType.field.leadDE')"
              :item-text="renderDocketEntryName"
              item-value="docketEntrySubTypeID"
              key="docketEntrySubtypeID"
              outlined
              required
              hide-details="auto"
              aria-describedby="a34542bb-704e-4c5c-b706-522cdf07a734"/>
            <field-description id="a34542bb-704e-4c5c-b706-522cdf07a734"
                               message-key="page.createFiling.step.filingType.field.leadDE.description"/>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="!isGuidedEntry" class="mt-10 justify-end">
      <c-btn type="primary" @click="selectManualItem">
        {{ $t('global.continue') }}
      </c-btn>
    </v-card-actions>
  </loading-card>
</template>

<script>
import WorkflowStepMixin from '@/mixins/workflowStep';
import axios from 'axios';
import _ from 'lodash';
import { scrollTo } from '@/application/utility';

export default {
  mixins: [ WorkflowStepMixin ],
  data () {
    return {
      filingTypes: [],
      filteredItems: [],
      filteredItemsKey: 0,
      caseClassificationID: null,
      caseClassificationGroupID: null,
      caseClassifications: [],
      docketEntrySubtypeID: null,
      docketEntrySubtypes: [],
      search: null
    };
  },
  computed: {
    isGuidedEntry () {
      return this.bean.guidedEntry;
    },
    isNewCaseFiling () {
      return this.bean.caseType === this.CONST.FILING_CASE_TYPE_NEW;
    }
  },
  methods: {
    searchInput: _.debounce(function (value) { // do NOT use arrow function here
      this.filterItems();
    }, 333),
    filterItems () {
      let matches = this.filingTypes;
      if (this.search !== null)
      {
        let tokens = this.search.match(/\S+/g);
        if (tokens !== null)
        {
          matches = [];
          this.filingTypes.forEach((item) => {
            let fullText = item.name + ' ' + item.description;
            let match = tokens.every((token) => { return fullText.toLowerCase().indexOf(token.toLowerCase()) >= 0; });
            if (match)
            {
              matches.push(item);
            }
          });
        }
      }

      this.filteredItems = matches;
      this.filteredItemsKey++;
    },
    loadData () {
      if (this.bean.caseClass !== null) {
        if (_.isNil(this.caseClassificationID)) {
          this.caseClassificationID = this.bean.caseClass.caseClassificationID;
        }
        if (_.isNil(this.caseClassificationGroupID)) {
          this.caseClassificationGroupID = this.bean.caseClass.caseClassificationGroup.caseClassificationGroupID;
        }
      }

      let query = (this.isGuidedEntry) ?
        { filingCode: this.CONST.FILING_ENTRY_MODE_GUIDED } : { filingCode: this.CONST.FILING_ENTRY_MODE_MANUAL };

      if (this.isNewCaseFiling) {
        query.leadNewCaseFiling = true;
      }
      else {
        query.leadExistingCaseFiling = true;
        query.caseInstanceUUID = this.bean.caseInstanceUUID;
      }
      let url = this.$cove.getAPI({
        name: 'api.filing.types',
        params: { courtID: this.bean.courtID },
        query
      });

      return axios.get(url)
        .then((response) => {
          if (this.isGuidedEntry)
          {
            let filingTypes = _.defaultTo(response.data, []);
            this.filingTypes = _.sortBy(filingTypes, 'name');
            this.filterItems();
          }
          else {
            this.filingTypes = _.defaultTo(response.data, []);

            let caseClassifications = _.uniqBy(this.filingTypes, 'createCaseClass.caseClassificationID');
            caseClassifications = _.map(caseClassifications, 'createCaseClass');
            caseClassifications = _.sortBy(caseClassifications, 'displayName');
            this.caseClassifications = caseClassifications;

            // pre-load if moving backwards through the flow
            if (this.bean.filingType !== null) {
              if (this.isNewCaseFiling) {
                this.caseClassificationID = this.bean.filingType.createCaseClass.caseClassificationID;
              }
              else {
                this.caseClassificationID = this.bean.caseClass.caseClassificationID;
              }
              this.docketEntrySubtypeID = this.bean.filingType.docketEntrySubtype.docketEntrySubTypeID;
            }

            if (!_.isNil(this.caseClassificationID)) {

              this.loadDocketEntrySubTypes();
            }
          }
        });

    },
    renderDocketEntryName (docketEntrySubType) {
      return docketEntrySubType.docketEntryType.docketEntryTypeName + ' - ' + docketEntrySubType.docketEntrySubTypeName;
    },
    changeClassification () {
      this.docketEntrySubtypeID = null;

      if (this.caseClassificationID !== null)
      {
        this.loadDocketEntrySubTypes();
      }
      else
      {
        this.docketEntrySubtypes = [];
      }
    },
    loadDocketEntrySubTypes () {
      let docketEntrySubtypes = this.filingTypes;
      if (this.isNewCaseFiling)
      {
        docketEntrySubtypes = _.filter(this.filingTypes, ['createCaseClass.caseClassificationID', this.caseClassificationID ]);
      }

      docketEntrySubtypes = _.map(docketEntrySubtypes, 'docketEntrySubtype');
      this.docketEntrySubtypes = docketEntrySubtypes;

      if (this.docketEntrySubtypes.length === 1)
      {
        this.docketEntrySubtypeID = this.docketEntrySubtypes[0].docketEntrySubTypeID;
      }
    },
    validateManualSelection () {
      this.$store.commit('cove/errors/clear');

      this.$cove.validation.required(this.caseClassificationID, 'page.createFiling.step.filingType.field.classification',
        'caseClassificationID');
      this.$cove.validation.required(this.docketEntrySubtypeID, 'page.createFiling.step.filingType.field.leadDEe', 'docketEntrySubtypeID');

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    selectManualItem () {
      let isValid = this.validateManualSelection();
      if (!isValid)
      {
        scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
      }
      else
      {
        let filingType = (this.isNewCaseFiling) ? _.find(this.filingTypes, ['createCaseClass.caseClassificationID',
          this.caseClassificationID, 'docketEntrySubtype.docketEntrySubTypeID', this.docketEntrySubtypeID]) :
          _.find(this.filingTypes, [ 'docketEntrySubtype.docketEntrySubTypeID', this.docketEntrySubtypeID]);
        this.save(filingType);
      }
    },
    save (filingType) {
      this.$emit('save', filingType);
    }
  }
};
</script>
