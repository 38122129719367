var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('loading-area',{attrs:{"loading":_vm.initialLoad,"height":350}},[_c('transition',{attrs:{"name":"scroll-x-reverse-transition","mode":"out-in"}},[(_vm.hasSeenStep)?_c('v-card',{key:"table"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('page.manageFiling.docketEntry.table.title'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t('page.manageFiling.docketEntry.table.description'))+" ")]),_c('v-data-table',{staticClass:"mx-sm-4",attrs:{"items":_vm.docketEntries,"headers":_vm.headers,"server-items-length":_vm.page.totalElements,"loading":_vm.loading,"item-key":"resourceID","disable-sort":"","hide-default-footer":"","mobile-breakpoint":800},scopedSlots:_vm._u([{key:"item.lead",fn:function(ref){
var value = ref.value;
return [(value)?[_c('v-icon',[_vm._v(" mdi-check ")]),_c('span',{staticClass:"d-sr-only"},[_vm._v(" "+_vm._s(_vm.$t('global.yes'))+" ")])]:[_c('span',{staticClass:"d-sr-only"},[_vm._v(" "+_vm._s(_vm.$t('global.no'))+" ")])]]}},(_vm.offerExcludeFromService)?{key:"item.excludeFromService",fn:function(ref){
var value = ref.value;
return [(value)?[_c('v-icon',[_vm._v(" mdi-check ")]),_c('span',{staticClass:"d-sr-only"},[_vm._v(" "+_vm._s(_vm.$t('global.yes'))+" ")])]:[_c('span',{staticClass:"d-sr-only"},[_vm._v(" "+_vm._s(_vm.$t('global.no'))+" ")])]]}}:null,{key:"item.documents",fn:function(ref){
var value = ref.value;
return _vm._l((value),function(d){return _c('div',{key:d.resourceID},[_c('a',{attrs:{"href":_vm.viewDocumentURL(d.documentLinkID),"target":"_blank"}},[_vm._v(" "+_vm._s(d.documentName)+" ")])])})}},{key:"item.resourceID",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-horizontal ")]),_c('span',{staticClass:"d-sr-only"},[_vm._v(" "+_vm._s(_vm.$t('global.action'))+" ")])],1)]}}],null,true)},[_c('v-list',{staticStyle:{"min-width":"100px"},attrs:{"dense":""}},[_c('v-list-item',{staticClass:"inset-focus",on:{"click":function($event){return _vm.$emit('edit', item.resourceID)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")])],1),(!item.lead)?_c('v-list-item',{staticClass:"inset-focus",on:{"click":function($event){return _vm.remove(item.resourceID)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('global.remove'))+" ")])],1):_vm._e()],1)],1)]}},{key:"footer",fn:function(){return [_c('paging-controls',{attrs:{"page":_vm.page},on:{"changePage":_vm.changePage}})]},proxy:true}],null,true)}),_c('div',{staticClass:"pa-3 mt-12 text-right"},[_c('c-btn',{on:{"click":function($event){return _vm.$emit('startNew')}}},[_vm._v(" "+_vm._s(_vm.$t('page.manageFiling.docketEntry.button.add'))+" ")]),(_vm.isStepComplete)?_c('c-btn',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$emit('next')}}},[_vm._v(" "+_vm._s(_vm.$t('global.continue'))+" ")]):_vm._e()],1)],1):_c('docket-entries-splash-card',{key:"splash",attrs:{"isLeadComplete":_vm.isLeadComplete},on:{"startLead":function($event){return _vm.$emit('startLead')},"viewDocketEntries":_vm.viewDocketEntries}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }