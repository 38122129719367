<template>
  <loading-card :loading="loading" class="nav-tab-medium">
    <template #default>
      <v-card-title>
        {{ $t('page.manageOrg.paymentMethod.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.manageOrg.paymentMethod.description') }}
      </v-card-subtitle>
      <v-card-text>

        <!-- payment method on file -->
        <template v-if="hasPaymentToken">

          <!-- sys admin view -->
          <mini-card v-if="isAdmin" class="mt-4 mb-8">
            <v-card-text class="pa-6">
              <div class="d-flex" style="gap:24px">
                <div class="flex-grow-0">
                  <img :src="require(`@/assets/paymentClient/${paymentClientName}/logo.png`)" alt=""/>
                </div>
                <v-divider vertical/>
                <div class="flex-grow-1 text-minimum-gray align-self-center" style="max-width:300px;">
                  {{ $t('page.manageOrg.paymentMethod.orgHasPaymentMethod') }}
                </div>
              </div>
            </v-card-text>
          </mini-card>

          <!-- org admin view -->
          <mini-card v-else class="mt-4 mb-8">
            <v-card-text class="pa-6">
              <div class="d-flex" style="gap:24px">
                <div class="flex-grow-0">
                  <img :src="require(`@/assets/paymentClient/${paymentClientName}/logo.png`)" alt=""/>
                </div>
                <v-divider vertical/>
                <div class="flex-grow-1">
                  <dl class="d-grid px-6" style="max-width:300px">
                    <dt>{{ $t('paymentMethod.name') }}</dt>
                    <dd>{{ paymentToken.paymentDescription }}</dd>

                    <!-- credit card display -->
                    <template v-if="isCreditCard">
                      <dt>{{ $t('paymentMethod.type') }}</dt>
                      <dd>{{ paymentToken.cardTypeName }}</dd>
                      <dt>{{ $t('paymentMethod.number') }}</dt>
                      <dd>
                        <span style="font-family:monospace">
                          &bull;&bull;&bull;&bull;
                        </span>
                        {{ paymentToken.cardNumberLast4 }}
                      </dd>
                      <dt>{{ $t('paymentMethod.expiration') }}</dt>
                      <dd>{{ paymentToken.cardExpirationMonth }}/{{ paymentToken.cardExpirationYear }}</dd>
                    </template>

                    <!-- bank account display -->
                    <template v-else>
                      <dt>{{ $t('paymentToken.accountNumber') }}</dt>
                      <dd>{{ paymentToken.accountTypeName }}</dd>
                      <dt>{{ $t('paymentToken.routingNumber') }}</dt>
                      <dd>{{ paymentToken.routingNumber }}</dd>
                      <dt>{{ $t('paymentToken.accountNumber') }}</dt>
                      <dd>
                        <span style="font-family:monospace">
                          &bull;&bull;&bull;&bull;
                        </span>
                        {{ paymentToken.accountNumberLast4 }}
                      </dd>
                    </template>
                  </dl>
                </div>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end mt-4">
              <c-btn type="danger" @click="removePaymentToken">
                {{ $t('global.remove') }}
              </c-btn>
            </v-card-actions>
          </mini-card>
        </template>

        <!-- no payment method on file -->
        <template v-else>

          <!-- sys admin view -->
          <cutout-area v-if="isAdmin" class="mt-4 mb-8">
            {{ $t('page.manageOrg.paymentMethod.orgNoPaymentMethod') }}
          </cutout-area>

          <!-- org admin view -->
          <cutout-area v-else class="mt-4 mb-8">
            <img :src="require(`@/assets/paymentClient/${paymentClientName}/logo.png`)" alt=""/>
            <template #action>
              <c-btn type="primary" @click="addPaymentToken">
                {{ $t('page.manageOrg.paymentMethod.add') }}
                <v-icon right>
                  mdi-open-in-new
                </v-icon>
              </c-btn>
            </template>
          </cutout-area>
        </template>

      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import CutoutArea from '@components/support/CutoutArea';
import LoadingCard from '@components/support/LoadingCard';
import MiniCard from '@components/support/MiniCard';
import { redirectToPaymentProvider } from '@/application/application';

export default {
  components: { LoadingCard, CutoutArea, MiniCard },
  props: {
    organizationID: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      paymentToken: null
    };
  },
  computed: {
    isAdmin () {
      return this.$store.getters['user/isAdmin'];
    },
    paymentClientName () {
      return this.$store.getters['application/getAppProperty'](this.CONST.APP_PROPERTY_PAYMENT_CLIENT);
    },
    hasPaymentToken () {
      return !_.isNil(this.paymentToken);
    },
    isCreditCard () {
      return !_.isEmpty(this.paymentToken.cardTypeName);
    }
  },
  created () {
    this.loadData();
  },
  methods: {
    loadData () {
      this.loading = true;
      let url = this.$cove.getAPI({
        name: 'api.organization',
        params: {
          id: this.organizationID
        },
        query: {
          fields: '*,paymentToken(*)'
        }
      });

      axios.get(url)
        .then((response) => {
          this.paymentToken = _.defaultTo(response.data.paymentToken, null);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addPaymentToken () {
      redirectToPaymentProvider({ organizationID: this.organizationID });
    },
    removePaymentToken () {
      this.$cove.confirm({ message: this.$t('page.manageOrg.paymentMethod.confirmRemove') })
        .then((confirm) => {
          if (confirm)
          {
            this.$cove.block({ delay: 0 });
            axios.delete(this.$cove.getAPI({ name: 'api.paymentToken', params: { id: this.paymentToken.resourceID } }))
              .then(() => {
                this.$cove.notify({ message: this.$t('application.remove.success'), color: 'success' });
                this.loadData();
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    }
  }
};
</script>