<template>
  <page-wrapper :title="$t('global.siteMap')">
    <section class="site-map-grid">
      <div>
        <h2 class="text-h5 mb-3">
          {{ $t('page.siteMap.general') }}
        </h2>
        <ul class="link-list">
          <li>
            <router-link :to="{ name: 'home' }">
              {{ $t('application.navigation.home') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'announcements' }">
              {{ $t('application.navigation.announcements') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'searchHome' }">
              {{ $t('application.navigation.search') }}
            </router-link>
          </li>
        </ul>
      </div>

      <div v-if="hasSession">
        <h2 class="text-h5 mb-3">
          User Account
        </h2>
        <ul class="link-list">
          <li>
            <router-link :to="{ name: 'user-profile' }">
              {{ $t('application.banner.user.profile') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'work-notifications' }">
              {{ $t('application.navigation.work.notifications') }}
            </router-link>
          </li>
          <li v-if="isEnableDocumentPurchaseFunctions">
            <router-link :to="{ name: 'cart' }">
              {{ $t('global.cart') }}
            </router-link>
          </li>
        </ul>
      </div>

      <div v-if="hasSession">
        <h2 class="text-h5 mb-3">
          Create
        </h2>
        <ul class="link-list">
          <li>
            <router-link :to="{ name: 'requestAccess' }">
              {{ $t('application.navigation.create.requestCaseAccess') }}
            </router-link>
          </li>
          <li v-if="isFiler">
            <router-link :to="{ name: 'create-filing' }">
              {{ $t('application.navigation.create.newFiling') }}
            </router-link>
          </li>
        </ul>
      </div>

      <div v-if="hasSession">
        <h2 class="text-h5 mb-3">
          My Work
        </h2>
        <ul class="link-list">
          <li v-if="isFiler">
            <router-link :to="{ name: 'work-filings' }">
              {{ $t('application.navigation.work.filings') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'work-cases' }">
              {{ $t('application.navigation.work.cases') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'work-hearings' }">
              {{ $t('application.navigation.work.hearings') }}
            </router-link>
          </li>
          <li v-if="isInterpreter">
            <router-link :to="{ name: 'work-tasks' }">
              {{ $t('application.navigation.work.tasks') }}
            </router-link>
          </li>
          <li v-if="isInterpreter">
            <router-link :to="{ name: 'work-invites' }">
              {{ $t('application.navigation.work.invites') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'work-receipts' }">
              {{ $t('application.navigation.work.myReceipts') }}
            </router-link>
          </li>
          <li v-if="isEnableDocumentPurchaseFunctions">
            <router-link :to="{ name: 'work-purchased-documents' }">
              {{ $t('application.navigation.work.myPurchasedDocuments') }}
            </router-link>
          </li>
        </ul>
      </div>

      <div v-if="isOrgAdmin">
        <h2 class="text-h5 mb-3">
          Organization
        </h2>
        <ul class="link-list">
          <li v-if="isFiler">
            <router-link :to="{ name: 'my-organization' }">
              {{ $t('application.navigation.manageOrg') }}
            </router-link>
          </li>
        </ul>
      </div>

      <div v-if="isAdmin">
        <h2 class="text-h5 mb-3">
          Administration
        </h2>
        <ul class="link-list">
          <li>
            <router-link :to="{ name: 'admin-search-user' }">
              {{ $t('application.navigation.administration.user.search') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'admin-notifications' }">
              {{ $t('application.navigation.administration.notifications.search') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'admin-subscriptions' }">
              {{ $t('application.navigation.administration.subscriptions') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'admin-kiosk-mode' }">
              {{ $t('kioskMode.title') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'admin-configuration' }">
              {{ $t('application.navigation.administration.configuration') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'admin-message-errors' }">
              {{ $t('application.navigation.administration.messageErrors') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'admin-receipts' }">
              {{ $t('application.navigation.administration.receipts') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'admin-organizations' }">
              {{ $t('application.navigation.administration.organizations') }}
            </router-link>
          </li>
        </ul>
      </div>

      <div>
        <h2 class="text-h5 mb-3">
          {{ $t('page.siteMap.support') }}
        </h2>
        <ul class="link-list">
          <li>
            <router-link :to="{ name: 'about' }">
              {{ $t('application.banner.about') }}
            </router-link>
          </li>
        </ul>
      </div>

    </section>
  </page-wrapper>
</template>

<script>
import PageWrapper from '@components/support/PageWrapper';
import PageMixin from '@/mixins/page';

export default {
  components: { PageWrapper },
  mixins: [ PageMixin ],
  computed: {
    hasSession () {
      return this.$store.state.user.hasSession;
    },
    isEnableDocumentPurchaseFunctions () {
      return this.$store.getters['application/getAppProperty'](this.CONST.FEATURE_PROPERTY_ENABLE_DOCUMENT_PURCHASE_FUNCTIONS);
    },
    isFiler () {
      return this.$store.getters['user/hasRole'](this.CONST.ROLE_FILER);
    },
    isInterpreter () {
      return this.$store.getters['user/hasRole'](this.CONST.ROLE_INTERPRETER);
    },
    isOrgAdmin () {
      return this.$store.getters['user/hasRole'](this.CONST.ROLE_ORGANIZATION_ADMINISTRATOR);
    },
    isAdmin () {
      return this.$store.getters['user/isAdmin'];
    }
  }
};
</script>

<style lang="scss" scoped>
.site-map-grid
{
  display: grid;
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-template-columns: repeat(auto-fit, 320px);
  gap: 40px;
}
</style>