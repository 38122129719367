<template>
  <loading-area :loading="loading">
    <template #default>
      <case-group-tables :caseGroups="caseGroups"/>

      <component v-for="td in tableData"
                 :key="td.title"
                 :is="td.component"
                 class="mb-6"
                 read-only
                 :title="td.title"
                 :items="td.items"
                 :headers="td.headers"
                 :courtID="courtID"
                 :caseInstanceUUID="caseInstance.caseInstanceUUID"
                 :isVerifiedParty="isVerifiedParty"
                 :page="td.page"
                 :sort="{}"/>
    </template>
  </loading-area>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import CaseView from '@/application/caseView';
import LoadingArea from '@components/support/LoadingArea';
import CaseGroupTables from '@components/support/caseView/CaseGroupTables';
import { TaskQueue } from 'cwait';

const TASK_QUEUE = new TaskQueue(Promise, 3);

export default {
  components: { LoadingArea, CaseGroupTables },
  props: {
    courtID: {
      type: String
    },
    caseInstance: {
      type: Object
    },
    isVerifiedParty: {
      type: Boolean
    }
  },
  data () {
    return {
      loading: true,
      tableData: [], // holds objects representing each table, including its data, headers, component, title, etc.
      caseGroups: []
    };
  },
  computed: {
    tabs () {
      return CaseView.getTabsByCaseClassGroupType(this.caseInstance.caseClassGroupTypeID);
    },
    selectedTab () {
      return this.tabs[this.selectedTabIndex];
    }
  },
  watch: {
    caseInstance: {
      immediate: true,
      handler (newValue) {
        this.tableData = [];
        this.caseGroups = [];
        if (!_.isNil(newValue))
        {
          this.loadData();
        }
      }
    }
  },
  methods: {
    getTabTitle (tab) {
      return this.$t(`page.caseView.tab.${tab}`);
    },
    getTabHeaders (tab) {
      return CaseView.getTabHeaders(tab, this.isVerifiedParty);
    },
    getTabComponent (tab) {
      let componentName = _.upperFirst(tab) + 'Tab';
      return () => {
        return import('@components/support/caseView/' + componentName);
      };
    },
    getTabSortableColumns (tab) {
      let filters = _.filter(this.getTabHeaders(tab), { sortable: true });
      filters = filters.concat(CaseView.getAdditionalSorts(tab));
      return _.orderBy(filters, 'text');
    },
    loadData () {
      this.loading = true;
      let promises = [];

      // load all the case tabs/tables
      this.tabs.forEach((tab) => {
        let config = CaseView.getTabAPIConfig(tab);
        let query = {
          page: 0,
          size: this.CONST.CASE_VIEW_PRINTABLE_MAX_TABLE_RESULTS
        };

        let sort = this.generateSortParam(tab, config);
        if (!_.isNil(sort))
        {
          query.sort = sort;
        }

        let url = this.$cove.getAPI({
          name: config.apiKey,
          params: {
            courtID: this.courtID,
            caseUUID: this.caseInstance.caseInstanceUUID
          },
          query
        });

        // disable sorting across all headers (since we are limiting how much data we are showing sorting doesn't make any sense)
        let headers = this.getTabHeaders(tab);
        headers.forEach((h) => { h.sortable = false; });

        let tableData = {
          items: [],
          headers,
          title: this.getTabTitle(tab),
          component: this.getTabComponent(tab),
          page: {
            size: this.CONST.CASE_VIEW_PRINTABLE_MAX_TABLE_RESULTS,
            number: 0,
            totalElements: 0
          }
        };

        this.tableData.push(tableData);

        promises.push(
          TASK_QUEUE.add(() => {
            return axios.get(url)
              .then((response) => {
                tableData.items = (_.get(response, 'data._embedded.results', [])).map((element) => {
                  return { ...element, searchResultKey: _.uniqueId() };
                });

                tableData.page.number = response.data.page.number;
                tableData.page.totalPages = response.data.page.totalPages;
                tableData.page.totalElements = response.data.page.totalElements;
              });
          })
        );
      });

      Promise.all(promises)
        .finally(() => {
          this.loading = false;
        });
    },
    generateSortParam (tab, config) {
      let sortBy = config.sortBy;
      let sortDesc = config.sortDesc;
      let header = _.find(this.getTabSortableColumns(tab), { value: sortBy });

      if (header.customSort)
      {
        return header.sortExpression;
      }
      else
      {
        return `${sortBy},${sortDesc ? 'desc' : 'asc'}`;
      }
    }
  }
};
</script>