import CONST from '@/application/constants';
import i18n from '@/plugins/i18n';

/**
 * Returns the list of tabs to be displayed according to the specified case class group type.
 *
 * @param {String|Number} caseClassGroupTypeID
 * @returns
 */
export function getTabsByCaseClassGroupType (caseClassGroupTypeID)
{
  let result = [];
  let id = caseClassGroupTypeID;
  if (typeof id === 'string')
  {
    id = parseInt(caseClassGroupTypeID);
  }

  switch (id) {
    case CONST.CASE_CLASS_GROUP_TYPE_CIVIL:
      result = [
        CONST.CASE_VIEW_TAB_DOCKET_ENTRIES,
        CONST.CASE_VIEW_TAB_PARTIES,
        CONST.CASE_VIEW_TAB_HEARINGS,
        CONST.CASE_VIEW_TAB_CLAIMS,
        CONST.CASE_VIEW_TAB_JUDGMENTS
      ];
      break;

    case CONST.CASE_CLASS_GROUP_TYPE_CRIMINAL:
    case CONST.CASE_CLASS_GROUP_TYPE_JUV_DEL:
      result = [
        CONST.CASE_VIEW_TAB_DOCKET_ENTRIES,
        CONST.CASE_VIEW_TAB_PARTIES,
        CONST.CASE_VIEW_TAB_HEARINGS,
        CONST.CASE_VIEW_TAB_CHARGES
      ];
      break;

    case CONST.CASE_CLASS_GROUP_TYPE_TRAFFIC:
    case CONST.CASE_CLASS_GROUP_TYPE_PARKING:
      result = [
        CONST.CASE_VIEW_TAB_DOCKET_ENTRIES,
        CONST.CASE_VIEW_TAB_PARTIES,
        CONST.CASE_VIEW_TAB_HEARINGS,
        CONST.CASE_VIEW_TAB_CITATIONS,
        CONST.CASE_VIEW_TAB_CHARGES
      ];
      break;

    case CONST.CASE_CLASS_GROUP_TYPE_ABUSE_NEGLECT:
    case CONST.CASE_CLASS_GROUP_TYPE_MARRIAGE:
    case CONST.CASE_CLASS_GROUP_TYPE_APPEAL:
    case CONST.CASE_CLASS_GROUP_TYPE_ADMIN:
      result = [
        CONST.CASE_VIEW_TAB_DOCKET_ENTRIES,
        CONST.CASE_VIEW_TAB_PARTIES,
        CONST.CASE_VIEW_TAB_HEARINGS
      ];
      break;

    default:
      throw new Error ('Error evaluating tabs for case - unknown case class group type ID: ' + caseClassGroupTypeID);
  }

  return result;
}

/**
 * Returns the API configuration values for the specified tab, including some AJAX properties as well as the default sorting.
 *
 * @param {String} tab
 * @returns
 */
export function getTabAPIConfig (tab)
{
  let config = null;
  if (tab === CONST.CASE_VIEW_TAB_DOCKET_ENTRIES)
  {
    config = {
      apiKey: 'api.case.docketentries',
      sortBy: 'filedDate',
      sortDesc: true
    };
  }
  else if (tab === CONST.CASE_VIEW_TAB_PARTIES)
  {
    config = {
      apiKey: 'api.case.parties',
      sortBy: 'relevance', // this is a custom, non-column sort
      sortDesc: true // doesn't matter for custom sort, but should always have a value
    };
  }
  else if (tab === CONST.CASE_VIEW_TAB_HEARINGS)
  {
    config = {
      apiKey: 'api.case.hearings',
      sortBy: 'startDate',
      sortDesc: true
    };
  }
  else if (tab === CONST.CASE_VIEW_TAB_CITATIONS)
  {
    config = {
      apiKey: 'api.case.citations',
      sortBy: 'violationDate',
      sortDesc: true
    };
  }
  else if (tab === CONST.CASE_VIEW_TAB_CHARGES)
  {
    config = {
      apiKey: 'api.case.charges',
      sortBy: 'orderBy',
      sortDesc: false
    };
  }
  else if (tab === CONST.CASE_VIEW_TAB_CLAIMS)
  {
    config = {
      apiKey: 'api.case.claims',
      sortBy: 'claimHeader.sequenceNumber',
      sortDesc: false
    };
  }
  else if (tab === CONST.CASE_VIEW_TAB_JUDGMENTS)
  {
    config = {
      apiKey: 'api.case.judgments',
      sortBy: 'judgmentDate',
      sortDesc: true
    };
  }

  return config;
}

/**
 * Returns the set of header configuration for the specified tab, including which columns/values to display and what is sortable.
 *
 * @param {String} tab
 * @returns
 */
export function getTabHeaders (tab, isVerifiedParty)
{
  let headers = [];

  if (tab === CONST.CASE_VIEW_TAB_DOCKET_ENTRIES)
  {
    headers.push({ text: i18n.t('page.caseView.tab.docketEntries.filedDate'), value: 'filedDate', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.docketEntries.type'), value: 'docketEntryType', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.docketEntries.subtype'), value: 'docketEntrySubType', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.docketEntries.description'), value: 'docketEntryDescription', sortable: false });
    headers.push({ text: i18n.t('page.caseView.tab.docketEntries.submittedBy'), value: 'submittedBy', sortable: false });
    headers.push({ text: i18n.t('global.view'), value: 'docketEntryUUID', sortable: false, align: 'right', class: 'pr-6' });
  }
  else if (tab === CONST.CASE_VIEW_TAB_PARTIES)
  {
    headers.push({ text: i18n.t('page.caseView.tab.parties.role'), value: 'partyHeader.partySubType', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.parties.name'), value: 'partyHeader.partyActorInstance.sortName', sortable: true });
    if (isVerifiedParty)
    {
      headers.push({ text: i18n.t('page.caseView.tab.parties.birthDate'), value: 'birthDate', sortable: false });
      headers.push({ text: i18n.t('page.caseView.tab.parties.address'), value: 'address.formattedAddress', sortable: false });
    }
    headers.push({ text: i18n.t('page.caseView.tab.parties.status'), value: 'partyHeader.partyStatus', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.parties.representation'), value: 'legalRepresentations', sortable: false });
  }
  else if (tab === CONST.CASE_VIEW_TAB_HEARINGS)
  {
    headers.push({ text: i18n.t('page.caseView.tab.hearings.date'), value: 'startDate', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.hearings.locationAndRoom'), value: 'event.location', sortable: false });
    headers.push({ text: i18n.t('page.caseView.tab.hearings.type'), value: 'hearingType', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.hearings.status'), value: 'hearingStatus', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.hearings.assignment'), value: 'event.judgeActorInstances', sortable: false });
    headers.push({ text: i18n.t('page.caseView.tab.hearings.streamingLink'), value: 'event.virtualStreamingLink', sortable: false });
  }
  else if (tab === CONST.CASE_VIEW_TAB_CITATIONS)
  {
    headers.push({ text: i18n.t('page.caseView.tab.citations.number'), value: 'citationNumber', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.citations.date'), value: 'violationDate', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.citations.agency'), value: 'citingAgencyActorInstance.sortName', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.citations.officers'), value: 'officerActorInstances', sortable: false });
  }
  else if (tab === CONST.CASE_VIEW_TAB_CHARGES)
  {
    // configure this column to use orderBy property for sorting, and use a column slot on the front-end to show the human readable
    // countNumber in its place instead.
    headers.push({ text: i18n.t('page.caseView.tab.charges.count'), value: 'orderBy', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.charges.chargeType'), value: 'chargeType', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.charges.offenseType'), value: 'statuteType', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.charges.statute'), value: 'statuteNumber', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.charges.offenseDate'), value: 'offenseDate', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.charges.plea'), value: 'pleaType', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.charges.disposition'), value: 'dispoType', sortable: true });
  }
  else if (tab === CONST.CASE_VIEW_TAB_CLAIMS)
  {
    headers.push({ text: i18n.t('page.caseView.tab.claims.number'), value: 'claimHeader.sequenceNumber', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.claims.date'), value: 'claimHeader.claimDate', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.claims.type'), value: 'claimHeader.claimType', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.claims.parties'), value: 'claimParties', sortable: false });
    headers.push({ text: i18n.t('page.caseView.tab.claims.dispositions'), value: 'dispos', sortable: false });
  }
  else if (tab === CONST.CASE_VIEW_TAB_JUDGMENTS)
  {
    headers.push({ text: i18n.t('page.caseView.tab.judgments.date'), value: 'judgmentDate', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.judgments.type'), value: 'judgmentType', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.judgments.status'), value: 'judgmentStatus', sortable: true });
    headers.push({ text: i18n.t('page.caseView.tab.judgments.forParties'), value: 'forParties', sortable: false });
    headers.push({ text: i18n.t('page.caseView.tab.judgments.againstParties'), value: 'againstParties', sortable: false });
    headers.push({ text: i18n.t('page.caseView.tab.judgments.claims'), value: 'claims', sortable: false });
    headers.push({ text: i18n.t('page.caseView.tab.judgments.amount'), value: 'judgmentAmount', sortable: true });
  }

  return headers;
}

/**
 * Hook to define any additional, non-column sorts for a particular tab.
 *
 * @param {String} tab
 * @returns
 */
export function getAdditionalSorts (tab)
{
  let result = [];
  if (tab === CONST.CASE_VIEW_TAB_PARTIES)
  {
    result.push({ text: i18n.t('page.caseView.sort.relevance'), value: 'relevance', customSort: true,
      sortExpression: ['orderBy,asc', 'partyNumber,asc'] });
  }

  return result;
}