<template>
  <v-dialog :value="visible" width="600" persistent>
    <v-card elevation="0" class="px-4 py-3" style="border-left:8px solid var(--v-error-base)">
      <h1 class="font-weight-regular">
        {{ $t('popup.serverError.title') }}
      </h1>
      <div class="mt-1 text-minimum-gray">
        {{ $t('popup.serverError.description') }}
      </div>
      <dl v-if="details !== null" class="d-grid mt-8 mb-8" style="grid-template-columns: 30% 70%;">
        <dt>{{ $t('popup.serverError.field.url') }}</dt>
        <dd>{{ details.url }}</dd>
        <dt>{{ $t('popup.serverError.field.method') }}</dt>
        <dd>{{ details.method }}</dd>
        <dt>{{ $t('popup.serverError.field.statusCode') }}</dt>
        <dd>{{ details.statusCode }}</dd>
        <dt>{{ $t('global.error.dateUTC') }}</dt>
        <dd>{{ details.timestamp }}</dd>
        <template v-if="details.errorReference">
          <dt>{{ $t('global.error.errorReference') }}</dt>
          <dd>{{ details.errorReference }}</dd>
        </template>
      </dl>
      <v-divider/>
      <div class="d-flex mt-3">
        <div style="flex: 0 0 50%">
          <c-btn type="primary" @click="copyToClipboard" id="copy-error-button">
            {{ $t('popup.serverError.button.copyToClipboard') }}
          </c-btn>
        </div>
        <div style="flex: 0 0 50%" class="text-right">
          <c-btn @click="close">
            {{ $t('cove.general.close') }}
          </c-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { focusElement } from '@/application/utility';

export default {
  computed: {
    visible () {
      return this.$store.state.application.serverErrorPopupVisible;
    },
    details () {
      return this.$store.state.application.serverErrorDetails;
    }
  },
  watch: {
    visible (newValue) {
      if (newValue)
      {
        focusElement('#copy-error-button');
      }
    }
  },
  methods: {
    copyToClipboard () {
      navigator.clipboard.writeText(JSON.stringify(this.details, null, 4));
    },
    close () {
      this.$store.commit('application/closeServerErrorPopup');
    }
  }
};
</script>

<style lang="scss" scoped>
dd
{
  font-family: monospace;
}
</style>