<template>
  <page-wrapper :title="$t('page.manageTask.pageTitle')">
    <v-row>
      <v-col cols="12" md="7" xl="6">
        <validation-error-panel/>
        <v-card v-show="!loading">
          <v-card-title>
            {{ $t('page.manageTask.title') }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('page.manageTask.subtitle') }}
          </v-card-subtitle>
          <v-card-text>
            <section-separator :title="$t('page.manageTask.section.details')" class="mb-4"/>
            <v-col cols="12">
              <dl class="d-grid grid-4060">
                <template v-if="!isSingleCourtMode">
                  <dt>{{ $t('courtCase.court') }}</dt>
                  <dd>{{ task.courtAbbreviation }}</dd>
                </template>
                <template v-if="hasCase">
                  <dt>{{ $t('courtCase.caseNumber') }}</dt>
                  <dd><router-link :to="{ name: 'caseView', params: {
                    courtID: internalCourtID,
                    caseUUID: task.caseInstanceID
                  }}">
                    {{ task.caseNumber }}
                  </router-link></dd>
                </template>
                <template v-if="!isSingleCaseLocationMode">
                  <dt>{{ $t('court.location') }}</dt>
                  <dd>{{ task.locationName }}</dd>
                </template>
                <dt>{{ $t('task.type.short') }}</dt>
                <dd>{{ task.taskType }}</dd>
                <dt>{{ $t('task.dueDate') }}</dt>
                <dd>{{ task.dueDate | formatDate }}</dd>
                <dt>{{ $t('task.status.short') }}</dt>
                <dd>{{ task.taskStatus }}</dd>
                <dt>{{ $t('task.priority.short') }}</dt>
                <dd>{{ task.taskPriority }}</dd>
              </dl>
            </v-col>
            <section-separator :title="$t('page.manageTask.section.assignment')" class="my-4"/>
            <dl class="d-grid grid-4060">
              <template v-if="hasDocument">
                <dt>{{ $t('task.assignment.attachment') }}</dt>
                <dd><a :href="generateDocumentURL(false)" target="_blank">{{ $t('global.view') }}</a></dd>
              </template>
              <dt>{{ $t('task.assignment.comments') }}</dt>
              <dd>{{ task.comments }}</dd>
            </dl>
            <section-separator :title="$t('page.manageTask.section.response')" class="my-4"/>
            <div v-if="!this.task.portalUserLocked">
              <v-row no-gutters>
                <v-col cols="12">
                  <c-textarea
                    v-model="comments"
                    :label="$t('task.responseComments')"
                    required
                    key="comments"
                    outlined
                    hide-details="auto"
                    aria-describedby="61e09367-188f-4d02-b482-7e1be043ea6d"/>
                  <field-description id="61e09367-188f-4d02-b482-7e1be043ea6d"
                                     message-key="task.responseComments.description"/>
                </v-col>
                <v-col cols="12">
                  <c-file-input
                    :label="$t('task.responseDocument')"
                    v-model="file"
                    key="file"
                    outlined
                    hide-details="auto"
                    aria-describedby="e9bf0fcf-ee12-4648-8a6b-cf2b5acc3b20"/>
                  <field-description id="e9bf0fcf-ee12-4648-8a6b-cf2b5acc3b20"
                                     message-key = "task.responseDocument.description">
                    {{ $te('task.responseDocument.description') ? $t('task.responseDocument.description') : null }}
                    <button type="button" class="link-button" @click="openFileTypesPopup">
                      {{ $t('global.viewFileTypes') }}
                    </button>
                  </field-description>
                </v-col>
              </v-row>
            </div>
            <div v-if="this.task.portalUserLocked">
              <dl class="d-grid grid-4060">
                <dt>{{ $t('task.responseComments') }}</dt>
                <dd>{{ task.responseComments }}</dd>
                <dt v-if="hasResponseDocument">{{ $t('task.responseDocument') }}</dt>
                <dd v-if="hasResponseDocument">
                  <a :href="generateDocumentURL(true)" target="_blank">
                    {{ $t('global.view') }}
                  </a>
                </dd>
              </dl>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end mt-4">
            <c-btn v-if="!this.task.portalUserLocked" type="primary" @click="submit(false)">
              {{ $t('application.button.submit') }}
            </c-btn>
            <c-btn v-if="!this.task.portalUserLocked" type="danger" @click="submit(true)">
              {{ $t('application.button.decline') }}
            </c-btn>
            <c-btn @click="returnToMyTasks">
              {{ $t('application.button.cancel') }}
            </c-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <file-types-popup v-model="fileTypesPopupVisible" :fileType="CONST.DOCUMENT_TYPE_TASK_RESPONSE"/>
  </page-wrapper>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import FieldDescription from '@components/support/FieldDescription';
import FileTypesPopup from '@components/support/FileTypesPopup';
import PageWrapper from '@components/support/PageWrapper';
import SectionSeparator from '@components/support/SectionSeparator';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import { scrollTo, validateFile } from '@/application/utility';
import { redirectNotFoundErrorHandler } from '@/application/ajax';

export default {
  components: { FieldDescription, FileTypesPopup, PageWrapper, SectionSeparator, ValidationErrorPanel },
  props: {
    taskID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      task: {},
      comments: null,
      file: null,
      loading: true,
      fileTypesPopupVisible: false
    };
  },
  created () {
    let taskPromise = axios.get(this.$cove.getAPI({ name: 'api.user.task', params: { taskID: this.taskID } }),
      { errorHandlers: { 404: redirectNotFoundErrorHandler } })
      .then((response) => {
        this.task = _.defaultTo(response.data, {});
      });
    let fileTypePromise = this.$store.dispatch('application/loadFileTypes', this.CONST.DOCUMENT_TYPE_TASK_RESPONSE);

    Promise.all([taskPromise, fileTypePromise]).finally(() => {
      this.loading = false;
    });
  },
  computed: {
    isSingleCourtMode () {
      return this.$store.getters['application/isSingleCourtMode'];
    },
    isSingleCaseLocationMode () {
      return this.$store.getters['application/isSingleCaseLocationMode'];
    },
    internalCourtID () {
      let internalCourt = this.$store.getters['application/getCourtByExternalID'](this.task.courtID);
      return !_.isNil(internalCourt) ? internalCourt.resourceID : null;
    },
    hasCase () {
      return !_.isNil(this.task.caseNumber);
    },
    hasDocument () {
      return !_.isNil(this.task.documentLinkID);
    },
    hasResponseDocument () {
      return !_.isNil(this.task.responseDocumentLinkID);
    }
  },
  methods: {
    generateDocumentURL (taskResponse) {
      return this.$cove.getAPI({
        name: 'api.user.task.document',
        params: { taskID: this.taskID },
        query: {
          taskResponse
        }
      });
    },
    validate (decline) {
      this.$store.commit('cove/errors/clear');
      this.$cove.validation.required(this.comments, 'task.responseComments', 'comments');
      if (decline && this.file !== null) {
        this.$cove.validation.addError('file', 'task.responseDocument.conditional.decline');
      }
      if (!decline) {
        if (this.file !== null) {
          validateFile(this.file, this.CONST.DOCUMENT_TYPE_TASK_RESPONSE, 'task.responseDocument', 'file');
        }
      }
      return !this.$store.getters['cove/errors/hasErrors']();
    },
    submit (decline) {
      this.$cove.block({ delay: 0 });

      let isValid = this.validate(decline);
      if (!isValid) {
        this.$cove.unblock();
        scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
        return;
      }

      let taskUpdate = {
        'comments': this.comments,
        decline
      };

      let formData = new FormData();
      formData.append('taskUpdate', JSON.stringify(taskUpdate));
      if (!decline) {
        formData.append('file', this.file);
      }

      let url = this.$cove.getAPI({ name: 'api.user.task', params: { taskID: this.taskID } });
      axios.post(url, formData).then(() => {
        this.returnToMyTasks ();
        this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
      }).finally(() => {
        this.$cove.unblock();
      });
    },
    returnToMyTasks () {
      this.$router.push({ name: 'work-tasks' });
    },
    openFileTypesPopup () {
      this.fileTypesPopupVisible = true;
    }
  }
};
</script>