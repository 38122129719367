<template>
  <c-dialog
    :value="value"
    width="650"
    close-key="cove.general.close"
    title-key="page.calendarSubscriptionPopup.title"
    @input="close">

    <div class="pa-5">
      {{ $t('page.calendarSubscriptionPopup.message') }}
      <div class="my-5">
        <code class="pa-3 font-weight-bold d-block" style="font-size:1rem;letter-spacing:0.03rem;word-break:break-all">
          {{ link }}
        </code>
      </div>
    </div>

    <template #actions-left>
      <c-btn type="primary" class="pa-4" @click="copyToClipboard">
        {{ $t('global.copyToClipboard') }}
      </c-btn>
    </template>

  </c-dialog>
</template>

<script>

export default {
  props: {
    value: {
      type: Boolean
    },
    link: {
      type: String
    }
  },
  methods: {
    close () {
      this.$emit('input', false);
    },
    copyToClipboard () {
      navigator.clipboard.writeText(this.link);
    }
  }
};
</script>