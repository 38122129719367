<template>
  <page-wrapper>
    <template #title>
      <v-container class="page-container mt-4 pb-0">
        <v-row class="d-none d-md-flex">
          <v-col class="d-flex align-center">
            <div class="mr-6">
              <img src="@/assets/logo.png" style="height:128px;width:128px;" alt=""/>
            </div>
            <div class="page-focus px-6 py-4" style="border-left:1px solid #c0c0c0" tabindex="-1">
              <h1 class="text-h4 font-weight-medium">
                {{ $t('application.project.name') }}
              </h1>
              <div class="font-weight-light" style="font-size:1.6rem;line-height:1.6;">
                {{ $t('application.project.tagline') }}
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="d-md-none">
          <v-col class="d-flex flex-column">
            <div class="text-center">
              <img src="@/assets/logo.png" alt=""/>
            </div>
            <div class="pt-4 text-center">
              <h1 class="text-h4 font-weight-medium">
                {{ $t('application.project.name') }}
              </h1>
              <div class="font-weight-light" style="font-size:1.6rem;line-height:1.6;">
                {{ $t('application.project.tagline') }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <v-row class="mt-2">
      <v-col cols="12" md="8" xl="7">
        <loading-area :loading="loadingAnnouncements">
          <v-row>
            <v-col v-if="announcements.length > 0" cols="12">
              <homepage-announcement-card
                v-for="(announcement, i) in announcements"
                :announcement="announcement"
                :key="announcement.resourceID"
                :last="i === announcements.length - 1"/>
            </v-col>
            <v-col cols="12" md="6">
              <homepage-card
                :title="$t('page.home.card.announcements.title')"
                :text="$t('page.home.card.announcements.description')"
                icon="mdi-bullhorn">
                <template #action>
                  <c-btn type="primary" :to="{ name: 'announcements' }">
                    {{ $t('page.home.card.announcements.read') }}
                  </c-btn>
                </template>
              </homepage-card>
            </v-col>
            <v-col cols="12" md="6">
              <homepage-card
                :title="$t('page.home.card.search.title')"
                :text="$t('page.home.card.search.description')"
                icon="mdi-magnify">
                <template #action>
                  <c-btn type="primary" :to="{ name: 'searchHome' }">
                    {{ $t('page.home.card.search.searchForRecords') }}
                  </c-btn>
                </template>
              </homepage-card>
            </v-col>
          </v-row>
        </loading-area>
      </v-col>
      <v-col md="3">
        <v-row>
          <v-col cols="12">
            <loading-card :loading="loadingQuicklinks">
              <template v-if="quicklinks.length > 0">
                <v-card-title>
                  {{ $t('page.home.card.quickLinks.title') }}
                </v-card-title>
                <v-card-text class="pb-3">
                  <ul class="link-list">
                    <li v-for="ql in quicklinks" :key="ql.resourceID">
                      <a :href="ql.url" target="_blank">
                        {{ ql.displayName }}
                      </a>
                    </li>
                  </ul>
                </v-card-text>
              </template>
            </loading-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <homepage-sandbox/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import HomepageAnnouncementCard from '@components/support/HomepageAnnouncementCard';
import HomepageCard from '@components/support/HomepageCard';
import HomepageSandbox from '@components/support/HomepageSandbox';
import PageWrapper from '@components/support/PageWrapper';
import PageMixin from '@/mixins/page';
import LoadingArea from '@components/support/LoadingArea';
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { HomepageAnnouncementCard, HomepageCard, HomepageSandbox, PageWrapper, LoadingArea, LoadingCard },
  mixins: [ PageMixin ],
  props: {
    logout: { // incoming prop that is set by the router redirect on successful logouts
      type: Boolean
    }
  },
  data () {
    return {
      loadingQuicklinks: true,
      quicklinks: [],
      loadingAnnouncements: true,
      announcements: []
    };
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.quicklinks', query: { sort: 'orderBy,asc', size: this.CONST.PAGING_MAX_SIZE } } ))
      .then((response) => {
        this.quicklinks = _.get(response, 'data._embedded.results', []);
      })
      .finally(() => {
        this.loadingQuicklinks = false;
      });

    let announcementQuery = {
      homePage: true,
      size: this.CONST.PAGING_MAX_SIZE,
      sort: ['orderBy,asc', 'startDate,desc']
    };

    axios.get(this.$cove.getAPI({ name: 'api.announcements', query: announcementQuery }))
      .then((response) => {
        this.announcements = _.get(response, 'data._embedded.results', []);
      })
      .finally(() => {
        this.loadingAnnouncements = false;
      });
  },
  mounted () {
    if (this.logout)
    {
      this.$cove.notify({ message: this.$t('global.logout.success'), color: 'success' });
    }
  }
};
</script>