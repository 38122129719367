<template>
  <loading-card :loading="loading" class="nav-tab-medium">
    <template #default>
      <v-card-title>
        {{ $t('page.admin.userAccount.documentAccess.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.admin.userAccount.documentAccess.description') }}
      </v-card-subtitle>
      <v-card-text>
        <!-- if user already has free docs via their org just show that -->
        <mini-card v-if="hasOrgFreeDocuments" class="mt-4 mb-8">
          <v-card-text class="pa-6">
            <div class="d-flex" style="gap:24px">
              <div class="flex-grow-0">
                <v-icon color="primary" style="font-size:50px">
                  mdi-file-document-check
                </v-icon>
              </div>
              <v-divider vertical/>
              <div class="flex-grow-1 text-minimum-gray align-self-center" style="max-width:300px;">
                {{ $t('page.admin.userAccount.documentAccess.userOrgFreeDocs') }}
              </div>
            </div>
          </v-card-text>
        </mini-card>

        <!-- otherwise, offer the admin the ability to give this user free docs -->
        <mini-card v-else class="mt-4 mb-8">
          <v-card-text>
            <div class="d-flex pa-2" style="gap:20px">
              <div class="flex-grow-0">
                <v-icon color="primary" style="font-size:50px">
                  mdi-file-document-check
                </v-icon>
              </div>
              <v-divider vertical/>
              <div class="flex-grow-1" style="max-width:300px">
                <div style="font-size:1.5rem">
                  {{ $t('page.manageOrg.documentAccess.card.title') }}
                </div>
                <div class="text-minimum-gray mt-2">
                  {{ $t('page.admin.userAccount.documentAccess.grantDescription') }}
                </div>
                <div class="mt-6">
                  <c-checkbox
                    v-model="freeDocuments"
                    :label="$t('page.admin.userAccount.documentAccess.field.grantAccess')"
                    hide-details
                    class="ma-0 pa-0"/>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end mt-8">
            <c-btn type="primary" @click="save">
              {{ $t('global.save') }}
            </c-btn>
          </v-card-actions>
        </mini-card>
      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import MiniCard from '@components/support/MiniCard';
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { LoadingCard, MiniCard },
  props: {
    userID: {
      type: String
    }
  },
  data () {
    return {
      loading: true,
      freeDocuments: false,
      hasOrgFreeDocuments: false
    };
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.user', params: { userID: this.userID }, query: { fields: '*,organization(*)' } }))
      .then((response) => {
        this.freeDocuments = _.get(response, 'data.freeDocuments', false);
        this.hasOrgFreeDocuments = _.get(response, 'data.organization.freeDocuments', false);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    save () {
      this.$cove.block({ delay: 0 });

      let url = this.$cove.getAPI({ name: 'api.user', params: { userID: this.userID } });
      axios.patch(url, { freeDocuments: this.freeDocuments })
        .then(() => {
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
        })
        .finally(() => {
          this.$cove.unblock();
        });
    }
  }
};
</script>