/* eslint-disable max-len */

import _ from 'lodash';
import { DateTime } from 'luxon';
import { dateToStartOfDay, dateToEndOfDay } from '@/application/application';
import CONST from '@/application/constants';
import cove from '@ctrack/cove';
import i18n from '@/plugins/i18n';
import JSURL from 'jsurl';
import store from '@/plugins/store';

/**
 *
 */
export function getValidSearchTypes ()
{
  return [
    CONST.SEARCH_TYPE_CASE,
    CONST.SEARCH_TYPE_PARTY,
    CONST.SEARCH_TYPE_CITATION,
    CONST.SEARCH_TYPE_JUDGMENT,
    CONST.SEARCH_TYPE_CALENDAR,
    CONST.SEARCH_TYPE_DOCUMENT,
    CONST.SEARCH_TYPE_PUBLICATION
  ];
}

export function getSupportedAdvancedSearchTypes ()
{
  return [
    CONST.SEARCH_TYPE_CASE,
    CONST.SEARCH_TYPE_PARTY,
    CONST.SEARCH_TYPE_CITATION,
    CONST.SEARCH_TYPE_JUDGMENT,
    CONST.SEARCH_TYPE_CALENDAR,
    CONST.SEARCH_TYPE_DOCUMENT
  ];
}

export function getSearchConfigBySearchType (searchType)
{
  let config = null;
  if (searchType === CONST.SEARCH_TYPE_CASE)
  {
    config = { apiKey: 'api.search.cases' };
  }
  else if (searchType === CONST.SEARCH_TYPE_PARTY)
  {
    config = { apiKey: 'api.search.parties' };
  }
  else if (searchType === CONST.SEARCH_TYPE_CITATION)
  {
    config = { apiKey: 'api.search.citations' };
  }
  else if (searchType === CONST.SEARCH_TYPE_JUDGMENT)
  {
    config = { apiKey: 'api.search.judgments' };
  }
  else if (searchType === CONST.SEARCH_TYPE_CALENDAR)
  {
    config = { apiKey: 'api.search.events' };
  }
  else if (searchType === CONST.SEARCH_TYPE_DOCUMENT)
  {
    config = { apiKey: 'api.search.documents' };
  }
  else if (searchType === CONST.SEARCH_TYPE_PUBLICATION)
  {
    config = { apiKey: 'api.search.publications' };
  }

  return config;
}

export function getIconBySearchType (searchType)
{
  let icon = 'mdi-magnify';
  if (searchType === CONST.SEARCH_TYPE_CASE)
  {
    icon = 'mdi-folder-open';
  }
  else if (searchType === CONST.SEARCH_TYPE_PARTY)
  {
    icon = 'mdi-account-group';
  }
  else if (searchType === CONST.SEARCH_TYPE_CITATION)
  {
    icon = 'mdi-card-bulleted';
  }
  else if (searchType === CONST.SEARCH_TYPE_JUDGMENT)
  {
    icon = 'mdi-scale-balance';
  }
  else if (searchType === CONST.SEARCH_TYPE_CALENDAR)
  {
    icon = 'mdi-calendar-month';
  }
  else if (searchType === CONST.SEARCH_TYPE_DOCUMENT)
  {
    icon = 'mdi-file-document';
  }
  else if (searchType === CONST.SEARCH_TYPE_PUBLICATION)
  {
    icon = 'mdi-file-sign';
  }

  return icon;
}

/**
 *
 */
export function generateSearchDTO (searchType)
{
  let addCaseFields = true;
  let searchDTO = {
    advanced: false,
    courtID: null,
    page: {
      size: 25,
      number: 0,
      totalElements: 0,
      totalPages: 0
    },
    sort: {
      sortBy: null,
      sortDesc: false
    }
  };

  if (searchType === CONST.SEARCH_TYPE_PARTY)
  {
    searchDTO[CONST.SEARCH_TYPE_PARTY] = {
      partySubTypeID: null,
      partyName: null,
      partyNameQueryTypeID: null,
      partyFirstName: null,
      partyFirstNameQueryTypeID: null,
      partyLastName: null,
      partyLastNameQueryTypeID: null,
      partySubtypeID: null,
      actorCategoryID: null
    };
  }
  else if (searchType === CONST.SEARCH_TYPE_CITATION)
  {
    searchDTO[CONST.SEARCH_TYPE_CITATION] = {
      citationNumber: null,
      citationNumberQueryTypeID: null,
      partyName: null,
      partyNameQueryTypeID: null,
      partyFirstName: null,
      partyFirstNameQueryTypeID: null,
      partyLastName: null,
      partyLastNameQueryTypeID: null,
      actorCategoryID: null,
      violationDateChoice: null,
      violationDateStart: null,
      violationDateEnd: null
    };
  }
  else if (searchType === CONST.SEARCH_TYPE_JUDGMENT)
  {
    searchDTO[CONST.SEARCH_TYPE_JUDGMENT] = {
      forActorCategoryID: null,
      forPartyName: null,
      forPartyNameQueryTypeID: null,
      forPartyFirstName: null,
      forPartyFirstNameQueryTypeID: null,
      forPartyLastName: null,
      forPartyLastNameQueryTypeID: null,
      againstActorCategoryID: null,
      againstPartyName: null,
      againstPartyNameQueryTypeID: null,
      againstPartyFirstName: null,
      againstPartyFirstNameQueryTypeID: null,
      againstPartyLastName: null,
      againstPartyLastNameQueryTypeID: null,
      judgmentTypeID: null,
      judgmentStatusID: null,
      judgmentDateChoice: null,
      judgmentDateStart: null,
      judgmentDateEnd: null
    };
  }
  else if (searchType === CONST.SEARCH_TYPE_CALENDAR)
  {
    addCaseFields = false;
    searchDTO[CONST.SEARCH_TYPE_CALENDAR] = {
      locationID: null,
      departmentActorInstanceUUID: null,
      calendarName: null,
      calendarNameQueryTypeID: null,
      judgeName: null,
      judgeNameQueryTypeID: null,
      judgeFirstName: null,
      judgeFirstNameQueryTypeID: null,
      judgeLastName: null,
      judgeLastNameQueryTypeID: null,
      courtSessionTypeID: null,
      calendarDateChoice: null,
      calendarDateStart: null,
      calendarDateEnd: null
    };
  }
  else if (searchType === CONST.SEARCH_TYPE_DOCUMENT)
  {
    searchDTO[CONST.SEARCH_TYPE_DOCUMENT] = {
      docketEntrySubTypeID: null,
      textAnyWords: null,
      textAllWords: null,
      textExactPhrase: null,
      textNoneWords: null,
      docketEntryFiledDateChoice: null,
      docketEntryFiledDateStart: null,
      docketEntryFiledDateEnd: null
    };
  }
  else if (searchType === CONST.SEARCH_TYPE_PUBLICATION)
  {
    addCaseFields = false;
    searchDTO[CONST.SEARCH_TYPE_PUBLICATION] = {
      caseNumber: null,
      publicationNumber: null,
      publicationName: null,
      publicationDateChoice: null,
      publicationDateStart: null,
      publicationDateEnd: null
    };
  }

  if (addCaseFields)
  {
    searchDTO[CONST.SEARCH_TYPE_CASE] = {
      locationID: null,
      caseCategoryID: null,
      caseNumber: null,
      caseNumberQueryTypeID: null,
      caseTitle: null,
      caseTitleQueryTypeID: null,
      filedDateChoice: null,
      filedDateStart: null,
      filedDateEnd: null,
      excludeClosed: false
    };
  }

  return searchDTO;
}

/**
 *
 * @param {*} searchType
 * @param {*} searchDTO
 */
export function setSearchDefaults (searchType, searchDTO)
{
  searchDTO.courtID = store.getters['application/getDefaultCourtID'];

  if (searchType === CONST.SEARCH_TYPE_CASE)
  {
    searchDTO.case.excludeClosed = true;
    searchDTO.sort.sortBy = 'caseHeader.filedDate';
    searchDTO.sort.sortDesc = true;
    searchDTO.case.caseNumberQueryTypeID = CONST.QUERY_TYPE_CONTAINS; // identifier string searches always use contains
    searchDTO.case.caseTitleQueryTypeID = getDefaultQueryType(false);
  }
  else if (searchType === CONST.SEARCH_TYPE_PARTY)
  {
    searchDTO.case.excludeClosed = true;
    searchDTO.party.partyNameQueryTypeID = getDefaultQueryType(false);
    searchDTO.sort.sortBy = 'score'; // this is a custom, non-column sort
    searchDTO.sort.sortDesc = true; // doesn't matter for custom sort, but should always have a value
  }
  else if (searchType === CONST.SEARCH_TYPE_CITATION)
  {
    searchDTO.case.excludeClosed = true;
    searchDTO.sort.sortBy = 'violationDate';
    searchDTO.sort.sortDesc = true;
    searchDTO.citation.partyNameQueryTypeID = getDefaultQueryType(false);
    searchDTO.citation.citationNumberQueryTypeID = CONST.QUERY_TYPE_CONTAINS; // identifier string searches always use contains
  }
  else if (searchType === CONST.SEARCH_TYPE_JUDGMENT)
  {
    searchDTO.case.excludeClosed = true;
    searchDTO.sort.sortBy = 'judgmentDate';
    searchDTO.sort.sortDesc = true;
    searchDTO.judgment.forPartyNameQueryTypeID = getDefaultQueryType(false);
    searchDTO.judgment.againstPartyNameQueryTypeID = getDefaultQueryType(false);
  }
  else if (searchType === CONST.SEARCH_TYPE_DOCUMENT)
  {
    // default sort will be determined later based on search criteria (see beforeInitialSearch)
    searchDTO.case.excludeClosed = true;
  }
  else if (searchType === CONST.SEARCH_TYPE_CALENDAR)
  {
    searchDTO.calendar.calendarDateChoice = '1m';
    searchDTO.calendar.calendarDateStart = cove.formatDate(DateTime.local());
    searchDTO.calendar.calendarDateEnd = cove.formatDate(DateTime.local().plus({ months: 1 }));

    searchDTO.sort.sortBy = 'startDate';
    searchDTO.sort.sortDesc = true;
    searchDTO.calendar.calendarNameQueryTypeID = getDefaultQueryType(false);
    searchDTO.calendar.judgeNameQueryTypeID = getDefaultQueryType(false);
  }
  else if (searchType === CONST.SEARCH_TYPE_PUBLICATION)
  {
    searchDTO.sort.sortBy = 'publicationDate';
    searchDTO.sort.sortDesc = true;
  }
}

export function getSearchResultHeaders (searchType)
{
  let headers = [];

  let singleCourtMode = store.getters['application/isSingleCourtMode'];

  if (!singleCourtMode && searchType !== CONST.SEARCH_TYPE_CALENDAR && searchType !== CONST.SEARCH_TYPE_PUBLICATION)
  {
    headers.push({ text: i18n.t('courtCase.court'), value: 'caseHeader.courtAbbreviation', sortable: true });
  }

  if (searchType === CONST.SEARCH_TYPE_CASE)
  {
    let singleLocationMode = store.getters['application/isSingleCaseLocationMode'];
    if (!singleLocationMode)
    {
      headers.push({ text: i18n.t('page.searchResults.case.location'), value: 'caseHeader.location', sortable: true });
    }

    headers.push({ text: i18n.t('page.searchResults.case.caseNumber'), value: 'caseHeader.caseNumber', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.case.caseTitle'), value: 'caseHeader.caseTitle', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.case.classification'), value: 'caseHeader.caseClassification', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.case.filedDate'), value: 'caseHeader.filedDate', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.case.open'), value: 'caseHeader.closedFlag', sortable: true });
  }
  else if (searchType === CONST.SEARCH_TYPE_PARTY)
  {
    headers.push({ text: i18n.t('page.searchResults.case.caseNumber'), value: 'caseHeader.caseNumber', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.case.caseTitle'), value: 'caseHeader.caseTitle', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.case.category'), value: 'caseHeader.caseCategory', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.case.filedDate'), value: 'caseHeader.filedDate', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.party.role'), value: 'partyHeader.partySubType', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.party.name'), value: 'partyHeader.partyActorInstance.sortName', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.case.open'), value: 'caseHeader.closedFlag', sortable: true });
  }
  else if (searchType === CONST.SEARCH_TYPE_CITATION)
  {
    headers.push({ text: i18n.t('page.searchResults.case.caseNumber'), value: 'caseHeader.caseNumber', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.case.caseTitle'), value: 'caseHeader.caseTitle', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.citation.number'), value: 'citationNumber', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.citation.date'), value: 'violationDate', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.citation.party'), value: 'defendantParty.partyActorInstance.sortName', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.citation.agency'), value: 'citingAgencyActorInstance.sortName', sortable: true });
  }
  else if (searchType === CONST.SEARCH_TYPE_JUDGMENT)
  {
    headers.push({ text: i18n.t('page.searchResults.case.caseNumber'), value: 'caseHeader.caseNumber', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.case.caseTitle'), value: 'caseHeader.caseTitle', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.judgment.date'), value: 'judgmentDate', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.judgment.type'), value: 'judgmentType', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.judgment.status'), value: 'judgmentStatus', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.judgment.for'), value: 'forParties', sortable: false });
    headers.push({ text: i18n.t('page.searchResults.judgment.against'), value: 'againstParties', sortable: false });
    headers.push({ text: i18n.t('page.searchResults.judgment.amount'), value: 'judgmentAmount', sortable: true });
  }
  else if (searchType === CONST.SEARCH_TYPE_DOCUMENT)
  {
    headers.push({ text: i18n.t('page.searchResults.case.caseNumber'), value: 'caseHeader.caseNumber', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.case.caseTitle'), value: 'caseHeader.caseTitle', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.case.category'), value: 'caseHeader.caseCategory', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.document.type'), value: 'docketEntryTypeName', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.document.filedDate'), value: 'docketEntryFiledDate', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.document.name'), value: 'documentName', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.document.text'), value: 'highlightsMap', sortable: false });
    headers.push({ text: i18n.t('global.view'), value: 'documentLinkUUID', sortable: false, align: 'right', class: 'pr-6' });
  }
  else if (searchType === CONST.SEARCH_TYPE_CALENDAR)
  {
    if (!singleCourtMode)
    {
      headers.push({ text: i18n.t('courtCase.court'), value: 'courtAbbreviation', sortable: true });
    }

    headers.push({ text: i18n.t('page.searchResults.calendar.date'), value: 'startDate', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.calendar.calendarName'), value: 'eventName', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.calendar.type'), value: 'courtSessionType', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.calendar.location'), value: 'location', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.calendar.room'), value: 'room', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.calendar.department'), value: 'departmentActorInstance.displayName', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.calendar.assignment'), value: 'judgeActorInstances', sortable: false });
  }
  else if (searchType === CONST.SEARCH_TYPE_PUBLICATION)
  {
    if (!singleCourtMode)
    {
      headers.push({ text: i18n.t('courtCase.court'), value: 'courtAbbreviation', sortable: true });
    }

    headers.push({ text: i18n.t('page.searchResults.publication.publicationDate'), value: 'publicationDate', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.publication.publicationNumber'), value: 'publicationNumber', sortable: true });
    headers.push({ text: i18n.t('page.searchResults.publication.publicationName'), value: 'publicationName', sortable: true });
  }

  return headers;
}

/**
 * Hook to define any additional, non-column sorts for a particular search type.
 *
 * @param {*} searchType
 * @returns
 */
export function getAdditionalSorts (searchType)
{
  let result = [];
  if (searchType === CONST.SEARCH_TYPE_PARTY || searchType === CONST.SEARCH_TYPE_DOCUMENT)
  {
    result.push({ text: i18n.t('page.searchResults.sort.score'), value: 'score', customSort: true, sortExpression: null });
  }

  return result;
}

/**
 * Returns the default query type which should be used for string/text searches. Accepts `advanced` mode boolean parameter so we can offer
 * different modes depending on the situation.
 *
 * @param {*} advanced
 * @returns
 */
export function getDefaultQueryType (advanced)
{
  let result = CONST.QUERY_TYPE_MATCH;
  if (advanced)
  {
    result = CONST.QUERY_TYPE_CONTAINS;
  }

  return result;
}

/**
 *
 * @param {*} searchType
 * @param {*} searchDTO
 */
export function validateSearchCriteria (searchType, searchDTO)
{
  store.commit('cove/errors/clear');

  if (searchDTO.case !== undefined && searchDTO.case.filedDateChoice === CONST.DATE_RANGE_OPTION_CUSTOM)
  {
    cove.validation.date.rejectIfInvalid(searchDTO.case.filedDateStart, 'page.searchCriteria.case.field.caseFiledDateFrom',
      'caseFiledDate-startDate');
    cove.validation.date.rejectIfInvalid(searchDTO.case.filedDateEnd, 'page.searchCriteria.case.field.caseFiledDateTo',
      'caseFiledDate-endDate');
    cove.validation.date.rejectIfBefore(searchDTO.case.filedDateEnd, searchDTO.case.filedDateStart,
      'page.searchCriteria.case.field.caseFiledDateTo', 'page.searchCriteria.case.field.caseFiledDateFrom', 'caseFiledDate-endDate');
  }

  if (searchType === CONST.SEARCH_TYPE_PARTY)
  {
    if (!searchDTO.advanced)
    {
      cove.validation.required(searchDTO.party.partyName, 'page.searchCriteria.party.field.name', 'name');
    }
    else
    {
      if (searchDTO.party.actorCategoryID === CONST.ACTOR_CATEGORY_PERSON)
      {
        if (searchDTO.party.partyFirstName === null && searchDTO.party.partyLastName === null)
        {
          let firstNameLabel = i18n.t('page.searchCriteria.party.field.firstName');
          let lastNameLabel = i18n.t('page.searchCriteria.party.field.lastName');
          cove.validation.addError('personName', 'page.searchCriteria.error.chooseOne', [firstNameLabel, lastNameLabel]);
        }
      }
      else
      {
        cove.validation.required(searchDTO.party.partyName, 'page.searchCriteria.party.field.orgName', 'orgName');
      }
    }
  }

  if (searchType === CONST.SEARCH_TYPE_CALENDAR)
  {
    if (searchDTO.calendar.calendarDateChoice === CONST.DATE_RANGE_OPTION_CUSTOM)
    {
      cove.validation.date.rejectIfInvalid(searchDTO.calendar.calendarDateStart, 'page.searchCriteria.calendar.field.dateFrom',
        'calendarDate-startDate');
      cove.validation.date.rejectIfInvalid(searchDTO.calendar.calendarDateEnd, 'page.searchCriteria.calendar.field.dateTo',
        'calendarDate-endDate');
      cove.validation.date.rejectIfBefore(searchDTO.calendar.calendarDateEnd, searchDTO.calendar.calendarDateStart,
        'page.searchCriteria.calendar.field.dateTo', 'page.searchCriteria.calendar.field.dateFrom', 'calendarDate-endDate');
    }
  }

  if (searchType === CONST.SEARCH_TYPE_CITATION)
  {
    if (searchDTO.citation.violationDateChoice === CONST.DATE_RANGE_OPTION_CUSTOM)
    {
      cove.validation.date.rejectIfInvalid(searchDTO.citation.violationDateStart, 'page.searchCriteria.citation.field.violationDateFrom',
        'violationDate-startDate');
      cove.validation.date.rejectIfInvalid(searchDTO.citation.violationDateEnd, 'page.searchCriteria.citation.field.violationDateTo',
        'violationDate-endDate');
      cove.validation.date.rejectIfBefore(searchDTO.citation.violationDateEnd, searchDTO.citation.violationDateStart,
        'page.searchCriteria.citation.field.violationDateTo', 'page.searchCriteria.citation.field.violationDateFrom',
        'violationDate-endDate');
    }
  }

  if (searchType === CONST.SEARCH_TYPE_DOCUMENT)
  {
    if (searchDTO.document.docketEntryFiledDateChoice === CONST.DATE_RANGE_OPTION_CUSTOM)
    {
      cove.validation.date.rejectIfInvalid(searchDTO.document.docketEntryFiledDateStart, 'page.searchCriteria.document.field.filedDateFrom',
        'filedDate-startDate');
      cove.validation.date.rejectIfInvalid(searchDTO.document.docketEntryFiledDateEnd, 'page.searchCriteria.document.field.filedDateTo',
        'filedDate-endDate');
      cove.validation.date.rejectIfBefore(searchDTO.document.docketEntryFiledDateEnd, searchDTO.document.docketEntryFiledDateStart,
        'page.searchCriteria.document.field.filedDateTo', 'page.searchCriteria.document.field.filedDateFrom',
        'filedDate-endDate');
    }
  }

  if (searchType === CONST.SEARCH_TYPE_JUDGMENT)
  {
    if (searchDTO.judgment.judgmentDateChoice === CONST.DATE_RANGE_OPTION_CUSTOM)
    {
      cove.validation.date.rejectIfInvalid(searchDTO.judgment.judgmentDateStart, 'page.searchCriteria.judgment.field.judgmentDateFrom',
        'judgmentDate-startDate');
      cove.validation.date.rejectIfInvalid(searchDTO.judgment.judgmentDateEnd, 'page.searchCriteria.judgment.field.judgmentDateTo',
        'judgmentDate-endDate');
      cove.validation.date.rejectIfBefore(searchDTO.judgment.judgmentDateEnd, searchDTO.judgment.judgmentDateStart,
        'page.searchCriteria.judgment.field.judgmentDateTo', 'page.searchCriteria.judgment.field.judgmentDateFrom',
        'judgmentDate-endDate');
    }
  }

  if (searchType === CONST.SEARCH_TYPE_PUBLICATION)
  {
    if (searchDTO.publication.publicationDateChoice === CONST.DATE_RANGE_OPTION_CUSTOM)
    {
      // TODO: add validation for the publication date
      cove.validation.date.rejectIfInvalid(searchDTO.publication.publicationDateStart, 'page.searchCriteria.publication.field.publicationDateFrom',
        'publicationDate-startDate');
      cove.validation.date.rejectIfInvalid(searchDTO.publication.publicationDateEnd, 'page.searchCriteria.publication.field.publicationDateTo',
        'publicationDate-endDate');
      cove.validation.date.rejectIfBefore(searchDTO.publication.publicationDateEnd, searchDTO.publication.publicationDateStart,
        'page.searchCriteria.publication.field.publicationDateTo', 'page.searchCriteria.publication.field.publicationDateFrom', 'publicationDate-endDate');
    }
  }

  return !store.getters['cove/errors/hasErrors']();
}

/**
 * Hook to finalize any defaults before the initial search is run.
 *
 * @param {String} searchType
 * @param {Object} searchDTO
 */
export function beforeInitialSearch (searchType, searchDTO)
{
  if (searchType === CONST.SEARCH_TYPE_DOCUMENT)
  {
    searchDTO.sort.sortBy = 'docketEntryFiledDate';
    searchDTO.sort.sortDesc = true;

    if (!_.isNil(searchDTO.document.textAnyWords) || !_.isNil(searchDTO.document.textAllWords) ||
    !_.isNil(searchDTO.document.textExactPhrase) || !_.isNil(searchDTO.document.textNoneWords))
    {
      searchDTO.sort.sortBy = 'score'; // this is a custom, non-column sort
      searchDTO.sort.sortDesc = true; // doesn't matter for custom sort, but should always have a value
    }
  }
}

/**
 * Translates the searchDTO into a suitable query format for the backend API.
 *
 * @param {*} searchType
 * @param {*} searchDTO
 * @returns
 */
export function generateAPIQueryObject (searchType, searchDTO)
{
  let query = {};
  if (searchType === CONST.SEARCH_TYPE_CASE)
  {
    if (!_.isNil(searchDTO.case.locationID))
    {
      query['caseHeader.locationID'] = searchDTO.case.locationID;
    }

    if (!_.isNil(searchDTO.case.caseCategoryID))
    {
      query['caseHeader.caseCategoryID'] = searchDTO.case.caseCategoryID;
    }

    if (!_.isNil(searchDTO.case.caseNumber))
    {
      query['caseHeader.caseNumber'] = searchDTO.case.caseNumber;
      query['caseHeader.caseNumberSearchType'] = searchDTO.case.caseNumberQueryTypeID;
    }

    if (!_.isNil(searchDTO.case.caseTitle))
    {
      query['caseHeader.caseTitle'] = searchDTO.case.caseTitle;
      query['caseHeader.caseTitleSearchType'] = searchDTO.case.caseTitleQueryTypeID;
    }

    if (!_.isNil(searchDTO.case.filedDateStart))
    {
      query['caseHeader.filedDateFrom'] = dateToStartOfDay(cove.parseDate(searchDTO.case.filedDateStart)).toISO();
    }

    if (!_.isNil(searchDTO.case.filedDateEnd))
    {
      query['caseHeader.filedDateTo'] = dateToEndOfDay(cove.parseDate(searchDTO.case.filedDateEnd)).toISO();
    }

    if (searchDTO.case.excludeClosed)
    {
      query['caseHeader.closedFlag'] = false;
    }

    if (!_.isNil(searchDTO.courtID))
    {
      query['caseHeader.courtID'] = searchDTO.courtID;
    }
  }

  if (searchType === CONST.SEARCH_TYPE_PARTY)
  {
    if (!_.isNil(searchDTO.party.partySubTypeID))
    {
      query['partyHeader.partySubTypeID'] = searchDTO.party.partySubTypeID;
    }

    if (!_.isNil(searchDTO.party.actorCategoryID))
    {
      query['partyHeader.partyActorInstance.actorCategoryID'] = searchDTO.party.actorCategoryID;
    }

    // this covers the default/simple search as well as advanced org. name search
    if (!_.isNil(searchDTO.party.partyName))
    {
      query['partyHeader.partyActorInstance.displayName'] = searchDTO.party.partyName;
      query['partyHeader.partyActorInstance.displayNameSearchType'] = searchDTO.party.partyNameQueryTypeID;
    }
    // this covers advanced person name search
    else if (searchDTO.party.actorCategoryID === CONST.ACTOR_CATEGORY_PERSON)
    {
      if (!_.isNil(searchDTO.party.partyFirstName))
      {
        query['partyHeader.partyActorInstance.firstName'] = searchDTO.party.partyFirstName;
        query['partyHeader.partyActorInstance.firstNameSearchType'] = searchDTO.party.partyFirstNameQueryTypeID;
      }
      if (!_.isNil(searchDTO.party.partyLastName))
      {
        query['partyHeader.partyActorInstance.lastName'] = searchDTO.party.partyLastName;
        query['partyHeader.partyActorInstance.lastNameSearchType'] = searchDTO.party.partyLastNameQueryTypeID;
      }
    }

    generateNestedCaseHeaderQueryCriteria(searchDTO, query);
  }

  if (searchType === CONST.SEARCH_TYPE_CITATION)
  {
    if (!_.isNil(searchDTO.citation.citationNumber))
    {
      query.citationNumber = searchDTO.citation.citationNumber;
      query.citationNumberSearchType = searchDTO.citation.citationNumberQueryTypeID;
    }

    if (!_.isNil(searchDTO.citation.violationDateStart))
    {
      query.violationDateFrom = dateToStartOfDay(cove.parseDate(searchDTO.citation.violationDateStart)).toISO();
    }

    if (!_.isNil(searchDTO.citation.violationDateEnd))
    {
      query.violationDateTo = dateToEndOfDay(cove.parseDate(searchDTO.citation.violationDateEnd)).toISO();
    }

    if (!_.isNil(searchDTO.citation.actorCategoryID))
    {
      query['defendantParty.partyActorInstance.actorCategoryID'] = searchDTO.citation.actorCategoryID;
    }

    // this covers the default/simple search as well as advanced org. name search
    if (!_.isNil(searchDTO.citation.partyName))
    {
      query['defendantParty.partyActorInstance.displayName'] = searchDTO.citation.partyName;
      query['defendantParty.partyActorInstance.displayNameSearchType'] = searchDTO.citation.partyNameQueryTypeID;
    }
    // this covers advanced person name search
    else if (searchDTO.citation.actorCategoryID === CONST.ACTOR_CATEGORY_PERSON)
    {
      if (!_.isNil(searchDTO.citation.partyFirstName))
      {
        query['defendantParty.partyActorInstance.firstName'] = searchDTO.citation.partyFirstName;
        query['defendantParty.partyActorInstance.firstNameSearchType'] = searchDTO.citation.partyFirstNameQueryTypeID;
      }
      if (!_.isNil(searchDTO.citation.partyLastName))
      {
        query['defendantParty.partyActorInstance.lastName'] = searchDTO.citation.partyLastName;
        query['defendantParty.partyActorInstance.lastNameSearchType'] = searchDTO.citation.partyLastNameQueryTypeID;
      }
    }

    generateNestedCaseHeaderQueryCriteria(searchDTO, query);
  }

  if (searchType === CONST.SEARCH_TYPE_JUDGMENT)
  {
    if (!_.isNil(searchDTO.judgment.judgmentTypeID))
    {
      query.judgmentTypeID = searchDTO.judgment.judgmentTypeID;
    }

    if (!_.isNil(searchDTO.judgment.judgmentStatusID))
    {
      query.judgmentStatusID = searchDTO.judgment.judgmentStatusID;
    }

    if (!_.isNil(searchDTO.judgment.judgmentDateStart))
    {
      query.judgmentDateFrom = dateToStartOfDay(cove.parseDate(searchDTO.judgment.judgmentDateStart)).toISO();
    }

    if (!_.isNil(searchDTO.judgment.judgmentDateEnd))
    {
      query.judgmentDateTo = dateToEndOfDay(cove.parseDate(searchDTO.judgment.judgmentDateEnd)).toISO();
    }

    if (!_.isNil(searchDTO.judgment.forActorCategoryID))
    {
      query['forParties.partyActorInstance.actorCategoryID'] = searchDTO.judgment.forActorCategoryID;
    }

    // this covers the default/simple search as well as advanced org. name search
    if (!_.isNil(searchDTO.judgment.forPartyName))
    {
      query['forParties.partyActorInstance.displayName'] = searchDTO.judgment.forPartyName;
      query['forParties.partyActorInstance.displayNameSearchType'] = searchDTO.judgment.forPartyNameQueryTypeID;
    }
    // this covers advanced person name search
    else if (searchDTO.judgment.actorCategoryID === CONST.ACTOR_CATEGORY_PERSON)
    {
      if (!_.isNil(searchDTO.judgment.forPartyFirstName))
      {
        query['forParties.partyActorInstance.firstName'] = searchDTO.judgment.forPartyFirstName;
        query['forParties.partyActorInstance.firstNameSearchType'] = searchDTO.judgment.forPartyFirstNameQueryTypeID;
      }
      if (!_.isNil(searchDTO.judgment.forPartyLastName))
      {
        query['forParties.partyActorInstance.lastName'] = searchDTO.judgment.forPartyLastName;
        query['forParties.partyActorInstance.lastNameSearchType'] = searchDTO.judgment.forPartyLastNameQueryTypeID;
      }
    }

    if (!_.isNil(searchDTO.judgment.againstActorCategoryID))
    {
      query['againstParties.partyActorInstance.actorCategoryID'] = searchDTO.judgment.againstActorCategoryID;
    }

    // this covers the default/simple search as well as advanced org. name search
    if (!_.isNil(searchDTO.judgment.againstPartyName))
    {
      query['againstParties.partyActorInstance.displayName'] = searchDTO.judgment.againstPartyName;
      query['againstParties.partyActorInstance.displayNameSearchType'] = searchDTO.judgment.againstPartyNameQueryTypeID;
    }
    // this covers advanced person name search
    else if (searchDTO.judgment.actorCategoryID === CONST.ACTOR_CATEGORY_PERSON)
    {
      if (!_.isNil(searchDTO.judgment.againstPartyFirstName))
      {
        query['againstParties.partyActorInstance.firstName'] = searchDTO.judgment.againstPartyFirstName;
        query['againstParties.partyActorInstance.firstNameSearchType'] = searchDTO.judgment.againstPartyFirstNameQueryTypeID;
      }
      if (!_.isNil(searchDTO.judgment.againstPartyLastName))
      {
        query['againstParties.partyActorInstance.lastName'] = searchDTO.judgment.againstPartyLastName;
        query['againstParties.partyActorInstance.lastNameSearchType'] = searchDTO.judgment.againstPartyLastNameQueryTypeID;
      }
    }

    generateNestedCaseHeaderQueryCriteria(searchDTO, query);
  }

  if (searchType === CONST.SEARCH_TYPE_DOCUMENT)
  {
    if (!_.isNil(searchDTO.document.docketEntrySubTypeID))
    {
      let parsedDocketEntryValue = parseDocketEntrySubTypeID(searchDTO.document.docketEntrySubTypeID);
      if (!_.isNil(parsedDocketEntryValue.docketEntryTypeID))
      {
        query.docketEntryTypeID = parsedDocketEntryValue.docketEntryTypeID;
      }
      if (!_.isNil(parsedDocketEntryValue.docketEntrySubTypeID))
      {
        query.docketEntrySubTypeID = parsedDocketEntryValue.docketEntrySubTypeID;
      }
    }

    if (!_.isNil(searchDTO.document.docketEntryFiledDateStart))
    {
      query.docketEntryFiledDateFrom = dateToStartOfDay(cove.parseDate(searchDTO.document.docketEntryFiledDateStart)).toISO();
    }

    if (!_.isNil(searchDTO.document.docketEntryFiledDateEnd))
    {
      query.docketEntryFiledDateTo = dateToEndOfDay(cove.parseDate(searchDTO.document.docketEntryFiledDateEnd)).toISO();
    }

    if (!_.isNil(searchDTO.document.textAnyWords))
    {
      query.anyOfTheseWords = searchDTO.document.textAnyWords;
    }

    if (!_.isNil(searchDTO.document.textAllWords))
    {
      query.allOfTheseWords = searchDTO.document.textAllWords;
    }

    if (!_.isNil(searchDTO.document.textExactPhrase))
    {
      query.thisExactPhrase = searchDTO.document.textExactPhrase;
    }

    if (!_.isNil(searchDTO.document.textNoneWords))
    {
      query.noneOfTheseWords = searchDTO.document.textNoneWords;
    }

    generateNestedCaseHeaderQueryCriteria(searchDTO, query);
  }

  if (searchType === CONST.SEARCH_TYPE_CALENDAR)
  {
    if (!_.isNil(searchDTO.calendar.locationID))
    {
      query.locationID = searchDTO.calendar.locationID;
    }

    if (!_.isNil(searchDTO.calendar.courtSessionTypeID))
    {
      query.courtSessionTypeID = searchDTO.calendar.courtSessionTypeID;
    }

    if (!_.isNil(searchDTO.calendar.calendarName))
    {
      query.eventName = searchDTO.calendar.calendarName;
      query.eventNameSearchType = searchDTO.calendar.calendarNameQueryTypeID;
    }

    if (!_.isNil(searchDTO.calendar.calendarDateStart))
    {
      query.startDateFrom = dateToStartOfDay(cove.parseDate(searchDTO.calendar.calendarDateStart)).toISO();
    }

    if (!_.isNil(searchDTO.calendar.calendarDateEnd))
    {
      query.startDateTo = dateToEndOfDay(cove.parseDate(searchDTO.calendar.calendarDateEnd)).toISO();
    }

    if (!_.isNil(searchDTO.courtID))
    {
      query.courtID = searchDTO.courtID;
    }

    if (!_.isNil(searchDTO.calendar.departmentActorInstanceUUID))
    {
      query['departmentActorInstance.actorInstanceUUID'] = searchDTO.calendar.departmentActorInstanceUUID;
    }

    if (!_.isNil(searchDTO.calendar.judgeName))
    {
      query['judgeActorInstances.displayName'] = searchDTO.calendar.judgeName;
      query['judgeActorInstances.displayNameSearchType'] = searchDTO.calendar.judgeNameQueryTypeID;
    }
    else
    {
      if (!_.isNil(searchDTO.calendar.judgeFirstName))
      {
        query['judgeActorInstances.firstName'] = searchDTO.calendar.judgeFirstName;
        query['judgeActorInstances.firstNameSearchType'] = searchDTO.calendar.judgeFirstNameQueryTypeID;
      }
      if (!_.isNil(searchDTO.calendar.judgeLastName))
      {
        query['judgeActorInstances.lastName'] = searchDTO.calendar.judgeLastName;
        query['judgeActorInstances.lastNameSearchType'] = searchDTO.calendar.judgeLastNameQueryTypeID;
      }
    }
  }

  if (searchType === CONST.SEARCH_TYPE_PUBLICATION)
  {
    if (!_.isNil(searchDTO.courtID))
    {
      query.courtID = searchDTO.courtID;
    }

    if (!_.isNil(searchDTO.publication.caseNumber))
    {
      query.caseNumber = searchDTO.publication.caseNumber;
    }

    if (!_.isNil(searchDTO.publication.publicationNumber))
    {
      query.publicationNumber = searchDTO.publication.publicationNumber;
    }

    if (!_.isNil(searchDTO.publication.publicationName))
    {
      query.publicationName = searchDTO.publication.publicationName;
    }

    if (!_.isNil(searchDTO.publication.publicationDateStart))
    {
      query.publicationDateFrom = dateToStartOfDay(cove.parseDate(searchDTO.publication.publicationDateStart)).toISO();
    }

    if (!_.isNil(searchDTO.publication.publicationDateEnd))
    {
      query.publicationDateTo = dateToEndOfDay(cove.parseDate(searchDTO.publication.publicationDateEnd)).toISO();
    }
  }

  return query;
}

/**
 * Adds in any nested case header query criteria into the query object.
 *
 * @param {*} searchDTO
 * @param {*} query
 */
export function generateNestedCaseHeaderQueryCriteria (searchDTO, query)
{
  if (!_.isNil(searchDTO.case.locationID))
  {
    query['caseHeader.locationID'] = searchDTO.case.locationID;
  }
  if (!_.isNil(searchDTO.case.caseCategoryID))
  {
    query['caseHeader.caseCategoryID'] = searchDTO.case.caseCategoryID;
  }
  if (!_.isNil(searchDTO.case.filedDateStart))
  {
    query['caseHeader.filedDateFrom'] = dateToStartOfDay(cove.parseDate(searchDTO.case.filedDateStart)).toISO();
  }
  if (!_.isNil(searchDTO.case.filedDateEnd))
  {
    query['caseHeader.filedDateTo'] = dateToEndOfDay(cove.parseDate(searchDTO.case.filedDateEnd)).toISO();
  }
  if (searchDTO.case.excludeClosed)
  {
    query['caseHeader.closedFlag'] = false;
  }
  if (!_.isNil(searchDTO.courtID))
  {
    query['caseHeader.courtID'] = searchDTO.courtID;
  }
}

/**
 *
 * @param {*} searchType
 * @param {*} searchDTO
 */
export function generateQueryObject (searchType, searchDTO)
{
  let cloned = _.cloneDeep(searchDTO);

  cloned[searchType] = _.omitBy(cloned[searchType], _.isNil);

  if (cloned.case !== undefined)
  {
    cloned.case = _.omitBy(cloned.case, _.isNil);
  }

  return JSURL.stringify(cloned);
}

/**
 *
 * @param {*} searchType
 * @param {*} query
 */
export function parseURLParams (searchType, query)
{
  let searchDTO = generateSearchDTO(searchType);
  _.merge(searchDTO, JSURL.parse(query.criteria));

  return searchDTO;
}

/**
 * We are mixing types and subtypes together in this dropdown by providing blanket 'all' options -- so we have to flag which values
 * are types vs subtypes
 * @param {*} docketEntrySubtypes
 * @returns array of dropdown values
 */
export function populateDocketEntrySubTypes (docketEntrySubtypes)
{
  let result = [];
  let sorted = _.sortBy(docketEntrySubtypes, ['docketEntryType.docketEntryTypeName', 'docketEntrySubTypeName']);
  let lastTypeID = null;

  sorted.forEach((des) => {
    let det = des.docketEntryType;
    let typeID = det.docketEntryTypeID;
    if (lastTypeID !== typeID)
    {
      lastTypeID = typeID;
      result.push({ text: det.docketEntryTypeName + ' - ' + i18n.t('global.all'), value: 'typeID:' + det.docketEntryTypeID });
    }

    result.push({ text: `${des.docketEntryType.docketEntryTypeName} - ${des.docketEntrySubTypeName}`,
      value: 'subtypeID:' + des.docketEntrySubTypeID });
  });

  return result;
}

/**
 * Parses the dropdown values generated by populateDocketEntrySubTypes function
 * @param {*} docketEntrySubTypeID
 * @returns object with parsed docketEntryTypeID and docketEntrySubTypeID
 */
export function parseDocketEntrySubTypeID (docketEntrySubTypeID)
{
  let docketEntrySubTypeValues = {
    docketEntryTypeID: null,
    docketEntrySubTypeID: null
  };
  if (docketEntrySubTypeID.startsWith('typeID:'))
  {
    docketEntrySubTypeValues.docketEntryTypeID = docketEntrySubTypeID.substring(7);
  }
  else if (docketEntrySubTypeID.startsWith('subtypeID:'))
  {
    docketEntrySubTypeValues.docketEntrySubTypeID = docketEntrySubTypeID.substring(10);
  }
  return docketEntrySubTypeValues;
}