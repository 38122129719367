<template>
  <div>
    <loading-card :loading="false" class="nav-tab-small">
      <v-card-title>
        {{ $t('page.currentUser.userAccess.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.currentUser.userAccess.description') }}
      </v-card-subtitle>
      <v-card-text class="my-3">
        <dl v-if="userTypes.length > 0" class="d-grid grid-5050 mt-1 mb-4">
          <dt>{{ $t('page.currentUser.userAccess.grantedAccess') }}</dt>
          <dd>
            <div v-for="ut in userTypes" :key="ut.resourceID">
              {{ ut.displayName }}
            </div>
          </dd>
        </dl>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <c-btn type="primary" v-bind="attrs" v-on="on">
              {{ $t('page.currentUser.userAccess.button.submitRequest') }}
              <v-icon class="mr-n1">
                mdi-menu-down
              </v-icon>
            </c-btn>
          </template>
          <v-list dense>
            <v-list-item v-if="!hasRole(CONST.ROLE_ATTORNEY) && userTypeAvailable(CONST.USER_TYPE_ATTORNEY)"
                         class="inset-focus"
                         @click="forwardToRequestAccess(CONST.USER_TYPE_ATTORNEY)">
              <v-list-item-title>
                {{ $t('page.currentUser.request.attorney') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="userTypeAvailable(CONST.USER_TYPE_PUBLIC)"
                         class="inset-focus"
                         @click="forwardToRequestAccess(CONST.USER_TYPE_PUBLIC)">
              <v-list-item-title>
                {{ $t('page.currentUser.request.publicCase') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!hasRole(CONST.ROLE_INTERPRETER) && userTypeAvailable(CONST.USER_TYPE_INTERPRETER)"
                         class="inset-focus"
                         @click="forwardToRequestAccess(CONST.USER_TYPE_INTERPRETER)">
              <v-list-item-title>
                {{ $t('page.currentUser.request.interpreter') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!hasRole(CONST.ROLE_ORGANIZATION_ADMINISTRATOR) && userTypeAvailable(CONST.USER_TYPE_ORGANIZATION_ADMIN)"
                         class="inset-focus"
                         @click="forwardToRequestAccess(CONST.USER_TYPE_ORGANIZATION_ADMIN)">
              <v-list-item-title>
                {{ $t('page.currentUser.request.organizationAdmin') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <c-btn type="primary" class="ml-2" @click="userRequestsPopupVisible = true;">
          {{ $t('page.currentUser.userAccess.button.viewAllRequests') }}
        </c-btn>
      </v-card-actions>
    </loading-card>

    <user-type-requests-popup v-model="userRequestsPopupVisible"/>
  </div>
</template>

<script>
import UserAccess from '@/application/userAccess';
import LoadingCard from '@components/support/LoadingCard';
import UserTypeRequestsPopup from '@components/support/UserTypeRequestsPopup';
import axios from 'axios';
import _ from 'lodash';

export default {
  components: { LoadingCard, UserTypeRequestsPopup },
  data () {
    return {
      userRequestsPopupVisible: false,
      availableUserTypes: []
    };
  },
  computed: {
    user () {
      return this.$store.state.user;
    },
    userTypes () {
      return this.$store.state.user.userTypes;
    }
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.usertypes' }))
      .then((response) => {
        this.availableUserTypes = _.get(response, 'data._embedded.results', []);
      });
  },
  methods: {
    forwardToRequestAccess: UserAccess.forwardToRequestAccess,
    hasRole (role) {
      return this.$store.getters['user/hasRole'](role);
    },
    userTypeAvailable (userTypeID)
    {
      return !_.isNil(_.find(this.availableUserTypes, ['resourceID', userTypeID]));
    }
  }
};
</script>