<template>
  <div>
    <div class="mx-auto" style="max-width:675px">
      <div class="mb-1" style="font-size:1.4rem">
        {{ $t('page.manageFiling.docketEntry.edit') }}
      </div>
      <validation-error-panel/>
      <workflow-wrapper>
        <edit-guide v-if="stepID === 'guide'"
                    key="guide"
                    :docketEntry="docketEntry"
                    @back="back"
                    @editType="editType"
                    @addDocument="addDocument"
                    @editDocument="editDocument"
                    @removeDocument="removeDocument"/>
        <step-type v-if="stepID === 'type'"
                   key="type"
                   :originalBean="docketEntryToEditTypeBean(docketEntry)"
                   :editing="true"
                   @save="saveType"
                   @cancel="cancel"/>
        <step-document v-if="stepID === 'addDocument'"
                       key="addDocument"
                       :originalBean="docketEntryToNewDocumentBean(docketEntry)"
                       :editing="true"
                       @save="saveNewDocument"
                       @cancel="cancel"/>
        <step-document v-if="stepID === 'editDocument'"
                       key="editDocument"
                       :originalBean="docketEntryToEditDocumentBean({ docketEntry, editDocumentID })"
                       :editing="true"
                       @save="updateDocument"
                       @cancel="cancel"/>
      </workflow-wrapper>
    </div>
  </div>
</template>

<script>
import Filing from '@/application/filing';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import WorkflowWrapper from '@components/support/WorkflowWrapper';
import EditGuide from '@components/support/manageFiling/docketEntry/EditGuide';
import StepType from '@components/support/manageFiling/docketEntry/StepType';
import StepDocument from '@components/support/manageFiling/docketEntry/StepDocument';

export default {
  components: { WorkflowWrapper, ValidationErrorPanel, EditGuide, StepType, StepDocument },
  props: {
    docketEntryID: {
      type: String
    }
  },
  data () {
    return {
      docketEntry: null,
      editDocumentID: null,
      stepID: 'guide'
    };
  },
  created () {
    this.loadDocketEntry();
  },
  methods: {
    docketEntryToEditTypeBean: Filing.docketEntryToEditTypeBean,
    docketEntryToNewDocumentBean: Filing.docketEntryToNewDocumentBean,
    docketEntryToEditDocumentBean: Filing.docketEntryToEditDocumentBean,
    loadDocketEntry () {
      return Filing.loadDocketEntryForEditing(this.docketEntryID)
        .then((data) => {
          this.docketEntry = data;
        });
    },
    afterSave () {
      return this.loadDocketEntry()
        .then(() => {
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
          this.stepID = 'guide';
        });
    },
    back () {
      this.$emit('exit');
    },
    cancel () {
      this.stepID = 'guide';
    },
    editType () {
      this.stepID = 'type';
    },
    addDocument () {
      this.stepID = 'addDocument';
    },
    editDocument (documentID) {
      this.editDocumentID = documentID;
      this.stepID = 'editDocument';
    },
    saveType (bean) {
      this.$cove.block({ delay: 0 });
      Filing.patchDocketEntryType({
        docketEntryID: this.docketEntryID,
        bean
      })
        .then(this.afterSave)
        .finally(() => {
          this.$cove.unblock();
        });
    },
    saveNewDocument (bean) {
      this.$cove.block({ delay: 0 });
      Filing.saveNewDocketEntryDocument({
        docketEntryID: this.docketEntryID,
        bean
      })
        .then(this.afterSave)
        .finally(() => {
          this.$cove.unblock();
        });
    },
    updateDocument (bean) {
      this.$cove.block({ delay: 0 });
      Filing.updateDocketEntryDocument({
        docketEntryID: this.docketEntryID,
        documentID: this.editDocumentID,
        bean
      })
        .then(this.afterSave)
        .finally(() => {
          this.$cove.unblock();
        });
    },
    removeDocument (documentID) {
      this.$cove.confirm({ message: this.$t('application.remove.confirm') })
        .then((confirmed) => {
          if (confirmed)
          {
            this.$cove.block({ delay: 0 });
            Filing.removeDocketEntryDocument(documentID)
              .then(this.loadDocketEntry)
              .then(() => {
                this.$cove.notify({ message: this.$t('application.remove.success'), color: 'success' });
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    }
  }
};
</script>