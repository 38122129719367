<!--For testing functionality only, remove when third party payment is complete-->
<template>
  <page-wrapper :title="'Payment Processor Simulation'">
    <v-row>
      <v-col cols="6">
        <v-card height="100%">
          <v-card-title style="text-align:center;" class="pb-0 mb-0">
            <h3 style="width:100%" class="text-h5 mb-0 font-weight-medium">
              {{ 'Payment Processor Simulation' }}
            </h3>
          </v-card-title>
          <v-card-text class="mt-4">
            <dl class="d-grid grid-4060">
              <dt>
                {{ 'Receipt UUID' }}
              </dt>
              <dd>
                {{ receiptUUID }}
              </dd>
              <dt>
                {{ 'Processor External Identifier' }}
              </dt>
              <dd>
                {{ processorExternalIdentifier }}
              </dd>
            </dl>
          </v-card-text>
          <v-card-actions>
            <div class="text-right" style="flex: 1 1 auto">
              <c-btn block type="primary" @click="onMarkAsPaid">
                {{ 'Mark As Paid' }}
              </c-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import PageMixin from '@/mixins/page';
import PageWrapper from '@components/support/PageWrapper';

export default {
  components: { PageWrapper },
  mixins: [ PageMixin ],
  props: {
    receiptUUID: {
      type: String,
      required: true
    },
    processorExternalIdentifier: {
      type: String,
      required: true
    }
  },
  methods: {
    onMarkAsPaid () {
      this.$cove.block({ delay: 0 });

      let redirectLocation = this.$cove.getAPI({ name: 'api.receipt.checkout.confirmation',
        query: { receiptUUID: this.receiptUUID, processorExternalIdentifier: this.processorExternalIdentifier } });
      window.location.href = redirectLocation;
    }
  }
};
</script>