<template>
  <div>
    <transition name="scroll-x-reverse-transition">
      <v-card v-show="!loading">
        <v-card-title>
          {{ $t('page.currentUser.interpreter.title') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('page.initialRegistration.interpreter.subtitle') }}
        </v-card-subtitle>
        <v-card-text>
          <v-row no-gutters>
            <interpreter-form :interpreter="interpreter" ref="interpreterForm"/>
            <v-col cols="12">
              <section-separator :title="$t('page.initialRegistration.section.requestInfo')" class="mt-n2 mb-4"/>
            </v-col>
            <v-col cols="12">
              <c-file-input
                :label="$t('page.initialRegistration.interpreter.field.referenceFile')"
                v-model="file"
                key="file"
                :required="getUserAccessConfig.requireFile"
                outlined
                hide-details="auto"
                aria-describedby="a6df58c5-f55c-4b1d-b076-d9c2ece69318"/>
              <field-description id="a6df58c5-f55c-4b1d-b076-d9c2ece69318">
                {{ $te('page.initialRegistration.interpreter.field.referenceFile.description') ?
                  $t('page.initialRegistration.interpreter.field.referenceFile.description') : null }}
                <button type="button" class="link-button" @click="openFileTypesPopup">
                  {{ $t('global.viewFileTypes') }}
                </button>
              </field-description>
            </v-col>
            <v-col cols="12">
              <c-textarea
                v-model="comments"
                :label="$t('page.initialRegistration.interpreter.field.comments')"
                :required="getUserAccessConfig.requireComments"
                key="comments"
                outlined
                hide-details="auto"
                aria-describedby="a8e8dbf3-f2e1-4908-bab2-6562b693cca2"/>
              <field-description id="a8e8dbf3-f2e1-4908-bab2-6562b693cca2"
                                 message-key="page.initialRegistration.interpreter.field.comments.description"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <c-btn type="primary" @click="save">
            {{ $t('page.initialRegistration.button.submitRequest') }}
          </c-btn>
        </v-card-actions>
      </v-card>
    </transition>

    <file-types-popup v-model="fileTypesPopupVisible" :fileType="CONST.DOCUMENT_TYPE_USER_REQUEST"/>
  </div>
</template>

<script>
import FieldDescription from '@components/support/FieldDescription';
import SectionSeparator from '@components/support/SectionSeparator';
import FileTypesPopup from '@components/support/FileTypesPopup';
import InterpreterForm from '@components/support/InterpreterForm';
import UserAccess from '@/application/userAccess';
import { scrollTo, validateFile } from '@/application/utility';

export default {
  components: { FieldDescription, SectionSeparator, FileTypesPopup, InterpreterForm },
  data () {
    return {
      loading: false,
      fileTypesPopupVisible: false,
      interpreter: {
        employer: null,
        selectedLocations: [],
        selectedLanguages: []
      },
      comments: null,
      file: null
    };
  },
  computed: {
    getUserAccessConfig () {
      return UserAccess.getUserAccessConfig(this.CONST.USER_TYPE_INTERPRETER);
    }
  },
  methods: {
    openFileTypesPopup () {
      this.fileTypesPopupVisible = true;
    },
    validate () {
      this.$store.commit('cove/errors/clear');

      this.$refs.interpreterForm.validate();

      if (this.getUserAccessConfig.requireFile)
      {
        this.$cove.validation.required(this.file, 'page.initialRegistration.interpreter.field.referenceFile', 'file');
      }

      if (this.file !== null)
      {
        validateFile(this.file, this.CONST.DOCUMENT_TYPE_USER_REQUEST, 'page.initialRegistration.interpreter.field.referenceFile', 'file');
      }

      if (this.getUserAccessConfig.requireComments)
      {
        this.$cove.validation.required(this.comments, 'page.initialRegistration.interpreter.field.comments', 'comments');
      }

      this.$cove.validation.string.maxLength(this.comments, 2000, 'page.initialRegistration.interpreter.field.comments', 'comments');

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      this.$cove.block({ delay: 0 });

      let isValid = this.validate();
      if (!isValid)
      {
        this.$cove.unblock();
        scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
        return;
      }

      this.$emit('submitRequest', {
        file: this.file,
        bean: {
          userTypeID: this.CONST.USER_TYPE_INTERPRETER,
          note: this.comments,
          interpreter: {
            employer: this.interpreter.employer,
            languages: this.interpreter.selectedLanguages
          }
        }
      });
    }
  }
};
</script>