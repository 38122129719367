<template>
  <page-wrapper :title="$t('page.searchResults.title')">
    <v-row>
      <v-col cols="12">
        <component
          :is="searchResultsComponent"
          :searchDTO="searchDTO"
          :items="results"
          :headers="headers"
          @toggleDrawer="toggleDrawer"
          @changePage="changePage"
          @changeSort="changeSort"
        />
      </v-col>
    </v-row>

    <v-navigation-drawer
      v-model="drawer"
      temporary
      right
      app
      disable-route-watcher
      width="400"
      class="js-filter-sidebar">
      <div class="px-4 py-2">
        <div class="d-flex align-center">
          <div style="flex:0 0 auto">
            <h1 class="text-h6" tabindex="-1" style="line-height:1rem;outline-offset:6px">
              {{ $t('global.drawer.filterSort.title') }}
            </h1>
          </div>
          <v-spacer/>
          <div style="flex:0 0 auto" class="text-right">
            <v-btn icon color="default" @click="drawer = false;">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <p class="mb-0 text-minimum-gray">
          {{ $t('global.drawer.filterSort.description') }}
        </p>
      </div>
      <v-divider/>
      <div class="px-4 pt-2 pb-6">
        <div class="c-caption mb-5">
          {{ $t('global.sort') }}
        </div>
        <c-autocomplete
          v-model="internalSortBy"
          :label="$t('global.drawer.filterSort.field.sortBy')"
          :clearable="false"
          :items="sortableColumns"
          item-text="text"
          item-value="value"
          outlined
          hide-details/>
        <transition name="fade-transition">
          <v-radio-group
            v-if="showSortDirections"
            v-model="internalSortDesc"
            class="mb-n1"
            hide-details
            :label="$t('global.drawer.filterSort.field.sortDir')">
            <v-radio
              :label="$t('global.ascending')"
              :value="false"/>
            <v-radio
              :label="$t('global.descending')"
              :value="true"/>
          </v-radio-group>
        </transition>
      </div>
      <v-divider/>
      <div class="px-4 pt-2 pb-4">
        <div class="c-caption js-criteria-anchor">
          {{ $t('global.filter') }}
        </div>

        <validation-error-panel class="mt-2 mb-n1"/>
        <component :is="searchCriteriaComponent"
                   :searchType="searchType"
                   :searchDTO="searchDTO"/>

        <v-row v-if="supportsAdvancedSearch && !searchDTO.advanced" no-gutters class="mt-0">
          <v-col cols="12">
            <button type="button" class="link-button" @click="toggleAdvancedSearch"
                    aria-describedby="a21a094c-6929-484a-b020-2477f9fdbb0f">
              {{ $t('page.searchCriteria.button.switchToAdvanced') }}
            </button>
            <field-description id="a21a094c-6929-484a-b020-2477f9fdbb0f"
                               message-key="page.searchCriteria.button.switchToAdvanced.description"/>
          </v-col>
        </v-row>
      </div>
      <template #append>
        <v-divider/>
        <div class="d-flex pa-4">
          <div class="pr-1" style="flex:0 0 50%">
            <c-btn block type="primary" @click="search">
              {{ $t('application.button.update') }}
            </c-btn>
          </div>
          <div class="pl-1" style="flex:0 0 50%">
            <c-btn block @click="drawer = false;">
              {{ $t('application.button.cancel') }}
            </c-btn>
          </div>
        </div>
      </template>
    </v-navigation-drawer>
  </page-wrapper>
</template>

<script>
import PageWrapper from '@components/support/PageWrapper';
import PagingControls from '@components/support/PagingControls';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import FieldDescription from '@components/support/FieldDescription';
import Search from '@/application/search';
import _ from 'lodash';
import axios from 'axios';
import PageMixin from '@/mixins/page';
import { replaceIgnoreDuplicate } from '@/application/utility';

export default {
  components: { PageWrapper, PagingControls, ValidationErrorPanel, FieldDescription },
  mixins: [ PageMixin ],
  props: {
    searchType: {
      type: String
    }
  },
  data () {
    return {
      searchDTO: null,
      backupSearchDTO: null,
      internalSortBy: null,
      internalSortDesc: null,
      results: [],
      drawer: false,
      loading: false
    };
  },
  computed: {
    headers () {
      return Search.getSearchResultHeaders(this.searchType);
    },
    supportsAdvancedSearch () {
      return Search.getSupportedAdvancedSearchTypes().includes(this.searchType);
    },
    sortableColumns () {
      let filters = _.filter(this.headers, { sortable: true });
      filters = filters.concat(Search.getAdditionalSorts(this.searchType));

      return _.orderBy(filters, 'text');
    },
    showSortDirections () {
      let sort = _.find(this.sortableColumns, { value: this.internalSortBy });
      return !_.isNil(sort) && !sort.customSort;
    },
    searchCriteriaComponent () {
      let componentName = _.upperFirst(this.searchType) + 'SearchCriteria';
      return () => {
        return import('@components/support/search/' + componentName);
      };
    },
    searchResultsComponent () {
      let componentName = _.upperFirst(this.searchType) + 'SearchResults';
      return () => {
        return import('@components/support/search/' + componentName);
      };
    }
  },
  watch: {
    drawer (newValue) {
      if (newValue)
      {
        this.internalSortBy = this.searchDTO.sort.sortBy;
        this.internalSortDesc = this.searchDTO.sort.sortDesc;
        this.backupSearchDTO = _.cloneDeep(this.searchDTO);

        setTimeout(() => {
          document.querySelector('.js-filter-sidebar h1').focus();
        }, 150);
      }
      else
      {
        this.$store.commit('cove/errors/clear');
        if (this.backupSearchDTO !== null)
        {
          this.searchDTO = this.backupSearchDTO;
        }
      }
    }
  },
  created () {
    // prevent URL hacking to forbidden search types
    if (!Search.getValidSearchTypes().includes(this.searchType))
    {
      this.$router.push({ name: 'searchHome' });
      this.$cove.notify({ message: this.$t('page.searchResults.error.invalidSearchType', [ this.searchType ]), color: 'error' });
    }

    this.searchDTO = Search.parseURLParams(this.searchType, this.$route.query);
    this.loadSearchResults(false);
  },
  methods: {
    search () {
      let isValid = Search.validateSearchCriteria(this.searchType, this.searchDTO);
      if (isValid)
      {
        // update sorting and paging
        this.searchDTO.sort = { sortBy: this.internalSortBy, sortDesc: this.internalSortDesc };
        this.searchDTO.page.number = 0;

        this.loadSearchResults(true);

        this.backupSearchDTO = null;
        this.drawer = false;
      }
      else
      {
        this.scrollSidebarTo(this.CONST.ERROR_CONTAINER_SELECTOR);
      }
    },
    loadSearchResults (updateURL) {
      if (updateURL)
      {
        let criteria = Search.generateQueryObject(this.searchType, this.searchDTO);
        replaceIgnoreDuplicate({ name: 'searchResults', params: { searchType: this.searchType }, query: { criteria } });
      }

      let query = {
        ...Search.generateAPIQueryObject(this.searchType, this.searchDTO),
        page: this.searchDTO.page.number,
        size: this.searchDTO.page.size
      };

      let sort = this.generateSortParam();
      if (!_.isNil(sort))
      {
        query.sort = sort;
      }

      let config = Search.getSearchConfigBySearchType(this.searchType);
      let url = this.$cove.getAPI({
        name: config.apiKey,
        query
      });

      this.$cove.block();
      this.loading = true;
      return axios.get(url)
        .then((response) => {
          this.results = (_.get(response, 'data._embedded.results', [])).map((element) => {
            return { ...element, searchResultKey: _.uniqueId() };
          });
          this.searchDTO.page.number = response.data.page.number;
          this.searchDTO.page.totalPages = response.data.page.totalPages;
          this.searchDTO.page.totalElements = response.data.page.totalElements;
          this.scrollTo('html');
        })
        .finally(() => {
          this.$cove.unblock();
          this.loading = false;
        });
    },
    generateSortParam () {
      let header = _.find(this.sortableColumns, { value: this.searchDTO.sort.sortBy });
      if (header.customSort)
      {
        return header.sortExpression;
      }
      else
      {
        return `${this.searchDTO.sort.sortBy},${this.searchDTO.sort.sortDesc ? 'desc' : 'asc'}`;
      }
    },
    toggleDrawer () {
      this.drawer = true;
    },
    toggleAdvancedSearch () {
      this.searchDTO.advanced = true;
      this.scrollSidebarTo('.js-criteria-anchor');
    },
    changePage (newPage) {
      this.searchDTO.page.number = newPage;
      this.loadSearchResults(true);
    },
    changeSort (sorting) {
      this.searchDTO.sort = sorting;
      this.searchDTO.page.number = 0;
      this.loadSearchResults(true);
    }
  }
};
</script>
