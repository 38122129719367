import { focusElement } from '@/application/utility';

export default {
  mounted () {
    // TODO 05/06/2021 this stopped working in chrome recently when navigating with mouse. all manual focus on -1 elements seems broken.
    // likely b/c of this https://www.chromestatus.com/feature/5658873031557120 [Feature: Use :focus-visible in the default UA style sheet]

    // we only set page title focus once established in the application. the initial page load leaves focus undefined purposely so tabbing
    // starts at the root of the document.
    if (!this.$store.getters['application/isInitialPageLoad'])
    {
      focusElement('.page-focus');
    }
  }
};