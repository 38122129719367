<template>
  <loading-area :loading="initialLoad" :height="350">
    <transition name="scroll-x-reverse-transition" mode="out-in">
      <v-card v-if="hasSeenStep" key="partiesTable">
        <v-card-title>
          {{ $t('page.manageFiling.party.table.title') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('page.manageFiling.party.table.description') }}
        </v-card-subtitle>

        <v-data-table
          :items="parties"
          :headers="headers"
          :server-items-length="page.totalElements"
          :loading="loading"
          class="mx-sm-4"
          item-key="resourceID"
          disable-sort
          hide-default-footer
          :mobile-breakpoint="800">
          <template #item.actor.mailingAddress="{ value }">
            <render-address :address="value"/>
          </template>
          <template #item.legalRepresenterMaps="{ item }">
            <div v-for="r in generateRepresenterMap(item)" :key="r">
              {{ r }}
            </div>
          </template>
          <template #item.resourceID="{ value }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-horizontal</v-icon>
                  <span class="d-sr-only">Action</span>
                </v-btn>
              </template>
              <v-list dense style="min-width:100px">
                <v-list-item class="inset-focus" @click="$emit('edit', value)">
                  <v-list-item-title>
                    {{ $t('global.edit') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="inset-focus" @click="remove(value)">
                  <v-list-item-title>
                    {{ $t('global.remove') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template #footer>
            <paging-controls :page="page" @changePage="changePage"/>
          </template>
        </v-data-table>

        <div class="pa-3 mt-12 text-right">
          <c-btn @click="$emit('start')">
            {{ $t('page.manageFiling.party.button.addParty') }}
          </c-btn>
          <c-btn v-if="isStepComplete" type="primary" @click="$emit('next')">
            {{ $t('global.continue') }}
          </c-btn>
        </div>
      </v-card>

      <parties-splash-card v-else
                           key="splashCard"
                           @addParty="$emit('start')"
                           @viewParties="viewParties"
                           @continue="$emit('next')"/>

    </transition>
  </loading-area>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import LoadingArea from '@components/support/LoadingArea';
import RenderAddress from '@/components/support/RenderAddress';
import PagingControls from '@components/support/PagingControls';
import PartiesSplashCard from '@components/support/manageFiling/party/PartiesSplashCard';
import Filing from '@/application/filing';

export default {
  components: { RenderAddress, PagingControls, LoadingArea, PartiesSplashCard },
  data () {
    return {
      initialLoad: true,
      hasSeenStep: false,
      loading: false,
      parties: [],
      page: {
        size: 20,
        number: 0,
        totalElements: 0,
        totalPages: 0
      },
      headers: [
        { text: this.$t('page.manageFiling.party.table.column.role'), value: 'partySubTypeExternalName', sortable: false },
        { text: this.$t('page.manageFiling.party.table.column.name'), value: 'actor.sortName', sortable: false },
        { text: this.$t('page.manageFiling.party.table.column.address'), value: 'actor.mailingAddress', sortable: false },
        { text: this.$t('page.manageFiling.party.table.column.representation'), value: 'legalRepresenterMaps', sortable: false },
        { text: this.$t('global.action'), value: 'resourceID', sortable: false, align: 'right' }
      ]
    };
  },
  computed: {
    filingID () {
      return this.$store.state.filing.filingID;
    },
    isStepComplete () {
      return this.$store.getters['filing/isStepComplete'](this.CONST.FILING_STEP_PARTIES);
    }
  },
  created () {
    this.hasSeenStep = this.$store.getters['filing/hasSeenStep'];
    if (!this.hasSeenStep)
    {
      this.$store.commit('filing/setMaxSeenStep', this.$store.getters['filing/getSelectedStepID']);
    }

    this.loadParties()
      .finally(() => {
        this.initialLoad = false;
      });
  },
  methods: {
    loadParties () {
      this.loading = true;

      let url = this.$cove.getAPI({
        name: 'api.filing.caseParties',
        query: {
          filingID: this.filingID,
          size: this.page.size,
          page: this.page.number,
          fields: '**,actor(*,mailingAddress(*)),legalRepresenterMaps(*,existingLegalRepresenter(*),legalRepresenter(*,actor(*)))'
        }
      });

      return axios.get(url).
        then((response) => {
          this.parties = _.get(response, 'data._embedded.results', []);
          this.page.number = response.data.page.number;
          this.page.totalPages = response.data.page.totalPages;
          this.page.totalElements = response.data.page.totalElements;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changePage (newPage) {
      this.page.number = newPage;
      this.loadParties();
    },
    viewParties () {
      this.hasSeenStep = true;
    },
    generateRepresenterMap (party) {
      let result = [];
      let proSe = _.find(party.legalRepresenterMaps, { proSe: true });
      if (proSe)
      {
        result.push(this.$t('global.selfRep'));
      }
      else
      {
        _.forEach(party.legalRepresenterMaps, (lrm) => {
          let possibleNames = [
            _.get(lrm, 'legalRepresenter.actor.sortName', null),
            _.get(lrm, 'existingLegalRepresenter.sortName', null)
          ];

          result.push(_.compact(possibleNames)[0]);
        });
      }

      return result;
    },
    remove (partyID) {
      this.$cove.confirm({ message: this.$t('application.remove.confirm') })
        .then((confirmed) => {
          if (confirmed)
          {
            this.$cove.block({ delay: 0 });
            Filing.removeParty(partyID)
              .then(() => {
                let p1 = this.loadParties();
                let p2 = this.$store.dispatch('filing/toggleSteps', { visible: true, refresh: true });
                return Promise.all([p1, p2]);
              })
              .then(() => {
                this.$cove.notify({ message: this.$t('application.remove.success'), color: 'success' });
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    }
  }
};
</script>