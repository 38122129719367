<template>
  <div>
    <transition name="scroll-x-reverse-transition">
      <v-card v-show="!loading">
        <v-card-title>
          {{ $t('page.initialRegistration.userType.title') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('page.initialRegistration.userType.description') }}
        </v-card-subtitle>
        <v-card-text>
          <v-list class="transparent py-0">
            <template v-for="(userType, i) in userTypes">
              <action-list-item :key="`${userType.resourceID}-item`"
                                @click="selectUserType(userType)"
                                :icon="getIconByUserType(userType)"
                                :title="userType.displayName"
                                :description="userType.description"/>
              <v-divider v-if="i !== userTypes.length - 1" :key="`${userType.resourceID}-divider`"/>
            </template>
          </v-list>

          <v-divider/>

          <div class="mt-4 d-flex align-center">
            <div style="flex: 0 0 180px">
              <c-btn large block aria-describedby="skip-description" @click="skip">
                {{ $t('global.skipThisStep') }}
              </c-btn>
            </div>
            <div style="flex: 1 1 auto" id="skip-description" class="px-5 text-minimum-gray">
              {{ $t('page.initialRegistration.userType.skipDescription') }}
            </div>
          </div>
        </v-card-text>
      </v-card>
    </transition>
  </div>
</template>

<script>
import ActionListItem from '@components/support/ActionListItem';
import _ from 'lodash';
import axios from 'axios';

export default {
  components: { ActionListItem },
  data () {
    return {
      loading: true,
      userTypes: []
    };
  },
  created () {
    this.$cove.block();
    this.loadUserTypes()
      .finally(() => {
        this.loading = false;
        this.$cove.unblock();
      });
  },
  methods: {
    selectUserType (userType) {
      this.$emit('selectUserType', userType);
    },
    loadUserTypes () {
      return axios.get(this.$cove.getAPI({ name: 'api.usertypes' }))
        .then((response) => {
          this.userTypes = _.get(response, 'data._embedded.results', []);
        });
    },
    getIconByUserType (userType) {
      let icon = 'mdi-account';
      switch (userType.resourceID)
      {
        case this.CONST.USER_TYPE_ATTORNEY:
          icon = 'mdi-briefcase-account';
          break;
        case this.CONST.USER_TYPE_INTERPRETER:
          icon = 'mdi-chat-processing';
          break;
        case this.CONST.USER_TYPE_PUBLIC:
          icon = 'mdi-account';
          break;
        case this.CONST.USER_TYPE_ORGANIZATION_ADMIN:
          icon = 'mdi-office-building';
          break;
      }

      return icon;
    },
    skip () {
      this.$router.push({ name: 'home' });
    }
  }
};
</script>