<template>
  <loading-card :loading="loading" class="nav-tab-small">
    <template #default>
      <v-card-title>
        {{ $t('page.admin.userAccount.address.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.admin.userAccount.address.description') }}
      </v-card-subtitle>
      <v-card-text class="mt-3 pb-9">
        <dl v-if="!isNil(user.actor.mailingAddress)" class="d-grid">
          <dt>{{ $t('page.admin.userAccount.address.address') }}</dt>
          <dd><render-address :address="user.actor.mailingAddress"/></dd>
        </dl>
        <cutout-area v-else class="py-4">
          {{ $t('page.admin.userAccount.address.missing') }}
        </cutout-area>
      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import axios from 'axios';
import LoadingCard from '@components/support/LoadingCard';
import CutoutArea from '@components/support/CutoutArea';
import RenderAddress from '@components/support/RenderAddress';

export default {
  components: { LoadingCard, RenderAddress, CutoutArea },
  props: {
    userID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      user: null
    };
  },
  created () {
    let url = this.$cove.getAPI({
      name: 'api.user',
      params: {
        userID: this.userID
      },
      query: {
        fields: '*,actor(*,mailingAddress(*))'
      }
    });

    axios.get(url)
      .then((response) => {
        this.user = response.data;
      }).finally(() => {
        this.loading = false;
      });
  }
};
</script>