<template>
  <loading-card :loading="loading">
    <v-card-title>
      {{ $t('page.manageFiling.abbreviatedFiling.docketEntryDetails.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.manageFiling.abbreviatedFiling.docketEntryDetails.description') }}
    </v-card-subtitle>
    <v-card-text class="pt-2">
      <v-row no-gutters>
        <v-col cols="12">
          <c-textarea
            v-model="bean.docketEntry.docketEntryNote"
            :label="$t('page.manageFiling.abbreviatedFiling.docketEntryDetails.filingStatement')"
            maxlength="2000"
            outlined
            hide-details="auto"
            aria-describedby="1a1e42d2-8321-48a7-ab9f-f1347c879354"/>
          <field-description id="1a1e42d2-8321-48a7-ab9f-f1347c879354"
                             :message-key="filingStatementDescriptionKey"/>
        </v-col>
        <v-col cols="12">
          <c-textarea
            v-model="bean.filing.filingNote"
            :label="$t('page.manageFiling.abbreviatedFiling.docketEntryDetails.comments')"
            maxlength="2000"
            outlined
            hide-details="auto"
            aria-describedby="b199aeb7-6b7d-40d2-b956-8f62a2b25b32"/>
          <field-description id="b199aeb7-6b7d-40d2-b956-8f62a2b25b32"
                             message-key="page.manageFiling.abbreviatedFiling.docketEntryDetails.comments.description"/>
        </v-col>
        <v-col cols="12">
          <section-separator :title="'File Attachment'" class="mb-5"/>
        </v-col>
        <v-col cols="12">
          <template v-if="bean.docketEntryDocument.documentLinkID === null">
            <c-file-input
              v-model="bean.document.file"
              :label="$t('page.manageFiling.abbreviatedFiling.docketEntryDetails.file')"
              key="file"
              outlined
              hide-details="auto"
              aria-describedby="7b1ebb78-322f-450d-9c14-ea6c85b022cb"/>
            <field-description id="7b1ebb78-322f-450d-9c14-ea6c85b022cb">
              {{ $t(docketEntryDocumentFileDescriptionKey) }}
              <button type="button" class="link-button" @click="openFileTypesPopup">
                {{ $t('page.manageFiling.abbreviatedFiling.docketEntryDetails.file.fileTypes') }}
              </button>
            </field-description>
            <file-types-popup v-model="fileTypesPopupVisible" :fileType="CONST.DOCUMENT_TYPE_FILING"/>
          </template>
          <template v-else>
            <c-btn small :href="viewDocumentURL" target="_blank">
              {{ $t('global.viewFile') }}
            </c-btn>
            <c-btn small @click="queueDocumentForRemoval">
              {{ $t('global.removeFile') }}
            </c-btn>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-end mt-5">
      <c-btn type="primary" @click="save">
        {{ $t('global.save') }}
      </c-btn>
      <template v-if="editing">
        <c-btn @click="$emit('cancel')">
          {{ $t('global.cancel') }}
        </c-btn>
      </template>
    </v-card-actions>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import WorkflowStepMixin from '@/mixins/workflowStep';
import FileTypesPopup from '@components/support/FileTypesPopup';
import { saveDocument } from '@/application/application';
import { validateFile } from '@/application/utility';
import Filing from '@/application/filing';

export default {
  components: { FileTypesPopup },
  mixins: [ WorkflowStepMixin ],
  props: {
    editing: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fileTypesPopupVisible: false,
      removeDocumentLinkID: null
    };
  },
  computed: {
    viewDocumentURL () {
      return this.$cove.getAPI({
        name: 'api.dms.document',
        params: { documentLinkID: this.bean.docketEntryDocument.documentLinkID }
      });
    },
    isFilingStatementRequired () {
      return Filing.getAbbreviatedFilingConfig().requireFilingStatement;
    },
    isDocumentRequired () {
      return Filing.getAbbreviatedFilingConfig().requireDocument;
    },
    isFilingStatementOrDocumentRequired () {
      return Filing.getAbbreviatedFilingConfig().requireFilingStatementOrDocument;
    },
    filing () {
      return this.$store.state.filing.filing;
    },
    filingType () {
      return this.$store.state.filing.filingType;
    },
    filingStatement () {
      return this.bean.docketEntry.docketEntryNote;
    },
    comments () {
      return this.bean.filing.filingNote;
    },
    filingStatementDescriptionKey () {
      let config = Filing.getAbbreviatedFilingConfig();
      if (config.requireFilingStatementOrDocument)
      {
        return 'page.manageFiling.abbreviatedFiling.docketEntryDetails.filingStatementOrDocument.description';
      }
      else if (config.requireFilingStatement)
      {
        return 'page.manageFiling.abbreviatedFiling.docketEntryDetails.filingStatement.description';
      }
      else
      {
        return '';
      }
    },
    docketEntryDocumentFileDescriptionKey () {
      let config = Filing.getAbbreviatedFilingConfig();
      if (config.requireFilingStatementOrDocument)
      {
        return 'page.manageFiling.abbreviatedFiling.docketEntryDetails.filingStatementOrDocument.description';
      }
      else if (config.requireDocument)
      {
        return 'page.manageFiling.abbreviatedFiling.docketEntryDetails.file.description';
      }
      else
      {
        return '';
      }
    }
  },
  methods: {
    loadData () {
      return this.$store.dispatch('application/loadFileTypes', this.CONST.DOCUMENT_TYPE_FILING);
    },
    validate () {
      this.$store.commit('cove/errors/clear');

      if (this.isFilingStatementRequired && _.isNil(this.filingStatement) )
      {
        this.$cove.validation.addErrorText('filingStatement', 'Filing statement is required');
      }

      if (this.isDocumentRequired && _.isNil(this.bean.document.file)
          && _.isNil(this.bean.docketEntryDocument.documentLinkID))
      {
        this.$cove.validation.required(this.bean.document.file, 'document.file', 'file');
      }

      if (this.isFilingStatementOrDocumentRequired && _.isNil(this.filingStatement)
          && _.isNil(this.bean.document.file) && _.isNil(this.bean.docketEntryDocument.documentLinkID))
      {
        this.$cove.validation.addErrorText('filingStatementOrDocument',
          'Either Filing Statement or Docket Entry Document is required');
      }

      if (!_.isNil(this.bean.document.file))
      {
        validateFile(this.bean.document.file, this.CONST.DOCUMENT_TYPE_FILING, 'page.manageAnnouncement.field.file', 'file');
      }

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      let isValid = this.validate();
      if (!isValid)
      {
        this.scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
      }
      else
      {
        if (this.bean.document.file !== null)
        {
          this.$cove.block({ delay: 0 });
          saveDocument(this.CONST.SYSTEM_TABLE_DOCKET_ENTRY_DOCUMENT, 'Filing Document', this.bean.document.file)
            .then((value) => {
              // we'll wait to do the removal until at least the new document has saved successfully
              if (this.removeDocumentLinkID !== null)
              {
                this.removeDocument();
              }

              this.bean.docketEntryDocument.documentLinkID = value;
              this.bean.document.file = null;

              // this is annoying but we want to make sure the block comes down (in the finally) before we emit from here
              // since sometimes we have to block again as part of the parent save process
              setTimeout(() => {
                this.$emit('save', this.bean);
              }, 1);
            })
            .finally(() => {
              this.$cove.unblock();
            });
        }
        else
        {
          if (this.removeDocumentLinkID !== null)
          {
            this.removeDocument();
            this.bean.document.file = null;
          }

          this.$emit('save', this.bean);
        }
      }
    },
    queueDocumentForRemoval () {
      this.removeDocumentLinkID = this.bean.docketEntryDocument.documentLinkID;
      this.bean.docketEntryDocument.documentLinkID = null;
    },
    removeDocument () {
      // this AJAX call configured to fail silently since failures might confuse the user and at worst we end up with
      // some orphaned documents
      let url = this.$cove.getAPI({ name: 'api.dms.document', params: { documentLinkID: this.removeDocumentLinkID } });
      axios.delete(url, { errorHandlers: { 400: null, 404: null, 500: null } })
        .catch((error) => {
          console.error('Error removing document from filing');
          console.error(error);
        });

      this.removeDocumentLinkID = null;
    },
    openFileTypesPopup () {
      this.fileTypesPopupVisible = true;
    }
  }
};
</script>