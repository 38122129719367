<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-sheet rounded elevation="2">
          <v-data-table
            v-model="selected"
            :items="config.items"
            :headers="config.headers"
            :sort-by="config.searchDTO.sort.sortBy"
            :sort-desc="config.searchDTO.sort.sortDesc"
            :server-items-length="config.searchDTO.page.totalElements"
            :item-key="config.itemKey"
            :show-select="config.showSelect"
            checkbox-color="primary"
            must-sort
            hide-default-footer
            :mobile-breakpoint="800"
            @update:options="config.onVDataTableUpdate">

            <!-- Copy scoped slots so the implementation of this template can use v-data-table slots -->
            <template v-for="s in dataTableScopedSlots" :slot="s.slot" slot-scope="scope">
              <slot :name="s.name" v-bind="scope"/>
            </template>

            <template #top>
              <div class="d-flex align-center">
                <div class="c-caption pt-2 pl-3" style="flex:1 1 auto">
                  <slot name="table-title"/>
                </div>
                <div class="text-right pt-2 pr-2 d-print-none" style="flex:1 1 auto">
                  <v-btn icon color="primary" @click="openDrawer">
                    <v-icon>
                      mdi-filter
                    </v-icon>
                    <span class="d-sr-only">
                      {{ $t('global.table.filterAndSort') }}
                    </span>
                  </v-btn>
                </div>
              </div>
            </template>

            <template #footer>
              <paging-controls :page="config.searchDTO.page" :selected-count="selected.length" @changePage="config.onVDataTableChangePage"/>
            </template>
          </v-data-table>
          <div>
            <slot name="tableFooter"/>
          </div>
        </v-sheet>
      </v-col>
    </v-row>

    <!--  Navigation Drawer  -->
    <v-navigation-drawer
      :value="drawer"
      @input="closeDrawer"
      temporary
      right
      app
      disable-route-watcher
      width="400"
      class="js-filter-sidebar">

      <!-- Copy scoped slots so the implementation of this template can use v-navigation-drawer slots -->
      <template v-for="s in navDrawerScopedSlots" :slot="s.slot" slot-scope="scope">
        <slot :name="s.name" v-bind="scope"/>
      </template>

      <!-- Filter Title -->
      <div class="px-4 py-2">
        <div class="d-flex align-center">
          <div style="flex:0 0 auto">
            <h1 class="text-h6" tabindex="-1" style="line-height:1rem;outline-offset:6px">
              <slot name="nav-drawer-title">
                {{ $t('global.drawer.filterSort.title') }}
              </slot>
            </h1>
          </div>
          <v-spacer/>
          <div style="flex:0 0 auto" class="text-right">
            <v-btn icon color="default" @click="closeDrawer">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <p class="mb-0 text-minimum-gray">
          <slot name="nav-drawer-description">
            {{ $t('global.drawer.filterSort.description') }}
          </slot>
        </p>
      </div>
      <v-divider/>

      <!-- Filter - Sort Section  -->
      <div class="px-4 pt-2 pb-6">
        <div class="c-caption mb-5">
          {{ $t('global.sort') }}
        </div>
        <slot name="nav-drawer-sort-body">
          <c-autocomplete
            v-model="config.filter.sortBy"
            :label="$t('global.drawer.filterSort.field.sortBy')"
            :clearable="false"
            :items="config.sortableColumns"
            item-text="text"
            item-value="value"
            outlined
            hide-details/>
          <transition name="fade-transition">
            <v-radio-group
              v-model="config.filter.sortDesc"
              class="mb-n1"
              hide-details
              :label="$t('global.drawer.filterSort.field.sortDir')">
              <v-radio
                :label="$t('global.ascending')"
                :value="false"/>
              <v-radio
                :label="$t('global.descending')"
                :value="true"/>
            </v-radio-group>
          </transition>
        </slot>
      </div>
      <v-divider/>

      <!-- Filter - Filter Section -->
      <div class="px-4 pt-2 pb-4">
        <div class="c-caption js-criteria-anchor">
          {{ $t('global.filter') }}
        </div>

        <!-- Validation Section -->
        <validation-error-panel class="mt-2 mb-n1"/>

        <!-- Filter Body -->
        <div>
          <transition name="scroll-y-transition" mode="out-in">
            <div :key="config.searchDTO.advanced">
              <slot name="nav-drawer-filter-body"/>
            </div>
          </transition>
        </div>
      </div>

      <!-- Button Bar -->
      <template #append>
        <v-divider/>
        <div class="d-flex pa-4">
          <div class="pr-1" style="flex:0 0 50%">
            <c-btn block type="primary" @click="onUpdateButtonClick">
              <slot name="nav-drawer-button-label-update">
                {{ $t('application.button.update') }}
              </slot>
            </c-btn>
          </div>
          <div class="pl-1" style="flex:0 0 50%">
            <c-btn block @click="closeDrawer">
              <slot name="nav-drawer-button-label-cancel">
                {{ $t('application.button.cancel') }}
              </slot>
            </c-btn>
          </div>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import PagingControls from '@components/support/PagingControls';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import _ from 'lodash';

export default {
  components: { PagingControls, ValidationErrorPanel },
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      drawer: false,
      selected: []
    };
  },
  computed: {
    dataTableScopedSlots () {
      return this.getFilteredScopedSlots('v-data-table');
    },
    navDrawerScopedSlots () {
      return this.getFilteredScopedSlots('v-navigation-drawer');
    }
  },
  watch: {
    drawer (newValue) {
      this.config.onDrawerUpdate(newValue);
    },
    selected (newValue) {
      this.config.onSelectedUpdate(newValue);
    }
  },
  methods: {
    getFilteredScopedSlots (byPrefix)
    {
      let results = [];
      let scopedSlotNames = Object.keys(this.$scopedSlots);

      // filter out all slot names without the prefix
      scopedSlotNames = _.filter(scopedSlotNames, (scopedSlotName) => {
        return scopedSlotName.startsWith(byPrefix + '.');
      });

      _.forEach(scopedSlotNames, (scopedSlotName) => {
        results.push({ name: scopedSlotName, slot: scopedSlotName.slice((byPrefix + '.').length) });
      });

      return results;
    },
    onUpdateButtonClick ()
    {
      this.config.onFilterSearch();
      if (!this.$store.getters['cove/errors/hasErrors']()) {
        this.closeDrawer();
      }
      this.clearSelected();
    },
    openDrawer () {
      this.drawer = true;
    },
    closeDrawer (value) {
      // If the closeDraw is attached to the @input of the nav drawer event we need to make sure to not close
      // the drawer immediately after it is opened.  In that case it will have an argument value of true
      if (value !== true)
      {
        this.drawer = false;
      }
    },
    clearSelected () {
      this.selected = [];
    }
  }
};
</script>