<template>
  <div>
    <transition name="scroll-x-reverse-transition" mode="out-in">
      <v-card v-if="hasSeenStep" key="summary">
        <v-card-title>
          {{ $t('page.manageFiling.filingInfo.summary.title') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('page.manageFiling.filingInfo.summary.description') }}
        </v-card-subtitle>
        <v-card-text class="pt-4">
          <dl class="d-grid grid-4060">
            <dt>{{ $t('filing.filedOnBehalfOf') }}</dt>
            <dd>
              <div v-for="f in filedOnBehalfOf" :key="f.resourceID">
                {{ getFiledByName(f) }}
              </div>
            </dd>
            <dt>{{ $t('filing.referenceName') }}</dt>
            <dd>
              {{ filing.referenceName }}
            </dd>
            <template v-if="filing.filingNote">
              <dt>{{ $t('filing.filingNote') }}</dt>
              <dd>
                {{ filing.filingNote }}
              </dd>
            </template>
            <template v-if="filing.confidential">
              <dt>{{ $t('filing.confidential') }}</dt>
              <dd>
                <v-icon style="position:relative;top:-2px;left:-2px">mdi-check</v-icon>
                <span class="d-sr-only">
                  {{ $t('global.yes') }}
                </span>
              </dd>
            </template>
          </dl>

          <div class="mt-16 text-right">
            <c-btn @click="$emit('edit')">
              {{ $t('global.edit') }}
            </c-btn>
            <c-btn v-if="isStepComplete" type="primary" @click="$emit('next')">
              {{ $t('global.continue') }}
            </c-btn>
          </div>
        </v-card-text>
      </v-card>

      <filing-info-splash-card v-else
                               key="splash"
                               @edit="$emit('edit')"
                               @viewInformation="viewInformation"/>

    </transition>
  </div>
</template>

<script>
import _ from 'lodash';
import FilingInfoSplashCard from '@components/support/manageFiling/filingInfo/FilingInfoSplashCard';
import Filing from '@/application/filing';

export default {
  components: { FilingInfoSplashCard },
  data () {
    return {
      hasSeenStep: false
    };
  },
  computed: {
    filing () {
      return this.$store.state.filing.filing;
    },
    isStepComplete () {
      return this.$store.getters['filing/isStepComplete'](this.CONST.FILING_STEP_FILING_INFO);
    },
    filedOnBehalfOf () {
      return _.defaultTo(this.$store.getters['filing/getFiledOnBehalfOf'], []);
    }
  },
  created () {
    this.hasSeenStep = this.$store.getters['filing/hasSeenStep'];
    if (!this.hasSeenStep)
    {
      this.$store.commit('filing/setMaxSeenStep', this.$store.getters['filing/getSelectedStepID']);
    }
  },
  methods: {
    viewInformation () {
      this.hasSeenStep = true;
    },
    getFiledByName (filedOnBehalfOf) {
      return Filing.getFiledOnBehalfOfName(filedOnBehalfOf);
    }
  }
};
</script>
