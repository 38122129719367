<template>
  <loading-card :loading="loading" class="nav-tab-medium">
    <template #default>
      <v-card-title>
        {{ $t('page.currentUser.documentAccess.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.currentUser.documentAccess.description') }}
      </v-card-subtitle>
      <v-card-text>
        <mini-card class="mt-4 mb-8">
          <v-card-text class="pa-6">
            <div class="d-flex" style="gap:24px">
              <div class="flex-grow-0">
                <v-icon color="primary" style="font-size:50px">
                  mdi-file-document-check
                </v-icon>
              </div>
              <v-divider vertical/>
              <div class="flex-grow-1 text-minimum-gray align-self-center" style="max-width:300px;">
                {{ cardText }}
              </div>
            </div>
          </v-card-text>
        </mini-card>
      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import MiniCard from '@components/support/MiniCard';
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { LoadingCard, MiniCard },
  data () {
    return {
      loading: true,
      hasFreeDocuments: false,
      hasOrgFreeDocuments: false
    };
  },
  computed: {
    cardText () {
      let result = null;
      if (this.hasOrgFreeDocuments)
      {
        result = this.$t('page.currentUser.documentAccess.userOrgFreeDocs');
      }
      else if (this.hasFreeDocuments)
      {
        result = this.$t('page.currentUser.documentAccess.freeDocAccess.description');
      }

      return result;
    }
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.user.currentuser', query: { fields: '*,organization(*)' } }))
      .then((response) => {
        this.hasFreeDocuments = _.get(response, 'data.freeDocuments', false);
        this.hasOrgFreeDocuments = _.get(response, 'data.organization.freeDocuments', false);

        // if this user doesn't have free docs, don't render this card at all and instead set them back to default profile card
        if (this.hasFreeDocuments || this.hasOrgFreeDocuments)
        {
          this.loading = false;
        }
        else
        {
          this.$router.replace({ name: 'user-profile' });
        }
      })
      .catch(() => {
        this.loading = false;
      });
  }
};
</script>