<template>
  <page-wrapper :title="$t('page.myNotifications.title')">

    <search-result-template :config="config" ref="searchResultTemplate">

      <template #table-title>
        {{ $t('page.myNotifications.table') }}
      </template>

      <template #v-data-table.item.readFlag="{ value }">
        <div v-if="value">
          <v-icon>mdi-email-open</v-icon>
          <span class="d-sr-only">{{ $t('global.read') }}</span>
        </div>
        <div v-if="!value">
          <v-icon color="primary">mdi-email</v-icon>
          <span class="d-sr-only">{{ $t('global.unread') }}</span>
        </div>
      </template>
      <template #v-data-table.item.createdDate="{ value }">
        <div style="min-width:100px">
          {{ value | formatDateTime }}
        </div>
      </template>
      <template #v-data-table.item.caseNumber="{ item }">
        <div v-if="item.caseNumber" style="white-space: nowrap">
          <router-link :to="{ name: 'caseView', params: { courtID: item.courtID, caseUUID: item.caseIdentifier } }">
            {{ item.caseNumber }}
          </router-link>
        </div>
      </template>
      <template #v-data-table.item.notificationID="{ item }">
        <button class="link-button" @click="openNotificationPopup(item)">
          {{ $t('global.view') }}
        </button>
      </template>

      <template #tableFooter>
        <div class="text-right pb-2 pr-2">
          <c-btn type="primary" class="pa-4" small @click="markAsReadMultiple">
            {{ $t('application.button.markAsRead') }}
          </c-btn>
        </div>
      </template>

      <template #nav-drawer-filter-body>
        <v-row no-gutters class="mt-4">
          <v-col cols="12">
            <date-range-picker
              :label="$t('page.searchCriteria.notification.field.date')"
              :labelStart="$t('page.searchCriteria.notification.field.dateFrom')"
              :labelEnd="$t('page.searchCriteria.notification.field.dateTo')"
              keyPrefix="createdDate"
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.dateChoice"
              :startDate.sync="searchDTO.dateStart"
              :endDate.sync="searchDTO.dateEnd"
              aria-describedby="84dc7fb7-0069-4cd0-94cd-5df8d91068ff"/>
            <field-description id="84dc7fb7-0069-4cd0-94cd-5df8d91068ff"
                               message-key="page.searchCriteria.notification.field.date.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.caseNumber"
              :label="$t('page.searchCriteria.notification.field.caseNumber')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="74b7ac73-1939-4ed5-8a8a-d0fd198908df"/>
            <field-description id="74b7ac73-1939-4ed5-8a8a-d0fd198908df"
                               message-key="page.searchCriteria.notification.field.caseNumber.description"/>
          </v-col>
          <v-col cols="12">
            <c-checkbox
              v-model="searchDTO.showUnreadOnly"
              class="mt-0 pt-0"
              :label="$t('page.searchCriteria.notification.field.showUnreadOnly')"
              aria-describedby="b13a5eea-348c-48eb-a338-00e895852a93"/>
            <field-description id="b13a5eea-348c-48eb-a338-00e895852a93"
                               message-key="page.searchCriteria.notification.field.showUnreadOnly.description"/>
          </v-col>
        </v-row>
      </template>
    </search-result-template>

    <my-notification-popup v-model="notificationPopupVisible" :notification="popupNotification" @reload="reload"/>

  </page-wrapper>
</template>

<script>

import CONST from '@/application/constants';
import SearchResultTemplate from '@components/support/template/SearchResultTemplate';
import SearchResultTemplateMixin from '@/mixins/searchResultTemplate';
import _ from 'lodash';
import { dateToEndOfDay, dateToStartOfDay } from '@/application/application';
import axios from 'axios';
import MyNotificationPopup from '@components/support/work/MyNotificationPopup';
import { DateTime } from 'luxon';

export default {
  components: { MyNotificationPopup, SearchResultTemplate },
  mixins: [ SearchResultTemplateMixin ],
  data () {
    return {
      showSelect: true,
      searchDTO: {
        sort: {
          sortBy: 'createdDate',
          sortDesc: true
        },
        dateChoice: '-3m',
        dateStart: this.$cove.formatDate(DateTime.local().minus({ 'month': 3 })),
        dateEnd: this.$cove.formatDate(DateTime.local()),
        caseNumber: null,
        showUnreadOnly: null
      },

      // for popup
      notificationPopupVisible: false,
      popupNotification: {}
    };
  },
  methods: {
    getItemKey () {
      return 'resourceID';
    },
    getRouteName () {
      return 'work-notifications';
    },
    getSearchAPIName () {
      return 'api.notifications';
    },
    getHeaders () {
      let headers = [];

      headers.push({ text: this.$t('notification.read'), value: 'readFlag', sortable: true, width: '80px' });
      headers.push({ text: this.$t('notification.createdDate'), value: 'createdDate', sortable: true });
      headers.push({ text: this.$t('notification.subject'), value: 'subject', sortable: true });
      headers.push({ text: this.$t('courtCase.caseNumber'), value: 'caseNumber', sortable: true });
      headers.push({ text: this.$t('page.searchResults.notification.viewLinkHeader'), value: 'notificationID',
        sortable: false, align: 'right' });

      return headers;
    },
    addSearchCriteria (query) {

      query.recipientUserID = this.$store.state.user.userID;
      query.notificationMethodID = CONST.NOTIFICATION_METHOD_APPLICATION;
      query.notificationStatusID = CONST.NOTIFICATION_STATUS_SENT;

      if (!_.isNil(this.searchDTO.sort.sortBy))
      {
        query.sort = `${this.searchDTO.sort.sortBy},${this.searchDTO.sort.sortDesc ? 'desc' : 'asc'}`;
      }

      if (!_.isNil(this.searchDTO.dateStart))
      {
        query.dateFrom = dateToStartOfDay(this.$cove.parseDate(this.searchDTO.dateStart)).toISO();
      }

      if (!_.isNil(this.searchDTO.dateEnd))
      {
        query.dateTo = dateToEndOfDay(this.$cove.parseDate(this.searchDTO.dateEnd)).toISO();
      }

      if (!_.isNil(this.searchDTO.caseNumber))
      {
        query.caseNumber = this.searchDTO.caseNumber;
      }

      if (!_.isNil(this.searchDTO.showUnreadOnly))
      {
        query.showUnreadOnly = this.searchDTO.showUnreadOnly;
      }
      return query;
    },
    validateSearchCriteria (searchDTO)
    {
      this.$cove.validation.date.rejectIfInvalid(searchDTO.dateStart, 'page.searchCriteria.notification.field.dateFrom',
        'createdDate-startDate');
      this.$cove.validation.date.rejectIfInvalid(searchDTO.dateEnd, 'page.searchCriteria.notification.field.dateTo',
        'createdDate-endDate');
      this.$cove.validation.date.rejectIfBefore(searchDTO.dateEnd, searchDTO.dateStart,
        'page.searchCriteria.notification.field.dateTo', 'page.searchCriteria.notification.field.dateFrom', 'createdDate-endDate');
    },
    openNotificationPopup (notification) {
      this.popupNotification = notification;
      this.notificationPopupVisible = true;
      this.markAsRead( [ notification.resourceID ]);
    },
    markAsReadMultiple () {
      if (this.selected.length === 0) {
        this.$cove.notify({ message: this.$t('page.myNotifications.validation.noneSelected'), color: 'warning' });
      } else {
        this.markAsRead(_.map(this.selected, 'resourceID')).then(() => {
          this.reload();
          this.$cove.notify({ message: this.$t('page.myNotifications.markAsRead.success'), color: 'success' });
        });
      }
    },
    markAsRead (notificationRecipientIds) {
      let url = this.$cove.getAPI({
        name: 'api.notifications.markRead',
        query: {
          notificationRecipientIds
        }
      });
      this.$cove.block({ delay: 0 });
      return axios.post(url)
        .then(() => {
          _.filter(this.items, (item) => { return _.includes(notificationRecipientIds, item.notificationRecipientID);})
            .forEach((item) => {item.readFlag = true;});
        }).finally(() => {
          this.$cove.unblock();
          // Get the latest polling data immediately after the mark as read
          this.$store.dispatch('user/poll');
        });
    },
    clearSelected () {
      this.$refs.searchResultTemplate.clearSelected();
    },
    reload () {
      this.clearSelected();
      this._reload();
    }
  }
};
</script>
