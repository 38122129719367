<template>
  <div>
    <loading-card :loading="loading" class="nav-tab-medium">
      <template #default>
        <v-card-title>
          {{ $t('page.manageOrg.subscription.title') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('page.manageOrg.subscription.description') }}
        </v-card-subtitle>
        <v-card-text>

          <v-alert
            :value="displayPurchaseSeatsWarning"
            class="mb-8"
            rounded
            icon="mdi-alert-box"
            color="warning"
            text>
            <div class="font-weight-medium" style="font-size:1.1rem;line-height:1.5">
              {{ $t('global.warning') }}
            </div>
            <div class="mt-2">
              {{ $t('page.manageOrg.subscription.paymentMethod') }}
            </div>
          </v-alert>

          <!-- subscription on record (same view for sys admin and org admin except for action buttons) -->
          <mini-card v-if="hasSubscription" class="mt-4 mb-8">
            <v-card-text>
              <div class="d-flex pa-2" style="gap:24px">
                <div class="flex-grow-0">
                  <v-icon color="primary" style="font-size:50px">
                    mdi-calendar-sync
                  </v-icon>
                </div>
                <v-divider vertical/>
                <div class="flex-grow-1" style="width:300px">
                  <dl class="d-grid">
                    <dt>{{ $t('subscription.type') }}</dt>
                    <dd>{{ subscription.subscriptionType.displayName }}</dd>
                    <dt>{{ $t('subscription.status') }}</dt>
                    <dd>{{ subscription.subscriptionStatus.displayName }}</dd>
                    <dt>{{ $t('subscription.activeSeats') }}</dt>
                    <dd>{{ subscription.activeSeats }}</dd>
                    <dt>{{ $t('subscription.openSeats') }}</dt>
                    <dd>{{ subscription.openSeats }}</dd>
                    <dt>{{ $t('subscription.totalSeats') }}</dt>
                    <dd>{{ subscription.totalSeats }}</dd>
                    <dt v-if="isActiveSubscription">{{ $t('subscription.renewalDate') }}</dt>
                    <dt v-if="!isActiveSubscription">{{ $t('subscription.terminationDate') }}</dt>
                    <dd>{{ renewalDate | formatDate }}</dd>
                    <dt v-if="isActiveSubscription">{{ $t('subscription.renewalAmount') }}</dt>
                    <dd v-if="isActiveSubscription">
                      <div>{{ subscription.renewalAmount | formatCurrency }}</div>
                      <div class="text-minimum-gray">
                        {{ $t('subscription.seatsCount', [subscription.activeSeats]) }}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end mt-4">
              <template v-if="isAdmin">
                <c-btn v-if="isGrantedSubscription && isActiveSubscription" type="danger"
                       @click="cancelSubscription('page.manageOrg.subscription.confirmRemoveGrantedSub')">
                  {{ $t('subscription.removeGrantedSubscription') }}
                </c-btn>
              </template>
              <template v-else-if="isValidSubscription">
                <c-btn v-if="displayPurchaseSeatsButton" type="primary" @click="purchaseSubscription">
                  {{ $t('subscription.purchaseSeats') }}
                </c-btn>
                <c-btn v-if="!isGrantedSubscription && isActiveSubscription" type="danger"
                       @click="cancelSubscription('page.manageOrg.subscription.confirmCancel')">
                  {{ $t('subscription.cancelSubscription') }}
                </c-btn>
              </template>

              <template v-if="!isAdmin && isCancelledSubscription">
                <c-btn type="primary" @click="reactivateSubscription">
                  {{ $t('subscription.reactivateSubscription') }}
                </c-btn>
              </template>
            </v-card-actions>
          </mini-card>

          <!-- no subscription -->
          <template v-else>

            <!-- sys admin view -->
            <cutout-area v-if="isAdmin" class="mt-4 mb-8">
              {{ $t('page.manageOrg.subscription.noSubscription') }}
              <template #action>
                <c-btn type="primary" @click="grantSubscription">
                  {{ $t('subscription.grantSubscription') }}
                </c-btn>
              </template>
            </cutout-area>

            <!-- no payment method -->
            <cutout-area v-else-if="!hasPaymentToken" class="mt-4 mb-8">
              {{ $t('page.manageOrg.subscription.paymentMethod') }}
            </cutout-area>

            <!-- org admin view -->
            <cutout-area v-else class="mt-4 mb-8">
              <v-icon color="primary" style="font-size:50px">
                mdi-calendar-sync
              </v-icon>
              <template #action>
                <c-btn type="primary" @click="purchaseSubscription">
                  {{ $t('page.manageOrg.subscription.button.purchase') }}
                </c-btn>
              </template>
            </cutout-area>

          </template>
        </v-card-text>
      </template>
    </loading-card>

    <purchase-sub-seats-popup v-model="purchasePopupVisible" :organizationID="organizationID" @checkoutComplete="loadData"/>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import CutoutArea from '@components/support/CutoutArea';
import LoadingCard from '@components/support/LoadingCard';
import MiniCard from '@components/support/MiniCard';
import PurchaseSubSeatsPopup from '@components/support/PurchaseSubSeatsPopup';
import { calculateSubscriptionRenewalDate } from '@/application/application';

export default {
  components: { LoadingCard, CutoutArea, PurchaseSubSeatsPopup, MiniCard },
  props: {
    organizationID: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      subscription: null,
      loading: true,
      purchasePopupVisible: false,
      hasPaymentToken: false
    };
  },
  computed: {
    isAdmin () {
      return this.$store.getters['user/isAdmin'];
    },
    hasSubscription () {
      return !_.isNil(this.subscription);
    },
    renewalDate () {
      return calculateSubscriptionRenewalDate(this.subscription.subscriptionDetail.startDate);
    },
    isGrantedSubscription () {
      return _.get(this.subscription, 'subscriptionType.grantedSubscription', false);
    },
    isActiveSubscription () {
      return !_.isNil(this.subscription) && this.subscription.subscriptionStatus.resourceID === this.CONST.SUBSCRIPTION_STATUS_ACTIVE;
    },
    isValidSubscription () {
      return _.get(this.subscription, 'subscriptionStatus.validSubscription', false);
    },
    isCancelledSubscription () {
      return _.get(this.subscription, 'subscriptionStatus.resourceID', null) ===
        this.CONST.SUBSCRIPTION_STATUS_CANCELLED;
    },
    displayPurchaseSeatsButton () {
      return !this.isAdmin && this.isValidSubscription && (this.isGrantedSubscription || this.hasPaymentToken);
    },
    displayPurchaseSeatsWarning () {
      return !this.isAdmin && this.isValidSubscription && !this.isGrantedSubscription && !this.hasPaymentToken;
    }
  },
  created () {
    this.loadData();
  },
  methods: {
    loadData () {
      this.loading = true;
      let promises = [];
      let url = this.$cove.getAPI({
        name: 'api.organization',
        params: {
          id: this.organizationID
        },
        query: {
          fields: '*,subscription(*,subscriptionStatus(*),subscriptionType(*))'
        }
      });
      promises.push(axios.get(url)
        .then((response) => {
          this.subscription = _.get(response, 'data.subscription', null);
        }));

      promises.push(axios.get(this.$cove.getAPI({ name: 'api.user.currentuser' }))
        .then((response) => {
          this.hasPaymentToken = _.get(response, 'data.hasOrganizationPaymentToken', false);
        }));

      Promise.all(promises)
        .finally(() => {
          this.loading = false;
        });
    },
    grantSubscription () {
      this.$cove.confirm({ message: this.$t('page.manageOrg.subscription.confirmGrantedSub') })
        .then((confirm) => {
          if (confirm)
          {
            let grantSubRequest = {
              subscriptionTypeID: this.CONST.SUBSCRIPTION_TYPE_ORGANIZATION_GRANTED,
              organizationID: this.organizationID,
              additionalSeats: 0
            };
            this.$cove.block({ delay: 0 });
            axios.post(this.$cove.getAPI({ name: 'api.subscriptions' }), grantSubRequest)
              .then(() => {
                this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
                this.loadData();
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    },
    purchaseSubscription () {
      this.purchasePopupVisible = true;
    },
    cancelSubscription (messageResource) {
      this.$cove.confirm({ message: this.$t(messageResource) })
        .then((confirm) => {
          if (confirm)
          {
            this.$cove.block({ delay: 0 });
            axios.delete(this.$cove.getAPI({ name: 'api.subscription', params: { id: this.subscription.resourceID } }))
              .then(() => {
                this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
                this.loadData();
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    },
    reactivateSubscription () {
      this.$cove.confirm({ message: this.$t('subscription.reactivateSubscription.confirmOrg') })
        .then((confirm) => {
          if (confirm)
          {
            this.$cove.block({ delay: 0 });
            axios.patch(this.$cove.getAPI({ name: 'api.subscription.reactivate', params: { id: this.subscription.resourceID } }))
              .then(() => {
                this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
                this.loadData();
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    }
  }
};
</script>