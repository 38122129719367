<template>
  <loading-card :loading="loading">
    <v-card-title>
      {{ $t('page.manageFiling.party.stepName.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.manageFiling.party.stepName.description') }}
    </v-card-subtitle>
    <v-card-text class="pt-2">
      <v-row no-gutters>
        <v-col cols="12">
          <section-separator :title="$t('page.manageFiling.party.stepName.section.name')" class="mb-5"/>
        </v-col>
        <v-col cols="12">
          <c-autocomplete
            v-model="bean.person"
            :items="actorCategories"
            :label="$t('page.manageFiling.party.stepName.field.type')"
            item-value="value"
            :clearable="false"
            outlined
            @change="changeType"
            hide-details="auto"
            aria-describedby="8d04bec7-cb1d-48d7-9400-70891f969d88"/>
          <field-description id="8d04bec7-cb1d-48d7-9400-70891f969d88"
                             message-key="page.manageFiling.party.stepName.field.type.description"/>
        </v-col>
        <transition name="scroll-y-transition" mode="out-in">
          <v-row v-if="bean.person" no-gutters key="person">
            <v-col cols="12" md="4" class="pr-md-2">
              <c-text-field
                v-model="bean.firstName"
                :label="$t('page.manageFiling.party.stepName.field.firstName')"
                maxlength="100"
                key="firstName"
                required
                outlined
                hide-details="auto"
                aria-describedby="cd9bead3-c382-4b9a-9bf1-c58d98847652"/>
              <field-description id="cd9bead3-c382-4b9a-9bf1-c58d98847652"
                                 message-key="page.manageFiling.party.stepName.field.firstName.description"/>
            </v-col>
            <v-col cols="12" md="4" class="px-md-2">
              <c-text-field
                v-model="bean.middleName"
                :label="$t('page.manageFiling.party.stepName.field.middleName')"
                maxlength="100"
                key="middleName"
                outlined
                hide-details="auto"
                aria-describedby="036e0c4a-b0ca-4b1f-9055-b36d655376fb"/>
              <field-description id="036e0c4a-b0ca-4b1f-9055-b36d655376fb"
                                 message-key="page.manageFiling.party.stepName.field.middleName.description"/>
            </v-col>
            <v-col cols="12" md="4" class="pl-md-2">
              <c-text-field
                v-model="bean.lastName"
                :label="$t('page.manageFiling.party.stepName.field.lastName')"
                maxlength="100"
                key="lastName"
                required
                outlined
                hide-details="auto"
                aria-describedby="75f93950-9378-4166-a781-92fe9df8e5fb"/>
              <field-description id="75f93950-9378-4166-a781-92fe9df8e5fb"
                                 message-key="page.manageFiling.party.stepName.field.lastName.description"/>
            </v-col>
            <v-col cols="12" md="4" class="pr-md-2">
              <c-text-field
                v-model="bean.prefix"
                :label="$t('page.manageFiling.party.stepName.field.prefix')"
                maxlength="20"
                key="prefix"
                outlined
                hide-details="auto"
                aria-describedby="de2ca5cc-5f5d-4cc3-bf34-4514532f25cb"/>
              <field-description id="de2ca5cc-5f5d-4cc3-bf34-4514532f25cb"
                                 message-key="page.manageFiling.party.stepName.field.prefix.description"/>
            </v-col>
            <v-col cols="12" md="4" class="px-md-2">
              <c-text-field
                v-model="bean.suffix"
                :label="$t('page.manageFiling.party.stepName.field.suffix')"
                maxlength="20"
                key="suffix"
                outlined
                hide-details="auto"
                aria-describedby="2edee104-be85-4db6-8749-ebbe27d026b9"/>
              <field-description id="2edee104-be85-4db6-8749-ebbe27d026b9"
                                 message-key="page.manageFiling.party.stepName.field.suffix.description"/>
            </v-col>
          </v-row>
          <v-row v-else no-gutters key="org">
            <v-col cols="12">
              <c-text-field
                v-model="bean.organizationName"
                :label="$t('page.manageFiling.party.stepName.field.orgName')"
                maxlength="250"
                key="orgName"
                outlined
                required
                hide-details="auto"
                aria-describedby="59e298ed-bf26-4e31-bd45-63e18429f459"/>
              <field-description id="59e298ed-bf26-4e31-bd45-63e18429f459"
                                 message-key="page.manageFiling.party.stepName.field.orgName.description"/>
            </v-col>
          </v-row>
        </transition>
        <v-col cols="12">
          <section-separator :title="$t('page.manageFiling.party.stepName.section.alias')" class="mb-5"/>
        </v-col>
        <transition name="scroll-y-transition" mode="out-in">
          <v-col v-if="!showAlias" key="prompt">
            <button type="button" class="link-button" @click="toggleAlias"
                    aria-describedby="53721665-c45c-49cf-a681-f301a6a9ac7d">
              {{ $t('page.manageFiling.party.stepName.action.enterAliasInfo') }}
            </button>
            <field-description id="53721665-c45c-49cf-a681-f301a6a9ac7d"
                               message-key="page.manageFiling.party.stepName.action.enterAliasInfo.description"/>
          </v-col>
          <v-row v-else no-gutters key="display">
            <v-col cols="12" md="4" class="pr-md-2">
              <c-autocomplete
                v-model="bean.aliasTypeID"
                :items="aliasTypes"
                :label="$t('page.manageFiling.party.stepName.field.aliasType')"
                key="aliasType"
                id="alias-type"
                item-text="displayName"
                item-value="resourceID"
                outlined
                hide-details="auto"
                aria-describedby="76c5662e-887b-48d8-808d-d363050d4611"/>
              <field-description id="76c5662e-887b-48d8-808d-d363050d4611"
                                 message-key="page.manageFiling.party.stepName.field.aliasType.description"/>
            </v-col>
            <v-col cols="12" md="8" class="pl-md-2">
              <c-text-field
                v-model="bean.aliasName"
                :label="$t('page.manageFiling.party.stepName.field.aliasName')"
                maxlength="250"
                key="aliasName"
                outlined
                hide-details="auto"
                aria-describedby="a6b7cafa-9987-401f-8a53-1d4bdad7a4f2"/>
              <field-description id="a6b7cafa-9987-401f-8a53-1d4bdad7a4f2"
                                 message-key="page.manageFiling.party.stepName.field.aliasName.description"/>
            </v-col>
          </v-row>
        </transition>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-end mt-5">
      <c-btn type="primary" @click="save">
        {{ editing ? $t('global.save') : $t('global.next') }}
      </c-btn>
      <c-btn v-if="!editing" @click="$emit('back')">
        {{ $t('global.back') }}
      </c-btn>
      <c-btn @click="$emit('cancel')">
        {{ $t('global.cancel') }}
      </c-btn>
    </v-card-actions>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import WorkflowStepMixin from '@/mixins/workflowStep';

export default {
  mixins: [ WorkflowStepMixin ],
  props: {
    editing: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showAlias: false,
      aliasTypes: []
    };
  },
  computed: {
    actorCategories () {
      return _.orderBy([
        { text: this.$t('global.actorType.person'), value: true },
        { text: this.$t('global.actorType.organization'), value: false }
      ], 'text');
    }
  },
  created () {
    this.showAlias = this.bean.aliasTypeID !== null;
  },
  methods: {
    loadData () {
      return axios.get(this.$cove.getAPI({ name: 'api.alias.types', query: { size: this.CONST.PAGING_MAX_SIZE } }))
        .then((response) => {
          this.aliasTypes = _.orderBy(_.get(response, 'data._embedded.results', []), 'displayName');
        });
    },
    validate () {
      this.$store.commit('cove/errors/clear');

      if (this.bean.person)
      {
        this.$cove.validation.required(this.bean.firstName, 'page.manageFiling.party.stepName.field.firstName', 'firstName');
        this.$cove.validation.required(this.bean.lastName, 'page.manageFiling.party.stepName.field.lastName', 'lastName');
      }
      else
      {
        this.$cove.validation.required(this.bean.organizationName, 'page.manageFiling.party.stepName.field.orgName', 'orgName');
      }

      let partialAlias = !_.isNil(this.bean.aliasTypeID) !== !_.isNil(this.bean.aliasName);
      if (partialAlias)
      {
        this.$cove.validation.required(this.bean.aliasTypeID, 'page.manageFiling.party.stepName.field.aliasType', 'aliasType');
        this.$cove.validation.required(this.bean.aliasName, 'page.manageFiling.party.stepName.field.aliasName', 'aliasName');
      }

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      let isValid = this.validate();
      if (!isValid)
      {
        this.scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
      }
      else
      {
        this.$emit('save', this.bean);
      }
    },
    toggleAlias () {
      this.showAlias = true;
      setTimeout(() => {
        document.querySelector('#alias-type').focus();
      }, 350);
    },
    changeType () {
      if (this.bean.person)
      {
        this.bean.organizationName = null;
      }
      else
      {
        this.bean.firstName = null;
        this.bean.middleName = null;
        this.bean.lastName = null;
        this.bean.prefix = null;
        this.bean.suffix = null;
      }
    }
  }
};
</script>