<template>
  <page-wrapper :title="$t('page.caseResults.title')">
    <search-result-template :config="config">

      <template #table-title>
        {{ $t('page.caseResults.table') }}
      </template>

      <!--  Result Values  -->
      <template #v-data-table.item.caseHeader.caseNumber="{ item }">
        <div>
          <div style="white-space: nowrap">
            <router-link :to="{ name: 'caseView', params: { courtID: getCourtByExternalID(item.caseHeader.courtID).resourceID,
                                                            caseUUID: item.caseHeader.caseInstanceUUID} }">
              {{ item.caseHeader.caseNumber }}
            </router-link>
          </div>
        </div>
      </template>

      <template #v-data-table.item.caseHeader.filedDate="{ value }">
        <div style="min-width:100px">
          <div>
            {{ value | formatDate }}
          </div>
          <div>
            {{ value | formatTime }}
          </div>
        </div>
      </template>

      <!-- Search Criteria -->
      <template #nav-drawer-filter-body>
        <v-row no-gutters class="mt-4">
          <v-col v-if="!isSingleCourtMode" cols="12">
            <c-autocomplete
              :items="courts"
              v-model="searchDTO.courtID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('courtCase.court')"
              outlined
              @change="changeCourt"
              key="courtID"
              hide-details="auto"
              aria-describedby="a15c521ed-92a4-4616-b9d1-a80d44675327"/>
            <field-description id="a15c521ed-92a4-4616-b9d1-a80d44675327"
                               message-key="page.caseResults.court.description"/>
          </v-col>
          <v-col cols="12" v-if="!isSingleCaseLocationMode">
            <c-autocomplete
              :items="caseLocations"
              v-model="searchDTO.caseHeader.locationID"
              item-text="locationName"
              item-value="locationID"
              :label="$t('court.location')"
              :no-data-text="$t('global.courtFirst')"
              outlined
              key="locationID"
              hide-details="auto"
              aria-describedby="a15c521ed-92a4-4616-b9d1-a80d44675327"/>
            <field-description id="a15c521ed-92a4-4616-b9d1-a80d44675327"
                               message-key="page.caseResults.location.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.caseHeader.caseNumber"
              :label="$t('courtCase.caseNumber')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="a4fff4d48-1fab-4242-9cb3-cd2556b665ae"/>
            <field-description id="a4fff4d48-1fab-4242-9cb3-cd2556b665ae"
                               message-key="page.caseResults.caseNumber.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.caseHeader.caseTitle"
              :label="$t('courtCase.caseTitle')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="a8d30a3c1-f653-494c-9c1d-2ca48078a2c6"/>
            <field-description id="a8d30a3c1-f653-494c-9c1d-2ca48078a2c6"
                               message-key="page.caseResults.caseTitle.description"/>
          </v-col>
          <v-col v-if="searchDTO.advanced" cols="12">
            <c-autocomplete
              v-model="searchDTO.caseHeader.caseTitleSearchType"
              :label="$t('page.caseResults.caseTitle.caseTitleSearchType')"
              :items="queryTypes"
              outlined
              class="ml-8"
              :clearable="false"
              hide-details="auto"
              aria-describedby="a6b94c73-6561-424b-835f-88e8465eff15"/>
            <field-description id="a6b94c73-6561-424b-835f-88e8465eff15" class="ml-8"
                               message-key="global.queryType.description"/>
          </v-col>
          <v-col cols="12">
            <c-autocomplete
              :items="caseCategories"
              v-model="searchDTO.caseHeader.caseCategoryID"
              item-text="caseCategoryName"
              item-value="caseCategoryID"
              :label="$t('courtCase.caseCategory')"
              :no-data-text="$t('global.courtFirst')"
              outlined
              key="caseCategoryID"
              hide-details="auto"
              aria-describedby="a48577fe1-5322-401b-915e-e7a38fa5489b"/>
            <field-description id="a48577fe1-5322-401b-915e-e7a38fa5489b"
                               message-key="page.caseResults.caseClassification.description"/>
          </v-col>
          <v-col cols="12">
            <date-range-picker
              :label="$t('courtCase.filedDate')"
              :labelStart="$t('courtCase.filedDateFrom')"
              :labelEnd="$t('courtCase.filedDateTo')"
              keyPrefix="caseFiledDate"
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.caseHeader.filedDateChoice"
              :startDate.sync="searchDTO.caseHeader.filedDateStart"
              :endDate.sync="searchDTO.caseHeader.filedDateEnd"
              aria-describedby="a188c3dd0-cad8-48c6-aa77-2d95de2ac6eb"/>
            <field-description id="a188c3dd0-cad8-48c6-aa77-2d95de2ac6eb"
                               message-key="page.caseResults.caseFiledDate.description"/>
          </v-col>
          <v-col cols="12">
            <c-checkbox
              v-model="searchDTO.caseHeader.excludeClosed"
              :label="$t('page.caseResults.excludeClosed')"
              class="mt-0 pt-0"
              hide-details="auto"
              aria-describedby="de9ec188-12eb-4cfe-9f6d-019ed76e97b3"/>
            <field-description id="de9ec188-12eb-4cfe-9f6d-019ed76e97b3"
                               message-key="page.caseResults.excludeClosed.description"/>
          </v-col>
          <v-col v-if="!searchDTO.advanced">
            <button type="button" class="link-button" @click="_toggleAdvancedSearch"
                    aria-describedby="a503a948-0b72-48db-b8b1-83466388b50a">
              {{ $t('page.caseResults.button.switchToAdvanced') }}
            </button>
            <field-description id="a503a948-0b72-48db-b8b1-83466388b50a"
                               message-key="page.caseResults.button.switchToAdvanced.description"/>
          </v-col>
        </v-row>
      </template>

    </search-result-template>

  </page-wrapper>
</template>

<script>

import SearchResultTemplate from '@components/support/template/SearchResultTemplate';
import SearchResultTemplateMixin from '@/mixins/searchResultTemplate';
import _ from 'lodash';
import axios from 'axios';
import CONST from '@/application/constants';
import { dateToEndOfDay, dateToStartOfDay } from '@/application/application';

export default {
  components: { SearchResultTemplate },
  mixins: [ SearchResultTemplateMixin ],
  data () {
    return {
      searchDTO: {
        sort: {
          sortBy: 'caseHeader.filedDate',
          sortDesc: true
        },
        courtID: null,
        caseHeader: {
          courtAbbreviation: null,
          caseNumber: null,
          caseNumberSearchType: CONST.QUERY_TYPE_CONTAINS,
          caseTitle: null,
          caseTitleSearchType: CONST.QUERY_TYPE_MATCH,
          locationID: null,
          location: null,
          caseInstanceID: null,
          caseInstanceUUID: null,
          caseCategoryID: null,
          filedDateChoice: null,
          filedDateStart: null,
          filedDateEnd: null,
          excludeClosed: true
        }
      },
      caseCategories: []
    };
  },
  watch: {
    'searchDTO.advanced' (newValue) {
      if (newValue)
      {
        // only change this query type if they didn't enter anything yet
        if (this.searchDTO.caseHeader.caseTitle === null)
        {
          this.searchDTO.caseHeader.caseTitleSearchType = this.CONST.QUERY_TYPE_CONTAINS;
        }
      }
    }
  },
  methods: {
    getRouteName () {
      return 'work-cases';
    },
    getSearchAPIName () {
      return 'api.search.user.cases';
    },
    getItemKey () {
      return 'caseHeader.caseInstanceUUID';
    },
    getHeaders () {
      let headers = [];
      if (!this.isSingleCourtMode)
      {
        headers.push({ text: this.$t('courtCase.court'), value: 'caseHeader.courtAbbreviation', sortable: true });
      }

      if (!this.isSingleCaseLocationMode)
      {
        headers.push({ text: this.$t('court.location'), value: 'caseHeader.location', sortable: true });
      }

      headers.push({ text: this.$t('courtCase.caseNumber'), value: 'caseHeader.caseNumber', sortable: true });
      headers.push({ text: this.$t('courtCase.caseTitle'), value: 'caseHeader.caseTitle', sortable: true });
      headers.push({ text: this.$t('page.myCases.classification'), value: 'caseHeader.caseClassification',
        sortable: true });
      headers.push({ text: this.$t('page.myCases.filedDate'), value: 'caseHeader.filedDate', sortable: true });

      return headers;
    },
    addSearchCriteria (query) {

      if (!_.isNil(this.searchDTO.sort.sortBy))
      {
        query.sort = `${this.searchDTO.sort.sortBy},${this.searchDTO.sort.sortDesc ? 'desc' : 'asc'}`;
      }

      if (!_.isNil(this.searchDTO.courtID))
      {
        query['caseHeader.courtID'] = this.searchDTO.courtID;
      }

      if (!_.isNil(this.searchDTO.caseHeader.caseNumber))
      {
        query['caseHeader.caseNumber'] = this.searchDTO.caseHeader.caseNumber;

        // Only send the searchType filter if there is text in the caseNumber
        if (!_.isNil(this.searchDTO.caseHeader.caseNumberSearchType))
        {
          query['caseHeader.caseNumberSearchType'] = this.searchDTO.caseHeader.caseNumberSearchType;
        }
      }

      if (!_.isNil(this.searchDTO.caseHeader.caseTitle))
      {
        query['caseHeader.caseTitle'] = this.searchDTO.caseHeader.caseTitle;

        // Only send the searchType filter if there is text in the caseTitle
        if (!_.isNil(this.searchDTO.caseHeader.caseTitleSearchType))
        {
          query['caseHeader.caseTitleSearchType'] = this.searchDTO.caseHeader.caseTitleSearchType;
        }
      }

      if (!_.isNil(this.searchDTO.caseHeader.locationID))
      {
        query['caseHeader.locationID'] = this.searchDTO.caseHeader.locationID;
      }

      if (!_.isNil(this.searchDTO.caseHeader.caseCategoryID))
      {
        query['caseHeader.caseCategoryID'] = this.searchDTO.caseHeader.caseCategoryID;
      }

      if (!_.isNil(this.searchDTO.caseHeader.filedDateStart))
      {
        query['caseHeader.filedDateFrom'] = dateToStartOfDay(this.$cove.parseDate(this.searchDTO.caseHeader.filedDateStart)).toISO();
      }

      if (!_.isNil(this.searchDTO.caseHeader.filingStatusDateEnd))
      {
        query['caseHeader.filedDateTo'] = dateToEndOfDay(this.$covecove.parseDate(this.searchDTO.caseHeader.filedDateEnd)).toISO();
      }

      if (this.searchDTO.caseHeader.excludeClosed)
      {
        query['caseHeader.closedFlag'] = false;
      }

      return query;
    },
    validateSearchCriteria (searchDTO)
    {
      this.$cove.validation.date.rejectIfInvalid(searchDTO.caseHeader.filedDateStart, 'courtCase.filedDateFrom',
        'filedDate-startDate');
      this.$cove.validation.date.rejectIfInvalid(searchDTO.caseHeader.filedDateEnd, 'courtCase.filedDateTo',
        'filedDate-endDate');
      this.$cove.validation.date.rejectIfBefore(searchDTO.caseHeader.filedDateEnd, searchDTO.caseHeader.filedDateStart,
        'courtCase.filedDateTo', 'courtCase.filedDateFrom', 'filedDate-endDate');
    },
    changeCourt () {
      this.loadCaseCategories();
    },
    loadCaseCategories ()
    {
      if (_.isNil(this.searchDTO.courtID))
      {
        this.caseCategories = [];
      }
      else
      {
        axios.get(this.$cove.getAPI({
          name: 'api.court.case.categories',
          params: { courtID: this.searchDTO.courtID }
        })).then((response) => {
          this.caseCategories = _.defaultTo(response.data, []);
        });
      }
    }
  }
};
</script>

