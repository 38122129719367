<template>
  <v-item-group :value="value" @change="updateStep" mandatory class="d-flex" tag="nav">
    <ol class="d-flex pa-0" style="list-style:none;flex-wrap:wrap">
      <v-item v-for="(step, i) in steps" v-slot="{ active, toggle }" :key="step.stepID" :value="step.stepID">
        <li>
          <v-btn text
                 class="breadcrumb-button"
                 :class="{ 'breadcrumb-button-active': active }"
                 color="primary"
                 @click="toggle"
                 :disabled="isStepDisabled(step)">
            {{ step.name }}
          </v-btn>
          <v-icon v-if="i !== steps.length - 1" class="px-1">
            mdi-chevron-right
          </v-icon>
        </li>
      </v-item>
    </ol>
  </v-item-group>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    steps: {
      type: Array
    },
    value: {
      type: String
    }
  },
  methods: {
    updateStep (value) {
      this.$emit('input', value);
    },
    isStepDisabled (step) {
      let currentIndex = _.findIndex(this.steps, { stepID: this.value });
      return currentIndex < _.findIndex(this.steps, { stepID: step.stepID });
    }
  }
};
</script>
