<template>
  <c-dialog
    :value="value"
    width="850"
    close-key="cove.general.close"
    title-key="page.hearingPopup.title"
    @input="close">

    <div class="pa-5">

      <section-separator :title="$t('page.hearingPopup.information')" class="mb-5"/>

      <div class="mt-2">
        <dl class="d-grid grid-4060">
          <dt>{{ $t('hearing.startDate') }}</dt>
          <dd>{{ hearing.startDate | formatDateTime }}</dd>
          <dt>{{ $t('hearing.endDate') }}</dt>
          <dd>{{ hearing.endDate | formatDateTime }}</dd>
          <dt>{{ $t('hearing.court') }}</dt>
          <dd>{{ hearing.event.courtAbbreviation }}</dd>
          <dt>{{ $t('hearing.location') }}</dt>
          <dd>{{ hearing.event.location }}</dd>
          <dt>{{ $t('hearing.room') }}</dt>
          <dd>{{ hearing.event.room }}</dd>
          <dt>{{ $t('hearing.type') }}</dt>
          <dd>{{ hearing.hearingType }}</dd>
          <dt>{{ $t('hearing.status') }}</dt>
          <dd>{{ hearing.hearingStatus }}</dd>
          <dt v-if="judgeExists">{{ $t('hearing.judge') }}</dt>
          <dd v-if="judgeExists">{{ hearing.event.judgeActorInstances[0].displayName }}</dd>
          <dt v-if="departmentExists">{{ $t('hearing.department') }}</dt>
          <dd v-if="departmentExists">{{ hearing.event.departmentActorInstance.displayName }}</dd>
        </dl>
      </div>

      <section-separator :title="$t('page.hearingPopup.case')" class="mb-5 mt-5"/>

      <div class="mt-2">
        <dl class="d-grid grid-4060">
          <dt v-if="!isSingleCourtMode">{{ $t('courtCase.court') }}</dt>
          <dd v-if="!isSingleCourtMode">{{ hearing.caseHeader.courtAbbreviation }}</dd>
          <dt>{{ $t('courtCase.caseNumber') }}</dt>
          <dd>
            <router-link :to="{ name: 'caseView', params: { courtID: caseCourtID,
                                                            caseUUID: hearing.caseHeader.caseInstanceUUID} }">
              {{ hearing.caseHeader.caseNumber }}
            </router-link>
          </dd>
          <dt>{{ $t('courtCase.caseTitle') }}</dt>
          <dd>{{ hearing.caseHeader.caseTitle }}</dd>
        </dl>
      </div>

      <template v-if="virtualConference">
        <section-separator :title="$t('page.hearingPopup.virtualConference')" class="mb-5 mt-5"/>

        <div class="mt-2">
          <dl class="d-grid grid-4060">
            <dt v-if="virtualConferenceLinkExists">{{ $t('hearing.conferenceLink') }}</dt>
            <dd v-if="virtualConferenceLinkExists">
              <a :href="hearing.event.virtualConferenceLink" target="_blank">{{ $t('page.hearingPopup.conferenceLink') }}</a>
            </dd>
            <dt v-if="virtualConferenceNoteExists">{{ $t('hearing.conferenceInformation') }}</dt>
            <dd v-if="virtualConferenceNoteExists">
              <pre class="primary-font" v-text="hearing.event.virtualConferenceNote"></pre>
            </dd>
            <dt v-if="virtualStreamingLinkExists">{{ $t('hearing.streamingLink') }}</dt>
            <dd v-if="virtualStreamingLinkExists">
              <a :href="hearing.event.virtualStreamingLink" target="_blank">{{ $t('page.hearingPopup.streamingLink') }}</a>
            </dd>
          </dl>
        </div>
      </template>

    </div>

  </c-dialog>
</template>

<script>
import SectionSeparator from '@components/support/SectionSeparator';
import _ from 'lodash';

export default {
  components: { SectionSeparator },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    hearing: {
      type: Object,
      required: true
    }
  },
  computed: {
    isSingleCourtMode () {
      return this.$store.getters['application/isSingleCourtMode'];
    },
    virtualConference () {
      return this.virtualConferenceLinkExists || this.virtualConferenceNoteExists || this.virtualStreamingLinkExists;
    },
    virtualConferenceLinkExists () {
      return !_.isNil(this.hearing.event.virtualConferenceLink);
    },
    virtualConferenceNoteExists () {
      return !_.isNil(this.hearing.event.virtualConferenceNote);
    },
    virtualStreamingLinkExists () {
      return !_.isNil(this.hearing.event.virtualStreamingLink) && !this.virtualConferenceLinkExists;
    },
    judgeExists () {
      return !_.isNil(this.hearing.event.judgeActorInstances) && !_.isNil(this.hearing.event.judgeActorInstances[0].displayName);
    },
    departmentExists () {
      return !_.isNil(this.hearing.event.departmentActorInstance) && !_.isNil(this.hearing.event.departmentActorInstance.displayName);
    },
    caseCourtID () {
      let court = this.$store.getters['application/getCourtByExternalID'](this.hearing.caseHeader.courtID);
      return !_.isNil(court) ? court.resourceID : null;
    }
  },
  methods: {
    close () {
      this.$emit('input', false);
    }
  }
};
</script>