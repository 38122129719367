<template>
  <loading-area :loading="caseInstance === null">
    <template #default>
      <div class="js-scroll-anchor"></div>
      <!-- horizontal (monitor) display -->
      <v-tabs v-model="selectedTabIndex"
              slider-color="accent"
              slider-size="4"
              class="elevation-2 mb-n2 d-none d-md-block d-print-none rounded"
              grow>
        <v-tab v-for="tab in tabs" class="primary--text inset-focus" tag="button" :key="tab">
          {{ getTabTitle(tab) }}
        </v-tab>
      </v-tabs>

      <!-- vertical (mobile) display -->
      <v-tabs v-model="selectedTabIndex"
              slider-color="accent"
              slider-size="4"
              class="elevation-2 mb-6 d-md-none d-print-none rounded"
              vertical>
        <v-tab v-for="tab in tabs" class="primary--text inset-focus justify-start" tag="button" style="max-width:none" :key="tab">
          {{ getTabTitle(tab) }}
        </v-tab>
      </v-tabs>

      <transition name="scroll-y-transition" mode="out-in">
        <component :is="tabTableComponent"
                   class="mt-4"
                   :title="getTabTitle(selectedTab)"
                   :items="items"
                   :headers="headers"
                   :courtID="courtID"
                   :caseInstanceUUID="caseInstance.caseInstanceUUID"
                   :isVerifiedParty="isVerifiedParty"
                   :page="page"
                   :sort="sort"
                   :loading="loadingTab"
                   @toggleDrawer="toggleDrawer"
                   @changePage="changePage"
                   @changeSort="changeSort"/>
      </transition>

      <v-navigation-drawer
        v-model="drawer"
        temporary
        right
        app
        disable-route-watcher
        width="400"
        class="js-filter-sidebar">
        <div class="px-4 py-2">
          <div class="d-flex align-center">
            <div style="flex:0 0 auto">
              <h1 class="text-h6" tabindex="-1" style="line-height:1rem;outline-offset:6px">
                {{ $t('global.drawer.sort.title') }}
              </h1>
            </div>
            <v-spacer/>
            <div style="flex:0 0 auto" class="text-right">
              <v-btn icon color="default" @click="drawer = false;">
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
          </div>
          <p class="mb-0 text-minimum-gray">
            {{ $t('global.drawer.sort.description') }}
          </p>
        </div>
        <v-divider/>
        <div class="px-4 pt-2 pb-6">
          <div class="c-caption mb-5">
            {{ $t('global.sort') }}
          </div>
          <c-autocomplete
            v-model="internalSortBy"
            :label="$t('global.drawer.filterSort.field.sortBy')"
            :clearable="false"
            :items="sortableColumns"
            item-text="text"
            item-value="value"
            outlined
            hide-details/>
          <transition name="fade-transition">
            <v-radio-group
              v-if="showSortDirections"
              v-model="internalSortDesc"
              class="mb-n1"
              hide-details
              :label="$t('global.drawer.filterSort.field.sortDir')">
              <v-radio
                :label="$t('global.ascending')"
                :value="false"/>
              <v-radio
                :label="$t('global.descending')"
                :value="true"/>
            </v-radio-group>
          </transition>
        </div>
        <template #append>
          <v-divider/>
          <div class="d-flex pa-4">
            <div class="pr-1" style="flex:0 0 50%">
              <c-btn block type="primary" @click="update">
                {{ $t('application.button.update') }}
              </c-btn>
            </div>
            <div class="pl-1" style="flex:0 0 50%">
              <c-btn block @click="drawer = false;">
                {{ $t('application.button.cancel') }}
              </c-btn>
            </div>
          </div>
        </template>
      </v-navigation-drawer>
    </template>
  </loading-area>
</template>

<script>
import LoadingArea from '@components/support/LoadingArea';
import CaseView from '@/application/caseView';
import { scrollTo } from '@/application/utility';
import axios from 'axios';
import _ from 'lodash';

export default {
  components: { LoadingArea },
  props: {
    courtID: {
      type: String
    },
    caseInstance: {
      type: Object
    },
    isVerifiedParty: {
      type: Boolean
    }
  },
  data () {
    return {
      selectedTabIndex: 0,
      loadingTab: false,
      scrollToResults: false,
      internalSortBy: null,
      internalSortDesc: null,
      drawer: false,
      items: [],
      page: {
        size: 20,
        number: 0,
        totalElements: 0,
        totalPages: 0
      },
      sort: {
        sortBy: null,
        sortDesc: false
      }
    };
  },
  computed: {
    tabs () {
      return CaseView.getTabsByCaseClassGroupType(this.caseInstance.caseClassGroupTypeID);
    },
    selectedTab () {
      return this.tabs[this.selectedTabIndex];
    },
    tabTableComponent () {
      let componentName = _.upperFirst(this.selectedTab) + 'Tab';
      return () => {
        return import('@components/support/caseView/' + componentName);
      };
    },
    headers () {
      return CaseView.getTabHeaders(this.selectedTab, this.isVerifiedParty);
    },
    sortableColumns () {
      let filters = _.filter(this.headers, { sortable: true });
      filters = filters.concat(CaseView.getAdditionalSorts(this.selectedTab));

      return _.orderBy(filters, 'text');
    },
    showSortDirections () {
      let sort = _.find(this.sortableColumns, { value: this.internalSortBy });
      return !_.isNil(sort) && !sort.customSort;
    }
  },
  watch: {
    caseInstance: {
      immediate: true,
      handler (newValue) {
        if (newValue !== null)
        {
          this.loadTabData(true);
        }
      }
    },
    selectedTabIndex (newValue) {
      this.items = [];
      this.page.number = 0;
      this.page.totalElements = 0;
      this.page.totalPages = 0;
      this.loadTabData(true);
    },
    drawer (newValue) {
      if (newValue)
      {
        this.internalSortBy = this.sort.sortBy;
        this.internalSortDesc = this.sort.sortDesc;

        setTimeout(() => {
          document.querySelector('.js-filter-sidebar h1').focus();
        }, 150);
      }
    }
  },
  methods: {
    getTabTitle (tab) {
      return this.$t(`page.caseView.tab.${tab}`);
    },
    update () {
      this.sort = { sortBy: this.internalSortBy, sortDesc: this.internalSortDesc };
      this.page.number = 0;

      this.loadTabData();

      this.drawer = false;
    },
    loadTabData (useDefaultSort) {
      let config = CaseView.getTabAPIConfig(this.selectedTab);

      if (useDefaultSort)
      {
        this.sort.sortBy = config.sortBy;
        this.sort.sortDesc = config.sortDesc;
      }

      let query = {
        page: this.page.number,
        size: this.page.size
      };

      let sort = this.generateSortParam();
      if (!_.isNil(sort))
      {
        query.sort = sort;
      }

      let url = this.$cove.getAPI({
        name: config.apiKey,
        params: {
          courtID: this.courtID,
          caseUUID: this.caseInstance.caseInstanceUUID
        },
        query
      });

      this.loadingTab = true;
      axios.get(url)
        .then((response) => {
          this.items = (_.get(response, 'data._embedded.results', [])).map((element) => {
            return { ...element, searchResultKey: _.uniqueId() };
          });

          this.page.number = response.data.page.number;
          this.page.totalPages = response.data.page.totalPages;
          this.page.totalElements = response.data.page.totalElements;

          if (this.scrollToResults)
          {
            scrollTo('.js-scroll-anchor');
            this.scrollToResults = false;
          }
        })
        .finally(() => {
          this.loadingTab = false;
        });
    },
    generateSortParam () {
      let header = _.find(this.sortableColumns, { value: this.sort.sortBy });
      if (header.customSort)
      {
        return header.sortExpression;
      }
      else
      {
        return `${this.sort.sortBy},${this.sort.sortDesc ? 'desc' : 'asc'}`;
      }
    },
    toggleDrawer () {
      this.drawer = true;
    },
    changePage (newPage) {
      this.page.number = newPage;
      this.scrollToResults = true;
      this.loadTabData();
    },
    changeSort (sorting) {
      this.sort = sorting;
      this.page.number = 0;
      this.scrollToResults = true;
      this.loadTabData();
    }
  }
};
</script>
