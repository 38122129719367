<!-- eslint-disable max-len -->
<template>
  <loading-card :loading="loading" :height="475">
    <v-card-title>
      {{ $t('page.manageFiling.summary.title') }}
    </v-card-title>
    <v-card-subtitle v-if="!isLocked" class="pb-0">
      {{ $t('page.manageFiling.summary.description') }}
    </v-card-subtitle>
    <v-card-text>

      <!-- filing info -->
      <summary-tab-section :title="$t('page.manageFiling.summary.section.filing')" icon="mdi-folder-open">
        <dl class="d-grid grid-5050">
          <dt>{{ $t('courtCase.court') }}</dt>
          <dd>{{ filing.court.displayName }}</dd>
          <template v-if="hasCaseLink">
            <dt>{{ $t('courtCase.caseNumber') }}</dt>
            <dd>{{ filing.courtCase.caseNumber }}</dd>
            <div :style="isNewCaseFiling ? 'display:none' : '' ">
              <dt>{{ $t('courtCase.caseTitle') }}</dt>
            </div>
            <div :style="isNewCaseFiling ? 'display:none' : '' ">
              <dd>{{ caseTitle }}</dd>
            </div>
          </template>
          <dt>{{ $t('courtCase.caseClassification') }}</dt>
          <dd>{{ filing.courtCase.caseClassificationName }}</dd>
          <dt>{{ $t('filing.filedOnBehalfOf') }}</dt>
          <dd>
            <div v-for="f in filedOnBehalfOf" :key="f.resourceID">
              {{ getFiledByName(f) }}
            </div>
          </dd>
          <dt>{{ $t('filing.referenceNumber') }}</dt>
          <dd>{{ filing.referenceNumber }}</dd>
        </dl>
      </summary-tab-section>

      <!-- parties -->
      <summary-tab-section v-if="showParties"
                           :title="isNewCaseFiling ? $t('page.manageFiling.summary.section.parties') : $t('page.manageFiling.summary.section.newParties')" icon="mdi-account-multiple">
        <div v-for="party in parties" class="d-flex align-center mb-3" :key="party.resourceID">
          <div class="pr-3 text-right" style="flex:0 0 44%;min-width:0">
            <div class="text-truncate font-weight-medium font-95">
              {{ party.actor.displayName }}
            </div>
            <div class="text-truncate label" style="font-size:11px">
              {{ party.partySubTypeExternalName }}
            </div>
          </div>
          <template v-if="generateRepresentation(party) !== null">
            <v-divider/>
            <div class="pl-3" style="flex:0 0 44%;min-width:0">
              <div class="text-truncate font-weight-medium font-95">
                {{ generateRepresentation(party) }}
              </div>
              <div class="text-truncate label text-left" style="font-size:11px">
                {{ $t('page.caseView.partiesCard.representation') }}
              </div>
            </div>
          </template>
        </div>
        <div v-if="partyOverflowCount > 0" class="d-flex mb-3">
          <div class="pr-3 text-right" style="flex:0 0 44%;min-width:0">
            <div class="text-truncate text-minimum-gray font-italic font-weight-medium font-95">
              {{ $t('page.manageFiling.summary.moreParties', [partyOverflowCount]) }}
            </div>
          </div>
        </div>
      </summary-tab-section>

      <!-- docket entries -->
      <summary-tab-section :title="$t('page.manageFiling.summary.section.docketEntries')" icon="mdi-file-document-multiple">
        <div v-for="de in docketEntries" class="d-flex align-center pb-3" :key="de.resourceID" style="min-width:0">
          <div class="pr-3 text-right" style="flex:0 0 44%;min-width:0">
            <div class="text-truncate font-weight-medium font-95">
              {{ de.docketEntryExternalName }}
            </div>
            <div v-if="de.lead" class="text-truncate label" style="font-size:11px;margin-bottom:-3px">
              {{ $t('filing.leadDocketEntry') }}
            </div>
            <div v-if="de.excludeFromService" class="text-truncate label" style="font-size:11px">
              {{ $t('docketEntry.excludeFromService') }}
            </div>
          </div>
          <v-divider/>
          <div class="pl-3" style="flex:0 0 44%;min-width:0;">
            <div v-for="d in de.documents" class="font-weight-medium font-95" :key="d.resourceID">
              <a :href="viewDocumentURL(d.documentLinkID)" target="_blank">
                {{ d.documentName }}
              </a>
            </div>
            <div class="label text-left" style="font-size:11px">
              {{ $t('global.documents') }}
            </div>
          </div>
        </div>
      </summary-tab-section>

      <!-- services -->
      <summary-tab-section v-if="showService" :title="$t('page.manageFiling.summary.section.service')" icon="mdi-email-send-outline">
        <div v-for="fs in filingServices" :key="fs.resourceID" class="d-flex align-center pb-3" style="min-width:0">
          <div class="pr-3 text-right" style="flex:0 0 44%;min-width:0">
            <div class="text-truncate font-weight-medium font-95">
              {{ getServiceRecipientName(fs) }}
            </div>
            <div class="text-truncate label" style="font-size:11px">
              {{ fs.filingServiceMethod.displayName }}
            </div>
          </div>
          <template v-if="showServiceDate(fs)">
            <v-divider/>
            <div class="pl-3" style="flex:0 0 44%;min-width:0;">
              <div class="font-weight-medium font-95">
                <span v-if="isEService(fs)">
                  {{ !isNil(fs.readDate) ? $t('page.manageFiling.summary.readOn', [$cove.formatDate(fs.readDate)]) : $t('page.manageFiling.summary.unread') }}
                </span>
                <span v-else>
                  {{ fs.serviceDate | formatDate }}
                </span>
              </div>
              <div class="label text-left" style="font-size:11px">
                {{ $t('page.manageFiling.summary.serviceDate') }}
              </div>
            </div>
          </template>
        </div>
      </summary-tab-section>
    </v-card-text>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import LoadingCard from '@components/support/LoadingCard';
import SummaryTabSection from '@components/support/manageFiling/SummaryTabSection';
import Filing from '@/application/filing';

export default {
  components: { LoadingCard, SummaryTabSection },
  data () {
    return {
      loading: true,
      parties: [],
      partyOverflowCount: 0,
      docketEntries: [],
      filingServices: []
    };
  },
  computed: {
    filing () {
      return this.$store.state.filing.filing;
    },
    isNewCaseFiling () {
      return this.$store.getters['filing/isNewCaseFiling'];
    },
    filedOnBehalfOf () {
      return this.$store.getters['filing/getFiledOnBehalfOf'];
    },
    isLocked () {
      return this.$store.getters['filing/isLocked'];
    },
    showParties () {
      return !_.isEmpty(this.parties);
    },
    hasCaseLink () {
      return !_.isNil(this.filing.courtCase.caseIdentifier);
    },
    isFilerCaseAccessible () {
      return this.$store.getters['filing/isFilerCaseAccessible'];
    },
    caseTitle () {
      return this.isFilerCaseAccessible ? this.filing.courtCase.caseTitle : this.$t('courtCase.secure');
    },
    showService () {
      return !_.isNil(_.find(this.docketEntries, { excludeFromService: false })) &&
        !_.isEmpty(this.filingServices);
    }
  },
  created () {
    Promise.all([this.loadParties(), this.loadDocketEntries(), this.loadServices()])
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    loadParties () {
      let displayPerType = 5;
      let involvementTypeIDs = [this.CONST.INVOLVMENT_TYPE_FILING_PARTY, this.CONST.INVOLVMENT_TYPE_RESPONDING_PARTY,
        this.CONST.INVOLVMENT_TYPE_INVOLVED_PARTY];

      let promises = involvementTypeIDs.map((involvmentTypeID) => {
        let url = this.$cove.getAPI({
          name: 'api.filing.caseParties',
          query: {
            filingID: this.filing.resourceID,
            size: displayPerType,
            fields: '**,actor(*),legalRepresenterMaps(*,existingLegalRepresenter(*),legalRepresenter(*,actor(*)))',
            involvementTypeExternalIdentifier: involvmentTypeID }
        });

        return axios.get(url);
      });

      return Promise.all(promises)
        .then((responses) => {
          responses.forEach((response) => {
            this.parties = this.parties.concat(_.get(response, 'data._embedded.results', []));
            this.partyOverflowCount += Math.max(response.data.page.totalElements - displayPerType, 0);
          });
        });
    },
    loadDocketEntries () {
      let url = this.$cove.getAPI({
        name: 'api.filing.docketEntries',
        query: {
          filingID: this.filing.resourceID,
          fields: '*,documents(*)',
          size: this.CONST.PAGING_MAX_SIZE,
          sort: ['lead,desc']
        }
      });

      return axios.get(url).
        then((response) => {
          this.docketEntries = _.get(response, 'data._embedded.results', []);
        });
    },
    loadServices () {
      let url = this.$cove.getAPI({
        name: 'api.filing.services',
        query: {
          filingID: this.filing.resourceID,
          // eslint-disable-next-line max-len
          fields: '*,filingServiceMethod(*),caseParty(*,actor(*)),legalRepresenter(*,actor(*)),existingCaseParty(*),existingLegalRepresenter(*)',
          size: this.CONST.PAGING_MAX_SIZE
        }
      });

      return axios.get(url).
        then((response) => {
          this.filingServices = _.get(response, 'data._embedded.results', []);
        });
    },
    viewDocumentURL (documentLinkID) {
      return this.$cove.getAPI({
        name: 'api.dms.document',
        params: { documentLinkID }
      });
    },
    getFiledByName (filedOnBehalfOf) {
      return Filing.getFiledOnBehalfOfName(filedOnBehalfOf);
    },
    generateRepresentation (party) {
      let result = null;
      let legalRepMaps = party.legalRepresenterMaps;
      if (!_.isEmpty(legalRepMaps))
      {
        let proSe = _.find(legalRepMaps, { proSe: true });
        if (proSe)
        {
          result = this.$t('global.selfRep');
        }
        else
        {
          let lrm = legalRepMaps[0];
          let possibleNames = [
            _.get(lrm, 'legalRepresenter.actor.sortName', null),
            _.get(lrm, 'existingLegalRepresenter.sortName', null)
          ];

          result = _.compact(possibleNames)[0];
        }
      }

      return result;
    },
    getServiceRecipientName (filingService) {
      let possibleNames = [
        _.get(filingService, 'caseParty.actor.displayName', null),
        _.get(filingService, 'legalRepresenter.actor.displayName', null),
        _.get(filingService, 'existingCaseParty.displayName', null),
        _.get(filingService, 'existingLegalRepresenter.displayName', null)
      ];

      return _.compact(possibleNames)[0];
    },
    isEService (filingService) {
      return filingService.filingServiceMethod.resourceID === this.CONST.FILING_SERVICE_METHOD_ESERVICE;
    },
    showServiceDate (filingService) {
      let fsm = filingService.filingServiceMethod;
      return fsm.resourceID === this.CONST.FILING_SERVICE_METHOD_ESERVICE || fsm.serviceDateRequired === true;
    }
  }
};
</script>