<template>
  <page-wrapper :title="$t('page.myHearings.title')">
    <search-result-template :config="config">

      <template #table-title>
        {{ $t('page.myHearings.table') }}
      </template>

      <template #v-data-table.item.startDate="{ item }">
        <button class="link-button" @click="openPopup(item)">
          {{ item.startDate | formatDateTime }}
        </button>
      </template>

      <!--  Result Values  -->
      <template #v-data-table.item.caseHeader.caseNumber="{ item }">
        <div>
          <div style="white-space: nowrap">
            <router-link :to="{ name: 'caseView', params: { courtID: getCourtByExternalID(item.caseHeader.courtID).resourceID,
                                                            caseUUID: item.caseHeader.caseInstanceUUID} }">
              {{ item.caseHeader.caseNumber }}
            </router-link>
          </div>
          <div>
            {{ item.caseHeader.caseTitle }}
          </div>
        </div>
      </template>

      <template #tableFooter>
        <div class="pr-2 pb-2 text-right">
          <c-btn type="primary" class="pa-4" small @click="showSubscriptionPopup = true">
            {{ $t('global.subscribe') }}
          </c-btn>
        </div>
      </template>

      <!-- Search Criteria -->
      <template #nav-drawer-filter-body>
        <v-row no-gutters class="mt-4">
          <v-col v-if="!isSingleCourtMode" cols="12">
            <c-autocomplete
              :items="courts"
              v-model="searchDTO.courtID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('courtCase.court')"
              outlined
              key="courtID"
              hide-details="auto"
              @change="changeCourt"
              aria-describedby="51755373-8449-442f-a765-48e5185d088d"/>
            <field-description id="51755373-8449-442f-a765-48e5185d088d"
                               message-key="page.myHearings.court.description"/>
          </v-col>
          <v-col cols="12" v-if="!isSingleCalendarLocationMode">
            <c-autocomplete
              :items="calendarLocations"
              v-model="searchDTO.locationID"
              item-text="locationName"
              item-value="locationID"
              :label="$t('court.location')"
              :no-data-text="$t('global.courtFirst')"
              outlined
              key="locationID"
              hide-details="auto"
              aria-describedby="bef355d3-de76-4b2c-9b4e-2b32a3867cc3"/>
            <field-description id="bef355d3-de76-4b2c-9b4e-2b32a3867cc3"
                               message-key="page.myHearings.location.description"/>
          </v-col>
          <v-col cols="12">
            <date-range-picker
              :label="$t('hearing.date')"
              :labelStart="$t('hearing.dateFrom')"
              :labelEnd="$t('hearing.dateTo')"
              keyPrefix="hearingDate"
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.hearingDateChoice"
              :startDate.sync="searchDTO.hearingDateStart"
              :endDate.sync="searchDTO.hearingDateEnd"
              :offerFutureDates="true"
              :offerPastDates="false"
              required
              aria-describedby="a188c3dd0-cad8-48c6-aa77-2d95de2ac6eb"/>
            <field-description id="a188c3dd0-cad8-48c6-aa77-2d95de2ac6eb"
                               message-key="page.myHearings.hearingDate.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.caseHeader.caseNumber"
              :label="$t('courtCase.caseNumber')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="64a804ad-0063-4975-bcd3-b86406137275"/>
            <field-description id="64a804ad-0063-4975-bcd3-b86406137275"
                               message-key="page.myHearings.caseNumber.description"/>
          </v-col>
        </v-row>
      </template>

    </search-result-template>

    <my-hearing-popup v-model="showPopup" :hearing="popupHearing"/>

    <calendar-subscription-popup v-model="showSubscriptionPopup" :link="calendarLink"/>

  </page-wrapper>
</template>

<script>

import CalendarSubscriptionPopup from '@components/support/CalendarSubscriptionPopup';
import SearchResultTemplateMixin from '@/mixins/searchResultTemplate';
import SearchResultTemplate from '@components/support/template/SearchResultTemplate';
import MyHearingPopup from '@components/support/work/MyHearingPopup';
import CONST from '@/application/constants';
import cove from '@ctrack/cove';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { dateToStartOfDay, dateToEndOfDay } from '@/application/application';
import axios from 'axios';

export default {
  components: { CalendarSubscriptionPopup, SearchResultTemplate, MyHearingPopup },
  mixins: [ SearchResultTemplateMixin ],
  data () {
    return {
      searchDTO: {
        sort: {
          sortBy: 'startDate',
          sortDesc: false
        },
        hearingTypeID: null,
        hearingStatusID: null,
        courtID: null,
        locationID: null,
        roomID: null,
        hearingDateChoice: '6m',
        hearingDateStart: this.$cove.formatDate(DateTime.local()),
        hearingDateEnd: this.$cove.formatDate(DateTime.local().plus({ 'month': 6 })),
        caseHeader: {
          caseNumber: null,
          caseNumberSearchType: CONST.QUERY_TYPE_CONTAINS
        }
      },
      showPopup: false,
      popupHearing: {
        event: {},
        caseHeader: {}
      },
      showSubscriptionPopup: false,
      calendarLink: null
    };
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.user.currentuser' }))
      .then((response) => {
        let user = response.data;
        this.calendarLink = this.$cove.getAPI({
          name: 'api.search.user.hearings.calendar',
          params: { userID: user.resourceID, token: user.calendarToken }
        });
      });
  },
  computed: {
    calendarLocations () {
      return this.$store.getters['application/getCalendarLocationsByCourt'](this.searchDTO.courtID);
    },
    isSingleCalendarLocationMode () {
      return this.$store.getters['application/isSingleCalendarLocationMode'];
    }
  },
  methods: {
    getRouteName () {
      return 'work-hearings';
    },
    getSearchAPIName () {
      return 'api.search.user.hearings';
    },
    getItemKey () {
      return 'hearingUUID';
    },
    getHeaders () {
      let headers = [];

      headers.push({ text: this.$t('hearing.date'), value: 'startDate', sortable: true });
      if (!this.isSingleCourtMode)
      {
        headers.push({ text: this.$t('hearing.court'), value: 'event.courtAbbreviation', sortable: true });
      }
      headers.push({ text: this.$t('hearing.location'), value: 'event.location', sortable: true });
      headers.push({ text: this.$t('hearing.room'), value: 'event.room', sortable: true });
      headers.push({ text: this.$t('courtCase.case'), value: 'caseHeader.caseNumber', sortable: true });
      headers.push({ text: this.$t('hearing.type'), value: 'hearingType', sortable: true });
      headers.push({ text: this.$t('hearing.status'), value: 'hearingStatus', sortable: true });

      return headers;
    },
    changeCourt () {
      this.locationID = null;
    },
    addSearchCriteria (query) {

      if (!_.isNil(this.searchDTO.sort.sortBy))
      {
        query.sort = `${this.searchDTO.sort.sortBy},${this.searchDTO.sort.sortDesc ? 'desc' : 'asc'}`;
      }

      if (!_.isNil(this.searchDTO.caseHeader.caseNumber))
      {
        query['caseHeader.caseNumber'] = this.searchDTO.caseHeader.caseNumber;

        // Only send the searchType filter if there is text in the caseNumber
        if (!_.isNil(this.searchDTO.caseHeader.caseNumberSearchType))
        {
          query['caseHeader.caseNumberSearchType'] = this.searchDTO.caseHeader.caseNumberSearchType;
        }
      }

      if (!_.isNil(this.searchDTO.hearingTypeID))
      {
        query.hearingTypeID = this.searchDTO.hearingTypeID;
      }

      if (!_.isNil(this.searchDTO.hearingStatusID))
      {
        query.hearingStatusID = this.searchDTO.hearingStatusID;
      }

      if (!_.isNil(this.searchDTO.courtID))
      {
        query.courtID = this.searchDTO.courtID;
      }

      if (!_.isNil(this.searchDTO.locationID))
      {
        query.locationID = this.searchDTO.locationID;
      }

      if (!_.isNil(this.searchDTO.roomID))
      {
        query.roomID = this.searchDTO.roomID;
      }

      if (!_.isNil(this.searchDTO.hearingDateStart))
      {
        query.startDateFrom = dateToStartOfDay(cove.parseDate(this.searchDTO.hearingDateStart)).toISO();
      }

      if (!_.isNil(this.searchDTO.hearingDateEnd))
      {
        query.startDateTo = dateToEndOfDay(cove.parseDate(this.searchDTO.hearingDateEnd)).toISO();
      }

      return query;
    },
    validateSearchCriteria (searchDTO)
    {
      if (_.isNil(searchDTO.hearingDateChoice))
      {
        cove.validation.required(searchDTO.hearingDateChoice, 'hearing.date', 'hearingDate-choice');
      }
      else if (searchDTO.hearingDateChoice === CONST.DATE_RANGE_OPTION_CUSTOM)
      {
        cove.validation.date.rejectIfInvalid(searchDTO.hearingDateStart, 'hearing.dateFrom',
          'hearingDate-startDate');
        cove.validation.date.rejectIfInvalid(searchDTO.hearingDateEnd, 'hearing.dateTo',
          'hearingDate-endDate');
        cove.validation.date.rejectIfBefore(searchDTO.hearingDateEnd, searchDTO.hearingDateStart,
          'hearing.dateTo', 'hearing.dateFrom', 'hearingDate-endDate');

        let currentDate = this.$cove.formatDate(dateToStartOfDay(DateTime.local()));
        cove.validation.date.rejectIfBefore(searchDTO.hearingDateStart, currentDate,
          'hearing.dateFrom', 'page.myHearings.today', 'hearingDate-startDate');

        cove.validation.required(searchDTO.hearingDateStart, 'hearing.dateFrom', 'hearingDate-startDate');
        cove.validation.required(searchDTO.hearingDateEnd, 'hearing.dateTo', 'hearingDate-endDate');
      }
    },
    openPopup (hearing) {
      this.popupHearing = hearing;
      this.showPopup = true;
    }
  }
};
</script>

