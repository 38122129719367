<template>
  <page-wrapper :title="$t('page.admin.userAccount.title')" :subtitle="subtitle">
    <nav-tabs :items="navTabs"/>
  </page-wrapper>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import PageMixin from '@/mixins/page';
import PageWrapper from '@components/support/PageWrapper';
import NavTabs from '@components/support/NavTabs';

export default {
  components: { PageWrapper, NavTabs },
  mixins: [ PageMixin ],
  props: {
    userID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      user: null,
      navTabs: [
        { name: this.$t('page.admin.userAccount.nav.loginAccount'), routeName: 'admin-user-login-account' },
        { name: this.$t('page.admin.userAccount.nav.preferences'), routeName: 'admin-user-preferences' },
        { name: this.$t('page.admin.userAccount.nav.address'), routeName: 'admin-user-address' },
        { name: this.$t('page.admin.userAccount.nav.contact'), routeName: 'admin-user-contact' },
        { name: this.$t('page.admin.userAccount.nav.roles'), routeName: 'admin-user-roles' },
        { name: this.$t('page.admin.userAccount.nav.documentAccess'), routeName: 'admin-user-document-access' },
        { name: this.$t('page.admin.userAccount.nav.assocOrg'), routeName: 'admin-user-org-details' },
        { name: this.$t('page.admin.userAccount.nav.paymentMethod'), routeName: 'admin-user-payment-method' },
        { name: this.$t('page.admin.userAccount.nav.subscription'), routeName: 'admin-user-subscription' }
      ]
    };
  },
  computed: {
    subtitle () {
      return _.get(this.user, 'actor.displayName', null);
    }
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.user', params: { userID: this.userID }, query: { fields: '*,actor(*)' } }))
      .then((response) => {
        this.user = response.data;
      });
  }
};
</script>
