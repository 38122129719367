<template>
  <div class="nav-tab-small">
    <validation-error-panel/>
    <loading-card :loading="loading">
      <v-card-title>
        {{ $t('page.currentUser.interpreter.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.currentUser.interpreter.description') }}
      </v-card-subtitle>
      <v-card-text class="mt-4 pb-2">
        <v-row no-gutters>
          <interpreter-form :interpreter="interpreter" ref="interpreterForm"/>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <c-btn type="primary" class="px-4" @click="save">
          {{ $t('application.button.save') }}
        </c-btn>
      </v-card-actions>
    </loading-card>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import InterpreterForm from '@components/support/InterpreterForm';
import LoadingCard from '@components/support/LoadingCard';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';

export default {
  components: { LoadingCard, InterpreterForm, ValidationErrorPanel },
  data () {
    return {
      loading: true,
      interpreter: {
        employer: null,
        selectedLocations: [],
        selectedLanguages: []
      }
    };
  },
  computed: {
    user () {
      return this.$store.state.user;
    }
  },
  created () {
    this.interpreter.employer = _.defaultTo(this.user.interpreterInfo.employer, null);

    let langPromise = axios.get(this.$cove.getAPI({ name: 'api.interpreter.languages' }));
    let classificationPromise = axios.get(this.$cove.getAPI({ name: 'api.interpreter.classifications' }));

    Promise.all([langPromise, classificationPromise])
      .then(([langResponse, classResponse]) => {
        let languages = langResponse.data;
        let classifications = classResponse.data;

        this.interpreter.selectedLanguages = _.cloneDeep(this.user.interpreterInfo.languages);
        this.interpreter.selectedLanguages.forEach((sl) => {
          this.$set(sl, 'id', _.uniqueId());
          this.$set(sl, 'language', _.find(languages, { languageID: String(sl.languageIdentifier) }).languageName);
          this.$set(sl, 'classification', _.find(classifications, {
            interpreterClassificationID: String(sl.classificationIdentifier) }).interpreterClassificationValue);
        });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    validate () {
      this.$store.commit('cove/errors/clear');
      this.$refs.interpreterForm.validate();
      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      this.$cove.block({ delay: 0 });

      let isValid = this.validate();
      if (!isValid)
      {
        this.$cove.unblock();
        this.scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
        return;
      }

      let payload = {
        employer: this.interpreter.employer,
        languages: this.interpreter.selectedLanguages
      };

      axios.patch(this.$cove.getAPI({ name: 'api.user.currentuser.interpreter' }), payload)
        .then(() => {
          return this.$store.dispatch('user/reload')
            .then(() => {
              this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
            });
        }).finally(() => {
          this.$cove.unblock();
        });
    }
  }
};
</script>