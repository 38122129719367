<template>
  <div>
    <transition name="scroll-y-transition" mode="out-in">
      <form :key="searchDTO.advanced">
        <v-row no-gutters>
          <v-col cols="12">
            <section-separator :title="$t('searchType.judgment.criteriaSeparator')" class="my-4"/>
          </v-col>
          <template v-if="searchDTO.advanced">
            <v-col cols="12">
              <c-autocomplete
                v-model="searchDTO.judgment.forActorCategoryID"
                :items="actorCategories"
                :label="$t('page.searchCriteria.judgment.field.forPartyType')"
                item-value="actorCategoryID"
                :clearable="false"
                outlined
                hide-details="auto"
                aria-describedby="a68b3248-aa5c-4698-b262-77c5719c604c"/>
              <field-description id="a68b3248-aa5c-4698-b262-77c5719c604c"
                                 message-key="page.searchCriteria.judgment.field.forPartyType.description"/>
            </v-col>
            <template v-if="searchDTO.judgment.forActorCategoryID === CONST.ACTOR_CATEGORY_PERSON">
              <v-col cols="12">
                <c-text-field
                  v-model="searchDTO.judgment.forPartyFirstName"
                  :label="$t('page.searchCriteria.judgment.field.forPartyFirstName')"
                  outlined
                  maxlength="250"
                  hide-details="auto"
                  aria-describedby="a3ec9b17-5336-4b06-a2ae-0f511d7e2b3a"/>
                <field-description id="a3ec9b17-5336-4b06-a2ae-0f511d7e2b3a"
                                   message-key="page.searchCriteria.judgment.field.forPartyFirstName.description"/>
              </v-col>
              <v-col cols="12">
                <c-autocomplete
                  v-model="searchDTO.judgment.forPartyFirstNameQueryTypeID"
                  :label="$t('page.searchCriteria.judgment.field.forPartyFirstNameSearchType')"
                  :items="queryTypes"
                  outlined
                  class="ml-8"
                  :clearable="false"
                  hide-details="auto"
                  aria-describedby="a87f3ccc-aec7-4914-a915-6a1a40a17294"/>
                <field-description id="a87f3ccc-aec7-4914-a915-6a1a40a17294" class="ml-8"
                                   message-key="global.queryType.description"/>
              </v-col>
              <v-col cols="12">
                <c-text-field
                  v-model="searchDTO.judgment.forPartyLastName"
                  :label="$t('page.searchCriteria.judgment.field.forPartyLastName')"
                  outlined
                  maxlength="250"
                  hide-details="auto"
                  aria-describedby="a47edeb3-9ad9-4fd7-a8d5-e02cb4bdef5c"/>
                <field-description id="a47edeb3-9ad9-4fd7-a8d5-e02cb4bdef5c"
                                   message-key="page.searchCriteria.judgment.field.forPartyLastName.description"/>
              </v-col>
              <v-col cols="12">
                <c-autocomplete
                  v-model="searchDTO.judgment.forPartyLastNameQueryTypeID"
                  :label="$t('page.searchCriteria.judgment.field.forPartyLastNameSearchType')"
                  :items="queryTypes"
                  outlined
                  class="ml-8"
                  :clearable="false"
                  hide-details="auto"
                  aria-describedby="a3050e05-d018-45a9-b4e0-5f44bda74c94"/>
                <field-description id="a3050e05-d018-45a9-b4e0-5f44bda74c94" class="ml-8"
                                   message-key="global.queryType.description"/>
              </v-col>
            </template>
            <template v-else-if="searchDTO.judgment.forActorCategoryID === CONST.ACTOR_CATEGORY_ORGANIZATION">
              <v-col cols="12">
                <c-text-field
                  v-model="searchDTO.judgment.forPartyName"
                  :label="$t('page.searchCriteria.judgment.field.forPartyOrgName')"
                  outlined
                  maxlength="250"
                  hide-details="auto"
                  aria-describedby="affe17fb-3fcf-4fba-ab4d-91094a931a4f"/>
                <field-description id="affe17fb-3fcf-4fba-ab4d-91094a931a4f"
                                   message-key="page.searchCriteria.judgment.field.forPartyOrgName.description"/>
              </v-col>
              <v-col cols="12">
                <c-autocomplete
                  v-model="searchDTO.judgment.forPartyNameQueryTypeID"
                  :label="$t('page.searchCriteria.judgment.field.forPartyOrgNameSearchType')"
                  :items="queryTypes"
                  outlined
                  class="ml-8"
                  :clearable="false"
                  hide-details="auto"
                  aria-describedby="a498495e-76fa-4a1d-92e0-0b43e3f31541"/>
                <field-description id="a498495e-76fa-4a1d-92e0-0b43e3f31541" class="ml-8"
                                   message-key="global.queryType.description"/>
              </v-col>
            </template>

            <v-col cols="12">
              <c-autocomplete
                v-model="searchDTO.judgment.againstActorCategoryID"
                :items="actorCategories"
                :label="$t('page.searchCriteria.judgment.field.againstPartyType')"
                item-value="actorCategoryID"
                :clearable="false"
                outlined
                hide-details="auto"
                aria-describedby="a2f553fa-9797-4066-8903-9dc46a88a278"/>
              <field-description id="a2f553fa-9797-4066-8903-9dc46a88a278"
                                 message-key="page.searchCriteria.judgment.field.againstPartyType.description"/>
            </v-col>
            <template v-if="searchDTO.judgment.againstActorCategoryID === CONST.ACTOR_CATEGORY_PERSON">
              <v-col cols="12">
                <c-text-field
                  v-model="searchDTO.judgment.againstPartyFirstName"
                  :label="$t('page.searchCriteria.judgment.field.againstPartyFirstName')"
                  outlined
                  maxlength="250"
                  hide-details="auto"
                  aria-describedby="a4313d81-57bd-4c43-a11e-743b4b60694a"/>
                <field-description id="a4313d81-57bd-4c43-a11e-743b4b60694a"
                                   message-key="page.searchCriteria.judgment.field.againstPartyFirstName.description"/>
              </v-col>
              <v-col cols="12">
                <c-autocomplete
                  v-model="searchDTO.judgment.againstPartyFirstNameQueryTypeID"
                  :label="$t('page.searchCriteria.judgment.field.againstPartyFirstNameSearchType')"
                  :items="queryTypes"
                  outlined
                  class="ml-8"
                  :clearable="false"
                  hide-details="auto"
                  aria-describedby="ab32ac3f-58ca-441c-a84a-70a6940454e8"/>
                <field-description id="ab32ac3f-58ca-441c-a84a-70a6940454e8" class="ml-8"
                                   message-key="global.queryType.description"/>
              </v-col>
              <v-col cols="12">
                <c-text-field
                  v-model="searchDTO.judgment.againstPartyLastName"
                  :label="$t('page.searchCriteria.judgment.field.againstPartyLastName')"
                  outlined
                  maxlength="250"
                  hide-details="auto"
                  aria-describedby="a34f2ab0-a7df-4c99-93af-3b4f62af8a6a"/>
                <field-description id="a34f2ab0-a7df-4c99-93af-3b4f62af8a6a"
                                   message-key="page.searchCriteria.judgment.field.againstPartyLastName.description"/>
              </v-col>
              <v-col cols="12">
                <c-autocomplete
                  v-model="searchDTO.judgment.againstPartyLastNameQueryTypeID"
                  :label="$t('page.searchCriteria.judgment.field.againstPartyLastNameSearchType')"
                  :items="queryTypes"
                  outlined
                  class="ml-8"
                  :clearable="false"
                  hide-details="auto"
                  aria-describedby="a97bbdc5-e9e4-4529-8a4a-52fbfd62b8a7"/>
                <field-description id="a97bbdc5-e9e4-4529-8a4a-52fbfd62b8a7" class="ml-8"
                                   message-key="global.queryType.description"/>
              </v-col>
            </template>
            <template v-else-if="searchDTO.judgment.againstActorCategoryID === CONST.ACTOR_CATEGORY_ORGANIZATION">
              <v-col cols="12">
                <c-text-field
                  v-model="searchDTO.judgment.againstPartyName"
                  :label="$t('page.searchCriteria.judgment.field.againstPartyOrgName')"
                  outlined
                  maxlength="250"
                  hide-details="auto"
                  aria-describedby="aaf8d1f9-c0b0-48f8-bc29-09290ecbe91a"/>
                <field-description id="aaf8d1f9-c0b0-48f8-bc29-09290ecbe91a"
                                   message-key="page.searchCriteria.judgment.field.againstPartyOrgName.description"/>
              </v-col>
              <v-col cols="12">
                <c-autocomplete
                  v-model="searchDTO.judgment.againstPartyNameQueryTypeID"
                  :label="$t('page.searchCriteria.judgment.field.againstPartyOrgNameSearchType')"
                  :items="queryTypes"
                  outlined
                  class="ml-8"
                  :clearable="false"
                  hide-details="auto"
                  aria-describedby="a15a4ee0-2e89-41e5-bda3-e24ca7aebbab"/>
                <field-description id="a15a4ee0-2e89-41e5-bda3-e24ca7aebbab" class="ml-8"
                                   message-key="global.queryType.description"/>
              </v-col>
            </template>
          </template>
          <template v-else>
            <v-col cols="12">
              <c-text-field
                v-model="searchDTO.judgment.forPartyName"
                :label="$t('page.searchCriteria.judgment.field.forPartyName')"
                outlined
                maxlength="250"
                hide-details="auto"
                aria-describedby="addb692f-18e7-4383-a26b-f887c2d96deb"/>
              <field-description id="addb692f-18e7-4383-a26b-f887c2d96deb"
                                 message-key="page.searchCriteria.judgment.field.forPartyName.description"/>
            </v-col>
            <v-col cols="12">
              <c-text-field
                v-model="searchDTO.judgment.againstPartyName"
                :label="$t('page.searchCriteria.judgment.field.againstPartyName')"
                outlined
                maxlength="250"
                hide-details="auto"
                aria-describedby="a1f531ba-3290-4207-8f51-3c0527904bc6"/>
              <field-description id="a1f531ba-3290-4207-8f51-3c0527904bc6"
                                 message-key="page.searchCriteria.judgment.field.againstPartyName.description"/>
            </v-col>
          </template>
          <v-col cols="12">
            <c-autocomplete
              v-model="searchDTO.judgment.judgmentTypeID"
              :items="judgmentTypes"
              item-text="judgmentTypeValue"
              item-value="judgmentTypeID"
              :label="$t('page.searchCriteria.judgment.field.judgmentType')"
              outlined
              hide-details="auto"
              aria-describedby="acb74564-b9b6-42d8-b381-dd5b7ccb2f85"/>
            <field-description id="acb74564-b9b6-42d8-b381-dd5b7ccb2f85"
                               message-key="page.searchCriteria.judgment.field.judgmentType.description"/>
          </v-col>
          <v-col cols="12">
            <c-autocomplete
              v-model="searchDTO.judgment.judgmentStatusID"
              :items="judgmentStatuses"
              item-text="judgmentStatusValue"
              item-value="judgmentStatusID"
              :label="$t('page.searchCriteria.judgment.field.judgmentStatus')"
              outlined
              hide-details="auto"
              aria-describedby="acf34a29-b593-4ca3-8362-1b40dad28e96"/>
            <field-description id="acf34a29-b593-4ca3-8362-1b40dad28e96"
                               message-key="page.searchCriteria.judgment.field.judgmentStatus.description"/>
          </v-col>
          <v-col cols="12">
            <date-range-picker
              :label="$t('page.searchCriteria.judgment.field.judgmentDate')"
              :labelStart="$t('page.searchCriteria.judgment.field.judgmentDateFrom')"
              :labelEnd="$t('page.searchCriteria.judgment.field.judgmentDateTo')"
              keyPrefix="judgmentDate"
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.judgment.judgmentDateChoice"
              :startDate.sync="searchDTO.judgment.judgmentDateStart"
              :endDate.sync="searchDTO.judgment.judgmentDateEnd"
              aria-describedby="a3d07b0d-e54a-4e6a-92fd-a8707d1f2dec"/>
            <field-description id="a3d07b0d-e54a-4e6a-92fd-a8707d1f2dec"
                               message-key="page.searchCriteria.judgment.field.judgmentDate.description"/>
          </v-col>
        </v-row>

        <nested-case-search-criteria :searchDTO="searchDTO" :searchType="searchType" class="mt-0"/>
      </form>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import SectionSeparator from '@components/support/SectionSeparator';
import FieldDescription from '@components/support/FieldDescription';
import DateRangePicker from '@components/support/DateRangePicker';
import NestedCaseSearchCriteria from '@components/support/search/NestedCaseSearchCriteria';
import SearchCriteriaMixin from '@/mixins/searchCriteria';

export default {
  components: { SectionSeparator, FieldDescription, NestedCaseSearchCriteria, DateRangePicker },
  mixins: [ SearchCriteriaMixin ],
  data () {
    return {
      judgmentTypes: [],
      judgmentStatuses: []
    };
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.judgment.types' }))
      .then((response) => {
        this.judgmentTypes = _.defaultTo(response.data, []);
      });
    axios.get(this.$cove.getAPI({ name: 'api.judgment.statuses' }))
      .then((response) => {
        this.judgmentStatuses = _.defaultTo(response.data, []);
      });
  },
  watch: {
    'searchDTO.advanced' (newValue) {
      if (newValue)
      {
        this.searchDTO.judgment.forPartyName = null;
        this.searchDTO.judgment.againstPartyName = null;
        this.searchDTO.judgment.forActorCategoryID = this.CONST.ACTOR_CATEGORY_PERSON;
        this.searchDTO.judgment.againstActorCategoryID = this.CONST.ACTOR_CATEGORY_PERSON;
        this.searchDTO.judgment.forPartyFirstNameQueryTypeID = this.getDefaultQueryType(true);
        this.searchDTO.judgment.forPartyLastNameQueryTypeID = this.getDefaultQueryType(true);
        this.searchDTO.judgment.forPartyNameQueryTypeID = this.getDefaultQueryType(true);
        this.searchDTO.judgment.againstPartyFirstNameQueryTypeID = this.getDefaultQueryType(true);
        this.searchDTO.judgment.againstPartyLastNameQueryTypeID = this.getDefaultQueryType(true);
        this.searchDTO.judgment.againstPartyNameQueryTypeID = this.getDefaultQueryType(true);
      }
    },
    'searchDTO.judgment.forActorCategoryID' () {
      this.searchDTO.judgment.forPartyName = null;
      this.searchDTO.judgment.forPartyFirstName = null;
      this.searchDTO.judgment.forPartyLastName = null;
      this.searchDTO.judgment.forPartyNameQueryTypeID = this.getDefaultQueryType(true);
      this.searchDTO.judgment.forPartyFirstNameQueryTypeID = this.getDefaultQueryType(true);
      this.searchDTO.judgment.forPartyLastNameQueryTypeID = this.getDefaultQueryType(true);
    },
    'searchDTO.judgment.againstActorCategoryID' () {
      this.searchDTO.judgment.againstPartyName = null;
      this.searchDTO.judgment.againstPartyFirstName = null;
      this.searchDTO.judgment.againstPartyLastName = null;
      this.searchDTO.judgment.againstPartyNameQueryTypeID = this.getDefaultQueryType(true);
      this.searchDTO.judgment.againstPartyFirstNameQueryTypeID = this.getDefaultQueryType(true);
      this.searchDTO.judgment.againstPartyLastNameQueryTypeID = this.getDefaultQueryType(true);
    }
  }
};
</script>
