<template>
  <v-card :class="{ 'mb-6': !last }">
    <v-card-title>
      {{ announcement.subject }}
    </v-card-title>
    <v-card-text>
      <div class="hide-if-empty mb-4 mt-n2">
        <v-chip v-if="announcement.critical" dark label small color="error darken-1" class="portal-tag">
          {{ $t('announcementCard.tag.important') }}
        </v-chip>
      </div>
      <div class="markdown" v-html="body"></div>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        v-if="hasDocument"
        color="primary"
        class="mw-auto"
        text
        :href="viewDocumentURL"
        target="_blank">
        {{ $t('application.button.viewFile') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { marked } from 'marked';

export default {
  props: {
    announcement: {
      type: Object
    },
    last: {
      type: Boolean
    }
  },
  computed: {
    body () {
      return marked.parse(this.announcement.body, { breaks: true });
    },
    hasDocument () {
      return !_.isNil(this.announcement.documentLinkID);
    },
    viewDocumentURL () {
      return this.$cove.getAPI({
        name: 'api.dms.document',
        params: { documentLinkID: this.announcement.documentLinkID }
      });
    }
  }
};
</script>