<template>
  <loading-card :loading="!ready">
    <v-card-title>
      {{ $t('page.caseView.hearingsCard.title') }}
    </v-card-title>
    <v-card-subtitle v-if="hasHearings">
      {{ $t('page.caseView.hearingsCard.description') }}
    </v-card-subtitle>
    <v-card-text>
      <div v-if="hasHearings" class="d-flex align-center">
        <template v-for="(hearing, i) in hearings">
          <div :key="`${hearing.startDate}.${hearing.hearingType}.display`"
               class="text-center px-1 py-2" :style="`flex: 0 0 ${100/hearings.length}%;min-width:0`">
            <div class="pb-1 px-1 font-weight-medium font-95">
              {{ hearing.hearingType }}
            </div>
            <div class="label text-center">
              {{ hearing.startDate | formatDate }}
            </div>
            <div class="label text-center">
              {{ hearing.startDate | formatTime }}
            </div>
          </div>
          <v-divider v-if="i !== hearings.length - 1" :key="`${hearing.startDate}.${hearing.hearingType}.divider`" vertical/>
        </template>
      </div>
      <div v-else class="font-italic text-minimum-gray text-center pb-3">
        {{ $t('page.caseView.hearingsCard.noneFound') }}
      </div>
    </v-card-text>
  </loading-card>
</template>

<script>
import { DateTime } from 'luxon';
import { dateToStartOfDay } from '@/application/application';
import LoadingCard from '@components/support/LoadingCard';
import axios from 'axios';
import _ from 'lodash';

export default {
  components: { LoadingCard },
  props: {
    courtID: {
      type: String
    },
    caseInstance: {
      type: Object
    },
    isVerifiedParty: {
      type: Boolean
    }
  },
  data () {
    return {
      ready: false,
      hearings: []
    };
  },
  computed: {
    hasHearings () {
      return this.hearings.length > 0;
    }
  },
  watch: {
    caseInstance: {
      immediate: true,
      handler (newValue) {
        if (!_.isNil(newValue))
        {
          this.loadHearings();
        }
        else
        {
          this.ready = false;
          this.hearings = [];
        }
      }
    }
  },
  methods: {
    loadHearings () {
      let url = this.$cove.getAPI({
        name: 'api.case.hearings',
        params: { courtID: this.courtID, caseUUID: this.caseInstance.caseInstanceUUID },
        query: { startDateFrom: dateToStartOfDay(DateTime.local()).toISO(), sort: 'startDate,asc', size: 3 }
      });

      axios.get(url)
        .then((response) => {
          this.hearings = _.get(response, 'data._embedded.results', []);
        })
        .finally(() => {
          this.ready = true;
        });
    }
  }
};
</script>