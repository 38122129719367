<template>
  <loading-card :loading="publicationItems === null">
    <template #default>
      <v-card-title>
        {{ groupName }}
      </v-card-title>
      <v-card-text>
        <div class="dont-break" v-for="(item, i) in publicationItems" :key="item.publicationItemUUID">
          <div class="d-flex flex-wrap flex-md-nowrap">
            <div style="flex: 0 0 180px">
              <router-link :to="{ name: 'caseView', params: { courtID: courtID, caseUUID: item.caseInstanceUUID } }"
                           class="font-weight-medium"
                           target="_blank">
                {{ item.caseNumber }}
              </router-link>
            </div>
            <div style="flex: 0 0 100%" class="d-md-none"></div>
            <div style="flex: 1 1 auto">
              <div>
                {{ item.title }}
              </div>
              <div class="d-flex align-center mt-2">
                <div style="flex: 1 1 50%" class="font-weight-medium py-2 text-center">
                  {{ item.decision }}
                </div>
                <v-divider vertical/>
                <div style="flex: 1 1 50%;" class="text-center py-2">
                  <div v-for="document in item.documents" :key="document.documentLinkUUID">
                    <a :href="getDocumentURL(courtID, item.caseInstanceUUID, document)" target="_blank">
                      {{ document.documentName }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <v-divider v-if="!isLast(i)" class="my-5"/>
        </div>
      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { LoadingCard },
  props: {
    groupName: {
      type: String,
      default: null
    },
    publicationItems: {
      type: Array,
      default: null
    },
    courtID: {
      type: String,
      required: true
    }
  },
  methods: {
    isLast (index) {
      return index === this.publicationItems.length - 1;
    },
    getDocumentURL (courtID, caseInstanceUUID, document) {
      return this.$cove.getAPI({
        name: 'api.case.docketentrydocuments',
        params: { courtID, caseInstanceID: caseInstanceUUID, documentLinkUUID: document.documentLinkUUID }
      });
    }
  }
};
</script>