<template>
  <page-wrapper :title="$t('page.publication.title')" :subtitle="subtitle">
    <v-row>
      <v-col cols="12" md="9" xl="8">
        <v-row>
          <v-col cols="12">
            <publication-banner-card :publication="publication"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <publication-item-group v-for="(key) in groupNameKeys"
                                    class="mb-6 dont-break"
                                    :key="key"
                                    :publicationItems="groupNameToPublicationItems[key]"
                                    :groupName="key"
                                    :courtID="courtID"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import PageMixin from '@/mixins/page';
import PageWrapper from '@components/support/PageWrapper';
import PublicationBannerCard from '@components/support/publication/PublicationBannerCard';
import PublicationItemGroup from '@components/support/publication/PublicationItemGroup';
import _ from 'lodash';
import axios from 'axios';
import { redirectNotFoundErrorHandler } from '@/application/ajax';

export default {
  components: { PageWrapper, PublicationBannerCard, PublicationItemGroup },
  mixins: [ PageMixin ],
  props: {
    courtID: {
      type: String
    },
    publicationUUID: {
      type: String
    }
  },
  data () {
    return {
      publication: null,
      groupNameToPublicationItems: null
    };
  },
  created () {
    this.load(this.courtID, this.publicationUUID);
  },
  computed: {
    subtitle () {
      return _.get(this.publication, 'publicationName', null);
    },
    groupNameKeys () {
      let groupOrders = [];
      _.forEach(this.groupNameToPublicationItems, (items, groupName) =>
      {
        let groupOrderBy = _.defaultTo(items[0].groupOrderBy, Number.MAX_SAFE_INTEGER);
        groupOrders.push({ groupName, groupOrderBy });
      });

      groupOrders = _.sortBy(groupOrders, 'groupOrderBy');

      return groupOrders.map((go) => { return go.groupName; });
    },
    defaultGroupName () {
      // you can have items without a parent grouping, so if we encounter those place them together in a pseudo-grouping using this label.
      // coerce this into an empty string if not specified (ie client may not want a grouping label on them at all).
      // the empty string keeps everything working nicely as the components expect a group name.
      return _.defaultTo(this.$t('page.publication.defaultGroupName'), '');
    }
  },
  methods: {
    load (courtID, publicationUUID) {
      axios.get(this.$cove.getAPI({ name: 'api.court.publication', params: { courtID, publicationUUID } }),
        { errorHandlers: { 404: redirectNotFoundErrorHandler } })
        .then((response) =>
        {
          this.publication = response.data;
          this.groupNameToPublicationItems = {};

          _.forEach(this.publication.publicationItems, (item) =>
          {
            let groupName = _.get(item, 'groupName', this.defaultGroupName);
            if (_.isNil(this.groupNameToPublicationItems[groupName]))
            {
              this.groupNameToPublicationItems[groupName] = [];
            }
            this.groupNameToPublicationItems[groupName].push(item);
          });

          _.forEach(this.groupNameToPublicationItems, (items, groupName) =>
          {
            this.groupNameToPublicationItems[groupName] = _.orderBy(items, 'orderBy', 'asc');
          });
        });
    }
  }
};
</script>