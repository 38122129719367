<template>
  <page-wrapper :title="$t(`searchType.${searchType}.label`)">
    <v-row>
      <v-col md="7" xl="6">
        <validation-error-panel/>
        <v-card>
          <v-card-title>
            {{ $t('page.searchCriteria.criteria.title') }}
          </v-card-title>
          <v-card-subtitle class="pb-2">
            {{ $t('page.searchCriteria.criteria.description') }}
          </v-card-subtitle>
          <v-card-text>
            <component :is="searchCriteriaComponent"
                       :searchType="searchType"
                       :searchDTO="searchDTO"/>

            <v-row v-if="supportsAdvancedSearch && !searchDTO.advanced" no-gutters class="mt-0">
              <v-col cols="12">
                <button type="button" class="link-button" @click="toggleAdvancedSearch"
                        aria-describedby="a407a948-0b82-48db-b8b1-87466288c50f">
                  {{ $t('page.searchCriteria.button.switchToAdvanced') }}
                </button>
                <field-description id="a407a948-0b82-48db-b8b1-87466288c50f"
                                   message-key="page.searchCriteria.button.switchToAdvanced.description"/>
              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions class="justify-end">
            <c-btn type="primary" @click="runSearch" style="min-width:100px">
              {{ $t('global.search') }}
            </c-btn>
          </v-card-actions>

        </v-card>
      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import PageWrapper from '@components/support/PageWrapper';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import SectionSeparator from '@components/support/SectionSeparator';
import FieldDescription from '@components/support/FieldDescription';
import Search from '@/application/search';
import _ from 'lodash';
import PageMixin from '@/mixins/page';

export default {
  components: { PageWrapper, SectionSeparator, ValidationErrorPanel, FieldDescription },
  mixins: [ PageMixin ],
  props: {
    searchType: {
      type: String
    }
  },
  data () {
    return {
      searchDTO: Search.generateSearchDTO(this.searchType)
    };
  },
  computed: {
    supportsAdvancedSearch () {
      return Search.getSupportedAdvancedSearchTypes().includes(this.searchType);
    },
    searchCriteriaComponent () {
      let componentName = _.upperFirst(this.searchType) + 'SearchCriteria';
      return () => {
        return import('@components/support/search/' + componentName);
      };
    }
  },
  created () {
    // prevent URL hacking to forbidden search types
    if (!Search.getValidSearchTypes().includes(this.searchType))
    {
      this.$router.push({ name: 'searchHome' });
    }

    Search.setSearchDefaults(this.searchType, this.searchDTO);
  },
  methods: {
    runSearch () {
      let isValid = Search.validateSearchCriteria(this.searchType, this.searchDTO);
      if (isValid)
      {
        Search.beforeInitialSearch(this.searchType, this.searchDTO);

        let criteria = Search.generateQueryObject(this.searchType, this.searchDTO);
        this.$router.push({ name: 'searchResults', params: { searchType: this.searchType }, query: { criteria } });
      }
      else
      {
        this.scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
      }
    },
    toggleAdvancedSearch () {
      this.searchDTO.advanced = true;
      this.scrollTo('html');
    }
  }
};
</script>