<template>
  <page-wrapper :title="$t('page.invite.pageTitle')">
    <v-row>
      <v-col cols="12" md="7" xl="6">
        <loading-card :loading="loading">
          <v-card-title>
            {{ $t('page.invite.title') }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('page.invite.subtitle') }}
          </v-card-subtitle>
          <v-card-text>
            <section-separator :title="$t('page.invite.section.details')" class="mt-2"/>
            <dl class="d-grid grid-4060">
              <template v-if="!isSingleCourtMode">
                <dt>{{ $t('courtCase.court') }}</dt>
                <dd>{{ invite.courtAbbreviation }}</dd>
              </template>
              <dt>{{ $t('invite.date') }}</dt>
              <dd>{{ invite.date | formatDateTime }}</dd>
              <template v-if="!isSingleCaseLocationMode">
                <dt>{{ $t('court.location') }}</dt>
                <dd>{{ invite.locationName }}</dd>
              </template>
              <dt>{{ $t('invite.type') }}</dt>
              <dd>
                <div v-if="invite.session">
                  <div>{{ $t('page.myInvites.session') }}</div>
                  <div class="text-minimum-gray">{{ invite.courtSessionTypeName }}</div>
                </div>
                <div v-if="!invite.session">
                  <div>{{ $t('page.myInvites.hearing') }}</div>
                  <div>
                    <router-link :to="{ name: 'caseView', params: {
                      courtID: internalCourtID,
                      caseUUID: invite.caseInstanceID
                    }}">
                      {{ invite.caseNumber }}
                    </router-link>
                  </div>
                </div>
              </dd>
              <dt>{{ $t('invite.subtype') }}</dt>
              <dd>{{ invite.subtype }}</dd>
              <template v-if="hasDepartment">
                <dt>{{ $t('invite.department') }}</dt>
                <dd>{{ invite.departmentDisplayName }}</dd>
              </template>
              <dt>{{ $t('invite.room') }}</dt>
              <dd>{{ invite.room }}</dd>
              <template v-if="hasJudge">
                <dt>{{ $t('invite.judge') }}</dt>
                <dd>{{ invite.judgeDisplayName }}</dd>
              </template>
              <dt>{{ $t('invite.status') }}</dt>
              <dd>{{ invite.status }}</dd>
            </dl>
            <div v-if="hasStreamingLink || hasConferenceLink || hasConferenceComment">
              <section-separator :title="$t('page.invite.section.virtualSessionInfo')" class="mt-8 mb-2"/>
              <dl class="d-grid grid-4060">
                <template v-if="hasStreamingLink">
                  <dt>{{ $t('invite.streaming') }}</dt>
                  <dd><a :href="invite.streamingLink" target="_blank">{{ $t('global.open') }}</a></dd>
                </template>
                <template v-if="hasConferenceLink">
                  <dt>{{ $t('invite.conference') }}</dt>
                  <dd><a :href="invite.conferenceLink" target="_blank">{{ $t('global.open') }}</a></dd>
                </template>
                <template v-if="hasConferenceComment">
                  <dt>{{ $t('invite.conferenceComment') }}</dt>
                  <dd>{{ invite.conferenceComment }}</dd>
                </template>
              </dl>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end mt-4">
            <c-btn v-if="showAccept" type="primary" @click="submit(false)">
              {{ $t('application.button.accept') }}
            </c-btn>
            <c-btn v-if="showDecline" type="danger" @click="submit(true)">
              {{ $t('application.button.decline') }}
            </c-btn>
            <c-btn @click="returnToInvites">
              {{ $t('application.button.cancel') }}
            </c-btn>
          </v-card-actions>
        </loading-card>
      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import CONST from '@/application/constants';
import PageWrapper from '@components/support/PageWrapper';
import SectionSeparator from '@components/support/SectionSeparator';
import LoadingCard from '@components/support/LoadingCard';
import { redirectNotFoundErrorHandler } from '@/application/ajax';

export default {
  components: { LoadingCard, PageWrapper, SectionSeparator },
  props: {
    eventID: {
      type: String,
      required: true
    },
    typeValue: {
      type: String,
      required: true
    },
    subtypeID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      invite: {}
    };
  },
  created () {
    axios.get(this.$cove.getAPI({
      name: 'api.search.user.invite',
      params: { inviteID: this.eventID, typeValue: this.typeValue },
      query: { subtypeID: this.subtypeID }
    }), { errorHandlers: { 404: redirectNotFoundErrorHandler } }).then((response) => {
      this.invite = _.defaultTo(response.data, {});
    }).finally(() => {
      this.loading = false;
    });
  },
  computed: {
    isSingleCourtMode () {
      return this.$store.getters['application/isSingleCourtMode'];
    },
    isSingleCaseLocationMode () {
      return this.$store.getters['application/isSingleCaseLocationMode'];
    },
    internalCourtID () {
      let internalCourt = this.$store.getters['application/getCourtByExternalID'](this.invite.courtID);
      return !_.isNil(internalCourt) ? internalCourt.resourceID : null;
    },
    hasDepartment () {
      return !_.isNil(this.invite.departmentDisplayName);
    },
    hasJudge () {
      return !_.isNil(this.invite.judgeDisplayName);
    },
    hasStreamingLink () {
      return !_.isNil(this.invite.streamingLink);
    },
    hasConferenceLink () {
      return !_.isNil(this.invite.conferenceLink);
    },
    hasConferenceComment () {
      return !_.isNil(this.invite.conferenceComment);
    },
    showAccept () {
      return this.invite.statusID === String(CONST.EVENT_ATTRIBUTE_STATUS_PENDING);
    },
    showDecline () {
      return this.invite.statusID === String(CONST.EVENT_ATTRIBUTE_STATUS_PENDING) ||
          this.invite.statusID === String(CONST.EVENT_ATTRIBUTE_STATUS_ACCEPTED);
    }
  },
  methods: {
    submit (decline) {
      this.$cove.block({ delay: 0 });
      let url = this.$cove.getAPI({
        name: 'api.search.user.invite',
        params: { inviteID: this.eventID, typeValue: this.typeValue }
      });
      axios.post(url, null, { params: { decline, 'subtypeID': this.invite.subtypeID } }).then(() => {
        this.returnToInvites();
        this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
      }).finally(() => {
        this.$cove.unblock();
      });
    },
    returnToInvites () {
      this.$router.push({ name: 'work-invites' });
    }
  }
};
</script>