<template>
  <div>
    <transition name="scroll-y-transition" mode="out-in">
      <form :key="searchDTO.advanced">
        <v-row no-gutters>
          <v-col cols="12">
            <section-separator :title="$t('searchType.party.criteriaSeparator')" class="my-4"/>
          </v-col>
          <v-col cols="12">
            <c-autocomplete
              v-model="searchDTO.party.partySubTypeID"
              :items="partySubTypes"
              :label="$t('page.searchCriteria.party.field.role')"
              :item-text="formatPartySubType"
              item-value="participantSubTypeID"
              outlined
              hide-details="auto"
              aria-describedby="a7df586f-d391-4b28-99ad-44a5663cef12"/>
            <field-description id="a7df586f-d391-4b28-99ad-44a5663cef12"
                               message-key="page.searchCriteria.party.field.role.description"/>
          </v-col>
          <template v-if="searchDTO.advanced">
            <v-col cols="12">
              <c-autocomplete
                v-model="searchDTO.party.actorCategoryID"
                :items="actorCategories"
                :label="$t('page.searchCriteria.party.field.partyType')"
                item-value="actorCategoryID"
                :clearable="false"
                outlined
                hide-details="auto"
                aria-describedby="aabf7bf4-43ff-48ce-be54-747646f35291"/>
              <field-description id="aabf7bf4-43ff-48ce-be54-747646f35291"
                                 message-key="page.searchCriteria.party.field.partyType.description"/>
            </v-col>
            <template v-if="searchDTO.party.actorCategoryID === CONST.ACTOR_CATEGORY_PERSON">
              <v-col cols="12">
                <c-text-field
                  v-model="searchDTO.party.partyFirstName"
                  :label="$t('page.searchCriteria.party.field.firstName')"
                  key="personName"
                  outlined
                  maxlength="250"
                  hide-details="auto"
                  aria-describedby="ab75a473-95fa-4608-8acc-0331f48f597d"/>
                <field-description id="ab75a473-95fa-4608-8acc-0331f48f597d"
                                   message-key="page.searchCriteria.party.field.firstName.description"/>
              </v-col>
              <v-col cols="12">
                <c-autocomplete
                  v-model="searchDTO.party.partyFirstNameQueryTypeID"
                  :label="$t('page.searchCriteria.party.field.firstNameSearchType')"
                  :items="queryTypes"
                  outlined
                  class="ml-8"
                  :clearable="false"
                  hide-details="auto"
                  aria-describedby="a6690f0f-b943-4621-876a-9d91dfc3ab58"/>
                <field-description id="a6690f0f-b943-4621-876a-9d91dfc3ab58" class="ml-8"
                                   message-key="global.queryType.description"/>
              </v-col>
              <v-col cols="12">
                <c-text-field
                  v-model="searchDTO.party.partyLastName"
                  :label="$t('page.searchCriteria.party.field.lastName')"
                  key="personName"
                  outlined
                  maxlength="250"
                  hide-details="auto"
                  aria-describedby="a4b853c7-bc75-4c3a-9420-c60608c66c29"/>
                <field-description id="a4b853c7-bc75-4c3a-9420-c60608c66c29"
                                   message-key="page.searchCriteria.party.field.lastName.description"/>
              </v-col>
              <v-col cols="12">
                <c-autocomplete
                  v-model="searchDTO.party.partyLastNameQueryTypeID"
                  :label="$t('page.searchCriteria.party.field.lastNameSearchType')"
                  :items="queryTypes"
                  outlined
                  class="ml-8"
                  :clearable="false"
                  hide-details="auto"
                  aria-describedby="ac3f9679-8880-47b2-a9df-4b00b4c92738"/>
                <field-description id="ac3f9679-8880-47b2-a9df-4b00b4c92738" class="ml-8"
                                   message-key="global.queryType.description"/>
              </v-col>
            </template>
            <template v-else-if="searchDTO.party.actorCategoryID === CONST.ACTOR_CATEGORY_ORGANIZATION">
              <v-col cols="12">
                <c-text-field
                  v-model="searchDTO.party.partyName"
                  :label="$t('page.searchCriteria.party.field.orgName')"
                  key="orgName"
                  required
                  outlined
                  maxlength="250"
                  hide-details="auto"
                  aria-describedby="a2903748-ae7a-46cd-8cbb-b0131ae5054b"/>
                <field-description id="a2903748-ae7a-46cd-8cbb-b0131ae5054b"
                                   message-key="page.searchCriteria.party.field.orgName.description"/>
              </v-col>
              <v-col cols="12">
                <c-autocomplete
                  v-model="searchDTO.party.partyNameQueryTypeID"
                  :label="$t('page.searchCriteria.party.field.orgNameSearchType')"
                  :items="queryTypes"
                  outlined
                  class="ml-8"
                  :clearable="false"
                  hide-details="auto"
                  aria-describedby="ac298db4-7dce-455e-a0aa-de5d5f02ba46"/>
                <field-description id="a0debcf3-c629-44b7-a5af-617124232b4d" class="ml-8"
                                   message-key="global.queryType.description"/>
              </v-col>
            </template>
          </template>
          <template v-else>
            <v-col cols="12">
              <c-text-field
                v-model="searchDTO.party.partyName"
                :label="$t('page.searchCriteria.party.field.name')"
                key="name"
                required
                outlined
                maxlength="250"
                hide-details="auto"
                aria-describedby="adb8cc62-528a-45ee-a84d-0afa7d86aa05"/>
              <field-description id="aabefcca-ec91-4cce-b7e6-046f2d3dba18"
                                 message-key="page.searchCriteria.party.field.name.description"/>
            </v-col>
          </template>
        </v-row>

        <nested-case-search-criteria :searchDTO="searchDTO" :searchType="searchType" class="mt-0"/>
      </form>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import SectionSeparator from '@components/support/SectionSeparator';
import FieldDescription from '@components/support/FieldDescription';
import NestedCaseSearchCriteria from '@components/support/search/NestedCaseSearchCriteria';
import SearchCriteriaMixin from '@/mixins/searchCriteria';

export default {
  components: { SectionSeparator, FieldDescription, NestedCaseSearchCriteria },
  mixins: [ SearchCriteriaMixin ],
  data () {
    return {
      partySubTypes: []
    };
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.party.subtypes' }))
      .then((response) => {
        this.partySubTypes = _.sortBy(_.defaultTo(response.data, []),
          ['participantType.participantTypeName', 'participantSubTypeName']);
      });
  },
  watch: {
    'searchDTO.advanced' (newValue) {
      if (newValue)
      {
        this.searchDTO.party.partyName = null;
        this.searchDTO.party.actorCategoryID = this.CONST.ACTOR_CATEGORY_PERSON;
        this.searchDTO.party.partyFirstNameQueryTypeID = this.getDefaultQueryType(true);
        this.searchDTO.party.partyLastNameQueryTypeID = this.getDefaultQueryType(true);
        this.searchDTO.party.partyNameQueryTypeID = this.getDefaultQueryType(true);
      }
    },
    'searchDTO.party.actorCategoryID' () {
      this.searchDTO.party.partyName = null;
      this.searchDTO.party.partyFirstName = null;
      this.searchDTO.party.partyLastName = null;
      this.searchDTO.party.partyNameQueryTypeID = this.getDefaultQueryType(true);
      this.searchDTO.party.partyFirstNameQueryTypeID = this.getDefaultQueryType(true);
      this.searchDTO.party.partyLastNameQueryTypeID = this.getDefaultQueryType(true);
    }
  },
  methods: {
    formatPartySubType (partySubType) {
      return `${partySubType.participantType.participantTypeName} - ${partySubType.participantSubTypeName}`;
    }
  }
};
</script>
