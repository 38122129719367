<template>
  <v-dialog :value="visible" width="600" persistent>
    <v-card elevation="0" class="px-4 py-3" style="border-left:8px solid var(--v-error-base)">
      <h1 class="font-weight-regular">
        {{ $t('popup.serverValidationError.title') }}
      </h1>
      <div class="mt-1 text-minimum-gray">
        {{ $t('popup.serverValidationError.description') }}
      </div>

      <v-alert
        :value="true"
        class="mt-6 mb-16"
        elevation="2"
        color="error"
        outlined
        text>
        <ul>
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <li v-for="error in errors">
            {{ error }}
          </li>
        </ul>
      </v-alert>

      <v-divider/>

      <div class="mt-3 text-right">
        <c-btn id="error-close-button" @click="close">
          {{ $t('cove.general.close') }}
        </c-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { focusElement } from '@/application/utility';

export default {
  computed: {
    visible () {
      return this.$store.state.application.serverValidationErrorPopupVisible;
    },
    errors () {
      return this.$store.getters['cove/errors/getAllErrorMessages']();
    }
  },
  watch: {
    visible (newValue) {
      if (newValue)
      {
        focusElement('#error-close-button');
      }
    }
  },
  methods: {
    close () {
      this.$store.commit('application/closeServerValidationErrorPopup');
      this.$store.commit('cove/errors/clear');
    }
  }
};
</script>