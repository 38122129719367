var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('loading-card',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('page.subscriptionMgmt.renewalHistory.title'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t('page.subscriptionMgmt.renewalHistory.description'))+" ")]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"sort-by":_vm.paging.sortBy,"sort-desc":_vm.paging.sortDesc,"server-items-length":_vm.paging.totalElements,"loading":_vm.loadingTable,"item-key":"resourceID","must-sort":"","hide-default-footer":"","mobile-breakpoint":800},on:{"update:options":_vm.paging.updateOptions},scopedSlots:_vm._u([{key:"item.renewalDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"item.pendingItemsCount",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(value))+" ")]}},{key:"item.processedItemsCount",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(value))+" ")]}},{key:"item.erroredItemsCount",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(value))+" ")]}},{key:"item.resourceID",fn:function(ref){
var value = ref.value;
return [_c('router-link',{attrs:{"to":{ name: 'admin-subscription-renewal', params: { subscriptionRenewalID: value } }}},[_vm._v(" "+_vm._s(_vm.$t('global.view'))+" ")])]}},{key:"footer",fn:function(){return [_c('paging-controls-new',{attrs:{"paging":_vm.paging}})]},proxy:true}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }