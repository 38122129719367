<template>
  <div>
    <transition name="scroll-x-reverse-transition" mode="out-in" appear>
      <slot/>
    </transition>
  </div>
</template>

<script>
export default {
};
</script>