<template>
  <loading-card :loading="loading" class="nav-tab-small">
    <template #default>
      <v-card-title>
        {{ $t('page.admin.userAccount.roles.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.admin.userAccount.roles.description') }}
      </v-card-subtitle>
      <v-card-text class="pa-0">
        <v-simple-table v-if="userRoleMaps.length > 0">
          <caption class="d-sr-only">
            {{ $t('page.admin.userAccount.roles.title') }}
          </caption>
          <thead>
            <tr>
              <th>{{ $t('page.admin.userAccount.roles.tableHeader.roleName') }}</th>
              <th class="text-right">{{ $t('global.remove') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="urm in userRoleMaps" :key="urm.role.resourceID">
              <td>{{ urm.role.displayName }}</td>
              <td class="text-right">
                <v-btn icon color="primary" @click="removeRole(urm)">
                  <span class="d-sr-only">
                    {{ $t('page.admin.userAccount.roles.button.removeRole', [urm.role.displayName]) }}
                  </span>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <cutout-area v-else class="py-4">
          {{ $t('page.admin.userAccount.roles.missing') }}
        </cutout-area>
      </v-card-text>
      <v-card-actions v-if="!isAdmin" class="justify-end mt-8">
        <c-btn type="primary" class="px-4" @click="grantAdminRole">
          {{ $t('page.admin.userAccount.roles.button.grantAdminRole') }}
        </c-btn>
      </v-card-actions>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import CutoutArea from '@components/support/CutoutArea';
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { LoadingCard, CutoutArea },
  props: {
    userID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      user: null
    };
  },
  computed: {
    userRoleMaps () {
      return _.get(this.user, 'userRoleMaps', null);
    },
    isAdmin () {
      let index = _.findIndex(_.get(this.user, 'userRoleMaps', null),
        ['role.resourceID', this.CONST.ROLE_UUID_ADMINISTRATOR]);
      return index >= 0;
    }
  },
  created () {
    this.loadUser()
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    loadUser () {
      let url = this.$cove.getAPI({
        name: 'api.user',
        params: {
          userID: this.userID
        },
        query: {
          fields: '*,userRoleMaps(role(*))'
        }
      });

      return axios.get(url)
        .then((response) => {
          this.user = response.data;
        });
    },
    grantAdminRole () {
      let payload = {
        userID: this.userID,
        roleID: this.CONST.ROLE_UUID_ADMINISTRATOR
      };

      this.$cove.block({ delay: 0 });
      axios.post(this.$cove.getAPI({ name: 'api.userrolemaps' }), payload)
        .then(() => {
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
          return this.loadUser(this.userID);
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    removeRole (userRoleMap) {
      this.$cove.confirm({ message: this.$t('page.admin.userAccount.roles.confirmRemove', [userRoleMap.role.displayName]) })
        .then((confirm) => {
          if (confirm)
          {
            this.$cove.block({ delay: 0 });
            axios.delete(this.$cove.getAPI({ name: 'api.userrolemap', params: { userRoleMapID: userRoleMap.resourceID } }))
              .then(() => {
                this.$cove.notify({ message: this.$t('application.remove.success'), color: 'success' });
                return this.loadUser(this.userID);
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    }
  }
};
</script>