<template>
  <v-alert v-if="showInstructions" colored-border type="info" border="left" elevation="2" :icon="false" class="pa-2 mb-5">
    <div class="d-flex align-center">
      <div class="pl-4" style="flex: 1 1 auto">
        <div class="text-uppercase font-weight-medium">
          {{ $t('page.manageFiling.instructions.title') }}
        </div>
      </div>
      <div style="flex: 0 0 auto" class="text-right">
        <v-btn text color="primary" @click="toggleVisibility">
          {{ visible ? $t('global.hide') : $t('global.show') }}
          <v-icon right>
            {{ visible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </v-btn>
      </div>
    </div>

    <v-expand-transition>
      <div v-if="visible" class="px-4">
        <div class="my-2 markdown" v-html="generateMarkdown()"></div>
      </div>
    </v-expand-transition>
  </v-alert>
</template>

<script>
import _ from 'lodash';
import { marked } from 'marked';
import { getLocalStorageValue, setLocalStorageValue } from '@/application/application';

export default {
  data () {
    return {
      visible: false
    };
  },
  created () {
    let filingIDs = _.defaultTo(getLocalStorageValue(this.CONST.BROWSER_STORAGE_HIDDEN_INSTR_FILING_IDS), []);
    this.visible = filingIDs.indexOf(this.filing.resourceID) === -1;
  },
  computed: {
    filing () {
      return this.$store.state.filing.filing;
    },
    filingType () {
      return this.$store.state.filing.filingType;
    },
    instructions () {
      return this.filingType.instruction;
    },
    isLocked () {
      return this.$store.getters['filing/isLocked'];
    },
    showInstructions () {
      return !_.isNil(this.instructions) && !this.isLocked;
    }
  },
  methods: {
    toggleVisibility () {
      this.visible = !this.visible;

      let filingID = this.filing.resourceID;
      let filingIDs = _.defaultTo(getLocalStorageValue(this.CONST.BROWSER_STORAGE_HIDDEN_INSTR_FILING_IDS), []);
      if (this.visible)
      {
        _.pull(filingIDs, filingID);
      }
      else
      {
        filingIDs.unshift(filingID);
      }

      setLocalStorageValue(this.CONST.BROWSER_STORAGE_HIDDEN_INSTR_FILING_IDS,
        filingIDs.splice(0, this.CONST.BROWSER_STORAGE_HIDDEN_INSTR_MAX_LENGTH));
    },
    generateMarkdown () {
      return marked.parse(_.escape(this.instructions), {
        breaks: true
      });
    }
  }
};
</script>