<template>
  <c-dialog
    :value="value"
    title-key="popup.setService.title"
    :width="500"
    @input="close">

    <div class="px-6 pt-7 pb-12">
      <validation-error-panel class="mb-7"/>

      <form>
        <c-autocomplete
          v-model="serviceType"
          :label="$t('popup.setService.field.serviceMethod')"
          :items="filteredServiceTypes"
          item-text="displayName"
          return-object
          key="serviceType"
          required
          outlined
          hide-details="auto"
          aria-describedby="a64f1990-2300-49f1-b5e7-18988f16cd2d">
          <template #item="{ item }">
            <v-list-item-content class="d-block">
              <v-list-item-title class="font-weight-medium">
                {{ item.displayName }}
              </v-list-item-title>
              <div class="d-flex text-minimum-gray font-95" style="max-width:80ch">
                {{ item.description }}
              </div>
            </v-list-item-content>
          </template>
        </c-autocomplete>
        <field-description id="a64f1990-2300-49f1-b5e7-18988f16cd2d"
                           message-key="popup.setService.field.serviceMethod.description"/>

        <transition name="slide-y-transition">
          <div v-if="showServiceDate">
            <c-date-picker
              v-model="serviceDate"
              :label="$t('popup.setService.field.serviceDate')"
              key="serviceDate"
              outlined
              required
              hide-details="auto"
              aria-describedby="a48c0082-4d7a-450f-9635-3f1d66d0ecb8"/>
            <field-description id="a48c0082-4d7a-450f-9635-3f1d66d0ecb8"
                               message-key="popup.setService.field.serviceDate.description"/>
          </div>
        </transition>
      </form>
    </div>

    <template #actions-right>
      <c-btn type="primary" @click="save">
        {{ $t('application.button.save') }}
      </c-btn>
    </template>

  </c-dialog>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import FieldDescription from '@components/support/FieldDescription';

export default {
  components: { ValidationErrorPanel, FieldDescription },
  props: {
    value: {
      type: Boolean
    },
    recipients: {
      type: Array
    }
  },
  data () {
    return {
      serviceType: null,
      serviceDate: null,
      serviceTypes: []
    };
  },
  computed: {
    filingID () {
      return this.$store.state.filing.filingID;
    },
    showServiceDate () {
      return _.get(this.serviceType, 'serviceDateRequired', false);
    },
    offerEService () {
      return !_.isEmpty(this.recipients) && _.every(this.recipients, { eServiceEligible: true });
    },
    filteredServiceTypes () {
      let result = _.cloneDeep(this.serviceTypes);
      if (!this.offerEService)
      {
        _.remove(result, { resourceID: this.CONST.FILING_SERVICE_METHOD_ESERVICE });
      }

      return result;
    }
  },
  watch: {
    value (newValue) {
      if (newValue && _.isEmpty(this.serviceTypes))
      {
        this.loadServiceTypes();
      }
      else if (!newValue)
      {
        this.reset();
      }
    }
  },
  methods: {
    loadServiceTypes () {
      this.$cove.block();

      let url = this.$cove.getAPI({
        name: 'api.filing.serviceMethods',
        query: { size: this.CONST.PAGING_MAX_SIZE }
      });

      axios.get(url)
        .then((response) => {
          this.serviceTypes = _.get(response, 'data._embedded.results', []);
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    validate () {
      this.$store.commit('cove/errors/clear');

      this.$cove.validation.required(this.serviceType, 'popup.setService.field.serviceMethod', 'serviceType');

      if (this.showServiceDate)
      {
        this.$cove.validation.required(this.serviceDate, 'popup.setService.field.serviceDate', 'serviceDate');
        this.$cove.validation.date.rejectIfInvalid(this.serviceDate, 'popup.setService.field.serviceDate', 'serviceDate');
      }

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      this.$cove.block({ delay: 0 });

      let isValid = this.validate();
      if (!isValid)
      {
        this.$cove.unblock();
        return;
      }

      let filingServiceRecipients = _.groupBy(this.recipients, 'recipientType');
      _.forEach(filingServiceRecipients, (value, key) => {
        filingServiceRecipients[key] = _.map(value, 'resourceID');
      });

      let payload = {
        filingID: this.filingID,
        filingServiceRecipients,
        filingServiceMethodID: this.serviceType.resourceID,
        ...(!_.isNil(this.serviceDate) && { serviceDate: this.$cove.parseDate(this.serviceDate).toISODate() })
      };

      axios.post(this.$cove.getAPI({ name: 'api.filing.services' }), payload)
        .then(() => {
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
          this.close(true);
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    close (reload = false) {
      this.$emit('input', false);
      if (reload)
      {
        this.$emit('reload');
      }
    },
    reset () {
      this.$store.commit('cove/errors/clear');
      this.serviceType = null;
      this.serviceDate = null;
    }
  }
};
</script>