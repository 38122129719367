<template>
  <div>
    <transition name="scroll-y-transition" mode="out-in">
      <form :key="searchDTO.advanced">
        <v-row no-gutters class="mt-4">
          <v-col v-if="!isSingleCourtMode" cols="12">
            <c-autocomplete
              :items="courts"
              v-model="searchDTO.courtID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('page.searchCriteria.case.field.court')"
              outlined
              @change="changeCourt"
              key="courtID"
              hide-details="auto"
              aria-describedby="adf6e5a4-b099-4b21-8a92-572059ce793a"/>
            <field-description id="adf6e5a4-b099-4b21-8a92-572059ce793a"
                               message-key="page.searchCriteria.case.field.court.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.case.caseNumber"
              :label="$t('page.searchCriteria.case.field.caseNumber')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="ac5ce72e-e17f-447a-9697-f9a7bd172b2a"/>
            <field-description id="ac5ce72e-e17f-447a-9697-f9a7bd172b2a"
                               message-key="page.searchCriteria.case.field.caseNumber.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.case.caseTitle"
              :label="$t('page.searchCriteria.case.field.caseTitle')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="a575f370-e512-47a7-a3e0-e24d1adfc836"/>
            <field-description id="a575f370-e512-47a7-a3e0-e24d1adfc836"
                               message-key="page.searchCriteria.case.field.caseTitle.description"/>
          </v-col>
          <v-col v-if="searchDTO.advanced" cols="12">
            <c-autocomplete
              v-model="searchDTO.case.caseTitleQueryTypeID"
              :label="$t('page.searchCriteria.case.field.caseTitleSearchType')"
              :items="queryTypes"
              outlined
              class="ml-8"
              :clearable="false"
              hide-details="auto"
              aria-describedby="a6b94c73-6561-424b-835f-88e8465eff15"/>
            <field-description id="a6b94c73-6561-424b-835f-88e8465eff15" class="ml-8"
                               message-key="global.queryType.description"/>
          </v-col>
          <v-col cols="12">
            <c-autocomplete
              v-model="searchDTO.case.caseCategoryID"
              :items="categories"
              item-text="caseCategoryName"
              item-value="caseCategoryID"
              :label="$t('page.searchCriteria.case.field.caseCategory')"
              :no-data-text="$t('global.courtFirst')"
              outlined
              hide-details="auto"
              aria-describedby="a8865f7f-cc2a-4adc-b6bf-b3b62a423397"/>
            <field-description id="a8865f7f-cc2a-4adc-b6bf-b3b62a423397"
                               message-key="page.searchCriteria.case.field.caseCategory.description"/>
          </v-col>
          <v-col cols="12">
            <date-range-picker
              :label="$t('page.searchCriteria.case.field.caseFiledDate')"
              :labelStart="$t('page.searchCriteria.case.field.caseFiledDateFrom')"
              :labelEnd="$t('page.searchCriteria.case.field.caseFiledDateTo')"
              keyPrefix="caseFiledDate"
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.case.filedDateChoice"
              :startDate.sync="searchDTO.case.filedDateStart"
              :endDate.sync="searchDTO.case.filedDateEnd"
              aria-describedby="a0d83c01-f8af-4a3d-8c79-5256302150d2"/>
            <field-description id="a0d83c01-f8af-4a3d-8c79-5256302150d2"
                               message-key="page.searchCriteria.case.field.caseFiledDate.description"/>
          </v-col>
          <v-col cols="12">
            <c-checkbox
              :label="$t('page.searchCriteria.case.field.excludeClosed')"
              v-model="searchDTO.case.excludeClosed"
              class="mt-0"
              hide-details="auto"
              aria-describedby="a0549454-b002-4539-a236-7a31504991a9"/>
            <field-description id="a0549454-b002-4539-a236-7a31504991a9"
                               message-key="page.searchCriteria.case.field.excludeClosed.description"/>
          </v-col>
        </v-row>

      </form>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import DateRangePicker from '@components/support/DateRangePicker';
import FieldDescription from '@components/support/FieldDescription';
import SearchCriteriaMixin from '@/mixins/searchCriteria';

export default {
  components: { DateRangePicker, FieldDescription },
  mixins: [ SearchCriteriaMixin ],
  data () {
    return {
      categories: []
    };
  },
  computed: {
    caseLocations () {
      return this.$store.getters['application/getCaseLocationsByCourt'](this.searchDTO.courtID);
    },
    isSingleCaseLocationMode () {
      return this.$store.getters['application/isSingleCaseLocationMode'];
    }
  },
  watch: {
    'searchDTO.courtID': {
      immediate: true,
      handler (newValue) {
        if (!_.isNil(newValue))
        {
          axios.get(this.$cove.getAPI({ name: 'api.court.case.categories', params: { courtID: newValue } }))
            .then((response) => {
              this.categories = _.defaultTo(response.data, []);
            });
        }
        else
        {
          this.categories = [];
        }
      }
    },
    'searchDTO.advanced' (newValue) {
      if (newValue)
      {
        // only change this query type if they didn't enter anything yet
        if (this.searchDTO.case.caseTitle === null)
        {
          this.searchDTO.case.caseTitleQueryTypeID = this.getDefaultQueryType(true);
        }
      }
    }
  },
  methods: {
    changeCourt () {
      this.searchDTO.case.locationID = null;
      this.searchDTO.case.caseCategoryID = null;
    }
  }
};
</script>
