var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-dialog',{attrs:{"value":_vm.value && !_vm.loading,"width":"850","close-key":"cove.general.close","title-key":"popup.userRequests.title"},on:{"input":_vm.close}},[_c('v-data-table',{staticClass:"my-1",attrs:{"items":_vm.userRequests,"headers":_vm.headers,"server-items-length":_vm.page.totalElements,"loading":_vm.loading,"item-key":"resourceID","disable-sort":"","show-expand":"","hide-default-footer":"","mobile-breakpoint":800},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('paging-controls',{attrs:{"page":_vm.page},on:{"changePage":_vm.changePage}})]},proxy:true},{key:"item.submissionDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.submissionDate))+" ")]}},{key:"item.processedDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"pb-2"},[_c('dl',{staticClass:"d-grid grid-4060"},[_c('dt',[_vm._v(_vm._s(_vm.$t('popup.userRequests.submissionNumber')))]),_c('dd',[_vm._v(_vm._s(item.submissionNumber))]),(!_vm.isNil(item.clerkComments))?[_c('dt',[_vm._v(_vm._s(_vm.$t('popup.userRequests.clerkComments')))]),_c('dd',[_vm._v(_vm._s(item.clerkComments))])]:_vm._e(),(item.userType.resourceID === _vm.CONST.USER_TYPE_PUBLIC)?[_c('dt',[_vm._v(_vm._s(_vm.$t('popup.userRequests.court')))]),_c('dd',[_vm._v(_vm._s(item.court.displayName))]),_c('dt',[_vm._v(_vm._s(_vm.$t('popup.userRequests.caseNumber')))]),_c('dd',[_vm._v(_vm._s(item.caseNumber))])]:_vm._e()],2)])])]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',{staticClass:"rotating-icon",class:{ active: isExpanded }},[_vm._v(" mdi-chevron-down ")]),_c('span',{staticClass:"d-sr-only"},[_vm._v(" "+_vm._s(isExpanded ? _vm.$t('global.collapse') : _vm.$t('global.expand'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }