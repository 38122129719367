<template>
  <div style="position:relative">
    <v-fade-transition leave-absolute>
      <v-card v-if="loading" :loading="loading && hasWaited" :style="{ 'height': `${height}px`}" key="loading"/>
      <v-card v-else key="content">
        <slot/>
      </v-card>
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: 300
    }
  },
  data () {
    return {
      hasWaited: false
    };
  },
  mounted () {
    setTimeout(() => {
      this.hasWaited = true;
    }, 333);
  }
};
</script>