<template>
  <loading-card :loading="loading" :height="250">
    <v-card-title>
      {{ $t('page.createFiling.step.court.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.createFiling.step.court.description') }}
    </v-card-subtitle>
    <v-card-text class="pt-3">
      <v-row>
        <v-col cols="12">
          <c-autocomplete :items="courts"
                          v-model="bean.courtID"
                          :label="$t('page.createFiling.step.court.field.court')"
                          item-text="displayName"
                          item-value="resourceID"
                          key="court"
                          :clearable="false"
                          outlined
                          required
                          hide-details="auto"
                          aria-describedby="a50ba63b-fc3c-4c7d-98b0-e95e58bb00b7"/>
          <field-description id="a50ba63b-fc3c-4c7d-98b0-e95e58bb00b7"
                             message-key="page.createFiling.step.court.field.court.description"/>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="mt-10 justify-end">
      <c-btn type="primary" @click="save">
        {{ $t('global.continue') }}
      </c-btn>
    </v-card-actions>
  </loading-card>
</template>

<script>
import WorkflowStepMixin from '@/mixins/workflowStep';
import { scrollTo } from '@/application/utility';

export default {
  mixins: [ WorkflowStepMixin ],
  computed: {
    courts () {
      return this.$store.state.application.courts;
    }
  },
  methods: {
    validate () {
      this.$store.commit('cove/errors/clear');

      this.$cove.validation.required(this.bean.courtID, 'page.createFiling.step.court.field.court', 'court');

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      let isValid = this.validate();
      if (!isValid)
      {
        scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
      }
      else
      {
        this.$emit('save', this.bean);
      }
    }
  }
};
</script>
