<template>
  <div class="d-flex flex-wrap flex-md-nowrap" style="row-gap:10px;column-gap:20px">
    <div class="flex-shrink-0 flex-grow-1 flex-md-grow-0" style="flex-basis: 200px">
      <loading-area :loading="loading">
        <v-list nav dense class="rounded" elevation="2" tag="nav">
          <v-list-item-group mandatory color="primary" @change="changeTab">
            <v-list-item v-for="item in items"
                         :key="item.name"
                         :to="{ name: item.routeName }"
                         class="primary--text"
                         replace>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium"
                                   style="white-space:normal;line-height:1.4;word-break:break-word;">
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </loading-area>
    </div>

    <!-- forces wrap on small displays -->
    <div class="d-md-none" style="flex: 1 0 100%;"></div>

    <div style="flex: 1 1 auto">
      <transition name="scroll-x-reverse-transition" mode="out-in" appear>
        <router-view/>
      </transition>
    </div>
  </div>
</template>

<script>
import LoadingArea from '@components/support/LoadingArea';

export default {
  components: { LoadingArea },
  props: {
    // an array of objects -- each of which should have a `name` and `routeName` property
    items: {
      type: Array
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    changeTab () {
      this.$store.commit('cove/errors/clear');
    }
  }
};
</script>