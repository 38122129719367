import * as core from '@/application/search-core';

import _ from 'lodash';
import CONST from '@/application/constants';
import store from '@/plugins/store';

/**
 *
 */
export function getValidSearchTypes ()
{
  let searchTypes = core.getValidSearchTypes();

  _.remove(searchTypes, (st) => {
    return st === CONST.SEARCH_TYPE_CITATION || st === CONST.SEARCH_TYPE_JUDGMENT;
  });

  return searchTypes;
}

export function getSupportedAdvancedSearchTypes ()
{
  let advancedSearchTypes = core.getSupportedAdvancedSearchTypes();

  _.remove(advancedSearchTypes, (st) => {
    return st === CONST.SEARCH_TYPE_CALENDAR;
  });

  return advancedSearchTypes;
}

export function generateSearchDTO (searchType)
{
  let searchDTO = core.generateSearchDTO(searchType);
  return searchDTO;
}

export function validateSearchCriteria (searchType, searchDTO)
{
  core.validateSearchCriteria(searchType, searchDTO);

  // custom validation would go here

  return !store.getters['cove/errors/hasErrors']();
}

export function generateAPIQueryObject (searchType, searchDTO)
{
  let query = core.generateAPIQueryObject (searchType, searchDTO);
  return query;
}

export function getSearchResultHeaders (searchType)
{
  let headers = core.getSearchResultHeaders(searchType);
  if (searchType === CONST.SEARCH_TYPE_PARTY) {
    _.remove(headers, ['value', 'caseHeader.filedDate']);
  }
  if (searchType === CONST.SEARCH_TYPE_CALENDAR) {
    _.remove(headers, ['value', 'eventName']);// calendar.name
    _.remove(headers, ['value', 'room']);// calendar.room
    _.remove(headers, ['value', 'departmentActorInstance.displayName']);// calendar.department
    _.remove(headers, ['value', 'judgeActorInstances']);// calendar.assignment
  }
  return headers;
}

export function setSearchDefaults (searchType, searchDTO)
{
  core.setSearchDefaults(searchType, searchDTO);
  searchDTO.case.excludeClosed = false;
}
