<template>
  <page-wrapper :title="$t('page.myReceipts.title')">
    <search-result-template :config="config">

      <template #table-title>
        {{ $t('page.myReceipts.table') }}
      </template>

      <template #v-data-table.item.receiptDate="{ value }">
        <div style="min-width:100px">
          {{ value | formatDateTime }}
        </div>
      </template>

      <template #v-data-table.item.referenceNumber="{ item }">
        <router-link :to="{ name: 'receipt', params: { receiptID: item.resourceID} }">
          {{ item.referenceNumber }}
        </router-link>
      </template>

      <template #v-data-table.item.amount="{ value }">
        <div style="min-width:100px">
          {{ value | formatCurrency }}
        </div>
      </template>

      <!-- Search Criteria -->
      <template #nav-drawer-filter-body>
        <v-row no-gutters class="mt-4">
          <v-col cols="12">
            <date-range-picker
              :label="$t('receipt.receiptDate')"
              :labelStart="$t('page.myReceipts.receiptDate.from')"
              :labelEnd="$t('page.myReceipts.receiptDate.to')"
              keyPrefix="receiptDate"
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.receiptDateChoice"
              :startDate.sync="searchDTO.receiptDateFrom"
              :endDate.sync="searchDTO.receiptDateTo"
              aria-describedby="d13e2dd2-a9e9-4faf-8a8b-6bafa6636b0b"/>
            <field-description id="d13e2dd2-a9e9-4faf-8a8b-6bafa6636b0b"
                               message-key="page.myReceipts.receiptDate.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.referenceNumber"
              :label="$t('receipt.referenceNumber')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="9f20325a-4d27-497b-8128-6400849eff7b"/>
            <field-description id="9f20325a-4d27-497b-8128-6400849eff7b"
                               message-key="page.myReceipts.referenceNumber.description"/>
          </v-col>
          <v-col cols="12">
            <c-autocomplete
              :items="receiptTypes"
              v-model="searchDTO.receiptTypeID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('receipt.receiptType')"
              outlined
              key="receiptTypeID"
              hide-details="auto"
              aria-describedby="420b8e28-e580-474c-949e-7a56cf06c285"/>
            <field-description id="420b8e28-e580-474c-949e-7a56cf06c285"
                               message-key="page.myReceipts.receiptTypeID.description"/>
          </v-col>
          <v-col cols="12">
            <c-autocomplete
              :items="receiptStatuses"
              v-model="searchDTO.receiptStatusID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('receipt.receiptStatus.short')"
              outlined
              key="receiptStatusID"
              hide-details="auto"
              aria-describedby="ca389f8c-a17f-4a9c-ad69-d09b846a26d9"/>
            <field-description id="ca389f8c-a17f-4a9c-ad69-d09b846a26d9"
                               message-key="page.myReceipts.receiptStatusID.description"/>
          </v-col>
        </v-row>
      </template>

    </search-result-template>

  </page-wrapper>
</template>

<script>

import SearchResultTemplate from '@components/support/template/SearchResultTemplate';
import SearchResultTemplateMixin from '@/mixins/searchResultTemplate';
import _ from 'lodash';
import axios from 'axios';
import { dateToEndOfDay, dateToStartOfDay } from '@/application/application';

export default {
  components: { SearchResultTemplate },
  mixins: [SearchResultTemplateMixin],
  data () {
    return {
      searchDTO: {
        sort: {
          sortBy: 'receiptDate',
          sortDesc: true
        },
        userID: null,
        receiptDateChoice: null,
        receiptDateFrom: null,
        receiptDateTo: null,
        referenceNumber: null,
        receiptTypeID: null,
        receiptStatusID: null
      },
      receiptTypes: [],
      receiptStatuses: []
    };
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.receipt.types' }))
      .then((response) => {
        this.receiptTypes = _.defaultTo(response.data._embedded.results, []);
      });
    axios.get(this.$cove.getAPI({ name: 'api.receipt.statuses' }))
      .then((response) => {
        this.receiptStatuses = _.defaultTo(response.data._embedded.results, []);
      });
  },
  methods: {
    getRouteName () {
      return 'work-receipts';
    },
    getSearchAPIName () {
      return 'api.receipts.search';
    },
    getItemKey () {
      return 'resourceID';
    },
    getHeaders () {
      let headers = [];

      headers.push({ text: this.$t('receipt.receiptDate'), value: 'receiptDate', sortable: true });
      headers.push({ text: this.$t('receipt.referenceNumber'), value: 'referenceNumber', sortable: true });
      headers.push({ text: this.$t('receipt.receiptType'), value: 'receiptType.displayName', sortable: true });
      headers.push({ text: this.$t('receipt.receiptStatus.short'), value: 'receiptStatus.displayName', sortable: true });
      headers.push({ text: this.$t('receipt.amount'), value: 'amount', sortable: true });

      return headers;
    },
    addSearchCriteria (query) {

      if (!_.isNil(this.searchDTO.sort.sortBy)) {
        query.sort = `${this.searchDTO.sort.sortBy},${this.searchDTO.sort.sortDesc ? 'desc' : 'asc'}`;
      }

      // set the userID to this user
      query.userID = this.$store.state.user.userID;

      if (!_.isNil(this.searchDTO.receiptDateFrom)) {
        query.receiptDateFrom = dateToStartOfDay(this.$cove.parseDate(this.searchDTO.receiptDateFrom)).toISO();
      }

      if (!_.isNil(this.searchDTO.receiptDateTo)) {
        query.receiptDateTo = dateToEndOfDay(this.$cove.parseDate(this.searchDTO.receiptDateTo)).toISO();
      }

      if (!_.isNil(this.searchDTO.referenceNumber)) {
        query.referenceNumber = this.searchDTO.referenceNumber;
      }

      if (!_.isNil(this.searchDTO.receiptTypeID)) {
        query.receiptTypeID = this.searchDTO.receiptTypeID;
      }
      if (!_.isNil(this.searchDTO.receiptStatusID)) {
        query.receiptStatusID = this.searchDTO.receiptStatusID;
      }

      return query;
    },
    validateSearchCriteria (searchDTO) {
      this.$cove.validation.date.rejectIfInvalid(searchDTO.receiptDateFrom, 'page.myReceipts.receiptDate.from', 'receiptDate-startDate');
      this.$cove.validation.date.rejectIfInvalid(searchDTO.receiptDateTo, 'page.myReceipts.receiptDate.to', 'receiptDate-endDate');
      this.$cove.validation.date.rejectIfBefore(searchDTO.receiptDateTo, searchDTO.receiptDateFrom,
        'page.myReceipts.receiptDate.to', 'page.myReceipts.receiptDate.from', 'receiptDate-endDate');
    }
  }
};
</script>

