<template>
  <transition name="fade-transition" mode="out-in">
    <span v-if="hasLoaded" key="display">
      <v-chip dark label small :color="chipClass" class="portal-tag d-print-none">
        {{ displayName }}
      </v-chip>
      <span class="d-none d-print-inline">
        {{ displayName }}
      </span>
    </span>
    <span v-else key="placeholder">
      <v-progress-circular indeterminate color="accent" width="2" size="20"/>
    </span>
  </transition>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import Vue from 'vue';

// maintain a global cache that can be used across all instances of this component
let cache = Vue.observable({
  loadStatuses: true,
  filingStatuses: null
});

export default {
  props: {
    filingStatusID: {
      type: String
    }
  },
  computed: {
    hasLoaded () {
      return cache.filingStatuses !== null;
    },
    displayName () {
      return cache.filingStatuses[this.filingStatusID].displayName;
    },
    chipClass () {
      let chipClass = null;

      switch (this.filingStatusID)
      {
        case this.CONST.FILING_STATUS_SUBMITTED:
          chipClass = 'filingStatus-submitted';
          break;
        case this.CONST.FILING_STATUS_RECEIVED:
          chipClass = 'filingStatus-received';
          break;
        case this.CONST.FILING_STATUS_APPROVED:
          chipClass = 'filingStatus-approved';
          break;
        case this.CONST.FILING_STATUS_REJECTED:
          chipClass = 'filingStatus-rejected';
          break;
        case this.CONST.FILING_STATUS_ERRORED:
          chipClass = 'filingStatus-errored';
          break;
        default:
          chipClass = 'filingStatus-draft';
      }

      return chipClass;
    }
  },
  created () {
    if (cache.loadStatuses)
    {
      let url = this.$cove.getAPI({ name: 'api.filing.statuses', query: { size: this.CONST.PAGING_MAX_SIZE } });
      axios.get(url)
        .then((response) => {
          cache.filingStatuses = _.keyBy(response.data._embedded.results, 'resourceID');
        });

      cache.loadStatuses = false;
    }
  }
};
</script>
