<template>
  <c-dialog
    :value="value && !loading"
    width="800"
    close-key="cove.general.close"
    titleKey="popup.filingStatusHistory.title"
    @input="close">

    <div style="min-height:250px">
      <v-data-table
        :items="statusHistories"
        :headers="headers"
        :loading="loading"
        item-key="resourceID"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        must-sort
        hide-default-footer
        :mobile-breakpoint="800">
        <template #item.statusDate="{ value }">
          <div>
            {{ value | formatDateTime }}
          </div>
        </template>
        <template #item.resourceID="{ index, item }">
          {{ formatDuration(index, item) }}
        </template>
      </v-data-table>
    </div>
  </c-dialog>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { DateTime } from 'luxon';
import { formatDuration } from '@/application/utility';

export default {
  props: {
    value: {
      type: Boolean
    }
  },
  data () {
    return {
      loading: false,
      statusHistories: [],
      sortBy: 'statusDate',
      sortDesc: true
    };
  },
  computed: {
    filingID () {
      return this.$store.state.filing.filingID;
    },
    headers () {
      return [
        { text: this.$t('filing.filingStatus'), value: 'filingStatus.displayName', sortable: false },
        { text: this.$t('filing.filingStatusDate'), value: 'statusDate' },
        { text: this.$t('popup.filingStatusHistory.column.timeInStatus'), value: 'resourceID', sortable: false }
      ];
    }
  },
  watch: {
    value (newValue) {
      if (newValue)
      {
        this.$cove.block();
        this.loading = true;
        this.loadStatusHistories()
          .finally(() => {
            this.loading = false;
            this.$cove.unblock();
          });
      }
      else
      {
        this.statusHistories = [];
      }
    }
  },
  methods: {
    loadStatusHistories () {
      let url = this.$cove.getAPI({
        name: 'api.filing.statusHistory',
        params: {
          id: this.filingID
        },
        query: {
          fields: '*,filingStatus(*)'
        }
      });

      return axios.get(url)
        .then((response) => {
          this.statusHistories = _.defaultTo(response.data, []);
        });
    },
    formatDuration (index, item) {
      let isCurrentStatus = (this.sortDesc ? index === 0 : index === this.statusHistories.length - 1);
      if (isCurrentStatus)
      {
        return formatDuration(item.statusDate, DateTime.local().toISO());
      }
      else
      {
        // can't rely on index param here since visible sort may not match source list, so find element manually
        let statusIndex = _.findIndex(this.statusHistories, ['resourceID', item.resourceID]);
        return formatDuration(item.statusDate, this.statusHistories[statusIndex - 1].statusDate);
      }
    },
    close () {
      this.$emit('input', false);
    }
  }
};
</script>