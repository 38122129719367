<template>
  <c-dialog :value="visible && !loading"
            close-key="cove.general.close"
            titleKey="page.popupHelp.title"
            @input="$emit('close')"
            width="700">
    <div class="px-5 pt-6 pb-4">

      <!-- admin editable view -->
      <v-row v-if="isAdmin" no-gutters>
        <v-col cols="12">
          <validation-error-panel class="mb-6" :error-scope="errorScope"/>
        </v-col>
        <template v-if="!adminLoaded">
          <div style="block">
            <div>
              {{ $t('page.popupHelp.admin.direction') }}
            </div>
            <div>
              <ul class="my-5" style="list-style:none">
                <li v-for="locale in locales" :key="locale.resourceID" class="mt-4">
                  <button type="button" class="link-button" @click="toggleAdminHelp(locale);">
                    {{ locale.displayName }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </template>
        <template v-else>
          <v-col cols="12">
            <div v-if="!preview">
              <c-textarea
                v-if="!preview"
                v-model="text"
                :key="`${errorScope}:text`"
                :label="$t('page.popupHelp.field.text')"
                rows="8"
                outlined
                required
                hide-details="auto"
                aria-describedby="b8199e75-1ce7-4b8f-87cf-7c1987df0648"/>
              <field-description id="b8199e75-1ce7-4b8f-87cf-7c1987df0648">
                {{ $t('page.popupHelp.field.text.description.prefix') }}
                <a href="https://www.markdownguide.org/basic-syntax/" target="_blank">{{
                  $t('page.popupHelp.field.text.description.markdownLink')
                }}</a>
                {{ $t('page.popupHelp.field.text.description.also') }}
                <a id="ph-preview-link" href="#" @click="(e) => { togglePreview(e, true); }">{{
                  $t('page.popupHelp.field.text.description.previewLink')
                }}</a>
              </field-description>
            </div>
            <div v-else>
              <div class="pa-4 rounded markdown"
                   style="background-color:#f7f7f7;border:1px solid #a0a0a0"
                   v-html="previewText">
              </div>
              <div class="mt-1 mb-5 text-right">
                <a id="ph-exit-preview-link"
                   href="#"
                   style="font-style:italic;font-size:.9rem"
                   @click="(e) => { togglePreview(e, false); }">
                  {{ $t('page.popupHelp.field.text.description.exitLink') }}
                </a>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <c-text-field
              :value="selectedLocale"
              :label="$t('page.popupHelp.field.locale')"
              outlined
              disabled
              hide-details="auto"
              aria-describedby="ddbd8d0e-e241-4bcd-aefc-afd4a9a62ac0"/>
            <field-description id="ddbd8d0e-e241-4bcd-aefc-afd4a9a62ac0" message-key="page.popupHelp.field.locale.description"/>
          </v-col>
          <v-col cols="12">
            <c-autocomplete
              :items="configurationGroups"
              v-model="configurationGroupID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('page.popupHelp.field.configurationGroup')"
              outlined
              required
              :clearable="false"
              :key="`${errorScope}:configurationGroupID`"
              hide-details="auto"
              aria-describedby="3f675bfe-d7c7-4dd2-9e1a-a6f6dac0a55c"/>
            <field-description id="3f675bfe-d7c7-4dd2-9e1a-a6f6dac0a55c"
                               message-key="page.popupHelp.field.configurationGroup.description"/>
          </v-col>
        </template>

      </v-row>

      <!-- standard user read-only view -->
      <div v-else-if="text !== null" v-html="previewText" class="markdown"></div>

      <!-- standard user missing help view -->
      <cutout-area v-else class="mt-5 mb-8">
        {{ $t('page.popupHelp.helpMissing') }}
      </cutout-area>
    </div>

    <template v-if="isAdmin && adminLoaded" #actions-right>
      <c-btn type="primary" @click="save">
        {{ $t('application.button.save') }}
      </c-btn>
      <c-btn v-if="!isNew" type="danger" @click="remove">
        {{ $t('application.button.remove') }}
      </c-btn>
    </template>

  </c-dialog>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { marked } from 'marked';
import FieldDescription from '@components/support/FieldDescription';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import CutoutArea from '@components/support/CutoutArea';

export default {
  components: { FieldDescription, ValidationErrorPanel, CutoutArea },
  props: {
    visible: {
      type: Boolean
    }
  },
  data () {
    return {
      loading: false,
      adminLoaded: false,
      preview: false,
      localeID: null,
      text: null,
      configurationGroupID: null,
      pageHelpResourceID: null,
      errorScope: 'popupHelp',
      configurationGroups: null
    };
  },
  watch: {
    visible: {
      immediate: true,
      handler (newValue) {
        if (newValue)
        {
          if (!this.isAdmin)
          {
            this.loadPublicView(this.userLocale);
          }
          else if (_.isEmpty(this.configurationGroups))
          {
            this.loadConfigurationGroups();
          }
        }
        else
        {
          this.text = null;
          this.localeID = null;
          this.configurationGroupID = null,
          this.pageHelpResourceID = null;
          this.adminLoaded = false;
        }

        this.$store.commit('cove/errors/clear', this.errorScope);
      }
    }
  },
  computed: {
    isAdmin () {
      return this.$store.getters['user/isAdmin'];
    },
    isNew () {
      return this.pageHelpResourceID === null;
    },
    locales () {
      return this.$store.state.application.locales;
    },
    selectedLocale () {
      return _.find(this.locales, { resourceID: this.localeID }).displayName;
    },
    userLocale () {
      return _.find(this.locales, { localeCode: this.$store.state.user.localeCode });
    },
    previewText () {
      return marked.parse(_.escape(this.text), {
        breaks: true
      });
    }
  },
  methods: {
    loadConfigurationGroups () {
      this.$cove.block();

      let url = this.$cove.getAPI({
        name: 'api.configurationgroups',
        query: { customerAvailableOnly: true }
      });

      axios.get(url)
        .then((response) => {
          this.configurationGroups = _.get(response, 'data._embedded.results', []);
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    validate () {
      this.$store.commit('cove/errors/clear', this.errorScope);
      this.$cove.validation.required(this.text, 'page.popupHelp.field.text', `${this.errorScope}:text`);
      this.$cove.validation.string.maxLength(this.text, 10000, 'page.popupHelp.field.text', `${this.errorScope}:text`);

      this.$cove.validation.required(this.configurationGroupID, 'page.popupHelp.field.configurationGroup',
        `${this.errorScope}:configurationGroupID`);

      return !this.$store.getters['cove/errors/hasErrors'](this.errorScope);
    },
    save () {
      this.$cove.block({ delay: 0 });

      let isValid = this.validate();
      if (!isValid)
      {
        this.$cove.unblock();
      }
      else
      {
        let url = this.isNew ?
          this.$cove.getAPI({ name: 'api.pageHelpMessages' }) :
          this.$cove.getAPI({ name: 'api.pageHelpMessage', params: { resourceID: this.pageHelpResourceID } });
        axios({
          url,
          method: this.isNew ? 'post' : 'patch',
          data: this.toBean()
        })
          .then(() => {
            this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
            this.$emit('close');
          })
          .finally(() => {
            this.$cove.unblock();
          });
      }
    },
    remove () {
      this.$cove.confirm({ message: this.$t('application.remove.confirm') })
        .then((confirmed) => {
          if (confirmed)
          {
            this.$cove.block({ delay: 0 });

            // TODO test with global error mgmt, i.e. make sure user is notified something went wrong with the removal
            let url = this.$cove.getAPI({ name: 'api.pageHelpMessage', params: { resourceID: this.pageHelpResourceID } });
            axios.delete(url)
              .then(() => {
                this.$cove.notify({ message: this.$t('application.remove.success'), color: 'success' });
                this.$emit('close');
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    },
    togglePreview (e, enabled) {
      this.preview = enabled;
      this.$nextTick(() => {
        document.getElementById(enabled ? 'ph-exit-preview-link' : 'ph-preview-link').focus();
      });

      e.preventDefault();
      return false;
    },
    loadPublicView (locale) {
      this.loading = true;
      this.loadHelpRecord(locale)
        .finally(() => {
          this.loading = false;
        });
    },
    loadHelpRecord (locale) {
      this.$cove.block();

      let url = this.$cove.getAPI({
        name: 'api.pageHelpMessages.search',
        query: {
          routeName: this.$route.name,
          localeID: locale.resourceID
        }
      });

      return axios.get(url)
        .then((response) => {
          let pageHelpMessage = response.data;
          if (_.isObject(pageHelpMessage))
          {
            this.fromBean(pageHelpMessage);
          }
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    fromBean (pageHelpMessage) {
      this.pageHelpResourceID = pageHelpMessage.resourceID;
      this.text = _.unescape(pageHelpMessage.helpMessage);
      this.localeID = pageHelpMessage.locale.resourceID;
      this.configurationGroupID = pageHelpMessage.configurationGroup.resourceID;
    },
    toBean () {
      return {
        routeName: this.$route.name,
        helpMessage: _.escape(this.text),
        localeID: this.localeID,
        configurationGroupID: this.configurationGroupID
      };
    },
    toggleAdminHelp (locale) {
      this.localeID = locale.resourceID;
      this.loadHelpRecord(locale)
        .finally(() => {
          this.adminLoaded = true;
        });
    }
  }
};
</script>
