<template>
  <loading-card :loading="publication === null">
    <template #default>
      <v-card-text class="py-5">
        <div class="d-flex align-center">
          <div style="flex: 1 1 auto;" class="text-center">
            <div class="c-caption mb-2">
              {{ publication.courtAbbreviation }}
            </div>
            <div class="font-weight-light mx-auto" style="max-width:60ch;font-size:1.5rem;line-height:1.25;">
              {{ publication.publicationNote }}
            </div>
          </div>
          <v-divider vertical class="d-none d-md-block mx-4"/>
          <!-- Full / Wide Screen View -->
          <div style="flex: 0 0 auto;max-width:180px;" class="d-none d-md-block">
            <div class="text-center font-weight-medium break-word" style="font-size:1.1rem">
              {{ publication.publicationNumber }}
            </div>
            <div class="text-center text-minimum-gray mt-1" style="font-size:1.1rem">
              {{ publication.publicationDate | formatDate }}
            </div>
          </div>
        </div>
        <!-- Small Screen View -->
        <div class="d-md-none mb-n2">
          <v-divider class="my-4"/>
          <div class="text-right">
            <div class="font-weight-medium" style="font-size:1.1rem">
              {{ publication.publicationNumber }}
            </div>
            <div class="text-minimum-gray mt-1" style="font-size:1.1rem">
              {{ publication.publicationDate | formatDate }}
            </div>
          </div>
        </div>
      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { LoadingCard },
  props: {
    publication: {
      type: Object,
      default: null
    }
  }
};
</script>