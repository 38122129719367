<template>
  <v-list-item :to="to" active-class="no-active" style="min-height:80px" @click="$emit('click')">
    <v-list-item-avatar v-if="icon !== null" class="mr-5 rounded-0">
      <v-icon color="primary" x-large>
        {{ icon }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title style="font-size:1.1rem;overflow:initial;white-space:initial" class="primary--text font-weight-medium">
        {{ title }}
      </v-list-item-title>
      <div v-if="renderMarkdown" v-html="generateMarkdown()" class="markdown"></div>
      <div v-else style="line-height:1.4">
        {{ description }}
      </div>
    </v-list-item-content>
    <v-list-item-action>
      <v-icon large color="primary">
        mdi-chevron-right
      </v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { marked } from 'marked';
import _ from 'lodash';

export default {
  props: {
    icon: {
      type: String,
      default: null
    },
    title: {
      type: String
    },
    description: {
      type: String
    },
    renderMarkdown: {
      type: Boolean,
      default: false
    },
    to: {
      type: Object
    }
  },
  methods: {
    generateMarkdown () {
      return marked.parse(_.escape(this.description), {
        breaks: true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list-item--link::before
{
  background-color: var(--v-primary-base);
}

.v-list-item:focus .v-list-item__title,
.v-list-item:hover .v-list-item__title
{
  text-decoration: underline;
}
</style>