<template>
  <loading-card :loading="loading" :height="250">
    <v-card-title>
      {{ $t('page.createFiling.step.caseType.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.createFiling.step.caseType.description') }}
    </v-card-subtitle>
    <v-card-text class="pt-3">
      <v-row>
        <v-col cols="12">
          <c-autocomplete
            v-model="bean.caseType"
            :label="$t('page.createFiling.step.caseType.field.caseType')"
            :items="caseTypes"
            item-text="name"
            item-value="value"
            required
            outlined
            hide-details="auto"
            key="caseType"
            aria-describedby="edd294fc-8d1c-402f-bd77-47536c4df3c5">
            <template #item="{ item }">
              <v-list-item-content class="d-block">
                <v-list-item-title class="font-weight-medium">
                  {{ item.name }}
                </v-list-item-title>
                <div class="d-flex text-minimum-gray font-95" style="max-width:80ch">
                  {{ item.description }}
                </div>
              </v-list-item-content>
            </template>
          </c-autocomplete>
          <field-description id="edd294fc-8d1c-402f-bd77-47536c4df3c5"
                             message-key="page.createFiling.step.field.caseType.description"/>

          <transition name="slide-y-transition" mode="out-in">
            <template v-if="isExistingCaseFiling">
              <div key="caseNumber">
                <c-text-field
                  v-model="bean.caseNumber"
                  :label="$t('courtCase.caseNumber')"
                  maxlength="100"
                  required
                  outlined
                  hide-details="auto"
                  class="mt-4"
                  key="caseNumber"
                  aria-describedby="61267e7f-c358-4186-87ec-58874420b543"/>
                <field-description id="61267e7f-c358-4186-87ec-58874420b543"
                                   message-key="page.createFiling.step.caseType.caseNumber.description"/>
              </div>
            </template>
          </transition>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-end mt-10">
      <c-btn type="primary" class="px-4" @click="save">
        {{ $t('global.continue') }}
      </c-btn>
    </v-card-actions>
  </loading-card>
</template>

<script>
import WorkflowStepMixin from '@/mixins/workflowStep';
import axios from 'axios';
import { scrollTo } from '@/application/utility';

export default {
  mixins: [ WorkflowStepMixin ],
  computed: {
    isExistingCaseFiling () {
      return this.bean.caseType === this.CONST.FILING_CASE_TYPE_EXISTING;
    },
    caseTypes () {
      let caseTypes = [
        {
          value: this.CONST.FILING_CASE_TYPE_NEW,
          name: this.$t('page.createFiling.step.caseType.newCase.title'),
          description: this.$t('page.createFiling.step.caseType.newCase.description'),
          icon: 'mdi-folder-plus'
        },
        {
          value: this.CONST.FILING_CASE_TYPE_EXISTING,
          name: this.$t('page.createFiling.step.caseType.existingCase.title'),
          description: this.$t('page.createFiling.step.caseType.existingCase.description'),
          icon: 'mdi-file-document-edit'
        }
      ];
      return caseTypes;
    }
  },
  created () {
    // reset the caseType fields
    this.bean.caseInstanceUUID = null;
    this.bean.caseType = null;
    this.bean.caseNumber = null;
    this.bean.caseTitle = null;
    this.bean.caseClass = null;
    this.bean.caseAccessible = null;
  },
  methods: {
    validate () {
      this.$store.commit('cove/errors/clear');

      this.$cove.validation.required(this.bean.caseType, 'page.createFiling.step.caseType.field.caseType', 'caseType');

      if (this.isExistingCaseFiling)
      {
        this.$cove.validation.required(this.bean.caseNumber, 'courtCase.caseNumber', 'caseNumber');
      }

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      this.$cove.block();

      let isValid = this.validate();
      if (!isValid)
      {
        this.$cove.unblock();
        scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
        return;
      }

      if (this.isExistingCaseFiling) {
        let query = {
          caseNumber: this.bean.caseNumber,
          fields: '*,caseClassification(*)'
        };
        axios.get(this.$cove.getAPI({ name: 'api.search.caseNumber', params: { courtID: this.bean.courtID }, query }))
          .then((response) => {
            if (response.data !== '')
            {
              this.bean.caseInstanceUUID = response.data.caseInstanceUUID;
              this.bean.caseClass = response.data.caseClassification;
              this.bean.caseTitle = response.data.caseTitle;
              this.bean.caseNumber = response.data.caseNumber;
              this.bean.caseAccessible = response.data.caseAccess;
              this.$emit('save', this.bean);
            }
            else
            {
              this.$cove.validation.addError('caseNumber', 'validation.createFiling.step.caseType.caseNumber.invalid');
            }
          })
          .catch((error) => {
            console.error(error);
            this.$cove.notify({ message: this.$t('page.createFiling.step.caseType.save.error'), color: 'error' });
          })
          .finally(() => {
            this.$cove.unblock();
          });
      }
      else {
        this.bean.caseNumber = null;
        this.$cove.unblock();
        this.$emit('save', this.bean);
      }
    }
  }
};
</script>
