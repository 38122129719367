<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <c-text-field
          v-model="address.line1"
          :label="$t('form.address.field.line1')"
          maxlength="100"
          key="address-line-1"
          outlined
          required
          hide-details="auto"
          aria-describedby="abc42274-0bf0-4b56-b4a6-c180750acc80"/>
        <field-description id="abc42274-0bf0-4b56-b4a6-c180750acc80"
                           message-key="form.address.field.line1.description"/>
      </v-col>
      <v-col cols="12">
        <div class="d-flex align-center">
          <div style="flex:1 1 auto">
            <c-text-field
              v-model="address.line2"
              :label="$t('form.address.field.line2')"
              maxlength="100"
              key="address-line-2"
              outlined
              hide-details="auto"
              aria-describedby="fa51a8ca-ba09-44f4-b9b8-5e0b548799c4"/>
          </div>
          <div v-if="!showMoreAddressFlag" class="pl-4" style="flex:0 0 auto;white-space:no-wrap">
            <button type="button" class="link-button" @click="showMoreAddress">
              {{ $t('form.address.button.showMoreLines') }}
            </button>
          </div>
        </div>
        <div>
          <field-description id="fa51a8ca-ba09-44f4-b9b8-5e0b548799c4"
                             message-key="form.address.field.line2.description"/>
        </div>
      </v-col>
      <transition name="scroll-y-transition">
        <v-row v-if="showMoreAddressFlag" no-gutters>
          <v-col cols="12">
            <c-text-field
              v-model="address.line3"
              :label="$t('form.address.field.line3')"
              maxlength="100"
              id="address-line-3"
              key="address-line-3"
              outlined
              hide-details="auto"
              aria-describedby="a438f3f3-d9f8-4b0b-a844-e18aa4290b98"/>
            <field-description id="a438f3f3-d9f8-4b0b-a844-e18aa4290b98"
                               message-key="form.address.field.line3.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="address.line4"
              :label="$t('form.address.field.line4')"
              maxlength="100"
              key="address-line-4"
              outlined
              hide-details="auto"
              aria-describedby="ee08f393-0b7a-45ef-a37a-d25e51c4785f"/>
            <field-description id="ee08f393-0b7a-45ef-a37a-d25e51c4785f"
                               message-key="form.address.field.line4.description"/>
          </v-col>
        </v-row>
      </transition>
      <v-col cols="12">
        <c-autocomplete
          v-model="address.countryIdentifier"
          :label="$t('form.address.field.country')"
          :items="countries"
          item-text="countryName"
          item-value="countryID"
          :clearable="false"
          @change="changeCountry"
          key="country"
          required
          outlined
          hide-details="auto"
          aria-describedby="d474833a-c157-404a-9932-b47a55530490"/>
        <field-description id="d474833a-c157-404a-9932-b47a55530490"
                           message-key="form.address.field.country.description"/>
      </v-col>
      <transition name="scroll-y-transition">
        <v-row v-if="hasRegions" no-gutters>
          <v-col cols="12" md="4" class="pr-md-2">
            <c-text-field
              v-model="address.city"
              :label="$t('form.address.field.city')"
              key="address-city"
              maxlength="100"
              required
              outlined
              hide-details="auto"
              aria-describedby="a77c1899-88fc-4f4b-bcaa-e12bae80b2ec"/>
            <field-description id="a77c1899-88fc-4f4b-bcaa-e12bae80b2ec"
                               message-key="form.address.field.city.description"/>
          </v-col>
          <v-col cols="12" md="4" class="px-md-2">
            <c-autocomplete
              v-model="address.regionIdentifier"
              :label="$t('form.address.field.region')"
              :items="regions"
              item-text="regionName"
              item-value="regionID"
              key="address-region"
              :clear-icon="null"
              required
              outlined
              hide-details="auto"
              aria-describedby="a9a36029-a670-4bbf-853a-740a24679c62"/>
            <field-description id="a9a36029-a670-4bbf-853a-740a24679c62"
                               message-key="form.address.field.region.description"/>
          </v-col>
          <v-col cols="12" md="4" class="pl-md-2">
            <c-text-field
              v-model="address.zipCode"
              :label="$t('form.address.field.zip')"
              key="address-zip-code"
              maxlength="10"
              required
              outlined
              hide-details="auto"
              aria-describedby="a62d30b4-7723-433b-9b1b-f062d8955fe1"/>
            <field-description id="a62d30b4-7723-433b-9b1b-f062d8955fe1"
                               message-key="form.address.field.zip.description"/>
          </v-col>
        </v-row>
      </transition>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import FieldDescription from '@components/support/FieldDescription';

export default {
  components: { FieldDescription },
  props: {
    address: {
      type: Object
    }
  },
  data () {
    return {
      regions: [],
      showMoreAddressFlag: false
    };
  },
  computed: {
    countries () {
      return this.$store.getters['application/getCountries'];
    },
    defaultCountry () {
      return this.$store.getters['application/getDefaultCountry'];
    },
    hasRegions () {
      return !_.isEmpty(this.regions);
    }
  },
  created () {
    if (this.address.countryIdentifier === null)
    {
      this.address.countryIdentifier = this.defaultCountry.countryID;
    }

    this.regions = this.$store.getters['application/getRegionsByCountry'](this.address.countryIdentifier);

    if (this.address.regionIdentifier === null)
    {
      this.setDefaultRegion();
    }

    if (!_.isNil(this.address.line3) || !_.isNil(this.address.line4))
    {
      this.showMoreAddressFlag = true;
    }

    // TODO no longer need this since no more AJAX loading in here - cleanup all usages
    this.$emit('ready');
  },
  methods: {
    validate () {
      this.$cove.validation.required(this.address.line1, 'form.address.field.line1', 'address-line-1');
      this.$cove.validation.string.maxLength(this.address.line1, 100, 'form.address.field.line1', 'address-line-1');
      this.$cove.validation.string.maxLength(this.address.line2, 100, 'form.address.field.line2', 'address-line-2');
      this.$cove.validation.string.maxLength(this.address.line3, 100, 'form.address.field.line3', 'address-line-3');
      this.$cove.validation.string.maxLength(this.address.line4, 100, 'form.address.field.line4', 'address-line-4');
      this.$cove.validation.required(this.address.countryIdentifier, 'form.address.field.country', 'country');

      if (this.hasRegions)
      {
        this.$cove.validation.required(this.address.city, 'form.address.field.city', 'address-city');
        this.$cove.validation.string.maxLength(this.address.city, 100, 'form.address.field.city', 'address-city');
        this.$cove.validation.required(this.address.regionIdentifier, 'form.address.field.region', 'address-region');
        this.$cove.validation.required(this.address.zipCode, 'form.address.field.zip', 'address-zip-code');
        this.$cove.validation.string.maxLength(this.address.zipCode, 10, 'form.address.field.zip', 'address-zip-code');
      }
    },
    changeCountry () {
      this.regions = this.$store.getters['application/getRegionsByCountry'](this.address.countryIdentifier);
      this.setDefaultRegion();
      if (!this.hasRegions)
      {
        this.address.city = null;
        this.address.zipCode = null;
      }
    },
    setDefaultRegion () {
      let defaultRegion = _.defaultTo(_.find(this.regions, { defaultFlag: true }), null);
      if (defaultRegion !== null)
      {
        this.address.regionIdentifier = defaultRegion.regionID;
      }
      else
      {
        this.address.regionIdentifier = null;
      }
    },
    showMoreAddress () {
      this.showMoreAddressFlag = true;
      setTimeout(() => {
        document.querySelector('#address-line-3').focus();
      }, 1);
    }
  }
};
</script>
