<template>
  <loading-card :loading="loading" class="nav-tab-small">
    <v-card-title>
      {{ $t('page.admin.userAccount.preferences.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.admin.userAccount.preferences.description') }}
    </v-card-subtitle>
    <v-card-text class="mt-3 pb-9">
      <dl class="d-grid">
        <dt>{{ $t('page.admin.userAccount.accountInformation.locale') }}</dt>
        <dd>{{ locale }}</dd>
        <dt>{{ $t('page.admin.userAccount.accountInformation.timezone') }}</dt>
        <dd>{{ timezone }}</dd>
        <dt>{{ $t('page.admin.userAccount.accountInformation.theme') }}</dt>
        <dd>{{ theme }}</dd>
        <dt>{{ $t('page.admin.userAccount.accountInformation.defaultCourt') }}</dt>
        <dd>{{ court }}</dd>
      </dl>
    </v-card-text>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { LoadingCard },
  props: {
    userID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      user: null
    };
  },
  computed: {
    locale () {
      return _.get(this.user, 'locale.displayName', null);
    },
    timezone () {
      return _.get(this.user, 'timezone', null);
    },
    theme () {
      let result = this.$t('page.currentUser.theme.light');
      if (!_.get(this.user, 'lightTheme', true))
      {
        result = this.$t('page.currentUser.theme.dark');
      }

      return result;
    },
    court () {
      return _.get(this.user, 'court.displayName', null);
    }
  },
  created () {
    let url = this.$cove.getAPI({
      name: 'api.user',
      params: {
        userID: this.userID
      },
      query: {
        fields: '*,actor(*),locale(*),court(*)'
      }
    });

    axios.get(url)
      .then((response) => {
        this.user = response.data;
      }).finally(() => {
        this.loading = false;
      });
  }
};
</script>