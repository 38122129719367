<template>
  <div>
    <transition name="scroll-x-reverse-transition">
      <v-card v-show="!loading">
        <v-card-title>
          {{ $t('page.initialRegistration.public.title') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('page.initialRegistration.public.description') }}
        </v-card-subtitle>
        <v-card-text>
          <v-row no-gutters>
            <v-col v-if="!isSingleCourtMode" cols="12" class="pt-2">
              <c-autocomplete :items="courts"
                              v-model="courtID"
                              :label="$t('page.initialRegistration.public.field.court')"
                              item-text="displayName"
                              item-value="resourceID"
                              key="courtID"
                              required
                              outlined
                              hide-details="auto"
                              aria-describedby="af813fd2-d3f2-4b86-8f6f-637141a1c595"/>
              <field-description id="af813fd2-d3f2-4b86-8f6f-637141a1c595"
                                 message-key="page.initialRegistration.public.field.court.description"/>
            </v-col>
            <v-col cols="12">
              <c-text-field
                v-model="caseNumber"
                :label="$t('page.initialRegistration.public.field.caseNumber')"
                maxlength="250"
                key="caseNumber"
                required
                outlined
                hide-details="auto"
                aria-describedby="a5c7baf8-0aca-41b0-a646-85b39d7f0112"/>
              <field-description id="a5c7baf8-0aca-41b0-a646-85b39d7f0112"
                                 message-key="page.initialRegistration.public.field.caseNumber.description"/>
            </v-col>
            <v-col cols="12">
              <section-separator :title="$t('page.initialRegistration.section.requestInfo')" class="mt-n2 mb-4"/>
            </v-col>
            <v-col cols="12">
              <c-file-input
                :label="$t('page.initialRegistration.public.field.referenceFile')"
                v-model="file"
                :required="getUserAccessConfig.requireFile"
                key="file"
                outlined
                hide-details="auto"
                aria-describedby="ad5fdb47-3aa6-4592-8cc8-6224a993d174"/>
              <field-description id="ad5fdb47-3aa6-4592-8cc8-6224a993d174">
                {{ $te('page.initialRegistration.public.field.referenceFile.description') ?
                  $t('page.initialRegistration.public.field.referenceFile.description') : null }}
                <button type="button" class="link-button" @click="openFileTypesPopup">
                  {{ $t('global.viewFileTypes') }}
                </button>
              </field-description>
            </v-col>
            <v-col cols="12">
              <c-textarea
                v-model="comments"
                :label="$t('page.initialRegistration.public.field.comments')"
                :required="getUserAccessConfig.requireComments"
                key="comments"
                outlined
                hide-details="auto"
                aria-describedby="a722e23c-42b3-442d-9a72-4a3130cded98"/>
              <field-description id="a722e23c-42b3-442d-9a72-4a3130cded98"
                                 message-key="page.initialRegistration.public.field.comments.description"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <c-btn type="primary" @click="save">
            {{ $t('page.initialRegistration.button.submitRequest') }}
          </c-btn>
        </v-card-actions>
      </v-card>
    </transition>

    <file-types-popup v-model="fileTypesPopupVisible" :fileType="CONST.DOCUMENT_TYPE_USER_REQUEST"/>
  </div>
</template>

<script>
import _ from 'lodash';
import FieldDescription from '@components/support/FieldDescription';
import SectionSeparator from '@components/support/SectionSeparator';
import FileTypesPopup from '@components/support/FileTypesPopup';
import UserAccess from '@/application/userAccess';
import { scrollTo, validateFile } from '@/application/utility';

export default {
  components: { FieldDescription, SectionSeparator, FileTypesPopup },
  props: {
    forwardCourtID: {
      type: String,
      default: null
    },
    forwardCaseNumber: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      fileTypesPopupVisible: false,
      courtID: null,
      caseNumber: null,
      comments: null,
      file: null
    };
  },
  computed: {
    courts () {
      return this.$store.state.application.courts;
    },
    isSingleCourtMode () {
      return this.$store.getters['application/isSingleCourtMode'];
    },
    defaultCourtID () {
      return this.$store.getters['application/getDefaultCourtID'];
    },
    getUserAccessConfig () {
      return UserAccess.getUserAccessConfig(this.CONST.USER_TYPE_PUBLIC);
    }
  },
  created () {
    this.courtID = this.defaultCourtID;

    if (this.forwardCourtID !== null)
    {
      this.courtID = this.forwardCourtID;
    }
    if (this.forwardCaseNumber !== null)
    {
      this.caseNumber = this.forwardCaseNumber;
    }
  },
  methods: {
    openFileTypesPopup () {
      this.fileTypesPopupVisible = true;
    },
    validate () {
      this.$store.commit('cove/errors/clear');

      this.$cove.validation.required(this.courtID, 'page.initialRegistration.public.field.court', 'courtID');
      this.$cove.validation.required(this.caseNumber, 'page.initialRegistration.public.field.caseNumber', 'caseNumber');
      this.$cove.validation.string.maxLength(this.caseNumber, 250, 'page.initialRegistration.public.field.caseNumber', 'caseNumber');

      if (this.getUserAccessConfig.requireFile)
      {
        this.$cove.validation.required(this.file, 'page.initialRegistration.public.field.referenceFile', 'file');
      }

      if (this.file !== null)
      {
        validateFile(this.file, this.CONST.DOCUMENT_TYPE_USER_REQUEST, 'page.initialRegistration.public.field.referenceFile', 'file');
      }

      if (this.getUserAccessConfig.requireComments)
      {
        this.$cove.validation.required(this.comments, 'page.initialRegistration.public.field.comments', 'comments');
      }

      this.$cove.validation.string.maxLength(this.comments, 2000, 'page.initialRegistration.public.field.comments', 'comments');

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      this.$cove.block({ delay: 0 });

      let isValid = this.validate();
      if (!isValid)
      {
        this.$cove.unblock();
        scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
        return;
      }

      this.$emit('submitRequest', {
        file: this.file,
        bean: _.omitBy({
          userTypeID: this.CONST.USER_TYPE_PUBLIC,
          courtID: this.courtID,
          caseNumber: this.caseNumber,
          note: this.comments
        }, _.isNil)
      });
    }
  }
};
</script>
