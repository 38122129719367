<template>
  <page-wrapper :title="$t('page.language.title')">
    <v-row>
      <v-col md="8" lg="7" xl="6">
        <v-card>
          <v-card-title>
            {{ $t('page.language.selection.title') }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('page.language.description') }}
          </v-card-subtitle>
          <v-card-text>
            <form>
              <v-radio-group v-model="locale" class="mt-0">
                <v-radio v-for="l in locales" :key="l.localeCode"
                         class="my-2"
                         :label="l.displayName"
                         :value="l.localeCode"/>
              </v-radio-group>
            </form>
          </v-card-text>
          <v-card-actions class="justify-end">
            <c-btn type="primary" @click="save">
              {{ $t('application.button.save') }}
            </c-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import PageWrapper from '@components/support/PageWrapper';
import { changeLocale } from '@/application/application';
import PageMixin from '@/mixins/page';

export default {
  components: { PageWrapper },
  mixins: [ PageMixin ],
  data () {
    return {
      locale: this.$i18n.locale
    };
  },
  computed: {
    locales () {
      return this.$store.state.application.locales;
    }
  },
  methods: {
    save () {
      this.$cove.block();
      changeLocale(this.locale)
        .then(() => {
          this.$cove.notify({ message: this.$t('page.language.update.success'), color: 'success' });
        })
        .finally(() => {
          this.$cove.unblock();
        });
    }
  }
};
</script>
