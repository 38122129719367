<template>
  <page-wrapper :title="$t('page.announcements.title')">
    <v-row>
      <v-col v-if="isAdmin" class="d-print-none d-md-none" cols="12">
        <manage-announcements-panel :show-all="showAll" @toggle="toggleShowAll"/>
      </v-col>
      <v-col cols="12" md="8" xl="7">
        <v-card v-if="!loading && announcements.length === 0">
          <v-card-text class="text-minimum-gray font-italic text-center" style="min-height:64px;line-height:32px">
            {{ $t('global.noneFound') }}
          </v-card-text>
        </v-card>

        <transition name="fade-transition" mode="out-in">
          <div :key="`${page.number} ${showAll}`">
            <announcement-card
              v-for="announcement in announcements"
              :key="announcement.resourceID"
              :announcement="announcement"
              class="mb-6"/>

            <paging-controls
              v-if="!loading"
              solo
              hide-on-single-page
              class="mt-12"
              :page="page"
              @changePage="changePage"/>
          </div>
        </transition>
      </v-col>
      <v-col v-if="isAdmin" class="d-none d-md-block" md="4" xl="3">
        <manage-announcements-panel :show-all="showAll" @toggle="toggleShowAll"/>
      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import PageWrapper from '@components/support/PageWrapper';
import PagingControls from '@components/support/PagingControls';
import ManageAnnouncementsPanel from '@components/support/ManageAnnouncementsPanel';
import AnnouncementCard from '@components/support/AnnouncementCard';
import { replaceIgnoreDuplicate } from '@/application/utility';
import _ from 'lodash';
import axios from 'axios';
import PageMixin from '@/mixins/page';

export default {
  components: { PageWrapper, ManageAnnouncementsPanel, AnnouncementCard, PagingControls },
  mixins: [ PageMixin ],
  data () {
    return {
      announcements: [],
      page: {
        number: 0,
        size: 5,
        totalElements: 0,
        totalPages: 0
      },
      loading: false,
      showAll: false
    };
  },
  created () {
    let p = this.$route.query.p;
    if (p !== undefined)
    {
      this.page.number = parseInt(p);
    }

    let all = this.$route.query.all;
    if (all !== undefined && all === 'true' && this.isAdmin)
    {
      this.showAll = true;
    }

    this.loadAnnouncements(false);
  },
  computed: {
    hasSession () {
      return this.$store.state.user.hasSession;
    },
    isAdmin () {
      return this.$store.getters['user/isAdmin'];
    }
  },
  methods: {
    changePage (newPage) {
      this.page.number = newPage;
      this.loadAnnouncements(true);
    },
    loadAnnouncements (replaceURL) {
      this.$cove.block();
      this.loading = true;
      this.announcements = [];
      this.scrollTo('html');

      let main = document.getElementById('main-content');
      if (main !== null)
      {
        main.focus();
      }

      let query = {
        page: this.page.number,
        size: this.page.size,
        sort: ['startDate,desc']
      };

      if (this.showAll && this.isAdmin)
      {
        query.showAll=this.showAll;
      }

      axios.get(this.$cove.getAPI({ name: 'api.announcements', query }))
        .then((response) => {
          this.announcements = _.get(response, 'data._embedded.results', []);
          this.page.number = response.data.page.number;
          this.page.totalPages = response.data.page.totalPages;
          this.page.totalElements = response.data.page.totalElements;

          let query = {
            p: String(this.page.number)
          };

          if (this.showAll && this.isAdmin)
          {
            query.all = 'true';
          }

          if (replaceURL)
          {
            replaceIgnoreDuplicate({ name: 'announcements', query });
          }

          this.markRead();
        })
        .finally(() => {
          this.loading = false;
          this.$cove.unblock();
        });
    },
    markRead () {
      if (this.hasSession && !this.showAll)
      {
        let promises = [];
        this.announcements.forEach((a) => {
          if (!a.read)
          {
            let url = this.$cove.getAPI({ name: 'api.announcement.markRead', params: { id: a.resourceID } });
            promises.push(axios.post(url));
          }
        });

        // if we marked any read, update the polling data
        if (promises.length > 0)
        {
          Promise.all(promises).then(() => {
            this.$store.dispatch('user/poll');
          });
        }
      }
    },
    toggleShowAll (v) {
      this.showAll = v;
      this.page.number = 0;
      this.loadAnnouncements(true);
    }
  }
};
</script>