<template>
  <loading-card :loading="loading" :height="250">
    <v-card-title>
      {{ $t('page.createFiling.step.details.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.createFiling.step.details.description') }}
    </v-card-subtitle>
    <v-card-text class="pt-3">
      <v-row>
        <v-col cols="12">
          <c-text-field
            v-model="bean.referenceName"
            :label="$t('page.createFiling.step.details.field.refName')"
            key="referenceName"
            maxlength="100"
            outlined
            required
            hide-details="auto"
            aria-describedby="a94d2ef6-5d61-4398-b313-4d99f0b1e64a"/>
          <field-description id="a94d2ef6-5d61-4398-b313-4d99f0b1e64a"
                             message-key="page.createFiling.step.details.field.refName.description"/>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-end mt-10">
      <c-btn type="primary" @click="save">
        {{ $t('global.continue') }}
      </c-btn>
    </v-card-actions>
  </loading-card>
</template>

<script>
import WorkflowStepMixin from '@/mixins/workflowStep';
import { scrollTo } from '@/application/utility';

export default {
  mixins: [ WorkflowStepMixin ],
  created () {
    this.bean.referenceName = this.bean.filingType.name;
  },
  computed: {
    courts () {
      return this.$store.state.application.courts;
    }
  },
  methods: {
    isAbbreviated () {
      return this.bean.filingType.filingCode === this.CONST.FILING_CODE_EXTERNAL_IDENTIFIER_ABBREVIATED;
    },
    validate () {
      this.$store.commit('cove/errors/clear');

      this.$cove.validation.required(this.bean.referenceName, 'page.createFiling.step.details.field.refName', 'referenceName');

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      let isValid = this.validate();
      if (!isValid)
      {
        scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
      }
      else
      {
        this.$emit('save', this.bean);
      }
    }
  }
};
</script>
