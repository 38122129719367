<template>
  <div>
    <slot name="title">
      <v-container class="page-container py-5" :fluid="fluid">
        <v-row>
          <v-col cols="12" class="pb-0">
            <h1 class="text-h4 page-title page-focus" tabindex="-1">
              {{ title }}
              <transition name="fade-transition" mode="out-in">
                <span v-if="hasSubtitle" class="page-subtitle" :key="subtitle">
                  {{ subtitle }}
                </span>
              </transition>
            </h1>
          </v-col>
        </v-row>
      </v-container>
    </slot>
    <v-container class="page-container" :fluid="fluid">
      <slot/>
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    fluid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasSubtitle () {
      return !_.isNil(this.subtitle);
    }
  },
  watch: {
    title: {
      immediate: true,
      handler () {
        this.updatePageTitle();
      }
    },
    subtitle: {
      immediate: true,
      handler () {
        this.updatePageTitle();
      }
    }
  },
  methods: {
    updatePageTitle () {
      let fullTitle = '';

      if (!_.isNil(this.title))
      {
        fullTitle += this.title;
      }

      if (!_.isNil(this.subtitle))
      {
        fullTitle += ' - ' + this.subtitle;
      }

      fullTitle += (fullTitle !== '' ? ' - ' : '') + this.$t('application.project.name');

      document.title = fullTitle;
    }
  }
};
</script>