<template>
  <page-wrapper :title="$t('page.myTasks.title')">

    <search-result-template :config="config">

      <template #table-title>
        {{ $t('page.myTasks.table') }}
      </template>

      <template #v-data-table.item.caseNumber="{ item }">
        <div v-if="item.caseNumber" style="white-space: nowrap">
          <router-link :to="{ name: 'caseView', params: {
            courtID: getCourtByExternalID(item.courtID).resourceID,
            caseUUID: item.caseInstanceID
          }}">
            {{ item.caseNumber }}
          </router-link>
        </div>
      </template>
      <template #v-data-table.item.dueDate="{ value }">
        <div style="min-width:100px">
          {{ value | formatDate }}
        </div>
      </template>

      <template #v-data-table.item.ticklerID="{ value }">
        <router-link :to="{ name: 'work-task', params: { taskID: value }}">
          {{ $t('global.view') }}
        </router-link>
      </template>

      <template #nav-drawer-filter-body>
        <v-row no-gutters class="mt-4">
          <v-col v-if="!isSingleCourtMode" cols="12">
            <c-autocomplete
              :items="courts"
              v-model="searchDTO.courtID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('courtCase.court')"
              outlined
              key="courtID"
              hide-details="auto"
              aria-describedby="6db9ddbf-90d3-451c-94b7-38a37db7d2ed"/>
            <field-description id="6db9ddbf-90d3-451c-94b7-38a37db7d2ed" message-key="page.myTasks.court.description"/>
          </v-col>

          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.caseNumber"
              :label="$t('courtCase.caseNumber')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="a9c88380-2730-436e-b518-875dbfbd3e68"/>
            <field-description id="a9c88380-2730-436e-b518-875dbfbd3e68" message-key="page.myTasks.caseNumber.description"/>
          </v-col>

          <v-col cols="12" v-if="!isSingleCaseLocationMode">
            <c-autocomplete
              :items="caseLocations"
              v-model="searchDTO.locationID"
              item-text="locationName"
              item-value="locationID"
              :label="$t('court.location')"
              :no-data-text="$t('global.courtFirst')"
              outlined
              key="locationID"
              hide-details="auto"
              aria-describedby="73f629a8-c1af-474b-a80c-61b8ad6c4bd1"/>
            <field-description id="73f629a8-c1af-474b-a80c-61b8ad6c4bd1" message-key="page.myTasks.location.description"/>
          </v-col>

          <v-col cols="12">
            <c-autocomplete
              :items="taskTypes"
              v-model="searchDTO.taskTypeID"
              item-text="taskTypeName"
              item-value="taskTypeID"
              :label="$t('task.type.short')"
              outlined
              key="taskTypeID"
              hide-details="auto"
              aria-describedby="77a1d7bf-b0d6-42ba-986f-a11698e36334"/>
            <field-description id="77a1d7bf-b0d6-42ba-986f-a11698e36334" message-key="page.myTasks.taskType.description"/>
          </v-col>

          <v-col cols="12">
            <date-range-picker
              :label="$t('task.dueDate')"
              :labelStart="$t('task.dueDateFrom')"
              :labelEnd="$t('task.dueDateTo')"
              keyPrefix="dueDate"
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.dueDateChoice"
              :startDate.sync="searchDTO.dueDateStart"
              :endDate.sync="searchDTO.dueDateEnd"
              aria-describedby="a3d826de-3889-41b5-9760-f71a407a6074"
              offer-future-dates
              offer-past-dates/>
            <field-description id="a3d826de-3889-41b5-9760-f71a407a6074" message-key="page.myTasks.dueDate.description"/>
          </v-col>

          <v-col cols="12">
            <c-autocomplete
              :items="taskStatuses"
              v-model="searchDTO.taskStatusIDs"
              multiple
              item-text="taskStatusName"
              item-value="taskStatusID"
              :label="$t('task.status.short')"
              outlined
              key="taskStatusID"
              hide-details="auto"
              aria-describedby="01bbeb87-9b7a-4d80-bb2f-f991e4ff9bcc">
              <template #selection="{ item }">
                <div class="portal-dropdown-chip">
                  {{ item.taskStatusName }}
                </div>
              </template>
            </c-autocomplete>
            <field-description id="01bbeb87-9b7a-4d80-bb2f-f991e4ff9bcc" message-key="page.myTasks.taskStatus.description"/>
          </v-col>

          <v-col cols="12">
            <c-checkbox
              v-model="searchDTO.excludeClosed"
              :label="$t('page.myTasks.excludeClosed')"
              aria-describedby="cb5aa8e7-e647-402e-a27d-a8fd9d34761b"/>
            <field-description id="cb5aa8e7-e647-402e-a27d-a8fd9d34761b" message-key="page.myTasks.excludeClosed.description"/>
          </v-col>

        </v-row>
      </template>
    </search-result-template>

  </page-wrapper>
</template>

<script>

import SearchResultTemplate from '@components/support/template/SearchResultTemplate';
import SearchResultTemplateMixin from '@/mixins/searchResultTemplate';
import _ from 'lodash';
import axios from 'axios';
import { dateToEndOfDay, dateToStartOfDay } from '@/application/application';

export default {
  components: { SearchResultTemplate },
  mixins: [ SearchResultTemplateMixin ],
  data () {
    return {
      searchDTO: {
        sort: {
          sortBy: 'dueDate',
          sortDesc: false
        },
        courtID: null,
        caseNumber: null,
        locationID: null,
        taskTypeID: null,
        dueDateChoice: null,
        dueDateStart: null,
        dueDateEnd: null,
        taskStatusIDs: [],
        excludeClosed: true
      },
      taskTypes: [],
      taskStatuses: []
    };
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.task.types' }))
      .then((response) => {
        this.taskTypes = _.defaultTo(response.data, []);
      });
    axios.get(this.$cove.getAPI({ name: 'api.task.statuses' }))
      .then((response) => {
        this.taskStatuses = _.defaultTo(response.data, []);
      });
  },
  methods: {
    getItemKey () {
      return 'resourceID';
    },
    getRouteName () {
      return 'work-tasks';
    },
    getSearchAPIName () {
      return 'api.search.user.tasks';
    },
    getHeaders () {
      let headers = [];

      if (!this.isSingleCourtMode) {
        headers.push({ text: this.$t('courtCase.court'), value: 'courtAbbreviation', sortable: true });
      }
      headers.push({ text: this.$t('courtCase.caseNumber'), value: 'caseNumber', sortable: true });
      if (!this.isSingleCaseLocationMode) {
        headers.push({ text: this.$t('court.location'), value: 'locationName', sortable: true });
      }
      headers.push({ text: this.$t('task.type.short'), value: 'taskType', sortable: true });
      headers.push({ text: this.$t('task.dueDate'), value: 'dueDate', sortable: true });
      headers.push({ text: this.$t('task.status.short'), value: 'taskStatus', sortable: true });
      headers.push({ text: this.$t('task.priority.short'), value: 'taskPriority', sortable: true });
      headers.push({ text: this.$t('global.view'), value: 'ticklerID', sortable: false, align: 'right' });

      return headers;
    },
    addSearchCriteria (query) {

      if (!_.isNil(this.searchDTO.sort.sortBy))
      {
        query.sort = `${this.searchDTO.sort.sortBy},${this.searchDTO.sort.sortDesc ? 'desc' : 'asc'}`;
      }

      if (!_.isNil(this.searchDTO.courtID))
      {
        query.courtID = this.searchDTO.courtID;
      }

      if (!_.isNil(this.searchDTO.caseNumber))
      {
        query.caseNumber = this.searchDTO.caseNumber;
      }

      if (!_.isNil(this.searchDTO.locationID))
      {
        query.locationID = this.searchDTO.locationID;
      }

      if (!_.isNil(this.searchDTO.taskTypeID))
      {
        query.typeID = this.searchDTO.taskTypeID;
      }

      if (!_.isNil(this.searchDTO.dueDateStart))
      {
        query.dueDateFrom = dateToStartOfDay(this.$cove.parseDate(this.searchDTO.dueDateStart)).toISO();
      }

      if (!_.isNil(this.searchDTO.dueDateEnd))
      {
        query.dueDateTo = dateToEndOfDay(this.$cove.parseDate(this.searchDTO.dueDateEnd)).toISO();
      }

      if (!_.isNil(this.searchDTO.taskStatusIDs))
      {
        query.statusIDs = this.searchDTO.taskStatusIDs;
      }

      query.excludeClosed = this.searchDTO.excludeClosed;

      return query;
    },
    validateSearchCriteria (searchDTO)
    {
      this.$cove.validation.date.rejectIfInvalid(searchDTO.dueDateStart, 'task.dueDateFrom', 'dueDate-startDate');
      this.$cove.validation.date.rejectIfInvalid(searchDTO.dueDateEnd, 'task.dueDateTo', 'dueDate-endDate');
      this.$cove.validation.date.rejectIfBefore(searchDTO.dueDateEnd, searchDTO.dueDateStart, 'task.dueDateTo', 'task.dueDateFrom',
        'dueDate-endDate');
    }
  }
};
</script>
