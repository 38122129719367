<template>
  <div>
    <loading-card :loading="loading" class="nav-tab-medium">
      <template #default>
        <v-card-title>
          {{ $t('page.currentUser.subscription.title') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('page.currentUser.subscription.description') }}
        </v-card-subtitle>
        <v-card-text>

          <!-- subscription via parent org -->
          <mini-card v-if="hasOrganizationSubscription" class="mt-4 mb-8">
            <v-card-text class="pa-6">
              <div class="d-flex" style="gap:24px">
                <div class="flex-grow-0">
                  <v-icon color="primary" style="font-size:50px">
                    mdi-calendar-sync
                  </v-icon>
                </div>
                <v-divider vertical/>
                <div class="flex-grow-1 text-minimum-gray align-self-center" style="max-width:300px;">
                  {{ $t('page.currentUser.subscription.userHasOrgSub') }}
                </div>
              </div>
            </v-card-text>
          </mini-card>

          <!-- user's subscription -->
          <mini-card v-else-if="hasSubscription" class="mt-4 mb-8">
            <v-card-text>
              <div class="d-flex pa-2" style="gap:24px">
                <div class="flex-grow-0">
                  <v-icon color="primary" style="font-size:50px">
                    mdi-calendar-sync
                  </v-icon>
                </div>
                <v-divider vertical/>
                <div class="flex-grow-1" style="width:300px">
                  <dl class="d-grid">
                    <dt>{{ $t('subscription.type') }}</dt>
                    <dd>{{ subscription.subscriptionType.displayName }}</dd>
                    <dt>{{ $t('subscription.status') }}</dt>
                    <dd>{{ subscription.subscriptionStatus.displayName }}</dd>
                    <dt v-if="isActiveSubscription">{{ $t('subscription.renewalDate') }}</dt>
                    <dt v-if="!isActiveSubscription">{{ $t('subscription.terminationDate') }}</dt>
                    <dd>{{ renewalDate | formatDate }}</dd>
                    <dt v-if="isActiveSubscription">{{ $t('subscription.renewalAmount') }}</dt>
                    <dd v-if="isActiveSubscription">{{ subscription.renewalAmount | formatCurrency }}</dd>
                  </dl>
                </div>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end mt-4">
              <c-btn v-if="!isGrantedSubscription && isActiveSubscription" type="danger" @click="cancelSubscription">
                {{ $t('page.currentUser.subscription.cancelSubscription') }}
              </c-btn>
              <template v-if="isCancelledSubscription">
                <c-btn type="primary" @click="reactivateSubscription">
                  {{ $t('subscription.reactivateSubscription') }}
                </c-btn>
              </template>
            </v-card-actions>
          </mini-card>

          <cutout-area v-else-if="!hasPaymentToken" class="mt-4 mb-8">
            {{ $t('page.currentUser.subscription.paymentMethod') }}
          </cutout-area>

          <cutout-area v-else class="mt-4 mb-8">
            <v-icon color="primary" style="font-size:50px">
              mdi-calendar-sync
            </v-icon>
            <template #action>
              <c-btn type="primary" @click="purchaseSubscription">
                {{ $t('page.manageOrg.subscription.button.purchase') }}
              </c-btn>
            </template>
          </cutout-area>

        </v-card-text>
      </template>
    </loading-card>

    <purchase-sub-seats-popup v-model="purchasePopupVisible" :userID="user.userID" @checkoutComplete="loadData"/>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import CutoutArea from '@components/support/CutoutArea';
import LoadingCard from '@components/support/LoadingCard';
import MiniCard from '@components/support/MiniCard';
import PurchaseSubSeatsPopup from '@components/support/PurchaseSubSeatsPopup';
import { calculateSubscriptionRenewalDate } from '@/application/application';

export default {
  components: { LoadingCard, CutoutArea, PurchaseSubSeatsPopup, MiniCard },
  data () {
    return {
      loading: true,
      subscription: null,
      hasOrganizationSubscription: false,
      purchasePopupVisible: false,
      hasPaymentToken: false
    };
  },
  computed: {
    hasSubscription () {
      return !_.isNil(this.subscription);
    },
    renewalDate () {
      return calculateSubscriptionRenewalDate(this.subscription.subscriptionDetail.startDate);
    },
    isGrantedSubscription () {
      return _.get(this.subscription, 'subscriptionType.grantedSubscription', false);
    },
    isActiveSubscription () {
      return _.get(this.subscription, 'subscriptionStatus.resourceID', null) === this.CONST.SUBSCRIPTION_STATUS_ACTIVE;
    },
    isCancelledSubscription () {
      return _.get(this.subscription, 'subscriptionStatus.resourceID', null) === this.CONST.SUBSCRIPTION_STATUS_CANCELLED;
    },
    user () {
      return this.$store.state.user;
    }
  },
  created () {
    this.loadData();
  },
  methods: {
    loadData () {
      this.loading = true;
      // eslint-disable-next-line max-len
      let fields = '*,subscription(*,subscriptionStatus(*),subscriptionType(*)),hasOrganizationSubscription,paymentToken(*),hasOrganizationPaymentToken';
      axios.get(this.$cove.getAPI({ name: 'api.user.currentuser',
        query: { fields } }))
        .then((response) => {
          this.subscription = _.get(response, 'data.subscription', null);
          this.hasOrganizationSubscription = _.get(response, 'data.hasOrganizationSubscription', false);
          this.hasPaymentToken = !_.isNil(_.get(response, 'data.paymentToken')) ||
            _.get(response, 'data.hasOrganizationPaymentToken', false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    purchaseSubscription () {
      this.purchasePopupVisible = true;
    },
    cancelSubscription () {
      this.$cove.confirm({ message: this.$t('page.currentUser.subscription.confirmCancelSubscription') })
        .then((confirm) => {
          if (confirm)
          {
            this.$cove.block({ delay: 0 });
            axios.delete(this.$cove.getAPI({ name: 'api.subscription', params: { id: this.subscription.resourceID } }))
              . then(() => {
                this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
                this.loadData();
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    },
    reactivateSubscription () {
      this.$cove.confirm({ message: this.$t('subscription.reactivateSubscription.confirmUser') })
        .then((confirm) => {
          if (confirm)
          {
            this.$cove.block({ delay: 0 });
            axios.patch(this.$cove.getAPI({ name: 'api.subscription.reactivate', params: { id: this.subscription.resourceID } }))
              .then(() => {
                this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
                this.loadData();
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    }
  }
};
</script>