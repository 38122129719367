<template>
  <div>
    <transition name="scroll-y-transition" mode="out-in">
      <form :key="searchDTO.advanced">
        <v-row no-gutters>
          <v-col cols="12">
            <section-separator :title="$t('searchType.citation.criteriaSeparator')" class="my-4"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.citation.citationNumber"
              :label="$t('page.searchCriteria.citation.field.citationNumber')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="ad88afdc-b6a4-4980-901d-c172765a1c22"/>
            <field-description id="ad88afdc-b6a4-4980-901d-c172765a1c22"
                               message-key="page.searchCriteria.citation.field.citationNumber.description"/>
          </v-col>
          <template v-if="searchDTO.advanced">
            <v-col cols="12">
              <c-autocomplete
                v-model="searchDTO.citation.actorCategoryID"
                :items="actorCategories"
                :label="$t('page.searchCriteria.citation.field.citedPartyType')"
                item-value="actorCategoryID"
                :clearable="false"
                outlined
                hide-details="auto"
                aria-describedby="aa9224e4-d1f3-4c71-a504-23164b2801a8"/>
              <field-description id="aa9224e4-d1f3-4c71-a504-23164b2801a8"
                                 message-key="page.searchCriteria.citation.field.citedPartyType.description"/>
            </v-col>
            <template v-if="searchDTO.citation.actorCategoryID === CONST.ACTOR_CATEGORY_PERSON">
              <v-col cols="12">
                <c-text-field
                  v-model="searchDTO.citation.partyFirstName"
                  :label="$t('page.searchCriteria.citation.field.citedPartyFirstName')"
                  maxlength="250"
                  outlined
                  hide-details="auto"
                  aria-describedby="a72413f2-ce74-405e-bef8-9bf156ca5458"/>
                <field-description id="a72413f2-ce74-405e-bef8-9bf156ca5458"
                                   message-key="page.searchCriteria.citation.field.citedPartyFirstName.description"/>
              </v-col>
              <v-col cols="12">
                <c-autocomplete
                  v-model="searchDTO.citation.partyFirstNameQueryTypeID"
                  :label="$t('page.searchCriteria.citation.field.citedPartyFirstNameSearchType')"
                  :items="queryTypes"
                  outlined
                  class="ml-8"
                  :clearable="false"
                  hide-details="auto"
                  aria-describedby="a7cb8694-da81-4056-a1fd-3b236528f4b7"/>
                <field-description id="a7cb8694-da81-4056-a1fd-3b236528f4b7" class="ml-8"
                                   message-key="global.queryType.description"/>
              </v-col>
              <v-col cols="12">
                <c-text-field
                  v-model="searchDTO.citation.partyLastName"
                  :label="$t('page.searchCriteria.citation.field.citedPartyLastName')"
                  maxlength="250"
                  outlined
                  hide-details="auto"
                  aria-describedby="a31023b0-3b14-448f-94f5-48fcdf826995"/>
                <field-description id="a31023b0-3b14-448f-94f5-48fcdf826995"
                                   message-key="page.searchCriteria.citation.field.citedPartyLastName.description"/>
              </v-col>
              <v-col cols="12">
                <c-autocomplete
                  v-model="searchDTO.citation.partyLastNameQueryTypeID"
                  :label="$t('page.searchCriteria.citation.field.citedPartyLastNameSearchType')"
                  :items="queryTypes"
                  outlined
                  class="ml-8"
                  :clearable="false"
                  hide-details="auto"
                  aria-describedby="aa0d0d1a-ced8-42b8-8494-95442eec4b1e"/>
                <field-description id="aa0d0d1a-ced8-42b8-8494-95442eec4b1e" class="ml-8"
                                   message-key="global.queryType.description"/>
              </v-col>
            </template>
            <template v-else-if="searchDTO.citation.actorCategoryID === CONST.ACTOR_CATEGORY_ORGANIZATION">
              <v-col cols="12">
                <c-text-field
                  v-model="searchDTO.citation.partyName"
                  :label="$t('page.searchCriteria.citation.field.citedOrgName')"
                  outlined
                  maxlength="250"
                  hide-details="auto"
                  aria-describedby="ad398f96-815e-4d07-a9a0-2ff8ca6fa51d"/>
                <field-description id="ad398f96-815e-4d07-a9a0-2ff8ca6fa51d"
                                   message-key="page.searchCriteria.citation.field.citedOrgName.description"/>
              </v-col>
              <v-col cols="12">
                <c-autocomplete
                  v-model="searchDTO.citation.partyNameQueryTypeID"
                  :label="$t('page.searchCriteria.citation.field.citedOrgNameSearchType')"
                  :items="queryTypes"
                  outlined
                  class="ml-8"
                  :clearable="false"
                  hide-details="auto"
                  aria-describedby="ab97f1eb-ec82-41c3-9eb1-677ec2c9b67d"/>
                <field-description id="ab97f1eb-ec82-41c3-9eb1-677ec2c9b67d" class="ml-8"
                                   message-key="global.queryType.description"/>
              </v-col>
            </template>
          </template>
          <template v-else>
            <v-col cols="12">
              <c-text-field
                v-model="searchDTO.citation.partyName"
                :label="$t('page.searchCriteria.citation.field.citedPartyName')"
                outlined
                maxlength="250"
                hide-details="auto"
                aria-describedby="a936e7c4-1ee4-42b9-a9e8-d913af342d77"/>
              <field-description id="a936e7c4-1ee4-42b9-a9e8-d913af342d77"
                                 message-key="page.searchCriteria.citation.field.citedPartyName.description"/>
            </v-col>
          </template>
          <v-col cols="12">
            <date-range-picker
              :label="$t('page.searchCriteria.citation.field.violationDate')"
              :labelStart="$t('page.searchCriteria.citation.field.violationDateFrom')"
              :labelEnd="$t('page.searchCriteria.citation.field.violationDateTo')"
              keyPrefix="violationDate"
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.citation.violationDateChoice"
              :startDate.sync="searchDTO.citation.violationDateStart"
              :endDate.sync="searchDTO.citation.violationDateEnd"
              aria-describedby="a9ab3a5d-2a8e-4a54-b26a-1edf9231dd18"/>
            <field-description id="a9ab3a5d-2a8e-4a54-b26a-1edf9231dd18"
                               message-key="page.searchCriteria.citation.field.violationDate.description"/>
          </v-col>
        </v-row>

        <nested-case-search-criteria :searchDTO="searchDTO" :searchType="searchType" class="mt-0"/>

      </form>
    </transition>
  </div>
</template>

<script>
import SectionSeparator from '@components/support/SectionSeparator';
import FieldDescription from '@components/support/FieldDescription';
import DateRangePicker from '@components/support/DateRangePicker';
import NestedCaseSearchCriteria from '@components/support/search/NestedCaseSearchCriteria';
import SearchCriteriaMixin from '@/mixins/searchCriteria';

export default {
  components: { SectionSeparator, FieldDescription, NestedCaseSearchCriteria, DateRangePicker },
  mixins: [ SearchCriteriaMixin ],
  watch: {
    'searchDTO.advanced' (newValue) {
      if (newValue)
      {
        this.searchDTO.citation.partyName = null;
        this.searchDTO.citation.actorCategoryID = this.CONST.ACTOR_CATEGORY_PERSON;
        this.searchDTO.citation.partyFirstNameQueryTypeID = this.getDefaultQueryType(true);
        this.searchDTO.citation.partyLastNameQueryTypeID= this.getDefaultQueryType(true);
        this.searchDTO.citation.partyNameQueryTypeID = this.getDefaultQueryType(true);
      }
    },
    'searchDTO.citation.actorCategoryID' () {
      this.searchDTO.citation.partyName = null;
      this.searchDTO.citation.partyFirstName = null;
      this.searchDTO.citation.partyLastName = null;
      this.searchDTO.citation.partyNameQueryTypeID = this.getDefaultQueryType(true);
      this.searchDTO.citation.partyFirstNameQueryTypeID = this.getDefaultQueryType(true);
      this.searchDTO.citation.partyLastNameQueryTypeID = this.getDefaultQueryType(true);
    }
  }
};
</script>
