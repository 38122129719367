<template>
  <v-card class="d-print-none">
    <v-card-title class="pb-2">
      {{ $t('page.announcements.adminCard.title') }}
    </v-card-title>
    <v-card-text>
      <div style="font-weight:500;font-size:1.1rem">
        {{ $t('page.announcements.adminCard.showAll') }}
      </div>
      <div class="d-flex align-center mt-1">
        <div class="text-minimum-gray font-95" style="flex:1 1 auto;">
          {{ $t('page.announcements.adminCard.showAll.description') }}
        </div>
        <div class="text-right" style="flex:0 0 64px">
          <v-switch inset
                    style="margin:0 -10px 0 0 !important;display:inline-flex"
                    hide-details
                    :input-value="showAll"
                    @change="toggle"/>
        </div>
      </div>

      <v-divider class="my-4"/>

      <div class="text-right">
        <c-btn block type="primary" :to="{ name: 'manageAnnouncement' }">
          {{ $t('page.announcements.adminCard.addAnnouncement') }}
        </c-btn>
      </div>

    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    showAll: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle (v) {
      this.$emit('toggle', v);
    }
  }
};
</script>