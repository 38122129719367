<template>
  <page-wrapper :title="$t('global.error')">
    <v-row>
      <v-col md="7">
        <v-card>
          <v-card-title>
            {{ $t('popup.serverError.title') }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('popup.serverError.description') }}
          </v-card-subtitle>
          <v-card-text>
            <dl class="d-grid" style="grid-template-columns: 30% 70%;">
              <dt>{{ $t('popup.serverError.field.url') }}</dt>
              <dd>{{ url }}</dd>
              <dt>{{ $t('popup.serverError.field.method') }}</dt>
              <dd>{{ method }}</dd>
              <dt>{{ $t('popup.serverError.field.statusCode') }}</dt>
              <dd>{{ statusCode }}</dd>
              <dt>{{ $t('global.error.dateUTC') }}</dt>
              <dd>{{ timestamp }}</dd>
              <template v-if="errorReference">
                <dt>{{ $t('global.error.errorReference') }}</dt>
                <dd>{{ errorReference }}</dd>
              </template>
            </dl>
          </v-card-text>
          <v-card-actions class="mt-10 justify-end">
            <c-btn type="primary" @click="copyToClipboard">
              {{ $t('global.copyToClipboard') }}
            </c-btn>
            <c-btn type="primary" :to="{ name: 'home' }" style="min-width:120px">
              {{ $t('global.returnHome') }}
            </c-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import PageWrapper from '@components/support/PageWrapper';

export default {
  components: { PageWrapper },
  props: {
    url: {
      type: String,
      default: null
    },
    method: {
      type: String,
      default: null
    },
    statusCode: {
      type: Number,
      default: 500
    },
    timestamp: {
      type: String,
      default: null
    },
    errorReference: {
      type: String,
      default: null
    }
  },
  methods: {
    copyToClipboard () {
      navigator.clipboard.writeText(JSON.stringify({ ...this.$props }, null, 4));
    }
  }
};
</script>