<template>
  <loading-card :loading="loading" :height="250">
    <v-card-title>
      {{ $t('page.createFiling.step.summary.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.createFiling.step.summary.description') }}
    </v-card-subtitle>
    <v-card-text>
      <dl class="d-grid grid-4060">
        <dt>{{ $t('page.createFiling.step.summary.field.court') }}</dt>
        <dd>{{ court.displayName }}</dd>
        <dt>{{ $t('page.createFiling.step.summary.field.caseType') }}</dt>
        <dd>{{ caseType }}</dd>
        <template v-if="!newCaseFiling">
          <dt>{{ $t('page.createFiling.step.summary.field.caseNumber') }}</dt>
          <dd>{{ bean.caseNumber }}</dd>
          <dt>{{ $t('page.createFiling.step.summary.field.caseTitle') }}</dt>
          <template v-if="bean.caseAccessible">
            <dd>{{ bean.caseTitle }}</dd>
          </template>
          <template v-else>
            <dd>{{ $t('courtCase.secure') }}</dd>
          </template>
        </template>
        <dt>{{ $t('page.createFiling.step.summary.field.caseClass') }}</dt>
        <template v-if="newCaseFiling">
          <dd>{{ bean.filingType.createCaseClass.displayName }}</dd>
        </template>
        <template v-else>
          <dd>{{ bean.caseClass.displayName }}</dd>
        </template>
        <dt>{{ $t('page.createFiling.step.summary.field.filingType') }}</dt>
        <dd>{{ leadDocketEntryName }}</dd>
        <dt>{{ $t('page.createFiling.step.summary.field.referenceName') }}</dt>
        <dd>{{ bean.referenceName }}</dd>
      </dl>
    </v-card-text>
    <v-card-actions class="justify-end mt-10">
      <c-btn type="primary" class="px-4" @click="save">
        {{ $t('page.createFiling.step.summary.button.createFiling') }}
      </c-btn>
    </v-card-actions>
  </loading-card>
</template>

<script>
import WorkflowStepMixin from '@/mixins/workflowStep';

export default {
  mixins: [ WorkflowStepMixin ],
  computed: {
    newCaseFiling () {
      return this.bean.caseType === this.CONST.FILING_CASE_TYPE_NEW;
    },
    court () {
      return this.$store.getters['application/getCourtByID'](this.bean.courtID);
    },
    caseType () {
      return this.bean.caseType === this.CONST.FILING_CASE_TYPE_NEW ?
        this.$t('global.filing.caseType.newCase') : this.$t('global.filing.caseType.existingCase');
    },
    leadDocketEntryName () {
      let deSubtype = this.bean.filingType.docketEntrySubtype;
      return deSubtype.docketEntryType.docketEntryTypeName + ' - ' + deSubtype.docketEntrySubTypeName;
    }
  },
  methods: {
    save () {
      this.$emit('save');
    },
    goBack () {
      this.$emit('goBack');
    }
  }
};
</script>
