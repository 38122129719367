<template>
  <loading-card :loading="caseInstance === null">
    <template #default>
      <v-card-title>
        {{ $t('page.caseView.actionsCard.title') }}
      </v-card-title>
      <v-card-text>
        <div class="mt-n3">
          <c-btn v-if="!isVerifiedParty && !isKioskMode" block type="primary" class="mt-3" @click="requestCaseAccess">
            {{ $t('page.caseView.actionsCard.requestCaseAccess') }}
          </c-btn>
          <v-fade-transition>
            <c-btn v-if="showPrintableViewButton" block type="primary" class="mt-3" @click="togglePrintableView">
              {{ $t('page.caseView.actionsCard.printableView') }}
            </c-btn>
          </v-fade-transition>
        </div>
      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import UserAccess from '@/application/userAccess';
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { LoadingCard },
  props: {
    courtID: {
      type: String
    },
    caseInstance: {
      type: Object
    },
    isVerifiedParty: {
      type: Boolean
    }
  },
  data () {
    return {
      ready: false,
      showPrintableViewButton: true
    };
  },
  computed: {
    hasSession () {
      return this.$store.state.user.hasSession;
    },
    isKioskMode () {
      return this.$store.getters['application/isKioskMode'];
    }
  },
  watch: {
    caseInstance: {
      immediate: true,
      handler (newValue) {
        // we aren't loading anything here (yet) but we do need to clear the card and update its display based upon the case and if the user
        // is verified or not.
        if (!_.isNil(newValue))
        {
          this.ready = true;
        }
        else
        {
          this.ready = false;
        }
      }
    }
  },
  methods: {
    requestCaseAccess () {
      UserAccess.forwardToRequestAccess(this.CONST.USER_TYPE_PUBLIC, { courtID: this.courtID, caseNumber: this.caseInstance.caseNumber });
    },
    togglePrintableView () {
      this.showPrintableViewButton = false;
      this.$emit('togglePrintableView');
    }
  }
};
</script>