<template>
  <page-wrapper :title="$t('page.createFiling.title')">
    <div class="d-flex mb-4">
      <div style="flex: 0 0 auto;max-width:100%">
        <v-card class="ma-0 py-2 px-3">
          <breadcrumbs :steps="steps" v-model="stepID"/>
        </v-card>
      </div>
    </div>

    <v-row>
      <v-col md="7" xl="6">
        <validation-error-panel/>
        <workflow-wrapper>
          <step-court v-if="stepID === 'court'"
                      key="court"
                      :originalBean="bean"
                      @ready="stepReady"
                      @save="saveCourt"/>
          <step-case-type v-if="stepID === 'caseType'"
                          key="caseType"
                          :originalBean="bean"
                          @ready="stepReady"
                          @save="saveCaseType"/>
          <step-entry-mode v-if="stepID === 'entryMode'"
                           key="entryMode"
                           :originalBean="bean"
                           @ready="stepReady"
                           @save="saveEntryMode"/>
          <step-filing-type v-if="stepID === 'filingType'"
                            key="filingType"
                            :originalBean="bean"
                            @ready="stepReady"
                            @save="saveFilingType"/>
          <step-details v-if="stepID === 'details'"
                        key="details"
                        :originalBean="bean"
                        @ready="stepReady"
                        @save="saveDetails"/>
          <step-summary v-if="stepID === 'summary'"
                        key="summary"
                        :originalBean="bean"
                        @ready="stepReady"
                        @goBack="goBack"
                        @save="saveSummary"/>
        </workflow-wrapper>
      </v-col>
    </v-row>

  </page-wrapper>
</template>

<script>
import PageWrapper from '@components/support/PageWrapper';
import WorkflowWrapper from '@components/support/WorkflowWrapper';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import StepCourt from '@components/support/createFiling/StepCourt';
import StepCaseType from '@components/support/createFiling/StepCaseType';
import StepEntryMode from '@components/support/createFiling/StepEntryMode';
import StepFilingType from '@components/support/createFiling/StepFilingType';
import StepDetails from '@components/support/createFiling/StepDetails';
import StepSummary from '@components/support/createFiling/StepSummary';
import Breadcrumbs from '@components/support/Breadcrumbs';
import PageMixin from '@/mixins/page';
import _ from 'lodash';
import axios from 'axios';

export default {
  components: { PageWrapper, WorkflowWrapper, StepCourt, StepCaseType, StepEntryMode, StepFilingType, StepDetails, StepSummary,
    ValidationErrorPanel, Breadcrumbs },
  mixins: [ PageMixin ],
  data () {
    return {
      stepID: this.isSingleCourtMode ? 'caseType' : 'court',
      bean: {
        courtID: this.$store.getters['application/getDefaultCourtID'],
        caseInstanceUUID: null,
        caseType: null,
        caseClass: null,
        caseNumber: null,
        caseTitle: null,
        caseAccessible: null,
        guidedEntry: null,
        referenceName: null,
        filingType: null
      }
    };
  },
  computed: {
    isSingleCourtMode () {
      return this.$store.getters['application/isSingleCourtMode'];
    },
    steps () {
      let steps = [];
      if (!this.isSingleCourtMode)
      {
        steps.push({ stepID: 'court', name: this.$t('page.createFiling.steps.court') });
      }

      steps.push({ stepID: 'caseType', name: this.$t('page.createFiling.steps.caseType') });
      steps.push({ stepID: 'entryMode', name: this.$t('page.createFiling.steps.entryMode') });
      steps.push({ stepID: 'filingType', name: this.$t('page.createFiling.steps.filingType') });
      steps.push({ stepID: 'details', name: this.$t('page.createFiling.steps.details') });
      steps.push({ stepID: 'summary', name: this.$t('page.createFiling.steps.summary') });

      return steps;
    }
  },
  methods: {
    stepReady () {
      this.$cove.unblock();
    },
    goBack () {
      let currentIndex = _.findIndex(this.steps, { stepID: this.stepID });
      if (currentIndex > 0)
      {
        this.stepID = this.steps[currentIndex - 1].stepID;
      }
    },
    saveCourt (bean) {
      this.bean.courtID = bean.courtID;
      this.stepID = 'caseType';
    },
    saveCaseType (bean) {
      this.bean.caseInstanceUUID = bean.caseInstanceUUID;
      this.bean.caseType = bean.caseType;
      this.bean.caseNumber = bean.caseNumber;
      this.bean.caseClass = bean.caseClass;
      this.bean.caseTitle = bean.caseTitle;
      this.bean.caseAccessible = bean.caseAccessible;
      this.stepID = 'entryMode';
    },
    saveEntryMode (guidedEntry) {
      this.bean.guidedEntry = guidedEntry;
      this.bean.filingType = null; // clear this out in case they went back far through the steps and are advancing again
      this.stepID = 'filingType';
    },
    saveFilingType (filingType) {
      this.bean.filingType = filingType;
      this.stepID = 'details';
    },
    saveDetails (bean) {
      this.bean.referenceName = bean.referenceName;
      this.stepID = 'summary';
    },
    saveSummary () {
      this.$cove.block({ delay: 0 });

      let bean = {
        courtID: this.bean.courtID,
        filingTypeID: this.bean.filingType.portalFilingTypeID,
        caseNumber: this.bean.caseNumber,
        referenceName: this.bean.referenceName
      };

      let url = this.$cove.getAPI({ name: 'api.filings' });
      axios.post(url, bean)
        .then((response) => {
          let filingID = response.data.resourceID;
          this.$router.push({ name: 'manage-filing', params: { filingID } });
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
        })
        .finally(() => {
          this.$cove.unblock();
        });
    }
  }
};
</script>