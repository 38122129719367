<template>
  <loading-card :loading="previewReceipt === null">
    <template #default>
      <v-card-title>
        {{ $t('page.documentCart.checkOutCard.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.documentCart.checkOutCard.description') }}
      </v-card-subtitle>

      <v-card-text class="pt-2"/>

      <v-card-actions class="justify-end">
        <!-- Payment Processor Button -->
        <template v-if="hasValidPaymentProcessorButtonImage">
          <v-btn class="px-0 elevation-1 rounded" @click="checkoutWithPaymentProcessor">
            <img :src="paymentProcessorButtonImageLink()" style="height:36px;border-radius:4px;" alt=""/>
            <span class="d-sr-only">
              {{ $t('page.documentCart.checkoutButton') }}
            </span>
          </v-btn>
        </template>
        <template v-else>
          <!-- Fallback button to be displayed if the payment processor image link cannot be found -->
          <c-btn type="primary" class="px-4" @click="checkoutWithPaymentProcessor">
            {{ $t('page.documentCart.checkoutButton') }}
          </c-btn>
        </template>
      </v-card-actions>

    </template>
  </loading-card>
</template>

<script>
import LoadingCard from '@components/support/LoadingCard';
import _ from 'lodash';

export default {
  components: { LoadingCard },
  props: {
    previewReceipt: {
      type: Object,
      default: null
    }
  },
  computed: {
    hasValidPaymentProcessorButtonImage () {
      return !_.isNil(this.paymentProcessorButtonImageLink());
    }
  },
  methods: {
    checkoutWithPaymentProcessor () {
      this.$emit('checkout');
    },
    paymentProcessorButtonImageLink () {
      let paymentClientName = this.$store.getters['application/getAppProperty'](this.CONST.APP_PROPERTY_PAYMENT_CLIENT);
      try
      {
        return require('@/assets/paymentClient/' + paymentClientName + '/checkout.png');
      }
      catch (e)
      {
        // Catch and eat exception if the image can't be found; A fallback button will be displayed instead
        return null;
      }
    }
  }
};
</script>