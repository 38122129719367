<template>
  <div>
    <transition name="scroll-x-reverse-transition">
      <v-card>
        <v-card-title>
          {{ $t('page.initialRegistration.attorney.title') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('page.initialRegistration.attorney.description') }}
        </v-card-subtitle>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" class="pt-2">
              <c-autocomplete
                v-model="regionIdentifier"
                :label="$t('page.initialRegistration.attorney.field.barRegion')"
                :items="regions"
                item-text="regionName"
                item-value="regionID"
                key="region"
                required
                outlined
                hide-details="auto"
                aria-describedby="abd82ee8-a154-42d4-a72b-57e19edc7fcd"/>
              <field-description id="abd82ee8-a154-42d4-a72b-57e19edc7fcd"
                                 message-key="page.initialRegistration.attorney.field.barRegion.description"/>
            </v-col>
            <v-col cols="12">
              <c-text-field
                v-model="barID"
                :label="$t('page.initialRegistration.attorney.field.barID')"
                maxlength="50"
                key="barID"
                required
                outlined
                hide-details="auto"
                aria-describedby="aa9cec8a-7b73-4b7d-bac2-1bb1bcfc3222"/>
              <field-description id="aa9cec8a-7b73-4b7d-bac2-1bb1bcfc3222"
                                 message-key="page.initialRegistration.attorney.field.barID.description"/>
            </v-col>
            <v-col cols="12">
              <section-separator :title="$t('page.initialRegistration.section.requestInfo')" class="mt-n2 mb-4"/>
            </v-col>
            <v-col cols="12">
              <c-file-input
                :label="$t('page.initialRegistration.attorney.field.referenceFile')"
                v-model="file"
                key="file"
                :required="getUserAccessConfig.requireFile"
                outlined
                hide-details="auto"
                aria-describedby="a48123be-e7a5-43ba-829e-c0807ebc8a29"/>
              <field-description id="a48123be-e7a5-43ba-829e-c0807ebc8a29">
                {{ $te('page.initialRegistration.attorney.field.referenceFile.description') ?
                  $t('page.initialRegistration.attorney.field.referenceFile.description') : null }}
                <button type="button" class="link-button" @click="openFileTypesPopup">
                  {{ $t('global.viewFileTypes') }}
                </button>
              </field-description>
            </v-col>
            <v-col cols="12">
              <c-textarea
                v-model="comments"
                :label="$t('page.initialRegistration.attorney.field.comments')"
                :required="getUserAccessConfig.requireComments"
                key="comments"
                outlined
                hide-details="auto"
                aria-describedby="adcc7099-804f-4255-9aa2-076a07fece95"/>
              <field-description id="adcc7099-804f-4255-9aa2-076a07fece95"
                                 message-key="page.initialRegistration.attorney.field.comments.description"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <c-btn type="primary" @click="save">
            {{ $t('page.initialRegistration.button.submitRequest') }}
          </c-btn>
        </v-card-actions>
      </v-card>
    </transition>

    <file-types-popup v-model="fileTypesPopupVisible" :fileType="CONST.DOCUMENT_TYPE_USER_REQUEST"/>
  </div>
</template>

<script>
import _ from 'lodash';
import FieldDescription from '@components/support/FieldDescription';
import SectionSeparator from '@components/support/SectionSeparator';
import FileTypesPopup from '@components/support/FileTypesPopup';
import UserAccess from '@/application/userAccess';
import { scrollTo, validateFile } from '@/application/utility';

export default {
  components: { FieldDescription, SectionSeparator, FileTypesPopup },
  data () {
    return {
      fileTypesPopupVisible: false,
      regionIdentifier: null,
      barID: null,
      comments: null,
      file: null
    };
  },
  computed: {
    getUserAccessConfig () {
      return UserAccess.getUserAccessConfig(this.CONST.USER_TYPE_ATTORNEY);
    },
    defaultCountry () {
      return this.$store.getters['application/getDefaultCountry'];
    },
    regions () {
      return this.$store.getters['application/getRegionsByCountry'](this.defaultCountry.countryID);
    }
  },
  created () {
    let defaultRegion = this.$store.getters['application/getDefaultRegionByCountry'](this.defaultCountry.countryID);
    if (defaultRegion !== null)
    {
      this.regionIdentifier = defaultRegion.regionID;
    }
  },
  methods: {
    openFileTypesPopup () {
      this.fileTypesPopupVisible = true;
    },
    validate () {
      this.$store.commit('cove/errors/clear');
      this.$cove.validation.required(this.regionIdentifier, 'page.initialRegistration.attorney.field.barRegion', 'region');
      this.$cove.validation.required(this.barID, 'page.initialRegistration.attorney.field.barID', 'barID');

      if (this.getUserAccessConfig.requireFile)
      {
        this.$cove.validation.required(this.file, 'page.initialRegistration.attorney.field.referenceFile', 'file');
      }

      if (this.file !== null)
      {
        validateFile(this.file, this.CONST.DOCUMENT_TYPE_USER_REQUEST, 'page.initialRegistration.attorney.field.referenceFile', 'file');
      }

      if (this.getUserAccessConfig.requireComments)
      {
        this.$cove.validation.required(this.comments, 'page.initialRegistration.attorney.field.comments', 'comments');
      }

      this.$cove.validation.string.maxLength(this.comments, 2000, 'page.initialRegistration.attorney.field.comments', 'comments');

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      this.$cove.block({ delay: 0 });

      let isValid = this.validate();
      if (!isValid)
      {
        this.$cove.unblock();
        scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
        return;
      }

      this.$emit('submitRequest', {
        file: this.file,
        bean: _.omitBy({
          userTypeID: this.CONST.USER_TYPE_ATTORNEY,
          barRegionIdentifier: this.regionIdentifier,
          barNumber: this.barID,
          note: this.comments
        }, _.isNil)
      });
    }
  }
};
</script>