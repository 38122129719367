import * as core from '@/application/userAccess-core';

let custom = {};
try {
  let empty = ''; // suppresses needless webpack warnings since it's less strict with dynamic paths
  custom = require(`@/application/userAccess-custom${empty}`);
} catch (err) {
  // do nothing
}

let userAccess = Object.assign({}, core, custom);

export default userAccess;