<template>
  <div>
    <transition name="scroll-x-reverse-transition">
      <v-card>
        <v-card-title>
          {{ $t('page.initialRegistration.address.title') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('page.initialRegistration.address.description') }}
        </v-card-subtitle>
        <v-card-text class="mt-4">
          <address-form :address="address" ref="addressForm"/>
        </v-card-text>
        <v-card-actions>
          <div v-if="!required" style="flex: 1 1 auto">
            <c-btn @click="advance">
              {{ $t('global.skipThisStep') }}
            </c-btn>
          </div>
          <div class="text-right" style="flex: 1 1 auto">
            <c-btn type="primary" @click="save">
              {{ $t('global.continue') }}
            </c-btn>
          </div>
        </v-card-actions>
      </v-card>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import AddressForm from '@/components/support/AddressForm';
import { scrollTo } from '@/application/utility';

export default {
  components: { AddressForm },
  props: {
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      address: {
        line1: null,
        line2: null,
        line3: null,
        line4: null,
        countryIdentifier: null,
        city: null,
        regionIdentifier: null,
        zipCode: null
      }
    };
  },
  methods: {
    advance () {
      this.$store.commit('cove/errors/clear');
      this.$emit('nextStep');
    },
    save () {
      this.$cove.block({ delay: 0 });

      let isValid = this.validate();
      if (!isValid)
      {
        this.$cove.unblock();
        scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
        return;
      }

      axios.patch(this.$cove.getAPI({ name: 'api.user.currentuser' }), { mailingAddress: this.address })
        .then(() => {
          return this.$store.dispatch('user/reload')
            .then(() => {
              this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
              this.advance();
            });
        }).finally(() => {
          this.$cove.unblock();
        });
    },
    validate () {
      this.$store.commit('cove/errors/clear');
      this.$refs.addressForm.validate();
      return !this.$store.getters['cove/errors/hasErrors']();
    }
  }
};
</script>