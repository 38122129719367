<template>
  <c-dialog
    :value="value"
    width="600"
    close-key="cove.general.cancel"
    title-key="popup.orgAddUser.title"
    @input="close">

    <validation-error-panel :error-scope="errorScope" class="ma-4 mb-2"/>

    <v-fade-transition mode="out-in">
      <div v-if="step === 0" class="px-8 pt-4" key="step0">
        <section-separator :title="$t('popup.orgAddUser.section.userSearch')" class="mb-4"/>
        <c-text-field
          v-model="emailAddress"
          :label="$t('popup.orgAddUser.field.email')"
          type="email"
          maxlength="250"
          required
          :key="`${this.errorScope}:emailAddress`"
          outlined
          hide-details="auto"
          aria-describedby="a7340b8c-936d-44de-b82f-b14df8b42da7"/>
        <field-description id="a7340b8c-936d-44de-b82f-b14df8b42da7"
                           message-key="popup.orgAddUser.field.email.description"/>
      </div>
      <div v-else class="px-8 pt-4" key="step1">
        <section-separator :title="$t('popup.orgAddUser.section.userInformation')"/>
        <dl class="d-grid">
          <dt>{{ $t('popup.orgAddUser.firstName') }}</dt>
          <dd>{{ user.actor.firstName }}</dd>
          <dt>{{ $t('popup.orgAddUser.lastName') }}</dt>
          <dd>{{ user.actor.lastName }}</dd>
          <dt>{{ $t('popup.orgAddUser.username') }}</dt>
          <dd>{{ user.username }}</dd>
          <dt>{{ $t('popup.orgAddUser.email') }}</dt>
          <dd>{{ user.actor.contacts.primaryEmail }}</dd>
        </dl>

        <section-separator :title="$t('popup.orgAddUser.section.options')" class="mt-2"/>
        <c-checkbox
          v-model="payment"
          :label="$t('popup.orgAddUser.field.paymentAccess')"
          :disabled="!editPaymentAccess"
          class="mt-3"
          hide-details="auto"
          aria-describedby="abfadb4f-69c9-457e-afa2-5a57a5d6a7b1"/>
        <field-description id="abfadb4f-69c9-457e-afa2-5a57a5d6a7b1"
                           message-key="popup.orgAddUser.field.paymentAccess.description"/>

        <template v-if="displaySubscriptionAccess">
          <c-checkbox
            v-model="subscription"
            :label="$t('popup.orgAddUser.field.subscriptionAccess')"
            :disabled="!editSubscriptionAccess"
            class="mt-n1"
            hide-details="auto"
            aria-describedby="a6709025-209a-416e-b5db-230151abb61b"/>
          <field-description id="a6709025-209a-416e-b5db-230151abb61b"
                             message-key="popup.orgAddUser.field.subscriptionAccess.description"/>
        </template>

        <c-checkbox
          v-model="admin"
          :label="$t('popup.orgAddUser.field.admin')"
          class="mt-n1"
          hide-details="auto"
          aria-describedby="a0ed4616-d904-4c40-a8b2-0e722debdda9"/>
        <field-description id="a0ed4616-d904-4c40-a8b2-0e722debdda9"
                           message-key="popup.orgAddUser.field.admin.description"/>

      </div>
    </v-fade-transition>

    <template #actions-right>
      <v-scroll-x-reverse-transition mode="out-in">
        <c-btn v-if="step === 0" type="primary" @click="searchUser" key="nextButton">
          {{ $t('global.next') }}
        </c-btn>
        <c-btn v-else type="primary" @click="save" key="saveButton">
          {{ $t('global.save') }}
        </c-btn>
      </v-scroll-x-reverse-transition>
    </template>
  </c-dialog>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import SectionSeparator from '@components/support/SectionSeparator';
import FieldDescription from '@components/support/FieldDescription';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';

export default {
  components: { FieldDescription, SectionSeparator, ValidationErrorPanel },
  props: {
    value: {
      type: Boolean
    },
    organizationID: {
      type: String
    }
  },
  data () {
    return {
      loading: false,
      errorScope: 'addUserPopup',
      step: 0,
      user: null,
      emailAddress: null,
      payment: false,
      subscription: false,
      admin: false,
      editPaymentAccess: false,
      editSubscriptionAccess: false,
      displaySubscriptionAccess: false
    };
  },
  watch: {
    value (newValue) {
      if (!newValue)
      {
        // reset all the data
        this.step = 0;
        this.user = null;
        this.emailAddress = null;
        this.payment = false;
        this.subscription = false;
        this.admin = false;
        this.editPaymentAccess = false;
        this.editSubscriptionAccess = false;
        this.displaySubscriptionAccess = false;

        this.$store.commit('cove/errors/clear', this.errorScope);
      }
    }
  },
  methods: {
    validateSearch () {
      this.$cove.validation.startScope(this.errorScope);
      this.$store.commit('cove/errors/clear');

      this.$cove.validation.required(this.emailAddress, 'popup.orgAddUser.field.email', 'emailAddress');
      if (!_.isEmpty(this.emailAddress) && !this.CONST.REGEX_VALID_EMAIL.test(this.emailAddress))
      {
        this.$cove.validation.addError('emailAddress', 'page.currentUser.error.invalidEmail',
          [this.$t('popup.orgAddUser.field.email')]);
      }

      let isValid = !this.$store.getters['cove/errors/hasErrors']();
      this.$cove.validation.endScope();

      return isValid;
    },
    searchUser () {
      let isValid = this.validateSearch();
      if (isValid)
      {
        let url = this.$cove.getAPI({
          name: 'api.organization.users.newUserSearch',
          query: {
            organizationID: this.organizationID,
            email: this.emailAddress
          }
        });

        axios.get(url, { errorScope: this.errorScope })
          .then((response) => {
            let data = response.data;
            this.user = data.user;
            this.editPaymentAccess = data.editPaymentAccess;
            this.editSubscriptionAccess = data.editSubscriptionAccess;
            this.displaySubscriptionAccess = data.displaySubscriptionAccess;
            this.step++;
          });
      }
    },
    save () {
      this.$cove.block({ delay: 0 });
      let url = this.$cove.getAPI({ name: 'api.organization.users' });
      let payload = {
        userID: this.user.resourceID,
        organizationID: this.organizationID,
        payment: this.payment,
        subscription: this.subscription,
        admin: this.admin
      };

      axios.post(url, payload)
        .then(() => {
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
          this.$emit('newUserAdded');
          this.close();
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    close () {
      this.$emit('input', false);
    }
  }
};
</script>