<template>
  <loading-card :loading="loading">
    <v-card-title>
      {{ $t('page.manageFiling.filingInfo.stepEdit.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.manageFiling.filingInfo.stepEdit.description') }}
    </v-card-subtitle>
    <v-card-text class="pt-2">
      <v-row no-gutters>
        <v-col cols="12">
          <c-autocomplete
            v-model="bean.filedOnBehalfOf"
            :label="$t('filing.filedOnBehalfOf')"
            :items="parties"
            key="fobo"
            item-text="sortName"
            item-value="uuid"
            return-object
            multiple
            :required="isNewCaseFiling"
            outlined
            hide-details="auto"
            aria-describedby="1e9dc693-d97f-45ff-b63b-9a974e3343ef">
            <template #selection="{ item }">
              <div class="portal-dropdown-chip">
                {{ item.sortName }}
              </div>
            </template>
          </c-autocomplete>
          <field-description id="1e9dc693-d97f-45ff-b63b-9a974e3343ef"
                             message-key="page.manageFiling.filingInfo.stepRole.field.filedOnBehalfOf.description"/>
        </v-col>
        <v-col v-if="!isNewCaseFiling" cols="12">
          <c-text-field
            v-model="bean.filedByOther"
            :label="$t('filing.filedByOther')"
            key="filedByOther"
            maxlength="250"
            outlined
            hide-details="auto"
            aria-describedby="a6328b8b-c538-419d-a244-f81dc9b495e6"/>
          <field-description id="a6328b8b-c538-419d-a244-f81dc9b495e6"
                             message-key="page.manageFiling.filingInfo.stepRole.field.filedByOther.description"/>
        </v-col>
        <v-col cols="12">
          <c-text-field
            v-model="bean.filing.referenceName"
            :label="$t('filing.referenceName')"
            key="referenceName"
            maxlength="250"
            outlined
            required
            hide-details="auto"
            aria-describedby="b748e0c8-889e-49fd-93dd-8b3799804421"/>
          <field-description id="b748e0c8-889e-49fd-93dd-8b3799804421"
                             message-key="page.manageFiling.filingInfo.stepRole.field.referenceName.description"/>
        </v-col>
        <v-col cols="12">
          <c-textarea
            v-model="bean.filing.filingNote"
            :label="$t('filing.filingNote')"
            outlined
            maxlength="2000"
            hide-details="auto"
            aria-describedby="e6d7f304-617a-45e8-8c74-5e0f3d73242d"/>
          <field-description id="e6d7f304-617a-45e8-8c74-5e0f3d73242d"
                             message-key="page.manageFiling.filingInfo.stepRole.field.filingNote.description"/>
        </v-col>
        <v-col cols="12">
          <c-checkbox
            v-model="bean.filing.confidential"
            :label="$t('filing.confidential')"
            class="mt-0"
            hide-details="auto"
            aria-describedby="a64e4ca6-1d27-4431-b39f-465f4dc9df89"/>
          <field-description id="a64e4ca6-1d27-4431-b39f-465f4dc9df89"
                             message-key="page.manageFiling.filingInfo.stepRole.field.confidential.description"/>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-end mt-5">
      <c-btn type="primary" @click="save">
        {{ $t('global.save') }}
      </c-btn>
      <c-btn @click="$emit('cancel')">
        {{ $t('global.cancel') }}
      </c-btn>
    </v-card-actions>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import WorkflowStepMixin from '@/mixins/workflowStep';

export default {
  mixins: [ WorkflowStepMixin ],
  data () {
    return {
      parties: []
    };
  },
  computed: {
    filingID () {
      return this.$store.state.filing.filingID;
    },
    isNewCaseFiling () {
      return this.$store.getters['filing/isNewCaseFiling'];
    }
  },
  methods: {
    loadData () {
      return this.loadParties();
    },
    validate () {
      this.$store.commit('cove/errors/clear');

      if (this.isNewCaseFiling)
      {
        this.$cove.validation.required(this.bean.filedOnBehalfOf, 'filing.filedOnBehalfOf', 'fobo');
      }
      else if (_.isEmpty(this.bean.filedOnBehalfOf) && _.isNil(this.bean.filedByOther))
      {
        this.$cove.validation.addError('filedByRequired', 'page.manageFiling.filingInfo.filedByRequired',
          [this.$t('filing.filedOnBehalfOf'), this.$t('filing.filedByOther')]);
      }

      this.$cove.validation.required(this.bean.filing.referenceName, 'filing.referenceName', 'referenceName');

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      let isValid = this.validate();
      if (!isValid)
      {
        this.scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
      }
      else
      {
        this.$emit('save', this.bean);
      }
    },
    loadParties () {
      let url = this.$cove.getAPI({
        name: 'api.filing.filerCandidates',
        params: {
          id: this.filingID
        }
      });

      return axios.get(url).
        then((response) => {
          this.parties = _.defaultTo(response.data, []);

          // convert any current values into properly selected dropdown elements
          let selected = [];
          _.forEach(this.bean.filedOnBehalfOf, (fobo) => {
            let select = null;
            if (!_.isNil(fobo.caseParty))
            {
              select = _.find(this.parties, { uuid: fobo.caseParty.resourceID });
            }
            else if (!_.isNil(fobo.existingCaseParty))
            {
              select = _.find(this.parties, { uuid: fobo.existingCaseParty.resourceID });
            }

            if (!_.isNil(select))
            {
              selected.push(select);
            }
          });

          this.bean.filedOnBehalfOf = selected;

          // preselect all filing parties if this is a new case filing and nothing has been saved yet
          if (this.isNewCaseFiling && _.isEmpty(this.bean.filedOnBehalfOf))
          {
            let filingParties = _.filter(this.parties, { involvementTypeID: String(this.CONST.INVOLVMENT_TYPE_FILING_PARTY) });
            this.bean.filedOnBehalfOf = filingParties;
          }
        });
    }
  }
};
</script>