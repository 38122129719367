import cove from '@ctrack/cove';
import axios from 'axios';
import _ from 'lodash';
import CONST from '@/application/constants';
import store from '../plugins/store';
import { generateDisplayName, generateSortName, getCountryForPhoneNumber, formatPhoneInput, formatPhoneForSaving }
  from '@/application/utility';

/**
 * Defines several configuration points that can be customized when creating new party records.
 *
 * @returns
 */
export function getPartyFieldConfig ()
{
  return {
    requireAddress: true,
    requirePhone: false,
    requireEmail: false
  };
}

/**
 * Returns the abbreviated filing config which determines which fields are required for submission of an abbreviated filing
 *
 * @returns {{requireFilingStatement: boolean, requireDocument: boolean, requireFilingStatementOrDocument: boolean}}
 */
export function getAbbreviatedFilingConfig ()
{
  return {
    requireFilingStatement: false,
    requireDocument: false,
    requireFilingStatementOrDocument: true
  };
}

/**
 * Returns true if the abbreviated filing is ready for submission by checking the abbreviatedFilingConfig to see what is required
 *
 * @param filing
 * @returns {boolean}
 */
export function isAbbreviatedFilingReadyForSubmission (filing)
{
  let config = getAbbreviatedFilingConfig();
  let filingStatement = _.get(filing, 'leadDocketEntry.docketEntryNote', null);
  let documentLinkID = _.get(filing, 'leadDocketEntry.documents[0].documentLinkID', null);
  return (config.requireFilingStatement && !_.isNil(filingStatement))
      || (config.requireDocument && !_.isNil(documentLinkID))
      || (config.requireFilingStatementOrDocument && (!_.isNil(filingStatement) || !_.isNil(documentLinkID)));
}

/**
 * Gets the name of the filedOnBehalfOf object from one of three fields, checked in order:
 * <ol>
 *     <li>Other Name</li>
 *     <li>(New) Case Party</li>
 *     <li>Existing Case Party</li>
 * </ol>
 * <br>
 * Returns null if all the above fields are null
 * @param filedOnBehalfOf
 * @returns Filed on behalf of name or null
 */
export function getFiledOnBehalfOfName (filedOnBehalfOf)
{
  let result = null;

  if (!_.isNil(filedOnBehalfOf.otherName))
  {
    result = filedOnBehalfOf.otherName;
  }
  else if (!_.isNil(filedOnBehalfOf.caseParty))
  {
    result = filedOnBehalfOf.caseParty.actor.displayName;
  }
  else if (!_.isNil(filedOnBehalfOf.existingCaseParty))
  {
    result = filedOnBehalfOf.existingCaseParty.displayName;
  }

  return result;
}
/**
 * Loads the party information that will be needed to perform subsequent edit actions. Returns a promise that resolves with the party data.
 *
 * @param {String} partyID
 * @returns
 */
export function loadPartyForEditing (partyID)
{
  let url = cove.getAPI({
    name: 'api.filing.caseParty',
    params: { id: partyID },
    // eslint-disable-next-line max-len
    query: { fields: '**,actor(*,aliasType(*),mailingAddress(*)),legalRepresenterMaps(*,existingLegalRepresenter(*),legalRepresenter(*,actor(*,mailingAddress(*))))' }
  });

  return axios.get(url)
    .then((response) => {
      return response.data;
    });
}

/**
 * Updates the party role information.
 *
 * @param {Object} param0
 * @returns
 */
export function patchPartyRole ({ partyID, bean })
{
  let url = cove.getAPI({
    name: 'api.filing.caseParty',
    params: {
      id: partyID
    }
  });

  let payload = {
    partySubTypeExternalIdentifier: bean.partySubTypeExternalIdentifier
  };

  return axios.patch(url, payload);
}

/**
 * Converts the party bean into a sub-bean suitable for editing the party role information.
 *
 * @param {Object} party
 * @returns
 */
export function partyToEditRoleBean (party)
{
  return {
    addMyself: false,
    linkUserID: null,
    involvementTypeID: parseInt(party.partyInvolvementTypeExternalIdentifier),
    partySubTypeName: party.partySubTypeExternalName,
    partySubTypeExternalIdentifier: parseInt(party.partySubTypeExternalIdentifier)
  };
}

/**
 * Updates the party name information.
 *
 * @param {Object} param0
 * @returns
 */
export function patchPartyName ({ partyID, bean })
{
  let url = cove.getAPI({
    name: 'api.filing.caseParty',
    params: {
      id: partyID
    }
  });

  let payload = {
    actor: {
      ...bean,
      displayName: generateDisplayName(bean),
      sortName: generateSortName(bean)
    }
  };

  return axios.patch(url, payload);
}

/**
 * Converts the party bean into a sub-bean suitable for editing the party name information.
 *
 * @param {Object} party
 * @returns
 */
export function partyToEditNameBean (party)
{
  let bean = {
    person: party.actor.person,
    prefix: party.actor.prefix,
    suffix: party.actor.suffix,
    firstName: party.actor.firstName,
    middleName: party.actor.middleName,
    lastName: party.actor.lastName,
    organizationName: party.actor.organizationName,
    aliasTypeID: null,
    aliasName: null
  };

  if (!_.isNil(party.actor.aliasType))
  {
    bean.aliasTypeID = party.actor.aliasType.resourceID;
    bean.aliasName = party.actor.aliasName;
  }

  return bean;
}

/**
 * Updates the party address information.
 *
 * @param {Object} param0
 * @returns
 */
export function patchPartyAddress ({ partyID, bean })
{
  let url = cove.getAPI({
    name: 'api.filing.caseParty',
    params: {
      id: partyID
    }
  });

  let payload = {
    actor: {
      mailingAddress: bean
    }
  };

  return axios.patch(url, payload);
}

/**
 * Updates the filing note information
 *
 * @param {Object} param0
 * @returns
 */
export function patchFilingNote ({ filingID, filingNote })
{
  let url = cove.getAPI({
    name: 'api.filing',
    params: {
      id: filingID
    }
  });

  let payload = { filingNote };

  return axios.patch(url, payload);
}

/**
 * Removes the address associated with the specified party.
 *
 * @param {Object} param0
 * @returns
 */
export function removePartyAddress ({ partyID })
{
  let url = cove.getAPI({
    name: 'api.filing.caseParty',
    params: {
      id: partyID
    }
  });

  let payload = {
    actor: {
      mailingAddress: null
    }
  };

  return axios.patch(url, payload);
}

/**
 * Converts the party bean into a sub-bean suitable for editing the party address information.
 *
 * @param {Object} party
 * @returns
 */
export function partyToEditAddressBean (party)
{
  let address = party.actor.mailingAddress;
  if (_.isNil(address))
  {
    address = {
      line1: null,
      line2: null,
      line3: null,
      line4: null,
      countryIdentifier: null,
      city: null,
      regionIdentifier: null,
      zipCode: null
    };
  }

  return address;
}

export function patchPartyContact ({ partyID, bean })
{
  let url = cove.getAPI({
    name: 'api.filing.caseParty',
    params: {
      id: partyID
    }
  });

  let payload = {
    actor: {
      contacts: {
        primaryEmail: bean.primaryEmail,
        contactPhoneNumber: !_.isNil(bean.contactPhoneNumber) ?
          formatPhoneForSaving(bean.contactPhoneCountry, bean.contactPhoneNumber) : null
      }
    }
  };

  return axios.patch(url, payload);
}

/**
 * Converts the party bean into a sub-bean suitable for editing the party contact information.
 *
 * @param {Object} party
 * @returns
 */
export function partyToEditContactBean (party)
{
  let bean = {
    contactPhoneCountry: null,
    contactPhoneNumber: null,
    primaryEmail: null
  };

  let contacts = party.actor.contacts;
  if (!_.isNil(contacts))
  {
    let phoneNumber = contacts.contactPhoneNumber;
    if (!_.isNil(phoneNumber))
    {
      bean.contactPhoneCountry = getCountryForPhoneNumber(phoneNumber);
      let trimmedNumber = contacts.contactPhoneNumber.substr(contacts.contactPhoneNumber.indexOf(' ') + 1);
      bean.contactPhoneNumber = formatPhoneInput(trimmedNumber, bean.contactPhoneCountry.iso);
    }

    bean.primaryEmail = _.defaultTo(contacts.primaryEmail, null);
  }

  return bean;
}

/**
 * Converts the party bean into a sub-bean suitable for editing a particular representer map bean of that party.
 *
 * @param {Object} party
 * @param {String} editLegalRepID
 * @returns
 */
export function partyToEditRepresentationBean (party, editLegalRepID)
{
  let legalRepMap = _.find(party.legalRepresenterMaps, { resourceID: editLegalRepID });

  let bean = {
    representationType: null,
    representationSelectionMode: null,
    existingAttorney: null,
    existingLegalOrg: null,
    newRepresentation: {
      legalRepresenter: {
        barID: null,
        regionExternalIdentifier: null
      },
      actor: buildActorDTO(),
      address: buildAddressDTO(),
      contact: buildContactDTO()
    }
  };

  // we are going to lock the user into the selection mode they previously chose, which isn't persisted :-(
  // so we need to examine the data and figure out what they chose, and since you can't edit `pro se` or `none` options it must either
  // be an existing attorney OR a brand new attorney
  let isExistingRepresenter = _.get(legalRepMap, 'legalRepresenter.actor.externalIdentifier', null) !== null
    || _.get(legalRepMap, 'existingLegalRepresenter.actorExternalIdentifier', null) !== null;

  if (isExistingRepresenter)
  {
    bean.representationType = CONST.REP_TYPE_PRIVATE;

    // the info could be in legalRep or existingLegalRep
    let representerActor = _.get(legalRepMap, 'legalRepresenter.actor', null);
    if (representerActor !== null)
    {
      if (representerActor.person)
      {
        bean.representationSelectionMode = CONST.REP_SELECTION_MODE_ATTORNEY;
        bean.existingAttorney = {
          actorHeader: {
            actorUUID: representerActor.externalIdentifier,
            displayName: representerActor.displayName,
            sortName: representerActor.sortName,
            firstName: representerActor.firstName,
            middleName: _.defaultTo(representerActor.middleName, null),
            lastName: representerActor.lastName,
            prefix: _.defaultTo(representerActor.prefix, null),
            suffix: _.defaultTo(representerActor.suffix, null)
          }
        };
      }
      else
      {
        bean.representationSelectionMode = CONST.REP_SELECTION_MODE_LAW_FIRM;
        bean.existingLegalOrg = {
          organizationName: representerActor.organizationName,
          actorHeader: {
            actorUUID: representerActor.externalIdentifier,
            displayName: representerActor.displayName,
            sortName: representerActor.sortName
          }
        };
      }
    }
    else
    {
      let representer = _.get(legalRepMap, 'existingLegalRepresenter', null);
      if (representer.attorney)
      {
        bean.representationSelectionMode = CONST.REP_SELECTION_MODE_ATTORNEY;
        bean.existingAttorney = {
          actorHeader: {
            actorUUID: representer.actorExternalIdentifier,
            displayName: representer.displayName,
            sortName: representer.sortName,
            firstName: null,
            middleName: null,
            lastName: null,
            prefix: null,
            suffix: null
          }
        };
      }
      else
      {
        bean.representationSelectionMode = CONST.REP_SELECTION_MODE_LAW_FIRM;
        bean.existingLegalOrg = {
          organizationName: representer.displayName,
          actorHeader: {
            actorUUID: representer.actorExternalIdentifier,
            displayName: representer.displayName,
            sortName: representer.sortName
          }
        };
      }
    }
  }
  else // this must be a free-form new attorney entry
  {
    bean.representationType = CONST.REP_TYPE_PRIVATE;
    bean.representationSelectionMode = CONST.REP_SELECTION_MODE_NEW;

    let newRep = bean.newRepresentation;
    let legalRepresenter = legalRepMap.legalRepresenter;

    newRep.legalRepresenter.barID = legalRepresenter.barNumber;
    newRep.legalRepresenter.regionExternalIdentifier = Number(legalRepresenter.regionExternalIdentifier);

    let phoneNumber = legalRepresenter.actor.contacts.contactPhoneNumber;
    let country = getCountryForPhoneNumber(phoneNumber);
    let trimmedNumber = phoneNumber.substr(phoneNumber.indexOf(' ') + 1);
    newRep.contact.contactPhoneCountry = country;
    newRep.contact.contactPhoneNumber = formatPhoneInput(trimmedNumber, country.iso);
    newRep.contact.primaryEmail = legalRepresenter.actor.contacts.primaryEmail;

    newRep.address = legalRepresenter.actor.mailingAddress;

    if (legalRepresenter.actor.person)
    {
      newRep.actor.person = true;
      newRep.actor.prefix = legalRepresenter.actor.prefix;
      newRep.actor.suffix = legalRepresenter.actor.suffix;
      newRep.actor.firstName = legalRepresenter.actor.firstName;
      newRep.actor.middleName = legalRepresenter.actor.middleName;
      newRep.actor.lastName = legalRepresenter.actor.lastName;
    }
    else
    {
      newRep.actor.person = false;
      newRep.actor.organizationName = legalRepresenter.actor.organizationName;
    }
  }

  return bean;
}

/**
 * Adds a new representation bean to the specified party.
 *
 * @param {Object} param0
 * @returns
 */
export function saveNewRepresentation ({ partyID, bean })
{
  let url = cove.getAPI({ name: 'api.legalrepresentermaps' });
  let payload = legalRepBeanToServicePayload(bean);
  let body = {
    casePartyID: partyID,
    ...payload
  };

  return axios.post(url, body);
}

/**
 * Updates the specified representation bean.
 *
 * @param {Object} param0
 * @returns
 */
export function updateRepresentation ({ partyID, legalRepID, bean })
{
  let url = cove.getAPI({ name: 'api.legalrepresentermap', params: { id: legalRepID } });
  let payload = legalRepBeanToServicePayload(bean);
  let body = {
    casePartyID: partyID,
    ...payload
  };

  return axios.patch(url, body);
}

/**
 * Converts the legal representation form bean into a payload bean suitable for our REST services.
 *
 * @param {Object} bean
 * @returns
 */
function legalRepBeanToServicePayload (bean)
{
  let payload = {};
  if (bean.representationType === CONST.REP_TYPE_PRO_SE)
  {
    payload.proSe = true;
  }
  else if (bean.representationType === CONST.REP_TYPE_CURRENT_USER)
  {
    let user = store.state.user;
    payload.legalRepresenter = {
      actor: {
        firstName: user.firstName,
        middleName: user.middleName,
        lastName: user.lastName,
        prefix: user.prefix,
        suffix: user.suffix,
        externalIdentifier: user.externalIdentifier,
        person: true
      },
      userID: user.userID
    };
  }
  else if (bean.representationType === CONST.REP_TYPE_PRIVATE)
  {
    if (bean.representationSelectionMode === CONST.REP_SELECTION_MODE_NEW)
    {
      let newRep = bean.newRepresentation;
      if (newRep.actor.person)
      {
        payload.legalRepresenter = {
          barNumber: newRep.legalRepresenter.barID,
          regionExternalIdentifier: newRep.legalRepresenter.regionExternalIdentifier,
          actor: {
            ...newRep.actor,
            mailingAddress: newRep.address,
            contacts: {
              primaryEmail: newRep.contact.primaryEmail,
              contactPhoneNumber: formatPhoneForSaving(newRep.contact.contactPhoneCountry, newRep.contact.contactPhoneNumber)
            }
          }
        };
      }
      else
      {
        payload.legalRepresenter = {
          actor: {
            ...newRep.actor,
            mailingAddress: newRep.address,
            contacts: {
              primaryEmail: newRep.contact.primaryEmail,
              contactPhoneNumber: formatPhoneForSaving(newRep.contact.contactPhoneCountry, newRep.contact.contactPhoneNumber)
            }
          }
        };
      }
    }
    else if (bean.representationSelectionMode === CONST.REP_SELECTION_MODE_ATTORNEY)
    {
      payload.legalRepresenter = {
        actor: {
          firstName: bean.existingAttorney.actorHeader.firstName,
          middleName: _.defaultTo(bean.existingAttorney.actorHeader.middleName, null),
          lastName: bean.existingAttorney.actorHeader.lastName,
          prefix: _.defaultTo(bean.existingAttorney.prefix, null),
          suffix: _.defaultTo(bean.existingAttorney.suffix, null),
          externalIdentifier: bean.existingAttorney.actorHeader.actorUUID,
          person: true
        }
      };
    }
    else if (bean.representationSelectionMode === CONST.REP_SELECTION_MODE_LAW_FIRM)
    {
      payload.legalRepresenter = {
        actor: {
          organizationName: bean.existingLegalOrg.organizationName,
          externalIdentifier: bean.existingLegalOrg.actorHeader.actorUUID,
          person: false
        }
      };
    }
  }

  return payload;
}

/**
 * Removes the specified legal representation record.
 *
 * @param {String} legalRepID
 * @returns
 */
export function removeRepresentation (legalRepID)
{
  let url = cove.getAPI({ name: 'api.legalrepresentermap', params: { id: legalRepID } });
  return axios.delete(url);
}

/**
 * Removes the specified party.
 *
 * @param {String} partyID
 * @returns
 */
export function removeParty (partyID)
{
  let url = cove.getAPI({ name: 'api.filing.caseParty', params: { id: partyID } });
  return axios.delete(url);
}

/**
 * Builds an address DTO suitable for our form input screen.
 *
 * @returns
 */
export function buildAddressDTO ()
{
  return {
    line1: null,
    line2: null,
    line3: null,
    line4: null,
    countryIdentifier: null,
    city: null,
    regionIdentifier: null,
    zipCode: null
  };
}

/**
 * Builds a contact DTO suitable for our form input screen.
 *
 * @returns
 */
export function buildContactDTO ()
{
  return {
    contactPhoneCountry: null,
    contactPhoneNumber: null,
    primaryEmail: null
  };
}

/**
 * Builds an actor/name DTO suitable for our form input screen.
 *
 * @returns
 */
export function buildActorDTO ()
{
  return {
    person: true,
    externalIdentifier: null,
    prefix: null,
    suffix: null,
    firstName: null,
    middleName: null,
    lastName: null,
    organizationName: null,
    aliasTypeID: null,
    aliasName: null
  };
}

/**
 * Builds a representation DTO suitable for capturing a brand new attorney / legal org representation from our form input screen.
 *
 * @returns
 */
export function partyToNewRepresentationBean (party)
{
  return {
    representationType: null,
    representationSelectionMode: null,
    existingAttorney: null,
    existingLegalOrg: null,
    newRepresentation: {
      legalRepresenter: {
        barID: null,
        regionExternalIdentifier: null
      },
      actor: buildActorDTO(),
      address: buildAddressDTO(),
      contact: buildContactDTO()
    }
  };
}

/**
 * Loads the docket entry information that will be needed to perform subsequent edit actions. Returns a promise that resolves with the
 * docket entry data.
 *
 * @param {String} docketEntryID
 * @returns
 */
export function loadDocketEntryForEditing (docketEntryID)
{
  let url = cove.getAPI({
    name: 'api.filing.docketEntry',
    params: { id: docketEntryID },
    query: { fields: '**,documents(*,confidentialReason(*))' }
  });

  return axios.get(url)
    .then((response) => {
      return response.data;
    });
}

/**
 * Converts the docket entry bean into a sub-bean suitable for editing the type information.
 *
 * @param {Object} docketEntry
 * @returns
 */
export function docketEntryToEditTypeBean (docketEntry)
{
  return {
    lead: docketEntry.lead,
    excludeFromService: docketEntry.excludeFromService,
    filingType: {
      portalFilingTypeID: Number(docketEntry.filingTypeExternalIdentifier)
    }
  };
}

/**
 * Updates the docket entry type information.
 *
 * @param {Object} param0
 * @returns
 */
export function patchDocketEntryType ({ docketEntryID, bean })
{
  let url = cove.getAPI({ name: 'api.filing.docketEntry', params: { id: docketEntryID } });
  let payload = {
    excludeFromService: bean.excludeFromService
  };

  return axios.patch(url, payload);
}

/**
 * Removes the specified docketEntry.
 *
 * @param {String} docketEntryID
 * @returns
 */
export function removeDocketEntry (docketEntryID)
{
  let url = cove.getAPI({ name: 'api.filing.docketEntry', params: { id: docketEntryID } });
  return axios.delete(url);
}

/**
 * Removes the specified docket entry document record.
 *
 * @param {String} documentID
 * @returns
 */
export function removeDocketEntryDocument (documentID)
{
  let url = cove.getAPI({ name: 'api.filing.docketEntry.document', params: { id: documentID } });
  return axios.delete(url);
}

/**
 * Builds a data entry DTO suitable for our docket entry document form.
 *
 * @param {Object} docketEntry
 * @returns
 */
export function docketEntryToNewDocumentBean (docketEntry)
{
  let documentName = null;
  if (_.isEmpty(docketEntry.documents))
  {
    documentName = docketEntry.docketEntryExternalName;
  }

  return {
    documentLinkID: null,
    documentName,
    documentNote: null,
    confidentialReasonID: null,
    file: null
  };
}

/**
 * Saves a new docket entry document record on the specified docket entry.
 *
 * @param {Object} param0
 * @returns
 */
export function saveNewDocketEntryDocument ({ docketEntryID, bean })
{
  let url = cove.getAPI({ name: 'api.filing.docketEntry.documents' });
  let body = {
    docketEntryID,
    ..._.pick(bean, ['documentName', 'documentNote', 'documentLinkID', 'confidentialReasonID'])
  };

  return axios.post(url, body);
}

/**
 * Converts the specified docket entry document within this docket entry into a bean suitable for our data entry form.
 *
 * @param {Object} param0
 * @returns
 */
export function docketEntryToEditDocumentBean ({ docketEntry, editDocumentID })
{
  let document = _.find(docketEntry.documents, { resourceID: editDocumentID });
  return {
    documentLinkID: document.documentLinkID,
    documentName: document.documentName,
    documentNote: document.documentNote,
    confidentialReasonID: _.get(document, 'confidentialReason.resourceID', null),
    file: null
  };
}

/**
 * Updates the docket entry document on the specified docket entry.
 *
 * @param {Object} param0
 * @returns
 */
export function updateDocketEntryDocument ({ docketEntryID, documentID, bean })
{
  let url = cove.getAPI({ name: 'api.filing.docketEntry.document', params: { id: documentID } });
  let body = {
    docketEntryID,
    ...bean
  };

  return axios.patch(url, body);
}