<template>
  <page-wrapper :title="$t('page.myInvites.title')">

    <search-result-template :config="config">

      <template #table-title>
        {{ $t('page.myInvites.table') }}
      </template>

      <template #v-data-table.item.date="{ value }">
        <div style="min-width:100px">
          {{ value | formatDateTime }}
        </div>
      </template>

      <template #v-data-table.item.session="{ item }">
        <div v-if="item.session" style="white-space: nowrap">
          <div>{{ $t('page.myInvites.session') }}</div>
          <div class="text-minimum-gray">{{ item.courtSessionTypeName }}</div>
        </div>
        <div v-if="!item.session" style="white-space: nowrap">
          <div>{{ $t('page.myInvites.hearing') }}</div>
          <div>
            <router-link :to="{ name: 'caseView', params: {
              courtID: getCourtByExternalID(item.courtID).resourceID,
              caseUUID: item.caseInstanceID
            }}">
              {{ item.caseNumber }}
            </router-link>
          </div>
        </div>
      </template>

      <template #v-data-table.item.eventID="{ item }">
        <router-link :to="{
          name: 'work-invite',
          params: { eventID: item.eventID, typeValue: getTypeValue(item.session), subtypeID: item.subtypeID }
        }">
          {{ $t('global.view') }}
        </router-link>
      </template>

      <template #tableFooter>
        <div class="pr-2 pb-2 text-right">
          <c-btn type="primary" class="pa-4" small @click="showSubscriptionPopup = true">
            {{ $t('global.subscribe') }}
          </c-btn>
        </div>
      </template>

      <template #nav-drawer-filter-body>
        <v-row no-gutters class="mt-4">
          <v-col v-if="!isSingleCourtMode" cols="12">
            <c-autocomplete
              :items="courts"
              v-model="searchDTO.courtID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('courtCase.court')"
              outlined
              key="courtID"
              hide-details="auto"
              aria-describedby="a6e97987-fad1-4ca5-bac8-6353f03097b9"/>
            <field-description id="a6e97987-fad1-4ca5-bac8-6353f03097b9"
                               message-key="page.myInvites.court.description"/>
          </v-col>

          <v-col cols="12" v-if="!isSingleCaseLocationMode">
            <c-autocomplete
              :items="caseLocations"
              v-model="searchDTO.locationID"
              item-text="locationName"
              item-value="locationID"
              :label="$t('court.location')"
              :no-data-text="$t('global.courtFirst')"
              outlined
              key="locationID"
              hide-details="auto"
              aria-describedby="e79ce48d-eddc-4f09-a5d3-b69fc710ccde"/>
            <field-description id="e79ce48d-eddc-4f09-a5d3-b69fc710ccde"
                               message-key="page.myInvites.location.description"/>
          </v-col>

          <v-col cols="12">
            <date-range-picker
              :label="$t('invite.date')"
              :labelStart="$t('invite.dateFrom')"
              :labelEnd="$t('invite.dateTo')"
              keyPrefix="date"
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.dateChoice"
              :startDate.sync="searchDTO.dateStart"
              :endDate.sync="searchDTO.dateEnd"
              aria-describedby="32f3ebe9-1452-42d3-9896-2d409b82c372"
              offer-future-dates
              required/>
            <field-description id="32f3ebe9-1452-42d3-9896-2d409b82c372"
                               message-key="page.myInvites.dueDate.description"/>
          </v-col>

          <v-col cols="12">
            <c-autocomplete
              :items="statuses"
              v-model="searchDTO.statusIDs"
              multiple
              item-text="eventAttributeStatusName"
              item-value="eventAttributeStatusID"
              :label="$t('invite.status')"
              outlined
              key="eventAttributeStatusID"
              hide-details="auto"
              aria-describedby="970a2e50-5f09-4d03-b3e3-7081cad0fbe9">
              <template #selection="{ item }">
                <div class="portal-dropdown-chip">
                  {{ item.eventAttributeStatusName }}
                </div>
              </template>
            </c-autocomplete>
            <field-description id="970a2e50-5f09-4d03-b3e3-7081cad0fbe9" message-key="page.myTasks.taskStatus.description"/>
          </v-col>

        </v-row>
      </template>
    </search-result-template>
    <calendar-subscription-popup v-model="showSubscriptionPopup" :link="calendarLink"/>
  </page-wrapper>
</template>

<script>

import SearchResultTemplate from '@components/support/template/SearchResultTemplate';
import SearchResultTemplateMixin from '@/mixins/searchResultTemplate';
import _ from 'lodash';
import axios from 'axios';
import CONST from '@/application/constants';
import { dateToEndOfDay, dateToStartOfDay } from '@/application/application';
import { DateTime } from 'luxon';
import CalendarSubscriptionPopup from '@components/support/CalendarSubscriptionPopup';

export default {
  components: { CalendarSubscriptionPopup, SearchResultTemplate },
  mixins: [ SearchResultTemplateMixin ],
  data () {
    return {
      searchDTO: {
        sort: {
          sortBy: 'date',
          sortDesc: false
        },
        courtID: null,
        locationID: null,
        dateChoice: '3m',
        dateStart: this.$cove.formatDate(DateTime.local()),
        dateEnd: this.$cove.formatDate(DateTime.local().plus({ 'months': 3 })),
        statusIDs: [ CONST.EVENT_ATTRIBUTE_STATUS_PENDING, CONST.EVENT_ATTRIBUTE_STATUS_ACCEPTED ]
      },
      statuses: [],
      showSubscriptionPopup: false,
      calendarLink: null
    };
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.event.eventAttributeStatues' }))
      .then((response) => {
        this.statuses = _.defaultTo(response.data, []);
      });
    axios.get(this.$cove.getAPI({ name: 'api.user.currentuser' }))
      .then((response) => {
        let user = response.data;
        this.calendarLink = this.$cove.getAPI({
          name: 'api.search.user.invites.calendar',
          params: { userID: user.resourceID, token: user.calendarToken }
        });
      });
  },
  methods: {
    getItemKey () {
      return 'eventID';
    },
    getRouteName () {
      return 'work-invites';
    },
    getSearchAPIName () {
      return 'api.search.user.invites';
    },
    getTypeValue (session) {
      return session ? CONST.EVENT_TYPE_EVENT : CONST.EVENT_TYPE_HEARING;
    },
    getHeaders () {
      let headers = [];

      if (!this.isSingleCourtMode) {
        headers.push({ text: this.$t('courtCase.court'), value: 'courtAbbreviation', sortable: true });
      }
      headers.push({ text: this.$t('invite.date'), value: 'date', sortable: true });
      if (!this.isSingleCaseLocationMode) {
        headers.push({ text: this.$t('court.location'), value: 'locationName', sortable: true });
      }
      headers.push({ text: this.$t('invite.room'), value: 'room', sortable: true });
      headers.push({ text: this.$t('invite.type'), value: 'session', sortable: false });
      headers.push({ text: this.$t('invite.subtype'), value: 'subtype', sortable: true });
      headers.push({ text: this.$t('invite.status'), value: 'status', sortable: true });
      headers.push({ text: this.$t('global.view'), value: 'eventID', sortable: false, align: 'right' });

      return headers;
    },
    addSearchCriteria (query) {

      if (!_.isNil(this.searchDTO.sort.sortBy))
      {
        query.sort = `${this.searchDTO.sort.sortBy},${this.searchDTO.sort.sortDesc ? 'desc' : 'asc'}`;
      }

      if (!_.isNil(this.searchDTO.courtID))
      {
        query.courtID = this.searchDTO.courtID;
      }

      if (!_.isNil(this.searchDTO.locationID))
      {
        query.locationID = this.searchDTO.locationID;
      }

      if (!_.isNil(this.searchDTO.dateStart))
      {
        query.dateFrom = dateToStartOfDay(this.$cove.parseDate(this.searchDTO.dateStart)).toISO();
      }

      if (!_.isNil(this.searchDTO.dateEnd))
      {
        query.dateTo = dateToEndOfDay(this.$cove.parseDate(this.searchDTO.dateEnd)).toISO();
      }

      if (!_.isNil(this.searchDTO.statusIDs))
      {
        query.resourceStatusIDs = this.searchDTO.statusIDs;
      }

      return query;
    },
    validateSearchCriteria (searchDTO)
    {
      this.$cove.validation.required(searchDTO.dateChoice, 'invite.date', 'date-choice');
      if (searchDTO.dateChoice === CONST.DATE_RANGE_OPTION_CUSTOM) {
        this.$cove.validation.required(searchDTO.dateStart, 'invite.dateFrom', 'date-startDate');
        this.$cove.validation.required(searchDTO.dateEnd, 'invite.dateTo', 'date-endDate');
      }
      this.$cove.validation.date.rejectIfInvalid(searchDTO.dateStart, 'invite.dateFrom', 'date-startDate');
      this.$cove.validation.date.rejectIfInvalid(searchDTO.dateEnd, 'invite.dateTo', 'date-endDate');
      this.$cove.validation.date.rejectIfBefore(searchDTO.dateEnd, searchDTO.dateStart, 'invite.dateTo',
        'invite.dateFrom', 'dueDate-endDate');
    }
  }
};
</script>
