<!-- ======================================
  IMPORTANT: DO NOT USE MESSASGE RESOURCES IN THIS FILE AS THEY MAY NOT BE AVAILABLE
=========================================== -->
<template>
  <v-app v-if="display">
    <v-app-bar app color="white" dense>
      <div style="font-size:22px">
        Public Portal
      </div>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <div class="d-flex justify-center mt-16">
        <div class="white rounded elevation-4 pa-12 pb-4" style="max-width:850px">
          <div class="d-flex align-center" style="gap:40px">
            <div style="flex: 0 0 auto">
              <v-icon style="font-size:160px" color="primary darken-1">
                mdi-wrench-clock
              </v-icon>
            </div>
            <div style="flex: 1 1 auto">
              <h1 class="text-h2" style="line-height:1.2">
                System is Undergoing Maintenance
              </h1>
            </div>
          </div>
          <div class="text-minimum-gray mt-4" style="font-size:1.3rem;">
            We apologize for the inconvenience, but the site is currently unavailable. We're working to restore service
            as quickly as possible. Please try again in a few minutes.
          </div>
          <v-divider style="margin:100px 0 12px 0"/>
          <div class="text-minimum-gray">
            You may contact the site administrator at 999-999-9999.
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data () {
    return {
      display: true
    };
  },
  created () {
    if (this.$store.state.application.initializationStatus === this.CONST.INITIALIZATION_STATUS_INITIALIZED)
    {
      this.display = false;
      this.$router.replace({ name: 'home' });
    }
  }
};
</script>