<template>
  <div class="mx-auto" style="max-width:675px">
    <div class="mb-1" style="font-size:1.4rem">
      {{ $t('page.manageFiling.abbreviatedFiling.filingDetails.title') }}
    </div>

    <template v-if="!isLocked">
      <v-card class="mb-3 py-2 px-3">
        <breadcrumbs v-model="stepID" :steps="steps"/>
      </v-card>
    </template>

    <validation-error-panel/>
    <workflow-wrapper>
      <step-details v-if="stepID === 'details'"
                    key="details"
                    :originalBean="bean"
                    :editing="isReadyForSubmission"
                    @save="saveDetails"
                    @cancel="cancel"/>
      <step-summary v-else-if="stepID === 'summary'"
                    key="summary"
                    :originalBean="bean"
                    @edit="gotoDetails"/>
    </workflow-wrapper>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import WorkflowWrapper from '@components/support/WorkflowWrapper';
import StepSummary from '@components/support/manageFiling/abbreviated/StepSummary';
import Breadcrumbs from '@components/support/Breadcrumbs';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import StepDetails from '@components/support/manageFiling/abbreviated/StepDetails';
import Filing from '@/application/filing';

export default {
  components: {
    StepDetails, WorkflowWrapper, StepSummary, Breadcrumbs, ValidationErrorPanel },
  data () {
    return {
      stepID: 'details',
      steps: [
        { stepID: 'details', name: this.$t('page.manageFiling.abbreviatedFiling.steps.docketEntryDetails') },
        { stepID: 'summary', name: this.$t('page.manageFiling.abbreviatedFiling.steps.summary') }
      ],
      bean: this.generateFormBean()
    };
  },
  watch:
  {
    isLocked (newValue) {
      if (newValue === true)
      {
        this.gotoSummary();
      }
    }
  },
  computed: {
    filingID () {
      return this.$store.state.filing.filingID;
    },
    filing () {
      return this.$store.state.filing.filing;
    },
    filingType () {
      return this.$store.state.filing.filingType;
    },
    leadDocketEntry () {
      return this.$store.getters['filing/getLeadDocketEntry'];
    },
    isReadyForSubmission () {
      return Filing.isAbbreviatedFilingReadyForSubmission(this.filing);
    },
    leadDocketEntryFirstDocument () {
      return _.get(this.filing, 'leadDocketEntry.documents[0]', null);
    },
    isLocked () {
      return this.$store.getters['filing/isLocked'];
    },
    filingStatement () {
      return this.bean.docketEntry.docketEntryNote;
    },
    comments () {
      return this.bean.filing.filingNote;
    }
  },
  created () {
    this.start();
  },
  methods: {
    start () {
      this.bean = this.generateFormBean();

      if (this.isReadyForSubmission)
      {
        this.gotoSummary();
      }
      else
      {
        this.gotoDetails();
      }
    },
    generateFormBean ()
    {
      return {
        filing: {
          filingNote: _.get(this.filing, 'filingNote', null)
        },
        docketEntry: {
          docketEntryNote: _.get(this.filing, 'leadDocketEntry.docketEntryNote', null)
        },
        docketEntryDocument: {
          docketEntryID: _.get(this.filing, 'leadDocketEntry.resourceID', null),
          documentLinkID: _.get(this.filing, 'leadDocketEntry.documents[0].documentLinkID', null),
          documentName: _.get(this.filing, 'leadDocketEntry.documents[0].documentName', null),
          documentNote: null
        },
        document: {
          file: null
        }
      };
    },
    gotoDetails () {
      this.stepID = 'details';
      let subtype = this.filingType.docketEntrySubtype;
      this.bean.docketEntryDocument.documentName = subtype.docketEntryType.docketEntryTypeName + ' - '
          + subtype.docketEntrySubTypeName;
    },
    saveDetails (bean) {
      this.bean = bean;

      this.$cove.block({ delay: 0 });

      let filingPromise = Filing.patchFilingNote({ filingID: this.filingID,
        filingNote: this.bean.filing.filingNote });

      let docketEntryPromise = axios.patch(this.$cove.getAPI({ name: 'api.filing.docketEntry',
        params: { id: this.filing.leadDocketEntry.resourceID } }),
      { docketEntryNote: this.bean.docketEntry.docketEntryNote } );

      let docketEntryDocumentPromise = Promise.resolve();
      if (!_.isNil(this.bean.docketEntryDocument.documentLinkID))
      {
        if (_.isNil(this.leadDocketEntryFirstDocument))
        {
          // Abbreviated filing should only have a single document associated with the lead docket entry
          docketEntryDocumentPromise = axios.post(this.$cove.getAPI({ name: 'api.filing.docketEntry.documents' }),
            this.bean.docketEntryDocument);
        }
        else
        {
          // Abbreviated filing already has a document, patch it with the new documentLinkID if it is different in the
          // case the user removed the document and added a new one
          if (this.leadDocketEntryFirstDocument.documentLinkID !== this.bean.docketEntryDocument.documentLinkID)
          {
            docketEntryDocumentPromise = axios.patch(this.$cove.getAPI({
              name: 'api.filing.docketEntry.document',
              params: { id: this.leadDocketEntryFirstDocument.resourceID }
            }), this.bean.docketEntryDocument);
          }
        }
      }

      Promise.all([filingPromise, docketEntryPromise, docketEntryDocumentPromise])
        .then(() => {

          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });

          // reload the filing changes
          return this.$store.dispatch('filing/load', { filingID: this.filingID }).then(() => {
            this.gotoSummary();
          });
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    gotoSummary () {
      this.stepID = 'summary';
    },
    cancel () {
      this.$cove.confirm({ message: this.$t('global.confirmation.cancel.create') })
        .then((confirm) => {
          if (confirm)
          {
            this.start();
          }
        });
    }
  }
};
</script>