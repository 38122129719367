<template>
  <v-data-table
    :items="items"
    :headers="headers"
    :sort-by="sort.sortBy"
    :sort-desc="sort.sortDesc"
    :server-items-length="page.totalElements"
    :loading="loading"
    item-key="searchResultKey"
    must-sort
    hide-default-footer
    class="elevation-2"
    :mobile-breakpoint="800"
    @update:options="updateOptions">
    <template #top>
      <v-alert
        :value="readOnly && page.totalElements > CONST.CASE_VIEW_PRINTABLE_MAX_TABLE_RESULTS"
        class="mb-1 rounded-b-0"
        icon="mdi-alert-box"
        color="warning"
        text>
        <div class="font-weight-medium" style="font-size:1.1rem;line-height:1.5">
          {{ $t('global.warning') }}
        </div>
        <div class="mt-2" style="max-width:120ch">
          {{ $t('page.caseView.tab.printableLimitWarning', [CONST.CASE_VIEW_PRINTABLE_MAX_TABLE_RESULTS]) }}
        </div>
      </v-alert>

      <div class="d-flex align-center pt-3 px-4" style="min-height:44px">
        <div class="c-caption" style="flex:1 1 auto">
          {{ $t('page.caseView.tab.hearings') }}
        </div>
        <div v-if="!readOnly" class="text-right d-print-none" style="flex:1 1 auto">
          <v-btn icon color="primary" @click="toggleDrawer">
            <v-icon>
              mdi-sort
            </v-icon>
            <span class="d-sr-only">
              {{ $t('global.sort') }}
            </span>
          </v-btn>
        </div>
      </div>
    </template>
    <template #item.startDate="{ value }">
      {{ value | formatDateTime }}
    </template>
    <template #item.event.location="{ item }">
      {{ item.event.location }}
      <span v-if="item.event.room !== undefined && item.event.room !== null">
        - {{ item.event.room }}
      </span>
    </template>
    <template #item.event.judgeActorInstances="{ value, item }">
      <template v-if="item.event.panelFlag === false && value !== undefined && value !== null && value.length > 0">
        {{ value[0].sortName }}
      </template>
    </template>
    <template #item.event.virtualStreamingLink="{ value }">
      <template v-if="value !== undefined && value !== null">
        <a :href="value" target="_blank">{{ $t('page.caseView.tab.hearings.streamingLinkText') }}</a>
      </template>
    </template>
    <template #footer>
      <paging-controls :page="page" @changePage="changePage" :read-only="readOnly"/>
    </template>
  </v-data-table>
</template>

<script>
import CaseViewTabMixin from '@/mixins/caseViewTab';

export default {
  mixins: [ CaseViewTabMixin ]
};
</script>