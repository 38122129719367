<template>
  <c-dialog
    :value="value"
    close-key="cove.general.close"
    title-key="kioskMode.title"
    width="600"
    @input="close">

    <validation-error-panel :error-scope="errorScope" class="ma-4 mb-8"/>

    <div class="ma-4">
      <v-fade-transition mode="out-in">
        <div v-if="!showExitPrompt" key="details">
          <div class="c-caption mb-1">
            {{ $t('global.details') }}
          </div>
          <div class="text-minimum-gray" style="max-width:75ch">
            {{ $t('popup.kioskMode.description') }}
          </div>

          <div class="mt-6 pb-2">
            <button type="button" class="link-button" @click="showExitPrompt = true">
              {{ $t('popup.kioskMode.button.exitKioskMode') }}
            </button>
          </div>
        </div>
        <div v-else key="exit">
          <div class="c-caption mb-1">
            {{ $t('popup.kioskMode.exit.title') }}
          </div>
          <div class="text-minimum-gray">
            {{ $t('popup.kioskMode.exit.description') }}
          </div>

          <div class="mt-8 mb-12">
            <c-text-field
              v-model="password"
              outlined
              required
              :maxlength="passwordMaxLength"
              :label="$t('popup.kioskMode.field.exitPassword')"
              type="password"
              :key="`${errorScope}:password`"
              id="kiosk-password"
              hide-details="auto"
              aria-describedby="786d673c-6875-4283-ad86-9b7b79ab7072"/>
            <field-description id="786d673c-6875-4283-ad86-9b7b79ab7072">
              {{ $t('popup.kioskMode.field.exitPassword.description', [passwordMinLength, passwordMaxLength]) }}
            </field-description>
          </div>
        </div>
      </v-fade-transition>
    </div>

    <template #actions-right>
      <v-fade-transition mode="out-in">
        <c-btn v-if="showExitPrompt" type="primary" key="button" @click="exitKioskMode">
          {{ $t('popup.kioskMode.button.exitKioskMode') }}
        </c-btn>
        <div v-else key="blank"></div>
      </v-fade-transition>
    </template>
  </c-dialog>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import { redirectToLogout } from '@/application/application';
import { focusElement } from '@/application/utility';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import FieldDescription from '@components/support/FieldDescription';

export default {
  components: { ValidationErrorPanel, FieldDescription },
  props: {
    value: {
      type: Boolean
    }
  },
  data () {
    return {
      password: null,
      showExitPrompt: false,
      errorScope: 'kioskPopup',
      passwordMinLength: 8,
      passwordMaxLength: 50
    };
  },
  watch: {
    showExitPrompt (newValue) {
      if (newValue)
      {
        focusElement('#kiosk-password');
      }
    },
    value (newValue) {
      if (!newValue)
      {
        this.$store.commit('cove/errors/clear', this.errorScope);
        this.showExitPrompt = false;
      }
    }
  },
  methods: {
    validate () {
      this.$store.commit('cove/errors/clear', this.errorScope);

      this.$cove.validation.required(this.password, 'popup.kioskMode.field.exitPassword', `${this.errorScope}:password`);
      this.$cove.validation.string.minLength(this.password, this.passwordMinLength, 'popup.kioskMode.field.exitPassword',
        `${this.errorScope}:password`);
      this.$cove.validation.string.maxLength(this.password, this.passwordMaxLength, 'popup.kioskMode.field.exitPassword',
        `${this.errorScope}:password`);

      return !this.$store.getters['cove/errors/hasErrors'](this.errorScope);
    },
    exitKioskMode () {
      let isValid = this.validate();
      if (isValid)
      {
        this.$cove.block({ delay: 0 });
        axios.delete(this.$cove.getAPI({ name: 'api.kiosk' }), {
          data: { password: this.password },
          // we are using a custom error handler since we are in a popup -- this lets us put the validation error into the scoped popup
          errorHandlers: {
            400: (error) => {
              let validationErrors = _.get(error, 'response.data.validationErrors', []);
              validationErrors.forEach((error, i) => {
                let replaced = error.replace(/^.*?:\s*/, ''); // chop the prefix (field name) from the REST API error message
                this.$cove.validation.addErrorText(`${this.errorScope}:password`, replaced);
              });
            }
          }
        })
          .then(() => {
            redirectToLogout();
          })
          .catch(() => {
            this.$cove.unblock();
          });
      }
    },
    close () {
      this.$emit('input', false);
    }
  }
};
</script>
