<template>
  <address v-if="shouldDisplay">
    <template v-if="isFormatted">
      <pre class="primary-font" v-text="formattedAddress"></pre>
    </template>
    <template v-else-if="isObject">
      <div>
        {{ address.line1 }}
      </div>
      <div v-if="address.line2">
        {{ address.line2 }}
      </div>
      <div v-if="address.line3">
        {{ address.line3 }}
      </div>
      <div v-if="address.line4">
        {{ address.line4 }}
      </div>
      <template v-if="hasRegion">
        <div>
          {{ address.city }} {{ region.regionName }} {{ address.zipCode }}
        </div>
      </template>
      <template v-if="isInternational">
        <div>
          {{ country.countryName }}
        </div>
      </template>
    </template>
  </address>
</template>

<script>
import _ from 'lodash';

export default {
  // you can pass in either an address object/dto or a pre-formatted string
  props: {
    address: {
      type: Object
    },
    formattedAddress: {
      type: String
    }
  },
  computed: {
    shouldDisplay () {
      return this.isFormatted || this.isObject;
    },
    isFormatted () {
      return !_.isNil(this.formattedAddress);
    },
    isObject () {
      return !_.isNil(this.address);
    },
    isInternational () {
      return this.$store.getters['application/getDefaultCountry'].countryID !== this.address.countryIdentifier;
    },
    hasRegion () {
      return !_.isNil(this.address.regionIdentifier);
    },
    region () {
      return this.$store.getters['application/getRegion'](this.address.regionIdentifier);
    },
    country () {
      return this.$store.getters['application/getCountry'](this.address.countryIdentifier);
    }
  }
};
</script>