<template>
  <page-wrapper :title="$t('page.myService.title')">
    <div class="d-flex flex-wrap flex-md-nowrap" style="gap: 12px">

      <div style="flex: 1 1 auto">
        <loading-area :loading="loading">
          <template #default>
            <v-card>
              <v-card-title>
                {{ $t('page.myService.table.title') }}
              </v-card-title>
              <v-card-subtitle>
                {{ $t('page.myService.table.description') }}
              </v-card-subtitle>
              <v-data-table
                :items="serviceDocketEntries"
                :headers="headers"
                class="mt-2"
                must-sort
                item-key="resourceID"
                hide-default-footer
                disable-pagination
                :mobile-breakpoint="800">
                <template #item.lead="{ value }">
                  <template v-if="value">
                    <v-icon>
                      mdi-check
                    </v-icon>
                    <span class="d-sr-only">
                      {{ $t('global.yes') }}
                    </span>
                  </template>
                  <template v-else>
                    <span class="d-sr-only">
                      {{ $t('global.no') }}
                    </span>
                  </template>
                </template>
                <template #item.documents="{ value }">
                  <div v-for="d in value" :key="d.resourceID">
                    <a :href="viewSubmittedDocumentURL(d.documentLinkID)" target="_blank">
                      {{ d.documentName }}
                    </a>
                  </div>
                </template>
                <template #item.docketEntryExternalIdentifier="{ value }">
                  <v-btn v-if="isApproved" icon color="primary" @click="openDocumentPopup(value)">
                    <v-icon>mdi-file</v-icon>
                    <span class="d-sr-only">
                      {{ $t('application.button.viewDocument') }}
                    </span>
                  </v-btn>
                  <span v-else class="text-minimum-gray">
                    {{ $t('global.unavailable') }}
                  </span>
                </template>
                <template #footer>
                  <paging-controls :page="page" read-only/>
                </template>
              </v-data-table>
            </v-card>
          </template>
        </loading-area>
      </div>

      <!-- forces wrap on small displays -->
      <div class="d-md-none" style="flex: 1 0 100%"></div>

      <div class="flex-shrink-0 flex-grow-1 flex-md-grow-0" style="flex-basis: 280px">
        <loading-card :loading="loading" :height="500">
          <template #default>
            <div class="pa-3">
              <div class="d-flex align-center">
                <div style="flex:0 0 auto">
                  <v-icon color="icon" style="font-size:28px">
                    mdi-folder-open
                  </v-icon>
                </div>
                <div class="c-caption" style="margin:2px 0 0 8px">
                  {{ isNewCaseFiling ? $t('global.filing.caseType.newCase') : $t('global.filing.caseType.existingCase') }}
                </div>
              </div>
              <div class="mt-3">
                <filing-status :filingStatusID="filing.filingStatus.resourceID"/>
                <span class="pl-1 text-minimum-gray" style="font-size:.95rem" :title="statusDate">
                  {{ relativeStatusDate }}
                </span>
              </div>
              <div class="mt-5">
                <div>
                  <div class="label text-left">{{ $t('courtCase.court') }}</div>
                  <div class="break-word">{{ filing.court.displayName }}</div>
                </div>
                <template v-if="hasCaseLink">
                  <div class="mt-4">
                    <div class="label text-left">{{ $t('courtCase.caseNumber') }}</div>
                    <div class="break-word">
                      <router-link :to="{ name: 'caseView', params: { courtID, caseUUID: caseInstanceUUID } }">
                        {{ filing.courtCase.caseNumber }}
                      </router-link>
                    </div>
                  </div>
                  <div class="mt-4" :style="isNewCaseFiling ? 'display:none' : '' ">
                    <div class="label text-left">{{ $t('courtCase.caseTitle') }}</div>
                    <div class="break-word">{{ caseTitle }}</div>
                  </div>
                </template>
                <div class="mt-4">
                  <div class="label text-left">{{ $t('courtCase.caseClassification') }}</div>
                  <div class="break-word">{{ filing.courtCase.caseClassificationName }}</div>
                </div>
                <div class="mt-4">
                  <div class="label text-left">{{ $t('filing.leadDocketEntry') }}</div>
                  <div class="break-word">{{ filing.leadDocketEntry.docketEntryExternalName }}</div>
                </div>
                <div v-if="hasRecipientUser" class="mt-4">
                  <div class="label text-left">{{ $t('filingService.recipientUserID') }}</div>
                  <div class="break-word">{{ service.recipientUser.actor.displayName }}</div>
                </div>
              </div>
              <div v-if="isRead" class="mt-4 pt-4" style="border-top:1px solid var(--line-color)">
                <v-alert
                  :value="true"
                  class="mb-1"
                  rounded
                  icon="mdi-email-open"
                  color="info"
                  text>
                  <div>
                    <div>
                      {{ $t('page.myService.hasBeenRead') }}
                    </div>
                    <div class="mt-3">
                      {{ service.readDate | formatDateTime }}
                    </div>
                  </div>
                </v-alert>
              </div>
            </div>

            <document-popup v-model="documentPopupVisible"
                            :courtID="courtID"
                            :docketEntryUUID="docketEntryUUID"
                            :caseInstanceUUID="caseInstanceUUID"/>

          </template>
        </loading-card>
      </div>
    </div>
  </page-wrapper>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import LoadingArea from '@components/support/LoadingArea';
import LoadingCard from '@components/support/LoadingCard';
import FilingStatus from '@components/support/FilingStatus';
import PageWrapper from '@components/support/PageWrapper';
import PagingControls from '@components/support/PagingControls';
import DocumentPopup from '@components/support/DocumentPopup';

export default {
  components: { PageWrapper, LoadingArea, LoadingCard, FilingStatus, PagingControls, DocumentPopup },
  props: {
    serviceID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      documentPopupVisible: false,
      docketEntryUUID: null,
      service: null,
      headers: [
        { text: this.$t('filing.leadDocketEntry'), value: 'lead', sortable: true },
        { text: this.$t('docketEntry.type'), value: 'docketEntryExternalName', sortable: true },
        { text: this.$t('page.myService.table.submittedDocuments'), value: 'documents', sortable: false },
        { text: this.$t('page.myService.table.officialDocuments'), value: 'docketEntryExternalIdentifier', sortable: false, align: 'right' }
      ]
    };
  },
  computed: {
    filing () {
      return this.service.filing;
    },
    caseInstanceUUID () {
      return this.filing.courtCase.caseIdentifier;
    },
    courtID () {
      return this.filing.court.resourceID;
    },
    currentUserID () {
      return this.$store.state.user.userID;
    },
    isApproved () {
      return this.filing.filingStatus.resourceID === this.CONST.FILING_STATUS_APPROVED;
    },
    serviceDocketEntries () {
      return _.defaultTo(this.filing.serviceDocketEntries, []);
    },
    page () {
      return {
        size: this.serviceDocketEntries.length,
        number: 0,
        totalElements: this.serviceDocketEntries.length
      };
    },
    isNewCaseFiling () {
      return this.filing.newCaseFiling === true;
    },
    hasCaseLink () {
      return !_.isNil(this.filing.courtCase.caseIdentifier);
    },
    hasRecipientUser () {
      return !_.isNil(this.service.recipientUser);
    },
    isRecipientUser () {
      return this.hasRecipientUser && this.service.recipientUser.resourceID === this.currentUserID;
    },
    isRead () {
      return !_.isNil(this.service.readDate);
    },
    // if the filer could see the title and you were e-served on it, you can see the title too (ie you are a party on the case)
    // in fact it will be a very rare case where this is ever false
    caseTitle () {
      let filerAccessible = this.filing.courtCase.filerCaseAccessible === true;
      return filerAccessible ? this.filing.courtCase.caseTitle : this.$t('courtCase.secure');
    },
    statusDate () {
      return this.$cove.formatDateTime(this.filing.filingStatusDate);
    },
    relativeStatusDate () {
      let dateTime = this.$cove.parseDate(this.filing.filingStatusDate);

      // in case of small time differences between server and client ensure we never show a 'future' value here
      let diff = dateTime.toMillis() - new Date().getTime();
      if (diff > 0)
      {
        dateTime = dateTime.minus(diff);
      }

      // also, subtract a second so immediate actions display at least 'one second ago'
      dateTime = dateTime.minus(1000);
      return dateTime.toRelative();
    }
  },
  created () {
    this.loadService();
  },
  methods: {
    loadService () {
      let serviceURL = this.$cove.getAPI({
        name: 'api.filing.service',
        params: {
          serviceID: this.serviceID
        },
        query: {
          // eslint-disable-next-line max-len
          fields: '*,recipientUser(*,actor(*)),filing(*,court(*),courtCase(*),leadDocketEntry(*),filingStatus(*),serviceDocketEntries(*,documents(*)))'
        }
      });

      axios.get(serviceURL)
        .then((response) => {
          this.service = response.data;

          if (!this.isRead && this.isRecipientUser)
          {
            let markreadURL = this.$cove.getAPI({
              name: 'api.filing.service.markread',
              params: { serviceID: this.serviceID }
            });

            return axios.patch(markreadURL);
          }

          return null;
        })
        .then((response) => {
          if (!_.isNil(response))
          {
            this.$set(this.service, 'readDate', _.get(response, 'data.readDate', null));

            // Get the latest polling data immediately after the mark as read
            this.$store.dispatch('user/poll');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    viewSubmittedDocumentURL (documentLinkID) {
      return this.$cove.getAPI({ name: 'api.dms.document', params: { documentLinkID } });
    },
    openDocumentPopup (docketEntryUUID) {
      this.documentPopupVisible = true;
      this.docketEntryUUID = docketEntryUUID;
    }
  }
};
</script>