<template>
  <div :id="id" class="field-description">
    {{ messageDisplay }}
    <slot/>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    messageKey: {
      type: String,
      required: false
    }
  },
  computed: {
    messageDisplay () {
      let result = !_.isNil(this.messageKey) ? this.$t(this.messageKey) : null;

      // basic error checking
      if (_.isNil(this.id))
      {
        result = 'ERROR: FIELD DESCRIPTION ELEMENT MISSING ID ATTRIBUTE';
      }

      return result;
    }
  }
};
</script>