<template>
  <loading-card :loading="loading">
    <template #default>
      <v-card-title>
        {{ $t('page.subscriptionMgmt.activeSubs.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.subscriptionMgmt.activeSubs.description') }}
      </v-card-subtitle>
      <v-card-text class="pa-0">
        <v-data-table
          :items="items"
          :headers="headers"
          :sort-by="paging.sortBy"
          :sort-desc="paging.sortDesc"
          :server-items-length="paging.totalElements"
          :loading="loadingTable"
          item-key="resourceID"
          must-sort
          hide-default-footer
          :mobile-breakpoint="800"
          @update:options="paging.updateOptions">
          <template #item.subscriberName="{ item }">
            <router-link v-if="!isNil(item.userID)" :to="{ name: 'admin-user-account', params: { userID: item.userID }}">
              {{ item.subscriberName }}
            </router-link>
            <router-link v-if="!isNil(item.organizationID)" :to="{ name: 'manage-org', params: { organizationID: item.organizationID} }">
              {{ item.subscriberName }}
            </router-link>
          </template>
          <template #footer>
            <paging-controls-new :paging="paging"/>
          </template>
        </v-data-table>
      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import LoadingCard from '@components/support/LoadingCard';
import PagingControlsNew from '@components/support/PagingControlsNew';
import Paging from '@/application/paging';
import JSURL from 'jsurl';
import { replaceIgnoreDuplicate } from '@/application/utility';

export default {
  components: { LoadingCard, PagingControlsNew },
  data () {
    return {
      loading: true,
      loadingTable: false,
      items: [],
      headers: [
        { text: this.$t('subscription.subscriberName.short'), value: 'subscriberName', sortable: true },
        { text: this.$t('subscription.type'), value: 'subscriptionTypeDisplayName' },
        { text: this.$t('subscription.activeSeats'), value: 'activeSeats', sortable: false },
        { text: this.$t('subscription.openSeats'), value: 'openSeats', sortable: false },
        { text: this.$t('subscription.totalSeats'), value: 'totalSeats', sortable: false },
        { text: this.$t('subscription.status'), value: 'subscriptionStatusDisplayName' }
      ],
      paging: new Paging({
        size: 25,
        sortBy: 'subscriberName',
        sortDesc: false,
        searchCallback: this.loadSubscriptions
      })
    };
  },
  created () {
    let criteria = JSURL.parse(_.defaultTo(this.$route.query.criteria, ''));
    this.paging.parseURLParams(criteria);

    this.loadSubscriptions(false)
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    loadSubscriptions (updateURL = true) {
      if (updateURL) {
        replaceIgnoreDuplicate({
          name: 'admin-subscription-active',
          query: {
            criteria: JSURL.stringify(this.paging.generateParams())
          }
        });
      }

      this.loadingTable = true;
      return axios.get(this.$cove.getAPI({
        name: 'api.subscriptions',
        query: { onlyValidSubscriptions: true, ...this.paging.generateParams() }
      }))
        .then((response) => {
          this.items = _.get(response, 'data._embedded.results', []);
          this.paging.parseResponse(response);
        })
        .finally(() => {
          this.loadingTable = false;
        });
    }
  }
};
</script>