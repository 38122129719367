<template>
  <c-dialog
    :value="visible"
    width="600"
    close-key="cove.general.cancel"
    title-key="popup.orgEditUser.title"
    @input="close">

    <div v-if="visible" class="px-8 pt-4">
      <section-separator :title="$t('popup.orgAddUser.section.userInformation')"/>
      <dl class="d-grid">
        <dt>{{ $t('popup.orgAddUser.firstName') }}</dt>
        <dd>{{ user.actor.firstName }}</dd>
        <dt>{{ $t('popup.orgAddUser.lastName') }}</dt>
        <dd>{{ user.actor.lastName }}</dd>
        <dt>{{ $t('popup.orgAddUser.username') }}</dt>
        <dd>{{ user.username }}</dd>
        <dt>{{ $t('popup.orgAddUser.email') }}</dt>
        <dd>{{ user.actor.contacts.primaryEmail }}</dd>
      </dl>

      <section-separator :title="$t('popup.orgAddUser.section.options')" class="mt-2"/>
      <c-checkbox
        v-model="payment"
        :label="$t('popup.orgAddUser.field.paymentAccess')"
        :disabled="!editPaymentAccess"
        class="mt-3"
        hide-details="auto"
        aria-describedby="abfadb4f-69c9-457e-afa2-5a57a5d6a7b1"/>
      <field-description id="abfadb4f-69c9-457e-afa2-5a57a5d6a7b1"
                         message-key="popup.orgAddUser.field.paymentAccess.description"/>

      <template v-if="displaySubscriptionAccess">
        <c-checkbox
          v-model="subscription"
          :label="$t('popup.orgAddUser.field.subscriptionAccess')"
          :disabled="!editSubscriptionAccess"
          class="mt-n3"
          hide-details="auto"
          aria-describedby="a6709025-209a-416e-b5db-230151abb61b"/>
        <field-description id="a6709025-209a-416e-b5db-230151abb61b"
                           message-key="popup.orgAddUser.field.subscriptionAccess.description"/>
      </template>

      <c-checkbox
        v-model="admin"
        :label="$t('popup.orgAddUser.field.admin')"
        :disabled="admin && isCurrentUser"
        class="mt-n3"
        hide-details="auto"
        aria-describedby="a0ed4616-d904-4c40-a8b2-0e722debdda9"/>
      <field-description id="a0ed4616-d904-4c40-a8b2-0e722debdda9"
                         message-key="popup.orgAddUser.field.admin.description"/>
    </div>

    <template #actions-right>
      <c-btn type="primary" @click="save" key="saveButton">
        {{ $t('global.save') }}
      </c-btn>
    </template>
  </c-dialog>
</template>

<script>
import axios from 'axios';
import SectionSeparator from '@components/support/SectionSeparator';
import FieldDescription from '@components/support/FieldDescription';

export default {
  components: { FieldDescription, SectionSeparator },
  props: {
    value: {
      type: Boolean
    },
    userMapID: {
      type: String
    }
  },
  data () {
    return {
      loading: false,
      user: null,
      payment: false,
      subscription: false,
      admin: false,
      editPaymentAccess: false,
      editSubscriptionAccess: false,
      displaySubscriptionAccess: false
    };
  },
  computed: {
    visible () {
      return this.value && !this.loading;
    },
    isCurrentUser () {
      return this.user.resourceID === this.$store.state.user.userID;
    }
  },
  watch: {
    value (newValue) {
      if (newValue)
      {
        // prep the popup for display
        this.$cove.block();
        this.loading = true;
        this.loadUser()
          .finally(() => {
            this.loading = false;
            this.$cove.unblock();
          });
      }
      else
      {
        // reset all the data
        this.user = null;
        this.payment = false;
        this.subscription = false;
        this.admin = false;
        this.editPaymentAccess = false;
        this.editSubscriptionAccess = false;
        this.displaySubscriptionAccess = false;
      }
    }
  },
  methods: {
    loadUser () {
      let url = this.$cove.getAPI({
        name: 'api.organization.user',
        params: {
          id: this.userMapID
        },
        query: {
          fields: '*,user(*,actor(*))'
        }
      });

      return axios.get(url)
        .then((response) => {
          let data = response.data;
          this.user = data.user;
          this.payment = data.payment;
          this.subscription = data.subscription;
          this.admin = data.admin;

          this.editPaymentAccess = data.editPaymentAccess || this.payment;
          this.editSubscriptionAccess = data.editSubscriptionAccess || this.subscription;
          this.displaySubscriptionAccess = data.displaySubscriptionAccess;
        });
    },
    save () {
      this.$cove.block({ delay: 0 });
      let url = this.$cove.getAPI({
        name: 'api.organization.user',
        params: {
          id: this.userMapID
        }
      });

      let payload = {
        payment: this.payment,
        subscription: this.subscription,
        admin: this.admin
      };

      axios.patch(url, payload)
        .then(() => {
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
          this.$emit('userEdited');
          this.close();
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    close () {
      this.$emit('input', false);
    }
  }
};
</script>