<template>
  <c-dialog
    :value="value && !loading"
    width="600"
    close-key="cove.general.close"
    title-key="popup.rotateCredentials.title"
    @input="close">

    <div class="pa-5">
      <div>
        {{ $t('popup.rotateCredentials.body') }}
      </div>
      <div class="text-center mt-7">
        <code class="pa-3 font-weight-bold" style="font-size:1rem;letter-spacing:0.03rem">
          {{ apiUserPassword }}
        </code>
      </div>
    </div>

    <template #actions-left>
      <c-btn type="primary" @click="copyToClipboard">
        {{ $t('popup.rotateCredentials.button.copyToClipboard') }}
      </c-btn>
    </template>

  </c-dialog>

</template>

<script>
import _ from 'lodash';
import axios from 'axios';

export default {
  props: {
    value: {
      type: Boolean
    },
    userID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      apiUserPassword: null
    };
  },
  watch: {
    value (newValue) {
      if (newValue)
      {
        this.$cove.block();
        this.loading = true;
        this.rotateCredentials()
          .finally(() => {
            this.loading = false;
            this.$cove.unblock();
          });
      }
      else
      {
        this.apiUserPassword = null;
      }
    }
  },
  methods: {
    rotateCredentials () {
      return axios.patch(this.$cove.getAPI({ name: 'api.user.rotatecredentials', params: { userID: this.userID } }) )
        .then((response) => {
          this.apiUserPassword = _.get(response, 'data.apiPassword', null);
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
        });
    },
    close () {
      this.$emit('input', false);
    },
    copyToClipboard () {
      navigator.clipboard.writeText(_.defaultTo(this.apiUserPassword, ''));
    }
  }
};
</script>
