<template>
  <div>
    <v-data-table
      :items="items"
      :headers="headers"
      :sort-by="searchDTO.sort.sortBy"
      :sort-desc="searchDTO.sort.sortDesc"
      :server-items-length="searchDTO.page.totalElements"
      item-key="searchResultKey"
      must-sort
      hide-default-footer
      class="elevation-2"
      :mobile-breakpoint="800"
      @update:options="updateOptions">
      <template #top>
        <div class="d-flex align-center">
          <div class="c-caption pt-2 pl-3" style="flex:1 1 auto">
            {{ $t('page.searchResults.document.table') }}
          </div>
          <div class="text-right pt-2 pr-2 d-print-none" style="flex:1 1 auto">
            <v-btn icon color="primary" @click="toggleDrawer">
              <v-icon>
                mdi-filter
              </v-icon>
              <span class="d-sr-only">
                {{ $t('global.table.filterAndSort') }}
              </span>
            </v-btn>
          </div>
        </div>
      </template>
      <template #item.caseHeader.caseNumber="{ item }">
        <router-link :to="{ name: 'caseView', params: { courtID: getCourtByExternalID(item.caseHeader.courtID).resourceID,
                                                        caseUUID: item.caseHeader.caseInstanceUUID} }">
          {{ item.caseHeader.caseNumber }}
        </router-link>
      </template>
      <template #item.docketEntryFiledDate="{ value }">
        {{ value | formatDate }}
      </template>
      <template #item.highlightsMap="{ value }">
        <div v-if="value !== undefined && value !== null"
             v-html="formatHighlights(value)"
             style="min-width:150px;max-width:250px"></div>
      </template>
      <template #item.documentLinkUUID="{ item }">
        <v-btn icon color="primary" @click="openDocumentPopup(getCourtByExternalID(item.caseHeader.courtID).resourceID,
                                                              item.caseHeader.caseInstanceUUID,
                                                              item.documentLinkUUID)">
          <span class="d-sr-only">
            {{ $t('application.button.viewDocument') }}
          </span>
          <v-icon>mdi-file</v-icon>
        </v-btn>
      </template>
      <template #footer>
        <paging-controls :page="searchDTO.page" @changePage="changePage"/>
      </template>
    </v-data-table>

    <document-popup v-model="documentPopupVisible"
                    :courtID="courtID"
                    :caseInstanceUUID="caseInstanceUUID"
                    :documentLinkUUID="documentLinkUUID"/>
  </div>
</template>

<script>
import DocumentPopup from '@components/support/DocumentPopup';
import SearchResultsMixin from '@/mixins/searchResults';
import _ from 'lodash';

export default {
  components: { DocumentPopup },
  mixins: [ SearchResultsMixin ],
  data () {
    return {
      documentPopupVisible: false,
      documentLinkUUID: null,
      courtID: null,
      caseInstanceUUID: null
    };
  },
  methods: {
    openDocumentPopup (courtID, caseInstanceUUID, documentLinkUUID) {
      this.documentPopupVisible = true;
      this.documentLinkUUID = documentLinkUUID;
      this.courtID = courtID;
      this.caseInstanceUUID = caseInstanceUUID;
    },
    formatHighlights (highlightsMap) {
      let result = null;
      let matches = _.defaultTo(Object.values(_.defaultTo(highlightsMap, {}))[0], []);
      if (matches.length > 0)
      {
        let results = [];
        matches.forEach((match) => {
          // escape any HTML contained within the text match (not likely but have to prevent HTML injection)
          let replaced = _.escape(match);

          // then replace our highlight matches with HTML markup
          replaced = replaced.replace(/\[h8e\]\s+\[h8s\]/g, ' '); // condense any consecutive matches into a larger match
          replaced = replaced.replace(/\[h8s\]/g, '<mark class="document-search-highlight">');
          replaced = replaced.replace(/\[h8e\]/g, '</mark>');

          // wrap the match in div for spacing
          replaced = '<div class="py-1">' + replaced + '</div>';

          results.push(replaced);
        });

        result = results.join('');
      }

      return result;
    }
  }
};
</script>