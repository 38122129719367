<template>
  <loading-card :loading="caseInstance === null">
    <template #default>
      <v-row no-gutters class="pa-3">
        <v-col cols="12">
          <div class="text-center c-caption mb-1">
            <div>
              {{ courtName }}
            </div>
            <div v-if="!isSingleCaseLocationMode" style="font-size:.9em">
              {{ caseInstance.location }}
            </div>
          </div>
          <div class="d-flex align-center">
            <div class="d-flex pl-3 pl-md-6 pl-xl-6 pb-2" style="flex:0 0 50%;min-height:64px">
              <pre class="case-caption-display pl-md-4" v-text="caseInstance.caseCaption"></pre>
            </div>
            <v-divider vertical/>
            <div class="d-flex justify-center" style="flex:0 0 50%;">
              <div>
                <div class="label text-left">
                  {{ $t('page.caseView.banner.caseNumber') }}
                </div>
                <div>
                  {{ caseInstance.caseNumber }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <v-row no-gutters>
            <v-col cols="12" class="pb-2 px-3 px-md-6 px-xl-6">
              <v-divider/>
            </v-col>
            <!-- condensed view -->
            <v-col cols="12" xl="6">
              <dl class="d-grid dense-grid pr-2">
                <dt>{{ $t('page.caseView.banner.classification') }}</dt>
                <dd>{{ caseInstance.caseClassification }}</dd>
                <dt>{{ $t('page.caseView.banner.filedDate') }}</dt>
                <dd>{{ caseInstance.filedDate | formatDateTime }}</dd>
                <dt class="d-xl-none">{{ $t('page.caseView.caseViewBannerCard.openclosed') }}</dt>
                <dd class="d-xl-none">{{ caseInstance.closedFlag ? $t('global.closed') : $t('global.open') }}</dd>
                <template v-if="isVerifiedParty">
                  <dt class="d-xl-none">{{ $t('page.caseView.banner.statusDate') }}</dt>
                  <dd class="d-xl-none">{{ caseInstance.caseStatusDate | formatDateTime }}</dd>
                </template>
              </dl>
            </v-col>
            <!-- wide view -->
            <v-col cols="6" class="d-none d-xl-block">
              <dl class="d-grid dense-grid pl-2">
                <dt>{{ $t('page.caseView.caseViewBannerCard.openclosed') }}</dt>
                <dd>{{ caseInstance.closedFlag ? $t('global.closed') : $t('global.open') }}</dd>
                <template v-if="isVerifiedParty">
                  <dt>{{ $t('page.caseView.banner.statusDate') }}</dt>
                  <dd>{{ caseInstance.caseStatusDate | formatDateTime }}</dd>
                </template>
              </dl>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <case-groups-popup v-model="caseGroupsPopupVisible" :courtID="courtID" :caseInstance="caseInstance"/>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import CaseGroupsPopup from '@/components/support/caseView/CaseGroupsPopup';
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { CaseGroupsPopup, LoadingCard },
  props: {
    courtID: {
      type: String
    },
    caseInstance: {
      type: Object
    },
    isVerifiedParty: {
      type: Boolean
    }
  },
  data () {
    return {
      caseGroupsPopupVisible: false
    };
  },
  computed: {
    courtName () {
      return this.$store.getters['application/getCourtByID'](this.courtID).displayName;
    },
    judgeName () {
      let result = null;
      if (!_.isNil(this.caseInstance.judgeActorInstances) && this.caseInstance.judgeActorInstances.length > 0)
      {
        result = this.caseInstance.judgeActorInstances[0].displayName;
      }

      return result;
    },
    hasGroups () {
      return this.caseInstance.caseGroupFlag === true;
    },
    isSingleCaseLocationMode () {
      return this.$store.getters['application/isSingleCaseLocationMode'];
    }
  },
  watch: {
    caseInstance (newValue) {
      if (newValue === null)
      {
        this.caseGroupsPopupVisible = false;
      }
    }
  }
};
</script>