<template>
  <div>
    <v-data-table
      :items="items"
      :headers="headers"
      :sort-by="sort.sortBy"
      :sort-desc="sort.sortDesc"
      :server-items-length="page.totalElements"
      :loading="loading"
      item-key="searchResultKey"
      must-sort
      hide-default-footer
      class="elevation-2"
      :mobile-breakpoint="800"
      @update:options="updateOptions">
      <template #top>
        <v-alert
          :value="readOnly && page.totalElements > CONST.CASE_VIEW_PRINTABLE_MAX_TABLE_RESULTS"
          class="mb-1 rounded-b-0"
          icon="mdi-alert-box"
          color="warning"
          text>
          <div class="font-weight-medium" style="font-size:1.1rem;line-height:1.5">
            {{ $t('global.warning') }}
          </div>
          <div class="mt-2" style="max-width:120ch">
            {{ $t('page.caseView.tab.printableLimitWarning', [CONST.CASE_VIEW_PRINTABLE_MAX_TABLE_RESULTS]) }}
          </div>
        </v-alert>

        <div class="d-flex align-center pt-3 px-4" style="min-height:44px">
          <div class="c-caption" style="flex:1 1 auto">
            {{ $t('page.caseView.tab.docketEntries') }}
          </div>
          <div v-if="!readOnly" class="text-right d-print-none" style="flex:1 1 auto">
            <v-btn icon color="primary" @click="toggleDrawer">
              <v-icon>
                mdi-sort
              </v-icon>
              <span class="d-sr-only">
                {{ $t('global.sort') }}
              </span>
            </v-btn>
          </div>
        </div>
      </template>
      <template #item.filedDate="{ value }">
        {{ value | formatDateTime }}
      </template>
      <template #item.submittedBy="{ value }">
        <div v-for="p in sortParties(value)" :key="p.partyActorInstance.sortName">
          {{ p.partyActorInstance.sortName }}
        </div>
      </template>
      <template #item.docketEntryUUID="{ item }">
        <v-btn v-if="item.documentCount > 0" icon color="primary" @click="openDocumentPopup(item.docketEntryUUID)">
          <span class="d-sr-only">
            {{ $t('application.button.viewDocument') }}
          </span>
          <v-icon>mdi-file</v-icon>
        </v-btn>
      </template>
      <template #footer>
        <paging-controls :page="page" @changePage="changePage" :read-only="readOnly"/>
      </template>
    </v-data-table>

    <document-popup v-model="documentPopupVisible"
                    :courtID="courtID"
                    :docketEntryUUID="docketEntryUUID"
                    :caseInstanceUUID="caseInstanceUUID"/>
  </div>
</template>

<script>
import DocumentPopup from '@components/support/DocumentPopup';
import CaseViewTabMixin from '@/mixins/caseViewTab';
import _ from 'lodash';

export default {
  components: { DocumentPopup },
  mixins: [ CaseViewTabMixin ],
  data () {
    return {
      docketEntryUUID: null,
      documentPopupVisible: false
    };
  },
  methods: {
    openDocumentPopup (docketEntryUUID) {
      this.documentPopupVisible = true;
      this.docketEntryUUID = docketEntryUUID;
    },
    sortParties (parties) {
      return _.sortBy(parties, 'partyActorInstance.sortName').slice(0, 25);
    }
  }
};
</script>