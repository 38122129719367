<template>
  <loading-card :loading="loading" class="nav-tab-medium">
    <template #default>
      <v-card-title>
        {{ $t('page.admin.userAccount.orgDetails.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.admin.userAccount.orgDetails.description') }}
      </v-card-subtitle>
      <v-card-text>
        <cutout-area v-if="organization === null" class="py-4">
          {{ $t('page.admin.userAccount.orgDetails.notLinked') }}
        </cutout-area>
        <dl v-else class="d-grid my-3">
          <dt>
            {{ $t('page.manageOrg.orgDetails.name') }}
          </dt>
          <dd>
            {{ organization.organizationDetails.sortName }}
          </dd>
          <dt>
            {{ $t('page.manageOrg.orgDetails.type') }}
          </dt>
          <dd>
            <div v-for="at in organization.organizationDetails.actorTypeList" :key="at.actorTypeID">
              {{ at.actorTypeName }}
            </div>
          </dd>
          <dt>
            {{ $t('page.manageOrg.orgDetails.address') }}
          </dt>
          <dd>
            <render-address :formattedAddress="organization.organizationDetails.formattedAddress"/>
          </dd>
          <dt>
            {{ $t('page.manageOrg.orgDetails.administrators') }}
          </dt>
          <dd>
            <div v-for="admin in organization.organizationDetails.organizationAdministrators"
                 :key="admin">
              {{ admin }}
            </div>
          </dd>
        </dl>
      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import RenderAddress from '@components/support/RenderAddress';
import CutoutArea from '@components/support/CutoutArea';
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { LoadingCard, RenderAddress, CutoutArea },
  props: {
    userID: {
      type: String
    }
  },
  data () {
    return {
      loading: true,
      organization: null
    };
  },
  created () {
    let url = this.$cove.getAPI({
      name: 'api.user',
      params: {
        userID: this.userID
      },
      query: {
        fields: '*,organization(*,organizationDetails(*)) '
      }
    });

    axios.get(url)
      .then((response) => {
        this.organization = _.defaultTo(response.data.organization, null);
      })
      .finally(() => {
        this.loading = false;
      });
  }
};
</script>