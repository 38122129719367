<template>
  <div>
    <loading-card :loading="loading" class="nav-tab-small">
      <template #default>
        <v-card-title>
          {{ $t('page.admin.userAccount.accountInformation.title') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('page.admin.userAccount.accountInformation.description') }}
        </v-card-subtitle>
        <v-card-text class="mt-3 pb-9">
          <dl class="d-grid">
            <dt>{{ $t('page.admin.userAccount.accountInformation.username') }}</dt>
            <dd>{{ user.username }}</dd>
            <dt>{{ $t('page.admin.userAccount.accountInformation.firstName') }}</dt>
            <dd>{{ firstName }}</dd>
            <dt>{{ $t('page.admin.userAccount.accountInformation.lastName') }}</dt>
            <dd>{{ lastName }}</dd>
            <dt>{{ $t('page.admin.userAccount.accountInformation.emailAddress') }}</dt>
            <dd>{{ email }}</dd>
            <dt>{{ $t('page.admin.userAccount.accountInformation.accountActive') }}</dt>
            <dd>{{ $t(user.usable ? 'global.yes' : 'global.no') }}</dd>
            <template v-if="user.apiUser">
              <dt>{{ $t('page.admin.userAccount.accountInformation.apiUser') }}</dt>
              <dd>
                <div>{{ $t('global.yes') }}</div>
                <div>
                  <button class="link-button mt-1" type="button" @click="openRotateCredentialsPopup">
                    {{ $t('page.admin.userAccount.apiUserAccount.button.rotateCredentials') }}
                  </button>
                </div>
              </dd>
            </template>
          </dl>
        </v-card-text>
        <v-card-actions v-if="!isCurrentUser" class="justify-end">
          <c-btn v-if="user.usable" type="danger" @click="deactivateAccount">
            {{ $t('page.admin.userAccount.accountInformation.button.deactivateAccount') }}
          </c-btn>
          <c-btn v-else type="primary" @click="activateAccount">
            {{ $t('page.admin.userAccount.accountInformation.button.activateAccount') }}
          </c-btn>
        </v-card-actions>
      </template>
    </loading-card>

    <rotate-credentials-popup v-model="rotateCredentialsPopupVisible" :userID="userID"/>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import LoadingCard from '@components/support/LoadingCard';
import RotateCredentialsPopup from '@components/support/RotateCredentialsPopup';

export default {
  components: { LoadingCard, RotateCredentialsPopup },
  props: {
    userID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      user: null,
      rotateCredentialsPopupVisible: false
    };
  },
  computed: {
    firstName () {
      return _.get(this.user, 'actor.firstName', null);
    },
    lastName () {
      return _.get(this.user, 'actor.lastName', null);
    },
    email () {
      return _.get(this.user, 'actor.contacts.primaryEmail', null);
    },
    isCurrentUser () {
      return this.$store.state.user.userID === this.userID;
    }
  },
  created () {
    this.loadUser()
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    activateAccount () {
      this.$cove.confirm({ message: this.$t('page.admin.userAccount.activateAccount.confirmation') })
        .then((confirmed) => {
          if (confirmed)
          {
            this.updateUsableFlag(true);
          }
        });
    },
    deactivateAccount () {
      this.$cove.confirm({ message: this.$t('page.admin.userAccount.deactivateAccount.confirmation') })
        .then((confirmed) => {
          if (confirmed)
          {
            this.updateUsableFlag(false);
          }
        });
    },
    updateUsableFlag (value) {
      this.$cove.block({ delay: 0 });
      axios.patch(this.$cove.getAPI({ name: 'api.user', params: { userID: this.userID } }), { usable: value })
        .then(() => {
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
          return this.loadUser();
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    openRotateCredentialsPopup () {
      this.$cove.confirm({ message: this.$t('page.admin.userAccount.rotateCredentials.confirmation') })
        .then((confirmed) => {
          if (confirmed)
          {
            this.rotateCredentialsPopupVisible = true;
          }
        });
    },
    loadUser () {
      let url = this.$cove.getAPI({
        name: 'api.user',
        params: {
          userID: this.userID
        },
        query: {
          fields: '*,actor(*)'
        }
      });

      return axios.get(url)
        .then((response) => {
          this.user = response.data;
        });
    }
  }
};
</script>