<template>
  <loading-card :loading="loading" class="nav-tab-medium">
    <template #default>
      <v-card-title>
        {{ $t('page.admin.userAccount.subscription.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.admin.userAccount.subscription.description') }}
      </v-card-subtitle>
      <v-card-text>

        <!-- subscription via parent org -->
        <mini-card v-if="hasOrganizationSubscription" class="mt-4 mb-8">
          <v-card-text class="pa-6">
            <div class="d-flex" style="gap:24px">
              <div class="flex-grow-0">
                <v-icon color="primary" style="font-size:50px">
                  mdi-calendar-sync
                </v-icon>
              </div>
              <v-divider vertical/>
              <div class="flex-grow-1 text-minimum-gray align-self-center" style="max-width:300px;">
                {{ $t('page.admin.userAccount.subscription.userHasOrgSub') }}
              </div>
            </div>
          </v-card-text>
        </mini-card>

        <!-- user's own personal subscription -->
        <mini-card v-else-if="hasSubscription" class="mt-4 mb-8">
          <v-card-text>
            <div class="d-flex pa-2" style="gap:24px">
              <div class="flex-grow-0">
                <v-icon color="primary" style="font-size:50px">
                  mdi-calendar-sync
                </v-icon>
              </div>
              <v-divider vertical/>
              <div class="flex-grow-1" style="width:300px">
                <dl class="d-grid">
                  <dt>{{ $t('subscription.type') }}</dt>
                  <dd>{{ subscription.subscriptionType.displayName }}</dd>
                  <dt>{{ $t('subscription.status') }}</dt>
                  <dd>{{ subscription.subscriptionStatus.displayName }}</dd>
                  <dt>{{ $t('subscription.renewalDate') }}</dt>
                  <dd>{{ renewalDate | formatDate }}</dd>
                  <dt>{{ $t('subscription.renewalAmount') }}</dt>
                  <dd>{{ subscription.renewalAmount | formatCurrency }}</dd>
                </dl>
              </div>
            </div>
          </v-card-text>
          <v-card-actions v-if="isGrantedSubscription && isValidSubscription" class="justify-end mt-4">
            <c-btn type="danger" @click="removeGrantedSubscription">
              {{ $t('subscription.removeGrantedSubscription') }}
            </c-btn>
          </v-card-actions>
        </mini-card>

        <!-- no subscription on record for this user -->
        <cutout-area v-else class="mt-4 mb-8">
          {{ $t('page.admin.userAccount.subscription.notValid') }}
          <template #action>
            <c-btn type="primary" @click="grantSubscription">
              {{ $t('subscription.grantSubscription') }}
            </c-btn>
          </template>
        </cutout-area>

      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import CutoutArea from '@components/support/CutoutArea';
import LoadingCard from '@components/support/LoadingCard';
import MiniCard from '@components/support/MiniCard';
import { calculateSubscriptionRenewalDate } from '@/application/application';

export default {
  components: { LoadingCard, CutoutArea, MiniCard },
  props: {
    userID: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      subscription: null,
      hasOrganizationSubscription: false
    };
  },
  computed: {
    hasSubscription () {
      return !_.isNil(this.subscription);
    },
    renewalDate () {
      return calculateSubscriptionRenewalDate(this.subscription.subscriptionDetail.startDate);
    },
    isGrantedSubscription () {
      return _.get(this.subscription, 'subscriptionType.grantedSubscription', false);
    },
    isValidSubscription () {
      return _.get(this.subscription, 'subscriptionStatus.validSubscription', false);
    }
  },
  created () {
    this.loadData();
  },
  methods: {
    loadData () {
      this.loading = true;

      let url = this.$cove.getAPI({
        name: 'api.user',
        params: {
          userID: this.userID
        },
        query: {
          fields: '*,subscription(*,subscriptionStatus(*),subscriptionType(*)),hasOrganizationSubscription'
        }
      });

      axios.get(url)
        .then((response) => {
          this.subscription = _.get(response, 'data.subscription', null);
          this.hasOrganizationSubscription = _.get(response, 'data.hasOrganizationSubscription', false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    grantSubscription () {
      this.$cove.confirm({ message: this.$t('page.manageUser.subscription.confirmGrantedSub') })
        .then((confirm) => {
          if (confirm)
          {
            let grantSubRequest = {
              subscriptionTypeID: this.CONST.SUBSCRIPTION_TYPE_PERSONAL_GRANTED,
              userID: this.userID,
              additionalSeats: 1
            };
            this.$cove.block({ delay: 0 });
            axios.post(this.$cove.getAPI({ name: 'api.subscriptions' }), grantSubRequest)
              .then(() => {
                this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
                this.loadData();
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    },
    removeGrantedSubscription () {
      this.$cove.confirm({ message: this.$t('page.manageUser.subscription.confirmRemoveGrantedSub') })
        .then((confirm) => {
          if (confirm)
          {
            this.$cove.block({ delay: 0 });
            axios.delete(this.$cove.getAPI({ name: 'api.subscription', params: { id: this.subscription.resourceID } }))
              . then(() => {
                this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
                this.loadData();
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    }
  }
};
</script>