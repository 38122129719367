<template>
  <div>
    <transition name="scroll-y-transition" mode="out-in">
      <form :key="searchDTO.advanced">
        <v-row no-gutters class="mt-4">
          <v-col v-if="!isSingleCourtMode" cols="12">
            <c-autocomplete
              :items="courts"
              v-model="searchDTO.courtID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('page.searchCriteria.calendar.field.court')"
              key="courtID"
              @change="changeCourt"
              outlined
              hide-details="auto"
              aria-describedby="fe0d2b9b-d89c-4c0f-8cc3-d9646f5e0987"/>
            <field-description id="fe0d2b9b-d89c-4c0f-8cc3-d9646f5e0987"
                               message-key="page.searchCriteria.calendar.field.court.description"/>
          </v-col>
          <v-col v-if="!isSingleCalendarLocationMode" cols="12">
            <c-autocomplete
              v-model="searchDTO.calendar.locationID"
              :items="calendarLocations"
              item-text="locationName"
              item-value="locationID"
              :label="$t('page.searchCriteria.calendar.field.location')"
              :no-data-text="$t('global.courtFirst')"
              outlined
              hide-details="auto"
              aria-describedby="bc614254-3be9-4db0-8499-c802a2e64ce4"/>
            <field-description id="bc614254-3be9-4db0-8499-c802a2e64ce4"
                               message-key="page.searchCriteria.calendar.field.location.description"/>
          </v-col>
          <v-col cols="12">
            <c-autocomplete
              v-model="searchDTO.calendar.courtSessionTypeID"
              :items="courtSessionTypes"
              item-text="courtSessionTypeName"
              item-value="courtSessionTypeID"
              :label="$t('page.searchCriteria.calendar.field.sessionType')"
              outlined
              hide-details="auto"
              aria-describedby="acdef06a-9d4e-4968-98c1-3f0037da4aeb"/>
            <field-description id="acdef06a-9d4e-4968-98c1-3f0037da4aeb"
                               message-key="page.searchCriteria.calendar.field.sessionType.description"/>
          </v-col>
          <v-col cols="12">
            <date-range-picker
              :label="$t('page.searchCriteria.calendar.field.date')"
              :labelStart="$t('page.searchCriteria.calendar.field.dateFrom')"
              :labelEnd="$t('page.searchCriteria.calendar.field.dateTo')"
              keyPrefix="calendarDate"
              offer-future-dates
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.calendar.calendarDateChoice"
              :startDate.sync="searchDTO.calendar.calendarDateStart"
              :endDate.sync="searchDTO.calendar.calendarDateEnd"
              aria-describedby="a36446ea-605e-4b75-afab-7fe2d0159a67"/>
            <field-description id="a36446ea-605e-4b75-afab-7fe2d0159a67"
                               message-key="page.searchCriteria.calendar.field.date.description"/>
          </v-col>
        </v-row>
      </form>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import FieldDescription from '@components/support/FieldDescription';
import DateRangePicker from '@components/support/DateRangePicker';
import SearchCriteriaMixin from '@/mixins/searchCriteria';

export default {
  components: { FieldDescription, DateRangePicker },
  mixins: [ SearchCriteriaMixin ],
  data () {
    return {
      departments: [],
      courtSessionTypes: []
    };
  },
  computed: {
    calendarLocations () {
      return this.$store.getters['application/getCalendarLocationsByCourt'](this.searchDTO.courtID);
    },
    isSingleCalendarLocationMode () {
      return this.$store.getters['application/isSingleCalendarLocationMode'];
    }
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.courtSessionTypes' }))
      .then((response) => {
        this.courtSessionTypes = _.defaultTo(response.data, []);
      });
  },
  watch: {
    'searchDTO.courtID': {
      immediate: true,
      handler (newValue) {
        if (!_.isNil(newValue))
        {
          axios.get(this.$cove.getAPI({ name: 'api.court.departments', params: { courtID: newValue } }))
            .then((response) => {
              this.departments = _.defaultTo(response.data, []);
            });
        }
        else
        {
          this.departments = [];
        }
      }
    },
    'searchDTO.advanced' (newValue) {
      if (newValue)
      {
        this.searchDTO.calendar.judgeName = null;
        this.searchDTO.calendar.judgeFirstNameQueryTypeID = this.getDefaultQueryType(true);
        this.searchDTO.calendar.judgeLastNameQueryTypeID = this.getDefaultQueryType(true);

        // only change this query type if they didn't enter anything yet
        if (this.searchDTO.calendar.calendarName === null)
        {
          this.searchDTO.calendar.calendarNameQueryTypeID = this.getDefaultQueryType(true);
        }
      }
    }
  },
  methods: {
    changeCourt () {
      this.searchDTO.calendar.locationID = null;
      this.searchDTO.calendar.departmentActorInstanceUUID = null;
    }
  }
};
</script>