import CONST from '@/application/constants';
import cove from '@ctrack/cove';
import _ from 'lodash';
import axios from 'axios';
import router from '@/plugins/router';
import i18n from '@/plugins/i18n';
import store from '@/plugins/store';
import { saveDocument } from '@/application/application';

/**
 * Defines several configuration points that can be customized based upon the specified user type access request being created.
 *
 * @param {String} userTypeID
 * @returns
 */
export function getUserAccessConfig (userTypeID)
{
  // default core config is the same for all user type access requests
  return {
    requireAddress: true,
    requireContactPhone: true,
    requireFile: true,
    requireComments: false
  };
}

/**
 * Forwards the user along to the specified user request access workflow. Performs some validation checks first to ensure they are allowed
 * to make the request.
 *
 * @param {String} userTypeID
 * @param {Object} queryParams
 */
export function forwardToRequestAccess (userTypeID, queryParams = {})
{
  // do not let them proceed if they aren't logged in
  if (!store.state.user.hasSession)
  {
    cove.notify({ color: 'warning', message: i18n.t('page.requestAccess.warning.mustBeLoggedIn') });
    return;
  }

  // public (case access) requests can always be made, so just forward them along
  if (userTypeID === CONST.USER_TYPE_PUBLIC)
  {
    router.push({ name: 'requestAccess', params: { userTypeID }, query: { ...queryParams } });
  }
  // for all other types verify there are no pending requests of that type first
  else
  {
    let query = { userTypeID, userRequestStatusID: CONST.USER_REQUEST_STATUS_PENDING };
    axios.get(cove.getAPI({ name: 'api.user.requests', query }))
      .then((response) => {
        let pendingRequests = _.get(response, 'data._embedded.results', []);
        if (pendingRequests.length > 0)
        {
          cove.notify({ color: 'warning', message: i18n.t('page.requestAccess.warning.alreadyRequested') });
        }
        else
        {
          router.push({ name: 'requestAccess', params: { userTypeID }, query: { ...queryParams } });
        }
      });
  }
}

/**
 * Accepts the information for making a user access requests and performs the necessary AJAX calls.
 *
 * @param {Object}
 */
export function submitRequest ({ file, bean }) {
  cove.block({ delay: 0 });

  // if a file was specified, upload it first as we need its identifer for the user access request
  let documentLinkID = null;
  let saveDocumentPromise = Promise.resolve();
  if (file !== null)
  {
    saveDocumentPromise = saveDocument(CONST.SYSTEM_TABLE_USER_REQUEST, 'User Request', file)
      .then((value) => {
        documentLinkID = value;
      });
  }

  // after dealing with the document, save the user access request
  saveDocumentPromise
    .then(() => {
      if (!_.isNil(documentLinkID))
      {
        bean.documentLinkID = documentLinkID;
      }

      return axios.post(cove.getAPI({ name: 'api.user.requests' }), bean)
        .then(() => {
          cove.notify({ message: i18n.t('application.save.success'), color: 'success' });
          router.push({ name: 'user-profile' });
        });
    })
    .finally(() => {
      cove.unblock();
    });
}