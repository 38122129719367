import cove from '@ctrack/cove';
import CONST from '@/application/constants';

/*
  Define all API endpoints here. A 'path' property is required and may contain path parameter arguments prefixed
  by a colon. An optional 'query' object can also be specified for any static query string values.
 */
cove.setAPIs({
  'api.alias.types': {
    path: '/aliastypes',
    query: { size: CONST.PAGING_MAX_SIZE }
  },
  'api.announcement': {
    path: '/announcements/:id'
  },
  'api.announcement.markRead': {
    path: '/announcements/markread/:id'
  },
  'api.announcements': {
    path: '/announcements'
  },
  'api.application.locales': {
    path: '/locales',
    query: { size: CONST.PAGING_MAX_SIZE }
  },
  'api.application.properties': {
    path: '/manage/info'
  },
  'api.case.charges': {
    path: '/courts/:courtID/cms/cases/:caseUUID/charges'
  },
  'api.case.citations': {
    path: '/courts/:courtID/cms/cases/:caseUUID/citations'
  },
  'api.case.claims': {
    path: '/courts/:courtID/cms/cases/:caseUUID/claims'
  },
  'api.case.docketentries': {
    path: '/courts/:courtID/cms/cases/:caseUUID/docketentries'
  },
  'api.case.docketentrydocuments': {
    path: '/courts/:courtID/cms/case/:caseInstanceID/docketentrydocuments/:documentLinkUUID'
  },
  'api.case.exists': {
    path: '/courts/:courtID/cms/cases/caseExists'
  },
  'api.case.groups': {
    path: '/courts/:courtID/cms/cases/:caseUUID/groups'
  },
  'api.case.hearings': {
    path: '/courts/:courtID/cms/cases/:caseUUID/hearings'
  },
  'api.case.judgments': {
    path: '/courts/:courtID/cms/cases/:caseUUID/judgments'
  },
  'api.case.parties': {
    path: '/courts/:courtID/cms/cases/:caseUUID/parties'
  },
  'api.case.verifiedCaseParty': {
    path: '/courts/:courtID/cms/cases/:caseUUID/parties/verify'
  },
  'api.cms.organizations': {
    path: '/cms/actors/organizations'
  },
  'api.confidential.reasons': {
    path: '/confidentialreasons',
    query: { size: CONST.PAGING_MAX_SIZE }
  },
  'api.config.export': {
    path: '/config/export'
  },
  'api.config.import': {
    path: '/config/import'
  },
  'api.countries': {
    path: '/cms/countries'
  },
  'api.court.case': {
    path: '/courts/:courtID/cms/cases/:caseUUID'
  },
  'api.court.case.categories': {
    path: '/courts/:courtID/cms/casecategories'
  },
  'api.court.case.classification': {
    path: '/courts/:courtID/cms/caseclassifications/:caseClassID'
  },
  'api.court.departments': {
    path: '/courts/:courtID/cms/departments'
  },
  'api.court.docketEntry.subtypes': {
    path: '/courts/:courtID/cms/docketentrysubtypes'
  },
  'api.court.docketEntryDocuments.access': {
    path: '/courts/cms/docketentrydocumentsaccess'
  },
  'api.court.publication': {
    path: '/courts/:courtID/cms/publication/:publicationUUID'
  },
  'api.courtSessionTypes': {
    path: '/cms/courtsessiontypes'
  },
  'api.courts': {
    path: '/courts',
    query: { size: CONST.PAGING_MAX_SIZE }
  },
  'api.dms.document': {
    path: '/dms/documents/:documentLinkID'
  },
  'api.dms.document.types': {
    path: '/dms/documents/types'
  },
  'api.dms.documents': {
    path: '/dms/documents'
  },
  'api.event.hearings': {
    path: '/courts/:courtID/cms/events/:eventID/hearings'
  },
  'api.event.eventAttributeStatues': {
    path: '/courts/cms/event/eventattributestatuses'
  },
  'api.filing': {
    path: '/filings/:id'
  },
  'api.filing.caseParties': {
    path: '/caseparties'
  },
  'api.filing.caseParty': {
    path: '/caseparties/:id'
  },
  'api.filing.clone': {
    path: '/filings/:id/clone'
  },
  'api.filing.docketEntries': {
    path: '/docketentries'
  },
  'api.filing.docketEntry': {
    path: '/docketentries/:id'
  },
  'api.filing.docketEntry.document': {
    path: '/docketentries/documents/:id'
  },
  'api.filing.docketEntry.documents': {
    path: '/docketentries/documents'
  },
  'api.filing.filerCandidates': {
    path: '/filings/:id/filercandidates'
  },
  'api.filing.recipients': {
    path: '/filings/:id/recipients'
  },
  'api.filing.serviceMethods': {
    path: '/filingservicemethods',
    query: { size: CONST.PAGING_MAX_SIZE }
  },
  'api.filing.service': {
    path: '/filingservices/:serviceID'
  },
  'api.filing.service.markread': {
    path: '/filingservices/:serviceID/markread'
  },
  'api.filing.services': {
    path: '/filingservices'
  },
  'api.filing.services.user': {
    path: '/filingservices/userservices'
  },
  'api.filing.statusHistory': {
    path: '/filings/:id/history'
  },
  'api.filing.status': {
    path: '/filingstatuses/:resourceID'
  },
  'api.filing.statuses': {
    path: '/filingstatuses',
    query: { size: CONST.PAGING_MAX_SIZE }
  },
  'api.filing.steps': {
    path: '/filings/:id/steps'
  },
  'api.filing.submit': {
    path: '/filings/:id/submit'
  },
  'api.filing.type': {
    path: '/courts/:courtID/cms/filingtypes/:filingTypeID'
  },
  'api.filing.types': {
    path: '/courts/:courtID/cms/filingtypes'
  },
  'api.filings': {
    path: '/filings'
  },
  'api.interpreter.classifications': {
    path: '/cms/interpreter/classifications'
  },
  'api.interpreter.languages': {
    path: '/cms/interpreter/languages'
  },
  'api.judgment.statuses': {
    path: '/cms/judgmentstatuses'
  },
  'api.judgment.types': {
    path: '/cms/judgmenttypes'
  },
  'api.kiosk': {
    path: '/kiosk'
  },
  'api.legalrepresentermap': {
    path: '/legalrepresentermaps/:id'
  },
  'api.legalrepresentermaps': {
    path: '/legalrepresentermaps'
  },
  'api.messageerrors': {
    path: '/messageerrors'
  },
  'api.messageerrors.requeue': {
    path: '/messageerrors/requeue'
  },
  'api.messageresources': {
    path: '/messageresources'
  },
  'api.notification.methods': {
    path: '/notificationmethods',
    query: { size: CONST.PAGING_MAX_SIZE }
  },
  'api.notification.preferences': {
    path: '/notificationpreferences'
  },
  'api.notification.types': {
    path: '/notificationtypes',
    query: { size: CONST.PAGING_MAX_SIZE }
  },
  'api.notification.statuses': {
    path: '/notificationstatuses',
    query: { size: CONST.PAGING_MAX_SIZE }
  },
  'api.notifications.markRead': {
    path: '/notifications/markread'
  },
  'api.notifications': {
    path: '/notifications'
  },
  'api.organization': {
    path: '/organizations/:id'
  },
  'api.organization.nameSearch': {
    path: '/organizations/namesearch'
  },
  'api.organization.user': {
    path: '/organizations/users/:id'
  },
  'api.organization.users': {
    path: '/organizations/users'
  },
  'api.organization.users.newUserSearch': {
    path: '/organizations/users/search'
  },
  'api.quicklinks': {
    path: '/quicklinks'
  },
  'api.pageHelpMessage': {
    path: '/pagehelpmessages/:resourceID'
  },
  'api.pageHelpMessages': {
    path: '/pagehelpmessages'
  },
  'api.pageHelpMessages.search': {
    path: '/pagehelpmessages/search',
    query: {
      fields: '*,locale(*),configurationGroup(*)'
    }
  },
  'api.party.subtypes': {
    path: '/courts/casepartysubtypes'
  },
  'api.paymentToken': {
    path: '/paymenttokens/:id'
  },
  'api.paymentTokens.request': {
    path: '/paymenttokens/request'
  },
  'api.receipt': {
    path: '/receipts/:id'
  },
  'api.receipt.preview': {
    path: '/receipts/preview'
  },
  'api.receipt.checkout': {
    path: '/receipts/checkout'
  },
  'api.receipt.checkout.confirmation': {
    path: '/receipts/checkout/confirmation'
  },
  'api.receipts.search': {
    path: '/receipts',
    query: {
      fields: '*,receiptType(*),receiptStatus(*),user(*,actor(*)),organization(*)'
    }
  },
  'api.receipts': {
    path: '/receipts/:id',
    query: {
      fields: '*,receiptType(*),receiptStatus(*),receiptItems(*,receiptItemDetails(*)),user(*,actor(*))'
    }
  },
  'api.receipt.statuses': {
    path: '/receiptstatuses',
    query: { size: CONST.PAGING_MAX_SIZE }
  },
  'api.receipt.types': {
    path: '/receipttypes',
    query: { size: CONST.PAGING_MAX_SIZE }
  },
  'api.purchasedDocuments': {
    path: '/purchaseddocuments'
  },
  'api.purchasedDocuments.search': {
    path: '/purchaseddocuments',
    query: {
      fields: '*,court(*),receipt(*)'
    }
  },
  'api.purchasedDocument': {
    path: '/purchaseddocuments/:id'
  },
  'api.purchasedDocument.cart': {
    path: '/purchaseddocuments/cart'
  },
  'api.regions': {
    path: '/cms/regions'
  },
  'api.representers': {
    path: '/cms/actors/representers'
  },
  'api.roles': {
    path: '/roles',
    query: { size: CONST.PAGING_MAX_SIZE }
  },
  'api.search.cases': {
    path: '/courts/cms/cases'
  },
  'api.search.caseNumber': {
    path: '/courts/:courtID/cms/cases/search'
  },
  'api.search.citations': {
    path: '/courts/cms/citations'
  },
  'api.search.documents': {
    path: '/courts/cms/docketentrydocuments'
  },
  'api.search.events': {
    path: '/courts/cms/events'
  },
  'api.search.judgments': {
    path: '/courts/cms/judgments'
  },
  'api.search.parties': {
    path: '/courts/cms/parties'
  },
  'api.search.publications': {
    path: '/courts/cms/publications'
  },
  'api.search.user.cases': {
    path: '/cms/user/cases'
  },
  'api.search.user.hearings': {
    path: '/cms/user/hearings'
  },
  'api.search.user.hearings.calendar': {
    path: '/cms/user/hearings/user/:userID/token/:token'
  },
  'api.search.user.invite': {
    path: '/cms/user/invites/:inviteID/type/:typeValue'
  },
  'api.search.user.invites': {
    path: '/cms/user/invites'
  },
  'api.search.user.invites.calendar': {
    path: '/cms/user/invites/user/:userID/token/:token'
  },
  'api.search.user.tasks': {
    path: '/cms/user/tasks'
  },
  'api.subscriptions.management': {
    path: '/subscriptions/management'
  },
  'api.subscription.reactivate': {
    path: '/subscriptions/:id/reactivate'
  },
  'api.subscription.renewal': {
    path: '/subscriptionrenewals/:id'
  },
  'api.subscription.renewal.executing': {
    path: '/subscriptionrenewals/executing'
  },
  'api.subscription.renewal.items': {
    path: '/subscriptionrenewalitems'
  },
  'api.subscription.renewal.summary': {
    path: '/subscriptionrenewals/:id/summary'
  },
  'api.subscription.renewals': {
    path: '/subscriptionrenewals'
  },
  'api.subscriptions': {
    path: '/subscriptions'
  },
  'api.subscription': {
    path: '/subscriptions/:id'
  },
  'api.subscription.purchase': {
    path: '/subscriptions/purchasesummary'
  },
  'api.task.types': {
    path: '/cms/task/types'
  },
  'api.task.statuses': {
    path: '/cms/task/statuses'
  },
  'api.user': {
    path: '/users/:userID'
  },
  'api.user.currentuser': {
    path: '/currentuser',
    query: {
      fields: '*,actor(*, mailingAddress(*)),locale(localeCode),court(*),interpreterInfo(*,languages(*)),userTypes(*)'
    }
  },
  'api.user.currentuser.pollingdata': {
    path: '/currentuser/pollingdata'
  },
  'api.users.namesearch': {
    path: '/users/namesearch'
  },
  'api.user.currentuser.interpreter': {
    path: '/currentuser/interpreter'
  },
  'api.user.requests': {
    path: '/userrequests'
  },
  // TODO: move these to api.search.user.*
  'api.user.task': {
    path: '/cms/user/tasks/:taskID'
  },
  'api.user.task.document': {
    path: '/cms/user/tasks/:taskID/document'
  },
  'api.user.terminateSession': {
    path: '/currentuser/session'
  },
  'api.usertypes': {
    path: '/usertypes'
  },
  'api.users': {
    path: '/users'
  },
  'api.user.rotatecredentials': {
    path: '/users/:userID/rotatecredentials'
  },
  'api.user.rotatecalendartoken': {
    path: '/users/:userID/rotatecalendartoken'
  },
  'api.userrolemap': {
    path: '/userrolemaps/:userRoleMapID'
  },
  'api.userrolemaps': {
    path: '/userrolemaps'
  },
  'api.configurationgroups': {
    path: '/configurationgroups',
    query: { size: CONST.PAGING_MAX_SIZE }
  },
  'api.notifications.resend': {
    path: '/notifications/resend'
  }
});