<template>
  <v-dialog :value="visible" width="560" persistent no-click-animation>
    <v-card elevation="0" class="pa-5">
      <h1 class="font-weight-regular">
        {{ $t('popup.sessionExpiry.title', [user.firstName]) }}
      </h1>
      <div class="mt-2 text-minimum-gray" style="font-size:1.1rem;">
        {{ $t('popup.sessionExpiry.description') }}
      </div>
      <v-divider class="mt-12 mb-4"/>
      <div class="d-flex" style="gap:12px">
        <div style="flex: 1 1 50%">
          <c-btn large block type="primary" id="stay-active-button" @click="keepAlive">
            {{ $t('global.yes') }}
          </c-btn>
        </div>
        <div style="flex: 1 1 50%">
          <c-btn large block @click="redirectToLogout">
            {{ $t('global.no') }}
          </c-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { focusElement } from '@/application/utility';
import { broadcastMessage } from '@/application/broadcast';
import { redirectToLogout, redirectToLogin, fetchCurrentUser, checkForSessionExpiry } from '@/application/application';

export default {
  computed: {
    visible () {
      return this.$store.state.application.userInactivityPopupVisible;
    },
    user () {
      return this.$store.state.user;
    }
  },
  watch: {
    visible (newValue) {
      if (newValue)
      {
        focusElement('#stay-active-button');
      }
    }
  },
  methods: {
    redirectToLogout,
    keepAlive () {
      fetchCurrentUser()
        .then((user) => {
          if (user === null)
          {
            redirectToLogin();
          }
          else
          {
            checkForSessionExpiry();
            broadcastMessage(this.CONST.BROADCAST_MESSAGE_KEEP_ALIVE);
          }
        });
    }
  }
};
</script>