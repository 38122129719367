<template>
  <div>
    <transition name="scroll-y-transition" mode="out-in">
      <form :key="searchDTO.advanced">
        <v-row no-gutters>
          <v-col cols="12">
            <section-separator :title="$t('searchType.document.criteriaSeparator')" class="my-4"/>
          </v-col>
          <v-col cols="12">
            <c-autocomplete
              v-model="searchDTO.document.docketEntrySubTypeID"
              :items="docketEntrySubTypes"
              :label="$t('page.searchCriteria.document.field.type')"
              item-text="text"
              item-value="value"
              outlined
              :no-data-text="$t('global.courtFirst')"
              hide-details="auto"
              aria-describedby="ab911ab6-884e-48d8-8415-a3eb07eebc7d"/>
            <field-description id="ab911ab6-884e-48d8-8415-a3eb07eebc7d"
                               message-key="page.searchCriteria.document.field.type.description"/>
          </v-col>
          <v-col cols="12">
            <date-range-picker
              :label="$t('page.searchCriteria.document.field.filedDate')"
              :labelStart="$t('page.searchCriteria.document.field.filedDateFrom')"
              :labelEnd="$t('page.searchCriteria.document.field.filedDateTo')"
              keyPrefix="filedDate"
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.document.docketEntryFiledDateChoice"
              :startDate.sync="searchDTO.document.docketEntryFiledDateStart"
              :endDate.sync="searchDTO.document.docketEntryFiledDateEnd"
              aria-describedby="a62b8d1f-37a3-4bc0-89fe-0aca78c8191a"/>
            <field-description id="a62b8d1f-37a3-4bc0-89fe-0aca78c8191a"
                               message-key="page.searchCriteria.document.field.filedDate.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.document.textAnyWords"
              :label="$t('page.searchCriteria.document.field.textAnyWords')"
              outlined
              maxlength="250"
              hide-details="auto"
              aria-describedby="a7a88856-0466-4c4e-848e-1eb6fc0f84cc"/>
            <field-description id="a7a88856-0466-4c4e-848e-1eb6fc0f84cc"
                               message-key="page.searchCriteria.document.field.textAnyWords.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.document.textAllWords"
              :label="$t('page.searchCriteria.document.field.textAllWords')"
              outlined
              maxlength="250"
              hide-details="auto"
              aria-describedby="a106ecc9-ff75-4219-8131-94b8d3a26e31"/>
            <field-description id="a106ecc9-ff75-4219-8131-94b8d3a26e31"
                               message-key="page.searchCriteria.document.field.textAllWords.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.document.textExactPhrase"
              :label="$t('page.searchCriteria.document.field.textExactPhrase')"
              outlined
              maxlength="250"
              hide-details="auto"
              aria-describedby="a2644247-c915-44f5-ac44-ba613b06b73a"/>
            <field-description id="a2644247-c915-44f5-ac44-ba613b06b73a"
                               message-key="page.searchCriteria.document.field.textExactPhrase.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.document.textNoneWords"
              :label="$t('page.searchCriteria.document.field.textNoneWords')"
              outlined
              maxlength="250"
              hide-details="auto"
              aria-describedby="a0605731-d694-4ae4-bdfa-27990728032a"/>
            <field-description id="a0605731-d694-4ae4-bdfa-27990728032a"
                               message-key="page.searchCriteria.document.field.textNoneWords.description"/>
          </v-col>
        </v-row>

        <nested-case-search-criteria :searchDTO="searchDTO" :searchType="searchType" @changeCourt="changeCourt" class="mt-0"/>
      </form>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import SectionSeparator from '@components/support/SectionSeparator';
import FieldDescription from '@components/support/FieldDescription';
import NestedCaseSearchCriteria from '@components/support/search/NestedCaseSearchCriteria';
import DateRangePicker from '@components/support/DateRangePicker';
import SearchCriteriaMixin from '@/mixins/searchCriteria';
import Search from '@/application/search';

export default {
  components: { SectionSeparator, FieldDescription, NestedCaseSearchCriteria, DateRangePicker },
  mixins: [ SearchCriteriaMixin ],
  data () {
    return {
      docketEntrySubTypes: []
    };
  },
  watch: {
    'searchDTO.courtID': {
      immediate: true,
      handler (newValue) {
        if (!_.isNil(newValue))
        {
          axios.get(this.$cove.getAPI({ name: 'api.court.docketEntry.subtypes', params: { courtID: newValue } }))
            .then((response) => {
              this.docketEntrySubTypes = Search.populateDocketEntrySubTypes(_.defaultTo(response.data, []));
            });
        }
        else
        {
          this.docketEntrySubTypes = [];
        }
      }
    }
  },
  methods: {
    changeCourt () {
      this.searchDTO.document.docketEntrySubTypeID = null;
    }
  }
};
</script>
