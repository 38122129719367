<template>
  <nav v-if="page.totalElements > 0 && !(hideOnSinglePage && page.totalPages === 1)"
       :class="`rounded ${color} ${solo ? 'elevation-2' : ''}`"
       :aria-label="$t('global.table.paginationTitle')">

    <v-divider v-if="!solo"/>

    <div class="d-flex align-center px-4 py-2">

      <div v-if="selectedCount > 0" style="flex: 0 0 auto;font-size:.95rem;" class="text-minimum-gray">
        {{ $t('global.table.numberSelected', [selectedCount]) }}
      </div>

      <div style="flex: 1 1 auto;min-height:36px" class="text-right d-flex align-center">
        <v-spacer/>

        <div class="text-minimum-gray" style="font-size:.95rem;">
          {{ $t('global.table.pageDisplay', [startRow, endRow, page.totalElements]) }}
        </div>

        <div v-if="!readOnly && page.totalPages > 1" class="ml-4 mr-n2 d-print-none">
          <v-tooltip top offset-overflow open-delay="400">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                :disabled="page.number === 0"
                @click="prevPage">
                <v-icon style="font-size:2rem">
                  mdi-chevron-left
                </v-icon>
                <span class="d-sr-only">
                  {{ $t('global.table.previousPage') }}
                </span>
              </v-btn>
            </template>
            {{ $t('global.table.previousPage') }}
          </v-tooltip>

          <v-tooltip top offset-overflow open-delay="400">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                :disabled="(page.number + 1) === page.totalPages"
                @click="nextPage">
                <v-icon style="font-size:2rem">
                  mdi-chevron-right
                </v-icon>
                <span class="d-sr-only">
                  {{ $t('global.table.nextPage') }}
                </span>
              </v-btn>
            </template>
            {{ $t('global.table.nextPage') }}
          </v-tooltip>
        </div>
      </div>
    </div>

    <div v-if="resultsOverflowed" class="text-center pb-3 warning--text font-weight-medium mt-n2">
      <div class="mx-auto" style="max-width:64ch">
        {{ $t('global.table.resultsOverflow', [maxResults]) }}
      </div>
    </div>
  </nav>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    // expects the following properties on page: size, number, totalElements, totalPages
    page: {
      type: Object
    },
    solo: {
      type: Boolean
    },
    hideOnSinglePage: {
      type: Boolean
    },
    selectedCount: {
      type: Number,
      default: 0
    },
    readOnly: { // when enabled shows a simplified display with just the count information -- no paging controls
      type: Boolean,
      default: false
    }
  },
  computed: {
    startRow () {
      return (this.page.number * this.page.size) + 1;
    },
    endRow () {
      return Math.min(this.startRow + this.page.size - 1, this.page.totalElements);
    },
    color () {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
    resultsOverflowed () {
      let totalElements = _.get(this, 'page.totalElements', 0);
      return totalElements >= this.maxResults;
    },
    maxResults () {
      return this.$store.getters['application/getAppProperty'](this.CONST.APP_PROPERTY_SEARCH_RESULTS_LIMIT);
    }
  },
  methods: {
    prevPage () {
      this.$emit('changePage', this.page.number - 1);
    },
    nextPage () {
      this.$emit('changePage', this.page.number + 1);
    }
  }
};
</script>