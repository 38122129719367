<template>
  <div class="d-flex justify-center">
    <v-card style="border:1px solid var(--line-color) !important;">
      <slot/>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>