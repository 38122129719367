import _ from 'lodash';
import { isTableSortClickEvent } from '@/application/utility';

const PAGING_DEFAULTS = {
  size: 25,
  page: 0,
  totalElements: 0,
  totalPages: 0,
  sortBy: null,
  sortDesc: true,
  searchCallback: () => {}
};

export default class Paging
{
  /**
	 * Standard constructor
	 *
	 * @param {Object} options an object allowing for overrides of the default paging settings
	 */
  constructor (options = {})
  {
    this.size = _.defaultTo(options.size, PAGING_DEFAULTS.size);
    this.page = _.defaultTo(options.page, PAGING_DEFAULTS.page);
    this.totalElements = _.defaultTo(options.totalElements, PAGING_DEFAULTS.totalElements);
    this.totalPages = _.defaultTo(options.totalPages, PAGING_DEFAULTS.totalPages);
    this.sortBy = _.defaultTo(options.sortBy, PAGING_DEFAULTS.sortBy);
    this.sortDesc = _.defaultTo(options.sortDesc, PAGING_DEFAULTS.sortDesc);
    this.searchCallback = _.defaultTo(options.searchCallback, PAGING_DEFAULTS.searchCallback);

    // have to permanently bind the updateOptions callback to this object otherwise the `this` context won't work correctly
    this.updateOptions = this.updateOptions.bind(this);
  }

  /**
   * Generates an object of query params suitable for our backend paging endpoints. Can also used to serialize the paging information
   * into the URL so that we can preserve the state of the search if the user moves on and then navigates back.
   *
   * @returns {Object}
   */
  generateParams ()
  {
    let params = {
      page: this.page,
      size: this.size
    };

    if (!_.isNil(this.sortBy))
    {
      params.sort = `${this.sortBy},${this.sortDesc ? 'desc' : 'asc'}`;
    }

    return params;
  }

  /**
   * Accepts an object of query parameters from a URL, parses out the paging related entries, and updates itself accordingly.
   *
   * @param {Object} queryParams
   */
  parseURLParams (queryParams)
  {
    if (!_.isNil(queryParams.page))
    {
      this.page = parseInt(queryParams.page);
    }

    if (!_.isNil(queryParams.size))
    {
      this.size = parseInt(queryParams.size);
    }

    if (!_.isNil(queryParams.sort))
    {
      let split = queryParams.sort.split(',');
      this.sortBy = split[0];
      this.sortDesc = split[1].toLowerCase() === 'desc';
    }
  }

  /**
  * Parses an axios response object to extract out the updated paging information.
  *
  * @param {Object} response axios response object
  */
  parseResponse (response)
  {
    let responsePage = _.get(response, 'data.page', null);
    if (responsePage !== null)
    {
      this.page = responsePage.number;
      this.totalPages = responsePage.totalPages;
      this.totalElements = responsePage.totalElements;
    }
  }

  /**
   * Should be used as the event callback on vuetify `@update:options` events. We examine the event to see if it's related to a manual click
   * on a table header which tells us it's a sorting type of event, and if so we'll update accordingly and initiate an update.
   *
   * @param {Object} options vuetify table updated options object
   */
  updateOptions (options)
  {
    if (isTableSortClickEvent())
    {
      let sortBy = options.sortBy[0];
      let sortDesc = options.sortDesc[0];
      this.sortBy = sortBy;
      this.sortDesc = sortDesc;

      this.page = 0;

      this.searchCallback();
    }
  }

  /**
   * Moves this paging object to the previous page and then initiates an update.
   */
  prevPage ()
  {
    this.page--;
    this.searchCallback();
  }

  /**
   * Moves this paging object to the next page and then initiates an update.
   */
  nextPage ()
  {
    this.page++;
    this.searchCallback();
  }
}