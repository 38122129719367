<template>
  <c-dialog
    :value="visible"
    width="600"
    close-key="cove.general.cancel"
    :title-key="isOrg ? 'popup.purchaseSubSeats.title' : 'popup.purchaseSubSeats.user.title'"
    @input="close">

    <validation-error-panel :error-scope="errorScope" class="ma-4 mb-2"/>

    <div v-if="visible" class="pa-4">
      <section-separator :title="$t('popup.purchaseSubSeats.section.orderForm')"/>
      <div v-if="isOrg">
        <div v-if="hasGrantedSubscription">
          {{ $t('popup.purchaseSubSeats.granted.description') }}
        </div>
        <div v-else>
          {{ $t('popup.purchaseSubSeats.description') }}
        </div>
      </div>
      <div v-else class="text-minimum-gray">
        {{ $t('popup.purchaseSubSeats.user.description') }}
      </div>
      <div v-if="isOrg">
        <section-separator title="Current Subscription Details" class="mt-4"/>
        <dl class="d-grid grid-5050 mt-2">
          <dt>{{ $t('subscription.activeSeats') }}</dt>
          <dd>{{ activeSeats }}</dd>
          <dt>{{ $t('subscription.openSeats') }}</dt>
          <dd>{{ openSeats }}</dd>
          <dt>{{ $t('subscription.totalSeats') }}</dt>
          <dd>{{ totalSeats }}</dd>
        </dl>

        <section-separator :title="$t('popup.purchaseSubSeats.section.purchase')" class="mt-4"/>
        <c-number
          v-model="numberOfSeats"
          :label="$t('popup.purchaseSubSeats.field.numberOfSeats')"
          :key="`${errorScope}:numberOfSeats`"
          ref="numberOfSeats"
          maxlength="3"
          required
          outlined
          class="mt-3"
          @input="seatsDebounce"
          hide-details="auto"
          aria-describedby="aaf34dbe-7d9e-4d19-a0d3-73e67a33af18"/>
        <field-description id="aaf34dbe-7d9e-4d19-a0d3-73e67a33af18"
                           message-key="popup.purchaseSubSeats.field.numberOfSeats.description"/>
      </div>

      <section-separator :title="$t('popup.purchaseSubSeats.section.orderSummary')" class="mt-8"/>
      <v-progress-linear v-if="loadingReceipt" indeterminate class="my-4"/>
      <template v-else-if="hasReceipt">
        <receipt-order-line-items :receipt="receipt" class="mt-1"/>
        <div v-if="hasGrantedSubscription">
          <v-divider class="my-3"/>
          <div class="d-flex font-weight-medium">
            <div style="flex: 1 1 50%">
              {{ $t('popup.purchaseSubSeats.dueToday') }}
            </div>
            <div class="text-right" style="flex: 1 1 50%">
              {{ 0 | formatCurrency }}
            </div>
          </div>
          <div class="pl-2 text-minimum-gray">
            {{ $t('popup.purchaseSubSeats.dueToday.description') }}
          </div>
        </div>
      </template>
      <div v-else class="pb-4">
        {{ $t('popup.purchaseSubSeats.enterSeats') }}
      </div>
    </div>

    <template v-if="visible" #actions-right>
      <template v-if="!hasGrantedSubscription">
        <template v-if="hasValidPaymentProcessorButtonImage">
          <v-btn class="px-0 elevation-1 rounded" @click="checkout">
            <img :src="paymentProcessorButtonImageLink" style="height:36px;border-radius:4px;" alt=""/>
            <span class="d-sr-only">
              {{ $t('page.documentCart.checkoutButton') }}
            </span>
          </v-btn>
        </template>
        <template v-else>
          <c-btn type="primary" class="px-4" @click="checkout">
            {{ $t('page.documentCart.checkoutButton') }}
          </c-btn>
        </template>
      </template>
      <template v-else>
        <c-btn type="primary" class="px-4" @click="confirmGranted">
          {{ $t('subscription.purchaseSeats') }}
        </c-btn>
      </template>
    </template>
  </c-dialog>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import CONST from '@/application/constants';
import FieldDescription from '@components/support/FieldDescription';
import ReceiptOrderLineItems from '@components/support/accounting/ReceiptOrderLineItems';
import SectionSeparator from '@components/support/SectionSeparator';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';

export default {
  components: { FieldDescription, SectionSeparator, ReceiptOrderLineItems, ValidationErrorPanel },
  props: {
    value: {
      type: Boolean
    },
    organizationID: {
      type: String
    },
    userID: {
      type: String
    }
  },
  data () {
    return {
      loading: false,
      numberOfSeats: null,
      errorScope: 'purchaseSubSeats',
      subscription: null,
      receipt: null,
      loadingReceipt: false
    };
  },
  computed: {
    visible () {
      return this.value && !this.loading;
    },
    paymentProcessorButtonImageLink () {
      let paymentClientName = this.$store.getters['application/getAppProperty'](this.CONST.APP_PROPERTY_PAYMENT_CLIENT);
      try
      {
        return require('@/assets/paymentClient/' + paymentClientName + '/checkout.png');
      }
      catch (e)
      {
        // Catch and eat exception if the image can't be found; A fallback button will be displayed instead
        return null;
      }
    },
    hasValidPaymentProcessorButtonImage () {
      return !_.isNil(this.paymentProcessorButtonImageLink);
    },
    isOrg () {
      return !_.isNil(this.organizationID);
    },
    hasReceipt () {
      return !_.isNil(this.receipt);
    },
    hasSubscription () {
      return !_.isNil(this.subscription);
    },
    hasGrantedSubscription () {
      return this.hasSubscription && this.subscription.subscriptionType.grantedSubscription;
    },
    activeSeats () {
      return this.hasSubscription ? this.subscription.activeSeats : 0;
    },
    openSeats () {
      return this.hasSubscription ? this.subscription.openSeats : 0;
    },
    totalSeats () {
      return this.hasSubscription ? this.subscription.totalSeats : 0;
    }
  },
  watch: {
    value (newValue) {
      if (newValue)
      {
        // prep the popup for display
        this.$cove.block();
        this.loading = true;
        this.loadDetails()
          .finally(() => {
            this.loading = false;
            this.$cove.unblock();
          });
      }
      else
      {
        // reset all the data
        this.numberOfSeats = null;
        this.subscription = null;
        this.receipt = null;
        this.$store.commit('cove/errors/clear', this.errorScope);
      }
    }
  },
  methods: {
    seatsDebounce: _.debounce(function () { // do not use arrow function here
      this.calculateTotal();
    }, 500),
    validate () {
      this.$cove.validation.startScope(this.errorScope);
      if (this.isOrg) {
        this.$store.commit('cove/errors/clear');

        this.$cove.validation.required(this.numberOfSeats, 'popup.purchaseSubSeats.field.numberOfSeats', 'numberOfSeats');
        this.$cove.validation.integer.rejectIfInvalid(this.numberOfSeats, 'popup.purchaseSubSeats.field.numberOfSeats', 'numberOfSeats');
        this.$cove.validation.integer.rejectIfLessThanEqualTo(this.numberOfSeats, 0, 'popup.purchaseSubSeats.field.numberOfSeats',
          'numberOfSeats');
      }

      let isValid = !this.$store.getters['cove/errors/hasErrors']();
      this.$cove.validation.endScope();
      return isValid;
    },
    loadDetails () {
      if (this.isOrg) {
        let url = this.$cove.getAPI({
          name: 'api.organization',
          params: {
            id: this.organizationID
          },
          query: {
            fields: '*,subscription(*,subscriptionType(*))'
          }
        });

        return axios.get(url, { errorScope: this.errorScope })
          .then((response) => {
            this.subscription = response.data.subscription;
          });
      } else {
        this.numberOfSeats = 1;
        return this.calculateTotal();
      }
    },
    confirmGranted () {
      this.$cove.confirm({ message: this.$t('popup.purchaseSubSeats.granted.confirmation') })
        .then((confirmed) => {
          if (confirmed) {
            this.checkout();
          }
        });
    },
    checkout () {
      let isValid = this.validate();
      if (isValid) {
        this.$cove.block({ delay: 0 });
        let url = this.$cove.getAPI({ name: 'api.subscriptions' });
        if (this.hasSubscription)
        {
          url = this.$cove.getAPI({ name: 'api.subscription', params: { id: this.subscription.resourceID } });
        }

        let payload = {};
        if (this.isOrg)
        {
          let parsedSeats = this.$refs.numberOfSeats.getValueAsNumber();
          payload = {
            additionalSeats: parsedSeats,
            subscriptionTypeID: this.hasSubscription ? this.subscription.subscriptionType.resourceID : CONST.SUBSCRIPTION_TYPE_ORGANIZATION,
            grandTotal: _.isNil(this.receipt) ? null : this.receipt.amount,
            organizationID: this.organizationID
          };
        }
        else
        {
          payload = {
            additionalSeats: 1,
            subscriptionTypeID: CONST.SUBSCRIPTION_TYPE_PERSONAL,
            grandTotal: _.isNil(this.receipt) ? null : this.receipt.amount,
            userID: this.userID
          };
        }

        let promise = {};
        if (this.hasSubscription)
        {
          promise = axios.patch(url, payload, { errorScope: this.errorScope });
        }
        else
        {
          promise = axios.post(url, payload, { errorScope: this.errorScope });
        }

        promise
          .then(() => {
            let successKey = 'popup.purchaseSubSeats.userPurchaseSuccess'; // default to a user purchase
            if (this.isOrg)
            {
              successKey = this.hasGrantedSubscription ? 'popup.purchaseSubSeats.grantedPurchaseSuccess' :
                'popup.purchaseSubSeats.orgPurchaseSuccess';
            }

            this.$cove.notify({ message: this.$t(successKey), color: 'success' });
            this.$emit('checkoutComplete');
            this.close();
          })
          .finally(() => {
            this.$cove.unblock();
          });
      }
    },
    close () {
      this.$emit('input', false);
    },
    calculateTotal () {
      let parsedSeats = this.numberOfSeats; // defaults to personal/hidden input mode
      if (this.isOrg)
      {
        parsedSeats = this.$refs.numberOfSeats.getValueAsNumber();
      }

      if (_.isNil(parsedSeats) || parsedSeats <= 0)
      {
        this.receipt = null;
        return Promise.resolve();
      }
      else
      {
        // parsedSeats could be a float, so parse to an int and update the field with the formatted value (in case the user hasn't blurred
        // out of the field yet)
        let intSeats = parseInt(parsedSeats);
        this.numberOfSeats = this.$cove.formatNumber(intSeats);

        let query = {};
        if (this.isOrg)
        {
          query = {
            subscriptionTypeID: this.hasSubscription ? this.subscription.subscriptionType.resourceID : CONST.SUBSCRIPTION_TYPE_ORGANIZATION,
            organizationID: this.organizationID,
            additionalSeats: intSeats
          };

          if (this.hasSubscription)
          {
            query.subscriptionID = this.subscription.resourceID;
          }
        }
        else
        {
          query = {
            subscriptionTypeID: CONST.SUBSCRIPTION_TYPE_PERSONAL,
            userID: this.userID,
            additionalSeats: 1
          };
        }

        this.loadingReceipt = true;
        this.$store.commit('cove/errors/clear', this.errorScope);
        let url = this.$cove.getAPI({ name: 'api.subscription.purchase', query });
        return axios.get(url, { errorScope: this.errorScope })
          .then((response) => {
            this.receipt = response.data;
          })
          .finally(() => {
            this.loadingReceipt = false;
          });
      }
    }
  }
};
</script>