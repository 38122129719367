<template>
  <v-alert
    :value="visible"
    elevation="2"
    color="error"
    text
    outlined
    icon="mdi-alert-circle"
    transition="fade-transition"
    class="js-validation-anchor"
    :class="errorScope ? 'js-scoped-errors' : null">
    <div class="font-weight-medium" style="font-size:1.1rem;line-height:1.5">
      {{ $t('global.validationErrors') }}
    </div>
    <ul class="mt-2">
      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <li v-for="error in errors">
        {{ error }}
      </li>
    </ul>
  </v-alert>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    errorScope: {
      type: String,
      default: null
    }
  },
  computed: {
    visible () {
      return !_.isEmpty(this.errors);
    },
    errors () {
      return this.$store.getters['cove/errors/getAllErrorMessages'](this.errorScope);
    }
  }
};
</script>