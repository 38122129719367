<template>
  <loading-card :loading="loading">
    <template #default>
      <v-card-title>
        {{ $t('page.subscriptionMgmt.renewalHistory.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.subscriptionMgmt.renewalHistory.description') }}
      </v-card-subtitle>
      <v-card-text class="pa-0">
        <v-data-table
          :items="items"
          :headers="headers"
          :sort-by="paging.sortBy"
          :sort-desc="paging.sortDesc"
          :server-items-length="paging.totalElements"
          :loading="loadingTable"
          item-key="resourceID"
          must-sort
          hide-default-footer
          :mobile-breakpoint="800"
          @update:options="paging.updateOptions">
          <template #item.renewalDate="{ value }">
            {{ value | formatDateTime }}
          </template>
          <template #item.pendingItemsCount="{ value }">
            {{ value | formatNumber }}
          </template>
          <template #item.processedItemsCount="{ value }">
            {{ value | formatNumber }}
          </template>
          <template #item.erroredItemsCount="{ value }">
            {{ value | formatNumber }}
          </template>
          <template #item.resourceID="{ value }">
            <router-link :to="{ name: 'admin-subscription-renewal', params: { subscriptionRenewalID: value } }">
              {{ $t('global.view') }}
            </router-link>
          </template>
          <template #footer>
            <paging-controls-new :paging="paging"/>
          </template>
        </v-data-table>
      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import LoadingCard from '@components/support/LoadingCard';
import Paging from '@/application/paging';
import PagingControlsNew from '@components/support/PagingControlsNew';
import JSURL from 'jsurl';
import { replaceIgnoreDuplicate } from '@/application/utility';

export default {
  components: { LoadingCard, PagingControlsNew },
  data () {
    return {
      loading: true,
      loadingTable: false,
      items: [],
      headers: [
        { text: this.$t('page.subscriptionMgmt.renewalHistory.renewalDate'), value: 'renewalDate', sortable: false },
        { text: this.$t('page.subscriptionMgmt.renewalHistory.renewalStatus.short'), value: 'subscriptionRenewalStatus.displayName',
          sortable: false },
        { text: this.$t('page.subscriptionMgmt.renewalHistory.initiatedUser'), value: 'initiatedUser.actor.sortName', sortable: false },
        { text: this.$t('page.subscriptionMgmt.renewalHistory.pendingItemsCount.short'), value: 'pendingItemsCount', sortable: false },
        { text: this.$t('page.subscriptionMgmt.renewalHistory.processedItemsCount.short'), value: 'processedItemsCount', sortable: false },
        { text: this.$t('page.subscriptionMgmt.renewalHistory.erroredItemsCount.short'), value: 'erroredItemsCount', sortable: false },
        { text: this.$t('page.subscriptionMgmt.renewalHistory.link.header'), value: 'resourceID', sortable: false, align: 'right' }
      ],
      paging: new Paging({
        size: 25,
        sortBy: 'renewalDate',
        sortDesc: true,
        searchCallback: this.loadHistory
      })
    };
  },
  created () {
    let criteria = JSURL.parse(_.defaultTo(this.$route.query.criteria, ''));
    this.paging.parseURLParams(criteria);

    this.loadHistory(false)
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    loadHistory (updateURL = true) {
      if (updateURL) {
        replaceIgnoreDuplicate({
          name: 'admin-subscription-history',
          query: {
            criteria: JSURL.stringify(this.paging.generateParams())
          }
        });
      }

      this.loadingTable = true;
      return axios.get(this.$cove.getAPI({
        name: 'api.subscription.renewals',
        query: {
          fields: '*,initiatedUser(*,actor(*)),subscriptionRenewalStatus(*)',
          ...this.paging.generateParams()
        }
      }))
        .then((response) => {
          this.items = _.get(response, 'data._embedded.results', []);
          this.paging.parseResponse(response);
        })
        .finally(() => {
          this.loadingTable = false;
        });
    }
  }
};
</script>