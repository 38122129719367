<template>
  <!-- project name -->
  <div class="banner-project-name text-truncate"
       :class="{ 'text-left pr-2 pl-4': !largeDisplay }"
       style="flex:1 1 50%">
    {{ $t('application.project.name') }}
  </div>
</template>

<script>
export default {
  computed: {
    largeDisplay () {
      return this.$vuetify.breakpoint.lgAndUp;
    }
  }
};
</script>