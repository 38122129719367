<template>
  <div>
    <transition name="scroll-y-transition" mode="out-in">
      <form>
        <v-row no-gutters class="mt-4">
          <v-col v-if="!isSingleCourtMode" cols="12">
            <c-autocomplete
              :items="courts"
              v-model="searchDTO.courtID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('page.searchCriteria.publication.field.court')"
              key="courtID"
              outlined
              hide-details="auto"
              aria-describedby="85f6c442-60aa-465d-a896-1e3fd53b41ba"/>
            <field-description id="85f6c442-60aa-465d-a896-1e3fd53b41ba"
                               message-key="page.searchCriteria.publication.field.court.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              :label="$t('page.searchCriteria.publication.field.caseNumber')"
              v-model="searchDTO.publication.caseNumber"
              outlined
              maxlength="250"
              hide-details="auto"
              aria-describedby="6a7edd8b-5220-4286-a0f0-daa785de0fcd"/>
            <field-description id="a4933c59-03d6-4daf-8188-5ea5fd0f47c4"
                               message-key="page.searchCriteria.publication.field.caseNumber.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              :label="$t('page.searchCriteria.publication.field.publicationNumber')"
              v-model="searchDTO.publication.publicationNumber"
              outlined
              maxlength="250"
              hide-details="auto"
              aria-describedby="f6363455-f1a9-4158-ac62-ee99acfc4325"/>
            <field-description id="f6363455-f1a9-4158-ac62-ee99acfc4325"
                               message-key="page.searchCriteria.publication.field.publicationNumber.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              :label="$t('page.searchCriteria.publication.field.publicationName')"
              v-model="searchDTO.publication.publicationName"
              outlined
              maxlength="250"
              hide-details="auto"
              aria-describedby="4779226c-71fa-450a-885b-5b6a353a158f"/>
            <field-description id="4779226c-71fa-450a-885b-5b6a353a158f"
                               message-key="page.searchCriteria.publication.field.publicationName.description"/>
          </v-col>

          <v-col cols="12">
            <date-range-picker
              :label="$t('page.searchCriteria.publication.field.publicationDate')"
              :labelStart="$t('page.searchCriteria.publication.field.publicationDateFrom')"
              :labelEnd="$t('page.searchCriteria.publication.field.publicationDateTo')"
              keyPrefix="publicationDate"
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.publication.publicationDateChoice"
              :startDate.sync="searchDTO.publication.publicationDateStart"
              :endDate.sync="searchDTO.publication.publicationDateEnd"
              aria-describedby="253d8cf3-a7c0-4329-9dab-0fa40f80d709"/>
            <field-description id="253d8cf3-a7c0-4329-9dab-0fa40f80d709"
                               message-key="page.searchCriteria.publication.field.publicationDate.description"/>
          </v-col>
        </v-row>
      </form>
    </transition>
  </div>
</template>

<script>
import FieldDescription from '@components/support/FieldDescription';
import DateRangePicker from '@components/support/DateRangePicker';
import SearchCriteriaMixin from '@/mixins/searchCriteria';

export default {
  components: { FieldDescription, DateRangePicker },
  mixins: [ SearchCriteriaMixin ]
};
</script>
