<template>
  <page-wrapper :title="$t('global.error')">
    <v-row>
      <v-col md="7">
        <v-card>
          <v-card-title>
            {{ error }}
          </v-card-title>
          <v-card-text>
            <div>
              {{ errorMessage }}
            </div>
            <div class="mt-5">
              <dl class="d-grid grid-4060">
                <dt>
                  {{ $t('page.errorDocumentCartCheckout.receiptReferenceNumber') }}
                </dt>
                <dd>
                  {{ receiptReferenceNumber }}
                </dd>
                <dt>
                  {{ $t('page.errorDocumentCartCheckout.receiptID') }}
                </dt>
                <dd>
                  {{ receiptUUID }}
                </dd>
                <dt>
                  {{ $t('page.errorDocumentCartCheckout.transactionID') }}
                </dt>
                <dd>
                  {{ processorExternalIdentifier }}
                </dd>
                <dt>
                  {{ $t('page.errorDocumentCartCheckout.transactionNumber') }}
                </dt>
                <dd>
                  {{ processorTransactionNumber }}
                </dd>
                <dt>
                  {{ $t('global.error.errorReference') }}
                </dt>
                <dd>
                  {{ errorReference }}
                </dd>
                <dt>
                  {{ $t('global.error.dateUTC') }}
                </dt>
                <dd>
                  {{ errorDateTime }}
                </dd>
              </dl>
            </div>
            <div class="text-right mt-16">
              <c-btn type="primary" @click="copyToClipboard">
                {{ $t('global.copyToClipboard') }}
              </c-btn>
              <c-btn type="primary" :to="{ name: 'home' }" style="min-width:120px">
                {{ $t('global.returnHome') }}
              </c-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import PageWrapper from '@components/support/PageWrapper';
import PageMixin from '@/mixins/page';

export default {
  components: { PageWrapper },
  mixins: [ PageMixin ],
  props: {
    receiptReferenceNumber: {
      type: String,
      default: null
    },
    receiptUUID: {
      type: String,
      default: null
    },
    processorExternalIdentifier: {
      type: String,
      default: null
    },
    processorTransactionNumber: {
      type: String,
      default: null
    },
    errorReference: {
      type: String,
      default: null
    },
    errorDateTime: {
      type: String,
      default: null
    },
    hasVoided: {
      type: String, // This will come in as a string regardless of this type
      default: 'false'
    },
    hasTransaction: {
      type: String, // This will come in as a string regardless of this type
      default: 'false'
    }
  },
  computed: {
    error () {
      return this.$t('page.error.documentCartCheckout.error.title');
    },
    errorMessage () {
      if (this.hasTransaction === 'true' && this.hasVoided === 'true')
      {
        return this.$t('page.error.documentCartCheckout.errorMessage.voided.description');
      }
      else if (this.hasTransaction === 'true' && this.hasVoided === 'false')
      {
        return this.$t('page.error.documentCartCheckout.errorMessage.willBeVoided.description');
      }
      else
      {
        return this.$t('page.error.documentCartCheckout.errorMessage.noTransactionCreated.description');
      }
    }
  },
  methods: {
    copyToClipboard () {
      navigator.clipboard.writeText(JSON.stringify({ error: this.error,
        errorMessage: this.errorMessage, ...this.$props }, null, 4));
    }
  }
};
</script>
