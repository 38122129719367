import ActionListItem from '@components/support/ActionListItem';
import LoadingCard from '@components/support/LoadingCard';
import FieldDescription from '@components/support/FieldDescription';
import SectionSeparator from '@components/support/SectionSeparator';
import _ from 'lodash';

export default {
  components: { FieldDescription, ActionListItem, SectionSeparator, LoadingCard },
  props: {
    originalBean: {
      type: null // syntax to use when this can be any type
    }
  },
  data () {
    return {
      bean: null,
      loading: true
    };
  },
  created () {
    // clear any errors that are on the screen when using breadcrumbs to navigate to a new step
    this.$store.commit('cove/errors/clear');

    this.bean = _.cloneDeep(this.originalBean);

    Promise.resolve(this.loadData())
      .then(() => {
        this.loading = false;
        this.$emit('ready');
      });
  },
  methods: {
    loadData () {
      // no-op
    }
  }
};