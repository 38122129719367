<template>
  <page-wrapper :title="$t('page.about.title')">
    <v-row>
      <v-col cols="12" md="8" xl="7">
        <v-card>
          <v-card-title class="pb-0">
            {{ $t('application.application.name') }}
          </v-card-title>
          <v-card-text>
            <dl class="d-grid">
              <dt>
                {{ $t('page.about.label.createdBy') }}
              </dt>
              <dd>
                {{ $t('application.createdBy.name') }}
              </dd>
              <template v-if="isAdmin">
                <dt>
                  {{ $t('page.about.label.builtOn') }}
                </dt>
                <dd>
                  {{ about.buildDate }}
                </dd>
                <dt>
                  {{ $t('page.about.label.builtWith') }}
                </dt>
                <dd>
                  node {{ about.nodeVersion }}
                  <br/>
                  npm {{ about.npmVersion }}
                </dd>
              </template>
            </dl>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" xl="7">
        <v-card>
          <v-card-title>
            {{ $t('page.about.label.software.title') }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('page.about.label.software.description') }}
          </v-card-subtitle>
          <v-card-text>
            <v-simple-table dense>
              <caption class="d-sr-only">
                {{ $t('page.about.label.software.title') }}
              </caption>
              <thead>
                <tr>
                  <th>
                    {{ $t('page.about.label.software.title') }}
                  </th>
                  <th v-if="isAdmin">
                    {{ $t('page.about.label.version') }}
                  </th>
                  <th>
                    {{ $t('page.about.label.license') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="d in about.dependencies" :key="d.name">
                  <td class="font-weight-medium">
                    <template v-if="d.name === 'party-js'">
                      <button type="button" @click="partyTime">
                        {{ d.name }}
                      </button>
                    </template>
                    <template v-else>
                      {{ d.name }}
                    </template>
                  </td>
                  <td v-if="isAdmin" style="font-family:monospace">
                    {{ d.version }}
                  </td>
                  <td>
                    {{ d.license }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import PageWrapper from '@components/support/PageWrapper';
import PageMixin from '@/mixins/page';
import party from 'party-js';

let PARTY_ACTIVATED = false;
let PARTY_SHAPES = ['square', 'circle', 'rectangle', 'star'];

export default {
  components: { PageWrapper },
  mixins: [ PageMixin ],
  computed: {
    isAdmin () {
      return this.$store.getters['user/isAdmin'];
    },
    about () {
      return JSON.parse(process.env.VUE_APP_ABOUT);
    }
  },
  methods: {
    partyTime () {
      party.confetti(party.Rect.fromScreen(), {
        count: 100,
        shapes: PARTY_SHAPES
      });

      if (!PARTY_ACTIVATED)
      {
        document.addEventListener('click', (e) => {
          party.confetti(e, {
            count: 16,
            shapes: PARTY_SHAPES
          });
        });

        PARTY_ACTIVATED = true;
      }
    }
  }
};
</script>
