<template>
  <div class="nav-tab-small">
    <validation-error-panel/>
    <loading-card :loading="false">
      <v-card-title>
        {{ $t('page.currentUser.section.address.header') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.currentUser.section.address.description') }}
      </v-card-subtitle>
      <v-card-text class="mt-4 pb-2">
        <address-form :address="address" ref="addressForm"/>
      </v-card-text>
      <v-card-actions class="justify-end">
        <c-btn type="primary" class="px-4" @click="save">
          {{ $t('application.button.save') }}
        </c-btn>
      </v-card-actions>
    </loading-card>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import AddressForm from '@/components/support/AddressForm';
import LoadingCard from '@components/support/LoadingCard';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';

export default {
  components: { LoadingCard, AddressForm, ValidationErrorPanel },
  data () {
    return {
      address: {
        line1: null,
        line2: null,
        line3: null,
        line4: null,
        countryIdentifier: null,
        city: null,
        regionIdentifier: null,
        zipCode: null
      }
    };
  },
  computed: {
    user () {
      return this.$store.state.user;
    }
  },
  created () {
    if (this.user.mailingAddress !== null)
    {
      this.address = _.cloneDeep(this.user.mailingAddress);
    }
  },
  methods: {
    validate () {
      this.$store.commit('cove/errors/clear');
      this.$refs.addressForm.validate();
      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      this.$cove.block({ delay: 0 });

      let isValid = this.validate();
      if (!isValid)
      {
        this.$cove.unblock();
        this.scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
        return;
      }

      axios.patch(this.$cove.getAPI({ name: 'api.user.currentuser' }), { mailingAddress: this.address })
        .then(() => {
          return this.$store.dispatch('user/reload');
        })
        .then(() => {
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
        })
        .finally(() => {
          this.$cove.unblock();
        });
    }
  }
};
</script>