<template>
  <div class="nav-tab-small">
    <validation-error-panel/>
    <loading-card :loading="false">
      <v-card-title>
        {{ $t('page.currentUser.section.contact.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.currentUser.section.contact.description') }}
      </v-card-subtitle>
      <v-card-text class="mt-2 pb-2">
        <v-row no-gutters>
          <!-- email section -->
          <v-col cols="12">
            <section-separator :title="$t('page.currentUser.section.email.header')" class="mb-5"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="user.emailAddress"
              :label="$t('page.currentUser.field.email')"
              type="email"
              outlined
              disabled
              hide-details="auto"
              aria-describedby="cdba3e22-ddbe-4dcf-a3be-614c9df53b3f"/>
            <field-description id="cdba3e22-ddbe-4dcf-a3be-614c9df53b3f" message-key="page.currentUser.field.email.description"/>
          </v-col>
          <v-row v-if="mailEnabled" no-gutters>
            <transition-group name="scroll-y-transition" tag="div" style="width:100%">
              <v-col cols="12" v-for="cc in contacts.carbonCopyEmails"
                     :key="cc.id" class="d-flex pa-0">
                <c-text-field
                  v-model="cc.value"
                  style="flex:1 1 auto"
                  :label="$t('page.currentUser.field.ccEmail')"
                  :id="`cc-email-${cc.id}`"
                  :key="`cc-email-${cc.id}`"
                  type="email"
                  maxlength="250"
                  outlined
                  required/>
                <v-btn icon style="flex:0 0 auto" class="ml-4 mt-3" @click="removeCCEmail($event, cc.id)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </transition-group>
            <v-col cols="12">
              <c-btn @click="addCCEmail" small aria-describedby="c297c19f-b8e2-415a-8101-7c689c89b60a">
                {{ $t('page.currentUser.button.addCCEmail') }}
              </c-btn>
              <field-description id="c297c19f-b8e2-415a-8101-7c689c89b60a"
                                 message-key="page.currentUser.button.addCCEmail.description"/>
            </v-col>
          </v-row>

          <!-- phone section -->
          <v-col cols="12" class="mt-4 mb-5">
            <section-separator :title="$t('page.currentUser.section.phone.header')"/>
          </v-col>
          <v-col cols="12">
            <div class="d-flex align-center">
              <c-autocomplete
                v-model="contacts.contactPhoneCountry"
                :items="countryPhones"
                item-text="typeahead"
                return-object
                :label="$t('page.currentUser.field.contactPhone.country')"
                style="flex:1 1 45%"
                outlined
                :clearable="false"
                hide-details="auto"
                aria-describedby="b0cd4f8e-7474-4218-9d2f-bc109f49c78a">
                <template #item="{ item }">
                  <div style="flex:1 1 auto">
                    {{ item.name }}
                  </div>
                  <div class="ml-4 text-minimum-gray" style="flex:0 0 auto">
                    +{{ item.dialCode }}
                  </div>
                </template>
              </c-autocomplete>
              <c-text-field
                v-model="contacts.contactPhoneNumber"
                :label="$t('page.currentUser.field.contactPhone.number')"
                class="ml-4"
                style="flex:1 1 55%"
                maxlength="50"
                type="tel"
                key="contactPhoneNumber"
                outlined
                @change="formatContactPhoneNumber"
                hide-details="auto"
                aria-describedby="b0cd4f8e-7474-4218-9d2f-bc109f49c78a"/>
            </div>
            <div>
              <field-description id="b0cd4f8e-7474-4218-9d2f-bc109f49c78a"
                                 message-key="page.currentUser.field.contactPhone.description"/>
            </div>
          </v-col>

          <!-- SMS section -->
          <template v-if="smsEnabled">
            <v-col cols="12" class="mt-5 mb-3">
              <section-separator :title="$t('page.currentUser.section.sms.header')"/>
            </v-col>
            <v-col cols="12">
              <c-checkbox
                v-model="smsFlag"
                :label="$t('page.currentUser.field.sms.enable')"
                class="my-0"
                @change="toggleSMS"
                hide-details="auto"
                aria-describedby="a555e785-d7e4-4622-8e1b-4ef3f1cd26b2"/>
              <field-description id="a555e785-d7e4-4622-8e1b-4ef3f1cd26b2" message-key="page.currentUser.field.sms.enable.description"/>
            </v-col>
            <v-col cols="12" :style="{ opacity: smsFlag ? '1' : '0.65' }">
              <div class="d-flex align-center">
                <c-autocomplete
                  v-model="contacts.smsPhoneCountry"
                  :items="countryPhones"
                  item-text="typeahead"
                  return-object
                  :label="$t('page.currentUser.field.smsPhone.country')"
                  style="flex:1 1 45%"
                  outlined
                  :clearable="false"
                  :required="smsFlag"
                  :disabled="!smsFlag"
                  hide-details="auto"
                  aria-describedby="c7623367-e073-42a4-af60-e435f87141e8">
                  <template #item="{ item }">
                    <div style="flex:1 1 auto">
                      {{ item.name }}
                    </div>
                    <div class="ml-4 text-minimum-gray" style="flex:0 0 auto">
                      +{{ item.dialCode }}
                    </div>
                  </template>
                </c-autocomplete>
                <c-text-field
                  v-model="contacts.smsPhoneNumber"
                  :label="$t('page.currentUser.field.smsPhone.number')"
                  class="ml-4"
                  style="flex:1 1 55%"
                  key="smsPhoneNumber"
                  type="tel"
                  maxlength="50"
                  outlined
                  :required="smsFlag"
                  :disabled="!smsFlag"
                  @change="formatSMSPhoneNumber"
                  hide-details="auto"
                  aria-describedby="c7623367-e073-42a4-af60-e435f87141e8"/>
              </div>
              <div>
                <field-description id="c7623367-e073-42a4-af60-e435f87141e8"
                                   message-key="page.currentUser.field.smsPhone.description"/>
              </div>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <c-btn type="primary" class="px-4" @click="save">
          {{ $t('application.button.save') }}
        </c-btn>
      </v-card-actions>
    </loading-card>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { focusElement, focusNext, formatPhoneInput, isPhoneValid, formatPhoneForSaving, getCountryForPhoneNumber }
  from '@/application/utility';
import CountryPhone from '@/application/countryPhone';
import LoadingCard from '@components/support/LoadingCard';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import FieldDescription from '@components/support/FieldDescription';
import SectionSeparator from '@components/support/SectionSeparator';

export default {
  components: { LoadingCard, ValidationErrorPanel, FieldDescription, SectionSeparator },
  data () {
    return {
      smsFlag: false,
      contacts: {
        carbonCopyEmails: [],
        smsPhoneCountry: null,
        smsPhoneNumber: null,
        contactPhoneCountry: null,
        contactPhoneNumber: null
      }
    };
  },
  computed: {
    user () {
      return this.$store.state.user;
    },
    mailEnabled () {
      return this.$store.getters['application/getAppProperty'](this.CONST.APP_PROPERTY_MAIL_ENABLED);
    },
    smsEnabled () {
      return this.$store.getters['application/getAppProperty'](this.CONST.APP_PROPERTY_SMS_ENABLED);
    },
    countryPhones () {
      return CountryPhone;
    },
    defaultPhoneCountry () {
      return this.$store.getters['application/getDefaultPhoneCountry'];
    }

  },
  created () {
    if (this.mailEnabled)
    {
      this.contacts.carbonCopyEmails = _.map(_.cloneDeep(this.user.carbonCopyEmails), (cc) => {
        return { id: _.uniqueId(), value: cc };
      });
    }

    let smsPhone = this.user.smsPhoneNumber;
    if (smsPhone !== null && this.smsEnabled)
    {
      this.smsFlag = true;
      this.contacts.smsPhoneCountry = getCountryForPhoneNumber(smsPhone);
      this.contacts.smsPhoneNumber = smsPhone.substr(smsPhone.indexOf(' ') + 1);
      this.formatSMSPhoneNumber();
    }

    let contactPhone = this.user.contactPhoneNumber;
    if (contactPhone !== null)
    {
      this.contacts.contactPhoneCountry = getCountryForPhoneNumber(contactPhone);
      this.contacts.contactPhoneNumber = contactPhone.substr(contactPhone.indexOf(' ') + 1);
      this.formatContactPhoneNumber();
    }
    else
    {
      this.contacts.contactPhoneCountry = this.defaultPhoneCountry;
    }
  },
  methods: {
    addCCEmail () {
      let id = _.uniqueId();
      this.contacts.carbonCopyEmails.push({ id, value: null });
      focusElement(`#cc-email-${id}`);
    },
    removeCCEmail (e, id) {
      focusNext(e);
      this.$delete(this.contacts.carbonCopyEmails, _.findIndex(this.contacts.carbonCopyEmails, { id }));
    },
    toggleSMS () {
      if (this.smsFlag)
      {
        this.contacts.smsPhoneCountry = this.defaultPhoneCountry;
      }
      else
      {
        this.contacts.smsPhoneCountry = null;
        this.contacts.smsPhoneNumber = null;
      }
    },
    formatSMSPhoneNumber () {
      this.contacts.smsPhoneNumber = formatPhoneInput(this.contacts.smsPhoneNumber, this.contacts.smsPhoneCountry.iso);
    },
    formatContactPhoneNumber () {
      this.contacts.contactPhoneNumber = formatPhoneInput(this.contacts.contactPhoneNumber, this.contacts.contactPhoneCountry.iso);
    },
    validate () {
      this.$store.commit('cove/errors/clear');

      // clear out empty CC addresses and duplicates
      if (this.mailEnabled)
      {
        let uniqueAddresses = [];
        this.contacts.carbonCopyEmails = this.contacts.carbonCopyEmails.filter((cc) => {
          let result = false;
          if (cc.value !== null)
          {
            let lowercase = cc.value.toLowerCase();
            if (!uniqueAddresses.includes(lowercase))
            {
              uniqueAddresses.push(lowercase);
              result = true;
            }
          }

          return result;
        });

        // check CC length and validity
        this.contacts.carbonCopyEmails.forEach((cc) => {
          this.$cove.validation.string.maxLength(cc.value, 250, 'page.currentUser.field.ccEmail', `cc-email-${cc.id}`);
          if (!this.CONST.REGEX_VALID_EMAIL.test(cc.value))
          {
            this.$cove.validation.addError(`cc-email-${cc.id}`, 'page.currentUser.error.invalidEmail',
              [this.$t('page.currentUser.field.ccEmail')]);
          }
        });
      }

      // if sms checked, validate sms number
      if (this.smsFlag && this.smsEnabled)
      {
        let smsProvided = this.$cove.validation.required(this.contacts.smsPhoneNumber, 'page.currentUser.field.smsPhone.number',
          'smsPhoneNumber');
        if (smsProvided && !isPhoneValid(this.contacts.smsPhoneNumber, this.contacts.smsPhoneCountry.iso))
        {
          this.$cove.validation.addError('smsPhoneNumber', 'page.currentUser.error.invalidPhone',
            [this.$t('page.currentUser.field.smsPhone.number')]);
        }
      }

      // if contact phone filed in, validate contact number
      if (this.contacts.contactPhoneNumber !== null &&
          !isPhoneValid(this.contacts.contactPhoneNumber, this.contacts.contactPhoneCountry.iso))
      {
        this.$cove.validation.addError('contactPhoneNumber', 'page.currentUser.error.invalidPhone',
          [this.$t('page.currentUser.field.contactPhone.number')]);
      }

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      this.$cove.block({ delay: 0 });

      let isValid = this.validate();
      if (!isValid)
      {
        this.$cove.unblock();
        this.scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
        return;
      }

      let smsPhoneNumber = null;
      if (this.smsFlag && this.smsEnabled)
      {
        smsPhoneNumber = formatPhoneForSaving(this.contacts.smsPhoneCountry, this.contacts.smsPhoneNumber);
      }

      let contactPhoneNumber = null;
      if (this.contacts.contactPhoneNumber !== null)
      {
        contactPhoneNumber = formatPhoneForSaving(this.contacts.contactPhoneCountry, this.contacts.contactPhoneNumber);
      }

      let payload = {
        contacts: {
          smsPhoneNumber,
          contactPhoneNumber,
          carbonCopyEmails: !this.mailEnabled || this.contacts.carbonCopyEmails.length === 0 ?
            null : _.map(this.contacts.carbonCopyEmails, 'value')
        }
      };

      axios.patch(this.$cove.getAPI({ name: 'api.user.currentuser' }), payload)
        .then(() => {
          return this.$store.dispatch('user/reload');
        })
        .then(() => {
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
        })
        .finally(() => {
          this.$cove.unblock();
        });
    }
  }
};
</script>