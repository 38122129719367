<template>
  <loading-card :loading="party === null">
    <template v-if="party !== null">
      <v-card-title>
        {{ $t('page.manageFiling.party.edit.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.manageFiling.party.edit.description') }}
      </v-card-subtitle>
      <v-card-text class="pt-2">
        <!-- Role -->
        <v-divider class="my-2" style="opacity:0.5"/>
        <div class="d-flex">
          <div style="flex: 1 1 auto;align-self:center">
            <dl class="d-grid grid-4060">
              <dt>{{ $t('page.manageFiling.party.step.role') }}</dt>
              <dd>{{ party.partySubTypeExternalName }}</dd>
            </dl>
          </div>
          <div class="text-right" style="flex: 0 0 100px">
            <v-btn color="primary" text icon @click="$emit('editRole')">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </div>
        <!-- Name -->
        <v-divider class="my-2" style="opacity:0.5"/>
        <div class="d-flex">
          <div style="flex: 1 1 auto;align-self:center">
            <dl class="d-grid grid-4060">
              <dt>{{ $t('page.manageFiling.party.step.name') }}</dt>
              <dd>{{ party.actor.displayName }}</dd>
            </dl>
          </div>
          <div class="text-right" style="flex: 0 0 100px">
            <v-btn color="primary" text icon @click="$emit('editName')">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </div>
        <!-- Address -->
        <v-divider class="my-2" style="opacity:0.5"/>
        <div class="d-flex">
          <div style="flex: 1 1 auto;align-self:center">
            <dl class="d-grid grid-4060">
              <dt>{{ $t('page.manageFiling.party.step.address') }}</dt>
              <dd><render-address :address="party.actor.mailingAddress"/></dd>
            </dl>
          </div>
          <div class="text-right" style="flex: 0 0 100px">
            <v-btn color="primary" text icon @click="$emit('editAddress')">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </div>
        <!-- Contact -->
        <v-divider class="my-2" style="opacity:0.5"/>
        <div class="d-flex">
          <div style="flex: 1 1 auto;align-self:center">
            <dl class="d-grid grid-4060">
              <dt>{{ $t('page.manageFiling.party.step.contact') }}</dt>
              <dd>
                <template v-if="hasContacts">
                  <div v-if="hasPhoneNumber" class="mb-3">
                    <div class="label text-left">{{ $t('page.manageFiling.party.stepContact.field.phoneNumber') }}</div>
                    <div>{{ formatPhoneDisplay(party.actor.contacts.contactPhoneNumber) }}</div>
                  </div>
                  <div v-if="hasEmailAddress">
                    <div class="label text-left">{{ $t('page.manageFiling.party.stepContact.field.emailAddress') }}</div>
                    <div>{{ party.actor.contacts.primaryEmail }}</div>
                  </div>
                </template>
              </dd>
            </dl>
          </div>
          <div class="text-right" style="flex: 0 0 100px">
            <v-btn color="primary" text icon @click="$emit('editContact')">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </div>
        <!-- Representation -->
        <template v-for="(legalRepMap) in party.legalRepresenterMaps">
          <v-divider class="my-2" style="opacity:0.5" :key="`${legalRepMap.resourceID}_divider`"/>
          <div class="d-flex" :key="`${legalRepMap.resourceID}_wrapper`">
            <div style="flex: 1 1 auto;align-self:center">
              <dl class="d-grid grid-4060">
                <dt>{{ $t('page.manageFiling.party.step.representation') }}</dt>
                <dd>{{ getNameForLegalRep(legalRepMap) }}</dd>
              </dl>
            </div>
            <div class="text-right" style="flex: 0 0 100px">
              <v-btn v-if="!legalRepMap.proSe" color="primary" text icon @click="$emit('editRepresentation', legalRepMap.resourceID)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn color="primary" text icon @click="$emit('removeRepresentation', legalRepMap.resourceID)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <!-- Add... -->
        <v-divider class="my-2" style="opacity:0.5"/>
        <div v-if="!isProSe" class="d-flex mt-4">
          <div class="d-flex" style="flex: 1 1 auto">
            <div style="flex: 0 0 40%">
              <!-- empty but stubbed out for alignment with other sections -->
            </div>
            <div class="pl-1" style="flex: 0 0 60%">
              <c-btn small type="primary" @click="$emit('addRepresentation')">
                {{ $t('page.manageFiling.party.edit.addRepresentation') }}
              </c-btn>
            </div>
          </div>
          <div style="flex: 0 0 100px">
            <!-- empty but stubbed out for alignment with other sections -->
          </div>
        </div>

      </v-card-text>
      <v-card-actions class="justify-end mt-5">
        <c-btn @click="$emit('back')">
          {{ $t('page.manageFiling.party.edit.backToParties') }}
        </c-btn>
      </v-card-actions>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import WorkflowStepMixin from '@/mixins/workflowStep';
import RenderAddress from '@/components/support/RenderAddress';
import { formatPhoneDisplay } from '@/application/utility';

export default {
  mixins: [ WorkflowStepMixin ],
  components: { RenderAddress },
  props: {
    party: {
      type: Object
    }
  },
  computed: {
    hasContacts () {
      return !_.isNil(this.party.actor.contacts);
    },
    hasPhoneNumber () {
      return !_.isNil(this.party.actor.contacts.contactPhoneNumber);
    },
    hasEmailAddress () {
      return !_.isNil(this.party.actor.contacts.primaryEmail);
    },
    isProSe () {
      return !_.isNil(_.find(this.party.legalRepresenterMaps, { proSe: true }));
    }
  },
  methods: {
    formatPhoneDisplay,
    getNameForLegalRep (legalRepMap) {
      let name = legalRepMap.proSe ? this.$t('global.selfRep') : null;
      if (name === null)
      {
        let possibleNames = [
          _.get(legalRepMap, 'legalRepresenter.actor.sortName', null),
          _.get(legalRepMap, 'existingLegalRepresenter.sortName', null)
        ];

        name = _.compact(possibleNames)[0];
      }

      return name;
    }
  }
};
</script>

<style lang="scss" scoped>
dl.d-grid dt
{
  font-weight: 600;
  text-align: left;
}
</style>