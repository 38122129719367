<template>
  <v-app>
    <v-app-bar app
               dense
               clipped-left
               clipped-right
               elevation="2"
               id="header-toolbar"
               class="d-print-none"
               :color="$vuetify.theme.isDark ? '' : 'white'">

      <!-- skip links -->
      <div :style="skipLinks ? 'flex: 0 0 100%' : ''">
        <div id="skip-links">
          <button class="d-sr-only-focusable" type="button"
                  @click="focusTitle"
                  @focus="skipLinks = true;"
                  @blur="skipLinks = false;">
            {{ $t('application.banner.skipLink') }}
          </button>
        </div>
      </div>

      <!-- small viewport width menu button and TR labeling -->
      <div class="d-flex align-center" :style="{ 'flex': largeDisplay ? '1 1 25%' : '0 0 auto' }">
        <div class="d-lg-none" style="height:48px;">
          <v-btn text color="primary" class="inset-focus" style="min-width:auto;height:100%" @click="drawer = !drawer">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </div>
        <banner-left/>
      </div>

      <banner-center/>

      <!-- action buttons -->
      <v-toolbar-items class="d-none d-md-block text-right" style="flex:1 1 25%;">
        <!-- logged in display -->
        <template v-if="hasSession">
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn text class="ma-0 inset-focus" v-on="on" v-bind="attrs" color="primary">
                <div class="text-left text-truncate" style="max-width:200px">
                  {{ userDisplayName }}
                </div>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item :to="{ name: 'user-profile' }" class="inset-focus" active-class="no-active">
                <v-list-item-title>
                  {{ $t('application.banner.user.profile') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="redirectToLogout" class="inset-focus">
                <v-list-item-title>
                  {{ $t('application.banner.user.logout') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <!-- anonymous display -->
        <template v-else>
          <v-btn v-if="!isKioskMode" text color="primary" @click="redirectToLogin" class="inset-focus">
            {{ $t('application.banner.login') }} / {{ $t('application.banner.register') }}
          </v-btn>
          <v-btn text color="primary" :to="{ name: 'language' }" class="inset-focus" active-class="no-active">
            {{ $t('application.banner.language') }}
          </v-btn>
        </template>
        <!-- cart -->
        <template v-if="hasSession && isEnableDocumentPurchaseFunctions">
          <v-btn text color="primary" class="inset-focus mw-auto" :to="{ name: 'cart'}" active-class="no-active">
            <v-icon class="px-0">
              mdi-cart
            </v-icon>
            <v-icon v-if="hasUnpurchasedDocumentsInCart" x-small color="red darken-4" class="mb-7 px-0">
              mdi-circle
            </v-icon>
            <span class="d-sr-only">
              {{ $t('global.cart') }}
            </span>
          </v-btn>
        </template>
        <!-- help menu -->
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn text color="primary" class="inset-focus mw-auto" v-on="on" v-bind="attrs">
              <v-icon>
                mdi-help-circle
              </v-icon>
              <span class="d-sr-only">
                {{ $t('global.help') }}
              </span>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item class="inset-focus" active-class="no-active" @click="togglePageHelp(true)">
              <v-list-item-title>
                {{ $t('application.banner.pageHelp') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'about' }" class="inset-focus" active-class="no-active">
              <v-list-item-title>
                {{ $t('application.banner.about') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <!-- simplified page header used when printing -->
    <div class="d-none d-print-flex align-center">
      <div style="flex:1 1 auto;font-size:1rem" class="font-weight-medium">
        {{ $t('application.project.name') }}
      </div>
      <div v-if="hasSession" style="flex:1 1 auto;font-size:1rem" class="text-right">
        {{ userDisplayName }}
      </div>
    </div>

    <div class="d-print-none">
      <v-navigation-drawer app clipped :permanent="largeDisplay" v-model="drawer">
        <v-list id="main-navigation" dense nav expand>
          <template v-if="smallOrDownDisplay">
            <v-list-group v-if="hasSession" prepend-icon="mdi-account">
              <template #activator>
                <v-list-item-title>
                  {{ userDisplayName }}
                </v-list-item-title>
              </template>
              <v-list-item :to="{ name: 'user-profile' }" active-class="no-active">
                <v-list-item-title>
                  {{ $t('application.banner.user.profile') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="redirectToLogout">
                <v-list-item-title>
                  {{ $t('application.banner.user.logout') }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
            <c-btn v-else-if="!isKioskMode" block type="primary" @click="redirectToLogin">
              {{ $t('application.banner.login') }} / {{ $t('application.banner.register') }}
            </c-btn>
            <v-divider class="my-2 mx-1"/>
          </template>
          <v-list-item :to="{ name: 'home' }" active-class="no-active">
            <v-list-item-icon>
              <v-icon>
                mdi-home
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('application.navigation.home') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'announcements' }" active-class="no-active">
            <v-list-item-icon>
              <v-icon>
                mdi-bullhorn
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="d-flex align-center">
              <div style="flex:1 1 auto">
                {{ $t('application.navigation.announcements') }}
              </div>
              <v-icon v-if="hasUnreadAnnouncements" small color="red darken-4" class="pr-1">
                mdi-circle
              </v-icon>
            </v-list-item-title>
          </v-list-item>
          <template v-if="hasSession">
            <v-list-item :to="{ name: 'work-notifications' }" active-class="no-active">
              <v-list-item-icon>
                <v-icon>
                  mdi-bell
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="d-flex align-center">
                <div style="flex:1 1 auto">
                  {{ $t('application.navigation.work.notifications') }}
                </div>
                <v-icon v-if="hasUnreadNotifications" small color="red darken-4" class="pr-1">
                  mdi-circle
                </v-icon>
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item :to="{ name: 'searchHome' }" active-class="no-active">
            <v-list-item-icon>
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('application.navigation.search') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isOrgAdmin" :to="{ name: 'my-organization'}" active-class="no-active">
            <v-list-item-icon>
              <v-icon>
                mdi-office-building
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('application.navigation.manageOrg') }}
            </v-list-item-title>
          </v-list-item>
          <template v-if="isAdmin">
            <v-list-group prepend-icon="mdi-security">
              <template #activator>
                <v-list-item-title>
                  {{ $t('application.navigation.administration') }}
                </v-list-item-title>
              </template>
              <v-list-item :to="{ name: 'admin-search-user' }" active-class="no-active">
                <v-list-item-title>
                  {{ $t('application.navigation.administration.user.search') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'admin-notifications' }" active-class="no-active">
                <v-list-item-title>
                  {{ $t('application.navigation.administration.notifications.search') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="isEnableSubscriptionFunctions" :to="{ name: 'admin-subscriptions' }" active-class="no-active">
                <v-list-item-title>
                  {{ $t('application.navigation.administration.subscriptions') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'admin-kiosk-mode' }" active-class="no-active">
                <v-list-item-title>
                  {{ $t('kioskMode.title') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'admin-configuration' }" active-class="no-active">
                <v-list-item-title>
                  {{ $t('application.navigation.administration.configuration') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'admin-message-errors' }" active-class="no-active">
                <v-list-item-title>
                  {{ $t('application.navigation.administration.messageErrors') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'admin-receipts' }" active-class="no-active">
                <v-list-item-title>
                  {{ $t('application.navigation.administration.receipts') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'admin-organizations' }" active-class="no-active">
                <v-list-item-title>
                  {{ $t('application.navigation.administration.organizations') }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
          </template>
          <template v-if="hasSession">
            <v-list-group prepend-icon="mdi-briefcase">
              <template #activator>
                <v-list-item-title>
                  {{ $t('application.navigation.work') }}
                </v-list-item-title>
              </template>
              <v-list-item v-if="isFiler" :to="{ name: 'work-filings' }" active-class="no-active">
                <v-list-item-title>
                  {{ $t('application.navigation.work.filings') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'work-cases' }" active-class="no-active">
                <v-list-item-title>
                  {{ $t('application.navigation.work.cases') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'work-hearings' }" active-class="no-active">
                <v-list-item-title>
                  {{ $t('application.navigation.work.hearings') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="isInterpreter" :to="{ name: 'work-tasks' }" active-class="no-active">
                <v-list-item-title>
                  {{ $t('application.navigation.work.tasks') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="isInterpreter" :to="{ name: 'work-invites' }" active-class="no-active">
                <v-list-item-title>
                  {{ $t('application.navigation.work.invites') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'work-receipts' }" active-class="no-active">
                <v-list-item-title>
                  {{ $t('application.navigation.work.myReceipts') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="isEnableDocumentPurchaseFunctions" :to="{ name: 'work-purchased-documents' }" active-class="no-active">
                <v-list-item-title>
                  {{ $t('application.navigation.work.myPurchasedDocuments') }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
          </template>
          <v-list-group v-if="!isKioskMode" prepend-icon="mdi-pencil-plus">
            <template #activator>
              <v-list-item-title>
                {{ $t('application.navigation.create') }}
              </v-list-item-title>
            </template>
            <!-- this is a bit redundant but it's nicer to render a true hyperlink when you are logged in vs using the click handler
            approach (which will show the user a warning message) -->
            <v-list-item
              :to="hasSession ? { name: 'requestAccess', params: { userTypeID: CONST.USER_TYPE_PUBLIC } } : null"
              @click="!hasSession ? forwardToRequestAccess(CONST.USER_TYPE_PUBLIC) : null"
              active-class="no-active">
              <v-list-item-title>
                {{ $t('application.navigation.create.requestCaseAccess') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isFiler" :to="{ name: 'create-filing' }" active-class="no-active">
              <v-list-item-title>
                {{ $t('application.navigation.create.newFiling') }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
          <template v-if="smallOrDownDisplay">
            <v-list-item v-if="!hasSession" :to="{ name: 'language' }" active-class="no-active">
              <v-list-item-icon>
                <v-icon>
                  mdi-earth
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t('application.banner.language') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-group prepend-icon="mdi-help-circle">
              <template #activator>
                <v-list-item-title>
                  {{ $t('global.help') }}
                </v-list-item-title>
              </template>
              <v-list-item class="inset-focus" active-class="no-active" @click="togglePageHelp(true)">
                <v-list-item-title>
                  {{ $t('application.banner.pageHelp') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'about' }" class="inset-focus" active-class="no-active">
                <v-list-item-title>
                  {{ $t('application.banner.about') }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
          </template>
        </v-list>

        <navigation-bottom/>

        <template #append>
          <div v-if="isKioskMode" class="pa-2" style="border-top:1px solid var(--line-color);">
            <div class="d-flex align-center primary--text font-weight-medium">
              <div class="pl-2" style="flex: 1 1 auto;">
                <div>
                  {{ $t('kioskMode.activeBanner') }}
                </div>
              </div>
              <div style="flex: 0 0 auto">
                <v-btn icon @click="kioskDetailsPopupVisible = true">
                  <v-icon color="primary">
                    mdi-information
                  </v-icon>
                  <span class="d-sr-only">
                    {{ $t('kioskMode.activeBanner.details') }}
                  </span>
                </v-btn>
              </div>
            </div>
          </div>

          <template v-if="!isKioskMode && smallOrDownDisplay">
            <v-divider/>
            <div class="pa-2">
              <c-btn v-if="hasSession" block type="primary" @click="redirectToLogout">
                {{ $t('application.banner.user.logout') }}
              </c-btn>
              <c-btn v-else block type="primary" @click="redirectToLogin">
                {{ $t('application.banner.login') }} / {{ $t('application.banner.register') }}
              </c-btn>
            </div>
          </template>
        </template>
      </v-navigation-drawer>
    </div>

    <v-main id="main-content">
      <transition name="scroll-x-reverse-transition" mode="out-in" appear>
        <router-view class="px-2" :key="$store.state.application.routerViewKey"/>
      </transition>
    </v-main>

    <v-footer id="footer-content" class="align-center" app inset absolute>
      <div style="flex:1 1 auto">
        <div>
          {{ $t('application.application.name') }}
        </div>
        <div>
          {{ $t('application.createdBy.name') }}
        </div>
      </div>
      <div class="text-right d-print-none" style="flex:1 1 auto">
        <router-link :to="{ name: 'site-map' }">
          {{ $t('global.siteMap') }}
        </router-link>
      </div>
    </v-footer>

    <page-help-popup :visible="pageHelpPopupVisible" @close="togglePageHelp(false);"/>
    <server-error-popup/>
    <server-validation-error-popup/>
    <kiosk-details-popup v-model="kioskDetailsPopupVisible"/>
    <inactivity-popup/>

    <c-snackbar/>
    <c-blocker/>
    <c-confirm/>

  </v-app>
</template>

<script>
import BannerCenter from '@components/support/BannerCenter';
import BannerLeft from '@components/support/BannerLeft';
import NavigationBottom from '@components/support/NavigationBottom';
import PageHelpPopup from '@components/support/PageHelpPopup';
import ServerErrorPopup from '@components/support/ServerErrorPopup';
import ServerValidationErrorPopup from '@components/support/ServerValidationErrorPopup';
import InactivityPopup from '@components/support/InactivityPopup';
import KioskDetailsPopup from '@components/support/KioskDetailsPopup';
import { redirectToLogin, redirectToLogout } from '@/application/application';
import { focusElement } from '@/application/utility';
import UserAccess from '@/application/userAccess';

export default {
  components: { BannerCenter, BannerLeft, NavigationBottom, PageHelpPopup, ServerErrorPopup, ServerValidationErrorPopup,
    KioskDetailsPopup, InactivityPopup },
  data () {
    return {
      drawer: false,
      skipLinks: false,
      pageHelpPopupVisible: false,
      kioskDetailsPopupVisible: false
    };
  },
  computed: {
    user () {
      return this.$store.state.user;
    },
    hasSession () {
      return this.$store.state.user.hasSession;
    },
    userDisplayName () {
      return this.$store.state.user.displayName;
    },
    largeDisplay () {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    smallOrDownDisplay () {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isKioskMode () {
      return this.$store.getters['application/isKioskMode'];
    },
    isEnableDocumentPurchaseFunctions () {
      return this.$store.getters['application/getAppProperty'](this.CONST.FEATURE_PROPERTY_ENABLE_DOCUMENT_PURCHASE_FUNCTIONS);
    },
    isEnableSubscriptionFunctions () {
      return this.$store.getters['application/getAppProperty'](this.CONST.FEATURE_PROPERTY_ENABLE_SUBSCRIPTION_FUNCTIONS);
    },
    isFiler () {
      return this.$store.getters['user/hasRole'](this.CONST.ROLE_FILER);
    },
    isInterpreter () {
      return this.$store.getters['user/hasRole'](this.CONST.ROLE_INTERPRETER);
    },
    isOrgAdmin () {
      return this.$store.getters['user/hasRole'](this.CONST.ROLE_ORGANIZATION_ADMINISTRATOR);
    },
    isAdmin () {
      return this.$store.getters['user/isAdmin'];
    },
    hasUnreadAnnouncements () {
      return this.$store.getters['user/hasUnreadAnnouncements'];
    },
    hasUnreadNotifications () {
      return this.$store.getters['user/hasUnreadNotifications'];
    },
    hasUnreadServices () {
      return this.$store.getters['user/hasUnreadServices'];
    },
    hasUnpurchasedDocumentsInCart () {
      return this.$store.getters['user/hasUnpurchasedDocumentsInCart'];
    }
  },
  methods: {
    redirectToLogin,
    redirectToLogout,
    forwardToRequestAccess: UserAccess.forwardToRequestAccess,
    focusTitle () {
      focusElement('.page-focus');
    },
    togglePageHelp (state) {
      this.pageHelpPopupVisible = state;
    }
  }
};
</script>
