<template>
  <loading-card :loading="loading">
    <template #default>
      <v-card-title>
        {{ $t('page.manageOrg.receipts.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.manageOrg.receipts.description') }}
      </v-card-subtitle>
      <v-card-text class="pa-0">
        <v-data-table
          :items="items"
          :headers="headers"
          :sort-by="paging.sortBy"
          :sort-desc="paging.sortDesc"
          :server-items-length="paging.totalElements"
          :loading="loadingTable"
          item-key="resourceID"
          must-sort
          hide-default-footer
          :mobile-breakpoint="800"
          @update:options="paging.updateOptions">
          <template #item.referenceNumber="{ item }">
            <router-link :to="{ name: 'receipt', params: { receiptID: item.resourceID} }">
              {{ item.referenceNumber }}
            </router-link>
          </template>
          <template #item.receiptDate="{ value }">
            {{ value | formatDateTime }}
          </template>
          <template #item.amount="{ value }">
            {{ value | formatCurrency }}
          </template>
          <template #footer>
            <paging-controls-new :paging="paging"/>
          </template>
        </v-data-table>
      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import LoadingCard from '@components/support/LoadingCard';
import JSURL from 'jsurl';
import { replaceIgnoreDuplicate } from '@/application/utility';
import PagingControlsNew from '@components/support/PagingControlsNew';
import Paging from '@/application/paging';

export default {
  components: { LoadingCard, PagingControlsNew },
  props: {
    organizationID: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      loadingTable: true,
      items: [],
      headers: [
        { text: this.$t('page.manageOrg.receipts.column.receiptDate'), value: 'receiptDate' },
        { text: this.$t('page.manageOrg.receipts.column.referenceNumber'), value: 'referenceNumber' },
        { text: this.$t('page.manageOrg.receipts.column.receiptType'), value: 'receiptType.displayName' },
        { text: this.$t('page.manageOrg.receipts.column.receiptStatus.short'), value: 'receiptStatus.displayName' },
        { text: this.$t('page.manageOrg.receipts.column.amount'), value: 'amount' },
        { text: this.$t('page.manageOrg.receipts.column.user'), value: 'user.actor.sortName' }
      ],
      paging: new Paging({
        size: 25,
        sortBy: 'receiptDate',
        sortDesc: true,
        searchCallback: this.loadReceipts
      })
    };
  },
  created () {
    let criteria = JSURL.parse(_.defaultTo(this.$route.query.criteria, ''));
    this.paging.parseURLParams(criteria);

    this.loadReceipts(false)
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    loadReceipts (updateURL = true) {
      if (updateURL)
      {
        // updates the browser URL, so we can restore the paging state if the user navigates back
        replaceIgnoreDuplicate({
          name: 'manage-org-receipts',
          params: {
            organizationID: this.organizationID
          },
          query: {
            criteria: JSURL.stringify(this.paging.generateParams())
          }
        });
      }

      let url = this.$cove.getAPI({
        name: 'api.receipts.search',
        query: {
          organizationID: this.organizationID,
          ...this.paging.generateParams()
        }
      });

      this.loadingTable = true;
      return axios.get(url)
        .then((response) => {
          this.items = _.get(response, 'data._embedded.results', []);
          this.paging.parseResponse(response);
        })
        .finally(() => {
          this.loadingTable = false;
        });
    }
  }
};
</script>