<template>
  <page-wrapper :title="$t('page.filingResults.title')">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="items"
          :headers="headers"
          :sort-by="searchDTO.sort.sortBy"
          :sort-desc="searchDTO.sort.sortDesc"
          :server-items-length="searchDTO.page.totalElements"
          item-key="resourceID"
          must-sort
          hide-default-footer
          class="elevation-2"
          :mobile-breakpoint="800"
          @update:options="updateOptions">
          <template #top>
            <div class="d-flex align-center">
              <div class="c-caption pt-2 pl-3" style="flex:1 1 auto">
                {{ $t('page.filingResults.table') }}
              </div>
              <div class="text-right pt-2 pr-2 d-print-none" style="flex:1 1 auto">
                <v-btn icon color="primary" @click="toggleDrawer">
                  <v-icon>
                    mdi-filter
                  </v-icon>
                  <span class="d-sr-only">
                    {{ $t('global.table.filterAndSort') }}
                  </span>
                </v-btn>
              </div>
            </div>
          </template>
          <template #item.referenceNumber="{ item }">
            <div>
              <div style="white-space: nowrap">
                <router-link :to="{ name: 'manage-filing', params: { filingID: item.resourceID } }">
                  {{ item.referenceNumber }}
                </router-link>
              </div>
              <div>
                {{ item.referenceName }}
              </div>
            </div>
          </template>
          <template #item.caseNumber="{ item }">
            <div>
              <div v-if="!item.caseNumber">
                {{ $t('page.filingResults.newCaseFiling') }}
              </div>
              <div v-if="showCaseLink(item)" style="white-space: nowrap">
                <router-link :to="{ name: 'caseView', params: { courtID: item.courtResourceID, caseUUID: item.caseIdentifier} }">
                  {{ item.caseNumber }}
                </router-link>
              </div>
              <div v-if="!item.filerCaseAccessible" style="white-space: nowrap">
                {{ item.caseNumber }}
              </div>
              <div :style="item.newCaseFiling ? 'display:none' : '' ">
                {{ getCaseTitle(item) }}
              </div>
            </div>
          </template>
          <template #item.filingStatusDisplayName="{ item }">
            <filing-status :filingStatusID="item.filingStatusResourceID"/>
          </template>
          <template #item.filingStatusDate="{ value }">
            <div style="min-width:100px">
              <div>
                {{ value | formatDate }}
              </div>
              <div>
                {{ value | formatTime }}
              </div>
            </div>
          </template>
          <template #footer>
            <paging-controls :page="searchDTO.page" @changePage="changePage"/>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-navigation-drawer
      v-model="drawer"
      temporary
      right
      app
      disable-route-watcher
      width="400"
      class="js-filter-sidebar">
      <div class="px-4 py-2">
        <div class="d-flex align-center">
          <div style="flex:0 0 auto">
            <h1 class="text-h6" tabindex="-1" style="line-height:1rem;outline-offset:6px">
              {{ $t('global.drawer.filterSort.title') }}
            </h1>
          </div>
          <v-spacer/>
          <div style="flex:0 0 auto" class="text-right">
            <v-btn icon color="default" @click="drawer = false;">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <p class="mb-0 text-minimum-gray">
          {{ $t('global.drawer.filterSort.description') }}
        </p>
      </div>
      <v-divider/>
      <div class="px-4 pt-2 pb-6">
        <div class="c-caption mb-5">
          {{ $t('global.sort') }}
        </div>
        <c-autocomplete
          v-model="internalSortBy"
          :label="$t('global.drawer.filterSort.field.sortBy')"
          :clearable="false"
          :items="sortableColumns"
          item-text="text"
          item-value="value"
          outlined
          hide-details/>
        <transition name="fade-transition">
          <v-radio-group
            v-model="internalSortDesc"
            class="mb-n1"
            hide-details
            :label="$t('global.drawer.filterSort.field.sortDir')">
            <v-radio
              :label="$t('global.ascending')"
              :value="false"/>
            <v-radio
              :label="$t('global.descending')"
              :value="true"/>
          </v-radio-group>
        </transition>
      </div>
      <v-divider/>
      <div class="px-4 pt-2 pb-4">
        <div class="c-caption js-criteria-anchor">
          {{ $t('global.filter') }}
        </div>

        <validation-error-panel class="mt-2 mb-n1"/>
        <div>
          <transition name="scroll-y-transition" mode="out-in">
            <form>
              <v-row no-gutters class="mt-4">
                <v-col cols="12">
                  <c-text-field
                    v-model="searchDTO.referenceNumber"
                    :label="$t('filing.referenceNumber')"
                    maxlength="100"
                    outlined
                    hide-details="auto"
                    aria-describedby="a5849db3c-fe7d-48db-8e29-5b91d2d9a3d3"/>
                  <field-description id="a5849db3c-fe7d-48db-8e29-5b91d2d9a3d3"
                                     message-key="page.filingResults.referenceNumber.description"/>
                </v-col>
                <v-col cols="12">
                  <c-text-field
                    v-model="searchDTO.referenceName"
                    :label="$t('filing.referenceName')"
                    maxlength="250"
                    outlined
                    hide-details="auto"
                    aria-describedby="a4da3c569-5796-4d82-835f-ff8fc9a6146e"/>
                  <field-description id="a4da3c569-5796-4d82-835f-ff8fc9a6146e"
                                     message-key="page.filingResults.referenceName.description"/>
                </v-col>
                <v-col v-if="!isSingleCourtMode" cols="12">
                  <c-autocomplete
                    :items="courts"
                    v-model="searchDTO.courtID"
                    item-text="displayName"
                    item-value="resourceID"
                    :label="$t('courtCase.court')"
                    outlined
                    @change="changeCourt"
                    key="courtID"
                    hide-details="auto"
                    aria-describedby="a15c521ed-92a4-4616-b9d1-a80d44675327"/>
                  <field-description id="a15c521ed-92a4-4616-b9d1-a80d44675327"
                                     message-key="page.filingResults.court.description"/>
                </v-col>
                <v-col cols="12">
                  <c-text-field
                    v-model="searchDTO.caseNumber"
                    :label="$t('courtCase.caseNumber')"
                    maxlength="250"
                    outlined
                    hide-details="auto"
                    aria-describedby="a4fff4d48-1fab-4242-9cb3-cd2556b665ae"/>
                  <field-description id="a4fff4d48-1fab-4242-9cb3-cd2556b665ae"
                                     message-key="page.filingResults.caseNumber.description"/>
                </v-col>
                <v-col cols="12">
                  <c-text-field
                    v-model="searchDTO.caseTitle"
                    :label="$t('courtCase.caseTitle')"
                    maxlength="250"
                    outlined
                    hide-details="auto"
                    aria-describedby="a8d30a3c1-f653-494c-9c1d-2ca48078a2c6"/>
                  <field-description id="a8d30a3c1-f653-494c-9c1d-2ca48078a2c6"
                                     message-key="page.filingResults.caseTitle.description"/>
                </v-col>
                <v-col cols="12">
                  <c-autocomplete
                    :items="leadDocketEntrySubtypes"
                    v-model="searchDTO.leadDocketEntrySubtypeID"
                    item-text="text"
                    item-value="value"
                    :label="$t('filing.leadDocketEntry')"
                    outlined
                    hide-details="auto"
                    :no-data-text="$t('global.courtFirst')"
                    aria-describedby="a48577fe1-5322-401b-915e-e7a38fa5489b"/>
                  <field-description id="a48577fe1-5322-401b-915e-e7a38fa5489b"
                                     message-key="page.filingResults.leadDocketEntry.description"/>
                </v-col>
                <v-col cols="12">
                  <c-autocomplete
                    :items="filingStatuses"
                    v-model="searchDTO.filingStatusID"
                    item-text="displayName"
                    item-value="resourceID"
                    :label="$t('filing.filingStatus')"
                    outlined
                    hide-details="auto"
                    aria-describedby="d31db11c-8566-455e-91d0-52c28e2da76f"/>
                  <field-description id="d31db11c-8566-455e-91d0-52c28e2da76f"
                                     message-key="page.filingResults.filingStatus.description"/>
                </v-col>
                <v-col cols="12">
                  <date-range-picker
                    :label="$t('filing.filingStatusDate')"
                    :labelStart="$t('filing.filingStatusDateFrom')"
                    :labelEnd="$t('filing.filingStatusDateTo')"
                    keyPrefix="filingStatusDate"
                    :inputAttributes="{ outlined: true }"
                    :choice.sync="searchDTO.filingStatusDateChoice"
                    :startDate.sync="searchDTO.filingStatusDateStart"
                    :endDate.sync="searchDTO.filingStatusDateEnd"
                    aria-describedby="a188c3dd0-cad8-48c6-aa77-2d95de2ac6eb"/>
                  <field-description id="a188c3dd0-cad8-48c6-aa77-2d95de2ac6eb"
                                     message-key="page.filingResults.filingStatusDate.description"/>
                </v-col>
                <v-col cols="12">
                  <c-autocomplete
                    :items="caseTypes"
                    v-model="searchDTO.caseTypeID"
                    item-text="displayName"
                    item-value="caseTypeID"
                    :label="$t('filing.caseType')"
                    outlined
                    hide-details="auto"
                    aria-describedby="de9ec188-12eb-4cfe-9f6d-019ed76e97b3"/>
                  <field-description id="de9ec188-12eb-4cfe-9f6d-019ed76e97b3"
                                     message-key="page.filingResults.caseType.description"/>
                </v-col>
              </v-row>

            </form>
          </transition>
        </div>
      </div>
      <template #append>
        <v-divider/>
        <div class="d-flex pa-4">
          <div class="pr-1" style="flex:0 0 50%">
            <c-btn block type="primary" @click="search">
              {{ $t('application.button.update') }}
            </c-btn>
          </div>
          <div class="pl-1" style="flex:0 0 50%">
            <c-btn block type="default" @click="drawer = false;">
              {{ $t('application.button.cancel') }}
            </c-btn>
          </div>
        </div>
      </template>
    </v-navigation-drawer>
  </page-wrapper>
</template>

<script>
import PageWrapper from '@components/support/PageWrapper';
import PagingControls from '@components/support/PagingControls';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import DateRangePicker from '@components/support/DateRangePicker';
import FieldDescription from '@components/support/FieldDescription';
import FilingStatus from '@components/support/FilingStatus';
import PageMixin from '@/mixins/page';
import JSURL from 'jsurl';
import _ from 'lodash';
import axios from 'axios';
import store from '@/plugins/store';
import cove from '@ctrack/cove';
import { dateToStartOfDay, dateToEndOfDay } from '@/application/application';
import Search from '@/application/search';
import { isTableSortClickEvent, replaceIgnoreDuplicate } from '@/application/utility';

export default {
  components: { PageWrapper, PagingControls, ValidationErrorPanel, DateRangePicker, FieldDescription, FilingStatus },
  mixins: [ PageMixin ],
  data () {
    return {
      searchDTO: {
        page: {
          size: 25,
          number: 0,
          totalElements: 0,
          totalPages: 0
        },
        sort: {
          sortBy: 'filingStatusDate',
          sortDesc: true
        },
        referenceNumber: null,
        referenceName: null,
        courtID: null,
        caseNumber: null,
        caseTitle: null,
        leadDocketEntrySubtypeID: null,
        filingStatusID: null,
        filingStatusDateChoice: null,
        filingStatusDateStart: null,
        filingStatusDateEnd: null,
        caseTypeID: null
      },
      items: [],
      loading: false,
      backupSearchDTO: null,
      internalSortBy: null,
      internalSortDesc: null,
      drawer: false,
      leadDocketEntrySubtypes: [],
      filingStatuses: []
    };
  },
  computed: {
    headers () {
      let headers = [];

      headers.push({ text: this.$t('filing.referenceNumber'), value: 'referenceNumber', sortable: true });
      if (!this.isSingleCourtMode)
      {
        headers.push({ text: this.$t('courtCase.court'), value: 'courtDisplayName', sortable: true });
      }
      headers.push({ text: this.$t('courtCase.case'), value: 'caseNumber', sortable: true });
      headers.push({ text: this.$t('courtCase.caseClassification'), value: 'caseClassificationName', sortable: false });
      headers.push({ text: this.$t('filing.leadDocketEntry'),
        value: 'docketEntryExternalName', sortable: false });
      headers.push({ text: this.$t('filing.filingStatus'), value: 'filingStatusDisplayName', sortable: true });
      headers.push({ text: this.$t('filing.filingStatusDate'), value: 'filingStatusDate', sortable: true });

      return headers;
    },
    sortableColumns () {
      let filters = _.filter(this.headers, { sortable: true });
      return _.orderBy(filters, 'text');
    },
    courts () {
      return this.$store.state.application.courts;
    },
    isSingleCourtMode () {
      return this.$store.getters['application/isSingleCourtMode'];
    },
    caseTypes () {
      let caseTypes = [
        {
          caseTypeID: this.CONST.FILING_CASE_TYPE_NEW,
          displayName: this.$t('page.filingResults.newCaseFiling')
        },
        {
          caseTypeID: this.CONST.FILING_CASE_TYPE_EXISTING,
          displayName: this.$t('page.filingResults.existingCaseFiling')
        }
      ];
      return caseTypes;
    }
  },
  watch: {
    drawer (newValue) {
      if (newValue)
      {
        this.internalSortBy = this.searchDTO.sort.sortBy;
        this.internalSortDesc = this.searchDTO.sort.sortDesc;
        this.backupSearchDTO = _.cloneDeep(this.searchDTO);

        setTimeout(() => {
          document.querySelector('.js-filter-sidebar h1').focus();
        }, 150);
      }
      else
      {
        this.$store.commit('cove/errors/clear');
        if (this.backupSearchDTO !== null)
        {
          this.searchDTO = this.backupSearchDTO;
        }
      }
    },
    'searchDTO.courtID': {
      immediate: true,
      handler (newValue) {
        if (!_.isNil(newValue))
        {
          axios.get(this.$cove.getAPI({ name: 'api.court.docketEntry.subtypes', params: { courtID: newValue } }))
            .then((response) => {
              this.leadDocketEntrySubtypes = Search.populateDocketEntrySubTypes(_.defaultTo(response.data, []));
            });
        }
        else
        {
          this.leadDocketEntrySubtypes = [];
        }

        if (this.searchDTO) {
          this.searchDTO.leadDocketEntrySubtypeID = null;
        }
      }
    }
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.filing.statuses' }))
      .then((response) => {
        this.filingStatuses = _.get(response, 'data._embedded.results', []);
      });

    _.merge(this.searchDTO, JSURL.parse(this.$route.query.criteria));
    this.loadSearchResults(false);
  },
  methods: {
    search () {
      let isValid = this.validateSearchCriteria(this.searchDTO);
      if (isValid)
      {
        // update sorting and paging
        this.searchDTO.sort = { sortBy: this.internalSortBy, sortDesc: this.internalSortDesc };
        this.searchDTO.page.number = 0;

        this.loadSearchResults(true);

        this.backupSearchDTO = null;
        this.drawer = false;
      }
      else
      {
        this.scrollSidebarTo(this.CONST.ERROR_CONTAINER_SELECTOR);
      }
    },
    validateSearchCriteria (searchDTO)
    {
      store.commit('cove/errors/clear');

      cove.validation.date.rejectIfInvalid(searchDTO.filingStatusDateStart, 'filing.filingStatusDateFrom',
        'filingStatusDate-startDate');
      cove.validation.date.rejectIfInvalid(searchDTO.filingStatusDateEnd, 'filing.filingStatusDateTo',
        'filingStatusDate-endDate');
      cove.validation.date.rejectIfBefore(searchDTO.filingStatusDateEnd, searchDTO.filingStatusDateStart,
        'filing.filingStatusDateTo', 'filing.filingStatusDateFrom', 'filingStatusDate-endDate');

      return !store.getters['cove/errors/hasErrors']();
    },
    loadSearchResults (updateURL) {
      if (updateURL)
      {
        let criteria = this.generateQueryObject(this.searchDTO);
        replaceIgnoreDuplicate({ name: 'work-filings', query: { criteria } });
      }

      let query = {
        page: this.searchDTO.page.number,
        size: this.searchDTO.page.size
      };

      if (!_.isNil(this.searchDTO.sort.sortBy))
      {
        query.sort = `${this.searchDTO.sort.sortBy},${this.searchDTO.sort.sortDesc ? 'desc' : 'asc'}`;
      }

      query.filedByUserID = this.$store.state.user.userID;

      if (!_.isNil(this.searchDTO.referenceNumber))
      {
        query.referenceNumber = this.searchDTO.referenceNumber;
      }

      if (!_.isNil(this.searchDTO.referenceName))
      {
        query.referenceName = this.searchDTO.referenceName;
      }

      if (!_.isNil(this.searchDTO.courtID))
      {
        query.courtID = this.searchDTO.courtID;
      }

      if (!_.isNil(this.searchDTO.caseNumber))
      {
        query.caseNumber = this.searchDTO.caseNumber;
      }

      if (!_.isNil(this.searchDTO.caseTitle))
      {
        query.caseTitle = this.searchDTO.caseTitle;
      }

      if (!_.isNil(this.searchDTO.leadDocketEntrySubtypeID))
      {
        let parsedDocketEntryValue = Search.parseDocketEntrySubTypeID(this.searchDTO.leadDocketEntrySubtypeID);
        if (!_.isNil(parsedDocketEntryValue.docketEntryTypeID))
        {
          query.leadDocketEntryTypeExternalIdentifier = parsedDocketEntryValue.docketEntryTypeID;
        }
        if (!_.isNil(parsedDocketEntryValue.docketEntrySubTypeID))
        {
          query.leadDocketEntrySubTypeExternalIdentifier = parsedDocketEntryValue.docketEntrySubTypeID;
        }
      }

      if (!_.isNil(this.searchDTO.filingStatusID))
      {
        query.filingStatusID = this.searchDTO.filingStatusID;
      }

      if (!_.isNil(this.searchDTO.filingStatusDateStart))
      {
        query.filingStatusDateFrom = dateToStartOfDay(cove.parseDate(this.searchDTO.filingStatusDateStart)).toISO();
      }

      if (!_.isNil(this.searchDTO.filingStatusDateEnd))
      {
        query.filingStatusDateTo = dateToEndOfDay(cove.parseDate(this.searchDTO.filingStatusDateEnd)).toISO();
      }

      if (!_.isNil(this.searchDTO.caseTypeID))
      {
        if (this.searchDTO.caseTypeID === this.CONST.FILING_CASE_TYPE_NEW)
        {
          query.newCaseFiling = true;
        }
        else if (this.searchDTO.caseTypeID === this.CONST.FILING_CASE_TYPE_EXISTING)
        {
          query.newCaseFiling = false;
        }
      }

      let url = this.$cove.getAPI({
        name: 'api.filings',
        query
      });

      this.$cove.block();
      this.loading = true;
      return axios.get(url)
        .then((response) => {
          this.items = _.get(response, 'data._embedded.results', []);
          this.searchDTO.page.number = response.data.page.number;
          this.searchDTO.page.totalPages = response.data.page.totalPages;
          this.searchDTO.page.totalElements = response.data.page.totalElements;
          this.scrollTo('html');
        })
        .finally(() => {
          this.$cove.unblock();
          this.loading = false;
        });
    },
    toggleDrawer () {
      this.drawer = true;
    },
    changePage (newPage) {
      this.searchDTO.page.number = newPage;
      this.loadSearchResults(true);
    },
    changeSort (sorting) {
      this.searchDTO.sort = sorting;
      this.searchDTO.page.number = 0;
      this.loadSearchResults(true);
    },
    changeCourt () {
      this.searchDTO.leadDocketEntrySubtypeID = null;
    },
    updateOptions (options) {
      if (isTableSortClickEvent())
      {
        let sortBy = options.sortBy[0];
        let sortDesc = options.sortDesc[0];
        this.changeSort({ sortBy, sortDesc });
      }
    },
    generateQueryObject () {
      let cloned = _.cloneDeep(this.searchDTO);
      cloned = _.omitBy(cloned, _.isNil);
      return JSURL.stringify(cloned);
    },
    isExistingCaseFiling (item) {
      return !_.isNil(item.newCaseFiling) && !item.newCaseFiling;
    },
    getCaseTitle (item) {
      // if the case number is not populated, then it's a new case filing before the filing has been approved, so return null.
      // if the case number is populated, then try to render the case title or secure case.
      let caseTitle = null;
      if (!_.isNil(item.caseNumber))
      {
        caseTitle = (!_.isNil(item.filerCaseAccessible) && item.filerCaseAccessible) ?
          item.caseTitle : this.$t('courtCase.secure');
      }
      return caseTitle;
    },
    showCaseLink (item) {
      return item.filerCaseAccessible && !_.isNil(item.caseIdentifier);
    }
  }
};
</script>
