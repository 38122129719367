<template>
  <loading-card :loading="loading">
    <v-card-title>
      {{ $t('page.manageFiling.abbreviatedFiling.summary.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.manageFiling.abbreviatedFiling.summary.description') }}
    </v-card-subtitle>
    <v-card-text class="pt-2">
      <v-row no-gutters>
        <v-col cols="12">
          <dl class="d-grid grid-4060">
            <dt>
              {{ $t('page.manageFiling.abbreviatedFiling.summary.docketEntry.name') }}
            </dt>
            <dd>
              {{ docketEntryName }}
            </dd>
            <dt>
              {{ $t('page.manageFiling.abbreviatedFiling.summary.filedOnBehalfOf.name') }}
            </dt>
            <dd>
              {{ filedOnBehalfOfName }}
            </dd>
            <template v-if="!isNil(filingStatement)">
              <dt>
                {{ $t('page.manageFiling.abbreviatedFiling.summary.filingStatement') }}
              </dt>
              <dd>
                {{ filingStatement }}
              </dd>
            </template>
            <template v-if="!isNil(bean.docketEntryDocument.documentLinkID)">
              <dt>
                {{ $t('global.document') }}
              </dt>
              <dd>
                <a :href="viewDocumentURL" target="_blank">
                  {{ bean.docketEntryDocument.documentName }}
                </a>
              </dd>
            </template>
            <template v-if="!isNil(comments)">
              <dt>
                {{ $t('page.manageFiling.abbreviatedFiling.summary.comments') }}
              </dt>
              <dd>
                {{ comments }}
              </dd>
            </template>
          </dl>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-end mt-5">
      <template v-if="!isLocked">
        <c-btn type="primary" @click="$emit('edit')">
          {{ $t('global.edit') }}
        </c-btn>
      </template>
    </v-card-actions>
  </loading-card>
</template>

<script>
import WorkflowStepMixin from '@/mixins/workflowStep';
import Filing from '@/application/filing';
import _ from 'lodash';

export default {
  mixins: [ WorkflowStepMixin ],
  computed: {
    viewDocumentURL () {
      return this.$cove.getAPI({
        name: 'api.dms.document',
        params: { documentLinkID: this.bean.docketEntryDocument.documentLinkID }
      });
    },
    docketEntryName () {
      let filingType = this.$store.state.filing.filingType;
      let docketEntrySubtype = filingType.docketEntrySubtype;
      return docketEntrySubtype.docketEntryType.docketEntryTypeName + ' - ' + docketEntrySubtype.docketEntrySubTypeName;
    },
    filingStatement () {
      return this.bean.docketEntry.docketEntryNote;
    },
    comments () {
      return this.bean.filing.filingNote;
    },
    filedOnBehalfOfName () {
      let filedOnBehalfOf =_.get(this.$store.state.filing, 'filing.filedOnBehalfOf[0]', null);
      return Filing.getFiledOnBehalfOfName(filedOnBehalfOf);
    },
    isLocked () {
      return this.$store.getters['filing/isLocked'];
    }
  }
};
</script>