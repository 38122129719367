<template>
  <loading-card :loading="false">
    <v-card-title>
      {{ $t('page.manageFiling.party.stepAddress.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.manageFiling.party.stepAddress.description') }}
    </v-card-subtitle>
    <v-card-text class="pt-2">
      <address-form :address="bean" ref="addressForm"/>
    </v-card-text>
    <v-card-actions class="mt-5">
      <div>
        <c-btn v-if="!editing && !isRequired" @click="$emit('skip')">
          {{ $t('global.skipThisStep') }}
        </c-btn>
      </div>
      <v-spacer/>
      <div>
        <c-btn type="primary" @click="save">
          {{ editing ? $t('global.save') : $t('global.next') }}
        </c-btn>
        <c-btn v-if="showRemoveButton" @click="remove">
          {{ $t('global.remove') }}
        </c-btn>
        <c-btn v-else-if="!editing" @click="$emit('back')">
          {{ $t('global.back') }}
        </c-btn>
        <c-btn @click="$emit('cancel')">
          {{ $t('global.cancel') }}
        </c-btn>
      </div>
    </v-card-actions>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import WorkflowStepMixin from '@/mixins/workflowStep';
import AddressForm from '@/components/support/AddressForm';
import Filing from '@/application/filing';

export default {
  components: { AddressForm },
  mixins: [ WorkflowStepMixin ],
  props: {
    editing: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isRequired () {
      return Filing.getPartyFieldConfig().requireAddress;
    },
    showRemoveButton () {
      // checking originalbean line1 since I don't have a good way of knowing if this address was persisted or not
      return this.editing && !this.isRequired && !_.isNil(this.originalBean.line1);
    }
  },
  methods: {
    validate () {
      this.$store.commit('cove/errors/clear');
      this.$refs.addressForm.validate();
      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      let isValid = this.validate();
      if (!isValid)
      {
        this.scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
      }
      else
      {
        this.$emit('save', this.bean);
      }
    },
    remove () {
      this.$cove.confirm({ message: this.$t('application.remove.confirm') })
        .then((confirm) => {
          if (confirm)
          {
            this.$emit('remove');
          }
        });
    }
  }
};
</script>