<template>
  <div>
    <workflow-wrapper>
      <parties-table v-if="stepID === 'partiesTable'"
                     key="partiesTable"
                     @start="start"
                     @edit="edit"
                     @next="$emit('next')"/>
      <edit-party v-else-if="stepID === 'edit'"
                  :partyID="editPartyID"
                  key="edit"
                  @exit="exitEdit"/>

      <div v-else key="workflow">
        <div class="mx-auto" style="max-width:675px">
          <div class="mb-1" style="font-size:1.4rem">
            {{ $t('page.manageFiling.party.addParty') }}
          </div>

          <v-card class="mb-3 py-2 px-3">
            <breadcrumbs v-model="stepID" :steps="steps"/>
          </v-card>

          <validation-error-panel/>
          <workflow-wrapper>
            <step-role v-if="stepID === 'role'"
                       key="role"
                       :originalBean="bean.party"
                       @save="saveRole"
                       @cancel="cancel"/>
            <step-name v-else-if="stepID === 'name'"
                       key="name"
                       :originalBean="bean.actor"
                       @save="saveName"
                       @back="gotoRole"
                       @cancel="cancel"/>
            <step-address v-else-if="stepID === 'address'"
                          key="address"
                          :originalBean="bean.address"
                          @skip="skipAddress"
                          @save="saveAddress"
                          @back="gotoName"
                          @cancel="cancel"/>
            <step-contact v-else-if="stepID === 'contact'"
                          key="contact"
                          :originalBean="bean.contact"
                          @skip="skipContact"
                          @save="saveContact"
                          @back="gotoAddress"
                          @cancel="cancel"/>
            <step-representation v-else-if="stepID === 'representation'"
                                 key="representation"
                                 :originalBean="bean.representation"
                                 @save="saveRepresentation"
                                 @back="gotoContact"
                                 @cancel="cancel"/>
            <step-summary v-else-if="stepID === 'summary'"
                          key="summary"
                          :originalBean="bean"
                          @save="saveSummary"
                          @back="gotoRepresentation"
                          @cancel="cancel"/>
          </workflow-wrapper>
        </div>
      </div>
    </workflow-wrapper>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { formatPhoneInput, getCountryForPhoneNumber, formatPhoneForSaving }
  from '@/application/utility';
import WorkflowWrapper from '@components/support/WorkflowWrapper';
import StepRole from '@components/support/manageFiling/party/StepRole';
import StepName from '@components/support/manageFiling/party/StepName';
import StepAddress from '@components/support/manageFiling/party/StepAddress';
import StepContact from '@components/support/manageFiling/party/StepContact';
import StepRepresentation from '@components/support/manageFiling/party/StepRepresentation';
import StepSummary from '@components/support/manageFiling/party/StepSummary';
import EditParty from '@components/support/manageFiling/party/EditParty';
import Breadcrumbs from '@components/support/Breadcrumbs';
import PartiesTable from '@components/support/manageFiling/party/PartiesTable';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import Filing from '@/application/filing';

export default {
  components: { WorkflowWrapper, StepRole, StepName, StepAddress, StepContact, StepRepresentation,
    StepSummary, EditParty, Breadcrumbs, PartiesTable, ValidationErrorPanel },
  data () {
    return {
      stepID: 'partiesTable',
      steps: [
        { stepID: 'role', name: this.$t('page.manageFiling.party.step.role') },
        { stepID: 'name', name: this.$t('page.manageFiling.party.step.name') },
        { stepID: 'address', name: this.$t('page.manageFiling.party.step.address') },
        { stepID: 'contact', name: this.$t('page.manageFiling.party.step.contact') },
        { stepID: 'representation', name: this.$t('page.manageFiling.party.step.representation') },
        { stepID: 'summary', name: this.$t('page.manageFiling.party.step.summary') }
      ],
      bean: this.generateFormBean(),
      editPartyID: null
    };
  },
  computed: {
    user () {
      return this.$store.state.user;
    },
    filingID () {
      return this.$store.state.filing.filingID;
    }
  },
  watch: {
    stepID (newValue) {
      if (newValue === 'partiesTable')
      {
        this.$store.dispatch('filing/toggleSteps', { visible: true, refresh: true });
      }
    }
  },
  methods: {
    start () {
      this.bean = this.generateFormBean();
      this.gotoRole();
      this.$store.dispatch('filing/toggleSteps', { visible: false });
    },
    edit (partyID) {
      this.editPartyID = partyID;
      this.stepID = 'edit';
      this.$store.dispatch('filing/toggleSteps', { visible: false });
    },
    exitEdit () {
      this.stepID = 'partiesTable';
    },
    gotoRole () {
      this.stepID = 'role';
    },
    saveRole (party) {
      let checkedAddMyself = !this.bean.party.addMyself && party.addMyself;
      let uncheckedAddMyself = this.bean.party.addMyself && !party.addMyself;

      this.bean.party = party;

      if (checkedAddMyself)
      {
        this.copyUserIntoActor();
      }
      else if (uncheckedAddMyself)
      {
        this.bean.actor = Filing.buildActorDTO();
        this.bean.address = Filing.buildAddressDTO();
        this.bean.contact = Filing.buildContactDTO();
      }

      this.gotoName();
    },
    gotoName () {
      this.stepID = 'name';
    },
    saveName (actor) {
      this.bean.actor = actor;
      this.gotoAddress();
    },
    gotoAddress () {
      this.stepID = 'address';
    },
    saveAddress (address) {
      this.bean.address = address;
      this.gotoContact();
    },
    skipAddress () {
      this.bean.address = Filing.buildAddressDTO();
      this.gotoContact();
    },
    skipContact () {
      this.bean.contact = Filing.buildContactDTO();
      this.gotoRepresentation();
    },
    gotoContact () {
      this.stepID = 'contact';
    },
    saveContact (contact) {
      this.bean.contact = contact;
      this.gotoRepresentation();
    },
    gotoRepresentation () {
      this.stepID = 'representation';
    },
    saveRepresentation (representation) {
      this.bean.representation = representation;
      this.gotoSummary();
    },
    gotoSummary () {
      this.stepID = 'summary';
    },
    saveSummary ({ addAnother }) {
      this.$cove.block({ delay: 0 });
      axios.post(this.$cove.getAPI({ name: 'api.filing.caseParties' }), this.generateBeanForSaving())
        .then(() => {
          if (addAnother)
          {
            this.start();
          }
          else
          {
            this.stepID = 'partiesTable';
          }

          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    cancel () {
      this.$cove.confirm({ message: this.$t('global.confirmation.cancel.create') })
        .then((confirm) => {
          if (confirm)
          {
            this.stepID = 'partiesTable';
          }
        });
    },
    copyUserIntoActor () {
      // clear out anything entered so far
      this.bean.actor = Filing.buildActorDTO();
      this.bean.address = Filing.buildAddressDTO();
      this.bean.contact = Filing.buildContactDTO();

      // apply the user's information into this party's information
      this.bean.actor.firstName = _.clone(this.user.firstName);
      this.bean.actor.lastName = _.clone(this.user.lastName);
      this.bean.actor.externalIdentifier = _.clone(_.defaultTo(this.user.externalIdentifier, null));
      this.bean.contact.primaryEmail = _.clone(this.user.emailAddress);

      let address = _.cloneDeep(this.user.mailingAddress);
      if (!_.isNil(address))
      {
        this.bean.address = _.pick(address, _.keys(this.bean.address));
      }

      let phone = _.clone(this.user.contactPhoneNumber);
      if (!_.isNil(phone))
      {
        let country = getCountryForPhoneNumber(phone);
        this.bean.contact.contactPhoneCountry = country;
        this.bean.contact.contactPhoneNumber = formatPhoneInput(phone.substr(phone.indexOf(' ') + 1), country.iso);
      }
    },
    generateBeanForSaving () {
      let bean = {
        filingID: this.filingID,
        partySubTypeExternalIdentifier: this.bean.party.partySubTypeExternalIdentifier,
        userID: this.bean.party.linkUserID,
        actor: {
          ...this.bean.actor
        }
      };

      if (!_.isNil(this.bean.address.line1))
      {
        bean.actor.mailingAddress = this.bean.address;
      }

      if (!_.isNil(this.bean.contact.primaryEmail))
      {
        _.set(bean.actor, 'contacts.primaryEmail', this.bean.contact.primaryEmail);
      }

      if (!_.isNil(this.bean.contact.contactPhoneNumber))
      {
        _.set(bean.actor, 'contacts.contactPhoneNumber',
          formatPhoneForSaving(this.bean.contact.contactPhoneCountry, this.bean.contact.contactPhoneNumber));
      }

      if (this.bean.representation.representationType === this.CONST.REP_TYPE_PRO_SE)
      {
        bean.legalRepresenterMap = {
          proSe: true
        };
      }
      else if (this.bean.representation.representationType === this.CONST.REP_TYPE_CURRENT_USER)
      {
        bean.legalRepresenterMap = {
          legalRepresenter: {
            actor: {
              firstName: this.user.firstName,
              middleName: this.user.middleName,
              lastName: this.user.lastName,
              prefix: this.user.prefix,
              suffix: this.user.suffix,
              externalIdentifier: this.user.externalIdentifier,
              person: true
            },
            userID: this.user.userID
          }
        };
      }
      else if (this.bean.representation.representationType === this.CONST.REP_TYPE_PRIVATE)
      {
        if (this.bean.representation.representationSelectionMode === this.CONST.REP_SELECTION_MODE_NEW)
        {
          let newRep = this.bean.representation.newRepresentation;
          bean.legalRepresenterMap = {
            legalRepresenter: {
              barNumber: newRep.legalRepresenter.barID,
              regionExternalIdentifier: newRep.legalRepresenter.regionExternalIdentifier,
              actor: {
                ...newRep.actor,
                mailingAddress: newRep.address,
                contacts: {
                  primaryEmail: newRep.contact.primaryEmail,
                  contactPhoneNumber: formatPhoneForSaving(newRep.contact.contactPhoneCountry, newRep.contact.contactPhoneNumber)
                }
              }
            }
          };
        }
        else if (this.bean.representation.representationSelectionMode === this.CONST.REP_SELECTION_MODE_ATTORNEY)
        {
          bean.legalRepresenterMap = {
            legalRepresenter: {
              actor: {
                firstName: this.bean.representation.existingAttorney.actorHeader.firstName,
                middleName: _.defaultTo(this.bean.representation.existingAttorney.actorHeader.middleName, null),
                lastName: this.bean.representation.existingAttorney.actorHeader.lastName,
                prefix: _.defaultTo(this.bean.representation.existingAttorney.prefix, null),
                suffix: _.defaultTo(this.bean.representation.existingAttorney.suffix, null),
                externalIdentifier: this.bean.representation.existingAttorney.actorHeader.actorUUID,
                person: true
              }
            }
          };
        }
        else if (this.bean.representation.representationSelectionMode === this.CONST.REP_SELECTION_MODE_LAW_FIRM)
        {
          bean.legalRepresenterMap = {
            legalRepresenter: {
              actor: {
                organizationName: this.bean.representation.existingLegalOrg.organizationName,
                externalIdentifier: this.bean.representation.existingLegalOrg.actorHeader.actorUUID,
                person: false
              }
            }
          };
        }
      }

      return bean;
    },
    generateFormBean () {
      return {
        party: {
          addMyself: false,
          linkUserID: null,
          involvementTypeID: null,
          partySubTypeName: null,
          partySubTypeExternalIdentifier: null
        },
        actor: Filing.buildActorDTO(),
        address: Filing.buildAddressDTO(),
        contact: Filing.buildContactDTO(),
        representation: {
          representationType: null,
          representationSelectionMode: null,
          existingAttorney: null,
          existingLegalOrg: null,
          newRepresentation: {
            legalRepresenter: {
              barID: null,
              regionExternalIdentifier: null
            },
            actor: Filing.buildActorDTO(),
            address: Filing.buildAddressDTO(),
            contact: Filing.buildContactDTO()
          }
        }
      };
    }
  }
};
</script>