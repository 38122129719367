var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading-card',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('page.manageOrg.users.title'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t('page.manageOrg.users.description'))+" ")]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"sort-by":_vm.paging.sortBy,"sort-desc":_vm.paging.sortDesc,"server-items-length":_vm.paging.totalElements,"loading":_vm.loadingTable,"item-key":"resourceID","must-sort":"","hide-default-footer":"","mobile-breakpoint":800},on:{"update:options":_vm.paging.updateOptions},scopedSlots:_vm._u([{key:"item.payment",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',[_vm._v(" mdi-check ")]):_vm._e(),_c('span',{staticClass:"d-sr-only"},[_vm._v(" "+_vm._s(value ? _vm.$t('global.yes') : _vm.$t('global.no'))+" ")])]}},{key:"item.subscription",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',[_vm._v(" mdi-check ")]):_vm._e(),_c('span',{staticClass:"d-sr-only"},[_vm._v(" "+_vm._s(value ? _vm.$t('global.yes') : _vm.$t('global.no'))+" ")])]}},{key:"item.admin",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',[_vm._v(" mdi-check ")]):_vm._e(),_c('span',{staticClass:"d-sr-only"},[_vm._v(" "+_vm._s(value ? _vm.$t('global.yes') : _vm.$t('global.no'))+" ")])]}},{key:"item.resourceID",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")]),_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(_vm.$t('global.action')))])],1)]}}],null,true)},[_c('v-list',{staticStyle:{"min-width":"100px"},attrs:{"dense":""}},[_c('v-list-item',{staticClass:"inset-focus",on:{"click":function($event){return _vm.editUser(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")])],1),(!_vm.isCurrentUser(item))?_c('v-list-item',{staticClass:"inset-focus",on:{"click":function($event){return _vm.removeUser(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('global.remove'))+" ")])],1):_vm._e()],1)],1)]}},{key:"footer",fn:function(){return [_c('paging-controls-new',{attrs:{"paging":_vm.paging}})]},proxy:true}])}),_c('div',{staticClass:"text-right pa-4"},[_c('c-btn',{attrs:{"type":"primary"},on:{"click":function($event){_vm.addUserPopupVisible = true;}}},[_vm._v(" "+_vm._s(_vm.$t('page.manageOrg.users.addUser'))+" ")])],1)],1)]},proxy:true}])}),_c('add-user-popup',{attrs:{"organizationID":_vm.organizationID},on:{"newUserAdded":_vm.refreshTable},model:{value:(_vm.addUserPopupVisible),callback:function ($$v) {_vm.addUserPopupVisible=$$v},expression:"addUserPopupVisible"}}),_c('edit-user-popup',{attrs:{"userMapID":_vm.selectedUserMapID},on:{"userEdited":_vm.refreshTable},model:{value:(_vm.editUserPopupVisible),callback:function ($$v) {_vm.editUserPopupVisible=$$v},expression:"editUserPopupVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }