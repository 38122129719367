<template>
  <page-wrapper :title="$t('global.error')" :subtitle="$t('page.error.invalidUser.subtitle')">
    <v-row>
      <v-col md="7">
        <v-card>
          <v-card-title>
            {{ $t('global.error') }}
          </v-card-title>
          <v-card-text>
            <p>
              {{ $t('page.error.invalidUser.message') }}
            </p>
          </v-card-text>
          <v-card-actions class="mt-10 justify-end">
            <c-btn type="primary" :to="{ name: 'home' }" style="min-width:120px">
              {{ $t('global.returnHome') }}
            </c-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import PageWrapper from '@components/support/PageWrapper';
import PageMixin from '@/mixins/page';

export default {
  components: { PageWrapper },
  mixins: [ PageMixin ]
};
</script>
