<template>
  <page-wrapper :title="$t('page.manageOrg.title')" :subtitle="subtitle">
    <nav-tabs :items="navTabs" :loading="loading"/>
  </page-wrapper>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import PageMixin from '@/mixins/page';
import PageWrapper from '@components/support/PageWrapper';
import NavTabs from '@components/support/NavTabs';
import { redirectNotFoundErrorHandler } from '@/application/ajax';

export default {
  components: { PageWrapper, NavTabs },
  mixins: [ PageMixin ],
  props: {
    organizationID: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      organization: null,
      loading: true
    };
  },
  computed: {
    isAdmin () {
      return this.$store.getters['user/isAdmin'];
    },
    subtitle () {
      return _.get(this.organization, 'organizationDetails.sortName', null);
    },
    hasFreeDocuments () {
      return _.get(this.organization, 'freeDocuments', false);
    },
    isEnableSubscriptionFunctions () {
      return this.$store.getters['application/getAppProperty'](this.CONST.FEATURE_PROPERTY_ENABLE_SUBSCRIPTION_FUNCTIONS);
    },
    navTabs () {
      let tabs = [
        { name: this.$t('page.manageOrg.nav.orgDetails'), routeName: 'manage-org-details' },
        { name: this.$t('page.manageOrg.nav.paymentMethod'), routeName: 'manage-org-payment-method' }
      ];

      if (this.isEnableSubscriptionFunctions)
      {
        tabs.push({ name: this.$t('page.manageOrg.nav.subscription'), routeName: 'manage-org-subscription' });
      }

      // only offer this tab if the org has free docs OR if the user is a sys admin, otherwise there is no reason for it
      if (this.hasFreeDocuments || this.isAdmin)
      {
        tabs.push({ name: this.$t('page.manageOrg.nav.documentAccess'), routeName: 'manage-org-document-access' });
      }

      tabs.push({ name: this.$t('page.manageOrg.nav.users'), routeName: 'manage-org-users' });
      tabs.push({ name: this.$t('page.manageOrg.nav.receipts'), routeName: 'manage-org-receipts' });

      return tabs;
    }
  },
  created () {
    let url = this.$cove.getAPI({
      name: 'api.organization',
      params: {
        id: this.organizationID
      },
      query: {
        fields: '*,organizationDetails(*)'
      }
    });

    axios.get(url, { errorHandlers: { 404: redirectNotFoundErrorHandler } })
      .then((response) => {
        this.organization = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
  }
};
</script>