<template>
  <div class="d-flex" style="justify-content:center">
    <div class="py-8 px-14 rounded" style="border:2px dashed var(--line-color);max-width:60ch;">
      <div class="text-center text-minimum-gray font-italic">
        <slot/>
      </div>
      <div v-if="$slots.action" class="mt-8 text-center">
        <slot name="action"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>