import _ from 'lodash';
import { DateTime, IANAZone, Settings } from 'luxon';
import { broadcastMessage } from '@/application/broadcast';
import axios from 'axios';
import CONST from '@/application/constants';
import Cookies from 'js-cookie';
import cove from '@ctrack/cove';
import i18n, { loadLocale } from '@/plugins/i18n';
import prettyBytes from 'pretty-bytes';
import store from '../plugins/store';
import vuetify from '@/plugins/vuetify';

/**
 * Returns the OS/browser timezone value.
 *
 * @returns {String}
 */
export function getBrowserTimezone ()
{
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * Returns a boolean representing if the user's OS/browser preference is for light mode (true) or dark mode (false).
 *
 * @returns {Boolean}
 */
export function getBrowserLightTheme ()
{
  // check for dark theme explicitly (versus checking for light) because in the event this check isn't working right it's better to default
  // to light rather than dark
  return !window.matchMedia('(prefers-color-scheme: dark)').matches;
}

/**
 * Returns the locale code associated with the user's OS/browser, falling back to our default locale if indeterminate.
 *
 * @returns {String}
 */
export function getBrowserLocale () {
  let locale = store.getters['application/getDefaultLocale'].localeCode;
  let navigator = window.navigator;
  if (navigator !== undefined && navigator !== null)
  {
    if (navigator.language !== undefined && navigator.language !== null)
    {
      locale = navigator.language;
    }
    else if (navigator.languages !== undefined && navigator.languages !== null)
    {
      locale = navigator.languages[0];
    }
  }

  return locale;
}

/**
 * Changes the current locale being used by the application.
 *
 * @param {String} localeCode
 */
export function changeLocale (localeCode)
{
  // update the locale in the store
  store.commit('user/setLocaleCode', localeCode);

  if (!store.state.user.hasSession)
  {
    // TODO do we need a cookie disclaimer in the US?
    setCookieValue(CONST.COOKIE_LOCALE, localeCode);
  }

  return loadLocale(localeCode)
    .then(() => {
      i18n.locale = localeCode;
      Settings.defaultLocale = localeCode;
      document.querySelector('html').setAttribute('lang', localeCode.substring(0, 2));

      let locale = store.getters['application/getLocaleByCode'](localeCode);
      cove.setConfig({
        'locale': localeCode,
        'parse.date': locale.dateParsePattern,
        'parse.time': locale.timeParsePattern,
        'format.currency': locale.currencyFormatPattern,
        'format.date': locale.dateFormatPattern,
        'format.dateTime': locale.dateTimeFormatPattern,
        'format.percent': locale.percentFormatPattern,
        'format.time': locale.timeFormatPattern
      });
    });
}

/**
 * Attempts to update the timezone used throughout the application.
 *
 * @param {String} timezone
 */
export function changeTimezone (timezone)
{
  // test that the selected timezone is valid with luxon
  let newTimezone = timezone;
  let validSpecifier = IANAZone.isValidSpecifier(newTimezone);
  let validTimezone = IANAZone.isValidZone(newTimezone);

  if (!validSpecifier || !validTimezone)
  {
    console.error('Unable to apply selected timezone: ' + newTimezone + '. Using UTC as default.');
    newTimezone = 'UTC';
  }

  store.commit('user/setTimezone', timezone);
  Settings.defaultZoneName = timezone;
}

/**
 * Changes the currently applied theme, between a light and dark theme.
 *
 * @param {Boolean} lightTheme
 */
export function changeTheme (lightTheme)
{
  store.commit('user/setTheme', lightTheme);
  vuetify.framework.theme.dark = !lightTheme;
}

/**
 * Set the user's default court identifier (UUID).
 *
 * @param {String} courtID
 */
export function changeCourt (courtID)
{
  store.commit('user/setCourt', courtID);
}

/**
 * Accepts a Luxon DateTime and returns an ISO formatted string representation of only the date portion encoded as in UTC timezone.
 * TODO this may make more sense in Cove but need to see how else we end up using date parsing in Portal...
 *
 * @param {*} dateTime a Luxon DateTime object
 */
export function dateOnlyToUTC (dateTime)
{
  if (dateTime === undefined || dateTime === null)
  {
    return null;
  }

  return DateTime.fromObject({
    year: dateTime.get('year'),
    month: dateTime.get('month'),
    day: dateTime.get('day'),
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0
  }).toISO();
}

/**
 * Accepts a Luxon DateTime and returns an ISO formatted string representation of only the date portion encoded as in UTC timezone.
 * TODO this may make more sense in Cove but need to see how else we end up using date parsing in Portal...
 *
 * @param {*} dateTime a Luxon DateTime object
 */
export function dateToStartOfDay (dateTime)
{
  if (dateTime === undefined || dateTime === null)
  {
    return null;
  }

  return DateTime.fromObject({
    year: dateTime.get('year'),
    month: dateTime.get('month'),
    day: dateTime.get('day'),
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 1 // TODO reset to zero once REST date issues addressed (backend 000 millisecond parsing issue)
  });
}

/**
 * Accepts a Luxon DateTime and returns an ISO formatted string representation of only the date portion encoded as in UTC timezone.
 * TODO this may make more sense in Cove but need to see how else we end up using date parsing in Portal...
 *
 * @param {*} dateTime a Luxon DateTime object
 */
export function dateToEndOfDay (dateTime)
{
  if (dateTime === undefined || dateTime === null)
  {
    return null;
  }

  return DateTime.fromObject({
    year: dateTime.get('year'),
    month: dateTime.get('month'),
    day: dateTime.get('day'),
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 900 // leaving a bit less than 999 to avoid any weird rounding issues which could affect the date
  });
}

/**
 * Returns a Promise that will resolves with either the current user JSON response object or null if there is no current user session.
 */
export function fetchCurrentUser ()
{
  return axios.get(cove.getAPI({ name: 'api.user.currentuser' }))
    .then((response) => {
      let data = response.data;
      return _.isObject(data) ? data : null;
    });
}

/**
 * Returns the local storage value associated with the specified key. Can optionally remove the value as well.
 *
 * Since the values are stringified on the way in, they will be parsed on the way out -- so you should recieve back the same type of element
 * you passed in, e.g. a number or boolean.
 *
 * @param {String} key
 * @param {Boolean} remove
 * @returns {*}
 */
export function getLocalStorageValue (key, remove = false)
{
  let result = localStorage.getItem(key);

  if (!_.isNil(result))
  {
    result = JSON.parse(result);
  }

  if (remove)
  {
    localStorage.removeItem(key);
  }

  return result;
}

/**
 * Stores a value within the browser for future use, suitable for values that need to persist across different page loads/refreshes.
 *
 * NOTE 1: Since localStorage only works with strings we'll stringify whatever comes in to try and preserve the type on retrieval. This only
 * works with primitives, strings, arrays, and simple objects.
 *
 * NOTE 2: We do not auto clear the values from this store so any sensitive / time-based values will need to be handled manually.
 *
 * @param {String} key
 * @param {*} value
 * @returns {Object}
 */
export function setLocalStorageValue (key, value)
{
  localStorage.setItem(key, JSON.stringify(value));
}

/**
 * Removes the specified entry from the browser's local storage.
 *
 * @param {String} key
 */
export function removeLocalStorageValue (key)
{
  localStorage.removeItem(key);
}

/**
 * Returns the value associated with the specified cookie. Returns undefined if no value is present for that cookie.
 *
 * @param {String} key
 * @returns {String|undefined}
 */
export function getCookieValue (key)
{
  return Cookies.get(key);
}

/**
 * Stores a cookie value.
 *
 * @param {String} key
 * @param {String} value
 */
export function setCookieValue (key, value)
{
  Cookies.set(key, value);
}

/**
 * Clears all browser cookies storage used by the application.
 */
export function clearCookies ()
{
  Object.keys(Cookies.get()).forEach((cookie) => {
    Cookies.remove(cookie);
  });
}

/**
 * Redirects the user to the (external) login URL. Accepts an optional redirect parameter which defaults to the current page.
 *
 * @param {Object}
 */
export function redirectToLogin ({ nextPath = window.location.pathname } = {})
{
  let next = nextPath;

  // if the user happened to be on an error page we don't want to restore that after they login, so send them home instead
  if (next.includes('/error/'))
  {
    next = '/portal/home';
  }

  setLocalStorageValue(CONST.BROWSER_STORAGE_NEXT_URL, next);

  // TODO at least verify it begins with our backend endpoint root? is this going to be flagged as dangerous?
  window.location.href = store.getters['application/getAppProperty'](CONST.APP_PROPERTY_LOGIN_URL);
}

/**
 * Redirects the user to the (external) logout URL.
 *
 * @param {Object}
 */
export function redirectToLogout ({ broadcast = true } = {})
{
  clearCookies();

  if (broadcast)
  {
    broadcastMessage(CONST.BROADCAST_MESSAGE_LOGOUT);
  }

  // TODO at least verify it begins with our backend endpoint root? is this going to be flagged as dangerous?
  window.location.href = store.getters['application/getAppProperty'](CONST.APP_PROPERTY_LOGOUT_URL);
}

/**
 * Formats the specified file size into a human readable display.
 *
 * @param {Number} fileSizeInBytes
 * @returns
 */
export function formatFileSize (fileSizeInBytes)
{
  let result = null;
  if (fileSizeInBytes !== undefined && fileSizeInBytes !== null)
  {
    result = prettyBytes(fileSizeInBytes, {
      binary: true,
      maximumFractionDigits: 1,
      locale: store.getters['user/getLanguage']
    });
  }

  return result;
}

/**
 * Saves a document and returns a promise that resolves with the newly generated document ID.
 *
 * @param {String} systemTableID
 * @param {String} documentName
 * @param {Object} file
 * @returns
 */
export function saveDocument (systemTableID, documentName, file)
{
  let formData = new FormData();
  formData.append('file', file);
  formData.append('documentInfo', JSON.stringify({
    documentName,
    systemTableID
  }));

  return axios.post(cove.getAPI({ name: 'api.dms.documents' }), formData)
    .then((response) => {
      return response.data.documentLinkID;
    });
}

/**
 * Starts the session expiration monitoring.
 */
export function startSessionExpiryInterval ()
{
  setInterval(checkForSessionExpiry, 15 * 1000);
}

/**
 * Performs a check to see if the user's backend/spring session is close to expiring. If so, i.e. they are in the warning period, we'll
 * display a blocking warning to them. This method will also pull down the blocker if it was visible and their session became active again,
 * which is possible since we share their session liveliness across tabs.
 */
export function checkForSessionExpiry ()
{
  let sessionTimeout = store.getters['application/getAppProperty'](CONST.APP_PROPERTY_SESSION_TIMEOUT);
  let sessionTimeoutWarning = store.getters['application/getAppProperty'](CONST.APP_PROPERTY_SESSION_TIMEOUT_WARNING);
  let safeIdleLengthMillis = sessionTimeout - sessionTimeoutWarning;

  let lastAjaxDateMillis = getLocalStorageValue(CONST.BROWSER_STORAGE_LAST_AJAX_DATE);
  let userIdleLengthMillis = new Date().getTime() - lastAjaxDateMillis;

  let warnUser = userIdleLengthMillis > safeIdleLengthMillis;
  store.commit('application/setUserInactivityPopupVisible', warnUser);
}

/**
 * Provided a subscription start date, calculates the renewal date for it (by shifting to the first of the next month)
 *
 * @param {String} startDate an ISO datetime string
 * @returns a luxon datetime object
 */
export function calculateSubscriptionRenewalDate (startDate)
{
  let parsed = cove.parseDate(startDate);
  let startOfMonth = parsed.startOf('month');
  return startOfMonth.plus({ month: 1 });
}

/**
 * Prompts for confirmation then forwards the user to our payment provider to capture their payment information.
 *
 * NOTE: This is fairly specific to our initial provider, nCourt, and will likely need refactoring in the future so it can handle any
 * provider.
 *
 * @param {Object} payload any POST parameters to pass along to our backend endpoint
 */
export function redirectToPaymentProvider (payload)
{
  cove.confirm({ message: i18n.t('paymentToken.confirmRedirect') })
    .then((confirm) => {
      if (confirm)
      {
        cove.block({ delay: 0 });
        let url = cove.getAPI({ name: 'api.paymentTokens.request' });
        axios.post(url, payload)
          .then((response) => {
            window.location.href = response.data.paymentTokenRedirectURL;
          })
          // catch here instead of finally, since we are going to redirect on success - let's leave the blocker on during that
          .catch(() => {
            cove.unblock();
          });
      }
    });
}