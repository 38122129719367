<template>
  <nav v-if="paging.totalElements > 0 && !(hideOnSinglePage && paging.totalPages === 1)"
       :class="`rounded ${color} ${solo ? 'elevation-2' : ''}`"
       :aria-label="$t('global.table.paginationTitle')">

    <v-divider v-if="!solo"/>

    <div class="d-flex align-center px-4 py-2">

      <div v-if="selectedCount > 0" class="text-minimum-gray font-95" style="flex: 0 0 auto">
        {{ $t('global.table.numberSelected', [selectedCount]) }}
      </div>

      <div class="text-right d-flex align-center" style="flex: 1 1 auto;min-height:36px">
        <v-spacer/>

        <div class="text-minimum-gray font-95">
          {{ $t('global.table.pageDisplay', [startRow, endRow, paging.totalElements]) }}
        </div>

        <div v-if="!readOnly && paging.totalPages > 1" class="ml-4 mr-n2 d-print-none">
          <v-tooltip top offset-overflow open-delay="400">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                :disabled="isFirstPage"
                @click="prevPage">
                <v-icon style="font-size:2rem">
                  mdi-chevron-left
                </v-icon>
                <span class="d-sr-only">
                  {{ $t('global.table.previousPage') }}
                </span>
              </v-btn>
            </template>
            {{ $t('global.table.previousPage') }}
          </v-tooltip>

          <v-tooltip top offset-overflow open-delay="400">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                :disabled="isLastPage"
                @click="nextPage">
                <v-icon style="font-size:2rem">
                  mdi-chevron-right
                </v-icon>
                <span class="d-sr-only">
                  {{ $t('global.table.nextPage') }}
                </span>
              </v-btn>
            </template>
            {{ $t('global.table.nextPage') }}
          </v-tooltip>
        </div>
      </div>
    </div>

    <div v-if="resultsOverflowed" class="text-center pb-3 warning--text font-weight-medium mt-n2">
      <div class="mx-auto" style="max-width:64ch">
        {{ $t('global.table.resultsOverflow', [maxResults]) }}
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    // expects a PP Paging object
    paging: {
      type: Object
    },
    solo: {
      type: Boolean
    },
    hideOnSinglePage: {
      type: Boolean
    },
    selectedCount: {
      type: Number,
      default: 0
    },
    readOnly: { // when enabled shows a simplified display with just the count information -- no paging controls
      type: Boolean,
      default: false
    }
  },
  computed: {
    startRow () {
      return (this.paging.page * this.paging.size) + 1;
    },
    endRow () {
      return Math.min(this.startRow + this.paging.size - 1, this.paging.totalElements);
    },
    isFirstPage () {
      return this.paging.page === 0;
    },
    isLastPage () {
      return (this.paging.page + 1) === this.paging.totalPages;
    },
    color () {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
    resultsOverflowed () {
      let totalElements = this.paging.totalElements;
      return totalElements >= this.maxResults;
    },
    maxResults () {
      return this.$store.getters['application/getAppProperty'](this.CONST.APP_PROPERTY_SEARCH_RESULTS_LIMIT);
    }
  },
  methods: {
    prevPage () {
      this.paging.prevPage();
    },
    nextPage () {
      this.paging.nextPage();
    }
  }
};
</script>