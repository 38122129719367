import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import cove from '@ctrack/cove';
import store from '../plugins/store';
import _ from 'lodash';

Vue.use(VueI18n);

const i18n = new VueI18n({
  // these will be replaced during initialization (once we load the locales and determine which one is the default)
  locale: 'en-US',
  fallbackLocale: 'en-US',
  silentTranslationWarn: true, // stop console messages for missing messages
  postTranslation (message, key) { // stop returning the message key as the message on missing messages (i18n default behavior)
    if (message === key)
    {
      return null;
    }
    else
    {
      return message;
    }
  }
});

/**
 * Returns the best locale based upon the requested locale and its compatibility with our supported locales.
 *
 * @param {String} requestedLocaleCode
 *
 * @returns {String} the best localeCode for this user
 */
export function getBestFitLocale (requestedLocaleCode)
{
  // search our supported locales for the best fit -- first by exact match...
  let localeCode = null;
  let supportedLocaleCodes = store.state.application.locales.map((l) => { return l.localeCode; });
  if (supportedLocaleCodes.includes(requestedLocaleCode))
  {
    localeCode = requestedLocaleCode;
  }
  // ...then by language only
  else
  {
    supportedLocaleCodes.forEach((lc) => {
      if (localeCode === null && lc.substring(0, 2) === requestedLocaleCode.substring(0, 2))
      {
        localeCode = lc;
      }
    });
  }

  // in the event we didn't find anything close use our default locale
  return _.defaultTo(localeCode, store.getters['application/getDefaultLocale'].localeCode);
}

/**
 * Loads the message resources for the specified locale into the i18n plugin. Returns a promise that's resolved after the messages are
 * loaded.
 *
 * @param {String} newLocale
 *
 * @returns {Object}
 */
export function loadLocale (newLocale) {
  return axios.get(cove.getAPI({ name: 'api.messageresources' }))
    .then((response) => {
      i18n.setLocaleMessage(newLocale, response.data.messages);
    });
}

export default i18n;