<template>
  <page-wrapper :title="$t('page.initialRegistration.title', [user.firstName])">
    <div class="mt-n4 font-weight-light" style="font-size:1.8rem;">
      {{ $t('page.initialRegistration.subtitle') }}
    </div>
    <div class="mt-4">
      <v-row>
        <v-col md="7" xl="6">
          <validation-error-panel/>

          <transition name="scroll-x-reverse-transition" mode="out-in">
            <step-user-type v-if="step === 'userType'" @selectUserType="selectUserType"/>
            <step-address v-else-if="step === 'address'" @nextStep="addressComplete" :required="getUserAccessConfig.requireAddress"/>
            <step-contact v-else-if="step === 'contact'" @nextStep="contactComplete" :required="getUserAccessConfig.requireContactPhone"/>
            <step-attorney v-else-if="step === 'attorney'" @submitRequest="submitRequest"/>
            <step-interpreter v-else-if="step === 'interpreter'" @submitRequest="submitRequest"/>
            <step-public v-else-if="step === 'public'" @submitRequest="submitRequest"/>
            <step-organization-admin v-else-if="step === 'organizationAdmin'" @submitRequest="submitRequest"/>
          </transition>
        </v-col>

        <transition name="scroll-x-reverse-transition" mode="out-in">
          <v-col v-if="step !== 'userType'" cols="12" md="4" xl="3">
            <v-alert colored-border type="info" border="left" elevation="2">
              <div class="text-uppercase" style="font-size:1.2rem;">
                {{ $t('global.tip') }}
              </div>
              <div class="mt-2">
                {{ $t('page.initialRegistration.tip.text') }}
              </div>
              <div class="mt-4 text-right">
                <c-btn small @click="confirmCancel">
                  {{ $t('page.initialRegistration.button.finishLater') }}
                </c-btn>
              </div>
            </v-alert>
          </v-col>
        </transition>
      </v-row>
    </div>
  </page-wrapper>
</template>

<script>
import PageWrapper from '@components/support/PageWrapper';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import StepUserType from '@components/pages/initialRegistration/StepUserType';
import StepAddress from '@components/pages/initialRegistration/StepAddress';
import StepContact from '@components/pages/initialRegistration/StepContact';
import StepAttorney from '@components/pages/initialRegistration/StepAttorney';
import StepInterpreter from '@components/pages/initialRegistration/StepInterpreter';
import StepPublic from '@components/pages/initialRegistration/StepPublic';
import UserAccess from '@/application/userAccess';
import StepOrganizationAdmin from '@components/pages/initialRegistration/StepOrganizationAdmin';
import store from '@/plugins/store';

export default {
  components: { StepOrganizationAdmin, PageWrapper, ValidationErrorPanel, StepUserType, StepAddress, StepAttorney,
    StepInterpreter, StepPublic, StepContact },
  data () {
    return {
      step: 'userType',
      selectedUserType: null
    };
  },
  created () {
    // many of the registration steps offer a file upload, so we'll pre-load the types here
    this.$store.dispatch('application/loadFileTypes', this.CONST.DOCUMENT_TYPE_USER_REQUEST);
  },
  computed: {
    user () {
      return this.$store.state.user;
    },
    getUserAccessConfig () {
      return UserAccess.getUserAccessConfig(this.selectedUserType.resourceID);
    }
  },
  methods: {
    selectUserType (userType) {
      this.selectedUserType = userType;
      this.step = 'address';
    },
    addressComplete () {
      this.step = 'contact';
    },
    contactComplete () {
      let nextStep = null;
      switch (this.selectedUserType.resourceID)
      {
        case this.CONST.USER_TYPE_ATTORNEY:
          nextStep = 'attorney';
          break;
        case this.CONST.USER_TYPE_INTERPRETER:
          nextStep = 'interpreter';
          break;
        case this.CONST.USER_TYPE_PUBLIC:
          nextStep = 'public';
          break;
        case this.CONST.USER_TYPE_ORGANIZATION_ADMIN:
          nextStep = 'organizationAdmin';
          break;
        default:
          throw new Error('Error - unknown user type id: ' + this.selectedUserType.resourceID);
      }

      this.step = nextStep;
    },
    submitRequest: UserAccess.submitRequest,
    cancelRequest () {
      this.$cove.notify({ message: this.$t('page.initialRegistration.notify.cancelled'), color: 'success' });
      this.$router.push({ name: 'user-profile' });
    },
    confirmCancel () {
      this.$cove.confirm({ message: this.$t('page.initialRegistration.confirm.cancel') })
        .then((confirmed) => {
          if (confirmed)
          {
            this.cancelRequest();
          }
        });
    }
  },
  // route guard so that we only allow users access to this page if it's their first application visit
  beforeRouteEnter (to, from, next) {
    if (store.getters['user/isFirstLogin'])
    {
      next();
    }
    else
    {
      next({ name: 'home' });
    }
  }
};
</script>