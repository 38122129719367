<template>
  <loading-card :loading="loading">
    <v-card-title>
      {{ $t('page.manageFiling.party.stepRole.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.manageFiling.party.stepRole.description') }}
    </v-card-subtitle>
    <v-card-text class="pt-2">
      <v-row no-gutters>
        <v-col cols="12">
          <c-autocomplete
            v-model="bean.involvementTypeID"
            :label="$t('page.manageFiling.party.stepRole.field.involvement')"
            :items="involvementTypes"
            key="involvementType"
            item-text="name"
            item-value="value"
            @change="changeInvolvement"
            required
            outlined
            hide-details="auto"
            aria-describedby="bc8f18e9-c508-4228-9f02-a3be5a2a7800">
            <template #item="{ item }">
              <v-list-item-content class="d-block">
                <v-list-item-title class="font-weight-medium">
                  {{ item.name }}
                </v-list-item-title>
                <div class="d-flex text-minimum-gray font-95" style="max-width:80ch">
                  {{ item.description }}
                </div>
              </v-list-item-content>
            </template>
          </c-autocomplete>
          <field-description id="bc8f18e9-c508-4228-9f02-a3be5a2a7800"
                             message-key="page.manageFiling.party.stepRole.field.involvement.description"/>
        </v-col>
        <v-col cols="12">
          <c-autocomplete
            v-model="bean.partySubTypeExternalIdentifier"
            :label="$t('page.manageFiling.party.stepRole.field.role')"
            :items="filteredPartySubTypes"
            key="role"
            item-text="participantSubTypeName"
            item-value="participantSubTypeID"
            no-data-text="Please select an Involvement Type first."
            outlined
            required
            hide-details="auto"
            aria-describedby="4d5a568f-5c0f-4f32-b307-33930b999baf"/>
          <field-description id="4d5a568f-5c0f-4f32-b307-33930b999baf"
                             message-key="page.manageFiling.party.stepRole.field.role.description"/>
        </v-col>
        <transition name="scroll-y-transition">
          <v-col v-if="showAddMyself" cols="12">
            <c-checkbox
              v-model="bean.addMyself"
              :label="$t('page.manageFiling.party.stepRole.field.addMyself')"
              class="my-0"
              hide-details="auto"
              aria-describedby="614a75b9-598c-4e39-8b93-6c2d4046fb5c"/>
            <field-description id="614a75b9-598c-4e39-8b93-6c2d4046fb5c"
                               message-key="page.manageFiling.party.stepRole.field.addMyself.description"/>
          </v-col>
        </transition>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-end mt-5">
      <c-btn type="primary" @click="save">
        {{ editing ? $t('global.save') : $t('global.next') }}
      </c-btn>
      <c-btn @click="$emit('cancel')">
        {{ $t('global.cancel') }}
      </c-btn>
    </v-card-actions>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import WorkflowStepMixin from '@/mixins/workflowStep';

export default {
  mixins: [ WorkflowStepMixin ],
  props: {
    editing: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      partySubTypes: [],
      linkedPartiesExist: false
    };
  },
  computed: {
    filingID () {
      return this.$store.state.filing.filingID;
    },
    user () {
      return this.$store.state.user;
    },
    isNewCaseFiling () {
      return this.$store.getters['filing/isNewCaseFiling'];
    },
    showAddMyself () {
      let result = !this.editing && !this.linkedPartiesExist;
      if (this.isNewCaseFiling)
      {
        result = result && this.bean.involvementTypeID === this.CONST.INVOLVMENT_TYPE_FILING_PARTY;
      }

      return result;
    },
    caseClassGroupID () {
      return this.$store.getters['filing/getCaseClassGroupID'];
    },
    filteredPartySubTypes () {
      if (this.bean.involvementTypeID === null)
      {
        return [];
      }
      else
      {
        return _.filter(this.partySubTypes, ['involvementType.casePartyInvolvementTypeID', this.bean.involvementTypeID ]);
      }
    },
    involvementTypes () {
      let result = [];
      if (this.shouldShowInvolvementType(this.CONST.INVOLVMENT_TYPE_FILING_PARTY))
      {
        result.push({
          name: 'Filing Party',
          // eslint-disable-next-line max-len
          description: 'Filing Party or Moving Party refers to the individual(s) or entities(s) who are submitting the legal document(s) which comprise this filing to the court.',
          value: this.CONST.INVOLVMENT_TYPE_FILING_PARTY
        });
      }

      if (this.shouldShowInvolvementType(this.CONST.INVOLVMENT_TYPE_RESPONDING_PARTY))
      {
        result.push({
          name: 'Responding Party',
          // eslint-disable-next-line max-len
          description: 'Responding or Non-Moving Party refers to the individual(s) or entities(s) who should be served with the legal document(s) which comprise this filing and are required to answer or respond.',
          value: this.CONST.INVOLVMENT_TYPE_RESPONDING_PARTY
        });
      }

      if (this.shouldShowInvolvementType(this.CONST.INVOLVMENT_TYPE_INVOLVED_PARTY))
      {
        result.push({
          name: 'Involved Party',
          // eslint-disable-next-line max-len
          description: 'Involved Parties refers to the individual(s) or entities(s) who are directly involved or interested in any act, affair, contract, transaction, or legal proceeding outcome related to the legal document(s) which comprise this filing to the court.',
          value: this.CONST.INVOLVMENT_TYPE_INVOLVED_PARTY
        });
      }

      return result;
    }
  },
  methods: {
    loadData () {
      let promises = [];
      promises.push(this.loadPartySubTypes());
      promises.push(this.searchForLinkedParties());

      return Promise.all(promises);
    },
    validate () {
      this.$store.commit('cove/errors/clear');

      this.$cove.validation.required(this.bean.involvementTypeID, 'page.manageFiling.party.stepRole.field.involvement', 'involvementType');
      this.$cove.validation.required(this.bean.partySubTypeExternalIdentifier, 'page.manageFiling.party.stepRole.field.role', 'role');

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      let isValid = this.validate();
      if (!isValid)
      {
        this.scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
      }
      else
      {
        let linkUserID = (this.bean.addMyself ? this.user.userID : null);
        let partySubTypeName = _.find(this.partySubTypes, { participantSubTypeID: this.bean.partySubTypeExternalIdentifier })
          .participantSubTypeName;

        this.$emit('save', { ...this.bean, partySubTypeName, linkUserID });
      }
    },
    loadPartySubTypes () {
      return axios.get(this.$cove.getAPI({ name: 'api.party.subtypes', query: { caseClassGroupID: this.caseClassGroupID } }))
        .then((response) => {
          this.partySubTypes = _.sortBy(_.defaultTo(response.data, []),
            ['participantType.participantTypeName', 'participantSubTypeName']);
        });
    },
    searchForLinkedParties () {
      let promise = Promise.resolve();

      // we can skip this entire AJAX check if certain conditions aren't met :-)
      if (!this.editing)
      {
        let url = this.$cove.getAPI({
          name: 'api.filing.caseParties',
          query: { filingID: this.filingID, linkedPartiesOnly: true }
        });

        promise = axios.get(url).
          then((response) => {
            this.linkedPartiesExist = response.data.page.totalElements > 0;
          });
      }

      return promise;
    },
    shouldShowInvolvementType (involvementTypeID) {
      return !_.isNil(_.find(this.partySubTypes, ['involvementType.casePartyInvolvementTypeID', involvementTypeID ]));
    },
    changeInvolvement () {
      this.bean.partySubTypeExternalIdentifier = null;
      if (!this.showAddMyself)
      {
        this.bean.addMyself = false;
      }
    }
  }
};
</script>