<template>
  <loading-card :loading="receipt === null">
    <template #default>
      <v-card-title>
        <slot name="title">
          {{ $t('page.receiptDetails.title') }}
        </slot>
      </v-card-title>
      <v-card-subtitle>
        <slot name="subtitle">
          {{ $t('page.receiptDetails.subtitle') }}
        </slot>
      </v-card-subtitle>
      <v-card-text>
        <dl class="d-grid grid-5050">
          <dt>{{ $t('receipt.referenceNumber') }}</dt>
          <dd>{{ receipt.referenceNumber }}</dd>
          <dt>{{ $t('receipt.receiptDate') }}</dt>
          <dd>{{ receipt.receiptDate | formatDateTime }}</dd>
          <dt>{{ $t('receipt.processorTransactionNumber') }}</dt>
          <dd>{{ receipt.processorTransactionNumber }}</dd>
          <dt>{{ $t('receipt.userID') }}</dt>
          <dd v-if="!isNil(receipt.user)">{{ receipt.user.actor.displayName }}</dd>
          <dt>{{ $t('receipt.receiptStatus.short') }}</dt>
          <dd>{{ receipt.receiptStatus.displayName }}</dd>
          <dt>{{ $t('receipt.amount') }}</dt>
          <dd>{{ receipt.amount | formatCurrency }}</dd>
        </dl>
      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { LoadingCard },
  props: {
    receipt: {
      type: Object
    }
  }
};
</script>