<template>
  <page-wrapper :title="$t('page.admin.config.title')">
    <v-row>
      <v-col cols="12" md="7" xl="6">
        <validation-error-panel/>

        <v-card>
          <v-card-title>
            {{ $t('page.admin.config.import.title') }}
          </v-card-title>
          <v-card-text class="pb-0">
            <div class="d-flex">
              <div style="flex: 0 0 auto">
                <div class="pa-3 mr-6" style="border:1px solid var(--v-icon-base);border-radius:100px">
                  <v-icon color="icon" style="font-size:50px">
                    mdi-file-upload
                  </v-icon>
                </div>
              </div>
              <div style="flex: 1 1 auto">
                <div>
                  <p>
                    {{ $t('page.admin.config.import.description') }}
                  </p>
                  <p>
                    {{ $t('page.admin.config.bePatient') }}
                  </p>
                </div>
                <div class="mt-6">
                  <c-file-input
                    :label="$t('page.admin.config.field.file')"
                    v-model="file"
                    key="file"
                    outlined
                    required
                    hide-details="auto"
                    aria-describedby="a25ddef8-6798-40b9-ac47-4f78c7ed6507"/>
                  <field-description id="a25ddef8-6798-40b9-ac47-4f78c7ed6507"
                                     message-key="page.admin.config.field.file.description"/>
                </div>
                <v-fade-transition>
                  <div v-if="hasWarnings">
                    <v-alert
                      :value="true"
                      class="mb-1"
                      rounded
                      icon="mdi-alert-box"
                      color="warning"
                      text>
                      <div class="font-weight-medium" style="font-size:1.1rem;line-height:1.5">
                        {{ $t('global.warning') }}
                      </div>
                      <div class="mt-2">
                        {{ $t('page.admin.config.warning.disclaimer') }}
                      </div>
                      <div class="mt-2">
                        <ul>
                          <li v-if="staleImport">
                            {{ $t('page.admin.config.warning.staleImport',
                                  [this.lastImportUser, $cove.formatDateTime(this.lastImportDate)]) }}
                          </li>
                          <li v-if="missingExport">
                            {{ $t('page.admin.config.warning.missingExport') }}
                          </li>
                        </ul>
                      </div>
                    </v-alert>
                    <div class="mb-8">
                      <c-checkbox v-model="acknowledged"
                                  hide-details
                                  key="acknowledged"
                                  :label="$t('page.admin.config.field.acknowledge')"/>
                    </div>
                  </div>
                </v-fade-transition>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <c-btn type="primary" @click="importConfig">
              {{ $t('page.admin.config.button.import') }}
            </c-btn>
          </v-card-actions>
        </v-card>

        <v-card class="mt-6">
          <v-card-title>
            {{ $t('page.admin.config.export.title') }}
          </v-card-title>
          <v-card-text>
            <div class="d-flex">
              <div style="flex: 0 0 auto">
                <div class="pa-3 mr-6" style="border:1px solid var(--v-icon-base);border-radius:100px">
                  <v-icon color="icon" style="font-size:50px">
                    mdi-file-download
                  </v-icon>
                </div>
              </div>
              <div style="flex: 1 1 auto">
                <p>
                  {{ $t('page.admin.config.export.description') }}
                </p>
                <p>
                  {{ $t('page.admin.config.bePatient') }}
                </p>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <c-btn type="primary" @click="exportConfig">
              {{ $t('page.admin.config.button.export') }}
            </c-btn>
          </v-card-actions>
        </v-card>

        <config-import-validation-errors-popup
          v-model="importValidationPopupVisible"
          :errors="importValidationErrors"/>
      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import PageWrapper from '@components/support/PageWrapper';
import FieldDescription from '@components/support/FieldDescription';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import ConfigImportValidationErrorsPopup from '@components/support/ConfigImportValidationErrorsPopup';
import PageMixin from '@/mixins/page';

export default {
  components: { PageWrapper, FieldDescription, ValidationErrorPanel, ConfigImportValidationErrorsPopup },
  mixins: [ PageMixin ],
  data () {
    return {
      file: null,
      acknowledged: false,
      staleImport: false,
      missingExport: false,
      lastImportUser: null,
      lastImportDate: null,
      importValidationPopupVisible: false,
      importValidationErrors: {}
    };
  },
  computed: {
    hasWarnings () {
      return this.staleImport || this.missingExport;
    }
  },
  watch: {
    file () {
      this.reset(false);
    }
  },
  methods: {
    reset (clearFile) {
      this.staleImport = false;
      this.missingExport = false;
      this.lastImportUser = null;
      this.lastImportDate = null;
      this.acknowledged = false;
      this.importValidationErrors = {};

      if (clearFile)
      {
        this.file = null;
      }
    },
    importConfig () {
      this.$cove.block({ delay: 0 });

      let isValid = this.validate();
      if (!isValid)
      {
        this.$cove.unblock();
        this.scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
        return;
      }

      let url = this.$cove.getAPI({ name: 'api.config.import' });
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('dto', JSON.stringify({
        ignoreWarnings: this.acknowledged
      }));

      axios.post(url, formData)
        .then((response) => {
          let data = response.data;
          this.staleImport = data.staleImport;
          this.missingExport = data.missingExport;
          this.importValidationErrors = _.get(data, 'validationErrors', {});
          this.lastImportDate = _.get(data, 'lastImportedDate', null);
          this.lastImportUser = _.get(data, 'lastImportedUser.actor.displayName', null);

          if (!_.isEmpty(this.importValidationErrors))
          {
            this.importValidationPopupVisible = true;
          }
          else if (this.acknowledged || !this.hasWarnings)
          {
            this.reset(true);
            this.$cove.notify({ message: this.$t('page.admin.config.import.success'), color: 'success' });
          }
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    validate () {
      this.$store.commit('cove/errors/clear');

      this.$cove.validation.required(this.file, 'page.admin.config.field.file', 'file');
      if (this.file !== null)
      {
        this.$cove.validation.file.rejectIfEmpty(this.file.size, 'page.admin.config.field.file', 'file');
        this.$cove.validation.file.rejectIfInvalidType(this.file.type, [this.CONST.MIME_TYPE_EXCEL], 'page.admin.config.field.file',
          'file');
      }

      if (this.hasWarnings && !this.acknowledged)
      {
        this.$cove.validation.addError('acknowledged', 'page.admin.config.error.mustAcknowledge');
      }

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    exportConfig () {
      let url = this.$cove.getAPI({ name: 'api.config.export' });
      let element = document.createElement('a');
      element.setAttribute('href', url);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }
};
</script>