<template>
  <page-wrapper :title="$t('page.caseView.title')" :subtitle="subtitle">

    <!-- verified party tag -->
    <transition name="scroll-y-transition" mode="out-in">
      <v-row v-if="isVerifiedParty">
        <v-col cols="12" class="py-0 my-0">
          <v-chip dark label small color="info darken-1" class="portal-tag elevation-2 d-block d-md-inline-flex" style="padding:6px 10px">
            <v-icon style="font-size:20px">
              mdi-shield-check
            </v-icon>
            <span class="ml-2">
              {{ $t('page.caseView.verifiedParty') }}
            </span>
          </v-chip>
        </v-col>
      </v-row>
    </transition>

    <!-- primary card display -->
    <v-row>
      <v-col cols="12" md="8" xl="9">
        <v-row>
          <v-col cols="12">
            <case-view-banner-card :courtID="courtID" :caseInstance="caseInstance" :isVerifiedParty="isVerifiedParty"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="6">
            <case-view-left-card :courtID="courtID" :caseInstance="caseInstance" :isVerifiedParty="isVerifiedParty"/>
          </v-col>
          <v-col cols="12" xl="6">
            <case-view-right-card :courtID="courtID" :caseInstance="caseInstance" :isVerifiedParty="isVerifiedParty"/>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="4" xl="3" class="d-print-none">
        <v-row>
          <v-col cols="12">
            <case-view-action-card :courtID="courtID" :caseInstance="caseInstance" :isVerifiedParty="isVerifiedParty"
                                   @togglePrintableView="togglePrintableView"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <case-view-sandbox :courtID="courtID" :caseInstance="caseInstance" :isVerifiedParty="isVerifiedParty"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- tab display -->
    <v-fade-transition leave-absolute>
      <v-row v-if="!printableView" key="standardView">
        <v-col cols="12">
          <div class="d-none d-print-block mt-n5"></div>
          <case-view-tabs :courtID="courtID" :caseInstance="caseInstance" :isVerifiedParty="isVerifiedParty"/>
        </v-col>
      </v-row>
      <v-row v-else key="printableView">
        <v-col cols="12">
          <case-view-printable-tabs :courtID="courtID" :caseInstance="caseInstance" :isVerifiedParty="isVerifiedParty"/>
        </v-col>
      </v-row>
    </v-fade-transition>

  </page-wrapper>
</template>

<script>
import PageWrapper from '@components/support/PageWrapper';
import CaseViewBannerCard from '@components/support/caseView/CaseViewBannerCard';
import CaseViewLeftCard from '@components/support/caseView/CaseViewLeftCard';
import CaseViewRightCard from '@components/support/caseView/CaseViewRightCard';
import CaseViewActionCard from '@components/support/caseView/CaseViewActionCard';
import CaseViewSandbox from '@components/support/caseView/CaseViewSandbox';
import CaseViewTabs from '@components/support/caseView/CaseViewTabs';
import CaseViewPrintableTabs from '@components/support/caseView/CaseViewPrintableTabs';
import axios from 'axios';
import PageMixin from '@/mixins/page';
import { redirectNotFoundErrorHandler } from '@/application/ajax';

export default {
  components: { PageWrapper, CaseViewBannerCard, CaseViewLeftCard, CaseViewRightCard, CaseViewActionCard, CaseViewSandbox, CaseViewTabs,
    CaseViewPrintableTabs },
  mixins: [ PageMixin ],
  props: {
    courtID: {
      type: String
    },
    caseUUID: {
      type: String
    }
  },
  data () {
    return {
      drawer: false,
      caseInstance: null,
      isVerifiedParty: false,
      printableView: false
    };
  },
  created () {
    this.load(this.courtID, this.caseUUID);
  },
  computed: {
    hasSession () {
      return this.$store.state.user.hasSession;
    },
    subtitle () {
      return this.caseInstance !== null ? this.caseInstance.caseTitle : null;
    }
  },
  methods: {
    load (courtID, caseUUID) {
      this.caseInstance = null;
      this.isVerifiedParty = false;

      let casePromise = axios.get(this.$cove.getAPI({ name: 'api.court.case', params: { courtID, caseUUID } }),
        { errorHandlers: { 404: redirectNotFoundErrorHandler } });

      // save an ajax call here for anonymous users, i.e. only perform the lookup if they are logged in
      let verifiedPartyPromise = Promise.resolve({ data: false }); // mimic an ajax response
      if (this.hasSession)
      {
        verifiedPartyPromise = axios.get(this.$cove.getAPI({ name: 'api.case.verifiedCaseParty', params: { courtID, caseUUID } }),
          { errorHandlers: { 404: redirectNotFoundErrorHandler } });
      }

      Promise
        .all([casePromise, verifiedPartyPromise])
        .then(([caseResponse, verifiedPartyResponse]) => {
          this.caseInstance = caseResponse.data.caseHeader;
          this.isVerifiedParty = verifiedPartyResponse.data;
        });
    },
    togglePrintableView () {
      this.printableView = true;
    }
  },
  beforeRouteUpdate (to, from, next) {
    next();
    this.load(to.params.courtID, to.params.caseUUID);
  }
};
</script>