<template>
  <page-wrapper :title="$t('page.myPurchasedDocuments.title')">
    <search-result-template :config="config">

      <template #table-title>
        {{ $t('page.myPurchasedDocuments.table') }}
      </template>

      <template #v-data-table.item.caseNumber="{ item }">
        <div>
          <div style="white-space: nowrap">
            <router-link :to="{ name: 'caseView', params: { courtID: item.court.resourceID,
                                                            caseUUID: item.caseIdentifier} }">
              {{ item.caseNumber }}
            </router-link>
          </div>
          <div class="text-minimum-gray">
            {{ item.caseTitle }}
          </div>
        </div>
      </template>

      <template #v-data-table.item.receipt.receiptDate="{ value }">
        <div style="min-width:100px">
          {{ value | formatDateTime }}
        </div>
      </template>

      <template #v-data-table.item.pageCount="{ value }">
        <div v-if="value > 0">
          {{ value }}
        </div>
      </template>

      <template #v-data-table.item.documentLinkIdentifier="{ item }">
        <v-btn icon color="primary" @click="openDocumentPopup(item)">
          <span class="d-sr-only">
            {{ $t('application.button.viewDocument') }}
          </span>
          <v-icon>mdi-file</v-icon>
        </v-btn>
      </template>

      <!-- Search Criteria -->
      <template #nav-drawer-filter-body>
        <v-row no-gutters class="mt-4">
          <v-col v-if="!isSingleCourtMode" cols="12">
            <c-autocomplete
              :items="courts"
              v-model="searchDTO.courtID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('courtCase.court')"
              outlined
              key="courtID"
              hide-details="auto"
              aria-describedby="bfc98f59-60f7-427e-8db3-0ad807bb3f92"/>
            <field-description id="bfc98f59-60f7-427e-8db3-0ad807bb3f92"
                               message-key="page.myPurchasedDocuments.court.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.caseNumber"
              :label="$t('courtCase.caseNumber')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="f0e90a6b-cb6a-4769-9284-977c718d1030"/>
            <field-description id="f0e90a6b-cb6a-4769-9284-977c718d1030"
                               message-key="page.myPurchasedDocuments.caseNumber.description"/>
          </v-col>
          <v-col cols="12">
            <c-autocomplete
              v-model="searchDTO.docketEntrySubTypeID"
              :items="docketEntrySubTypes"
              :label="$t('purchasedDocuments.docketEntryExternalName')"
              item-text="text"
              item-value="value"
              outlined
              :no-data-text="$t('global.courtFirst')"
              hide-details="auto"
              aria-describedby="e7457b58-3235-481f-a9a4-1d86b02a63f4"/>
            <field-description id="e7457b58-3235-481f-a9a4-1d86b02a63f4"
                               message-key="page.myPurchasedDocuments.docketEntrySubTypeID.description"/>
          </v-col>
          <v-col cols="12">
            <date-range-picker
              :label="$t('purchasedDocument.receiptDate')"
              :labelStart="$t('page.myPurchasedDocuments.receiptDate.from')"
              :labelEnd="$t('page.myPurchasedDocuments.receiptDate.to')"
              keyPrefix="receiptDate"
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.receiptDateChoice"
              :startDate.sync="searchDTO.receiptDateFrom"
              :endDate.sync="searchDTO.receiptDateTo"
              aria-describedby="d4768a95-3e47-4584-88ea-07de21c3e05d"/>
            <field-description id="d4768a95-3e47-4584-88ea-07de21c3e05d"
                               message-key="page.myPurchasedDocuments.receiptDate.description"/>
          </v-col>
        </v-row>
      </template>

    </search-result-template>

    <document-popup v-model="documentPopupVisible"
                    :courtID="courtID"
                    :caseInstanceUUID="caseInstanceUUID"
                    :documentLinkUUID="documentLinkUUID"/>

  </page-wrapper>
</template>

<script>

import DocumentPopup from '@components/support/DocumentPopup';
import SearchResultTemplate from '@components/support/template/SearchResultTemplate';
import SearchResultTemplateMixin from '@/mixins/searchResultTemplate';
import Search from '@/application/search';
import _ from 'lodash';
import axios from 'axios';
import { dateToEndOfDay, dateToStartOfDay } from '@/application/application';

export default {
  components: { DocumentPopup, SearchResultTemplate },
  mixins: [ SearchResultTemplateMixin ],
  data () {
    return {
      searchDTO: {
        sort: {
          sortBy: 'receipt.receiptDate',
          sortDesc: true
        },
        userID: null,
        courtID: null,
        caseNumber: null,
        docketEntrySubTypeID: null,
        receiptDateChoice: null,
        receiptDateFrom: null,
        receiptDateTo: null
      },
      docketEntrySubTypes: [],
      // document popup
      documentPopupVisible: false,
      courtID: null,
      caseInstanceUUID: null,
      documentLinkUUID: null
    };
  },
  watch: {
    'searchDTO.courtID': {
      immediate: true,
      handler (newValue) {
        if (!_.isNil(newValue))
        {
          axios.get(this.$cove.getAPI({ name: 'api.court.docketEntry.subtypes', params: { courtID: newValue } }))
            .then((response) => {
              this.docketEntrySubTypes = Search.populateDocketEntrySubTypes(_.defaultTo(response.data, []));
            });
        }
        else
        {
          this.docketEntrySubTypes = [];
        }
      }
    }
  },
  computed: {
    isSingleCourtMode () {
      return this.$store.getters['application/isSingleCourtMode'];
    }
  },
  methods: {
    getRouteName () {
      return 'work-purchased-documents';
    },
    getSearchAPIName () {
      return 'api.purchasedDocuments.search';
    },
    getItemKey () {
      return 'resourceID';
    },
    getHeaders () {
      let headers = [];
      if (!this.isSingleCourtMode) {
        headers.push({ text: this.$t('courtCase.court'), value: 'court.displayName', sortable: true });
      }
      headers.push({ text: this.$t('courtCase.case'), value: 'caseNumber', sortable: true });
      headers.push({ text: this.$t('purchasedDocuments.docketEntryExternalName'), value: 'docketEntryExternalName', sortable: true });
      headers.push({ text: this.$t('purchasedDocument.receiptDate'), value: 'receipt.receiptDate', sortable: true });
      headers.push({ text: this.$t('purchasedDocument.documentName'), value: 'documentName', sortable: true });
      headers.push({ text: this.$t('purchasedDocument.pageCount'), value: 'pageCount', sortable: true });
      headers.push({ text: this.$t('global.view'), value: 'documentLinkIdentifier', sortable: false, align: 'right' });

      return headers;
    },
    addSearchCriteria (query) {

      if (!_.isNil(this.searchDTO.sort.sortBy)) {
        query.sort = `${this.searchDTO.sort.sortBy},${this.searchDTO.sort.sortDesc ? 'desc' : 'asc'}`;
      }

      // set the userID to this user
      query.userID = this.$store.state.user.userID;

      if (!_.isNil(this.searchDTO.courtID)) {
        query.courtID = this.searchDTO.courtID;
      }

      if (!_.isNil(this.searchDTO.caseNumber)) {
        query.caseNumber = this.searchDTO.caseNumber;
      }

      if (!_.isNil(this.searchDTO.docketEntrySubTypeID)) {
        let parsedDocketEntryValue = Search.parseDocketEntrySubTypeID(this.searchDTO.docketEntrySubTypeID);
        if (!_.isNil(parsedDocketEntryValue.docketEntryTypeID)) {
          query.docketEntryTypeExternalIdentifier = parsedDocketEntryValue.docketEntryTypeID;
        }
        if (!_.isNil(parsedDocketEntryValue.docketEntrySubTypeID)) {
          query.docketEntrySubTypeExternalIdentifier = parsedDocketEntryValue.docketEntrySubTypeID;
        }
      }
      if (!_.isNil(this.searchDTO.receiptDateFrom)) {
        query.receiptDateFrom = dateToStartOfDay(this.$cove.parseDate(this.searchDTO.receiptDateFrom)).toISO();
      }

      if (!_.isNil(this.searchDTO.receiptDateTo)) {
        query.receiptDateTo = dateToEndOfDay(this.$cove.parseDate(this.searchDTO.receiptDateTo)).toISO();
      }

      return query;
    },
    validateSearchCriteria (searchDTO) {
      this.$cove.validation.date.rejectIfInvalid(searchDTO.receiptDateFrom, 'page.myPurchasedDocuments.receiptDate.from',
        'receiptDate-startDate');
      this.$cove.validation.date.rejectIfInvalid(searchDTO.receiptDateTo, 'page.myPurchasedDocuments.receiptDate.to',
        'receiptDate-endDate');
      this.$cove.validation.date.rejectIfBefore(searchDTO.receiptDateTo, searchDTO.receiptDateFrom,
        'page.myPurchasedDocuments.receiptDate.to', 'page.myPurchasedDocuments.receiptDate.from',
        'receiptDate-endDate');
    },
    openDocumentPopup (purchasedDocument) {
      this.courtID = purchasedDocument.court.resourceID;
      this.caseInstanceUUID = purchasedDocument.caseIdentifier;
      this.documentLinkUUID = purchasedDocument.documentLinkIdentifier;
      this.documentPopupVisible = true;
    }
  }
};
</script>

