<template>
  <loading-card :loading="loading" class="nav-tab-medium">
    <template #default>
      <v-card-title>
        {{ $t('page.currentUser.paymentMethod.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.currentUser.paymentMethod.description') }}
      </v-card-subtitle>
      <v-card-text>

        <!-- payment method via parent org -->
        <mini-card v-if="hasOrganizationPaymentToken" class="mt-4 mb-8">
          <v-card-text class="pa-6">
            <div class="d-flex" style="gap:24px">
              <div class="flex-grow-0">
                <img :src="require(`@/assets/paymentClient/${paymentClientName}/logo.png`)" alt=""/>
              </div>
              <v-divider vertical/>
              <div class="flex-grow-1 text-minimum-gray align-self-center" style="max-width:300px;">
                {{ $t('page.currentUser.paymentMethod.hasOrgPayment') }}
              </div>
            </div>
          </v-card-text>
        </mini-card>

        <!-- user's payment method -->
        <mini-card v-else-if="hasPaymentToken" class="mt-4 mb-8">
          <v-card-text class="pa-6">
            <div class="d-flex" style="gap:24px">
              <div class="flex-grow-0">
                <img :src="require(`@/assets/paymentClient/${paymentClientName}/logo.png`)" alt=""/>
              </div>
              <v-divider vertical/>
              <div class="flex-grow-1">
                <dl class="d-grid px-6" style="max-width:300px">
                  <dt>{{ $t('paymentMethod.name') }}</dt>
                  <dd>{{ paymentToken.paymentDescription }}</dd>

                  <!-- credit card display -->
                  <template v-if="isCreditCard">
                    <dt>{{ $t('paymentMethod.type') }}</dt>
                    <dd>{{ paymentToken.cardTypeName }}</dd>
                    <dt>{{ $t('paymentMethod.number') }}</dt>
                    <dd>
                      <span style="font-family:monospace">
                        &bull;&bull;&bull;&bull;
                      </span>
                      {{ paymentToken.cardNumberLast4 }}
                    </dd>
                    <dt>{{ $t('paymentMethod.expiration') }}</dt>
                    <dd>{{ paymentToken.cardExpirationMonth }}/{{ paymentToken.cardExpirationYear }}</dd>
                  </template>

                  <!-- bank account display -->
                  <template v-else>
                    <dt>{{ $t('paymentToken.accountType') }}</dt>
                    <dd>{{ paymentToken.accountTypeName }}</dd>
                    <dt>{{ $t('paymentToken.routingNumber') }}</dt>
                    <dd>{{ paymentToken.routingNumber }}</dd>
                    <dt>{{ $t('paymentToken.accountNumber') }}</dt>
                    <dd>
                      <span style="font-family:monospace">
                        &bull;&bull;&bull;&bull;
                      </span>
                      {{ paymentToken.accountNumberLast4 }}
                    </dd>
                  </template>
                </dl>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end mt-4">
            <c-btn type="danger" @click="removePaymentToken">
              {{ $t('global.remove') }}
            </c-btn>
          </v-card-actions>
        </mini-card>

        <!-- no payment method on file -->
        <cutout-area v-else class="mt-4 mb-8">
          <img :src="require(`@/assets/paymentClient/${paymentClientName}/logo.png`)" alt=""/>
          <template #action>
            <c-btn type="primary" @click="addPaymentToken">
              {{ $t('page.manageOrg.paymentMethod.add') }}
              <v-icon right>
                mdi-open-in-new
              </v-icon>
            </c-btn>
          </template>
        </cutout-area>

      </v-card-text>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import CutoutArea from '@components/support/CutoutArea';
import LoadingCard from '@components/support/LoadingCard';
import MiniCard from '@components/support/MiniCard';
import { redirectToPaymentProvider } from '@/application/application';

export default {
  components: { LoadingCard, CutoutArea, MiniCard },
  data () {
    return {
      loading: true,
      paymentToken: null,
      hasOrganizationPaymentToken: false
    };
  },
  computed: {
    user () {
      return this.$store.state.user;
    },
    hasPaymentToken () {
      return !_.isNil(this.paymentToken);
    },
    isCreditCard () {
      return !_.isEmpty(this.paymentToken.cardTypeName);
    },
    paymentClientName () {
      return this.$store.getters['application/getAppProperty'](this.CONST.APP_PROPERTY_PAYMENT_CLIENT);
    }
  },
  created () {
    this.loadData();
  },
  methods: {
    loadData () {
      this.loading = true;
      axios.get(this.$cove.getAPI({ name: 'api.user.currentuser', query: { fields: '*,paymentToken(*),hasOrganizationPaymentToken' } }))
        .then((response) => {
          this.paymentToken = _.get(response, 'data.paymentToken', null);
          this.hasOrganizationPaymentToken = _.get(response, 'data.hasOrganizationPaymentToken', false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addPaymentToken () {
      redirectToPaymentProvider({ userID: this.user.userID });
    },
    removePaymentToken () {
      this.$cove.confirm({ message: this.$t('page.currentUser.paymentMethod.confirmRemove') })
        .then((confirm) => {
          if (confirm)
          {
            this.$cove.block({ delay: 0 });
            axios.delete(this.$cove.getAPI({ name: 'api.paymentToken', params: { id: this.paymentToken.resourceID } }))
              .then(() => {
                this.$cove.notify({ message: this.$t('application.remove.success'), color: 'success' });
                this.loadData();
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    }
  }
};
</script>