<template>
  <loading-card :loading="false" class="nav-tab-small">
    <v-card-title>
      {{ $t('page.currentUser.section.login.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.currentUser.section.login.description') }}
    </v-card-subtitle>
    <v-card-text class="my-3">
      <dl class="d-grid">
        <dt>{{ $t('page.currentUser.section.login.username') }}</dt>
        <dd>{{ user.username }}</dd>
        <dt>{{ $t('page.currentUser.section.login.firstName') }}</dt>
        <dd>{{ user.firstName }}</dd>
        <dt>{{ $t('page.currentUser.section.login.lastName') }}</dt>
        <dd>{{ user.lastName }}</dd>
        <dt>{{ $t('page.currentUser.section.login.email') }}</dt>
        <dd>{{ user.emailAddress }}</dd>
        <dt>{{ $t('page.currentUser.section.login.password') }}</dt>
        <dd style="font-family:monospace">
          &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
        </dd>
      </dl>
    </v-card-text>
  </loading-card>
</template>

<script>
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { LoadingCard },
  computed: {
    user () {
      return this.$store.state.user;
    }
  }
};
</script>