<template>
  <c-dialog
    :value="value"
    width="850"
    close-key="cove.general.close"
    title-key="page.notificationPopup.title"
    @input="close">

    <div class="pa-5">

      <section-separator :title="$t('page.notificationPopup.metaData')" class="mb-5"/>

      <div class="mt-2">
        <dl class="d-grid grid-3070">
          <dt>{{ $t('notification.createdDate') }}</dt>
          <dd>{{ notification.createdDate | formatDateTime }}</dd>
          <dt>{{ $t('notification.status.short') }}</dt>
          <dd>{{ notification.notificationStatusDisplayName }}</dd>
          <dt v-if="showCaseNumber">{{ $t('courtCase.caseNumber') }}</dt>
          <dd v-if="showCaseNumber">{{ notification.caseNumber }}</dd>
          <dt>{{ $t('notification.subject') }}</dt>
          <dd>{{ notification.subject }}</dd>
        </dl>
      </div>

      <section-separator :title="$t('notification.richTextBody')" class="mb-5"/>
      <div class="mt-2">
        <div v-html="generateMarkdown()"></div>
      </div>
    </div>

  </c-dialog>
</template>

<script>
import _ from 'lodash';
import SectionSeparator from '@components/support/SectionSeparator';
import { marked } from 'marked';

export default {
  components: { SectionSeparator },
  props: {
    value: {
      type: Boolean
    },
    notification: {
      type: Object,
      required: true
    }
  },
  computed: {
    showRecipientValue () {
      return this.notification.notificationMethodID !== this.CONST.NOTIFICATION_METHOD_APPLICATION;
    },
    notificationMethodLabel () {
      return this.notification.notificationMethodID === this.CONST.NOTIFICATION_METHOD_EMAIL ?
        this.$t('notification.recipient.email') :
        this.$t('notification.recipient.sms');
    },
    showCaseNumber () {
      return !_.isNil(this.notification.caseNumber);
    }
  },
  methods: {
    close () {
      this.$emit('input', false);
      this.$emit('reload');
    },
    generateMarkdown () {
      return marked.parse(_.escape(this.notification.richTextBody), {
        breaks: true
      });
    }
  }
};
</script>