<template>
  <v-app style="background-color:#f4f4f4">
    <v-container fluid class="mt-8" style="max-width:900px">
      <v-row>
        <v-col cols="12" class="white pa-8 elevation-3">
          <div class="pb-2" style="border-bottom:2px solid #c0c0c0">
            <div class="d-flex align-end">
              <div>
                <h1 class="text-h2">
                  {{ $t('global.error') }}
                  <span class="text-minimum-gray" style="font-size:.7em">
                    &mdash; {{ $t('page.error.unauth.subtitle') }}
                  </span>
                </h1>
              </div>
              <div class="flex-grow-1 text-right">
                <v-icon color="error" style="font-size:52px">
                  mdi-cancel
                </v-icon>
              </div>
            </div>
          </div>
          <p class="mt-6" style="font-size:16px;font-weight:400;">
            {{ $t('page.error.unauth.description') }}
          </p>
          <div v-if="resource">
            <code class="py-1 px-2 error--text font-weight-bold" style="font-size:14px;display:block;word-break:break-all">
              {{ resource }}
            </code>
          </div>
          <div class="mt-16">
            <c-btn large type="primary" :to="{ name: 'home' }">
              {{ $t('global.returnHome') }}
            </c-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  props: {
    resource: {
      type: String
    }
  }
};
</script>