<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div>
    <v-alert :value="showRejectedCard"
             elevation="2"
             color="error"
             icon="mdi-alert-circle"
             outlined
             text
             class="mb-4"
             transition="fade-transition">
      <div class="font-weight-medium" style="font-size:1.1rem;line-height:1.5">
        {{ $t('page.manageFiling.feedback.rejection.title') }}
      </div>
      <p>
        {{ $t('page.manageFiling.feedback.rejection.description') }}
      </p>
      <ul>
        <li v-for="f in feedback">
          {{ f }}
        </li>
      </ul>
    </v-alert>

    <v-alert :value="showErroredCard"
             elevation="2"
             color="error"
             icon="mdi-alert-circle"
             outlined
             text
             class="mb-4"
             transition="fade-transition">
      <div class="font-weight-medium" style="font-size:1.1rem;line-height:1.5">
        {{ $t('page.manageFiling.feedback.error.title') }}
      </div>
      <p>
        {{ $t('page.manageFiling.feedback.error.description') }}
      </p>
      <ul>
        <li v-for="f in feedback">
          {{ f }}
        </li>
      </ul>
    </v-alert>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  computed: {
    showRejectedCard () {
      return this.filing.filingStatus.resourceID === this.CONST.FILING_STATUS_REJECTED &&
        this.feedback.length > 0;
    },
    showErroredCard () {
      return this.filing.filingStatus.resourceID === this.CONST.FILING_STATUS_ERRORED;
    },
    filing () {
      return this.$store.state.filing.filing;
    },
    feedback () {
      let feedback = [];
      let reasons = this.filing.clerkRejectionReason;
      if (!_.isEmpty(reasons))
      {
        let split = reasons.split('|').filter((v) => { return !_.isEmpty(v); });
        feedback = split;
      }

      let comments = this.filing.clerkComments;
      if (!_.isEmpty(comments))
      {
        feedback.push(comments);
      }

      return feedback;
    }
  }
};
</script>