<template>
  <div>
    <transition name="scroll-x-reverse-transition">
      <v-card>
        <v-card-title>
          {{ $t('page.initialRegistration.organizationAdmin.title') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('page.initialRegistration.organizationAdmin.description') }}
        </v-card-subtitle>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" class="pt-2">

              <c-autocomplete
                v-model="cmsOrganization"
                :items="organizations"
                :label="$t('page.initialRegistration.organizationAdmin.field.organization')"
                :no-data-text="$t('global.typeahead.search.noDataText')"
                :loading="orgLoading"
                no-filter
                required
                outlined
                return-object
                key="organization"
                item-text="sortName"
                :search-input.sync="organizationSearch"
                @click:clear="() => { organizations = []; }"
                @blur="() => { if (!cmsOrganization) { organizations = []; } }"
                hide-details="auto"
                aria-describedby="8f2fdd10-7e54-4f6f-9085-c778b667391c">
                <template #item="{ item }">
                  <v-list-item-content>
                    <div class="d-flex align-center">
                      <div class="break-word pr-2" style="flex: 0 0 50%;max-width:400px">
                        {{ item.sortName }}
                      </div>
                      <div class="text-minimum-gray break-word" style="flex: 0 0 50%;max-width:400px">
                        <render-address :formattedAddress="item.formattedAddress"/>
                      </div>
                    </div>
                  </v-list-item-content>
                </template>
              </c-autocomplete>
              <field-description id="8f2fdd10-7e54-4f6f-9085-c778b667391c"
                                 message-key="page.initialRegistration.organizationAdmin.field.organization.description"/>
            </v-col>

            <v-col cols="12">
              <section-separator :title="$t('page.initialRegistration.section.requestInfo')" class="mt-n2 mb-4"/>
            </v-col>
            <v-col cols="12">
              <c-file-input
                :label="$t('page.initialRegistration.organizationAdmin.field.referenceFile')"
                v-model="file"
                key="file"
                :required="getUserAccessConfig.requireFile"
                outlined
                hide-details="auto"
                aria-describedby="472f00b9-d92c-4253-aa4a-6918f2e77fb4"/>
              <field-description id="472f00b9-d92c-4253-aa4a-6918f2e77fb4">
                {{ $te('page.initialRegistration.organizationAdmin.field.referenceFile.description') ?
                  $t('page.initialRegistration.organizationAdmin.field.referenceFile.description') : null }}
                <button type="button" class="link-button" @click="openFileTypesPopup">
                  {{ $t('global.viewFileTypes') }}
                </button>
              </field-description>
            </v-col>
            <v-col cols="12">
              <c-textarea
                v-model="comments"
                :label="$t('page.initialRegistration.organizationAdmin.field.comments')"
                :required="getUserAccessConfig.requireComments"
                key="comments"
                outlined
                hide-details="auto"
                aria-describedby="113b3853-658a-47c8-a807-73243aa20654"/>
              <field-description id="113b3853-658a-47c8-a807-73243aa20654"
                                 message-key="page.initialRegistration.organizationAdmin.field.comments.description"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <c-btn type="primary" @click="save">
            {{ $t('page.initialRegistration.button.submitRequest') }}
          </c-btn>
        </v-card-actions>
      </v-card>
    </transition>

    <file-types-popup v-model="fileTypesPopupVisible" :fileType="CONST.DOCUMENT_TYPE_USER_REQUEST"/>
  </div>
</template>

<script>
import _ from 'lodash';
import FieldDescription from '@components/support/FieldDescription';
import SectionSeparator from '@components/support/SectionSeparator';
import FileTypesPopup from '@components/support/FileTypesPopup';
import UserAccess from '@/application/userAccess';
import RenderAddress from '@/components/support/RenderAddress';
import { scrollTo, validateFile } from '@/application/utility';
import axios from 'axios';

export default {
  components: { FieldDescription, SectionSeparator, FileTypesPopup, RenderAddress },
  data () {
    return {
      organizations: [],
      organizationSearch: null,
      orgLoading: false,
      cmsOrganization: null,
      comments: null,
      file: null,
      fileTypesPopupVisible: false
    };
  },
  computed: {
    getUserAccessConfig () {
      return UserAccess.getUserAccessConfig(this.CONST.USER_TYPE_ORGANIZATION_ADMIN);
    }
  },
  watch: {
    organizationSearch (newValue) {
      this.organizationDebounce(newValue);
    }
  },
  methods: {
    organizationDebounce: _.debounce(function (newValue) { // do not use arrow function here
      if (!_.isNil(newValue) && newValue.trim().length > 0)
      {
        this.loadOrganizations();
      }
    }, 333),
    loadOrganizations () {
      let url = this.$cove.getAPI({
        name: 'api.cms.organizations',
        query: {
          search: this.organizationSearch.trim(),
          size: this.CONST.PAGING_MAX_SIZE,
          sort: 'sortName,asc'
        }
      });

      this.orgLoading = true;
      axios.get(url)
        .then((response) => {
          this.organizations = _.get(response, 'data._embedded.results', []);
        })
        .finally(() => {
          this.orgLoading = false;
        });
    },
    validate () {
      this.$store.commit('cove/errors/clear');
      this.$cove.validation.required(this.cmsOrganization,
        'page.initialRegistration.organizationAdmin.field.organization', 'organization');

      if (this.getUserAccessConfig.requireFile)
      {
        this.$cove.validation.required(this.file, 'page.initialRegistration.organizationAdmin.field.referenceFile', 'file');
      }

      if (this.file !== null)
      {
        validateFile(this.file, this.CONST.DOCUMENT_TYPE_USER_REQUEST,
          'page.initialRegistration.organizationAdmin.field.referenceFile', 'file');
      }

      if (this.getUserAccessConfig.requireComments)
      {
        this.$cove.validation.required(this.comments,
          'page.initialRegistration.organizationAdmin.field.comments', 'comments');
      }

      this.$cove.validation.string.maxLength(this.comments, 2000,
        'page.initialRegistration.organizationAdmin.field.comments', 'comments');

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      this.$cove.block({ delay: 0 });

      let isValid = this.validate();
      if (!isValid)
      {
        this.$cove.unblock();
        scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
        return;
      }

      this.$emit('submitRequest', {
        file: this.file,
        bean: _.omitBy({
          userTypeID: this.CONST.USER_TYPE_ORGANIZATION_ADMIN,
          organizationExternalIdentifier: this.cmsOrganization.actorUUID,
          note: this.comments
        }, _.isNil)
      });
    },
    openFileTypesPopup () {
      this.fileTypesPopupVisible = true;
    }
  }
};
</script>