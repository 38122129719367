<template>
  <loading-card :loading="loading">
    <v-card-title>
      {{ $t('page.manageFiling.party.stepRep.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.manageFiling.party.stepRep.description') }}
    </v-card-subtitle>
    <v-card-text class="pt-2">
      <v-row no-gutters>
        <v-col v-if="!hideOptions" cols="12">
          <v-radio-group v-model="bean.representationType"
                         :label="$t('page.manageFiling.party.stepRep.field.repQuestion')"
                         class="mt-0"
                         @change="changeRepType">
            <v-radio v-if="isAttorney"
                     :value="CONST.REP_TYPE_CURRENT_USER"
                     :label="$t('page.manageFiling.party.stepRep.field.repQuestion.option.selfAtty')"
                     style="width:fit-content"/>
            <v-radio :value="CONST.REP_TYPE_PRIVATE"
                     :label="$t('page.manageFiling.party.stepRep.field.repQuestion.option.private')"
                     style="width:fit-content"/>
            <v-radio v-if="allowProSe"
                     :value="CONST.REP_TYPE_PRO_SE"
                     :label="$t('page.manageFiling.party.stepRep.field.repQuestion.option.proSe')"
                     style="width:fit-content"/>
            <v-radio v-if="!editing"
                     :value="CONST.REP_TYPE_UNKNOWN"
                     :label="$t('page.manageFiling.party.stepRep.field.repQuestion.option.unknown')"
                     style="width:fit-content"/>
          </v-radio-group>
        </v-col>

        <transition name="scroll-y-transition">
          <v-col v-if="showAttorneySelection" cols="12" key="selection">
            <v-radio-group v-model="bean.representationSelectionMode"
                           :label="$t('page.manageFiling.party.stepRep.field.repSelection')"
                           class="mt-0"
                           @change="changeSelectionMode">
              <v-radio :value="CONST.REP_SELECTION_MODE_ATTORNEY"
                       :label="$t('page.manageFiling.party.stepRep.field.repSelection.option.existingAttorney')"
                       style="width:fit-content"/>
              <v-radio :value="CONST.REP_SELECTION_MODE_LAW_FIRM"
                       :label="$t('page.manageFiling.party.stepRep.field.repSelection.option.existingLegalOrg')"
                       style="width:fit-content"/>
              <v-radio v-if="isAttorney"
                       :value="CONST.REP_SELECTION_MODE_NEW"
                       :label="$t('page.manageFiling.party.stepRep.field.repSelection.option.addNew')"
                       style="width:fit-content"/>
            </v-radio-group>
          </v-col>
        </transition>

        <transition name="scroll-y-transition" mode="out-in">
          <v-row v-if="bean.representationSelectionMode === CONST.REP_SELECTION_MODE_ATTORNEY" no-gutters key="attorneySearch">
            <v-col cols="12">
              <section-separator :title="$t('global.search')" class="mt-2 mb-5"/>
            </v-col>
            <v-col cols="12">
              <c-autocomplete
                v-model="bean.existingAttorney"
                :items="attorneys"
                :label="$t('page.manageFiling.party.stepRep.field.chooseAttorney')"
                :loading="repLoading"
                no-filter
                :hide-no-data="!showNoAttorneyResults"
                required
                outlined
                return-object
                key="existingAttorney"
                item-text="actorHeader.sortName"
                :search-input.sync="attorneySearch"
                @click:clear="() => { attorneys = []; }"
                @blur="() => { if (!bean.existingAttorney) { attorneys = []; } }"
                hide-details="auto"
                aria-describedby="1dd4f9db-9ad5-4710-934e-c8eba3c68e5e">
                <template #item="{ item }">
                  <v-list-item-content>
                    <div class="d-flex align-center">
                      <div class="break-word pr-2" style="flex: 0 0 44%">
                        {{ item.actorHeader.sortName }}
                      </div>
                      <div class="text-minimum-gray break-word" style="flex: 0 0 44%">
                        <render-address :formattedAddress="item.formattedAddress"/>
                      </div>
                      <div class="text-minimum-gray text-right break-word" style="flex: 0 0 12%">
                        {{ item.barNumber }}
                      </div>
                    </div>
                  </v-list-item-content>
                </template>
              </c-autocomplete>
              <field-description id="1dd4f9db-9ad5-4710-934e-c8eba3c68e5e"
                                 message-key="page.manageFiling.party.stepRep.field.chooseAttorney.description"/>
            </v-col>
          </v-row>
          <v-row v-else-if="bean.representationSelectionMode === CONST.REP_SELECTION_MODE_LAW_FIRM" no-gutters key="legalOrgSearch">
            <v-col cols="12">
              <section-separator :title="$t('global.search')" class="mt-2 mb-5"/>
            </v-col>
            <v-col cols="12">
              <c-autocomplete
                v-model="bean.existingLegalOrg"
                :items="legalOrgs"
                :label="$t('page.manageFiling.party.stepRep.field.chooseLegalOrg')"
                :loading="repLoading"
                no-filter
                :hide-no-data="!showNoLegalOrgResults"
                required
                outlined
                return-object
                item-text="actorHeader.sortName"
                key="existingLegalOrg"
                :search-input.sync="legalOrgSearch"
                @click:clear="() => { legalOrgs = []; }"
                @blur="() => { if (!bean.existingLegalOrg) { legalOrgs = []; } }"
                hide-details="auto"
                aria-describedby="7cf52d12-3cd7-4ee5-a834-ba2b1f840fc1">
                <template #item="{ item }">
                  <v-list-item-content>
                    <div class="d-flex align-center">
                      <div class="break-word pr-2" style="flex: 0 0 50%">
                        {{ item.actorHeader.sortName }}
                      </div>
                      <div class="text-minimum-gray break-word" style="flex: 0 0 50%">
                        <render-address :formattedAddress="item.formattedAddress"/>
                      </div>
                    </div>
                  </v-list-item-content>
                </template>
              </c-autocomplete>
              <field-description id="7cf52d12-3cd7-4ee5-a834-ba2b1f840fc1"
                                 message-key="page.manageFiling.party.stepRep.field.chooseLegalOrg.description"/>
            </v-col>
          </v-row>
          <v-row v-else-if="bean.representationSelectionMode === CONST.REP_SELECTION_MODE_NEW" no-gutters key="enterNew">
            <v-col v-if="!hideOptions" cols="12">
              <section-separator :title="$t('page.manageFiling.party.stepRep.section.type')" class="mt-2 mb-5"/>
            </v-col>
            <v-col v-if="!hideOptions" cols="12">
              <c-autocomplete
                v-model="bean.newRepresentation.actor.person"
                :items="actorCategories"
                :label="$t('page.manageFiling.party.stepRep.field.actorType')"
                item-value="value"
                :clearable="false"
                outlined
                @change="changeType"
                hide-details="auto"
                aria-describedby="8d04bec7-cb1d-48d7-9400-70891f969d88"/>
              <field-description id="8d04bec7-cb1d-48d7-9400-70891f969d88"
                                 message-key="page.manageFiling.party.stepRep.field.actorType.description"/>
            </v-col>
            <transition name="scroll-y-transition" mode="out-in">
              <v-row v-if="bean.newRepresentation.actor.person" no-gutters key="display">
                <v-col cols="12">
                  <section-separator title="Bar Information" class="mb-5"/>
                </v-col>
                <v-col cols="12" md="4" class="pr-md-2">
                  <c-autocomplete
                    v-model="bean.newRepresentation.legalRepresenter.regionExternalIdentifier"
                    :label="$t('page.manageFiling.party.stepRep.field.barRegion')"
                    :items="regions"
                    item-text="regionName"
                    item-value="regionID"
                    key="attyRegion"
                    required
                    outlined
                    hide-details="auto"
                    aria-describedby="abd82ee8-a154-42d4-a72b-57e19edc7fcd"/>
                  <field-description id="abd82ee8-a154-42d4-a72b-57e19edc7fcd"
                                     message-key="page.manageFiling.party.stepRep.field.barRegion.description"/>
                </v-col>
                <v-col cols="12" md="8" class="pl-md-2">
                  <c-text-field
                    v-model="bean.newRepresentation.legalRepresenter.barID"
                    :label="$t('page.manageFiling.party.stepRep.field.barID')"
                    maxlength="50"
                    key="barID"
                    required
                    outlined
                    hide-details="auto"
                    aria-describedby="aa9cec8a-7b73-4b7d-bac2-1bb1bcfc3222"/>
                  <field-description id="aa9cec8a-7b73-4b7d-bac2-1bb1bcfc3222"
                                     message-key="page.manageFiling.party.stepRep.field.barID.description"/>
                </v-col>
              </v-row>
              <div v-else key="empty">
                <!-- placeholder here to keep transition timing in sync with name changes -->
              </div>
            </transition>

            <v-col cols="12">
              <transition name="scroll-y-transition" mode="out-in">
                <v-row v-if="bean.newRepresentation.actor.person" no-gutters key="person">
                  <v-col cols="12">
                    <section-separator title="Name" class="mb-5"/>
                  </v-col>
                  <v-col cols="12" md="4" class="pr-md-2">
                    <c-text-field
                      v-model="bean.newRepresentation.actor.firstName"
                      :label="$t('page.manageFiling.party.stepRep.field.firstName')"
                      maxlength="100"
                      key="firstName"
                      required
                      outlined
                      hide-details="auto"
                      aria-describedby="cd9bead3-c382-4b9a-9bf1-c58d98847652"/>
                    <field-description id="cd9bead3-c382-4b9a-9bf1-c58d98847652"
                                       message-key="page.manageFiling.party.stepRep.field.firstName.description"/>
                  </v-col>
                  <v-col cols="12" md="4" class="px-md-2">
                    <c-text-field
                      v-model="bean.newRepresentation.actor.middleName"
                      :label="$t('page.manageFiling.party.stepRep.field.middleName')"
                      maxlength="100"
                      key="middleName"
                      outlined
                      hide-details="auto"
                      aria-describedby="036e0c4a-b0ca-4b1f-9055-b36d655376fb"/>
                    <field-description id="036e0c4a-b0ca-4b1f-9055-b36d655376fb"
                                       message-key="page.manageFiling.party.stepRep.field.middleName.description"/>
                  </v-col>
                  <v-col cols="12" md="4" class="pl-md-2">
                    <c-text-field
                      v-model="bean.newRepresentation.actor.lastName"
                      :label="$t('page.manageFiling.party.stepRep.field.lastName')"
                      maxlength="100"
                      key="lastName"
                      required
                      outlined
                      hide-details="auto"
                      aria-describedby="75f93950-9378-4166-a781-92fe9df8e5fb"/>
                    <field-description id="75f93950-9378-4166-a781-92fe9df8e5fb"
                                       message-key="page.manageFiling.party.stepRep.field.lastName.description"/>
                  </v-col>
                  <v-col cols="12" md="4" class="pr-md-2">
                    <c-text-field
                      v-model="bean.newRepresentation.actor.prefix"
                      :label="$t('page.manageFiling.party.stepRep.field.prefix')"
                      maxlength="100"
                      key="prefix"
                      outlined
                      hide-details="auto"
                      aria-describedby="de2ca5cc-5f5d-4cc3-bf34-4514532f25cb"/>
                    <field-description id="de2ca5cc-5f5d-4cc3-bf34-4514532f25cb"
                                       message-key="page.manageFiling.party.stepRep.field.prefix.description"/>
                  </v-col>
                  <v-col cols="12" md="4" class="px-md-2">
                    <c-text-field
                      v-model="bean.newRepresentation.actor.suffix"
                      :label="$t('page.manageFiling.party.stepRep.field.suffix')"
                      maxlength="100"
                      key="suffix"
                      outlined
                      hide-details="auto"
                      aria-describedby="2edee104-be85-4db6-8749-ebbe27d026b9"/>
                    <field-description id="2edee104-be85-4db6-8749-ebbe27d026b9"
                                       message-key="page.manageFiling.party.stepRep.field.suffix.description"/>
                  </v-col>
                </v-row>
                <v-row v-else no-gutters key="org">
                  <v-col cols="12">
                    <section-separator title="Name" class="mb-5"/>
                  </v-col>
                  <v-col cols="12">
                    <c-text-field
                      v-model="bean.newRepresentation.actor.organizationName"
                      :label="$t('page.manageFiling.party.stepRep.field.legalOrgName')"
                      maxlength="100"
                      key="orgName"
                      outlined
                      required
                      hide-details="auto"
                      aria-describedby="59e298ed-bf26-4e31-bd45-63e18429f459"/>
                    <field-description id="59e298ed-bf26-4e31-bd45-63e18429f459"
                                       message-key="page.manageFiling.party.stepRep.field.legalOrgName.description"/>
                  </v-col>
                </v-row>
              </transition>
            </v-col>
            <v-col cols="12">
              <section-separator :title="$t('page.manageFiling.section.address')" class="mb-5"/>
            </v-col>
            <address-form :address="bean.newRepresentation.address" ref="addressForm"/>
            <v-col cols="12">
              <section-separator :title="$t('page.manageFiling.section.contact')" class="mb-5"/>
            </v-col>
            <v-col cols="12" md="6" class="pr-md-2">
              <c-autocomplete
                v-model="bean.newRepresentation.contact.contactPhoneCountry"
                :label="$t('page.manageFiling.party.stepRep.field.phoneCountry')"
                :items="countryPhones"
                item-text="typeahead"
                return-object
                outlined
                :clearable="false"
                hide-details
                aria-describedby="f3242c61-4249-4aa2-a6e4-49013691ec85">
                <template #item="{ item }">
                  <div style="flex:1 1 auto">
                    {{ item.name }}
                  </div>
                  <div class="ml-4 text-minimum-gray" style="flex:0 0 auto">
                    +{{ item.dialCode }}
                  </div>
                </template>
              </c-autocomplete>
            </v-col>
            <div class="d-md-none" style="flex: 0 0 100%;margin-top:26px">
              <!-- placeholder when wrapped on small displays -->
            </div>
            <v-col cols="12" md="6" class="pl-md-2">
              <c-text-field
                v-model="bean.newRepresentation.contact.contactPhoneNumber"
                :label="$t('page.manageFiling.party.stepRep.field.phoneNumber')"
                maxlength="50"
                key="contactPhoneNumber"
                outlined
                required
                @change="formatContactPhoneNumber"
                hide-details="auto"
                aria-describedby="f3242c61-4249-4aa2-a6e4-49013691ec85"/>
            </v-col>
            <div>
              <field-description id="f3242c61-4249-4aa2-a6e4-49013691ec85"
                                 message-key="page.manageFiling.party.stepRep.field.phone.description"/>
            </div>
            <v-col cols="12">
              <c-text-field
                v-model="bean.newRepresentation.contact.primaryEmail"
                :label="$t('page.manageFiling.party.stepRep.field.emailAddress')"
                maxlength="50"
                type="email"
                key="emailAddress"
                outlined
                required
                hide-details="auto"
                aria-describedby="f982ad87-e235-4c7d-912d-9e28adcb5148"/>
              <field-description id="f982ad87-e235-4c7d-912d-9e28adcb5148"
                                 message-key="page.manageFiling.party.stepRep.field.emailAddress.description"/>
            </v-col>
          </v-row>
        </transition>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-end mt-5">
      <c-btn type="primary" @click="save">
        {{ editing ? $t('global.save') : $t('global.next') }}
      </c-btn>
      <c-btn v-if="!editing" @click="$emit('back')">
        {{ $t('global.back') }}
      </c-btn>
      <c-btn @click="$emit('cancel')">
        {{ $t('global.cancel') }}
      </c-btn>
    </v-card-actions>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import WorkflowStepMixin from '@/mixins/workflowStep';
import AddressForm from '@/components/support/AddressForm';
import CountryPhones from '@/application/countryPhone';
import RenderAddress from '@/components/support/RenderAddress';
import { formatPhoneInput, isPhoneValid } from '@/application/utility';

export default {
  components: { AddressForm, RenderAddress },
  mixins: [ WorkflowStepMixin ],
  props: {
    // true when accessed via the Edit Party screen, which could be editing an existing legalRep OR adding a new one
    editing: {
      type: Boolean,
      default: false
    },
    // used when editing existing legal rep to prevent them from changing the type of representation selected
    hideOptions: {
      type: Boolean,
      default: false
    },
    allowProSe: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showNoAttorneyResults: false,
      showNoLegalOrgResults: false,
      attorneys: [],
      legalOrgs: [],
      repLoading: false,
      attorneySearch: null,
      legalOrgSearch: null
    };
  },
  computed: {
    actorCategories () {
      return _.orderBy([
        { text: this.$t('page.manageFiling.party.stepRep.field.chooseAttorney'), value: true },
        { text: this.$t('page.manageFiling.party.stepRep.field.chooseLegalOrg'), value: false }
      ], 'text');
    },
    isAttorney () {
      return this.$store.getters['user/hasRole'](this.CONST.ROLE_ATTORNEY);
    },
    showAttorneySelection () {
      return !this.hideOptions && this.bean.representationType === this.CONST.REP_TYPE_PRIVATE;
    },
    countryPhones () {
      return CountryPhones;
    },
    defaultPhoneCountry () {
      return this.$store.getters['application/getDefaultPhoneCountry'];
    },
    countries () {
      return this.$store.getters['application/getCountries'];
    },
    defaultCountry () {
      return this.$store.getters['application/getDefaultCountry'];
    },
    regions () {
      return this.$store.getters['application/getRegionsByCountry'](this.defaultCountry.countryID);
    }
  },
  methods: {
    watchAttorneySearch (newValue, oldValue) {
      this.showNoAttorneyResults = false;
      this.attorneyDebounce(newValue);
    },
    watchLegalOrgSearch (newValue) {
      this.showNoLegalOrgResults = false;
      this.legalOrgDebounce(newValue);
    },
    attorneyDebounce: _.debounce(function (newValue) { // do not use arrow function here
      if (!_.isNil(newValue) && newValue.trim().length > 0)
      {
        this.loadAttorneys();
      }
    }, 333),
    legalOrgDebounce: _.debounce(function (newValue) { // do not use arrow function here
      if (!_.isNil(newValue) && newValue.trim().length > 0)
      {
        this.loadLegalOrgs();
      }
    }, 333),
    loadData () {
      if (this.bean.newRepresentation.contact.contactPhoneCountry === null)
      {
        this.bean.newRepresentation.contact.contactPhoneCountry = this.defaultPhoneCountry;
      }

      if (this.bean.newRepresentation.legalRepresenter.regionExternalIdentifier === null)
      {
        let defaultRegion = this.$store.getters['application/getDefaultRegionByCountry'](this.defaultCountry.countryID);
        if (defaultRegion !== null)
        {
          this.bean.newRepresentation.legalRepresenter.regionExternalIdentifier = defaultRegion.regionID;
        }
      }

      if (this.bean.existingAttorney !== null)
      {
        this.attorneys = [this.bean.existingAttorney];
        this.attorneySearch = this.bean.existingAttorney.actorHeader.sortName;
      }
      else if (this.bean.existingLegalOrg !== null)
      {
        this.legalOrgs = [this.bean.existingLegalOrg];
        this.legalOrgSearch = this.bean.existingLegalOrg.actorHeader.sortName;
      }

      // !!! be sure to do this at the very end of loadData !!!
      // we set up the watchers here instead of the traditional way because we need to seed this value if we are editing existing data
      // and don't want the AJAX search to run immediately (in these cases we seed the dropdown with exactly one value - the selected one).
      this.$watch('attorneySearch', this.watchAttorneySearch);
      this.$watch('legalOrgSearch', this.watchLegalOrgSearch);
    },
    validate () {
      this.$store.commit('cove/errors/clear');

      // TODO this should reference a global 'representation' label instead
      this.$cove.validation.required(this.bean.representationType, 'page.caseView.tab.parties.representation', 'n/a');

      if (this.bean.representationType === this.CONST.REP_TYPE_PRIVATE)
      {
        this.$cove.validation.required(this.bean.representationSelectionMode, 'page.manageFiling.party.stepRep.field.repSelection',
          'no-key');
      }

      if (this.bean.representationSelectionMode === this.CONST.REP_SELECTION_MODE_NEW)
      {
        if (this.bean.newRepresentation.actor.person)
        {
          this.$cove.validation.required(this.bean.newRepresentation.legalRepresenter.regionExternalIdentifier,
            'page.manageFiling.party.stepRep.field.barRegion', 'attyRegion');
          this.$cove.validation.required(this.bean.newRepresentation.legalRepresenter.barID, 'page.manageFiling.party.stepRep.field.barID',
            'barID');
          this.$cove.validation.required(this.bean.newRepresentation.actor.firstName, 'page.manageFiling.party.stepRep.field.firstName',
            'firstName');
          this.$cove.validation.required(this.bean.newRepresentation.actor.lastName, 'page.manageFiling.party.stepRep.field.lastName',
            'lastName');
        }
        else
        {
          this.$cove.validation.required(this.bean.newRepresentation.actor.organizationName,
            'page.manageFiling.party.stepRep.field.legalOrgName', 'orgName');
        }

        this.$refs.addressForm.validate();

        let phoneNumber = this.bean.newRepresentation.contact.contactPhoneNumber;
        this.$cove.validation.required(phoneNumber, 'page.manageFiling.party.stepRep.field.phoneNumber', 'contactPhoneNumber');
        if (phoneNumber !== null && !isPhoneValid(phoneNumber, this.bean.newRepresentation.contact.contactPhoneCountry.iso))
        {
          this.$cove.validation.addError('contactPhoneNumber', 'page.currentUser.error.invalidPhone',
            [this.$t('page.manageFiling.party.stepRep.field.phoneNumber')]);
        }

        let hasEmail = this.$cove.validation.required(this.bean.newRepresentation.contact.primaryEmail,
          'page.manageFiling.party.stepRep.field.emailAddress', 'emailAddress');
        if (hasEmail && !this.CONST.REGEX_VALID_EMAIL.test(this.bean.newRepresentation.contact.primaryEmail))
        {
          this.$cove.validation.addError('emailAddress', 'page.currentUser.error.invalidEmail',
            [this.$t('page.manageFiling.party.stepRep.field.emailAddress')]);
        }
      }
      else if (this.bean.representationSelectionMode === this.CONST.REP_SELECTION_MODE_ATTORNEY)
      {
        this.$cove.validation.required(this.bean.existingAttorney, 'page.manageFiling.party.stepRep.field.chooseAttorney',
          'existingAttorney');
      }
      else if (this.bean.representationSelectionMode === this.CONST.REP_SELECTION_MODE_LAW_FIRM)
      {
        this.$cove.validation.required(this.bean.existingLegalOrg, 'page.manageFiling.party.stepRep.field.chooseLegalOrg',
          'existingLegalOrg');
      }

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      let isValid = this.validate();
      if (!isValid)
      {
        this.scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
      }
      else
      {
        this.$emit('save', this.bean);
      }
    },
    changeRepType () {
      this.bean.representationSelectionMode = null;
    },
    changeSelectionMode () {
      this.bean.existingAttorney = null;
      this.attorneys = [];
      this.bean.existingLegalOrg = null;
      this.legalOrgs = [];
    },
    changeType () {
      if (this.bean.newRepresentation.actor.person)
      {
        this.bean.newRepresentation.actor.organizationName = null;
      }
      else
      {
        this.bean.newRepresentation.actor.firstName = null;
        this.bean.newRepresentation.actor.middleName = null;
        this.bean.newRepresentation.actor.lastName = null;
        this.bean.newRepresentation.actor.prefix = null;
        this.bean.newRepresentation.actor.suffix = null;
      }
    },
    formatContactPhoneNumber () {
      this.bean.newRepresentation.contact.contactPhoneNumber =
        formatPhoneInput(this.bean.newRepresentation.contact.contactPhoneNumber,
          this.bean.newRepresentation.contact.contactPhoneCountry.iso);
    },
    loadAttorneys () {
      let url = this.$cove.getAPI({
        name: 'api.representers',
        query: {
          search: this.attorneySearch.trim(),
          attorneySearch: true,
          size: this.CONST.PAGING_MAX_SIZE
        }
      });

      this.repLoading = true;
      axios.get(url)
        .then((response) => {
          this.attorneys = _.get(response, 'data._embedded.results', []);
          if (this.attorneys.length === 0)
          {
            this.showNoAttorneyResults = true;
          }
        })
        .finally(() => {
          this.repLoading = false;
        });
    },
    loadLegalOrgs () {
      let url = this.$cove.getAPI({
        name: 'api.representers',
        query: {
          search: this.legalOrgSearch.trim(),
          attorneySearch: false,
          size: this.CONST.PAGING_MAX_SIZE
        }
      });

      this.repLoading = true;
      axios.get(url)
        .then((response) => {
          this.legalOrgs = _.get(response, 'data._embedded.results', []);
          if (this.legalOrgs.length === 0)
          {
            this.showNoLegalOrgResults = true;
          }
        })
        .finally(() => {
          this.repLoading = false;
        });
    }
  }
};
</script>