<template>
  <c-dialog
    :value="value"
    width="850"
    close-key="cove.general.close"
    title-key="page.notificationPopup.title"
    @input="close">

    <div class="pa-5">

      <section-separator :title="$t('page.notificationPopup.metaData')" class="mb-5"/>

      <div class="mt-2">
        <dl class="d-grid grid-3070">
          <dt>{{ $t('notification.recipient') }}</dt>
          <dd>{{ notification.recipientDisplayName }} ({{ notification.recipientUsername }})</dd>
          <dt>{{ $t('notification.createdDate') }}</dt>
          <dd>{{ notification.createdDate | formatDateTime }}</dd>
          <dt>{{ $t('notification.status.short') }}</dt>
          <dd>{{ notification.notificationStatusDisplayName }}</dd>
          <dt>{{ $t('notification.type') }}</dt>
          <dd>{{ notification.notificationTypeDisplayName }}</dd>
          <dt>{{ $t('notification.method') }}</dt>
          <dd>{{ notification.notificationMethodDisplayName }}</dd>
          <template v-if="showRecipientValue">
            <dt>{{ notificationMethodLabel }}</dt>
            <dd>{{ notification.recipientValue }}</dd>
            <dt>{{ sentDateLabel }}</dt>
            <dd>{{ notification.sentDate | formatDateTime }}</dd>
          </template>
          <template v-if="showCaseNumber">
            <dt>{{ $t('courtCase.caseNumber') }}</dt>
            <dd>{{ notification.caseNumber }}</dd>
          </template>
          <dt>{{ $t('notification.subject') }}</dt>
          <dd>{{ notification.subject }}</dd>
        </dl>
      </div>

      <section-separator :title="$t('notification.richTextBody')" class="mb-5"/>
      <div class="mt-2 markdown" v-html="generateMarkdown()"></div>
    </div>

    <template #actions-right>
      <c-btn type="primary" @click="resend">
        {{ $t('application.button.resend') }}
      </c-btn>
    </template>
  </c-dialog>
</template>

<script>
import _ from 'lodash';
import SectionSeparator from '@components/support/SectionSeparator';
import { marked } from 'marked';
import axios from 'axios';

export default {
  components: { SectionSeparator },
  props: {
    value: {
      type: Boolean
    },
    notification: {
      type: Object,
      required: true
    }
  },
  computed: {
    showRecipientValue () {
      return this.notification.notificationMethodID !== this.CONST.NOTIFICATION_METHOD_APPLICATION;
    },
    notificationMethodLabel () {
      return this.notification.notificationMethodID === this.CONST.NOTIFICATION_METHOD_EMAIL ?
        this.$t('notification.recipient.email') :
        this.$t('notification.recipient.sms');
    },
    sentDateLabel () {
      return this.notification.notificationMethodID === this.CONST.NOTIFICATION_METHOD_EMAIL ?
        this.$t('notification.sentDate.email') :
        this.$t('notification.sentDate.sms');
    },
    showCaseNumber () {
      return !_.isNil(this.notification.caseNumber);
    }
  },
  methods: {
    close (reload = false) {
      this.$emit('input', false);
      if (reload) {
        this.$emit('reload');
      }
    },
    generateMarkdown () {
      return marked.parse(_.escape(this.notification.richTextBody), {
        breaks: true
      });
    },
    resend () {
      this.$cove.block({ delay: 0 });

      let isValid = this.validate();
      if (!isValid)
      {
        this.$cove.unblock();
        this.$cove.notify({ color: 'warning', message: this.$t('notification.resend.validation.noError') });
      }
      else
      {
        let notificationIds = [];
        notificationIds.push(this.notification.notificationUUID);

        let url = this.$cove.getAPI({
          name: 'api.notifications.resend',
          query: {
            notificationIds
          }
        });

        axios.post(url)
          .then(() => {
            this.$cove.notify({ message: this.$t('notification.resend.success'), color: 'success' });
            this.close(true);
          })
          .finally(() => {
            this.$cove.unblock();
          });
      }
    },
    validate () {
      return this.notification.notificationStatusID === this.CONST.NOTIFICATION_STATUS_ERROR;
    }
  }
};
</script>