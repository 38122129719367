<template>
  <page-wrapper :title="$t('page.adminOrganizations.title')">
    <search-result-template :config="config">

      <template #table-title>
        {{ $t('page.adminOrganizations.table') }}
      </template>

      <template #v-data-table.item.sortName="{ item }">
        <template v-if="!isNil(item.organizationUUID)">
          <router-link :to="{ name: 'manage-org', params: { organizationID: item.organizationUUID} }">
            {{ item.sortName }}
          </router-link>
        </template>
        <template v-else>
          {{ item.sortName }}
        </template>
      </template>

      <template #v-data-table.item.formattedAddress="{ value }">
        <render-address :formattedAddress="value"/>
      </template>

      <template #v-data-table.item.actorTypeList="{ value }">
        <div v-for="actorType in value" :key="actorType.actorTypeID">
          {{ actorType.actorTypeName }}
        </div>
      </template>

      <!-- Search Criteria -->
      <template #nav-drawer-filter-body>
        <v-row no-gutters class="mt-4">
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.sortName"
              :label="$t('page.adminOrganizations.name')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="1c4bb58b-3c8f-4d91-ad0e-186d3f44f4ff"/>
            <field-description id="1c4bb58b-3c8f-4d91-ad0e-186d3f44f4ff"
                               message-key="page.adminOrganizations.name.description"/>
          </v-col>
        </v-row>
      </template>

    </search-result-template>

  </page-wrapper>
</template>

<script>
import _ from 'lodash';
import RenderAddress from '@components/support/RenderAddress';
import SearchResultTemplate from '@components/support/template/SearchResultTemplate';
import SearchResultTemplateMixin from '@/mixins/searchResultTemplate';

export default {
  components: { RenderAddress, SearchResultTemplate },
  mixins: [SearchResultTemplateMixin],
  data () {
    return {
      searchDTO: {
        sort: {
          sortBy: 'sortName',
          sortDesc: false
        },
        sortName: null
      }
    };
  },
  methods: {
    getRouteName () {
      return 'admin-organizations';
    },
    getSearchAPIName () {
      return 'api.cms.organizations';
    },
    getItemKey () {
      return 'actorUUID';
    },
    getHeaders () {
      let headers = [];

      headers.push({ text: this.$t('page.adminOrganizations.name'), value: 'sortName', sortable: true });
      headers.push({ text: this.$t('page.adminOrganizations.address'), value: 'formattedAddress', sortable: false });
      headers.push({ text: this.$t('page.adminOrganizations.actorTypes.short'), value: 'actorTypeList', sortable: false });

      return headers;
    },
    addSearchCriteria (query) {
      if (!_.isNil(this.searchDTO.sort.sortBy))
      {
        query.sort = `${this.searchDTO.sort.sortBy},${this.searchDTO.sort.sortDesc ? 'desc' : 'asc'}`;
      }

      if (!_.isNil(this.searchDTO.sortName))
      {
        query.search = this.searchDTO.sortName;
      }

      return query;
    },
    validateSearchCriteria (searchDTO) {
      // no validation
    }
  }
};
</script>

