<template>
  <div>
    <transition name="scroll-x-reverse-transition">
      <v-card>
        <v-card-title>
          {{ $t('page.initialRegistration.contact.title') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('page.initialRegistration.contact.description') }}
        </v-card-subtitle>
        <v-card-text class="mt-2">
          <v-row no-gutters>
            <v-col v-if="smsEnabled" cols="12">
              <c-checkbox
                v-model="smsFlag"
                :label="$t('page.currentUser.field.sms.enable')"
                class="my-0"
                @change="toggleSMS"
                hide-details="auto"
                aria-describedby="a555e785-d7e4-4622-8e1b-4ef3f1cd26b2"/>
              <field-description id="a555e785-d7e4-4622-8e1b-4ef3f1cd26b2"
                                 message-key="page.currentUser.field.sms.enable.description"/>
            </v-col>
            <transition name="scroll-y-transition">
              <v-col v-if="smsFlag" cols="12">
                <div class="d-flex align-center">
                  <c-autocomplete
                    v-model="smsPhoneCountry"
                    :items="countryPhones"
                    item-text="typeahead"
                    return-object
                    :label="$t('page.currentUser.field.smsPhone.country')"
                    style="flex:1 1 45%"
                    outlined
                    :clearable="false"
                    required
                    hide-details="auto"
                    aria-describedby="c7623367-e073-42a4-af60-e435f87141e8">
                    <template #item="{ item }">
                      <div style="flex:1 1 auto">
                        {{ item.name }}
                      </div>
                      <div class="ml-4 text-minimum-gray" style="flex:0 0 auto">
                        +{{ item.dialCode }}
                      </div>
                    </template>
                  </c-autocomplete>
                  <c-text-field
                    v-model="smsPhoneNumber"
                    :label="$t('page.currentUser.field.smsPhone.number')"
                    class="ml-4"
                    style="flex:1 1 55%"
                    key="smsPhoneNumber"
                    type="tel"
                    maxlength="50"
                    outlined
                    required
                    @change="formatSMSPhoneNumber"
                    hide-details="auto"
                    aria-describedby="c7623367-e073-42a4-af60-e435f87141e8"/>
                </div>
                <div>
                  <field-description id="c7623367-e073-42a4-af60-e435f87141e8"
                                     message-key="page.currentUser.field.smsPhone.description"/>
                </div>
              </v-col>
            </transition>
            <v-col cols="12">
              <div class="d-flex align-center">
                <c-autocomplete
                  v-model="contactPhoneCountry"
                  :items="countryPhones"
                  item-text="typeahead"
                  return-object
                  :label="$t('page.currentUser.field.contactPhone.country')"
                  style="flex:1 1 45%"
                  outlined
                  :clearable="false"
                  hide-details="auto"
                  aria-describedby="b0cd4f8e-7474-4218-9d2f-bc109f49c78a">
                  <template #item="{ item }">
                    <div style="flex:1 1 auto">
                      {{ item.name }}
                    </div>
                    <div class="ml-4 text-minimum-gray" style="flex:0 0 auto">
                      +{{ item.dialCode }}
                    </div>
                  </template>
                </c-autocomplete>
                <c-text-field
                  v-model="contactPhoneNumber"
                  :label="$t('page.currentUser.field.contactPhone.number')"
                  class="ml-4"
                  style="flex:1 1 55%"
                  maxlength="50"
                  type="tel"
                  key="contactPhoneNumber"
                  outlined
                  :required="required"
                  @change="formatContactPhoneNumber"
                  hide-details="auto"
                  aria-describedby="b0cd4f8e-7474-4218-9d2f-bc109f49c78a"/>
              </div>
              <div>
                <field-description id="b0cd4f8e-7474-4218-9d2f-bc109f49c78a"
                                   message-key="page.currentUser.field.contactPhone.description"/>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <div v-if="!required" style="flex: 1 1 auto">
            <c-btn @click="advance">
              {{ $t('global.skipThisStep') }}
            </c-btn>
          </div>
          <div class="text-right" style="flex: 1 1 auto">
            <c-btn type="primary" @click="save">
              {{ $t('global.continue') }}
            </c-btn>
          </div>
        </v-card-actions>
      </v-card>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import CountryPhone from '@/application/countryPhone';
import { scrollTo, formatPhoneInput, isPhoneValid, formatPhoneForSaving } from '@/application/utility';
import FieldDescription from '@components/support/FieldDescription';

export default {
  components: { FieldDescription },
  props: {
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      smsFlag: false,
      smsPhoneCountry: null,
      smsPhoneNumber: null,
      contactPhoneCountry: null,
      contactPhoneNumber: null
    };
  },
  computed: {
    countryPhones () {
      return CountryPhone;
    },
    defaultPhoneCountry () {
      return this.$store.getters['application/getDefaultPhoneCountry'];
    },
    smsEnabled () {
      return this.$store.getters['application/getAppProperty'](this.CONST.APP_PROPERTY_SMS_ENABLED);
    }
  },
  created () {
    this.contactPhoneCountry = this.defaultPhoneCountry;
  },
  methods: {
    advance () {
      this.$store.commit('cove/errors/clear');
      this.$emit('nextStep');
    },
    save () {
      this.$cove.block({ delay: 0 });

      let isValid = this.validate();
      if (!isValid)
      {
        this.$cove.unblock();
        scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
        return;
      }

      let smsPhoneNumber = null;
      if (this.smsFlag && this.smsEnabled)
      {
        smsPhoneNumber = formatPhoneForSaving(this.smsPhoneCountry, this.smsPhoneNumber);
      }

      let contactPhoneNumber = null;
      if (this.contactPhoneNumber !== null)
      {
        contactPhoneNumber = formatPhoneForSaving(this.contactPhoneCountry, this.contactPhoneNumber);
      }

      let bean = {
        contacts: {
          smsPhoneNumber,
          contactPhoneNumber
        }
      };

      axios.patch(this.$cove.getAPI({ name: 'api.user.currentuser' }), bean)
        .then(() => {
          return this.$store.dispatch('user/reload')
            .then(() => {
              this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
              this.advance();
            });
        }).finally(() => {
          this.$cove.unblock();
        });
    },
    validate () {
      this.$store.commit('cove/errors/clear');

      // if sms checked, validate sms number
      if (this.smsFlag && this.smsEnabled)
      {
        let smsProvided = this.$cove.validation.required(this.smsPhoneNumber, 'page.currentUser.field.smsPhone.number', 'smsPhoneNumber');
        if (smsProvided && !isPhoneValid(this.smsPhoneNumber, this.smsPhoneCountry.iso))
        {
          this.$cove.validation.addError('smsPhoneNumber', 'page.currentUser.error.invalidPhone',
            [this.$t('page.currentUser.field.smsPhone.number')]);
        }
      }

      // if contact phone filed in, validate contact number
      if (this.required)
      {
        this.$cove.validation.required(this.contactPhoneNumber, 'page.currentUser.field.contactPhone.number', 'contactPhoneNumber');
      }

      if (this.contactPhoneNumber !== null && !isPhoneValid(this.contactPhoneNumber, this.contactPhoneCountry.iso))
      {
        this.$cove.validation.addError('contactPhoneNumber', 'page.currentUser.error.invalidPhone',
          [this.$t('page.currentUser.field.contactPhone.number')]);
      }

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    toggleSMS () {
      if (this.smsFlag)
      {
        this.smsPhoneCountry = this.defaultPhoneCountry;
      }
      else
      {
        this.smsPhoneCountry = null;
        this.smsPhoneNumber = null;
      }
    },
    formatSMSPhoneNumber () {
      this.smsPhoneNumber = formatPhoneInput(this.smsPhoneNumber, this.smsPhoneCountry.iso);
    },
    formatContactPhoneNumber () {
      this.contactPhoneNumber = formatPhoneInput(this.contactPhoneNumber, this.contactPhoneCountry.iso);
    }
  }
};
</script>