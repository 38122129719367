import _ from 'lodash';
import { changeLocale, changeTimezone, changeTheme, getBrowserTimezone, getCookieValue, getBrowserLightTheme, getBrowserLocale,
  setLocalStorageValue, redirectToLogout, startSessionExpiryInterval } from '@/application/application';
import { scrollTo, scrollSidebarTo } from '@/application/utility';
import { broadcastMessage } from '@/application/broadcast';
import i18n, { getBestFitLocale } from '@/plugins/i18n';
import axios from 'axios';
import CONST from '@/application/constants';
import cove from '@ctrack/cove';
import store from '@/plugins/store';
import Vue from 'vue';

/**
 * The application's primary initialization tasks.
 */
export default function ()
{
  Vue.mixin({
    computed: {
      CONST () {
        return CONST;
      }
    },
    methods: {
      scrollTo,
      scrollSidebarTo,
      isNil: _.isNil
    }
  });

  // apply front-end configuration values
  cove.setConfig(Object.assign({}, window._config));

  // check if an invalid/unusable account attempted to login. if so, they may pass IDP authentication but our backend will forward them to
  // '/invalid-user' as our flag that this account is not usable. once detected we'll log them out and dump them on an error page. this also
  // stops the rest of the application from continuing to load.
  let invalidUser = _.get(window, 'location.pathname', null) === '/invalid-user';
  if (invalidUser)
  {
    setLocalStorageValue(CONST.BROWSER_STORAGE_NEXT_URL, '/portal/error/invalid-user');
    store.dispatch('application/loadAppProperties')
      .then(() => {
        redirectToLogout();
      });

    return;
  }

  // load data required for initialization
  let localePromise = store.dispatch('application/loadLocales');
  let appPropertiesPromise = store.dispatch('application/loadAppProperties');
  let userPromise = store.dispatch('user/login');

  // this is responsible for applying the user's preferences in the application...
  // - if it's an anonymous user we'll apply all browser defaults except for language in which case we also check a cookie
  // - if you are a valid user:
  // ---- if it's your first time logging in we'll apply browser defaults as your preferences, and persist them to your account
  // ---- otherwise, we'll use the preferences from your account
  Promise.all([localePromise, appPropertiesPromise, userPromise])
    .then(() => {

      if (store.state.user.hasSession)
      {
        // Start user polling only if the user is logged in and all the application properties are loaded
        store.dispatch('user/startPolling');
        startSessionExpiryInterval();
        broadcastMessage(CONST.BROADCAST_MESSAGE_KEEP_ALIVE);
      }

      let patchUserPromise = Promise.resolve();
      let anonymousUser = !store.state.user.hasSession;
      let firstLogin = !anonymousUser && store.getters['user/isFirstLogin'];
      let localeCode = null;
      let timezone = null;
      let lightTheme = null;

      // set default locale information into i18n plugin
      let defaultLocale = store.getters['application/getDefaultLocale'].localeCode;
      i18n.locale = defaultLocale;
      i18n.fallbackLocale = defaultLocale;

      // check if we are operating in kiosk mode
      let kioskPromise = axios.get(cove.getAPI({ name: 'api.kiosk' }));
      kioskPromise.then((response) => {
        store.commit('application/setKioskMode', response.data);
      });

      // if this is a registered user's first login send them directly to the initial registration guide
      if (firstLogin)
      {
        setLocalStorageValue(CONST.BROWSER_STORAGE_NEXT_URL, '/portal/initial-registration');
      }

      // if anonymous or user's first login, load preferences from the browser
      if (anonymousUser || firstLogin)
      {
        localeCode = getBrowserLocale();
        timezone = getBrowserTimezone();
        lightTheme = getBrowserLightTheme();

        if (anonymousUser)
        {
          let cookieLocale = getCookieValue(CONST.COOKIE_LOCALE);
          if (cookieLocale !== undefined)
          {
            localeCode = cookieLocale;
          }
        }

        localeCode = getBestFitLocale(localeCode);

        if (firstLogin)
        {
          patchUserPromise = axios.patch(cove.getAPI({ name: 'api.user.currentuser' }), {
            localeID: _.find(store.state.application.locales, { localeCode }).resourceID,
            timezone,
            lightTheme,
            userPrefApplied: true
          });
        }
      }
      // else this is an existing user, use their account preferences
      else
      {
        localeCode = store.state.user.localeCode;
        timezone = store.state.user.timezone;
        lightTheme = store.state.user.lightTheme;
      }

      // apply the preferences to the system
      changeTimezone(timezone);
      changeTheme(lightTheme);

      // load and set user locale/language. once completed load any additional resources dependent on knowing locale
      let localePromise = changeLocale(localeCode).then(() => {
        return store.dispatch('application/loadCourts');
      });

      // load countries and regions
      let countryPromise = store.dispatch('application/loadCountries');
      let regionPromise = store.dispatch('application/loadRegions');

      // Add Event Listener for tab visibility changes (Page Visibility API)
      if (store.state.user.hasSession)
      {
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'visible')
          {
            store.commit('application/setTabVisible', true);
            // poll immediately when the user switches focus back to the tab
            store.dispatch('user/poll');
            // TODO: future: maybe a backoff in future (cooldown)
          }
          else if (document.visibilityState === 'hidden')
          {
            store.commit('application/setTabVisible', false);
          }
        });
      }

      // once all the final actions have completed flag the app as initialized
      return Promise.all([patchUserPromise, localePromise, countryPromise, regionPromise, kioskPromise])
        .then(() => {
          store.commit('application/initialize', CONST.INITIALIZATION_STATUS_INITIALIZED);
        });
    })
    .catch((error) => {
      console.error(error);
      store.commit('application/initialize', CONST.INITIALIZATION_STATUS_ERRORED);
    });
}