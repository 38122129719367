<template>
  <page-wrapper :title="$t('page.adminFilingSearch.title')">

    <search-result-template :config="config">

      <template #table-title>
        {{ $t('page.adminFilingSearch.table') }}
      </template>

      <!--  Result Values  -->
      <template #v-data-table.item.referenceNumber="{ item }">
        <div style="white-space: nowrap">
          <router-link :to="{ name: 'manage-filing', params: { filingID: item.resourceID } }">
            {{ item.referenceNumber }}
          </router-link>
        </div>
      </template>
      <template #v-data-table.item.caseNumber="{ item }">
        <div v-if="!item.caseNumber">
          {{ $t('global.filing.caseType.newCase') }}
        </div>
        <div v-if="item.caseNumber" style="white-space: nowrap">
          <router-link :to="{ name: 'caseView', params: { courtID: item.courtResourceID, caseUUID: item.caseIdentifier} }">
            {{ item.caseNumber }}
          </router-link>
        </div>
      </template>
      <template #v-data-table.item.filingStatusDisplayName="{ item }">
        <filing-status :filingStatusID="item.filingStatusResourceID"/>
      </template>
      <template #v-data-table.item.filingStatusDate="{ value }">
        <div style="min-width:100px">
          <div>
            {{ value | formatDate }}
          </div>
          <div>
            {{ value | formatTime }}
          </div>
        </div>
      </template>
      <template #v-data-table.item.filedByUsername="{ item }">
        <div style="min-width:100px">
          <div>
            {{ item.filedBySortName }}
          </div>
          <div class="text-minimum-gray">
            {{ item.filedByUsername }}
          </div>
        </div>
      </template>

      <template #nav-drawer-filter-body>
        <v-row no-gutters class="mt-4">
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.referenceNumber"
              :label="$t('filing.referenceNumber')"
              maxlength="100"
              outlined
              hide-details="auto"
              aria-describedby="465c51e6-d38e-4af7-8fab-d4a2e0d9c14d"/>
            <field-description id="465c51e6-d38e-4af7-8fab-d4a2e0d9c14d"
                               message-key="page.filingResults.referenceNumber.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.filedByUsername"
              :label="$t('page.adminFilingSearch.criteria.filedBy.username')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="86c7bbf2-b554-4669-88c0-76e78eeb2b28"/>
            <field-description id="86c7bbf2-b554-4669-88c0-76e78eeb2b28"
                               message-key="page.adminFilingSearch.criteria.filedBy.username.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.filedByFirstName"
              :label="$t('page.adminFilingSearch.criteria.filedBy.firstName')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="738f6fb3-2fca-4446-a2a5-f9d0cbb8dd26"/>
            <field-description id="738f6fb3-2fca-4446-a2a5-f9d0cbb8dd26"
                               message-key="page.adminFilingSearch.criteria.filedBy.firstName.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.filedByLastName"
              :label="$t('page.adminFilingSearch.criteria.filedBy.lastName')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="0ac89a75-3915-4f53-a620-0237b1aee5d7"/>
            <field-description id="0ac89a75-3915-4f53-a620-0237b1aee5d7"
                               message-key="page.adminFilingSearch.criteria.filedBy.lastName.description"/>
          </v-col>
          <v-col v-if="!isSingleCourtMode" cols="12">
            <c-autocomplete
              :items="courts"
              v-model="searchDTO.courtID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('courtCase.court')"
              outlined
              key="courtID"
              hide-details="auto"
              aria-describedby="7adfe410-a662-47d3-bb8d-db767e06f3d9"/>
            <field-description id="7adfe410-a662-47d3-bb8d-db767e06f3d9"
                               message-key="page.filingResults.court.description"/>
          </v-col>
          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.caseNumber"
              :label="$t('courtCase.caseNumber')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="5d7acd9a-129d-4541-8b33-b93fdc9328da"/>
            <field-description id="5d7acd9a-129d-4541-8b33-b93fdc9328da"
                               message-key="page.filingResults.caseNumber.description"/>
          </v-col>
          <v-col cols="12">
            <c-autocomplete
              :items="filingStatuses"
              v-model="searchDTO.filingStatusID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('filing.filingStatus')"
              outlined
              hide-details="auto"
              aria-describedby="89305e9b-f599-4b9f-9aa9-7f7a01959de5"/>
            <field-description id="89305e9b-f599-4b9f-9aa9-7f7a01959de5"
                               message-key="page.filingResults.filingStatus.description"/>
          </v-col>
          <v-col cols="12">
            <date-range-picker
              :label="$t('filing.filingStatusDate')"
              :labelStart="$t('filing.filingStatusDateFrom')"
              :labelEnd="$t('filing.filingStatusDateTo')"
              keyPrefix="filingStatusDate"
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.filingStatusDateChoice"
              :startDate.sync="searchDTO.filingStatusDateStart"
              :endDate.sync="searchDTO.filingStatusDateEnd"
              aria-describedby="40459106-72e6-4c39-8a86-6a70c1bc4764"/>
            <field-description id="40459106-72e6-4c39-8a86-6a70c1bc4764"
                               message-key="page.filingResults.filingStatusDate.description"/>
          </v-col>
        </v-row>
      </template>
    </search-result-template>
  </page-wrapper>
</template>

<script>

import FilingStatus from '@components/support/FilingStatus';
import SearchResultTemplate from '@components/support/template/SearchResultTemplate';
import SearchResultTemplateMixin from '@/mixins/searchResultTemplate';
import _ from 'lodash';
import axios from 'axios';
import { dateToEndOfDay, dateToStartOfDay } from '@/application/application';

export default {
  components: { FilingStatus, SearchResultTemplate },
  mixins: [ SearchResultTemplateMixin ],
  data () {
    return {
      searchDTO: {
        sort: {
          sortBy: 'filingStatusDate',
          sortDesc: true
        },
        referenceNumber: null,
        courtID: null,
        caseNumber: null,
        filingStatusID: null,
        filingStatusDateChoice: null,
        filingStatusDateStart: null,
        filingStatusDateEnd: null,
        filedByUsername: null,
        filedByFirstName: null,
        filedByLastName: null
      },
      filingStatuses: []
    };
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.filing.statuses' }))
      .then((response) => {
        this.filingStatuses = _.defaultTo(response.data._embedded.results, []);
      });
  },
  methods: {
    getItemKey () {
      return 'resourceID';
    },
    getRouteName () {
      return 'admin-filings';
    },
    getSearchAPIName () {
      return 'api.filings';
    },
    getHeaders () {
      let headers = [];

      headers.push({ text: this.$t('filing.referenceNumber'), value: 'referenceNumber', sortable: true });
      if (!this.isSingleCourtMode)
      {
        headers.push({ text: this.$t('courtCase.court'), value: 'courtDisplayName', sortable: true });
      }
      headers.push({ text: this.$t('courtCase.case'), value: 'caseNumber', sortable: true });
      headers.push({ text: this.$t('filing.leadDocketEntry'), value: 'docketEntryExternalName', sortable: false });
      headers.push({ text: this.$t('filing.filingStatus'), value: 'filingStatusDisplayName', sortable: true });
      headers.push({ text: this.$t('filing.filingStatusDate'), value: 'filingStatusDate', sortable: true });
      headers.push({ text: this.$t('filing.filedBy'), value: 'filedByUsername', sortable: true });

      return headers;
    },
    addSearchCriteria (query) {

      if (!_.isNil(this.searchDTO.sort.sortBy))
      {
        query.sort = `${this.searchDTO.sort.sortBy},${this.searchDTO.sort.sortDesc ? 'desc' : 'asc'}`;
      }

      if (!_.isNil(this.searchDTO.referenceNumber))
      {
        query.referenceNumber = this.searchDTO.referenceNumber;
      }

      if (!_.isNil(this.searchDTO.filedByUsername))
      {
        query.filedByUsername = this.searchDTO.filedByUsername;
      }

      if (!_.isNil(this.searchDTO.filedByFirstName))
      {
        query.filedByFirstName = this.searchDTO.filedByFirstName;
      }

      if (!_.isNil(this.searchDTO.filedByLastName))
      {
        query.filedByLastName = this.searchDTO.filedByLastName;
      }

      if (!_.isNil(this.searchDTO.courtID))
      {
        query.courtID = this.searchDTO.courtID;
      }

      if (!_.isNil(this.searchDTO.caseNumber))
      {
        query.caseNumber = this.searchDTO.caseNumber;
      }

      if (!_.isNil(this.searchDTO.filingStatusID))
      {
        query.filingStatusID = this.searchDTO.filingStatusID;
      }

      if (!_.isNil(this.searchDTO.filingStatusDateStart))
      {
        query.filingStatusDateFrom = dateToStartOfDay(this.$cove.parseDate(this.searchDTO.filingStatusDateStart)).toISO();
      }

      if (!_.isNil(this.searchDTO.filingStatusDateEnd))
      {
        query.filingStatusDateTo = dateToEndOfDay(this.$cove.parseDate(this.searchDTO.filingStatusDateEnd)).toISO();
      }

      return query;
    },
    validateSearchCriteria (searchDTO) {
      this.$cove.validation.date.rejectIfInvalid(searchDTO.filingStatusDateStart, 'filing.filingStatusDateFrom',
        'filingStatusDate-startDate');
      this.$cove.validation.date.rejectIfInvalid(searchDTO.filingStatusDateEnd, 'filing.filingStatusDateTo',
        'filingStatusDate-endDate');
      this.$cove.validation.date.rejectIfBefore(searchDTO.filingStatusDateEnd, searchDTO.filingStatusDateStart,
        'filing.filingStatusDateTo', 'filing.filingStatusDateFrom', 'filingStatusDate-endDate');
    },
    isExistingCaseFiling (item) {
      return !_.isNil(item.newCaseFiling) && !item.newCaseFiling;
    }
  }
};
</script>
