<template>
  <page-wrapper :title="$t('kioskMode.title')">
    <v-row>
      <v-col cols="12" md="7" xl="6">
        <validation-error-panel/>

        <v-card>
          <v-card-title>
            {{ $t('global.details') }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('page.kioskMode.description') }}
          </v-card-subtitle>
          <v-card-text class="mt-4">
            <c-text-field
              v-model="password"
              :label="$t('page.kioskMode.field.password')"
              type="password"
              key="password"
              outlined
              required
              :maxlength="passwordMaxLength"
              hide-details="auto"
              aria-describedby="1c7ff877-3d96-49fe-ae59-d1dd10e9790d"/>
            <field-description id="1c7ff877-3d96-49fe-ae59-d1dd10e9790d">
              {{ $t('page.kioskMode.field.password.description', [passwordMinLength, passwordMaxLength]) }}
            </field-description>

            <c-checkbox
              v-model="resetPassword"
              :label="$t('page.kioskMode.field.reset')"
              class="mt-6"
              hide-details="auto"
              aria-describedby="0bf6cd02-b334-42e1-873b-a94ad48a189f"/>
            <field-description id="0bf6cd02-b334-42e1-873b-a94ad48a189f"
                               message-key="page.kioskMode.field.reset.description"/>
          </v-card-text>
          <v-card-actions class="justify-end">
            <c-btn type="primary" @click="enableKioskMode">
              {{ $t('page.kioskMode.button.enable') }}
            </c-btn>
          </v-card-actions>
        </v-card>

      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import axios from 'axios';
import { redirectToLogout } from '@/application/application';
import PageWrapper from '@components/support/PageWrapper';
import FieldDescription from '@components/support/FieldDescription';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import PageMixin from '@/mixins/page';

export default {
  components: { PageWrapper, FieldDescription, ValidationErrorPanel },
  mixins: [ PageMixin ],
  data () {
    return {
      password: null,
      resetPassword: false,
      passwordMinLength: 8,
      passwordMaxLength: 50
    };
  },
  methods: {
    validate () {
      this.$store.commit('cove/errors/clear');

      this.$cove.validation.required(this.password, 'page.kioskMode.field.password', 'password');
      this.$cove.validation.string.minLength(this.password, this.passwordMinLength, 'page.kioskMode.field.password', 'password');
      this.$cove.validation.string.maxLength(this.password, this.passwordMaxLength, 'page.kioskMode.field.password', 'password');

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    enableKioskMode () {
      this.$cove.block({ delay: 0 });

      let isValid = this.validate();
      if (!isValid)
      {
        this.$cove.unblock();
        this.scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
        return;
      }

      axios({
        url: this.$cove.getAPI({ name: 'api.kiosk' }),
        method: this.resetPassword ? 'patch' : 'post',
        data: {
          password: this.password
        }
      })
        .then(() => {
          redirectToLogout();
        })
        .catch(() => {
          this.$cove.unblock();
        });
    }
  }
};
</script>