<template>
  <loading-card :loading="loading">
    <v-card-title>
      {{ $t('page.manageFiling.docketEntry.stepType.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.manageFiling.docketEntry.stepType.description') }}
    </v-card-subtitle>
    <v-card-text class="pt-2">
      <v-row no-gutters>
        <v-col cols="12">
          <c-autocomplete
            v-model="bean.filingType"
            :label="$t('docketEntry.type')"
            :items="filingTypes"
            :item-text="renderDocketEntryName"
            :filter="filterFilingType"
            key="filingType"
            return-object
            required
            outlined
            :disabled="lockType"
            hide-details="auto"
            aria-describedby="669b3f4e-077b-49fb-90e2-976a67845b40">
            <template #item="{ item }">
              <v-list-item-content>
                <div class="d-flex">
                  <div class="flex-grow-1 font-weight-medium">
                    {{ item.name }}
                  </div>
                  <div class="flex-grow-1 text-end text-minimum-gray ml-8">
                    {{ renderDocketEntryName(item) }}
                  </div>
                </div>
              </v-list-item-content>
            </template>
          </c-autocomplete>
          <field-description id="669b3f4e-077b-49fb-90e2-976a67845b40"
                             message-key="page.manageFiling.docketEntry.stepType.field.type.description"/>
        </v-col>
        <v-col v-if="offerExcludeFromService" cols="12">
          <c-checkbox
            v-model="bean.excludeFromService"
            :label="$t('docketEntry.excludeFromService')"
            class="my-0"
            hide-details="auto"
            aria-describedby="af3bddc5-ff9a-4082-bf39-231df2b92ea5"/>
          <field-description id="af3bddc5-ff9a-4082-bf39-231df2b92ea5"
                             message-key="page.manageFiling.docketEntry.stepType.field.excludeFromService.description"/>
        </v-col>
        <v-expand-transition>
          <v-col v-if="showInstructions" cols="12">
            <v-sheet class="pa-3 pt-5 rounded" style="border:1px solid var(--line-color);border-left:8px solid var(--v-info-base);">
              <transition name="fade-transition" mode="out-in">
                <div :key="bean.filingType.portalFilingTypeID">
                  <div class="text-uppercase font-weight-medium">
                    {{ $t('page.manageFiling.instructions.title') }}
                  </div>
                  <div class="mt-4 markdown" v-html="instructionsMarkdown"></div>
                </div>
              </transition>
            </v-sheet>
          </v-col>
        </v-expand-transition>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-end mt-5">
      <c-btn type="primary" @click="save">
        {{ editing ? $t('global.save') : $t('global.next') }}
      </c-btn>
      <c-btn @click="$emit('cancel')">
        {{ $t('global.cancel') }}
      </c-btn>
    </v-card-actions>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { marked } from 'marked';
import WorkflowStepMixin from '@/mixins/workflowStep';

export default {
  mixins: [ WorkflowStepMixin ],
  props: {
    editing: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      filingTypes: []
    };
  },
  computed: {
    courtID () {
      return this.$store.getters['filing/getCourtID'];
    },
    offerExcludeFromService () {
      return this.$store.getters['filing/hasStep'](this.CONST.FILING_STEP_SERVICE);
    },
    lockType () {
      return this.bean.lead || this.editing;
    },
    leadDocketEntry () {
      return this.$store.getters['filing/getLeadDocketEntry'];
    },
    showInstructions () {
      return !_.isNil(this.bean.filingType) && !_.isNil(this.bean.filingType.instruction) && !this.bean.lead;
    },
    instructionsMarkdown () {
      let result = null;
      if (this.showInstructions)
      {
        result = marked.parse(_.escape(this.bean.filingType.instruction), {
          breaks: true
        });
      }

      return result;
    }
  },
  methods: {
    loadData () {
      let promise = null;

      if (this.bean.lead)
      {
        let url = this.$cove.getAPI({
          name: 'api.filing.type',
          params: {
            courtID: this.courtID,
            filingTypeID: this.leadDocketEntry.filingTypeExternalIdentifier
          }
        });

        promise = axios.get(url)
          .then((response) => {
            this.bean.filingType = response.data;
            this.filingTypes = [response.data];
          });
      }
      else
      {
        let url = this.$cove.getAPI({
          name: 'api.filing.types',
          params: {
            courtID: this.courtID
          },
          query: {
            filingCode: this.CONST.FILING_ENTRY_MODE_MANUAL,
            assocdNewCaseFiling: true
          }
        });

        promise = axios.get(url)
          .then((response) => {
            let filingTypes = _.defaultTo(response.data, []);
            this.filingTypes = _.sortBy(filingTypes, 'name');

            // if we are editing we have to pre-select the correct dropdown item
            if (this.editing)
            {
              this.bean.filingType = _.find(this.filingTypes,
                { portalFilingTypeID: this.bean.filingType.portalFilingTypeID });
            }
          });
      }

      return promise;
    },
    renderDocketEntryName (filingType) {
      let docketEntrySubtype = filingType.docketEntrySubtype;
      return docketEntrySubtype.docketEntryType.docketEntryTypeName + ' - ' + docketEntrySubtype.docketEntrySubTypeName;
    },
    filterFilingType (filingType, queryText) {
      let searchText = filingType.name.toLowerCase() + ' ' + this.renderDocketEntryName(filingType).toLowerCase();
      return searchText.indexOf(queryText.toLowerCase()) >= 0;
    },
    validate () {
      this.$store.commit('cove/errors/clear');

      this.$cove.validation.required(this.bean.filingType, 'docketEntry.type', 'filingType');

      return !this.$store.getters['cove/errors/hasErrors']();
    },
    save () {
      let isValid = this.validate();
      if (!isValid)
      {
        this.scrollTo(this.CONST.ERROR_CONTAINER_SELECTOR);
      }
      else
      {
        this.$emit('save', this.bean);
      }
    }
  }
};
</script>