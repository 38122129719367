<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text style="min-height:85px;">
      <div class="d-flex">
        <div style="flex: 1 1 auto">
          {{ text }}
        </div>
        <div style="flex: 0 0 auto">
          <div class="pa-2 ml-4" style="border:1px solid var(--v-icon-base);border-radius:100px">
            <v-icon large color="icon">
              {{ icon }}
            </v-icon>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="justify-end">
      <slot name="action"/>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    text: {
      type: String
    },
    icon: {
      type: String
    }
  }
};
</script>