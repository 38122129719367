import PagingControls from '@components/support/PagingControls';
import { isTableSortClickEvent } from '@/application/utility';

export default {
  components: { PagingControls },
  props: {
    title: {
      type: String
    },
    page: {
      type: Object
    },
    sort: {
      type: Object
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean
    },
    items: {
      type: Array
    },
    headers: {
      type: Array
    },
    courtID: {
      type: String
    },
    caseInstanceUUID: {
      type: String
    },
    isVerifiedParty: {
      type: Boolean
    }
  },
  methods: {
    toggleDrawer () {
      this.$emit('toggleDrawer');
    },
    changePage (newPage) {
      this.$emit('changePage', newPage);
    },
    updateOptions (options) {
      if (isTableSortClickEvent())
      {
        let sortBy = options.sortBy[0];
        let sortDesc = options.sortDesc[0];
        this.$emit('changeSort', { sortBy, sortDesc });
      }
    }
  }
};