<template>
  <workflow-wrapper>
    <filing-info-summary v-if="view === 'summary'" key="summary" @edit="edit" @next="$emit('next')"/>
    <div v-else-if="view === 'edit'" key="edit">
      <div class="mx-auto" style="max-width:675px">
        <div class="mb-1" style="font-size:1.4rem">
          {{ $t('page.manageFiling.filingInfo.editFilingInfo') }}
        </div>
        <validation-error-panel/>
        <step-edit :originalBean="filingToEditFilingInfoBean()" @save="saveFilingInfo" @cancel="cancel"/>
      </div>
    </div>
  </workflow-wrapper>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import WorkflowWrapper from '@components/support/WorkflowWrapper';
import FilingInfoSummary from '@components/support/manageFiling/filingInfo/FilingInfoSummary';
import StepEdit from '@components/support/manageFiling/filingInfo/StepEdit';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';

export default {
  components: { WorkflowWrapper, StepEdit, FilingInfoSummary, ValidationErrorPanel },
  data () {
    return {
      view: 'summary'
    };
  },
  computed: {
    filing () {
      return this.$store.state.filing.filing;
    }
  },
  watch: {
    view (newValue) {
      if (newValue === 'edit')
      {
        this.$store.dispatch('filing/toggleSteps', { visible: false });
      }
      else
      {
        this.$store.dispatch('filing/toggleSteps', { visible: true, refresh: true });
      }
    }
  },
  methods: {
    edit () {
      this.view = 'edit';
    },
    filingToEditFilingInfoBean () {
      let clonedFOBO = _.cloneDeep(this.filing.filedOnBehalfOf);
      let otherFOBO = _.remove(clonedFOBO, (f) => { return !_.isNil(f.otherName); });
      let filedByOther = _.get(otherFOBO[0], 'otherName', null);

      return {
        filing: this.filing,
        filedOnBehalfOf: clonedFOBO,
        filedByOther
      };
    },
    saveFilingInfo (bean) {
      this.$cove.block({ delay: 0 });

      let filedOnBehalfOf = [];
      if (!_.isNil(bean.filedByOther))
      {
        filedOnBehalfOf.push({ otherName: bean.filedByOther });
      }

      if (!_.isEmpty(bean.filedOnBehalfOf))
      {
        bean.filedOnBehalfOf.forEach((filerCandidate) => {
          if (filerCandidate.newCaseParty)
          {
            filedOnBehalfOf.push({ casePartyID: filerCandidate.uuid });
          }
          else
          {
            filedOnBehalfOf.push({
              existingCasePartyID: filerCandidate.uuid
            });
          }
        });
      }

      let patchBean = {
        referenceName: bean.filing.referenceName,
        confidential: bean.filing.confidential,
        filingNote: bean.filing.filingNote,
        filedOnBehalfOf
      };

      let url = this.$cove.getAPI({ name: 'api.filing', params: { id: this.filing.resourceID } });

      axios.patch(url, patchBean)
        .then(() => {
          this.view = 'summary';
          this.$store.dispatch('filing/loadFiling');
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    cancel () {
      this.$cove.confirm({ message: this.$t('global.confirmation.cancel.edit') })
        .then((confirm) => {
          if (confirm)
          {
            this.view = 'summary';
          }
        });
    }
  }
};
</script>