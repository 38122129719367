<template>
  <loading-card :loading="false" class="nav-tab-small">
    <v-card-title>
      {{ $t('page.currentUser.section.pref.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.currentUser.section.pref.description') }}
    </v-card-subtitle>
    <v-card-text class="mt-4 pb-0">
      <form>
        <v-row no-gutters>
          <v-col cols="12">
            <c-autocomplete :items="locales"
                            v-model="locale"
                            :label="$t('page.currentUser.field.locale')"
                            item-text="displayName"
                            outlined
                            return-object
                            hide-details="auto"
                            :clearable="false"
                            aria-describedby="a37f85f16-a9ea-4c13-bf18-ea57887f587f"/>
            <field-description id="a37f85f16-a9ea-4c13-bf18-ea57887f587f" message-key="page.currentUser.field.locale.description"/>
          </v-col>
          <v-col cols="12">
            <c-autocomplete :items="timezones"
                            v-model="timezone"
                            :label="$t('page.currentUser.field.timezone')"
                            outlined
                            hide-details="auto"
                            :clearable="false"
                            aria-describedby="f417e791-6c28-4d5f-998a-215f38238dbb"/>
            <field-description id="f417e791-6c28-4d5f-998a-215f38238dbb" message-key="page.currentUser.field.timezone.description"/>
          </v-col>
          <v-col cols="12">
            <c-autocomplete :items="themeOptions"
                            v-model="lightTheme"
                            :label="$t('page.currentUser.field.theme')"
                            :clearable="false"
                            outlined
                            hide-details="auto"
                            aria-describedby="a1c6307f7-ee81-49f5-ad11-a35c5cb4667e"/>
            <field-description id="a1c6307f7-ee81-49f5-ad11-a35c5cb4667e" message-key="page.currentUser.field.theme.description"/>
          </v-col>
          <v-col v-if="!isSingleCourtMode" cols="12">
            <c-autocomplete :items="courts"
                            v-model="court"
                            :label="$t('page.currentUser.field.court')"
                            item-text="displayName"
                            return-object
                            outlined
                            hide-details="auto"
                            aria-describedby="de235f43-96e4-480f-be38-4d104fa65ded"/>
            <field-description id="de235f43-96e4-480f-be38-4d104fa65ded" message-key="page.currentUser.field.court.description"/>
          </v-col>
        </v-row>
      </form>
    </v-card-text>
    <v-card-actions class="justify-end">
      <c-btn type="primary" class="px-4" @click="save">
        {{ $t('application.button.save') }}
      </c-btn>
    </v-card-actions>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import timezones from '@/application/timezones';
import LoadingCard from '@components/support/LoadingCard';
import FieldDescription from '@components/support/FieldDescription';
import { changeLocale, changeTimezone, changeTheme, changeCourt } from '@/application/application';

export default {
  components: { LoadingCard, FieldDescription },
  data () {
    return {
      locale: null,
      timezone: null,
      lightTheme: null,
      court: null
    };
  },
  computed: {
    user () {
      return this.$store.state.user;
    },
    locales () {
      return this.$store.state.application.locales;
    },
    timezones () {
      return timezones;
    },
    themeOptions () {
      return [
        { text: this.$t('page.currentUser.theme.dark'), value: false },
        { text: this.$t('page.currentUser.theme.light'), value: true }
      ];
    },
    courts () {
      return this.$store.state.application.courts;
    },
    isSingleCourtMode () {
      return this.$store.getters['application/isSingleCourtMode'];
    },
    selectedCourtID () {
      return this.court !== null ? this.court.resourceID : null;
    }
  },
  created () {
    this.locale = _.find(this.locales, { localeCode: this.user.localeCode });
    this.timezone = this.user.timezone;
    this.lightTheme = this.user.lightTheme;

    if (this.user.defaultCourtID !== null)
    {
      this.court = _.find(this.courts, { resourceID: this.user.defaultCourtID });
    }
  },
  methods: {
    toPreferencesBean () {
      return {
        localeID: this.locale.resourceID,
        timezone: this.timezone,
        lightTheme: this.lightTheme,
        courtID: this.selectedCourtID
      };
    },
    save () {
      this.$cove.block({ delay: 0 });
      axios.patch(this.$cove.getAPI({ name: 'api.user.currentuser' }), this.toPreferencesBean())
        .then(() => {
          changeTimezone(this.timezone);
          changeTheme(this.lightTheme);
          changeCourt(this.selectedCourtID);
          return changeLocale(this.locale.localeCode);
        })
        .then(() => {
          this.$store.dispatch('user/poll'); // may have to update announcement indicator if they changed locales
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
        })
        .finally(() => {
          this.$cove.unblock();
        });
    }
  }
};
</script>