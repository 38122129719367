import CONST from '@/application/constants';
import { redirectToLogout, checkForSessionExpiry } from '@/application/application';
import store from '@/plugins/store';

const CHANNEL = new BroadcastChannel(CONST.BROADCAST_CHANNEL_PRIMARY);

/**
 * Broadcast a message to all other open tabs/windows. Accepts an optional payload which can be anything you want to attach to the message.
 *
 * @param {String} messageName
 * @param {*} payload
 */
export function broadcastMessage (messageName, payload = null)
{
  CHANNEL.postMessage({ messageName, payload });
}

/**
 * Listeners to all incoming messages and delegates them to the appropriate handlers.
 *
 * @param {Event} event
 */
CHANNEL.onmessage = (event) => {
  let messageName = event.data.messageName;
  // no messages need the payload yet, but may in the future
  // let payload = event.data.payload;

  // check for session as well here since you could have a mix of auth and anon tabs (not likely, but possible) and there is no need to
  // logout of an anon tab
  if (messageName === CONST.BROADCAST_MESSAGE_LOGOUT && (store.state.user.hasSession || store.getters['application/isKioskMode']))
  {
    redirectToLogout({ broadcast: false });
  }
  else if (messageName === CONST.BROADCAST_MESSAGE_KEEP_ALIVE)
  {
    checkForSessionExpiry();
  }
};