<template>
  <loading-card :loading="purchasedDocuments === null">
    <v-card-title>
      <slot name="title">
        {{ $t('page.receiptPurchasedDocuments.title') }}
      </slot>
    </v-card-title>
    <v-card-subtitle class="pb-2">
      <slot name="subtitle">
        {{ $t('page.receiptPurchasedDocuments.subtitle') }}
      </slot>
    </v-card-subtitle>
    <v-card-text class="pa-0">
      <v-data-table
        :items="purchasedDocuments"
        :headers="headers"
        class="mt-2"
        item-key="resourceID"
        hide-default-footer
        disable-pagination
        :mobile-breakpoint="800">

        <template #item.caseNumber="{ item }">
          <div style="white-space: nowrap">
            <router-link :to="{ name: 'caseView', params: {
              courtID: item.court.resourceID,
              caseUUID: item.caseIdentifier }}">
              {{ item.caseNumber }}
            </router-link>
          </div>
          <div>
            {{ item.caseTitle }}
          </div>
        </template>
        <template #item.docketEntryFiledDate="{ value }">
          <div>
            {{ value | formatDate }}
          </div>
          <div>
            {{ value | formatTime }}
          </div>
        </template>
        <template #item.pageCount="{ value }">
          <div v-if="value > 0">
            {{ value }}
          </div>
        </template>
        <template #item.documentLinkIdentifier="{ item }">
          <v-btn icon color="primary" @click="openDocumentPopup(item)">
            <span class="d-sr-only">
              {{ $t('application.button.viewDocument') }}
            </span>
            <v-icon>mdi-file</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <document-popup v-model="documentPopupVisible"
                    :courtID="courtID"
                    :caseInstanceUUID="caseInstanceUUID"
                    :documentLinkUUID="documentLinkUUID"/>

  </loading-card>
</template>

<script>
import DocumentPopup from '@components/support/DocumentPopup';
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { DocumentPopup, LoadingCard },
  props: {
    purchasedDocuments: {
      type: Array
    }
  },
  data () {
    return {
      // document popup
      documentPopupVisible: false,
      courtID: null,
      caseInstanceUUID: null,
      documentLinkUUID: null
    };
  },
  computed: {
    headers () {
      let headers = [];

      if (!this.isSingleCourtMode) {
        headers.push({ text: this.$t('courtCase.court'), value: 'court.displayName', sortable: false });
      }
      headers.push({ text: this.$t('courtCase.case'), value: 'caseNumber', sortable: false });
      headers.push({
        text: this.$t('purchasedDocuments.docketEntryExternalName'),
        value: 'docketEntryExternalName',
        sortable: false
      });
      headers.push({ text: this.$t('purchasedDocument.docketEntryFiledDate'), value: 'docketEntryFiledDate', sortable: false });
      headers.push({ text: this.$t('purchasedDocument.documentName'), value: 'documentName', sortable: false });
      headers.push({ text: this.$t('purchasedDocument.pageCount'), value: 'pageCount', sortable: false });
      headers.push({ text: this.$t('page.receiptPurchasedDocuments.viewHeader'), value: 'documentLinkIdentifier', sortable: false,
        align: 'right' });

      return headers;
    },
    isSingleCourtMode () {
      return this.$store.getters['application/isSingleCourtMode'];
    }
  },
  methods: {
    viewDocument (documentLinkIdentifier) {
      this.$cove.notify({
        message: 'Document view not yet implemented. DocumentLinkIdentifier: ' + documentLinkIdentifier,
        color: 'warning'
      });
    },
    openDocumentPopup (purchasedDocument) {
      this.courtID = purchasedDocument.court.resourceID;
      this.caseInstanceUUID = purchasedDocument.caseIdentifier;
      this.documentLinkUUID = purchasedDocument.documentLinkIdentifier;
      this.documentPopupVisible = true;
    }
  }
};
</script>