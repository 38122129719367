var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"items":_vm.items,"headers":_vm.headers,"sort-by":_vm.searchDTO.sort.sortBy,"sort-desc":_vm.searchDTO.sort.sortDesc,"server-items-length":_vm.searchDTO.page.totalElements,"item-key":"searchResultKey","must-sort":"","hide-default-footer":"","mobile-breakpoint":800},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"c-caption pt-2 pl-3",staticStyle:{"flex":"1 1 auto"}},[_vm._v(" "+_vm._s(_vm.$t('page.searchResults.document.table'))+" ")]),_c('div',{staticClass:"text-right pt-2 pr-2 d-print-none",staticStyle:{"flex":"1 1 auto"}},[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":_vm.toggleDrawer}},[_c('v-icon',[_vm._v(" mdi-filter ")]),_c('span',{staticClass:"d-sr-only"},[_vm._v(" "+_vm._s(_vm.$t('global.table.filterAndSort'))+" ")])],1)],1)])]},proxy:true},{key:"item.caseHeader.caseNumber",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'caseView', params: { courtID: _vm.getCourtByExternalID(item.caseHeader.courtID).resourceID,
                                                      caseUUID: item.caseHeader.caseInstanceUUID} }}},[_vm._v(" "+_vm._s(item.caseHeader.caseNumber)+" ")])]}},{key:"item.docketEntryFiledDate",fn:function(ref){
                                                      var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]}},{key:"item.highlightsMap",fn:function(ref){
                                                      var value = ref.value;
return [(value !== undefined && value !== null)?_c('div',{staticStyle:{"min-width":"150px","max-width":"250px"},domProps:{"innerHTML":_vm._s(_vm.formatHighlights(value))}}):_vm._e()]}},{key:"item.documentLinkUUID",fn:function(ref){
                                                      var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.openDocumentPopup(_vm.getCourtByExternalID(item.caseHeader.courtID).resourceID,
                                                            item.caseHeader.caseInstanceUUID,
                                                            item.documentLinkUUID)}}},[_c('span',{staticClass:"d-sr-only"},[_vm._v(" "+_vm._s(_vm.$t('application.button.viewDocument'))+" ")]),_c('v-icon',[_vm._v("mdi-file")])],1)]}},{key:"footer",fn:function(){return [_c('paging-controls',{attrs:{"page":_vm.searchDTO.page},on:{"changePage":_vm.changePage}})]},proxy:true}])}),_c('document-popup',{attrs:{"courtID":_vm.courtID,"caseInstanceUUID":_vm.caseInstanceUUID,"documentLinkUUID":_vm.documentLinkUUID},model:{value:(_vm.documentPopupVisible),callback:function ($$v) {_vm.documentPopupVisible=$$v},expression:"documentPopupVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }