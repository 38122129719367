<template>
  <page-wrapper :title="$t('page.manageFiling.title')" :subtitle="subtitle" fluid>
    <div class="mb-4 d-md-none">
      <filing-sidebar @removeFiling="removeFiling" @submitFiling="submitFiling" @makeCorrections="makeCorrections"/>
    </div>
    <div class="d-flex">
      <div style="flex: 1 1 auto">
        <loading-area :loading="filingType === null" :height="500">
          <template #default>
            <filing-instructions/>
            <filing-feedback/>
            <template v-if="isFullFiling">
              <filing-content/>
            </template>
            <template v-else-if="isAbbreviatedFiling">
              <abbreviated-filing-content/>
            </template>
          </template>
        </loading-area>
      </div>
      <div class="d-none d-md-block pl-4" style="flex: 0 0 280px">
        <filing-sidebar @removeFiling="removeFiling" @submitFiling="submitFiling" @makeCorrections="makeCorrections"/>
      </div>
    </div>
  </page-wrapper>
</template>

<script>
import _ from 'lodash';
import PageWrapper from '@components/support/PageWrapper';
import PageMixin from '@/mixins/page';
import LoadingArea from '@components/support/LoadingArea';
import FilingSidebar from '@components/support/manageFiling/FilingSidebar';
import FilingInstructions from '@components/support/manageFiling/FilingInstructions';
import FilingContent from '@components/support/manageFiling/FilingContent';
import FilingFeedback from '@components/support/manageFiling/FilingFeedback';
import AbbreviatedFilingContent from '@components/support/manageFiling/abbreviated/AbbreviatedFilingContent';
import Filing from '@/application/filing';

export default {
  components: { AbbreviatedFilingContent, PageWrapper, FilingSidebar, FilingInstructions, FilingContent, FilingFeedback, LoadingArea },
  mixins: [ PageMixin ],
  props: {
    filingID: {
      type: String,
      required: true
    }
  },
  computed: {
    filing () {
      return this.$store.state.filing.filing;
    },
    filingType () {
      return this.$store.state.filing.filingType;
    },
    subtitle () {
      return this.filing !== null ? this.filing.referenceName : null;
    },
    allStepValidationErrors () {
      return this.$store.getters['filing/getAllStepValidationErrors'];
    },
    isFullFiling () {
      return this.filing.filingCodeExternalIdentifier !== this.CONST.FILING_CODE_EXTERNAL_IDENTIFIER_ABBREVIATED;
    },
    isAbbreviatedFiling () {
      return this.filing.filingCodeExternalIdentifier === this.CONST.FILING_CODE_EXTERNAL_IDENTIFIER_ABBREVIATED;
    },
    isAbbreviatedFilingReadyForSubmission () {
      return Filing.isAbbreviatedFilingReadyForSubmission(this.filing);
    }
  },
  created () {
    this.$store.commit('filing/reset');
    this.$store.dispatch('filing/load', { filingID: this.filingID });
  },
  methods: {
    removeFiling () {
      this.$cove.confirm({ message: this.$t('page.manageFiling.sidebar.removeFiling.warning') })
        .then((confirm) => {
          if (confirm)
          {
            this.$cove.block({ delay: 0 });
            this.$store.dispatch('filing/remove')
              .then(() => {
                this.$cove.notify({ message: this.$t('application.remove.success'), color: 'success' });
                this.$router.push({ name: 'work-filings' });
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    },
    makeCorrections () {
      this.$cove.confirm({ message: this.$t('page.manageFiling.sidebar.makeCorrections.confirm') })
        .then((confirm) => {
          if (confirm)
          {
            this.$cove.block({ delay: 0 });
            this.$store.dispatch('filing/clone')
              .then((newFilingID) => {
                this.$cove.notify({ message: this.$t('page.manageFiling.sidebar.makeCorrections.success'), color: 'success' });
                this.$router.push({ name: 'manage-filing', params: { filingID: newFilingID } });
                this.$store.commit('application/refreshView');
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    },
    submitFiling () {
      if ( (this.isFullFiling && !_.isEmpty(this.allStepValidationErrors))
          || (this.isAbbreviatedFiling && !this.isAbbreviatedFilingReadyForSubmission) )
      {
        this.$cove.notify({ message: this.$t('page.manageFiling.submit.incompleteSteps'), color: 'error' });
      }
      else
      {
        this.$cove.confirm({ message: this.$t('page.manageFiling.submit.confirm') })
          .then((confirm) => {
            if (confirm)
            {
              this.$cove.block({ delay: 0 });
              this.$store.dispatch('filing/submit')
                .then(() => {
                  this.$cove.notify({ message: this.$t('page.manageFiling.submit.success'), color: 'success' });
                })
                .then(() => {
                  this.$store.commit('filing/setSelectedStepID', this.CONST.FILING_STEP_SUMMARY);
                  return this.$store.dispatch('filing/loadFiling');
                })
                .finally(() => {
                  this.$cove.unblock();
                });
            }
          });
      }
    }
  }
};
</script>