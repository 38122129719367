import Vue from 'vue';
import './application/devtools'; // must be immediately after Vue is imported
import App from './App.vue';
import store from './plugins/store';
import vuetify from './plugins/vuetify';
import router from './plugins/router';
import i18n from './plugins/i18n';
import initialize from './application/initialize';
import './plugins/cove';
import './application/ajax';
import './application/api';
import '@/styles/styles.scss';

Vue.config.productionTip = false;

new Vue({
  store,
  vuetify,
  router,
  i18n,
  render: (h) => {
    return h(App);
  }
}).$mount('#app');

initialize();
