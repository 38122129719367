<template>
  <div>
    <loading-card :loading="loading">
      <template #default>
        <v-card-title>
          {{ $t('page.manageOrg.users.title') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('page.manageOrg.users.description') }}
        </v-card-subtitle>
        <v-card-text class="pa-0">
          <v-data-table
            :items="items"
            :headers="headers"
            :sort-by="paging.sortBy"
            :sort-desc="paging.sortDesc"
            :server-items-length="paging.totalElements"
            :loading="loadingTable"
            item-key="resourceID"
            must-sort
            hide-default-footer
            :mobile-breakpoint="800"
            @update:options="paging.updateOptions">
            <template #item.payment="{ value }">
              <v-icon v-if="value">
                mdi-check
              </v-icon>
              <span class="d-sr-only">
                {{ value ? $t('global.yes') : $t('global.no') }}
              </span>
            </template>
            <template #item.subscription="{ value }">
              <v-icon v-if="value">
                mdi-check
              </v-icon>
              <span class="d-sr-only">
                {{ value ? $t('global.yes') : $t('global.no') }}
              </span>
            </template>
            <template #item.admin="{ value }">
              <v-icon v-if="value">
                mdi-check
              </v-icon>
              <span class="d-sr-only">
                {{ value ? $t('global.yes') : $t('global.no') }}
              </span>
            </template>
            <template #item.resourceID="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-horizontal</v-icon>
                    <span class="d-sr-only">{{ $t('global.action') }}</span>
                  </v-btn>
                </template>
                <v-list dense style="min-width:100px">
                  <v-list-item class="inset-focus" @click="editUser(item)">
                    <v-list-item-title>
                      {{ $t('global.edit') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="!isCurrentUser(item)" class="inset-focus" @click="removeUser(item)">
                    <v-list-item-title>
                      {{ $t('global.remove') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template #footer>
              <paging-controls-new :paging="paging"/>
            </template>
          </v-data-table>

          <div class="text-right pa-4">
            <c-btn type="primary" @click="addUserPopupVisible = true;">
              {{ $t('page.manageOrg.users.addUser') }}
            </c-btn>
          </div>
        </v-card-text>
      </template>
    </loading-card>

    <add-user-popup v-model="addUserPopupVisible" :organizationID="organizationID" @newUserAdded="refreshTable"/>
    <edit-user-popup v-model="editUserPopupVisible" :userMapID="selectedUserMapID" @userEdited="refreshTable"/>

  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import LoadingCard from '@components/support/LoadingCard';
import AddUserPopup from '@components/support/manageOrg/AddUserPopup';
import EditUserPopup from '@components/support/manageOrg/EditUserPopup';
import JSURL from 'jsurl';
import { replaceIgnoreDuplicate } from '@/application/utility';
import PagingControlsNew from '@components/support/PagingControlsNew';
import Paging from '@/application/paging';

export default {
  components: { LoadingCard, PagingControlsNew, AddUserPopup, EditUserPopup },
  props: {
    organizationID: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      loadingTable: false,
      addUserPopupVisible: false,
      editUserPopupVisible: false,
      selectedUserMapID: null,
      items: [],
      paging: new Paging({
        size: 25,
        sortBy: 'user.actor.sortName',
        sortDesc: false,
        searchCallback: this.loadUsers
      })
    };
  },
  computed: {
    headers () {
      let headers = [
        { text: this.$t('page.manageOrg.users.column.name'), value: 'user.actor.sortName' },
        { text: this.$t('page.manageOrg.users.column.username'), value: 'user.username' },
        // can't sort by email since it can't be traversed through the hibernate bean
        { text: this.$t('page.manageOrg.users.column.email'), value: 'user.actor.contacts.primaryEmail', sortable: false },
        { text: this.$t('page.manageOrg.users.column.payment'), value: 'payment', sortable: false, align: 'center' }
      ];

      if (this.isEnableSubscriptionFunctions)
      {
        headers.push({ text: this.$t('page.manageOrg.users.column.subscription'), value: 'subscription', sortable: false,
          align: 'center' });
      }

      headers.push({ text: this.$t('page.manageOrg.users.column.admin'), value: 'admin', sortable: false, align: 'center' });
      headers.push({ text: this.$t('global.action'), value: 'resourceID', align: 'right', sortable: false });

      return headers;
    },
    isEnableSubscriptionFunctions () {
      return this.$store.getters['application/getAppProperty'](this.CONST.FEATURE_PROPERTY_ENABLE_SUBSCRIPTION_FUNCTIONS);
    }
  },
  created () {
    let criteria = JSURL.parse(_.defaultTo(this.$route.query.criteria, ''));
    this.paging.parseURLParams(criteria);

    this.loadUsers(false)
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    loadUsers (updateURL = true) {
      if (updateURL)
      {
        // updates the browser URL so we can restore the paging state if the user navigates back
        replaceIgnoreDuplicate({
          name: 'manage-org-users',
          params: {
            organizationID: this.organizationID
          },
          query: {
            criteria: JSURL.stringify(this.paging.generateParams())
          }
        });
      }

      let url = this.$cove.getAPI({
        name: 'api.organization.users',
        query: {
          organizationID: this.organizationID,
          fields: '*,user(*,actor(*))',
          ...this.paging.generateParams()
        }
      });

      this.loadingTable = true;
      return axios.get(url)
        .then((response) => {
          this.items = _.get(response, 'data._embedded.results', []);
          this.paging.parseResponse(response);
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    editUser (userMap) {
      this.selectedUserMapID = userMap.resourceID;
      this.editUserPopupVisible = true;
    },
    removeUser (userMap) {
      this.$cove.confirm({ message: this.$t('page.manageOrg.users.confirmRemove') })
        .then((confirm) => {
          if (confirm)
          {
            this.$cove.block({ delay: 0 });
            let url = this.$cove.getAPI({ name: 'api.organization.user', params: { id: userMap.resourceID } });
            axios.delete(url)
              .then(() => {
                this.$cove.notify({ message: this.$t('application.remove.success'), color: 'success' });
                this.refreshTable();
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    },
    refreshTable () {
      this.paging.page = 0;
      this.loadUsers();
    },
    isCurrentUser (userMap) {
      return userMap.user.resourceID === this.$store.state.user.userID;
    }
  }
};
</script>