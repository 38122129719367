<template>
  <div>
    <transition name="slide-y-transition" appear>
      <filing-tabs v-show="stepsVisible" class="mb-5"/>
    </transition>

    <workflow-wrapper>
      <party-tab v-if="selectedStepID === CONST.FILING_STEP_PARTIES"
                 :key="`partyTab-${tabNonce}`"
                 @next="next"/>
      <filing-info-tab v-if="selectedStepID === CONST.FILING_STEP_FILING_INFO"
                       :key="`filingInfoTab-${tabNonce}`"
                       @next="next"/>
      <docket-entry-tab v-if="selectedStepID === CONST.FILING_STEP_DOCKET_ENTRIES"
                        :key="`docketEntryTab-${tabNonce}`"
                        @next="next"/>
      <service-tab v-if="selectedStepID === CONST.FILING_STEP_SERVICE"
                   :key="`serviceTab-${tabNonce}`"
                   @next="next"/>
      <summary-tab v-if="selectedStepID === CONST.FILING_STEP_SUMMARY"
                   :key="`summaryTab-${tabNonce}`"/>
    </workflow-wrapper>

  </div>
</template>

<script>
import WorkflowWrapper from '@components/support/WorkflowWrapper';
import PartyTab from '@components/support/manageFiling/PartyTab';
import FilingInfoTab from '@components/support/manageFiling/FilingInfoTab';
import DocketEntryTab from '@components/support/manageFiling/DocketEntryTab';
import ServiceTab from '@components/support/manageFiling/ServiceTab';
import SummaryTab from '@components/support/manageFiling/SummaryTab';
import FilingTabs from '@components/support/manageFiling/FilingTabs';

export default {
  components: { PartyTab, WorkflowWrapper, FilingTabs, FilingInfoTab, DocketEntryTab, ServiceTab, SummaryTab },
  computed: {
    stepsVisible () {
      return this.$store.state.filing.stepsVisible;
    },
    selectedStepID () {
      return this.$store.getters['filing/getSelectedStepID'];
    },
    tabNonce () {
      return this.$store.state.filing.tabNonce;
    }
  },
  methods: {
    next () {
      this.$store.commit('filing/nextStep');
    }
  }
};
</script>