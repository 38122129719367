<template>
  <c-dialog :value="value"
            width="850"
            persistent
            title-key="popup.configErrors.title"
            close-key="cove.general.close"
            @input="close">
    <div class="pa-5">
      <v-alert
        :value="true"
        class="mb-2 rounded-0"
        icon="mdi-alert-circle"
        color="error"
        outlined
        rounded
        text>
        <div class="font-weight-medium" style="font-size:1.1rem;line-height:1.5">
          {{ $t('global.validationErrors') }}
        </div>
        <div class="mt-2">
          {{ $t('popup.configErrors.description') }}
        </div>
      </v-alert>
    </div>

    <v-simple-table>
      <caption>
        {{ $t('popup.configErrors.table.title') }}
      </caption>
      <thead>
        <tr>
          <th scope="col">
            {{ $t('popup.configErrors.col.sheet') }}
          </th>
          <th scope="col">
            {{ $t('popup.configErrors.col.row') }}
          </th>
          <th scope="col">
            {{ $t('popup.configErrors.col.message') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(rows, ws) in errors">
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <tr v-for="e in rows">
            <td>{{ ws }}</td>
            <td>
              <div>{{ e.rowNum }}</div>
              <div class="text-minimum-gray" style="font-family:monospace">{{ e.rowId }}</div>
            </td>
            <td class="break-word">
              <div>{{ e.errorMessage }}</div>
              <div class="text-minimum-gray" style="font-family:monospace">{{ e.errorDetails }}</div>
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
  </c-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean
    },
    errors: {
      type: Object
    }
  },
  methods: {
    close () {
      this.$emit('input', false);
    }
  }
};
</script>