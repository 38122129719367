<template>
  <div>
    <v-simple-table v-for="(cg, i) in caseGroups" class="elevation-2 mb-6" :key="i">
      <caption>
        {{ cg.groupType }}
      </caption>
      <thead>
        <tr>
          <th v-if="!isSingleCourtMode" scope="col">
            {{ $t('popup.caseGroups.column.court') }}
          </th>
          <th scope="col">
            {{ $t('popup.caseGroups.column.caseNumber') }}
          </th>
          <th scope="col">
            {{ $t('popup.caseGroups.column.caseTitle') }}
          </th>
          <th scope="col">
            {{ $t('popup.caseGroups.column.classification') }}
          </th>
          <th v-if="hasLeadCase(cg)" scope="col" class="text-center">
            {{ $t('popup.caseGroups.column.leadCase') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="cg.caseLinks.length === 0">
          <td colspan="100" class="text-center text-minimum-gray font-italic">
            {{ $t('popup.caseGroups.emptyGroup') }}
          </td>
        </tr>

        <tr v-for="(link, i) in cg.caseLinks" :key="i">
          <td v-if="!isSingleCourtMode">{{ getCourt(link.courtID).displayName }}</td>
          <td>
            <router-link
              @click.native="$emit('navigating')"
              :to="{ name: 'caseView', params: { courtID: getCourt(link.courtID).resourceID, caseUUID: link.caseInstanceUUID}}">
              {{ link.caseNumber }}
            </router-link>
          </td>
          <td>{{ link.caseTitle }}</td>
          <td>{{ link.caseClassification }}</td>
          <td v-if="hasLeadCase(cg)" class="text-center">
            <template v-if="link.leadCaseFlag">
              <v-icon>
                mdi-check
              </v-icon>
              <span class="d-sr-only">
                {{ $t('global.yes') }}
              </span>
            </template>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    caseGroups: {
      type: Array
    }
  },
  computed: {
    isSingleCourtMode () {
      return this.$store.getters['application/isSingleCourtMode'];
    }
  },
  methods: {
    hasLeadCase (caseGroup) {
      return !_.isNil(_.find(caseGroup.caseLinks, { leadCaseFlag: true }));
    },
    getCourt (externalCourtID) {
      return this.$store.getters['application/getCourtByExternalID'](externalCourtID);
    }
  }
};
</script>