<template>
  <div>
    <v-data-table
      :items="items"
      :headers="headers"
      :sort-by="searchDTO.sort.sortBy"
      :sort-desc="searchDTO.sort.sortDesc"
      :server-items-length="searchDTO.page.totalElements"
      item-key="searchResultKey"
      must-sort
      hide-default-footer
      class="elevation-2"
      :mobile-breakpoint="800"
      @update:options="updateOptions">
      <template #top>
        <div class="d-flex align-center">
          <div class="c-caption pt-2 pl-3" style="flex:1 1 auto">
            {{ $t('page.searchResults.calendar.table') }}
          </div>
          <div class="text-right pt-2 pr-2 d-print-none" style="flex:1 1 auto">
            <div class="text-right pt-2 pr-2 d-print-none" style="flex:1 1 auto">
              <v-btn icon color="primary" @click="toggleDrawer">
                <v-icon>
                  mdi-filter
                </v-icon>
                <span class="d-sr-only">
                  {{ $t('global.table.filterAndSort') }}
                </span>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
      <template #item.startDate="{ item }">
        <button class="link-button" @click="openHearingsPopup(getCourtByExternalID(item.courtID).resourceID, item.eventUUID)">
          {{ item.startDate | formatDateTime }}
        </button>
      </template>
      <template #item.judgeActorInstances="{ item }">
        <template v-if="item.panelFlag === false">
          <div v-for="judge in item.judgeActorInstances" :key="judge.sortName">
            {{ judge.sortName }}
          </div>
        </template>
      </template>
      <template #footer>
        <paging-controls :page="searchDTO.page" @changePage="changePage"/>
      </template>
    </v-data-table>

    <hearings-popup v-model="hearingsPopupVisible" :courtID="selectedCourtID" :eventID="selectedEventID"/>

  </div>
</template>

<script>
import SearchResultsMixin from '@/mixins/searchResults';
import HearingsPopup from '@components/support/search/HearingsPopup';

export default {
  mixins: [ SearchResultsMixin ],
  components: { HearingsPopup },
  data () {
    return {
      selectedCourtID: null,
      selectedEventID: null,
      hearingsPopupVisible: false
    };
  },
  methods: {
    openHearingsPopup (courtID, eventID) {
      this.selectedCourtID = courtID;
      this.selectedEventID = eventID;
      this.hearingsPopupVisible = true;
    }
  }
};
</script>