<template>
  <loading-card :loading="loading" class="nav-tab-medium">
    <template #default>
      <v-card-title>
        {{ $t('page.currentUser.orgDetails.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('page.currentUser.orgDetails.description') }}
      </v-card-subtitle>
      <v-card-text>
        <dl class="d-grid my-3">
          <dt>
            {{ $t('page.manageOrg.orgDetails.name') }}
          </dt>
          <dd>
            {{ organization.organizationDetails.sortName }}
          </dd>
          <dt>
            {{ $t('page.manageOrg.orgDetails.type') }}
          </dt>
          <dd>
            <div v-for="at in organization.organizationDetails.actorTypeList" :key="at.actorTypeID">
              {{ at.actorTypeName }}
            </div>
          </dd>
          <dt>
            {{ $t('page.manageOrg.orgDetails.address') }}
          </dt>
          <dd>
            <render-address :formattedAddress="organization.organizationDetails.formattedAddress"/>
          </dd>
          <dt>
            {{ $t('page.manageOrg.orgDetails.administrators') }}
          </dt>
          <dd>
            <div v-for="admin in organization.organizationDetails.organizationAdministrators"
                 :key="admin">
              {{ admin }}
            </div>
          </dd>
        </dl>
      </v-card-text>
      <v-card-actions class="justify-end">
        <c-btn type="danger" @click="leaveOrganization">
          {{ $t('page.currentUser.orgDetails.leaveOrg') }}
        </c-btn>
      </v-card-actions>
    </template>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import RenderAddress from '@components/support/RenderAddress';
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { LoadingCard, RenderAddress },
  data () {
    return {
      loading: true,
      organization: null
    };
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.user.currentuser', query: { fields: '*,organization(*,organizationDetails(*))' } }))
      .then((response) => {
        this.organization = _.get(response, 'data.organization', null);

        // if this user doesn't belong to an org, don't show this card at all
        if (this.organization === null)
        {
          this.$router.replace({ name: 'user-profile' });
        }
        else
        {
          this.loading = false;
        }
      })
      .catch(() => {
        this.loading = false;
      });
  },
  methods: {
    leaveOrganization () {
      this.$cove.confirm({ message: this.$t('page.currentUser.orgDetails.confirmLeaveOrg') })
        .then((confirm) => {
          if (confirm)
          {
            this.$cove.block({ delay: 0 });

            let url = this.$cove.getAPI({ name: 'api.organization.user', params: { id: this.organization.organizationUserMapID } });
            axios.delete(url)
              .then(() => {
                this.$router.replace({ name: 'user-profile', params: { reloadNav: true } });
                this.$cove.notify({ message: this.$t('page.currentUser.orgDetails.leaveOrg.success'), color: 'success' });
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    }
  }
};
</script>