<template>
  <page-wrapper :title="$t('page.subscriptionMgmt.title')">
    <nav-tabs :items="navTabs"/>
  </page-wrapper>
</template>

<script>
import PageMixin from '@/mixins/page';
import PageWrapper from '@components/support/PageWrapper';
import NavTabs from '@components/support/NavTabs';

export default {
  components: { PageWrapper, NavTabs },
  mixins: [ PageMixin ],
  data () {
    return {
      navTabs: [
        { name: this.$t('page.subscriptionMgmt.activeSubs.title'), routeName: 'admin-subscription-active' },
        { name: this.$t('page.subscriptionMgmt.processSubs.title'), routeName: 'admin-subscription-process' },
        { name: this.$t('page.subscriptionMgmt.renewalHistory.title'), routeName: 'admin-subscription-history' }
      ]
    };
  }
};
</script>
