<template>
  <page-wrapper :title="$t('page.myServices.title')">

    <search-result-template :config="config" ref="searchResultTemplate">
      <template #table-title>
        {{ $t('page.myServices.table.title') }}
      </template>

      <template #v-data-table.item.readFlag="{ value }">
        <div v-if="value">
          <v-icon>mdi-email-open</v-icon>
          <span class="d-sr-only">{{ $t('global.read') }}</span>
        </div>
        <div v-if="!value">
          <v-icon color="primary">mdi-email</v-icon>
          <span class="d-sr-only">{{ $t('global.unread') }}</span>
        </div>
      </template>

      <template #v-data-table.item.receivedDate="{ value }">
        {{ value | formatDate }}
      </template>

      <template #v-data-table.item.caseNumber="{ item }">
        <div>
          <template v-if="item.caseIdentifier">
            <router-link :to="{ name: 'caseView', params: { courtID: item.courtID, caseUUID: item.caseIdentifier } }">
              {{ item.caseNumber }}
            </router-link>
          </template>
          <template v-else>
            {{ $t('global.filing.caseType.newCase') }}
          </template>
        </div>
        <div v-if="item.caseIdentifier">
          {{ getCaseTitle(item) }}
        </div>
      </template>

      <template #v-data-table.item.filingStatusDate="{ value }">
        <div>
          {{ value | formatDate }}
        </div>
        <div>
          {{ value | formatTime }}
        </div>
      </template>

      <template #v-data-table.item.leadDocketEntryExternalName="{ item }">
        <router-link :to="{ name: 'work-service', params: { serviceID: item.filingServiceID } }">
          {{ item.leadDocketEntryExternalName }}
        </router-link>
      </template>

      <template #v-data-table.item.filingStatusDisplayName="{ item }">
        <filing-status :filingStatusID="item.filingStatusID"/>
      </template>

      <!-- Search Criteria -->
      <template #nav-drawer-filter-body>
        <v-row no-gutters class="mt-4">
          <v-col v-if="!isSingleCourtMode" cols="12">
            <c-autocomplete
              :items="courts"
              v-model="searchDTO.courtID"
              item-text="displayName"
              item-value="resourceID"
              :label="$t('courtCase.court')"
              outlined
              hide-details="auto"
              aria-describedby="a8916c57-ef96-4acb-94c0-ddac6a61434f"/>
            <field-description id="a8916c57-ef96-4acb-94c0-ddac6a61434f"
                               message-key="page.caseResults.court.description"/>
          </v-col>

          <v-col cols="12">
            <c-text-field
              v-model="searchDTO.caseNumber"
              :label="$t('courtCase.caseNumber')"
              maxlength="250"
              outlined
              hide-details="auto"
              aria-describedby="aa08d02b-f472-453d-b51d-4df5a2ed8fee"/>
            <field-description id="aa08d02b-f472-453d-b51d-4df5a2ed8fee"
                               message-key="page.myServices.criteria.caseNumber.description"/>
          </v-col>

          <v-col cols="12">
            <date-range-picker
              :label="$t('filingService.serviceDate')"
              :labelStart="$t('page.myServices.criteria.serviceDateFrom')"
              :labelEnd="$t('page.myServices.criteria.serviceDateTo')"
              keyPrefix="receivedDate"
              :inputAttributes="{ outlined: true }"
              :choice.sync="searchDTO.receivedDateChoice"
              :startDate.sync="searchDTO.receivedDateStart"
              :endDate.sync="searchDTO.receivedDateEnd"
              aria-describedby="a71bef23-5845-48b1-b74b-050333aea029"/>
            <field-description id="a71bef23-5845-48b1-b74b-050333aea029"
                               message-key="page.myServices.criteria.serviceDate.description"/>
          </v-col>

          <v-col cols="12">
            <c-checkbox
              v-model="searchDTO.showUnreadOnly"
              class="mt-0 pt-0"
              :label="$t('page.myServices.criteria.unreadOnly')"
              aria-describedby="af300044-448d-4267-85cd-1fd4a11af92e"/>
            <field-description id="af300044-448d-4267-85cd-1fd4a11af92e"
                               message-key="page.myServices.criteria.unreadOnly.description"/>
          </v-col>
        </v-row>
      </template>

    </search-result-template>

  </page-wrapper>
</template>

<script>
import _ from 'lodash';
import SearchResultTemplate from '@components/support/template/SearchResultTemplate';
import SearchResultTemplateMixin from '@/mixins/searchResultTemplate';
import FilingStatus from '@components/support/FilingStatus';
import { dateToEndOfDay, dateToStartOfDay } from '@/application/application';
import { DateTime } from 'luxon';

export default {
  components: { SearchResultTemplate, FilingStatus },
  mixins: [ SearchResultTemplateMixin ],
  data () {
    return {
      searchDTO: {
        sort: {
          sortBy: 'receivedDate',
          sortDesc: true
        },
        courtID: null,
        caseNumber: null,
        showUnreadOnly: null,
        receivedDateChoice: '-3m',
        receivedDateStart: this.$cove.formatDate(DateTime.local().minus({ 'month': 3 })),
        receivedDateEnd: this.$cove.formatDate(DateTime.local())
      }
    };
  },
  methods: {
    getItemKey () {
      return 'filingServiceID';
    },
    getRouteName () {
      return 'work-services';
    },
    getSearchAPIName () {
      return 'api.filing.services.user';
    },
    getHeaders () {
      let headers = [
        { text: this.$t('page.myServices.table.read'), value: 'readFlag', width: '80px', sortable: true },
        { text: this.$t('filingService.serviceDate'), value: 'receivedDate', sortable: true }
      ];

      if (!this.isSingleCourtMode)
      {
        headers.push({ text: this.$t('courtCase.court'), value: 'courtDisplayName', sortable: true });
      }

      headers.push({ text: this.$t('courtCase.case'), value: 'caseNumber', sortable: true });
      headers.push({ text: this.$t('courtCase.caseClassification'), value: 'caseClassificationName', sortable: true });
      headers.push({ text: this.$t('filing.leadDocketEntry'), value: 'leadDocketEntryExternalName', sortable: true });
      headers.push({ text: this.$t('page.myServices.table.filingStatus'), value: 'filingStatusDisplayName', sortable: true });

      return headers;
    },
    addSearchCriteria (query) {
      if (!_.isNil(this.searchDTO.sort.sortBy))
      {
        query.sort = `${this.searchDTO.sort.sortBy},${this.searchDTO.sort.sortDesc ? 'desc' : 'asc'}`;
      }

      if (!_.isNil(this.searchDTO.courtID))
      {
        query.courtID = this.searchDTO.courtID;
      }

      if (!_.isNil(this.searchDTO.caseNumber))
      {
        query.caseNumber = this.searchDTO.caseNumber;
      }

      if (!_.isNil(this.searchDTO.receivedDateStart))
      {
        query.dateReceivedFrom = dateToStartOfDay(this.$cove.parseDate(this.searchDTO.receivedDateStart)).toISO();
      }

      if (!_.isNil(this.searchDTO.receivedDateEnd))
      {
        query.dateReceivedTo = dateToEndOfDay(this.$cove.parseDate(this.searchDTO.receivedDateEnd)).toISO();
      }

      if (!_.isNil(this.searchDTO.showUnreadOnly))
      {
        query.showUnreadOnly = this.searchDTO.showUnreadOnly;
      }

      return query;
    },
    getCaseTitle (item) {
      let filerAccessible = item.filerCaseAccessibleFlag === true;
      return filerAccessible ? item.caseTitle : this.$t('courtCase.secure');
    },
    validateSearchCriteria (searchDTO)
    {
      this.$cove.validation.date.rejectIfInvalid(searchDTO.receivedDateStart, 'page.myServices.criteria.serviceDateFrom',
        'receivedDate-startDate');
      this.$cove.validation.date.rejectIfInvalid(searchDTO.receivedDateEnd, 'page.myServices.criteria.serviceDateTo',
        'receivedDate-endDate');
      this.$cove.validation.date.rejectIfBefore(searchDTO.receivedDateEnd, searchDTO.receivedDateStart,
        'page.myServices.criteria.serviceDateTo', 'page.myServices.criteria.serviceDateFrom', 'receivedDate-endDate');
    }
  }
};
</script>
