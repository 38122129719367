import _ from 'lodash';
import { fetchCurrentUser, getLocalStorageValue, setLocalStorageValue } from '@/application/application';
import { redirectNotFoundErrorHandler } from '@/application/ajax';
import axios from 'axios';
import CONST from '@/application/constants';
import CountryPhone from '@/application/countryPhone';
import cove from '@ctrack/cove';
import Vue from 'vue';
import Vuex from 'vuex';
import { DateTime } from 'luxon';

Vue.use(Vuex);

const application = {
  namespaced: true,
  state () {
    return {
      initializationStatus: CONST.INITIALIZATION_STATUS_PENDING,
      locales: [],
      courts: [],
      appProperties: {},
      courtToLocationsMap: {}, // key,value (courtResourceID, Locations): Maps courtResourceID to a list of locations
      fileTypes: {},
      countries: [],
      regions: [],
      kioskMode: false,
      routerViewKey: 0, // used on main router-view outlet to forcefully refresh the page/component via route configuration
      previousRoute: null, // cache the previous route, but only when the component/page changes (ignores push/replace on current component)
      serverErrorPopupVisible: false,
      serverErrorDetails: null,
      serverValidationErrorPopupVisible: false,
      userInactivityPopupVisible: false,
      tabVisible: true // Indicates if the tab is currently visible (not minimized, is the selected tab, or when not in background(mobile))
    };
  },
  getters: {
    isSingleCaseLocationMode (state) {
      // true if no court can have more than one case location
      let isSingleCaseLocationMode = true;

      // eslint-disable-next-line consistent-return
      _.forEach(state.courtToLocationsMap, (locations, key) => {
        // Locations per court
        if (locations.length > 1)
        {
          let caseLocationCount = 0;

          // eslint-disable-next-line consistent-return
          _.forEach(locations, (location) => {
            if (location.caseLocationFlag)
            {
              caseLocationCount++;
              if (caseLocationCount > 1)
              {
                // terminate loop early (lodash)
                return false;
              }
            }
          });

          if (caseLocationCount > 1)
          {
            isSingleCaseLocationMode = false;
            // terminate loop early (lodash)
            return false;
          }
        }

      });

      return isSingleCaseLocationMode;
    },
    isSingleCalendarLocationMode (state) {
      // true if no court can have move than one cal location
      let isSingleCalendarLocationMode = true;

      // eslint-disable-next-line consistent-return
      _.forEach(state.courtToLocationsMap, (locations, key) => {
        // Locations per court
        if (locations.length > 1)
        {
          let calendarLocationCount = 0;

          // eslint-disable-next-line consistent-return
          _.forEach(locations, (location) => {
            if (location.calendarEventFlag)
            {
              calendarLocationCount++;
              if (calendarLocationCount > 1)
              {
                // terminate loop early (lodash)
                return false;
              }
            }
          });

          if (calendarLocationCount > 1)
          {
            isSingleCalendarLocationMode = false;
            // terminate loop early (lodash)
            return false;
          }
        }

      });

      return isSingleCalendarLocationMode;
    },
    getCaseLocationsByCourt (state)
    {
      return (courtResourceID) => {
        return _.filter(state.courtToLocationsMap[courtResourceID], { caseLocationFlag: true });
      };
    },
    getCalendarLocationsByCourt (state) {
      return (courtResourceID) => {
        return _.filter(state.courtToLocationsMap[courtResourceID], { calendarEventFlag: true });
      };
    },
    isInitialized (state) {
      return state.initializationStatus !== CONST.INITIALIZATION_STATUS_PENDING;
    },
    isSingleCourtMode (state) {
      return state.courts.length === 1;
    },
    getLocaleByCode (state) {
      return (localeCode) => {
        return _.find(state.locales, { localeCode });
      };
    },
    getDefaultLocale (state) {
      return _.find(state.locales, { defaultFlag: true });
    },
    getCountries (state) {
      return state.countries;
    },
    getCountry (state) {
      return (countryID) => {
        return _.defaultTo(_.find(state.countries, { countryID }), null);
      };
    },
    getDefaultCountry (state) {
      return _.find(state.countries, { defaultFlag: true });
    },
    getDefaultPhoneCountry (state, getters) {
      let defaultCountry = getters.getDefaultCountry;
      return _.defaultTo(_.find(CountryPhone, { iso: defaultCountry.countryAbbreviation }), null);
    },
    getRegionsByCountry (state) {
      return (countryID) => {
        return _.sortBy(_.filter(state.regions, ['country.countryID', countryID]), 'regionName');
      };
    },
    getDefaultRegionByCountry (state, getters) {
      return (countryID) => {
        let regions = getters.getRegionsByCountry(countryID);
        return _.defaultTo(_.find(regions, { defaultFlag: true }), null);
      };
    },
    getRegion (state) {
      return (regionID) => {
        return _.defaultTo(_.find(state.regions, { regionID }), null);
      };
    },
    isInitialPageLoad (state) {
      return state.previousRoute.name === null;
    },
    getCourtByID (state) {
      return (courtID) => {
        return _.find(state.courts, { resourceID: courtID });
      };
    },
    getCourtByExternalID (state) {
      return (externalCourtID) => {
        return _.find(state.courts, { externalIdentifier: externalCourtID });
      };
    },
    getDefaultCourtID (state, getters, rootState) {
      let defaultCourtID = rootState.user.defaultCourtID;
      if (defaultCourtID === null && getters.isSingleCourtMode)
      {
        defaultCourtID = state.courts[0].resourceID;
      }

      return defaultCourtID;
    },
    getAppProperty (state, propertyKey)
    {
      return (propertyKey) => {
        let value = _.get(state.appProperties, propertyKey, null);
        if (_.isNil(value))
        {
          throw new Error('Could not find app property: ' + propertyKey);
        }

        return value;
      };
    },
    isKioskMode (state) {
      return state.kioskMode;
    },
    isTabVisible (state) {
      return state.tabVisible;
    },
    isTabHidden (state) {
      return !state.tabVisible;
    }
  },
  mutations: {
    initialize (state, status) {
      state.initializationStatus = status;
    },
    loadLocales (state, locales) {
      state.locales = _.sortBy(locales, 'displayName');
    },
    loadCourts (state, courts) {
      state.courts = _.sortBy(courts, 'displayName');
      _.forEach(courts, (court) => {
        Vue.set(state.courtToLocationsMap, court.resourceID, _.sortBy(court.locations, 'locationName'));
      });
    },
    loadFileTypes (state, { documentType, fileTypes }) {
      Vue.set(state.fileTypes, documentType, fileTypes);
    },
    loadCountries (state, countries) {
      state.countries = countries;
    },
    loadRegions (state, regions) {
      state.regions = regions;
    },
    setPreviousRoute (state, from) {
      state.previousRoute = from;
    },
    refreshView (state) {
      state.routerViewKey++;
    },
    showServerErrorPopup (state, payload) {
      state.serverErrorDetails = payload;
      state.serverErrorPopupVisible = true;
    },
    closeServerErrorPopup (state) {
      state.serverErrorPopupVisible = false;
    },
    showServerValidationErrorPopup (state) {
      state.serverValidationErrorPopupVisible = true;
    },
    closeServerValidationErrorPopup (state) {
      state.serverValidationErrorPopupVisible = false;
    },
    loadAppProperties (state, appProperties) {
      state.appProperties = appProperties;
    },
    setKioskMode (state, kioskMode) {
      state.kioskMode = kioskMode;
    },
    setTabVisible (state, isTabVisible) {
      state.tabVisible = isTabVisible;
    },
    setUserInactivityPopupVisible (state, visible) {
      state.userInactivityPopupVisible = visible;
    }
  },
  actions: {
    loadLocales (context) {
      return axios.get(cove.getAPI({ name: 'api.application.locales', query: { size: CONST.PAGING_MAX_SIZE } }))
        .then((response) => {
          context.commit('loadLocales', _.get(response, 'data._embedded.results', []));
        });
    },
    loadCourts (context) {
      return axios.get(cove.getAPI({ name: 'api.courts', query: { size: CONST.PAGING_MAX_SIZE, fields: '*,locations(*)' } }))
        .then((response) => {
          context.commit('loadCourts', _.get(response, 'data._embedded.results', []));
        });
    },
    loadCountries (context) {
      return axios.get(cove.getAPI({ name: 'api.countries' }))
        .then((response) => {
          context.commit('loadCountries', response.data);
        });
    },
    loadRegions (context) {
      return axios.get(cove.getAPI({ name: 'api.regions' }))
        .then((response) => {
          context.commit('loadRegions', response.data);
        });
    },
    loadFileTypes (context, documentType) {
      let promise = Promise.resolve();
      if (context.state.fileTypes[documentType] === undefined)
      {
        let url = cove.getAPI({ name: 'api.dms.document.types', query: { documentType } });
        promise = axios.get(url)
          .then((response) => {
            context.commit('loadFileTypes', { documentType, fileTypes: response.data });
          });
      }

      return promise;

    },
    loadAppProperties (context) {
      return axios.get(cove.getAPI({ name: 'api.application.properties' })).then((response) => {
        context.commit('loadAppProperties', _.get(response, 'data', {}));
      });
    }
  }
};

const user = {
  namespaced: true,
  state () {
    return {
      hasSession: false,
      userID: null,
      externalIdentifier: null, // CMS actor instance UUID
      displayName: null,
      sortName: null,
      firstName: null,
      middleName: null,
      lastName: null,
      prefix: null,
      suffix: null,
      emailAddress: null,
      carbonCopyEmails: [],
      smsPhoneNumber: null,
      contactPhoneNumber: null,
      mailingAddress: null,
      username: null,
      localeCode: null,
      timezone: null,
      lightTheme: true,
      defaultCourtID: null,
      userPrefApplied: false,
      interpreterInfo: null,
      userTypes: [],
      roles: [ CONST.ROLE_ANONYMOUS ],
      pollingIntervalID: null,
      notifications: {
        unreadAnnouncements: false,
        unreadNotifications: false,
        unreadServices: false,
        unpurchasedDocumentsInCart: false
      },
      pollingHeartbeat: DateTime.local()
    };
  },
  getters: {
    hasRole (state) {
      return (role) => {
        return _.includes(state.roles, role);
      };
    },
    hasUserType (state) {
      return (userTypeID) => {
        return !_.isNil(_.find(state.userTypes, ['resourceID', userTypeID]));
      };
    },
    isAdmin (state) {
      return _.includes(state.roles, CONST.ROLE_ADMINISTRATOR);
    },
    isFirstLogin (state) {
      return !state.userPrefApplied;
    },
    getLanguage (state) {
      let language = null;
      if (state.localeCode !== null)
      {
        language = state.localeCode.substring(0, 2);
      }

      return language;
    },
    hasUnreadAnnouncements (state) {
      return state.notifications.unreadAnnouncements;
    },
    hasUnreadNotifications (state) {
      return state.notifications.unreadNotifications;
    },
    hasUnreadServices (state) {
      return state.notifications.unreadServices;
    },
    hasUnpurchasedDocumentsInCart (state) {
      return state.notifications.unpurchasedDocumentsInCart;
    },
    isUserIdle (state, getters, rootState, rootGetters) {
      // return a function so that the isUserIdle is computed even if the heartbeat is not updated
      return () => {
        let isUserIdle = false;
        if (state.pollingHeartbeat !== null)
        {
          let currentDateTime = DateTime.local();

          // subtract 1000 milliseconds to account for the interval executing slightly behind the polling rate (~500-600 ms)
          let timeSinceLastHeartbeatMilliseconds = currentDateTime.diff(state.pollingHeartbeat).toObject().milliseconds - 1000;
          let idleTimeThreshold = rootGetters['application/getAppProperty'](CONST.APP_PROPERTY_POLLING_TIME_TO_IDLE);

          if (timeSinceLastHeartbeatMilliseconds >= idleTimeThreshold)
          {
            isUserIdle = true;
          }
        }

        return isUserIdle;
      };
    }
  },
  mutations: {
    login (state) {
      state.hasSession = true;
    },
    loadUser (state, user) {
      state.userID = user.resourceID;
      state.externalIdentifier = _.defaultTo(user.actor.externalIdentifier, null);
      state.username = user.username;
      state.displayName = user.actor.displayName;
      state.sortName = user.actor.sortName;
      state.firstName = user.actor.firstName;
      state.middleName = _.defaultTo(user.actor.middleName, null);
      state.lastName = user.actor.lastName;
      state.prefix = _.defaultTo(user.actor.prefix, null);
      state.suffix = _.defaultTo(user.actor.suffix, null);
      state.emailAddress = user.actor.contacts.primaryEmail;
      state.carbonCopyEmails = _.defaultTo(user.actor.contacts.carbonCopyEmails, []);
      state.smsPhoneNumber = _.defaultTo(user.actor.contacts.smsPhoneNumber, null);
      state.contactPhoneNumber = _.defaultTo(user.actor.contacts.contactPhoneNumber, null);
      state.mailingAddress = _.defaultTo(user.actor.mailingAddress, null);
      state.localeCode = user.locale.localeCode;
      state.timezone = user.timezone;
      state.lightTheme = user.lightTheme;
      state.defaultCourtID = user.court !== undefined ? user.court.resourceID : null;
      state.userPrefApplied = user.userPrefApplied; // we primarily use this to tell if it's a user's first login or not
      state.interpreterInfo = _.defaultTo(user.interpreterInfo, null);
      state.userTypes = _.defaultTo(user.userTypes, []);
      state.roles = user.roles;
    },
    setLocaleCode (state, localeCode) {
      state.localeCode = localeCode;
    },
    setTimezone (state, timezone) {
      state.timezone = timezone;
    },
    setTheme (state, lightTheme) {
      state.lightTheme = lightTheme;
    },
    setCourt (state, courtID) {
      state.defaultCourtID = courtID;
    },
    setPollingIntervalID (state, intervalID) {
      state.pollingIntervalID = intervalID;
    },
    clearPollingIntervalID (state) {
      clearInterval(state.pollingIntervalID);
    },
    setNotifications (state, notifications) {
      state.notifications.unreadAnnouncements = notifications.unreadAnnouncements;
      state.notifications.unreadNotifications = notifications.unreadNotifications;
      state.notifications.unreadServices = notifications.unreadServices;
      state.notifications.unpurchasedDocumentsInCart = notifications.unpurchasedDocumentsInCart;
    },
    updatePollingHeartbeat (state) {
      state.pollingHeartbeat = DateTime.local();
    }
  },
  actions: {
    login (context) {
      let promise = Promise.resolve();
      if (!context.state.hasSession)
      {
        promise = fetchCurrentUser().then((user) => {
          if (user !== null)
          {
            context.commit('login');
            context.commit('loadUser', user);
          }
        });
      }

      return promise;
    },
    reload (context) {
      return fetchCurrentUser().then((user) => {
        if (user !== null)
        {
          context.commit('loadUser', user);
        }
      });
    },
    startPolling (context) {
      context.dispatch('poll');
      let interval = context.rootGetters['application/getAppProperty'](CONST.APP_PROPERTY_POLLING_INTERVAL);
      if (_.isNil(interval) || interval < CONST.POLLING_INTERVAL_MINIMUM_MS)
      {
        console.warn('Configured polling interval of \'' + interval + ' ms\' is too small; Polling interval must be greater than or' +
            ' equal to ' + CONST.POLLING_INTERVAL_MINIMUM_MS + ' milliseconds.  Setting polling interval to '
            + CONST.POLLING_INTERVAL_MINIMUM_MS + ' milliseconds' );

        interval = CONST.POLLING_INTERVAL_MINIMUM_MS;
      }
      let pollingIntervalID = setInterval(() => { context.dispatch('poll'); }, interval);
      context.commit('setPollingIntervalID', pollingIntervalID);
    },
    stopPolling (context) {
      context.commit('clearPollingIntervalID');
    },
    poll (context) {
      let isUserIdle = context.getters.isUserIdle();
      let isTabVisible = context.rootGetters['application/isTabVisible'];
      let shouldExecuteAjax = !isUserIdle && isTabVisible;

      let result = Promise.resolve();
      if (shouldExecuteAjax)
      {
        result = axios.get(cove.getAPI({ name: 'api.user.currentuser.pollingdata' }),
          {
            errorHandlers: {
              400: (error) => { handlePollingErrorResponse(context, error); },
              401: (error) => { handlePollingErrorResponse(context, error); },
              403: (error) => { handlePollingErrorResponse(context, error); },
              404: (error) => { handlePollingErrorResponse(context, error); },
              500: (error) => { handlePollingErrorResponse(context, error); }
            },
            skipHeartbeat: true
          })
          .then((response) => {
            context.commit('setNotifications', response.data);
          });
      }

      return result;
    }
  }
};

const filing = {
  namespaced: true,
  state () {
    return {
      filingID: null,
      filing: null,
      filingType: null,
      filingSteps: [],
      selectedStepIndex: -1,
      stepsVisible: true,
      caseClassification: null,
      maxSeenStepID: null,
      tabNonce: 0 // track a nonce we can use to force refresh a tab if clicked when already active
    };
  },
  getters: {
    getCourtID (state) {
      return state.filing.court.resourceID;
    },
    getFilingTypeID (state) {
      return state.filing.leadDocketEntry.filingTypeExternalIdentifier;
    },
    getLeadDocketEntry (state) {
      return state.filing.leadDocketEntry;
    },
    getFilingSteps (state) {
      return state.filingSteps;
    },
    getFiledOnBehalfOf (state) {
      return state.filing.filedOnBehalfOf;
    },
    getCaseClassGroupID (state) {
      return state.caseClassification.caseClassificationGroup.caseClassificationGroupID;
    },
    getAllStepValidationErrors (state) {
      let errors = [];
      state.filingSteps.forEach((step) => {
        errors = errors.concat(step.validationMessages);
      });

      return errors;
    },
    getSelectedStepID (state) {
      let result = null;
      if (!_.isEmpty(state.filingSteps) && state.selectedStepIndex >= 0)
      {
        result = state.filingSteps[state.selectedStepIndex].filingStepID;
      }

      return result;
    },
    isLocked (state) {
      return state.filing.filingStatus.resourceID !== CONST.FILING_STATUS_DRAFT;
    },
    isStepComplete (state) {
      return (filingStepID) => {
        let step = _.find(state.filingSteps, { filingStepID });
        let messages = step.validationMessages;
        return _.isArray(messages) && messages.length === 0;
      };
    },
    isNewCaseFiling (state) {
      return state.filing.newCaseFiling === true;
    },
    hasSeenStep (state) {
      let maxStepIndex = _.findIndex(state.filingSteps, { filingStepID: state.maxSeenStepID });
      return maxStepIndex >= state.selectedStepIndex;
    },
    isFilerCaseAccessible (state) {
      return state.filing.courtCase.filerCaseAccessible === true;
    },
    hasStep (state) {
      return (filingStepID) => {
        return !_.isNil(_.find(state.filingSteps, { filingStepID }));
      };
    }
  },
  mutations: {
    setFilingID (state, filingID) {
      state.filingID = filingID;
    },
    setFiling (state, filing) {
      state.filing = filing;
    },
    setFilingType (state, filingType) {
      state.filingType = filingType;
    },
    setCaseClassification (state, caseClassification) {
      state.caseClassification = caseClassification;
    },
    setFilingSteps (state, filingSteps) {
      state.filingSteps = filingSteps;
    },
    setSelectedStepIndex (state, newIndex) {
      state.selectedStepIndex = newIndex;
    },
    setSelectedStepID (state, stepID) {
      let index = _.findIndex(state.filingSteps, { filingStepID: stepID });
      state.selectedStepIndex = index;
    },
    setStepsVisible (state, visible) {
      state.stepsVisible = visible;
    },
    _setMaxSeenStep (state, stepID) {
      state.maxSeenStepID = stepID;
    },
    setMaxSeenStep (state, stepID) {
      let mapObject = _.defaultTo(getLocalStorageValue(CONST.BROWSER_STORAGE_FILING_MAX_SEEN_STEP), {});
      let map = new Map(Object.entries(mapObject));
      map.set(state.filingID, stepID);

      if (map.size > CONST.BROWSER_STORAGE_FILING_MAX_SEEN_STEP_LENGTH)
      {
        let oldestKey = map.keys().next().value;
        map.delete(oldestKey);
      }

      state.maxSeenStepID = stepID;
      setLocalStorageValue(CONST.BROWSER_STORAGE_FILING_MAX_SEEN_STEP, Object.fromEntries(map));
    },
    nextStep (state) {
      state.selectedStepIndex++;
    },
    refreshTab (state) {
      state.tabNonce++;
    },
    reset (state) {
      state.filingID = null;
      state.filing = null;
      state.filingType = null;
      state.filingSteps = [];
      state.selectedStepIndex = -1;
      state.stepsVisible = true;
      state.caseClassification = null;
      state.maxSeenStepID = null;
    }
  },
  actions: {
    load (context, { filingID }) {
      context.commit('setFilingID', filingID);

      let stepsPromise = context.dispatch('loadSteps');

      let mapObject = _.defaultTo(getLocalStorageValue(CONST.BROWSER_STORAGE_FILING_MAX_SEEN_STEP), {});
      let map = new Map(Object.entries(mapObject));
      let maxStepID = _.defaultTo(map.get(filingID), null);
      context.commit('_setMaxSeenStep', maxStepID);

      let filingPromise =
        context.dispatch('loadFiling')
          .then(() => {
            let promises = [];
            let courtID = context.getters.getCourtID;
            let filingTypeID = context.getters.getFilingTypeID;
            let caseClassID = context.state.filing.courtCase.caseClassificationIdentifier;
            let filingTypeURL = cove.getAPI({ name: 'api.filing.type', params: { courtID, filingTypeID } });
            let caseClassURL = cove.getAPI({ name: 'api.court.case.classification', params: { courtID, caseClassID } });

            promises.push(axios.get(filingTypeURL)
              .then((response) => {
                context.commit('setFilingType', response.data);
              })
            );

            promises.push(axios.get(caseClassURL)
              .then((response) => {
                context.commit('setCaseClassification', response.data);
              })
            );

            return Promise.all(promises);

          });

      return Promise.all([stepsPromise, filingPromise])
        .then(() => {
          if (context.getters.isLocked)
          {
            context.commit('setSelectedStepID', CONST.FILING_STEP_SUMMARY);
          }
        });
    },
    loadFiling (context) {
      let filingURL = cove.getAPI({
        name: 'api.filing',
        params: { id: context.state.filingID },
        // eslint-disable-next-line max-len
        query: { fields: '**,court(*),courtCase(*),leadDocketEntry(*, documents(*)),filedOnBehalfOf(*,existingCaseParty(*),caseParty(*,actor(*)))' }
      });

      return axios.get(filingURL, { errorHandlers: { 404: redirectNotFoundErrorHandler } })
        .then((response) => {
          let filing = response.data;
          context.commit('setFiling', filing);
        });
    },
    loadSteps (context) {
      let url = cove.getAPI({
        name: 'api.filing.steps',
        params: { id: context.state.filingID }
      });

      return axios.get(url, { errorHandlers: { 404: redirectNotFoundErrorHandler } })
        .then((response) => {
          context.commit('setFilingSteps', response.data);
        });
    },
    toggleSteps (context, { visible, refresh }) {
      let promise = Promise.resolve();
      if (refresh)
      {
        promise = context.dispatch('loadSteps')
          .then(() => {
            context.commit('setStepsVisible', visible);
          });
      }
      else
      {
        context.commit('setStepsVisible', visible);
      }

      return promise;
    },
    submit (context) {
      let url = cove.getAPI({
        name: 'api.filing.submit',
        params: { id: context.state.filingID }
      });

      return axios.post(url);
    },
    clone (context) {
      let url = cove.getAPI({
        name: 'api.filing.clone',
        params: { id: context.state.filingID }
      });

      return axios.post(url)
        .then((response) => {
          return response.data.resourceID;
        });
    },
    remove (context) {
      let url = cove.getAPI({
        name: 'api.filing',
        params: { id: context.state.filingID }
      });

      return axios.delete(url);
    }
  }
};

/** ******* Functions ******* **/
// Add private functions below for the store
function handlePollingErrorResponse (context, error)
{
  // Don't display the error on the UI, log it to the console instead, and stop polling
  context.dispatch('stopPolling');
  console.error('Error while polling for user polling data! Stopping polling for notifications >> ' +
      'Unread notification indicators will no longer update automatically until the page is refreshed.');
  console.error(error);
}

/** ************************* **/

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production', // never enable in PROD as it's a large performance hit
  modules: {
    application,
    user,
    filing
  }
});
