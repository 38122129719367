<template>
  <loading-area :loading="initialLoad" :height="350">
    <transition name="scroll-x-reverse-transition" mode="out-in">
      <v-card v-if="hasSeenStep" key="table">
        <v-card-title>
          {{ $t('page.manageFiling.docketEntry.table.title') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('page.manageFiling.docketEntry.table.description') }}
        </v-card-subtitle>

        <v-data-table
          :items="docketEntries"
          :headers="headers"
          :server-items-length="page.totalElements"
          :loading="loading"
          class="mx-sm-4"
          item-key="resourceID"
          disable-sort
          hide-default-footer
          :mobile-breakpoint="800">
          <template #item.lead="{ value }">
            <template v-if="value">
              <v-icon>
                mdi-check
              </v-icon>
              <span class="d-sr-only">
                {{ $t('global.yes') }}
              </span>
            </template>
            <template v-else>
              <span class="d-sr-only">
                {{ $t('global.no') }}
              </span>
            </template>
          </template>
          <template v-if="offerExcludeFromService" #item.excludeFromService="{ value }">
            <template v-if="value">
              <v-icon>
                mdi-check
              </v-icon>
              <span class="d-sr-only">
                {{ $t('global.yes') }}
              </span>
            </template>
            <template v-else>
              <span class="d-sr-only">
                {{ $t('global.no') }}
              </span>
            </template>
          </template>
          <template #item.documents="{ value }">
            <div v-for="d in value" :key="d.resourceID">
              <a :href="viewDocumentURL(d.documentLinkID)" target="_blank">
                {{ d.documentName }}
              </a>
            </div>
          </template>
          <template #item.resourceID="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-dots-horizontal
                  </v-icon>
                  <span class="d-sr-only">
                    {{ $t('global.action') }}
                  </span>
                </v-btn>
              </template>
              <v-list dense style="min-width:100px">
                <v-list-item class="inset-focus" @click="$emit('edit', item.resourceID)">
                  <v-list-item-title>
                    {{ $t('global.edit') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="!item.lead" class="inset-focus" @click="remove(item.resourceID)">
                  <v-list-item-title>
                    {{ $t('global.remove') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template #footer>
            <paging-controls :page="page" @changePage="changePage"/>
          </template>
        </v-data-table>

        <div class="pa-3 mt-12 text-right">
          <c-btn @click="$emit('startNew')">
            {{ $t('page.manageFiling.docketEntry.button.add') }}
          </c-btn>
          <c-btn v-if="isStepComplete" type="primary" @click="$emit('next')">
            {{ $t('global.continue') }}
          </c-btn>
        </div>
      </v-card>

      <docket-entries-splash-card v-else
                                  key="splash"
                                  :isLeadComplete="isLeadComplete"
                                  @startLead="$emit('startLead')"
                                  @viewDocketEntries="viewDocketEntries"/>

    </transition>
  </loading-area>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import LoadingArea from '@components/support/LoadingArea';
import PagingControls from '@components/support/PagingControls';
import DocketEntriesSplashCard from '@components/support/manageFiling/docketEntry/DocketEntriesSplashCard';
import Filing from '@/application/filing';

export default {
  components: { PagingControls, LoadingArea, DocketEntriesSplashCard },
  data () {
    return {
      initialLoad: true,
      hasSeenStep: false,
      isLeadComplete: false,
      loading: false,
      docketEntries: [],
      page: {
        size: 20,
        number: 0,
        totalElements: 0,
        totalPages: 0
      }
    };
  },
  computed: {
    headers () {
      let headers = [];
      headers.push({ text: this.$t('page.manageFiling.docketEntry.table.column.lead'), value: 'lead', sortable: false });
      headers.push({ text: this.$t('page.manageFiling.docketEntry.table.column.type'), value: 'docketEntryExternalName', sortable: false });
      headers.push({ text: this.$t('page.manageFiling.docketEntry.table.column.documents'), value: 'documents', sortable: false });

      if (this.offerExcludeFromService)
      {
        headers.push({ text: this.$t('page.manageFiling.docketEntry.table.column.excludeService'), value: 'excludeFromService',
          sortable: false, align: 'center' });
      }

      headers.push({ text: this.$t('global.action'), value: 'resourceID', sortable: false, align: 'right' });

      return headers;
    },
    filingID () {
      return this.$store.state.filing.filingID;
    },
    isStepComplete () {
      return this.$store.getters['filing/isStepComplete'](this.CONST.FILING_STEP_DOCKET_ENTRIES);
    },
    offerExcludeFromService () {
      return this.$store.getters['filing/hasStep'](this.CONST.FILING_STEP_SERVICE);
    }
  },
  created () {
    this.hasSeenStep = this.$store.getters['filing/hasSeenStep'];
    if (!this.hasSeenStep)
    {
      this.$store.commit('filing/setMaxSeenStep', this.$store.getters['filing/getSelectedStepID']);
    }

    this.loadDocketEntries()
      .then(() => {
        let leadDocuments = _.find(this.docketEntries, { lead: true }).documents;
        this.isLeadComplete = !_.isEmpty(leadDocuments);
      })
      .finally(() => {
        this.initialLoad = false;
      });
  },
  methods: {
    loadDocketEntries () {
      this.loading = true;

      let url = this.$cove.getAPI({
        name: 'api.filing.docketEntries',
        query: {
          filingID: this.filingID,
          size: this.page.size,
          page: this.page.number,
          sort: ['lead,desc'],
          fields: '*,documents(*)'
        }
      });

      return axios.get(url).
        then((response) => {
          this.docketEntries = _.get(response, 'data._embedded.results', []);
          this.page.number = response.data.page.number;
          this.page.totalPages = response.data.page.totalPages;
          this.page.totalElements = response.data.page.totalElements;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changePage (newPage) {
      this.page.number = newPage;
      this.loadDocketEntries();
    },
    viewDocumentURL (documentLinkID) {
      return this.$cove.getAPI({ name: 'api.dms.document', params: { documentLinkID } });
    },
    viewDocketEntries () {
      this.hasSeenStep = true;
    },
    remove (docketEntryID) {
      this.$cove.confirm({ message: this.$t('application.remove.confirm') })
        .then((confirmed) => {
          if (confirmed)
          {
            this.$cove.block({ delay: 0 });
            Filing.removeDocketEntry(docketEntryID)
              .then(() => {
                let p1 = this.loadDocketEntries();
                let p2 = this.$store.dispatch('filing/toggleSteps', { visible: true, refresh: true });
                return Promise.all([p1, p2]);
              })
              .then(() => {
                this.$cove.notify({ message: this.$t('application.remove.success'), color: 'success' });
              })
              .finally(() => {
                this.$cove.unblock();
              });
          }
        });
    }
  }
};
</script>