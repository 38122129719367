<template>
  <c-dialog
    :value="value && !loading"
    width="750"
    close-key="cove.general.close"
    title-key="popup.documents.title"
    @input="close">

    <template v-if="page.totalElements > 0">
      <v-alert :value="true" class="mb-1 rounded-0" icon="mdi-information" color="info" text>
        {{ $t('popup.documents.disclaimer') }}
      </v-alert>

      <validation-error-panel :error-scope="errorScope" class="ma-4 mb-2"/>

      <v-data-table
        :items="documents"
        :headers="headers"
        :server-items-length="page.totalElements"
        :loading="loading"
        item-key="documentLinkUUID"
        disable-sort
        hide-default-footer
        class=""
        :mobile-breakpoint="800">
        <template #item.documentInfo.pageCount="{ value }">
          {{ value > 0 ? value : '' }}
        </template>
        <template #item.documentInfo.fileSize="{ value }">
          {{ formatFileSize(value) }}
        </template>
        <template #item.userDocumentState="{ item }">
          <span v-if="item.userDocumentState === CONST.USER_DOCUMENT_STATE_UNAVAILABLE"
                class="text-minimum-gray">
            {{ $t('popup.documents.userDocumentState.unavailable') }}
          </span>
          <a v-else-if="item.userDocumentState === CONST.USER_DOCUMENT_STATE_VIEWABLE"
             :href="generateDocumentURL(item.documentLinkUUID)"
             target="_blank">
            {{ $t('global.view') }}
          </a>
          <span v-else-if="item.userDocumentState === CONST.USER_DOCUMENT_STATE_IN_CART"
                class="text-minimum-gray">
            {{ $t('popup.documents.userDocumentState.addedToCart') }}
          </span>
          <button v-else-if="item.userDocumentState === CONST.USER_DOCUMENT_STATE_PURCHASABLE"
                  type="button"
                  class="link-button"
                  @click="addToCart(item)">
            {{ $t('popup.documents.userDocumentState.addToCart') }}
          </button>
        </template>
        <template #footer>
          <paging-controls :page="page" @changePage="changePage"/>
        </template>
      </v-data-table>
    </template>

    <!-- account for situations where the document icon / popup button is shown but the user cannot access any documents -->
    <div v-else class="d-flex align-center justify-center" style="height:200px">
      <div class="text-minimum-gray font-italic pa-8" style="font-size:1.1rem;border:2px dashed var(--line-color);max-width:75ch">
        {{ $t('popup.documents.noneFound') }}
      </div>
    </div>

  </c-dialog>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import PagingControls from '@components/support/PagingControls';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import { formatFileSize } from '@/application/application';

export default {
  components: { PagingControls, ValidationErrorPanel },
  // courtID is required, and either (docketEntryUUID & caseInstanceUUID) or (caseInstanceUUID && documentLinkUUID) must be specified
  props: {
    courtID: { // the PP court UUID. don't flag as required here since parent pages will default this to null until a record is selected.
      type: String
    },
    docketEntryUUID: {
      type: String
    },
    caseInstanceUUID: {
      type: String
    },
    documentLinkUUID: {
      type: String
    },
    value: {
      type: Boolean
    }
  },
  data () {
    return {
      loading: false,
      errorScope: 'documentPopup',
      documents: [],
      page: {
        size: 10,
        number: 0,
        totalElements: 0,
        totalPages: 0
      }
    };
  },
  computed: {
    headers () {
      return [
        { text: this.$t('popup.documents.column.name'), value: 'documentName', sortable: false },
        { text: this.$t('popup.documents.column.type'), value: 'documentInfo.fileExtension', sortable: false },
        { text: this.$t('popup.documents.column.pages'), value: 'documentInfo.pageCount', sortable: false },
        { text: this.$t('popup.documents.column.size'), value: 'documentInfo.fileSize', sortable: false },
        { text: this.$t('global.view'), value: 'userDocumentState', sortable: false, align: 'right' }
      ];
    }
  },
  watch: {
    value (newValue) {
      if (newValue)
      {
        this.$cove.block();
        this.loading = true;
        this.loadDocuments()
          .finally(() => {
            this.loading = false;
            this.$cove.unblock();
          });
      }
      else
      {
        this.documents = [];
        this.page.number = 0;
        this.$store.commit('cove/errors/clear', this.errorScope);
      }
    }
  },
  methods: {
    formatFileSize,
    loadDocuments () {
      let query = {
        page: this.page.number,
        size: this.page.size,
        sort: 'documentName,asc'
      };

      query['caseHeader.courtID'] = this.courtID;

      if (!_.isNil(this.documentLinkUUID))
      {
        query.documentLinkUUID = this.documentLinkUUID;
      }

      if (!_.isNil(this.docketEntryUUID))
      {
        query.docketEntryUUID = this.docketEntryUUID;
      }

      if (!_.isNil(this.caseInstanceUUID))
      {
        query['caseHeader.caseInstanceUUID'] = this.caseInstanceUUID;
      }

      return axios.get(this.$cove.getAPI({ name: 'api.court.docketEntryDocuments.access', query }))
        .then((response) => {
          this.documents = _.get(response, 'data._embedded.results', []);
          this.page.number = response.data.page.number;
          this.page.totalPages = response.data.page.totalPages;
          this.page.totalElements = response.data.page.totalElements;
        });
    },
    generateDocumentURL (documentLinkUUID) {
      return this.$cove.getAPI({
        name: 'api.case.docketentrydocuments',
        params: {
          documentLinkUUID,
          courtID: this.courtID,
          caseInstanceID: this.caseInstanceUUID
        }
      });
    },
    addToCart (document) {
      this.$cove.block({ delay: 0 });

      let url = this.$cove.getAPI({ name: 'api.purchasedDocuments' });
      let payload = {
        documentLinkUUID: document.documentLinkUUID,
        caseInstanceUUID: document.caseHeader.caseInstanceUUID,
        courtUUID: this.getCourtByExternalID(document.caseHeader.courtID).resourceID
      };

      axios.post(url, payload, { errorScope: this.errorScope })
        .then(() => {
          this.$cove.notify({ message: this.$t('popup.documents.addToCart.success'), color: 'success' });
          document.userDocumentState = this.CONST.USER_DOCUMENT_STATE_IN_CART;
          this.$store.dispatch('user/poll');
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    changePage (newPage) {
      this.page.number = newPage;
      this.loadDocuments();
    },
    close () {
      this.$emit('input', false);
    },
    getCourtByExternalID (externalCourtID) {
      return this.$store.getters['application/getCourtByExternalID'](externalCourtID);
    }
  }
};
</script>