<template>
  <div>
    <workflow-wrapper>
      <docket-entries-table v-if="stepID === 'docketEntriesTable'"
                            key="docketEntriesTable"
                            @startLead="start(true)"
                            @startNew="start(false)"
                            @edit="edit"
                            @next="$emit('next')"/>
      <edit-docket-entry v-else-if="stepID === 'edit'"
                         :docketEntryID="editDocketEntryID"
                         key="edit"
                         @exit="exitEdit"/>

      <div v-else key="workflow">
        <div class="mx-auto" style="max-width:675px">
          <div class="mb-1" style="font-size:1.4rem">
            {{ $t('page.manageFiling.docketEntry.add') }}
          </div>

          <v-card class="mb-3 py-2 px-3">
            <breadcrumbs v-model="stepID" :steps="steps"/>
          </v-card>

          <validation-error-panel/>
          <workflow-wrapper>
            <step-type v-if="stepID === 'type'"
                       key="type"
                       :originalBean="bean"
                       @save="saveType"
                       @cancel="cancel"/>
            <step-document v-else-if="stepID === 'document'"
                           key="document"
                           :originalBean="bean.document"
                           @save="saveDocument"
                           @back="gotoType"
                           @cancel="cancel"/>
            <step-summary v-else-if="stepID === 'summary'"
                          key="summary"
                          :originalBean="bean"
                          @save="saveSummary"
                          @back="gotoDocument"
                          @cancel="cancel"/>
          </workflow-wrapper>
        </div>
      </div>
    </workflow-wrapper>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import WorkflowWrapper from '@components/support/WorkflowWrapper';
import StepType from '@components/support/manageFiling/docketEntry/StepType';
import StepDocument from '@components/support/manageFiling/docketEntry/StepDocument';
import StepSummary from '@components/support/manageFiling/docketEntry/StepSummary';
import Breadcrumbs from '@components/support/Breadcrumbs';
import DocketEntriesTable from '@components/support/manageFiling/docketEntry/DocketEntriesTable';
import ValidationErrorPanel from '@components/support/ValidationErrorPanel';
import EditDocketEntry from '@components/support/manageFiling/docketEntry/EditDocketEntry';

export default {
  components: { WorkflowWrapper, StepType, StepDocument, StepSummary, Breadcrumbs, DocketEntriesTable, ValidationErrorPanel,
    EditDocketEntry },
  data () {
    return {
      stepID: 'docketEntriesTable',
      steps: [
        { stepID: 'type', name: this.$t('page.manageFiling.docketEntry.step.type') },
        { stepID: 'document', name: this.$t('page.manageFiling.docketEntry.step.document') },
        { stepID: 'summary', name: this.$t('page.manageFiling.docketEntry.step.summary') }
      ],
      bean: this.generateFormBean(),
      editDocketEntryID: null
    };
  },
  computed: {
    filingID () {
      return this.$store.state.filing.filingID;
    },
    leadDocketEntry () {
      return this.$store.getters['filing/getLeadDocketEntry'];
    }
  },
  watch: {
    stepID (newValue) {
      if (newValue === 'docketEntriesTable')
      {
        this.$store.dispatch('filing/toggleSteps', { visible: true, refresh: true });
      }
    }
  },
  methods: {
    start (isLead) {
      this.bean = this.generateFormBean();
      this.bean.lead = isLead;
      this.gotoType();
      this.$store.dispatch('filing/toggleSteps', { visible: false });
    },
    edit (docketEntryID) {
      this.editDocketEntryID = docketEntryID;
      this.stepID = 'edit';
      this.$store.dispatch('filing/toggleSteps', { visible: false });
    },
    exitEdit () {
      this.stepID = 'docketEntriesTable';
    },
    gotoType () {
      this.stepID = 'type';
    },
    saveType (bean) {
      this.bean.filingType = bean.filingType;
      this.bean.excludeFromService = bean.excludeFromService;
      this.gotoDocument();
    },
    gotoDocument () {
      this.stepID = 'document';
      let subtype = this.bean.filingType.docketEntrySubtype;
      this.bean.document.documentName = subtype.docketEntryType.docketEntryTypeName + ' - ' + subtype.docketEntrySubTypeName;
    },
    saveDocument (bean) {
      this.bean.document = bean;
      this.gotoSummary();
    },
    gotoSummary () {
      this.stepID = 'summary';
    },
    saveSummary () {
      this.$cove.block({ delay: 0 });
      axios.post(this.$cove.getAPI({ name: 'api.filing.docketEntries' }), this.generateBeanForSaving())
        .then(() => {
          this.stepID = 'docketEntriesTable';
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
        })
        .finally(() => {
          this.$cove.unblock();
        });
    },
    cancel () {
      this.$cove.confirm({ message: this.$t('global.confirmation.cancel.edit') })
        .then((confirm) => {
          if (confirm)
          {
            this.stepID = 'docketEntriesTable';
          }
        });
    },
    generateBeanForSaving () {
      let bean = {
        filingID: this.filingID,
        filingTypeExternalIdentifier: this.bean.filingType.portalFilingTypeID,
        useLeadDocketEntryFlag: this.bean.lead,
        excludeFromService: this.bean.excludeFromService,
        docketEntryDocument: {
          ..._.pick(this.bean.document, ['documentName', 'documentNote', 'documentLinkID', 'confidentialReasonID'])
        }
      };

      return bean;
    },
    generateFormBean () {
      return {
        lead: false,
        filingType: null,
        excludeFromService: false,
        document: {
          documentLinkID: null,
          documentName: null,
          documentNote: null,
          confidentialReasonID: null,
          file: null
        }
      };
    }
  }
};
</script>