<template>
  <loading-card :loading="loading">
    <v-card-title>
      {{ $t('page.manageFiling.docketEntry.stepSummary.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.manageFiling.docketEntry.stepSummary.description') }}
    </v-card-subtitle>
    <v-card-text class="pt-2">
      <dl class="d-grid grid-4060">
        <dt>
          {{ $t('docketEntry.type') }}
        </dt>
        <dd>
          {{ renderDocketEntryName(bean.filingType) }}
        </dd>
        <template v-if="bean.excludeFromService">
          <dt>
            {{ $t('docketEntry.excludeFromService') }}
          </dt>
          <dd>
            {{ $t('global.yes') }}
          </dd>
        </template>
        <dt>
          {{ $t('global.document') }}
        </dt>
        <dd>
          <a :href="viewDocumentURL" target="_blank">
            {{ bean.document.documentName }}
          </a>
        </dd>
      </dl>
    </v-card-text>
    <v-card-actions class="justify-end mt-5">
      <c-btn type="primary" @click="save">
        {{ $t('global.finish') }}
      </c-btn>
      <c-btn @click="$emit('back')">
        {{ $t('global.back') }}
      </c-btn>
      <c-btn @click="$emit('cancel')">
        {{ $t('global.cancel') }}
      </c-btn>
    </v-card-actions>
  </loading-card>
</template>

<script>
import WorkflowStepMixin from '@/mixins/workflowStep';

export default {
  mixins: [ WorkflowStepMixin ],
  computed: {
    viewDocumentURL () {
      return this.$cove.getAPI({
        name: 'api.dms.document',
        params: { documentLinkID: this.bean.document.documentLinkID }
      });
    }
  },
  methods: {
    save () {
      this.$emit('save', this.bean);
    },
    renderDocketEntryName (filingType) {
      let docketEntrySubtype = filingType.docketEntrySubtype;
      return docketEntrySubtype.docketEntryType.docketEntryTypeName + ' - ' + docketEntrySubtype.docketEntrySubTypeName;
    }
  }
};
</script>