<template>
  <v-row no-gutters>
    <v-col cols="12">
      <section-separator :title="$t('searchType.case.criteriaSeparator')" class="mb-4"/>
    </v-col>
    <v-col v-if="!isSingleCourtMode" cols="12">
      <c-autocomplete
        :items="courts"
        v-model="searchDTO.courtID"
        item-text="displayName"
        item-value="resourceID"
        :label="$t('page.searchCriteria.case.field.court')"
        key="courtID"
        outlined
        @change="changeCourt"
        hide-details="auto"
        aria-describedby="accda3a2-e48e-4b3d-b465-e9a187ff8cf7"/>
      <field-description id="accda3a2-e48e-4b3d-b465-e9a187ff8cf7"
                         message-key="page.searchCriteria.case.field.court.description"/>
    </v-col>
    <v-col v-if="!isSingleCaseLocationMode" cols="12">
      <c-autocomplete
        v-model="searchDTO.case.locationID"
        :items="caseLocations"
        item-text="locationName"
        item-value="locationID"
        :label="$t('page.searchCriteria.case.field.location')"
        :no-data-text="$t('global.courtFirst')"
        outlined
        hide-details="auto"
        aria-describedby="a34e9e98-90df-4b8c-8f0e-01047a3dd76b"/>
      <field-description id="a34e9e98-90df-4b8c-8f0e-01047a3dd76b"
                         message-key="page.searchCriteria.case.field.location.description"/>
    </v-col>
    <v-col v-if="searchType !== CONST.SEARCH_TYPE_CITATION" cols="12">
      <c-autocomplete
        v-model="searchDTO.case.caseCategoryID"
        :items="categories"
        item-text="caseCategoryName"
        item-value="caseCategoryID"
        :label="$t('page.searchCriteria.case.field.caseCategory')"
        :no-data-text="$t('global.courtFirst')"
        outlined
        hide-details="auto"
        aria-describedby="aab15e3b-93c6-430d-a621-d367334280e1"/>
      <field-description id="aab15e3b-93c6-430d-a621-d367334280e1"
                         message-key="page.searchCriteria.case.field.caseCategory.description"/>
    </v-col>
    <v-col cols="12">
      <date-range-picker
        :label="$t('page.searchCriteria.case.field.caseFiledDate')"
        :labelStart="$t('page.searchCriteria.case.field.caseFiledDateFrom')"
        :labelEnd="$t('page.searchCriteria.case.field.caseFiledDateTo')"
        keyPrefix="caseFiledDate"
        :inputAttributes="{ outlined: true }"
        :choice.sync="searchDTO.case.filedDateChoice"
        :startDate.sync="searchDTO.case.filedDateStart"
        :endDate.sync="searchDTO.case.filedDateEnd"
        aria-describedby="a975d690-a42d-41cb-a8cb-c3b618cd58e0"/>
      <field-description id="a975d690-a42d-41cb-a8cb-c3b618cd58e0"
                         message-key="page.searchCriteria.case.field.caseFiledDate.description"/>
    </v-col>
    <v-col cols="12">
      <c-checkbox
        :label="$t('page.searchCriteria.case.field.excludeClosed')"
        v-model="searchDTO.case.excludeClosed"
        class="mt-0"
        hide-details="auto"
        aria-describedby="a52bdc60-7cb0-4ba6-905f-824435741343"/>
      <field-description id="a52bdc60-7cb0-4ba6-905f-824435741343"
                         message-key="page.searchCriteria.case.field.excludeClosed.description"/>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import SectionSeparator from '@components/support/SectionSeparator';
import DateRangePicker from '@components/support/DateRangePicker';
import FieldDescription from '@components/support/FieldDescription';
import SearchCriteriaMixin from '@/mixins/searchCriteria';

export default {
  components: { SectionSeparator, DateRangePicker, FieldDescription },
  mixins: [ SearchCriteriaMixin ],
  data () {
    return {
      categories: []
    };
  },
  computed: {
    caseLocations () {
      return this.$store.getters['application/getCaseLocationsByCourt'](this.searchDTO.courtID);
    },
    isSingleCaseLocationMode () {
      return this.$store.getters['application/isSingleCaseLocationMode'];
    }
  },
  watch: {
    'searchDTO.courtID': {
      immediate: true,
      handler (newValue) {
        if (!_.isNil(newValue))
        {
          axios.get(this.$cove.getAPI({ name: 'api.court.case.categories', params: { courtID: newValue } }))
            .then((response) => {
              this.categories = _.defaultTo(response.data, []);
            });
        }
        else
        {
          this.categories = [];
        }
      }
    }
  },
  methods: {
    changeCourt () {
      this.searchDTO.case.locationID = null;
      this.searchDTO.case.caseCategoryID = null;
      this.$emit('changeCourt');
    }
  }
};
</script>