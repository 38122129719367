<template>
  <loading-card :loading="loading" class="nav-tab-medium" :height="500">
    <v-card-title>
      {{ $t('page.currentUser.notification.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('page.currentUser.notification.subtitle') }}
    </v-card-subtitle>
    <v-card-text class="my-3 pa-0">
      <v-simple-table>
        <thead>
          <tr>
            <th scope="col">
              {{ $t('popup.notificationPreferences.notificationType') }}
            </th>
            <th v-for="method in notificationMethods" scope="col" :key="`col-${method.resourceID}`">
              <div>
                {{ method.displayName }}
              </div>
              <div>
                <c-checkbox hide-details class="ma-0 pa-0 mt-1" @change="v => { toggleAll(v, method.resourceID) }"/>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="type in notificationTypes" :key="`row-${type.resourceID}`">
            <td>{{ type.displayName }}</td>
            <td v-for="method in notificationMethods"
                :key="`${type.resourceID}_${method.resourceID}`"
                style="width:125px">
              <c-checkbox
                v-model="notificationPreferences[type.resourceID][method.resourceID].selected"
                :disabled="notificationPreferences[type.resourceID][method.resourceID].disabled"
                hide-details
                class="ma-0 pa-0"/>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
    <v-card-actions class="justify-end">
      <c-btn type="primary" class="px-4" @click="save">
        {{ $t('application.button.save') }}
      </c-btn>
    </v-card-actions>
  </loading-card>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import LoadingCard from '@components/support/LoadingCard';

export default {
  components: { LoadingCard },
  data () {
    return {
      loading: true,
      notificationTypes: [],
      notificationMethods: [],

      // notificationPreferences: the responsive, simplified object used in the vue table
      // {
      //   '<typeUUID>': {
      //     '<methodUUID>': {
      //       'selected': boolean,
      //       'disabled': boolean
      //     }
      //   }
      // }
      notificationPreferences: {}
    };
  },
  computed: {
    user () {
      return this.$store.state.user;
    }
  },
  created () {
    axios.get(this.$cove.getAPI({ name: 'api.notification.preferences' }))
      .then((response) => {
        // [{
        //   'notificationType': {
        //     'resourceID': UUID,
        //     'displayName': String
        //   },
        //   'userPreferences': [{
        //     'notificationMethod': {
        //       'resourceID': UUID,
        //       'displayName': String
        //     },
        //     'selected': boolean,
        //     'disable': boolean
        //   }]
        // }];
        let userPreferences = response.data;
        let tempPreferences = {};
        let tempTypes = [];
        let tempMethods = [];
        // make a 2D-Array to allow for easier vue reactivity
        userPreferences.forEach((rType, i) => {
          tempTypes.push(rType.notificationType);
          tempPreferences[rType.notificationType.resourceID] = {};
          rType.userPreferences.forEach((rMethod) => {
            if (i === 0) {
              tempMethods.push(rMethod.notificationMethod);
            }
            tempPreferences[rType.notificationType.resourceID][rMethod.notificationMethod.resourceID] = {
              'selected': rMethod.selected,
              'disabled': rMethod.disabled
            };
          });
        });

        this.notificationTypes = tempTypes;
        this.notificationMethods = tempMethods;
        this.notificationPreferences = tempPreferences;
      }).finally(() => {
        this.loading = false;
      });
  },
  methods: {
    toggleAll (value, methodID) {
      _.forEach(this.notificationPreferences, (typeMapping) => {
        let config = typeMapping[methodID];
        if (!config.disabled)
        {
          config.selected = value;
        }
      });
    },
    save () {
      this.$cove.block({ delay: 0 });
      // convert this.notificationPreferences to notificationPreferencesRequest

      // notificationPreferenceRequest: the object to be sent to the service to update the user's notification preferences
      // {
      //   'userPreferences': {
      //     '<notificationTypeUUID>': [{
      //       'notificationMethodID': UUID,
      //       'selected': boolean
      //     }]
      //   }
      // };
      let notificationPreferencesRequest = {
        userPreferences: {}
      };

      this.notificationTypes.forEach((notificationType) => {
        notificationPreferencesRequest.userPreferences[notificationType.resourceID]= [];

        this.notificationMethods.forEach((notificationMethod) => {
          notificationPreferencesRequest.userPreferences[notificationType.resourceID].push({
            'notificationMethodId': notificationMethod.resourceID,
            'selected': this.notificationPreferences[notificationType.resourceID][notificationMethod.resourceID].selected
          });
        });
      });

      axios.post(this.$cove.getAPI({ name: 'api.notification.preferences' }), notificationPreferencesRequest)
        .then(() => {
          this.$cove.notify({ message: this.$t('application.save.success'), color: 'success' });
        })
        .finally(() => {
          this.$cove.unblock();
        });
    }
  }
};
</script>